import { createAction } from "@reduxjs/toolkit";
import { IActionItem } from "../../models/calendar";
import { IFilterType } from "../filterType";
import { StoreState, StoreStateDontLoad } from "../storeState";
import { ClientActionListTypes } from "./types";

export const clientActionListFetchRequest = createAction<
  StoreStateDontLoad,
  ClientActionListTypes.CLIENT_ACTION_LIST_FETCH_REQUEST
>(ClientActionListTypes.CLIENT_ACTION_LIST_FETCH_REQUEST);

export const clientActionListFetchSuccess = createAction<
  {
    data: IActionItem[];
    newCountState: StoreStateDontLoad;
    newCount: number;
  },
  ClientActionListTypes.CLIENT_ACTION_LIST_FETCH_SUCCESS
>(ClientActionListTypes.CLIENT_ACTION_LIST_FETCH_SUCCESS);

export const clientActionListFetchError = createAction<
  boolean,
  ClientActionListTypes.CLIENT_ACTION_LIST_FETCH_ERROR
>(ClientActionListTypes.CLIENT_ACTION_LIST_FETCH_ERROR);

export const clientActionListCountRequest = createAction<
  void,
  ClientActionListTypes.CLIENT_ACTION_LIST_COUNT_REQUEST
>(ClientActionListTypes.CLIENT_ACTION_LIST_COUNT_REQUEST);

export const clientActionListCountSuccess = createAction<
  number,
  ClientActionListTypes.CLIENT_ACTION_LIST_COUNT_SUCCESS
>(ClientActionListTypes.CLIENT_ACTION_LIST_COUNT_SUCCESS);

export const clientActionListCountError = createAction<
  boolean,
  ClientActionListTypes.CLIENT_ACTION_LIST_COUNT_ERROR
>(ClientActionListTypes.CLIENT_ACTION_LIST_COUNT_ERROR);

export const clientActionListChangeOrder = createAction<
  { orderBy: string; orderDesc: boolean },
  ClientActionListTypes.CLIENT_ACTION_LIST_CHANGE_ORDER
>(ClientActionListTypes.CLIENT_ACTION_LIST_CHANGE_ORDER);

export const clientActionListChangePage = createAction<
  number,
  ClientActionListTypes.CLIENT_ACTION_LIST_CHANGE_PAGE
>(ClientActionListTypes.CLIENT_ACTION_LIST_CHANGE_PAGE);

export const clientActionListChangeFilter = createAction<
  IFilterType,
  ClientActionListTypes.CLIENT_ACTION_LIST_CHANGE_FILTER
>(ClientActionListTypes.CLIENT_ACTION_LIST_CHANGE_FILTER);

export const clientActionListReset = createAction<
  void,
  ClientActionListTypes.CLIENT_ACTION_LIST_RESET
>(ClientActionListTypes.CLIENT_ACTION_LIST_RESET);

export const clientActionListLoadAction = createAction<
  { newState: StoreState; newCountState: StoreStateDontLoad },
  ClientActionListTypes.CLIENT_ACTION_LIST_LOAD
>(ClientActionListTypes.CLIENT_ACTION_LIST_LOAD);
