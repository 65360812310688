import { AppThunkAction, AppThunkActionAsync } from "..";
import * as actions from "./actions";
import assistanceApi from "../../api/assistance";

export function getAssistanceAddress(
  assistanceAddressId: number
): AppThunkActionAsync {
  return async (dispatch) => {
    dispatch(actions.assistanceAddressFetchRequest());
    try {
      const address = await assistanceApi.getAddress(assistanceAddressId);
      dispatch(actions.assistanceAddressFetchSuccess(address.data));
    } catch {
      dispatch(actions.assistanceAddressFetchError());
    }
  };
}

export function getDefaultAssistanceAddress(): AppThunkAction {
  return (dispatch) => {
    const data = {
      id: 0,
      name: "",
      street: "",
      houseNumber: 0,
      landRegistryNumber: 0,
      city: "",
      postCode: 0,

      latitude: 0,
      longitude: 0,

      openMonday: "",
      openTuesday: "",
      openWednesday: "",
      openThursday: "",
      openFriday: "",
      openSaturday: "",
      openSunday: "",

      careTypeList: [],
      targetGroupList: [],
    };

    dispatch(actions.assistanceAddressFetchSuccess(data));
  };
}
