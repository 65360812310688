export const PAGE_SIZE = 10;
export const SUGGESTION_SIZE = 10;
export const DASHBOARD_SIZE = 5;
export const SEARCH_SIZE = 100;
export const DEBOUNCE_TIMEOUT_MS = 500;
export const CACHE_MINUTES = 5;
export const MAX_DASHBOARD_LINKS = 5;
export const DASHBOARD_REFRESH_MS = 60000;
export const DATE_FORMAT = "dd.MM.yyyy";
export const TIME_FORMAT = "HH:mm";
export const DATE_TIME_FORMAT = "dd.MM.yyyy HH:mm";
export const DATE_TIME_LOG_FORMAT = "dd.MM.yyyy HH:mm:ss.SSS";

export const CALENDAR_NUMBER_DEFAULT_MIN = 800;
export const CALENDAR_NUMBER_DEFAULT_MAX = 1545;
export const CALENDAR_NUMBER_DEFAULT_MAX_SLOT = 1600;
export const CALENDAR_ACTION_NUMBER_DEFAULT_MAX_SLOT = 815;
//Allowed only 5, 10, 15, 20, 30.
export const CALENDAR_STEP = 15;

export const TOAST_AUTOCLOSE_DEFAULT = 3000;
export const TOAST_AUTOCLOSE_LONGER = 5000;

export const ARCHIVE_FOLDER = "Archiv";
