import { AxiosResponse, CancelToken } from "axios";
import { FC, ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import {
  DemandPriorityType,
  DemandStateFilterType,
  DemandStateType,
  IDemandItem,
} from "../../models/demand";
import { IApplicationState } from "../../store";
import { IFilterType } from "../../store/filterType";
import {
  changeDemandListFilter,
  changeDemandListOrder,
  changeDemandListPage,
  getDemandList,
  getDemandListCount,
  IDemandListState,
  selectDemandList,
  demandListLoad,
  addDemandParams,
} from "../../store/demandList";
import FilterPanel from "../common/filter/FilterPanel";
import Grid from "../common/grid/Grid";
import { GridIconAdd, GridIconDetail } from "../common/grid/GridIcons";
import { TableCol, TableColCenter } from "../common/grid/TableCol";
import Page from "../layout/Page";
import { format } from "date-fns";
import { DATE_FORMAT } from "../../utils/consts";
import { getClassByDate } from "../../utils/date";
import { hasRight } from "../../utils/rights";
import { RightType } from "../../models/auth";
import { selectIdentityRights } from "../../store/identity";
import SuggestionListFormik from "../common/suggestion/SuggestionListFormik";
import {
  handleLoadClientSuggestions,
  handleLoadGroupSuggestions,
  handleLoadUserSuggestions,
} from "../../utils/suggestions";
import Input from "../common/form/Input";
import SuggestionFormik from "../common/suggestion/SuggestionFormik";
import { CalendarModalToDiv } from "../calendar/CalendarStyles";
import DatePicker from "../common/form/DatePicker";
import { ExportType } from "../../models/common";
import {
  listEffectGetFilter,
  listEffectsGetParamsForExport,
} from "../../store/listEffect";
import demandApi from "../../api/demand";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes, faUser } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as NewDemandIcon } from "../../images/demand-new.svg";
import i18n from "i18next";
import { InputContainer, InputRow } from "../../styles/form";
import { InputLabel } from "../common/form/InputStyles";
import InputSelectSeparator from "../common/form/InputSelectSeparator";
import {
  IChangeDemandListState,
  selectChangeDemandList,
  getChangeDemandList,
  getChangeDemandListCount,
  changeDemandLoad,
  changeChangeDemandListOrder,
  changeChangeDemandListPage,
  changeChangeDemandListFilter,
} from "../../store/changeDemandList";
import {
  ChangeDemandStateType,
  IChangeDemandItem,
} from "../../models/changeDemads";

interface IProps {
  prov: IDemandListState;
  prov2: IChangeDemandListState;
  identityRights?: RightType[];
  // demand
  getDemandList(cancelToken: CancelToken): void;
  getDemandListCount(cancelToken: CancelToken): void;
  changeDemandListOrder(orderBy: string, orderDesc: boolean): void;
  changeDemandListPage(page: number): void;
  changeDemandListFilter(filter: IFilterType): void;
  demandListLoad(reload: boolean): void;
  // change demand
  getChangeDemandList(cancelToken: CancelToken): void;
  getChangeDemandListCount(cancelToken: CancelToken): void;
  changeDemandLoad(reload: boolean): void;
  changeChangeDemandListOrder(orderBy: string, orderDesc: boolean): void;
  changeChangeDemandListPage(page: number): void;
  changeChangeDemandListFilter(filter: IFilterType): void;
}

const Demand: FC<IProps> = ({
  prov,
  prov2,
  identityRights,
  // demand
  getDemandList,
  getDemandListCount,
  changeDemandListOrder,
  changeDemandListPage,
  changeDemandListFilter,
  demandListLoad,
  // change demand
  getChangeDemandList,
  getChangeDemandListCount,
  changeDemandLoad,
  changeChangeDemandListOrder,
  changeChangeDemandListPage,
  changeChangeDemandListFilter,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    demandListLoad(true);
  }, [demandListLoad]);

  useEffect(() => {
    changeDemandLoad(true);
  }, [changeDemandLoad]);

  const handleRenderData = (item: IDemandItem): ReactElement => {
    return (
      <>
        <TableColCenter>{getDemandStateIcon(item)}</TableColCenter>
        <TableCol>
          <span className={getClassByDate(item.deadLine)}>
            {format(item.deadLine, DATE_FORMAT)}
          </span>
        </TableCol>
        <TableCol>
          <span
            className={
              item.priority === DemandPriorityType.High ? "error" : undefined
            }
          >
            {t("demand.priorities." + item.priority)}
          </span>
        </TableCol>
        <TableCol>{item.name}</TableCol>
        <TableCol>{item.clientName}</TableCol>
        <TableCol>{item.assignedName}</TableCol>
        <TableCol>
          <NavLink to={`/demand/${item.id}`}>
            <GridIconDetail />
          </NavLink>
        </TableCol>
      </>
    );
  };

  const getTextForState = (state: ChangeDemandStateType): string => {
    switch (state) {
      case ChangeDemandStateType.New:
        return t("changeDemand.stateFilter.new");
      case ChangeDemandStateType.Accepted:
        return t("changeDemand.stateFilter.accepted");
      case ChangeDemandStateType.Decline:
        return t("changeDemand.stateFilter.declined");
      default:
        return "";
    }
  };

  const renderChangeDemandData = (item: IChangeDemandItem): ReactElement => {
    return (
      <>
        <TableCol>{getTextForState(item.state)}</TableCol>
        <TableCol>{format(item.createdAt, DATE_FORMAT)}</TableCol>
        <TableCol>{item.clientName}</TableCol>
        <TableCol>{item.adminName}</TableCol>
        <TableCol />
      </>
    );
  };

  const handleRowClick = (item: IDemandItem) => {
    navigate(`/demand/${item.id}`);
  };

  const handleRowClick2 = (item: IChangeDemandItem) => {
    navigate(`/change-demand/${item.id}`);
  };

  const handleExport = async (
    type: ExportType
  ): Promise<AxiosResponse<Blob>> => {
    const params = listEffectsGetParamsForExport(prov);
    listEffectGetFilter(params, prov.filter);
    addDemandParams(params, prov);

    if (type === ExportType.Csv) {
      return await demandApi.demandListExportCsv(params);
    }

    return await demandApi.demandListExportXlsx(params);
  };

  const getDemandStateIcon = (demand: IDemandItem) => {
    switch (demand.state) {
      case DemandStateType.New:
        if (!demand.isUserAssigned) {
          return (
            <FontAwesomeIcon
              icon={faUser}
              title={i18n.t("demand.states." + demand.state)}
            />
          );
        }

        return (
          <NewDemandIcon
            title={i18n.t("demand.states." + demand.state)}
            className={getClassByDate(demand.deadLine)}
          />
        );
      case DemandStateType.Finished:
        return (
          <FontAwesomeIcon
            icon={faCheck}
            color={"var(--success-color)"}
            title={i18n.t("demand.states." + demand.state)}
          />
        );
      case DemandStateType.Canceled:
        return (
          <FontAwesomeIcon
            icon={faTimes}
            color={"var(--error-color)"}
            title={i18n.t("demand.states." + demand.state)}
          />
        );
    }
  };

  return (
    <Page>
      <FilterPanel
        title={t("demand.pageTitle")}
        name="demand"
        filter={prov.filter!}
        changeFilter={changeDemandListFilter}
        doExport={handleExport}
        initialValues={{
          from: prov.filter!.from,
          to: prov.filter!.to,
          state: prov.filter!.state,
          groups: prov.filter!.groups,
          users: prov.filter!.users,
          creators: prov.filter!.creators,
          clientId: prov.filter!.clientId,
          clientName: prov.filter!.clientName,
        }}
      >
        <InputRow>
          <InputLabel>
            {t("calendar.list.date") + " - " + t("common.from")}
          </InputLabel>
          <InputContainer>
            <DatePicker name={"from"} />
            <CalendarModalToDiv>{t("common.to")}</CalendarModalToDiv>
            <DatePicker name={"to"} />
          </InputContainer>
        </InputRow>

        <Input name="state" component="select" label={t("demand.state")}>
          <option value={DemandStateFilterType.All}>
            {t("demand.stateFilter.all")}
          </option>
          <InputSelectSeparator />
          <option value={DemandStateFilterType.New}>
            {t("demand.stateFilter.new")}
          </option>
          <option value={DemandStateFilterType.AfterDeadline}>
            {t("demand.stateFilter.afterDeadline")}
          </option>
          <option value={DemandStateFilterType.NotAssigned}>
            {t("demand.stateFilter.notAssigned")}
          </option>
          <option value={DemandStateFilterType.NotAssignedAfterDeadline}>
            {t("demand.stateFilter.notAssignedAfterDeadline")}
          </option>
          <option value={DemandStateFilterType.AssignedAfterDeadline}>
            {t("demand.stateFilter.assignedAfterDeadline")}
          </option>
          <InputSelectSeparator />
          <option value={DemandStateFilterType.Finished}>
            {t("demand.stateFilter.finished")}
          </option>
          <option value={DemandStateFilterType.Canceled}>
            {t("demand.stateFilter.canceled")}
          </option>
        </Input>

        <SuggestionListFormik
          nameList="users"
          nameId="id"
          nameText="name"
          label={t("demand.assignedUser")}
          loadSuggestions={handleLoadUserSuggestions}
        />
        <SuggestionListFormik
          nameList="groups"
          nameId="id"
          nameText="name"
          label={t("demand.assignedGroup")}
          loadSuggestions={handleLoadGroupSuggestions}
        />
        <SuggestionListFormik
          nameList="creators"
          nameId="id"
          nameText="name"
          label={t("demand.creator")}
          loadSuggestions={handleLoadUserSuggestions}
        />
        <SuggestionFormik
          nameId="clientId"
          nameText="clientName"
          label={t("demand.client")}
          loadSuggestions={handleLoadClientSuggestions}
        />
      </FilterPanel>

      <Grid<IDemandItem>
        headers={[
          { captionStr: "demand.state", orderName: "State", minContent: true },
          { captionStr: "demand.deadLine", orderName: "DeadLine" },
          { captionStr: "demand.priority", orderName: "Priority" },
          { captionStr: "demand.name", orderName: "Name" },
          { captionStr: "demand.client", orderName: "Client" },
          { captionStr: "demand.assignedName", orderName: "AssignedName" },
          {
            captionEl: hasRight(identityRights, [RightType.WriteDemand]) ? (
              <NavLink to={`/demand/new`}>
                <GridIconAdd />
              </NavLink>
            ) : undefined,
          },
        ]}
        renderData={handleRenderData}
        getData={getDemandList}
        getCount={getDemandListCount}
        changeOrder={changeDemandListOrder}
        changePage={changeDemandListPage}
        onRowClick={handleRowClick}
        prov={prov}
      />

      <br />

      <FilterPanel
        title={t("changeDemand.pageTitle")}
        name="changeDemand"
        filter={prov2.filter!}
        changeFilter={changeChangeDemandListFilter}
        initialValues={{}}
      >
        <Input name="state" component="select" label={t("demand.state")}>
          <option value={ChangeDemandStateType.All}>
            {t("demand.stateFilter.all")}
          </option>
          <InputSelectSeparator />
          <option value={ChangeDemandStateType.New}>
            {t("changeDemand.stateFilter.new")}
          </option>
          <option value={ChangeDemandStateType.Accepted}>
            {t("changeDemand.stateFilter.accepted")}
          </option>
          <option value={ChangeDemandStateType.Decline}>
            {t("changeDemand.stateFilter.declined")}
          </option>
        </Input>
      </FilterPanel>

      <Grid<IChangeDemandItem>
        headers={[
          {
            captionStr: "changeDemand.state",
            orderName: "state",
            minContent: true,
          },
          {
            captionStr: "changeDemand.createdAt",
            orderName: "createdAt",
            minContent: true,
          },
          { captionStr: "changeDemand.client" },
          { captionStr: "changeDemand.assignedName" },
          {},
        ]}
        renderData={renderChangeDemandData}
        getData={getChangeDemandList}
        getCount={getChangeDemandListCount}
        changeOrder={changeChangeDemandListOrder}
        changePage={changeChangeDemandListPage}
        onRowClick={handleRowClick2}
        prov={prov2}
      />
    </Page>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    prov: selectDemandList(state),
    prov2: selectChangeDemandList(state),
    identityRights: selectIdentityRights(state),
  };
};

const mapDispachToProps = {
  // demand
  getDemandList,
  getDemandListCount,
  changeDemandListOrder,
  changeDemandListPage,
  changeDemandListFilter,
  demandListLoad,
  // change demand,
  getChangeDemandList,
  getChangeDemandListCount,
  changeDemandLoad,
  changeChangeDemandListOrder,
  changeChangeDemandListPage,
  changeChangeDemandListFilter,
};

export default connect(mapStateToProps, mapDispachToProps)(Demand);
