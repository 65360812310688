import { FC, useEffect, useRef } from "react";

interface IProps {
  token: string;
  url: string;
}

const DocumentEditOnline: FC<IProps> = ({ token, url }) => {
  const buttonRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (buttonRef.current) {
      buttonRef.current.click();
    }
  }, [buttonRef]);

  return (
    <form
      action={url}
      encType="multipart/form-data"
      method="post"
      style={{ display: "none" }}
    >
      <input name="access_token" defaultValue={token} type="password" />
      <input type="submit" ref={buttonRef} />
    </form>
  );
};

export default DocumentEditOnline;
