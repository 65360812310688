import { createAction } from "@reduxjs/toolkit";
import { IAssistancePerson } from "../../models/assistance";
import { AssistancePersonTypes } from "./types";

export const assistancePersonFetchRequest = createAction<
  void,
  AssistancePersonTypes.ASSISTANCE_PERSON_FETCH_REQUEST
>(AssistancePersonTypes.ASSISTANCE_PERSON_FETCH_REQUEST);

export const assistancePersonFetchSuccess = createAction<
  IAssistancePerson,
  AssistancePersonTypes.ASSISTANCE_PERSON_FETCH_SUCCESS
>(AssistancePersonTypes.ASSISTANCE_PERSON_FETCH_SUCCESS);

export const assistancePersonFetchError = createAction<
  void,
  AssistancePersonTypes.ASSISTANCE_PERSON_FETCH_ERROR
>(AssistancePersonTypes.ASSISTANCE_PERSON_FETCH_ERROR);
