import * as ReselectModule from "reselect";
import { IApplicationState } from "..";
import { WorkflowType } from "../../models/workflow";
import { StoreState } from "../storeState";

export const selectWorkflowUnsetTypesState: ReselectModule.Selector<
  IApplicationState,
  StoreState
> = (state) => state.workflowUnsetTypes.state;

export const selectWorkflowUnsetTypesLastLoaded: ReselectModule.Selector<
  IApplicationState,
  Date | undefined
> = (state) => state.workflowUnsetTypes.lastLoaded;

export const selectWorkflowUnsetTypes: ReselectModule.Selector<
  IApplicationState,
  WorkflowType[]
> = (state) => state.workflowUnsetTypes.data;
