import { createAction } from "@reduxjs/toolkit";
import { IItemIdName } from "../../models/common";
import { ClientRiskTypes } from "./types";

export const clientRiskFetchRequest = createAction<
  void,
  ClientRiskTypes.CLIENT_RISK_FETCH_REQUEST
>(ClientRiskTypes.CLIENT_RISK_FETCH_REQUEST);

export const clientRiskFetchSuccess = createAction<
  IItemIdName[],
  ClientRiskTypes.CLIENT_RISK_FETCH_SUCCESS
>(ClientRiskTypes.CLIENT_RISK_FETCH_SUCCESS);

export const clientRiskFetchError = createAction<
  void,
  ClientRiskTypes.CLIENT_RISK_FETCH_ERROR
>(ClientRiskTypes.CLIENT_RISK_FETCH_ERROR);

export const clientRiskReset = createAction<
  void,
  ClientRiskTypes.CLIENT_RISK_RESET
>(ClientRiskTypes.CLIENT_RISK_RESET);
