import { addDays, formatISO, startOfWeek, subDays } from "date-fns";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CalendarItemType,
  ICalendarCol,
  ICalendarCols,
  ICalendarItem,
} from "../../models/calendar";
import {
  getCalendar,
  transformActionReponseSimple,
} from "../../utils/calendar";
import Calendar from "../common/calendar/Calendar";
import { CalendarHeader } from "../common/calendar/CalendarStyles";
import CalendarDateSelector from "../common/calendar/CalendarDateSelector";
import { connect } from "react-redux";
import { IApplicationState } from "../../store";
import { selectClientId } from "../../store/client";
import { Button } from "../../styles/button";
import { useNavigate, useParams } from "react-router";
import CalendarDetailModal, {
  ICalendarDetailModalData,
} from "../calendar/CalendarDetailModal";
import { errorToast } from "../../utils/toasts";
import clientApi from "../../api/client";

interface IProps {
  clientId?: number;
}

const ClientCalendar: FC<IProps> = ({ clientId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { clientId: paramClientId } = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [labels, setLabels] = useState<string[]>([]);
  const [cols, setCols] = useState<ICalendarCol[]>([]);
  const [mondayDate, setMondayDate] = useState(
    startOfWeek(new Date(), { weekStartsOn: 1 })
  );
  const [openDetailData, setOpenDetailData] =
    useState<ICalendarDetailModalData | null>(null);

  const getData = async (mondayDate: Date): Promise<ICalendarCols> => {
    const params = new URLSearchParams();
    params.append("from", formatISO(mondayDate));
    params.append("to", formatISO(addDays(mondayDate, 7)));

    const apiData = await clientApi.getClientActionList(clientId!, { params });

    const transformed = transformActionReponseSimple(apiData.data);
    const data = getCalendar(mondayDate, 7, transformed, true);
    return data;
  };

  const handleOnClick = (type: CalendarItemType, item: ICalendarItem) => {
    setOpenDetailData({ type, item });
  };

  const handlePrev = () => {
    const newMonday = subDays(mondayDate, 7);
    setMondayDate(newMonday);
  };

  const handleNext = () => {
    const newMonday = addDays(mondayDate, 7);
    setMondayDate(newMonday);
  };

  const handleReset = () => {
    const newMonday = startOfWeek(new Date(), { weekStartsOn: 1 });
    setMondayDate(newMonday);
  };

  const handleDetailDataClose = () => {
    setOpenDetailData(null);
  };

  const handleAddAction = () => {
    navigate(
      `/client/${paramClientId}/action/new?return=/client/${paramClientId}/calendar`
    );
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const data = await getData(mondayDate);
        setLabels(data.labels);
        setCols(data.cols);
      } catch (err) {
        errorToast();
      }
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mondayDate, t]);

  return (
    <>
      <Button ver="secondary" onClick={handleAddAction}>
        {t("client.calendar.addAction")}
      </Button>

      <CalendarDetailModal
        data={openDetailData}
        close={handleDetailDataClose}
      />
      <CalendarHeader>
        <CalendarDateSelector
          date={mondayDate}
          onClickPrev={handlePrev}
          onClickNext={handleNext}
          onReset={handleReset}
        />
      </CalendarHeader>
      <Calendar
        labels={labels}
        cols={cols}
        loading={loading}
        onClick={handleOnClick}
      />
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    clientId: selectClientId(state),
  };
};

export default connect(mapStateToProps)(ClientCalendar);
