import { AppThunkAction, AppThunkActionAsync } from "..";
import * as actions from "./actions";
import clientApi from "../../api/client";
import { selectClientContactPersonList } from ".";
import { IFilterType } from "../filterType";
import { CancelToken } from "axios";
import {
  listEffectCountFilter,
  listEffectGetFilter,
  listEffectLoad,
  listEffectReload,
  listEffectsCountCheck,
  listEffectsGetCheck,
  listEffectsGetCount,
  listEffectsGetNew,
  listEffectsGetParams,
} from "../listEffect";

export function getClientContactPersonList(
  clientId: number,
  cancelToken: CancelToken
): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectClientContactPersonList(getState());
    if (listEffectsGetCheck(state.state)) {
      return;
    }

    let newState = listEffectsGetNew(state);
    dispatch(
      actions.clientContactPersonListFetchRequest(newState.newCountState)
    );
    try {
      const params = listEffectsGetParams(state, newState.take);
      listEffectGetFilter(params, state.filter);

      const contactPersons = await clientApi.getClientContactPersonList(
        clientId,
        {
          params,
          cancelToken,
        }
      );
      cancelToken.throwIfRequested();
      newState = listEffectsGetCount(newState, contactPersons);

      dispatch(
        actions.clientContactPersonListFetchSuccess({
          data: contactPersons.data,
          newCountState: newState.newCountState,
          newCount: newState.newCount,
        })
      );
    } catch {
      dispatch(
        actions.clientContactPersonListFetchError(
          cancelToken.reason !== undefined
        )
      );
    }
  };
}

export function getClientContactPersonListCount(
  clientId: number,
  cancelToken: CancelToken
): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectClientContactPersonList(getState());
    if (listEffectsCountCheck(state.countState)) {
      return;
    }

    dispatch(actions.clientContactPersonListCountRequest());
    try {
      const params = listEffectCountFilter(state.filter);
      const count = await clientApi.getClientContactPersonCount(clientId, {
        params,
        cancelToken,
      });
      cancelToken.throwIfRequested();

      dispatch(actions.clientContactPersonListCountSuccess(count.data));
    } catch {
      dispatch(
        actions.clientContactPersonListCountError(
          cancelToken.reason !== undefined
        )
      );
    }
  };
}

export function changeClientContactPersonListOrder(
  orderBy: string,
  orderDesc: boolean
): AppThunkAction {
  return async (dispatch) => {
    dispatch(
      actions.clientContactPersonListChangeOrder({ orderBy, orderDesc })
    );
  };
}

export function changeClientContactPersonListPage(
  page: number
): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.clientContactPersonListChangePage(page));
  };
}

export function changeClientContactPersonListFilter(
  filter: IFilterType
): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.clientContactPersonListChangeFilter(filter));
  };
}

export function clientContactPersonListLoad(reload: boolean): AppThunkAction {
  return async (dispatch, getState) => {
    const state = selectClientContactPersonList(getState());
    reload = listEffectReload(reload, state);
    if (reload) {
      dispatch(actions.clientContactPersonListReset());
    } else {
      const newState = listEffectLoad(state);
      dispatch(
        actions.clientContactPersonListLoadAction({
          newState: newState.newState,
          newCountState: newState.newCountState,
        })
      );
    }
  };
}
