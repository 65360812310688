import {
  WorkflowDefinitionItemAutoType,
  WorkflowDefinitionItemTopMode,
  WorkflowDefinitionItemType,
} from "../../../models/workflow";

export enum DiagramActionType {
  Add = "add",
  Link = "link",
  Edit = "edit",
  Delete = "delete",
}

export enum DiagramPortFoundType {
  AllEnabled = "allEnabled",
  AllDisabled = "allDisabled",
  History = "history",
}

export interface IDiagramNodeData {
  type: WorkflowDefinitionItemType;
  question?: string | null;
  answers: string[];
  autoType?: WorkflowDefinitionItemAutoType | null;
  templateId?: number | null;
  templateName?: string | null;
  topPortMode: WorkflowDefinitionItemTopMode;
  nestedWorkflowId?: number | null;
  nestedWorkflowName?: string | null;
}

export const DiagramNodeDataInit: IDiagramNodeData = {
  type: WorkflowDefinitionItemType.Template,
  question: "",
  answers: ["", ""],
  autoType: undefined,
  templateId: undefined,
  templateName: undefined,
  topPortMode: WorkflowDefinitionItemTopMode.AtLeastOne,
  nestedWorkflowId: undefined,
  nestedWorkflowName: undefined,
};
