import { AppThunkAction, AppThunkActionAsync } from "..";
import * as actions from "./actions";
import basicApi from "../../api/basic";
import { IBasicSettings } from "../../models/basic";
import { StoreState } from "../storeState";
import { selectBasicSettingsState } from ".";

export function getBasicSettings(): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectBasicSettingsState(getState());
    if (state !== StoreState.None) {
      return;
    }

    dispatch(actions.basicSettingsFetchRequest());
    try {
      const basicSettings = await basicApi.getBasicSettings();
      dispatch(actions.basicSettingsFetchSuccess(basicSettings.data));
    } catch {
      dispatch(actions.basicSettingsFetchError());
    }
  };
}

export function updateBasicSettings(newData: IBasicSettings): AppThunkAction {
  return (dispatch) => {
    dispatch(actions.basicSettingsFetchSuccess(newData));
  };
}
