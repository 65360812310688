import { CancelToken } from "axios";
import { format } from "date-fns";
import { FC, ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ILogItem } from "../../models/log";
import { IApplicationState } from "../../store";
import { IFilterType } from "../../store/filterType";
import {
  changeLogListFilter,
  changeLogListOrder,
  changeLogListPage,
  getLogList,
  getLogListCount,
  ILogListState,
  selectLogList,
  logListLoad,
} from "../../store/logList";
import { DATE_TIME_LOG_FORMAT } from "../../utils/consts";
import {
  handleLoadClientSuggestions,
  handleLoadUserSuggestions,
} from "../../utils/suggestions";
import FilterPanel from "../common/filter/FilterPanel";
import DatePicker from "../common/form/DatePicker";
import Grid from "../common/grid/Grid";
import { TableCol } from "../common/grid/TableCol";
import SuggestionFormik from "../common/suggestion/SuggestionFormik";
import AdminLogDescription from "./AdminLogDescription";

interface IProps {
  prov: ILogListState;
  getLogList(cancelToken: CancelToken): void;
  getLogListCount(cancelToken: CancelToken): void;
  changeLogListOrder(orderBy: string, orderDesc: boolean): void;
  changeLogListPage(page: number): void;
  changeLogListFilter(filter: IFilterType): void;
  logListLoad(reload: boolean): void;
}

const AdminLog: FC<IProps> = ({
  prov,
  getLogList,
  getLogListCount,
  changeLogListOrder,
  changeLogListPage,
  changeLogListFilter,
  logListLoad,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    logListLoad(true);
  }, [logListLoad]);

  const handleRenderData = (item: ILogItem): ReactElement => {
    return (
      <>
        <TableCol>{format(item.dateTime, DATE_TIME_LOG_FORMAT)}</TableCol>
        <TableCol>{item.user?.name}</TableCol>
        <TableCol>{item.client?.name}</TableCol>
        <TableCol>
          <AdminLogDescription item={item} />
        </TableCol>
      </>
    );
  };

  return (
    <>
      <FilterPanel
        title={t("admin.log.pageTitle")}
        name="adminLog"
        filter={prov.filter!}
        changeFilter={changeLogListFilter}
        initialValues={{
          from: prov.filter!.from,
          to: prov.filter!.to,
          userId: prov.filter!.userId,
          userName: prov.filter!.userName,
          clientId: prov.filter!.clientId,
          clientName: prov.filter!.clientName,
        }}
      >
        <DatePicker name={"from"} label={t("common.from")} />
        <DatePicker name={"to"} label={t("common.to")} />
        <SuggestionFormik
          nameId="userId"
          nameText="userName"
          label={t("admin.log.user")}
          loadSuggestions={handleLoadUserSuggestions}
        />
        <SuggestionFormik
          nameId="clientId"
          nameText="clientName"
          label={t("admin.log.client")}
          loadSuggestions={handleLoadClientSuggestions}
        />
      </FilterPanel>
      <Grid<ILogItem>
        headers={[
          { captionStr: "admin.log.dateTime" },
          { captionStr: "admin.log.user" },
          { captionStr: "admin.log.client" },
          { captionStr: "admin.log.description" },
        ]}
        renderData={handleRenderData}
        getData={getLogList}
        getCount={getLogListCount}
        changeOrder={changeLogListOrder}
        changePage={changeLogListPage}
        buttons={false}
        prov={prov}
      />
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    prov: selectLogList(state),
  };
};

const mapDispachToProps = {
  getLogList,
  getLogListCount,
  changeLogListOrder,
  changeLogListPage,
  changeLogListFilter,
  logListLoad,
};

export default connect(mapStateToProps, mapDispachToProps)(AdminLog);
