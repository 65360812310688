import { AxiosResponse } from "axios";
import { IDashboadLinksRequest, IDashboardLink } from "../models/dashboard";
import api from "../utils/api";

const getDashboardLinks = async (): Promise<AxiosResponse<IDashboardLink[]>> =>
  api.get(`/api/Dashboard/Link`);

const updateDashboardLinks = async (
  data: IDashboadLinksRequest
): Promise<AxiosResponse<boolean>> => api.put(`/api/Dashboard/Link`, data);

const dashboardApi = {
  getDashboardLinks,
  updateDashboardLinks,
};

export default dashboardApi;
