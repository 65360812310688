import { createAction } from "@reduxjs/toolkit";
import { IBasicSettings } from "../../models/basic";
import { BasicSettingsTypes } from "./types";

export const basicSettingsFetchRequest = createAction<
  void,
  BasicSettingsTypes.BASIC_SETTINGS_FETCH_REQUEST
>(BasicSettingsTypes.BASIC_SETTINGS_FETCH_REQUEST);

export const basicSettingsFetchSuccess = createAction<
  IBasicSettings,
  BasicSettingsTypes.BASIC_SETTINGS_FETCH_SUCCESS
>(BasicSettingsTypes.BASIC_SETTINGS_FETCH_SUCCESS);

export const basicSettingsFetchError = createAction<
  void,
  BasicSettingsTypes.BASIC_SETTINGS_FETCH_ERROR
>(BasicSettingsTypes.BASIC_SETTINGS_FETCH_ERROR);
