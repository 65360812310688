import { CancelToken } from "axios";
import { FC, ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { RightType } from "../../models/auth";
import { IWorkflowItem } from "../../models/workflow";
import { IApplicationState } from "../../store";
import { IFilterType } from "../../store/filterType";
import { selectIdentityRights } from "../../store/identity";
import {
  changeWorkflowListFilter,
  changeWorkflowListOrder,
  changeWorkflowListPage,
  getWorkflowList,
  getWorkflowListCount,
  IWorkflowListState,
  selectWorkflowList,
  workflowListLoad,
} from "../../store/workflowList";
import { hasRight } from "../../utils/rights";
import FilterPanel from "../common/filter/FilterPanel";
import Grid from "../common/grid/Grid";
import {
  GridIconAdd,
  GridIconEdit,
  GridIconIsActive,
} from "../common/grid/GridIcons";
import { TableCol, TableColCenter } from "../common/grid/TableCol";
import Page from "../layout/Page";
import ModuleWorkflowUnsetTypes from "./ModuleWorkflowUnsetTypes";

interface IProps {
  prov: IWorkflowListState;
  identityRights?: RightType[];
  getWorkflowList(cancelToken: CancelToken): void;
  getWorkflowListCount(cancelToken: CancelToken): void;
  changeWorkflowListOrder(orderBy: string, orderDesc: boolean): void;
  changeWorkflowListPage(page: number): void;
  changeWorkflowListFilter(filter: IFilterType): void;
  workflowListLoad(reload: boolean): void;
}

const ModuleWorkflow: FC<IProps> = ({
  prov,
  identityRights,
  getWorkflowList,
  getWorkflowListCount,
  changeWorkflowListOrder,
  changeWorkflowListPage,
  changeWorkflowListFilter,
  workflowListLoad,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    workflowListLoad(false);
  }, [workflowListLoad]);

  const handleRenderData = (item: IWorkflowItem): ReactElement => {
    return (
      <>
        <TableColCenter>{item.isActive && <GridIconIsActive />}</TableColCenter>
        <TableCol>{item.name}</TableCol>
        <TableCol>{t("module.workflow.types." + item.type)}</TableCol>
        <TableCol>
          <NavLink to={`/module/workflow/${item.id}`}>
            <GridIconEdit />
          </NavLink>
        </TableCol>
      </>
    );
  };

  const handleRowClick = (item: IWorkflowItem) => {
    navigate(`/module/workflow/${item.id}`);
  };

  return (
    <Page>
      <FilterPanel
        title={t("module.workflow.pageTitle")}
        name="moduleWorkflow"
        filter={prov.filter!}
        changeFilter={changeWorkflowListFilter}
      />
      <Grid<IWorkflowItem>
        headers={[
          { captionStr: "module.workflow.isActive", minContent: true },
          { captionStr: "module.workflow.name" },
          { captionStr: "module.workflow.type" },
          {
            captionEl: hasRight(identityRights, [RightType.WriteWorkflow]) ? (
              <NavLink to={`/module/workflow/new`}>
                <GridIconAdd />
              </NavLink>
            ) : undefined,
          },
        ]}
        renderData={handleRenderData}
        getData={getWorkflowList}
        getCount={getWorkflowListCount}
        changeOrder={changeWorkflowListOrder}
        changePage={changeWorkflowListPage}
        onRowClick={handleRowClick}
        prov={prov}
      />
      <ModuleWorkflowUnsetTypes />
    </Page>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    prov: selectWorkflowList(state),
    identityRights: selectIdentityRights(state),
  };
};

const mapDispachToProps = {
  getWorkflowList,
  getWorkflowListCount,
  changeWorkflowListOrder,
  changeWorkflowListPage,
  changeWorkflowListFilter,
  workflowListLoad,
};

export default connect(mapStateToProps, mapDispachToProps)(ModuleWorkflow);
