import * as ReselectModule from "reselect";
import { IApplicationState } from "..";
import { IItemIdName } from "../../models/common";
import { StoreState } from "../storeState";

export const selectTemplateFolderState: ReselectModule.Selector<
  IApplicationState,
  StoreState
> = (state) => state.templateFolder.state;

export const selectTemplateFolder: ReselectModule.Selector<
  IApplicationState,
  IItemIdName[]
> = (state) => state.templateFolder.data;
