import { FC, useEffect, useRef, useState } from "react";
import { IClientDocumentItem } from "../../models/client";
import { useTranslation } from "react-i18next";
import CustomModal from "../common/modal/CustomModal";
import { Button } from "../../styles/button";
import { Form, Formik, FormikProps } from "formik";
import { ApiError, FormGroup } from "../../styles/form";
import Input from "../common/form/Input";
import * as Yup from "yup";
import SuggestionListFormik from "../common/suggestion/SuggestionListFormik";
import {
  handleLoadAssistanceSuggestions,
  handleLoadGroupSuggestions,
  handleLoadRoleSuggestions,
  handleLoadUserSuggestions,
} from "../../utils/suggestions";
import validations from "../../utils/validations";
import { errorSet } from "../../utils/error";
import { promiseToastSave } from "../../utils/toasts";
import clientApi from "../../api/client";
import { keepFileExtensions } from "../../utils/string";

interface FormikDocumentParams {
  id: number;
  name: string;
  isClientVisible: boolean;
  users?: { id: number; name: string }[];
  groups?: { id: number; name: string }[];
  roles?: { id: number; name: string }[];
  assistances?: { id: number; name: string }[];
}

interface Props {
  open: boolean;
  document: IClientDocumentItem | null;
  clientId: number;
  onClose: () => void;
  onConfirm: () => void;
}

export const ClientDocumentDocumentEditModal: FC<Props> = ({
  open,
  document,
  clientId,
  onClose,
  onConfirm,
}) => {
  const { t } = useTranslation();
  const formikRef = useRef<FormikProps<FormikDocumentParams> | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setError(null);
    formikRef.current?.resetForm();
  }, [open]);

  const handleSubmit = async (values: FormikDocumentParams) => {
    setError(null);
    try {
      await promiseToastSave(async () => {
        const fileName = keepFileExtensions(document!.name, values.name);

        await clientApi.clientDocumentUpdate(values.id, {
          name: fileName,
          idClient: clientId,
          users: values.users,
          groups: values.groups,
          roles: values.roles,
          assistances: values.assistances,
          isClientVisible: values.isClientVisible,
        });
      });
      onConfirm();
    } catch (err) {
      errorSet(setError, err, t);
    }
  };

  if (!document) return null;
  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        id: document.id,
        name: document.name,
        isClientVisible: document.isClientVisible,
        assistances: document.assistances,
        users: document.users,
        groups: document.groups,
        roles: document.roles,
      }}
      onSubmit={handleSubmit}
      validationSchema={Yup.object().shape({
        name: validations.stringRequired(t),
      })}
      validateOnMount
      enableReinitialize
    >
      {({ isSubmitting, isValid, submitForm, touched, errors }) => (
        <CustomModal
          title={t("module.document.modal.editTitle")}
          isOpen={open}
          close={onClose}
          actions={
            <>
              <Button ver="secondary" disabled={isSubmitting} onClick={onClose}>
                {t("common.cancel")}
              </Button>
              <Button
                disabled={!isValid || isSubmitting}
                onClick={() => submitForm()}
              >
                {t("common.save")}
              </Button>
            </>
          }
        >
          <Form>
            <FormGroup labelWidth="7rem">
              <Input
                name="name"
                key="name"
                label={t("module.document.folder.name")}
                error={touched.name && !!errors.name}
                maxLength={255}
              />
              <hr />
              <h2>{t("module.document.rights.title")}</h2>
              <SuggestionListFormik
                nameList="users"
                nameId="id"
                nameText="name"
                label={t("module.document.rights.users")}
                loadSuggestions={handleLoadUserSuggestions}
              />
              <SuggestionListFormik
                nameList="groups"
                nameId="id"
                nameText="name"
                label={t("module.document.rights.groups")}
                loadSuggestions={handleLoadGroupSuggestions}
              />
              <SuggestionListFormik
                nameList="roles"
                nameId="id"
                nameText="name"
                label={t("module.document.rights.roles")}
                loadSuggestions={handleLoadRoleSuggestions}
              />
              <SuggestionListFormik
                nameList="assistances"
                nameId="id"
                nameText="name"
                label={t("module.document.rights.assistances")}
                loadSuggestions={handleLoadAssistanceSuggestions}
              />
              <Input
                name="isClientVisible"
                type="checkbox"
                label={t(`module.document.rights.isClientVisible`)}
                inputWidth="1.5rem"
                inputHeight="1.5rem"
              />
              {error && <ApiError>{error}</ApiError>}
            </FormGroup>
          </Form>
        </CustomModal>
      )}
    </Formik>
  );
};
