import styled from "styled-components";

export const DemandQuestionContainer = styled.div``;

export const DemandQuestionTitle = styled.p`
  font-weight: bold;
`;

export const DemandQuestionOptionContainer = styled.div``;

export const DemandQuestionOptionInput = styled.input``;

export const DemandQuestionOptionLabel = styled.label`
  margin-left: 0.2rem;
`;
