import { createAction } from "@reduxjs/toolkit";
import { ILogItem } from "../../models/log";
import { StoreState, StoreStateDontLoad } from "../storeState";
import { ILogListFilter, LogListTypes } from "./types";

export const logListFetchRequest = createAction<
  StoreStateDontLoad,
  LogListTypes.LOG_LIST_FETCH_REQUEST
>(LogListTypes.LOG_LIST_FETCH_REQUEST);

export const logListFetchSuccess = createAction<
  { data: ILogItem[]; newCountState: StoreStateDontLoad; newCount: number },
  LogListTypes.LOG_LIST_FETCH_SUCCESS
>(LogListTypes.LOG_LIST_FETCH_SUCCESS);

export const logListFetchError = createAction<
  boolean,
  LogListTypes.LOG_LIST_FETCH_ERROR
>(LogListTypes.LOG_LIST_FETCH_ERROR);

export const logListCountRequest = createAction<
  void,
  LogListTypes.LOG_LIST_COUNT_REQUEST
>(LogListTypes.LOG_LIST_COUNT_REQUEST);

export const logListCountSuccess = createAction<
  number,
  LogListTypes.LOG_LIST_COUNT_SUCCESS
>(LogListTypes.LOG_LIST_COUNT_SUCCESS);

export const logListCountError = createAction<
  boolean,
  LogListTypes.LOG_LIST_COUNT_ERROR
>(LogListTypes.LOG_LIST_COUNT_ERROR);

export const logListChangeOrder = createAction<
  { orderBy: string; orderDesc: boolean },
  LogListTypes.LOG_LIST_CHANGE_ORDER
>(LogListTypes.LOG_LIST_CHANGE_ORDER);

export const logListChangePage = createAction<
  number,
  LogListTypes.LOG_LIST_CHANGE_PAGE
>(LogListTypes.LOG_LIST_CHANGE_PAGE);

export const logListChangeFilter = createAction<
  ILogListFilter,
  LogListTypes.LOG_LIST_CHANGE_FILTER
>(LogListTypes.LOG_LIST_CHANGE_FILTER);

export const logListReset = createAction<void, LogListTypes.LOG_LIST_RESET>(
  LogListTypes.LOG_LIST_RESET
);

export const logListLoadAction = createAction<
  { newState: StoreState; newCountState: StoreStateDontLoad },
  LogListTypes.LOG_LIST_LOAD
>(LogListTypes.LOG_LIST_LOAD);
