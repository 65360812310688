import { AxiosResponse, CancelToken } from "axios";
import { FC, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RightType } from "../../models/auth";
import { IActionItem } from "../../models/calendar";
import { IApplicationState } from "../../store";
import { IFilterType } from "../../store/filterType";
import {
  selectIdentityId,
  selectIdentityName,
  selectIdentityRights,
} from "../../store/identity";
import {
  changeActionListFilter,
  changeActionListOrder,
  changeActionListPage,
  getActionList,
  getActionListCount,
  IActionListState,
  selectActionList,
  actionListLoad,
  actionListSetDefaultFilter,
  IActionListFilter,
  addActionParams,
} from "../../store/actionList";
import FilterPanel from "../common/filter/FilterPanel";
import Grid from "../common/grid/Grid";
import { TableCol } from "../common/grid/TableCol";
import { addDays, format, startOfDay } from "date-fns";
import * as Yup from "yup";
import DatePicker from "../common/form/DatePicker";
import Loader from "../common/Loader";
import { ExportType, IItemIdName } from "../../models/common";
import SuggestionListFormik from "../common/suggestion/SuggestionListFormik";
import { DATE_TIME_FORMAT, TIME_FORMAT } from "../../utils/consts";
import validations from "../../utils/validations";
import {
  handleLoadClientSuggestions,
  handleLoadUserSuggestions,
} from "../../utils/suggestions";
import { NavLink, useNavigate } from "react-router-dom";
import { GridIconAdd, GridIconEdit } from "../common/grid/GridIcons";
import { hasRight } from "../../utils/rights";
import { GridSelectedCheck } from "../common/grid/GridComponents";
import { ActionButton } from "../../styles/button";
import calendarApi from "../../api/calendar";
import Input from "../common/form/Input";
import SuggestionFormik from "../common/suggestion/SuggestionFormik";
import { promiseToastDeleteNoException } from "../../utils/toasts";
import { useFormikContext } from "formik";
import AssignUserModal from "./AssignUserModal";
import {
  listEffectGetFilter,
  listEffectsGetParamsForExport,
} from "../../store/listEffect";

interface IProps {
  prov: IActionListState;
  identityId?: number;
  identityName?: string;
  identityRights?: RightType[];
  getActionList(cancelToken: CancelToken): void;
  getActionListCount(cancelToken: CancelToken): void;
  changeActionListOrder(orderBy: string, orderDesc: boolean): void;
  changeActionListPage(page: number): void;
  changeActionListFilter(filter: IFilterType): void;
  actionListLoad(reload: boolean): void;
  actionListSetDefaultFilter(
    assignedUsers: IItemIdName[],
    from: Date | null,
    to: Date | null
  ): void;
}

const CalendarFilter: FC = () => {
  const { t } = useTranslation();
  const { values } = useFormikContext<IActionListFilter>();

  return (
    <>
      <DatePicker name="from" label={t("common.from")} />
      <DatePicker name="to" label={t("common.to")} />
      <Input
        name="notAssigned"
        type="checkbox"
        label={t("calendar.list.notAssigned")}
        inputWidth="1.5rem"
        inputHeight="1.5rem"
      />
      {!values.notAssigned && (
        <SuggestionListFormik
          nameList="assignedUsers"
          nameId="id"
          nameText="name"
          label={t("calendar.list.users")}
          loadSuggestions={handleLoadUserSuggestions}
        />
      )}
      <SuggestionFormik
        nameId="clientId"
        nameText="clientName"
        label={t("calendar.list.client")}
        loadSuggestions={handleLoadClientSuggestions}
      />
    </>
  );
};

const CalendarList: FC<IProps> = ({
  prov,
  identityId,
  identityName,
  identityRights,
  getActionList,
  getActionListCount,
  changeActionListOrder,
  changeActionListPage,
  changeActionListFilter,
  actionListLoad,
  actionListSetDefaultFilter,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [assignmentIsOpen, setAssignmentIsOpen] = useState<boolean>(false);

  useEffect(() => {
    const defaultAssignedUsers = [{ id: identityId!, name: identityName! }];
    const defaultFrom = startOfDay(new Date());
    const defaultTo = addDays(defaultFrom, 7);

    actionListSetDefaultFilter(defaultAssignedUsers, defaultFrom, defaultTo);
    actionListLoad(true);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionListLoad]);

  useEffect(() => {
    setSelectedIds([]);
  }, [prov.filter]);

  const isOutdated = (item: IActionItem) =>
    item.from < new Date() || item.to < new Date();

  const handleSelectId = (item: IActionItem) => {
    if (selectedIds.find((x) => x === item.id) === undefined) {
      setSelectedIds([...selectedIds, item.id]);
    } else {
      setSelectedIds(selectedIds.filter((x) => x !== item.id));
    }
  };

  const handleSelectAll = () => {
    if (
      prov.data
        .filter((item) => !isOutdated(item))
        .find((x) => !selectedIds.includes(x.id)) === undefined
    ) {
      setSelectedIds(
        selectedIds.filter(
          (x) => prov.data.find((y) => y.id === x) === undefined
        )
      );
    } else {
      setSelectedIds([
        ...selectedIds,
        ...prov.data
          .filter((item) => !isOutdated(item))
          .map((x) => x.id)
          .filter((x) => !selectedIds.includes(x)),
      ]);
    }
  };

  const handleAssigneUser = async () => {
    setAssignmentIsOpen(true);
  };

  const handleCloseAssignment = () => {
    setAssignmentIsOpen(false);
  };

  const handleConfirmAssignment = () => {
    setAssignmentIsOpen(false);
    setSelectedIds([]);
    actionListLoad(true);
  };

  const handleDeleteSelectedActions = async () => {
    await promiseToastDeleteNoException(async () => {
      for (const id of selectedIds) {
        await calendarApi.deleteAction(id);
      }
      setSelectedIds([]);
      actionListLoad(true);
    });
  };

  const handleRenderData = (item: IActionItem): ReactElement => {
    return (
      <>
        <TableCol>
          <GridSelectedCheck
            checked={selectedIds.find((x) => x === item.id) !== undefined}
            onChange={() => handleSelectId(item)}
            disabled={isOutdated(item)}
          />
        </TableCol>
        <TableCol>
          {item.userAssignee ? (
            item.userAssignee.name
          ) : (
            <span className="error">{t("calendar.list.unassigned")}</span>
          )}
        </TableCol>
        <TableCol>{item.description}</TableCol>
        <TableCol>{item.client?.name}</TableCol>
        <TableCol>{item.type.name}</TableCol>
        <TableCol>
          {format(item.from, DATE_TIME_FORMAT)}
          {" - "}
          {format(item.to, TIME_FORMAT)}
        </TableCol>
        <TableCol>
          <NavLink to={`/calendar/list/${item.id}`}>
            <GridIconEdit />
          </NavLink>
        </TableCol>
      </>
    );
  };

  const handleRowClick = (item: IActionItem) => {
    navigate(`/calendar/list/${item.id}`);
  };

  const handleExport = async (
    type: ExportType
  ): Promise<AxiosResponse<Blob>> => {
    const params = listEffectsGetParamsForExport(prov);
    listEffectGetFilter(params, prov.filter);
    addActionParams(params, prov);

    if (type === ExportType.Csv) {
      return await calendarApi.actionListExportCsv(params);
    }

    return await calendarApi.actionListExportXlsx(params);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <AssignUserModal
        isOpen={assignmentIsOpen}
        selectedActionIds={selectedIds}
        close={handleCloseAssignment}
        confirm={handleConfirmAssignment}
      />
      <FilterPanel
        title={t("calendar.list.pageTitle")}
        name="calendarList"
        filter={prov.filter!}
        changeFilter={changeActionListFilter}
        doExport={handleExport}
        initialValues={{
          assignedUsers: prov.filter!.assignedUsers,
          from: prov.filter!.from,
          to: prov.filter!.to,
          notAssigned: prov.filter!.notAssigned,
          clientId: prov.filter!.clientId,
          clientName: prov.filter!.clientName,
        }}
        validationSchema={Yup.object({
          assignedUsers: Yup.array().when("notAssigned", {
            is: (notAssigned: any) => !notAssigned,
            then: validations.arrayMoreThanZero(t),
          }),
        })}
      >
        <CalendarFilter />
      </FilterPanel>
      <Grid<IActionItem>
        headers={[
          {
            captionEl: (
              <>
                {prov.data.length > 1 && (
                  <GridSelectedCheck
                    checked={
                      prov.data
                        .filter((item) => !isOutdated(item))
                        .every((x) => selectedIds.includes(x.id)) &&
                      selectedIds.length !== 0
                    }
                    onChange={handleSelectAll}
                  />
                )}
              </>
            ),
          },
          { captionStr: "calendar.list.user" },
          { captionStr: "calendar.list.description" },
          { captionStr: "calendar.list.client" },
          { captionStr: "calendar.list.type" },
          { captionStr: "calendar.list.date" },
          {
            captionEl: hasRight(identityRights, [
              RightType.WriteCalendarList,
            ]) ? (
              <NavLink to={`/calendar/list/new`}>
                <GridIconAdd />
              </NavLink>
            ) : undefined,
          },
        ]}
        renderData={handleRenderData}
        getData={getActionList}
        getCount={getActionListCount}
        changeOrder={changeActionListOrder}
        changePage={changeActionListPage}
        onRowClick={handleRowClick}
        checkbox={true}
        prov={prov}
      />
      <ActionButton
        ver="secondary"
        disabled={selectedIds.length === 0}
        onClick={handleAssigneUser}
      >
        {t("calendar.list.assigneUser")}
      </ActionButton>
      <ActionButton
        ver="secondary"
        disabled={selectedIds.length === 0}
        onClick={handleDeleteSelectedActions}
      >
        {t("common.delete")}
      </ActionButton>
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    prov: selectActionList(state),
    identityId: selectIdentityId(state),
    identityName: selectIdentityName(state),
    identityRights: selectIdentityRights(state),
  };
};

const mapDispachToProps = {
  getActionList,
  getActionListCount,
  changeActionListOrder,
  changeActionListPage,
  changeActionListFilter,
  actionListLoad,
  actionListSetDefaultFilter,
};

export default connect(mapStateToProps, mapDispachToProps)(CalendarList);
