import { createAction } from "@reduxjs/toolkit";
import { IFolderItem } from "../../models/document";
import { StoreState, StoreStateDontLoad } from "../storeState";
import { ITemplateFolderListFilter, TemplateFolderListTypes } from "./types";

export const templateFolderListFetchRequest = createAction<
  StoreStateDontLoad,
  TemplateFolderListTypes.TEMPLATE_FOLDER_LIST_FETCH_REQUEST
>(TemplateFolderListTypes.TEMPLATE_FOLDER_LIST_FETCH_REQUEST);

export const templateFolderListFetchSuccess = createAction<
  {
    data: IFolderItem[];
    newCountState: StoreStateDontLoad;
    newCount: number;
  },
  TemplateFolderListTypes.TEMPLATE_FOLDER_LIST_FETCH_SUCCESS
>(TemplateFolderListTypes.TEMPLATE_FOLDER_LIST_FETCH_SUCCESS);

export const templateFolderListFetchError = createAction<
  boolean,
  TemplateFolderListTypes.TEMPLATE_FOLDER_LIST_FETCH_ERROR
>(TemplateFolderListTypes.TEMPLATE_FOLDER_LIST_FETCH_ERROR);

export const templateFolderListCountRequest = createAction<
  void,
  TemplateFolderListTypes.TEMPLATE_FOLDER_LIST_COUNT_REQUEST
>(TemplateFolderListTypes.TEMPLATE_FOLDER_LIST_COUNT_REQUEST);

export const templateFolderListCountSuccess = createAction<
  number,
  TemplateFolderListTypes.TEMPLATE_FOLDER_LIST_COUNT_SUCCESS
>(TemplateFolderListTypes.TEMPLATE_FOLDER_LIST_COUNT_SUCCESS);

export const templateFolderListCountError = createAction<
  boolean,
  TemplateFolderListTypes.TEMPLATE_FOLDER_LIST_COUNT_ERROR
>(TemplateFolderListTypes.TEMPLATE_FOLDER_LIST_COUNT_ERROR);

export const templateFolderListChangeOrder = createAction<
  { orderBy: string; orderDesc: boolean },
  TemplateFolderListTypes.TEMPLATE_FOLDER_LIST_CHANGE_ORDER
>(TemplateFolderListTypes.TEMPLATE_FOLDER_LIST_CHANGE_ORDER);

export const templateFolderListChangePage = createAction<
  number,
  TemplateFolderListTypes.TEMPLATE_FOLDER_LIST_CHANGE_PAGE
>(TemplateFolderListTypes.TEMPLATE_FOLDER_LIST_CHANGE_PAGE);

export const templateFolderListChangeFilter = createAction<
  ITemplateFolderListFilter,
  TemplateFolderListTypes.TEMPLATE_FOLDER_LIST_CHANGE_FILTER
>(TemplateFolderListTypes.TEMPLATE_FOLDER_LIST_CHANGE_FILTER);

export const templateFolderListReset = createAction<
  void,
  TemplateFolderListTypes.TEMPLATE_FOLDER_LIST_RESET
>(TemplateFolderListTypes.TEMPLATE_FOLDER_LIST_RESET);

export const templateFolderListLoadAction = createAction<
  { newState: StoreState; newCountState: StoreStateDontLoad },
  TemplateFolderListTypes.TEMPLATE_FOLDER_LIST_LOAD
>(TemplateFolderListTypes.TEMPLATE_FOLDER_LIST_LOAD);
