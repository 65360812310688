import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

export const GridHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0.5rem;
  height: 2rem;
`;

export const GridOrderIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: max-content;
  margin: 0;
`;

export const GridOrderIcon = styled(FontAwesomeIcon)`
  margin-left: 0.2rem;
  color: var(--table-order-color);
  cursor: pointer;

  &:hover {
    color: var(--table-order-color-hover);
  }

  &.active {
    color: var(--table-order-color-active);
  }
`;

export const GridSelectedCheckInput = styled.input`
  margin-right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
`;
