import * as ReselectModule from "reselect";
import { IApplicationState } from "..";
import { IDemandHistory } from "../../models/demand";
import { StoreState } from "../storeState";

export const selectDemandHistoryState: ReselectModule.Selector<
  IApplicationState,
  StoreState
> = (state) => state.demandHistory.state;

export const selectDemandHistory: ReselectModule.Selector<
  IApplicationState,
  IDemandHistory | null
> = (state) => state.demandHistory.data;
