import { format } from "date-fns";
import { FC, ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { ILogItem, LogType } from "../../models/log";
import { handleDates } from "../../utils/api";
import { DATE_TIME_FORMAT } from "../../utils/consts";
import { GridIconCollapse, GridIconExpand } from "../common/grid/GridIcons";
import { SmallTable } from "../common/grid/table";
import {
  AdminLogDescriptionCol,
  AdminLogDescriptionContainer,
  ExpandControlWrapper,
} from "./AdminLogDescriptionStyles";

interface IProps {
  item: ILogItem;
}

const AdminLogDescription: FC<IProps> = ({ item }) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);

  const getDescription = (): string => {
    return item.type + ": " + item.entityName;
  };

  const dataFilter = (value: any): boolean => {
    if (typeof value === "number") {
      return true;
    }

    if (typeof value === "boolean") {
      return true;
    }

    return !!value;
  };

  const valueFormat = (value: any) => {
    if (typeof value === "boolean") {
      if (value) {
        return t("common.yes");
      }

      return t("common.no");
    }

    if (value instanceof Date) {
      return format(value, DATE_TIME_FORMAT);
    }

    return value;
  };

  const getDataArray = (values: any): ReactElement => {
    const columns = [] as string[];
    for (const val of values) {
      columns.push(...Object.keys(val).filter((x) => !columns.includes(x)));
    }

    return (
      <SmallTable>
        <thead>
          <tr>
            {columns.map((x) => (
              <th key={x}>{x}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {values.map((val: any, i: number) => (
            <tr key={i}>
              {columns.map((col) => (
                <td key={col}>{valueFormat(val[col])}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </SmallTable>
    );
  };

  const getData = (): ReactElement | null => {
    if (item.type === LogType.DocumentChange) {
      return <>{item.changes}</>;
    }

    if (
      item.type !== LogType.EntityCreated &&
      item.type !== LogType.EntityDeleted &&
      item.type !== LogType.EntityChange &&
      item.type !== LogType.EntityArrayUpdated &&
      item.type !== LogType.SeachForClient
    ) {
      return null;
    }

    const data = JSON.parse(item.changes!);
    handleDates(data);

    switch (item.type) {
      case LogType.SeachForClient:
        return (
          <SmallTable>
            <thead>
              <tr>
                <th>{t("admin.log.changeName")}</th>
                <th>{t("admin.log.changeValue")}</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(data)
                .filter((key) => dataFilter(data[key]))
                .map((key) => (
                  <tr key={key}>
                    <td>{key}</td>
                    <td>{valueFormat(data[key])}</td>
                  </tr>
                ))}
            </tbody>
          </SmallTable>
        );
      case LogType.EntityCreated:
      case LogType.EntityDeleted:
        return (
          <SmallTable>
            <thead>
              <tr>
                <th>{t("admin.log.changeName")}</th>
                <th>{t("admin.log.changeValue")}</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(data)
                .filter((key) => dataFilter(data[key]))
                .map((key) => (
                  <tr key={key}>
                    <td>{key}</td>
                    <td>{valueFormat(data[key])}</td>
                  </tr>
                ))}
            </tbody>
          </SmallTable>
        );
      case LogType.EntityChange:
        return (
          <SmallTable>
            <thead>
              <tr>
                <th>{t("admin.log.changeName")}</th>
                <th>{t("admin.log.changeOldValue")}</th>
                <th>{t("admin.log.changeNewValue")}</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(data)
                .filter(
                  (key) =>
                    dataFilter(data[key].old) || dataFilter(data[key].new)
                )
                .map((key) => (
                  <tr key={key}>
                    <td>{key}</td>
                    <td>{valueFormat(data[key].old)}</td>
                    <td>{valueFormat(data[key].new)}</td>
                  </tr>
                ))}
            </tbody>
          </SmallTable>
        );
      case LogType.EntityArrayUpdated:
        return (
          <>
            {!!data.new && (
              <>
                {t("admin.log.added")}
                {getDataArray(data.new)}
              </>
            )}
            {!!data.old && (
              <>
                {t("admin.log.deleted")}
                {getDataArray(data.old)}
              </>
            )}
          </>
        );
    }
  };

  return (
    <AdminLogDescriptionCol>
      <AdminLogDescriptionContainer>
        <div>{getDescription()}</div>
        <ExpandControlWrapper>
          {isExpanded ? (
            <GridIconCollapse
              onClick={() => setIsExpanded((value) => !value)}
            />
          ) : (
            <GridIconExpand onClick={() => setIsExpanded((value) => !value)} />
          )}
        </ExpandControlWrapper>
      </AdminLogDescriptionContainer>
      {isExpanded && <div>{getData()}</div>}
    </AdminLogDescriptionCol>
  );
};

export default AdminLogDescription;
