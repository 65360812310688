import { FC, useState } from "react";
import styled from "styled-components";
import { GridIconCollapse, GridIconExpand } from "./GridIcons";
import { COLORS } from "../../../styles/colors";
import RepairLineBreaks from "../RepairLineBreaks";

export const TableColContainer = styled.div`
  display: flex;
`;

interface TableColProps {
  isPublic?: boolean;
}

export const TableCol: FC<TableColProps> = ({ children, isPublic = false }) => {
  return (
    <td
      style={{
        background: isPublic ? COLORS.pastelGreen : "",
      }}
    >
      <TableColContainer>{children}</TableColContainer>
    </td>
  );
};

export const TableColCenterContainer = styled.div`
  margin: 0 auto;
  width: max-content;
`;

export const TableColCenter: FC = ({ children }) => {
  return (
    <td>
      <TableColCenterContainer>{children}</TableColCenterContainer>
    </td>
  );
};

export const TableColInput: FC = ({ children }) => {
  return (
    <td>
      <div className="width100">{children}</div>
    </td>
  );
};

export const TableColNoWrap: FC = ({ children }) => {
  return (
    <td>
      <div className="nowrap">{children}</div>
    </td>
  );
};

export const ExpandControlWrapper = styled.div`
  align-self: start;
`;

interface TableColExtraTextProps {
  text1: string;
  text2: string;
}

export const TableColExtraText: FC<TableColExtraTextProps> = ({
  text1,
  text2,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => setIsExpanded((prevValue) => !prevValue);

  const textClassName = isExpanded ? "width100" : "extra-text";

  return (
    <>
      <td style={{ width: "25%" }}>
        <div className={textClassName}>
          <RepairLineBreaks text={text1} />
        </div>
      </td>
      <td>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className={textClassName}>
            <RepairLineBreaks text={text2} />
          </div>
          <ExpandControlWrapper style={{ width: "2.2rem" }}>
            {isExpanded ? (
              <GridIconCollapse onClick={toggleExpanded} />
            ) : (
              <GridIconExpand onClick={toggleExpanded} />
            )}
          </ExpandControlWrapper>
        </div>
      </td>
    </>
  );
};
