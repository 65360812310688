import { createAction } from "@reduxjs/toolkit";
import { IDefaultDocumentDocumentItem } from "../../models/defaultDocument";
import { StoreState, StoreStateDontLoad } from "../storeState";
import {
  DefaultDocumentDocumentListTypes,
  IDefaultDocumentDocumentListFilter,
} from "./types";

export const defaultDocumentDocumentListFetchRequest = createAction<
  StoreStateDontLoad,
  DefaultDocumentDocumentListTypes.DEFAULT_DOCUMENT_DOCUMENT_LIST_FETCH_REQUEST
>(
  DefaultDocumentDocumentListTypes.DEFAULT_DOCUMENT_DOCUMENT_LIST_FETCH_REQUEST
);

export const defaultDocumentDocumentListFetchSuccess = createAction<
  {
    data: IDefaultDocumentDocumentItem[];
    newCountState: StoreStateDontLoad;
    newCount: number;
  },
  DefaultDocumentDocumentListTypes.DEFAULT_DOCUMENT_DOCUMENT_LIST_FETCH_SUCCESS
>(
  DefaultDocumentDocumentListTypes.DEFAULT_DOCUMENT_DOCUMENT_LIST_FETCH_SUCCESS
);

export const defaultDocumentDocumentListFetchError = createAction<
  boolean,
  DefaultDocumentDocumentListTypes.DEFAULT_DOCUMENT_DOCUMENT_LIST_FETCH_ERROR
>(DefaultDocumentDocumentListTypes.DEFAULT_DOCUMENT_DOCUMENT_LIST_FETCH_ERROR);

export const defaultDocumentDocumentListCountRequest = createAction<
  void,
  DefaultDocumentDocumentListTypes.DEFAULT_DOCUMENT_DOCUMENT_LIST_COUNT_REQUEST
>(
  DefaultDocumentDocumentListTypes.DEFAULT_DOCUMENT_DOCUMENT_LIST_COUNT_REQUEST
);

export const defaultDocumentDocumentListCountSuccess = createAction<
  number,
  DefaultDocumentDocumentListTypes.DEFAULT_DOCUMENT_DOCUMENT_LIST_COUNT_SUCCESS
>(
  DefaultDocumentDocumentListTypes.DEFAULT_DOCUMENT_DOCUMENT_LIST_COUNT_SUCCESS
);

export const defaultDocumentDocumentListCountError = createAction<
  boolean,
  DefaultDocumentDocumentListTypes.DEFAULT_DOCUMENT_DOCUMENT_LIST_COUNT_ERROR
>(DefaultDocumentDocumentListTypes.DEFAULT_DOCUMENT_DOCUMENT_LIST_COUNT_ERROR);

export const defaultDocumentDocumentListChangeOrder = createAction<
  { orderBy: string; orderDesc: boolean },
  DefaultDocumentDocumentListTypes.DEFAULT_DOCUMENT_DOCUMENT_LIST_CHANGE_ORDER
>(DefaultDocumentDocumentListTypes.DEFAULT_DOCUMENT_DOCUMENT_LIST_CHANGE_ORDER);

export const defaultDocumentDocumentListChangePage = createAction<
  number,
  DefaultDocumentDocumentListTypes.DEFAULT_DOCUMENT_DOCUMENT_LIST_CHANGE_PAGE
>(DefaultDocumentDocumentListTypes.DEFAULT_DOCUMENT_DOCUMENT_LIST_CHANGE_PAGE);

export const defaultDocumentDocumentListChangeFilter = createAction<
  IDefaultDocumentDocumentListFilter,
  DefaultDocumentDocumentListTypes.DEFAULT_DOCUMENT_DOCUMENT_LIST_CHANGE_FILTER
>(
  DefaultDocumentDocumentListTypes.DEFAULT_DOCUMENT_DOCUMENT_LIST_CHANGE_FILTER
);

export const defaultDocumentDocumentListReset = createAction<
  void,
  DefaultDocumentDocumentListTypes.DEFAULT_DOCUMENT_DOCUMENT_LIST_RESET
>(DefaultDocumentDocumentListTypes.DEFAULT_DOCUMENT_DOCUMENT_LIST_RESET);

export const defaultDocumentDocumentListLoadAction = createAction<
  { newState: StoreState; newCountState: StoreStateDontLoad },
  DefaultDocumentDocumentListTypes.DEFAULT_DOCUMENT_DOCUMENT_LIST_LOAD
>(DefaultDocumentDocumentListTypes.DEFAULT_DOCUMENT_DOCUMENT_LIST_LOAD);
