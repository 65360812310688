import { createAction } from "@reduxjs/toolkit";
import { IClientRevenueItem } from "../../models/client";
import { IFilterType } from "../filterType";
import { StoreState, StoreStateDontLoad } from "../storeState";
import { ClientRevenueListTypes } from "./types";

export const clientRevenueListFetchRequest = createAction<
  StoreStateDontLoad,
  ClientRevenueListTypes.CLIENT_REVENUE_LIST_FETCH_REQUEST
>(ClientRevenueListTypes.CLIENT_REVENUE_LIST_FETCH_REQUEST);

export const clientRevenueListFetchSuccess = createAction<
  {
    data: IClientRevenueItem[];
    newCountState: StoreStateDontLoad;
    newCount: number;
  },
  ClientRevenueListTypes.CLIENT_REVENUE_LIST_FETCH_SUCCESS
>(ClientRevenueListTypes.CLIENT_REVENUE_LIST_FETCH_SUCCESS);

export const clientRevenueListFetchError = createAction<
  boolean,
  ClientRevenueListTypes.CLIENT_REVENUE_LIST_FETCH_ERROR
>(ClientRevenueListTypes.CLIENT_REVENUE_LIST_FETCH_ERROR);

export const clientRevenueListCountRequest = createAction<
  void,
  ClientRevenueListTypes.CLIENT_REVENUE_LIST_COUNT_REQUEST
>(ClientRevenueListTypes.CLIENT_REVENUE_LIST_COUNT_REQUEST);

export const clientRevenueListCountSuccess = createAction<
  number,
  ClientRevenueListTypes.CLIENT_REVENUE_LIST_COUNT_SUCCESS
>(ClientRevenueListTypes.CLIENT_REVENUE_LIST_COUNT_SUCCESS);

export const clientRevenueListCountError = createAction<
  boolean,
  ClientRevenueListTypes.CLIENT_REVENUE_LIST_COUNT_ERROR
>(ClientRevenueListTypes.CLIENT_REVENUE_LIST_COUNT_ERROR);

export const clientRevenueListChangeOrder = createAction<
  { orderBy: string; orderDesc: boolean },
  ClientRevenueListTypes.CLIENT_REVENUE_LIST_CHANGE_ORDER
>(ClientRevenueListTypes.CLIENT_REVENUE_LIST_CHANGE_ORDER);

export const clientRevenueListChangePage = createAction<
  number,
  ClientRevenueListTypes.CLIENT_REVENUE_LIST_CHANGE_PAGE
>(ClientRevenueListTypes.CLIENT_REVENUE_LIST_CHANGE_PAGE);

export const clientRevenueListChangeFilter = createAction<
  IFilterType,
  ClientRevenueListTypes.CLIENT_REVENUE_LIST_CHANGE_FILTER
>(ClientRevenueListTypes.CLIENT_REVENUE_LIST_CHANGE_FILTER);

export const clientRevenueListReset = createAction<
  void,
  ClientRevenueListTypes.CLIENT_REVENUE_LIST_RESET
>(ClientRevenueListTypes.CLIENT_REVENUE_LIST_RESET);

export const clientRevenueListLoadAction = createAction<
  { newState: StoreState; newCountState: StoreStateDontLoad },
  ClientRevenueListTypes.CLIENT_REVENUE_LIST_LOAD
>(ClientRevenueListTypes.CLIENT_REVENUE_LIST_LOAD);
