import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { SIZES } from "../../styles/sizes";

export const DashboardLinksContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 14rem);
  column-gap: 1rem;
  row-gap: 1rem;

  @media screen and (min-width: ${SIZES.mobile}) {
    grid-template-columns: repeat(2, 14rem);
  }

  @media screen and (min-width: ${SIZES.tablet}) {
    grid-template-columns: repeat(3, 14rem);
  }

  @media screen and (min-width: ${SIZES.laptop}) {
    grid-template-columns: repeat(4, 14rem);
  }

  @media screen and (min-width: ${SIZES.desktop}) {
    grid-template-columns: repeat(5, 14rem);
  }
`;

//todo: color!
const dashboardLinkContainerStyle = `
  display: block;
  width: 11rem;
  height: 10rem;
  background-color: #ffffff;
  border: 1px solid #3b3b3b;
  border-radius: 26px;
  text-align: center;
  display: flex;
  align-items: center;
  text-decoration: none;
`;

export const DashboardLinkContainer = styled(NavLink)`
  ${dashboardLinkContainerStyle}
`;

export const DashboardExternalLinkContainer = styled.a`
  ${dashboardLinkContainerStyle}
`;

export const DashBoardLinkGroup = styled.div`
  margin: 0 auto;
`;

export const DashBoardLinkIcon = styled(FontAwesomeIcon)``;

//todo: color
export const DashBoardLinkText = styled.div`
  margin-top: 0.2rem;
  font-size: 1.2rem;
  color: #3b3b3b;
`;
