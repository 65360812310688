import { IListingItem, ListingType } from "../../models/listing";
import { IFilterType } from "../filterType";
import { IListType } from "../listType";

export enum ListingListTypes {
  LISTING_LIST_FETCH_REQUEST = "@@listingList/FETCH_REQUEST",
  LISTING_LIST_FETCH_SUCCESS = "@@listingList/FETCH_SUCCESS",
  LISTING_LIST_FETCH_ERROR = "@@listingList/FETCH_ERROR",
  LISTING_LIST_COUNT_REQUEST = "@@listingList/COUNT_REQUEST",
  LISTING_LIST_COUNT_SUCCESS = "@@listingList/COUNT_SUCCESS",
  LISTING_LIST_COUNT_ERROR = "@@listingList/COUNT_ERROR",
  LISTING_LIST_CHANGE_ORDER = "@@listingList/CHANGE_ORDER",
  LISTING_LIST_CHANGE_PAGE = "@@listingList/CHANGE_PAGE",
  LISTING_LIST_CHANGE_FILTER = "@@listingList/CHANGE_FILTER",
  LISTING_LIST_RESET = "@@listingList/RESET",
  LISTING_LIST_LOAD = "@@listingList/LOAD",
}

export interface IListingListItems
  extends IListType<IListingItem, IFilterType> {}

export interface IListingListState {
  types: Record<ListingType, IListingListItems>;
}
