import * as ReselectModule from "reselect";
import { IApplicationState } from "..";
import { IAction } from "../../models/calendar";
import { StoreState } from "../storeState";

export const selectActionState: ReselectModule.Selector<
  IApplicationState,
  StoreState
> = (state) => state.action.state;

export const selectAction: ReselectModule.Selector<
  IApplicationState,
  IAction | null
> = (state) => state.action.data;
