import { AxiosRequestConfig, AxiosResponse } from "axios";
import { IItemIdName } from "../models/common";
import { IFolderItem } from "../models/document";
import {
  ITemplateDocumentItem,
  TemplateDocumentType,
} from "../models/template";
import api from "../utils/api";

const getFolderList = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<IFolderItem[]>> =>
  api.get(`/api/Documents/Templates/Folders`, config);

const getFolderCount = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<number>> =>
  api.get(`/api/Documents/Templates/Folders/Count`, config);

const getFolder = async (
  folderId: string
): Promise<AxiosResponse<IItemIdName[]>> =>
  api.get(`/api/Documents/Templates/Folder/${folderId}/Path`);

const createFolder = async (
  parentId: string,
  data: IFolderItem
): Promise<AxiosResponse<IFolderItem>> => {
  const params = new URLSearchParams();
  if (parentId) {
    params.append("parentId", parentId);
  }
  return api.post(`/api/Documents/Templates/Folder`, data, { params });
};

const updateFolder = async (
  folderId: string,
  data: IFolderItem
): Promise<AxiosResponse<IFolderItem>> =>
  api.put(`/api/Documents/Templates/Folder/${folderId}`, data);

const getDocumentList = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<ITemplateDocumentItem[]>> =>
  api.get(`/api/Documents/Templates`, config);

const getDocumentCount = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<number>> =>
  api.get(`/api/Documents/Templates/Count`, config);

const getDocumentUploadUrl = async (
  fileName: string | null,
  folderId: string | null,
  documentId: string | null
): Promise<AxiosResponse<string>> => {
  const params = new URLSearchParams();

  if (fileName) {
    params.append("fileName", fileName);
  }

  if (folderId) {
    params.append("folderId", folderId);
  }

  if (documentId) {
    params.append("documentId", documentId);
  }

  return api.get(`/api/Document/Template/UploadUrl`, { params });
};

const addDocument = async (
  fileName: string,
  folderId: string | null,
  usedFor: TemplateDocumentType
): Promise<AxiosResponse<string>> => {
  const params = new URLSearchParams();
  params.append("fileName", fileName);

  if (folderId) {
    params.append("folderId", folderId);
  }

  params.append("usedFor", usedFor);

  return api.post(`/api/Document/Template`, undefined, { params });
};

const getDocumentDownloadUrl = async (
  documentId: number
): Promise<AxiosResponse<string>> =>
  api.get(`/api/Document/Template/${documentId}/Download`);

const updateDocument = async (
  documentId: number
): Promise<AxiosResponse<string>> =>
  api.put(`/api/Document/Template/${documentId}`);

const templateApi = {
  getFolderList,
  getFolderCount,
  getFolder,
  createFolder,
  updateFolder,
  getDocumentList,
  getDocumentCount,
  getDocumentUploadUrl,
  addDocument,
  getDocumentDownloadUrl,
  updateDocument,
};

export default templateApi;
