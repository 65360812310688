import { AppThunkAction, AppThunkActionAsync } from "..";
import * as actions from "./actions";
import clientApi from "../../api/client";
import { selectClientActionList } from ".";
import { IFilterType } from "../filterType";
import { CancelToken } from "axios";
import {
  listEffectCountFilter,
  listEffectGetFilter,
  listEffectLoad,
  listEffectReload,
  listEffectsCountCheck,
  listEffectsGetCheck,
  listEffectsGetCount,
  listEffectsGetNew,
  listEffectsGetParams,
} from "../listEffect";

export function getClientActionList(
  clientId: number,
  cancelToken: CancelToken
): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectClientActionList(getState());
    if (listEffectsGetCheck(state.state)) {
      return;
    }

    let newState = listEffectsGetNew(state);
    dispatch(actions.clientActionListFetchRequest(newState.newCountState));
    try {
      const params = listEffectsGetParams(state, newState.take);
      listEffectGetFilter(params, state.filter);

      const clientActions = await clientApi.getClientActionList(clientId, {
        params,
        cancelToken,
      });
      cancelToken.throwIfRequested();
      newState = listEffectsGetCount(newState, clientActions);

      dispatch(
        actions.clientActionListFetchSuccess({
          data: clientActions.data,
          newCountState: newState.newCountState,
          newCount: newState.newCount,
        })
      );
    } catch {
      dispatch(
        actions.clientActionListFetchError(cancelToken.reason !== undefined)
      );
    }
  };
}

export function getClientActionListCount(
  clientId: number,
  cancelToken: CancelToken
): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectClientActionList(getState());
    if (listEffectsCountCheck(state.countState)) {
      return;
    }

    dispatch(actions.clientActionListCountRequest());
    try {
      const params = listEffectCountFilter(state.filter);
      const count = await clientApi.getClientActionCount(clientId, {
        params,
        cancelToken,
      });
      cancelToken.throwIfRequested();

      dispatch(actions.clientActionListCountSuccess(count.data));
    } catch {
      dispatch(
        actions.clientActionListCountError(cancelToken.reason !== undefined)
      );
    }
  };
}

export function changeClientActionListOrder(
  orderBy: string,
  orderDesc: boolean
): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.clientActionListChangeOrder({ orderBy, orderDesc }));
  };
}

export function changeClientActionListPage(page: number): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.clientActionListChangePage(page));
  };
}

export function changeClientActionListFilter(
  filter: IFilterType
): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.clientActionListChangeFilter(filter));
  };
}

export function clientActionListLoad(reload: boolean): AppThunkAction {
  return async (dispatch, getState) => {
    const state = selectClientActionList(getState());
    reload = listEffectReload(reload, state);
    if (reload) {
      dispatch(actions.clientActionListReset());
    } else {
      const newState = listEffectLoad(state);
      dispatch(
        actions.clientActionListLoadAction({
          newState: newState.newState,
          newCountState: newState.newCountState,
        })
      );
    }
  };
}
