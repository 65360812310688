import { IClientBenefitItem } from "../../models/client";
import { IFilterType } from "../filterType";
import { IListType } from "../listType";

export enum ClientBenefitListTypes {
  CLIENT_BENEFIT_LIST_FETCH_REQUEST = "@@clientBenefitList/FETCH_REQUEST",
  CLIENT_BENEFIT_LIST_FETCH_SUCCESS = "@@clientBenefitList/FETCH_SUCCESS",
  CLIENT_BENEFIT_LIST_FETCH_ERROR = "@@clientBenefitList/FETCH_ERROR",
  CLIENT_BENEFIT_LIST_COUNT_REQUEST = "@@clientBenefitList/COUNT_REQUEST",
  CLIENT_BENEFIT_LIST_COUNT_SUCCESS = "@@clientBenefitList/COUNT_SUCCESS",
  CLIENT_BENEFIT_LIST_COUNT_ERROR = "@@clientBenefitList/COUNT_ERROR",
  CLIENT_BENEFIT_LIST_CHANGE_ORDER = "@@clientBenefitList/CHANGE_ORDER",
  CLIENT_BENEFIT_LIST_CHANGE_PAGE = "@@clientBenefitList/CHANGE_PAGE",
  CLIENT_BENEFIT_LIST_CHANGE_FILTER = "@@clientBenefitList/CHANGE_FILTER",
  CLIENT_BENEFIT_LIST_RESET = "@@clientBenefitList/RESET",
  CLIENT_BENEFIT_LIST_LOAD = "@@clientBenefitList/LOAD",
}

export interface IClientBenefitListState
  extends IListType<IClientBenefitItem, IFilterType> {}
