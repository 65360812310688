import { FC, Fragment } from "react";

interface IProps {
  text: string;
}

const RepairLineBreaks: FC<IProps> = ({ text }) => {
  return (
    <>
      {text
        .replaceAll("\r\n", "\n")
        .replaceAll("\r", "\n")
        .split("\n")
        .map((x, i) => (
          <Fragment key={i}>
            {x}
            <br />
          </Fragment>
        ))}
    </>
  );
};

export default RepairLineBreaks;
