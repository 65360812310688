import { ICareTypeItem } from "../../models/careType";
import { IFilterType } from "../filterType";
import { IListType } from "../listType";

export enum CareTypeListTypes {
  CARE_TYPE_LIST_FETCH_REQUEST = "@@careTypeList/FETCH_REQUEST",
  CARE_TYPE_LIST_FETCH_SUCCESS = "@@careTypeList/FETCH_SUCCESS",
  CARE_TYPE_LIST_FETCH_ERROR = "@@careTypeList/FETCH_ERROR",
  CARE_TYPE_LIST_COUNT_REQUEST = "@@careTypeList/COUNT_REQUEST",
  CARE_TYPE_LIST_COUNT_SUCCESS = "@@careTypeList/COUNT_SUCCESS",
  CARE_TYPE_LIST_COUNT_ERROR = "@@careTypeList/COUNT_ERROR",
  CARE_TYPE_LIST_CHANGE_ORDER = "@@careTypeList/CHANGE_ORDER",
  CARE_TYPE_LIST_CHANGE_PAGE = "@@careTypeList/CHANGE_PAGE",
  CARE_TYPE_LIST_CHANGE_FILTER = "@@careTypeList/CHANGE_FILTER",
  CARE_TYPE_LIST_RESET = "@@careTypeList/RESET",
  CARE_TYPE_LIST_LOAD = "@@careTypeList/LOAD",
}

export interface ICareTypeListFilter extends IFilterType {
  assistanceCategoryListingId?: number;
  assistanceCategoryListingName?: string;
}

export interface ICareTypeListState
  extends IListType<ICareTypeItem, ICareTypeListFilter> {}
