import { AppThunkAction, AppThunkActionAsync } from "..";
import * as actions from "./actions";
import workflowApi from "../../api/workflow";
import { WorkflowPriorityType, WorkflowType } from "../../models/workflow";

export function getWorkflow(workflowId: number): AppThunkActionAsync {
  return async (dispatch) => {
    dispatch(actions.workflowFetchRequest());
    try {
      const workflow = await workflowApi.getWorkflow(workflowId);
      dispatch(actions.workflowFetchSuccess(workflow.data));
    } catch {
      dispatch(actions.workflowFetchError());
    }
  };
}

export function getDefaultWorkflow(): AppThunkAction {
  return (dispatch) => {
    const data = {
      id: 0,
      isActive: true,
      name: "",
      type: WorkflowType.Standard,
      priority: WorkflowPriorityType.Medium,

      categoryId: 0,
      categoryName: "",

      definition: [],
    };

    dispatch(actions.workflowFetchSuccess(data));
  };
}
