import { createAction } from "@reduxjs/toolkit";
import { IRoleItem } from "../../models/role";
import { IFilterType } from "../filterType";
import { StoreState, StoreStateDontLoad } from "../storeState";
import { RoleListTypes } from "./types";

export const roleListFetchRequest = createAction<
  StoreStateDontLoad,
  RoleListTypes.ROLE_LIST_FETCH_REQUEST
>(RoleListTypes.ROLE_LIST_FETCH_REQUEST);

export const roleListFetchSuccess = createAction<
  { data: IRoleItem[]; newCountState: StoreStateDontLoad; newCount: number },
  RoleListTypes.ROLE_LIST_FETCH_SUCCESS
>(RoleListTypes.ROLE_LIST_FETCH_SUCCESS);

export const roleListFetchError = createAction<
  boolean,
  RoleListTypes.ROLE_LIST_FETCH_ERROR
>(RoleListTypes.ROLE_LIST_FETCH_ERROR);

export const roleListCountRequest = createAction<
  void,
  RoleListTypes.ROLE_LIST_COUNT_REQUEST
>(RoleListTypes.ROLE_LIST_COUNT_REQUEST);

export const roleListCountSuccess = createAction<
  number,
  RoleListTypes.ROLE_LIST_COUNT_SUCCESS
>(RoleListTypes.ROLE_LIST_COUNT_SUCCESS);

export const roleListCountError = createAction<
  boolean,
  RoleListTypes.ROLE_LIST_COUNT_ERROR
>(RoleListTypes.ROLE_LIST_COUNT_ERROR);

export const roleListChangeOrder = createAction<
  { orderBy: string; orderDesc: boolean },
  RoleListTypes.ROLE_LIST_CHANGE_ORDER
>(RoleListTypes.ROLE_LIST_CHANGE_ORDER);

export const roleListChangePage = createAction<
  number,
  RoleListTypes.ROLE_LIST_CHANGE_PAGE
>(RoleListTypes.ROLE_LIST_CHANGE_PAGE);

export const roleListChangeFilter = createAction<
  IFilterType,
  RoleListTypes.ROLE_LIST_CHANGE_FILTER
>(RoleListTypes.ROLE_LIST_CHANGE_FILTER);

export const roleListReset = createAction<void, RoleListTypes.ROLE_LIST_RESET>(
  RoleListTypes.ROLE_LIST_RESET
);

export const roleListLoadAction = createAction<
  { newState: StoreState; newCountState: StoreStateDontLoad },
  RoleListTypes.ROLE_LIST_LOAD
>(RoleListTypes.ROLE_LIST_LOAD);
