import { FC } from "react";
import { GridSelectedCheckInput } from "./GridStyles";

export const GridSelectedCheck: FC<{
  checked: boolean;
  onChange(): void;
  disabled?: boolean;
}> = ({ checked, onChange, disabled = false }) => {
  return (
    <GridSelectedCheckInput
      type="checkbox"
      checked={checked}
      onChange={onChange}
      onClick={(e) => e.stopPropagation()}
      disabled={disabled}
    />
  );
};
