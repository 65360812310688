import { Form, Formik, FormikProps } from "formik";
import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../styles/button";
import CustomModal from "../common/modal/CustomModal";
import * as Yup from "yup";
import validations from "../../utils/validations";
import { ApiError, FormGroup } from "../../styles/form";
import { promiseToastSave } from "../../utils/toasts";
import { IItemIdName } from "../../models/common";
import SuggestionListFormik from "../common/suggestion/SuggestionListFormik";
import {
  handleLoadAssistanceSuggestions,
  handleLoadGroupSuggestions,
  handleLoadRoleSuggestions,
  handleLoadUserSuggestions,
} from "../../utils/suggestions";
import Input from "../common/form/Input";
import { errorSet } from "../../utils/error";
import { AxiosResponse } from "axios";
import { IFolderItem } from "../../models/document";

export interface IProps {
  folder: IFolderItem;
  parentId: string;
  isAdding: boolean;
  isOpen: boolean;
  showIsClientVisible: boolean;
  createFolder(
    parentId: string,
    data: IFolderItem | IFolderItem
  ): Promise<AxiosResponse<IFolderItem>>;
  updateFolder(
    folderId: string,
    data: IFolderItem | IFolderItem
  ): Promise<AxiosResponse<IFolderItem>>;
  close(): void;
  confirm(): void;
}

const ModuleDocumentFolderModal: FC<IProps> = ({
  folder,
  parentId,
  isAdding,
  isOpen,
  showIsClientVisible,
  createFolder,
  updateFolder,
  close,
  confirm,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);

  const formikRef = useRef<FormikProps<IFolderItem> | null>(null);

  useEffect(() => {
    setError(null);
    formikRef.current?.resetForm();
  }, [isOpen]);

  const handleSubmit = async (data: IFolderItem) => {
    setError(null);

    try {
      await promiseToastSave(async () => {
        if (isAdding) {
          await createFolder(parentId, data);
        } else {
          await updateFolder(folder.id.toString(), data);
        }
      });
      confirm();
    } catch (err) {
      errorSet(setError, err, t);
    }
  };

  return (
    <Formik<IFolderItem>
      innerRef={formikRef}
      initialValues={folder}
      validationSchema={Yup.object().shape(
        {
          name: validations.stringRequired(t),

          users: Yup.array().when(["groups", "roles", "assistances"], {
            is: (
              groups: IItemIdName[],
              roles: IItemIdName[],
              assistances: IItemIdName[]
            ) => {
              return groups?.length + roles?.length + assistances?.length === 0;
            },
            then: Yup.array()
              .required(t("module.document.rights.error"))
              .min(1, t("module.document.rights.requiredError")),
          }),
          groups: Yup.array().when(["users", "roles", "assistances"], {
            is: (
              users: IItemIdName[],
              roles: IItemIdName[],
              assistances: IItemIdName[]
            ) => users?.length + roles?.length + assistances?.length === 0,
            then: Yup.array()
              .required(t("module.document.rights.error"))
              .min(1, t("module.document.rights.requiredError")),
          }),
          roles: Yup.array().when(["users", "groups", "assistances"], {
            is: (
              users: IItemIdName[],
              groups: IItemIdName[],
              assistances: IItemIdName[]
            ) => users?.length + groups?.length + assistances?.length === 0,
            then: Yup.array()
              .required(t("module.document.rights.error"))
              .min(1, t("module.document.rights.requiredError")),
          }),
          assistances: Yup.array().when(["users", "groups", "roles"], {
            is: (
              users: IItemIdName[],
              groups: IItemIdName[],
              roles: IItemIdName[]
            ) => users?.length + groups?.length + roles?.length === 0,
            then: Yup.array()
              .required(t("module.document.rights.error"))
              .min(1, t("module.document.rights.requiredError")),
          }),
        },
        [
          ["users", "groups"],
          ["users", "roles"],
          ["users", "assistances"],
          ["groups", "roles"],
          ["groups", "assistances"],
          ["roles", "assistances"],
        ]
      )}
      validateOnMount={true}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({ isSubmitting, isValid, submitForm, touched, errors }) => (
        <CustomModal
          title={t(
            "module.document.folder.modal." +
              (isAdding ? "addTitle" : "editTitle")
          )}
          isOpen={isOpen}
          close={close}
          actions={
            <>
              <Button
                ver="secondary"
                disabled={isSubmitting}
                onClick={() => close()}
              >
                {t("common.cancel")}
              </Button>
              <Button
                disabled={!isValid || isSubmitting}
                onClick={() => submitForm()}
              >
                {t("common.save")}
              </Button>
            </>
          }
        >
          <Form>
            <FormGroup labelWidth="7rem">
              <Input
                name="name"
                key="name"
                label={t("module.document.folder.name")}
                error={touched.name && !!errors.name}
                disabled={!isAdding}
                maxLength={255}
              />

              <hr />
              <h2>{t("module.document.rights.title")}</h2>

              <SuggestionListFormik
                nameList="users"
                nameId="id"
                nameText="name"
                label={t("module.document.rights.users")}
                loadSuggestions={handleLoadUserSuggestions}
              />

              <SuggestionListFormik
                nameList="groups"
                nameId="id"
                nameText="name"
                label={t("module.document.rights.groups")}
                loadSuggestions={handleLoadGroupSuggestions}
              />

              <SuggestionListFormik
                nameList="roles"
                nameId="id"
                nameText="name"
                label={t("module.document.rights.roles")}
                loadSuggestions={handleLoadRoleSuggestions}
              />

              <SuggestionListFormik
                nameList="assistances"
                nameId="id"
                nameText="name"
                label={t("module.document.rights.assistances")}
                loadSuggestions={handleLoadAssistanceSuggestions}
              />

              {showIsClientVisible && (
                <Input
                  name="isClientVisible"
                  type="checkbox"
                  label={t(`module.document.rights.isClientVisible`)}
                  inputWidth="1.5rem"
                  inputHeight="1.5rem"
                />
              )}

              {error && <ApiError>{error}</ApiError>}
            </FormGroup>
          </Form>
        </CustomModal>
      )}
    </Formik>
  );
};

export default ModuleDocumentFolderModal;
