import { CancelToken } from "axios";
import { FC, ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { RightType } from "../../models/auth";
import { IRoleItem } from "../../models/role";
import { IApplicationState } from "../../store";
import { IFilterType } from "../../store/filterType";
import { selectIdentityRights } from "../../store/identity";
import {
  changeRoleListFilter,
  changeRoleListOrder,
  changeRoleListPage,
  getRoleList,
  getRoleListCount,
  IRoleListState,
  selectRoleList,
  roleListLoad,
} from "../../store/roleList";
import { hasRight } from "../../utils/rights";
import FilterPanel from "../common/filter/FilterPanel";
import Grid from "../common/grid/Grid";
import { GridIconAdd, GridIconEdit } from "../common/grid/GridIcons";
import { TableCol } from "../common/grid/TableCol";

interface IProps {
  prov: IRoleListState;
  identityRights?: RightType[];
  getRoleList(cancelToken: CancelToken): void;
  getRoleListCount(cancelToken: CancelToken): void;
  changeRoleListOrder(orderBy: string, orderDesc: boolean): void;
  changeRoleListPage(page: number): void;
  changeRoleListFilter(filter: IFilterType): void;
  roleListLoad(reload: boolean): void;
}

const AdminRole: FC<IProps> = ({
  prov,
  identityRights,
  getRoleList,
  getRoleListCount,
  changeRoleListOrder,
  changeRoleListPage,
  changeRoleListFilter,
  roleListLoad,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    roleListLoad(false);
  }, [roleListLoad]);

  const handleRenderData = (item: IRoleItem): ReactElement => {
    return (
      <>
        <TableCol>{item.name}</TableCol>
        <TableCol>{item.description}</TableCol>
        <TableCol>{item.usersCount}</TableCol>
        <TableCol>
          <NavLink to={`/admin/role/${item.id}`}>
            <GridIconEdit />
          </NavLink>
        </TableCol>
      </>
    );
  };

  const handleRowClick = (item: IRoleItem) => {
    navigate(`/admin/role/${item.id}`);
  };

  return (
    <>
      <FilterPanel
        title={t("admin.role.pageTitle")}
        name="adminRole"
        filter={prov.filter!}
        changeFilter={changeRoleListFilter}
      />
      <Grid<IRoleItem>
        headers={[
          { captionStr: "admin.role.name" },
          { captionStr: "admin.role.description" },
          { captionStr: "admin.role.usersCount" },
          {
            captionEl: hasRight(identityRights, [RightType.WriteRole]) ? (
              <NavLink to={`/admin/role/new`}>
                <GridIconAdd />
              </NavLink>
            ) : undefined,
          },
        ]}
        renderData={handleRenderData}
        getData={getRoleList}
        getCount={getRoleListCount}
        changeOrder={changeRoleListOrder}
        changePage={changeRoleListPage}
        onRowClick={handleRowClick}
        prov={prov}
      />
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    prov: selectRoleList(state),
    identityRights: selectIdentityRights(state),
  };
};

const mapDispachToProps = {
  getRoleList,
  getRoleListCount,
  changeRoleListOrder,
  changeRoleListPage,
  changeRoleListFilter,
  roleListLoad,
};

export default connect(mapStateToProps, mapDispachToProps)(AdminRole);
