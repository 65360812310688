import { CancelToken } from "axios";
import { FC, ReactElement, useEffect } from "react";
import { connect } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { IAssistancePersonItem } from "../../models/assistance";
import { IApplicationState } from "../../store";
import { IFilterType } from "../../store/filterType";
import {
  changeAssistancePersonListFilter,
  changeAssistancePersonListOrder,
  changeAssistancePersonListPage,
  getAssistancePersonList,
  getAssistancePersonListCount,
  IAssistancePersonListState,
  selectAssistancePersonList,
  assistancePersonListLoad,
} from "../../store/assistancePersonList";
import FilterPanel from "../common/filter/FilterPanel";
import Grid from "../common/grid/Grid";
import { GridIconAdd, GridIconEdit } from "../common/grid/GridIcons";
import { TableCol } from "../common/grid/TableCol";
import { selectAssistanceId } from "../../store/assistance";
import { hasRight } from "../../utils/rights";
import { RightType } from "../../models/auth";
import { selectIdentityRights } from "../../store/identity";

interface IProps {
  assistanceId?: number;
  prov: IAssistancePersonListState;
  identityRights?: RightType[];
  getAssistancePersonList(assistanceId: number, cancelToken: CancelToken): void;
  getAssistancePersonListCount(
    assistanceId: number,
    cancelToken: CancelToken
  ): void;
  changeAssistancePersonListOrder(orderBy: string, orderDesc: boolean): void;
  changeAssistancePersonListPage(page: number): void;
  changeAssistancePersonListFilter(filter: IFilterType): void;
  assistancePersonListLoad(reload: boolean): void;
}

const AssistancePerson: FC<IProps> = ({
  assistanceId,
  prov,
  identityRights,
  getAssistancePersonList,
  getAssistancePersonListCount,
  changeAssistancePersonListOrder,
  changeAssistancePersonListPage,
  changeAssistancePersonListFilter,
  assistancePersonListLoad,
}) => {
  const { assistanceId: paramAssistanceId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    assistancePersonListLoad(false);
  }, [assistancePersonListLoad]);

  const handleRenderData = (item: IAssistancePersonItem): ReactElement => {
    return (
      <>
        <TableCol>{item.type.name}</TableCol>
        <TableCol>{item.title}</TableCol>
        <TableCol>{item.firstName}</TableCol>
        <TableCol>{item.lastName}</TableCol>
        <TableCol>{item.degree}</TableCol>
        <TableCol>
          <NavLink to={`/assistance/${paramAssistanceId}/person/${item.id}`}>
            <GridIconEdit />
          </NavLink>
        </TableCol>
      </>
    );
  };

  const handleRowClick = (item: IAssistancePersonItem) => {
    navigate(`/assistance/${paramAssistanceId}/person/${item.id}`);
  };

  const handleGetData = (cancelToken: CancelToken) =>
    getAssistancePersonList(assistanceId!, cancelToken);

  const handleGetCount = (cancelToken: CancelToken) =>
    getAssistancePersonListCount(assistanceId!, cancelToken);

  return (
    <>
      <FilterPanel
        name="assistancePerson"
        filter={prov.filter!}
        changeFilter={changeAssistancePersonListFilter}
      />
      <Grid<IAssistancePersonItem>
        headers={[
          { captionStr: "assistance.person.type" },
          { captionStr: "assistance.person.title" },
          { captionStr: "assistance.person.firstName" },
          { captionStr: "assistance.person.lastName" },
          { captionStr: "assistance.person.degree" },
          {
            captionEl: hasRight(identityRights, [
              RightType.WriteAssistances,
            ]) ? (
              <NavLink to={`/assistance/${paramAssistanceId}/person/new`}>
                <GridIconAdd />
              </NavLink>
            ) : undefined,
          },
        ]}
        renderData={handleRenderData}
        getData={handleGetData}
        getCount={handleGetCount}
        changeOrder={changeAssistancePersonListOrder}
        changePage={changeAssistancePersonListPage}
        onRowClick={handleRowClick}
        prov={prov}
      />
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    assistanceId: selectAssistanceId(state),
    prov: selectAssistancePersonList(state),
    identityRights: selectIdentityRights(state),
  };
};

const mapDispachToProps = {
  getAssistancePersonList,
  getAssistancePersonListCount,
  changeAssistancePersonListOrder,
  changeAssistancePersonListPage,
  changeAssistancePersonListFilter,
  assistancePersonListLoad,
};

export default connect(mapStateToProps, mapDispachToProps)(AssistancePerson);
