import { Form, Formik } from "formik";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../styles/button";
import { ApiError, FormGroup } from "../../styles/form";
import CustomModal from "../common/modal/CustomModal";
import * as Yup from "yup";
import validations from "../../utils/validations";
import { connect } from "react-redux";
import bucketApi from "../../api/bucket";
import { assistanceDocumentListLoad } from "../../store/assistanceDocumentList";
import assistanceApi from "../../api/assistance";
import AssistanceDocumentAddContent, {
  IFormData,
} from "./AssistanceDocumentAddContent";
import { promiseToastSave } from "../../utils/toasts";
import { errorSet } from "../../utils/error";
import { keepFileExtensions } from "../../utils/string";

interface IProps {
  file: File | null;
  assistanceId: number;
  isOpen: boolean;
  close(): void;
  assistanceDocumentListLoad(reload: boolean): void;
}

const AssistanceDocumentAddModal: FC<IProps> = ({
  file,
  assistanceId,
  isOpen,
  close,
  assistanceDocumentListLoad,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setError(null);
  }, [isOpen]);

  const handleSubmit = async (data: IFormData) => {
    setError(null);
    try {
      await promiseToastSave(async () => {
        const fileName = keepFileExtensions(file!.name, data.name);

        const uploadUrl = await assistanceApi.getAssistanceDocumentUploadUrl(
          assistanceId,
          fileName,
          null
        );

        await bucketApi.uploadDocument(uploadUrl.data, file!);
        await assistanceApi.addAssistanceDocument(assistanceId, fileName);

        assistanceDocumentListLoad(true);
        close();
      });
    } catch (err) {
      errorSet(setError, err, t);
    }
  };

  return (
    <Formik
      initialValues={{
        file: "",
        name: "",
      }}
      validationSchema={Yup.object({
        name: validations.stringRequired(t),
      })}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, isValid, submitForm }) => (
        <CustomModal
          title={t("document.addDocument.title")}
          isOpen={isOpen}
          close={close}
          actions={
            <>
              <Button
                ver="secondary"
                disabled={isSubmitting}
                onClick={() => close()}
              >
                {t("common.cancel")}
              </Button>
              <Button
                disabled={!isValid || isSubmitting}
                onClick={() => submitForm()}
              >
                {t("common.save")}
              </Button>
            </>
          }
        >
          <Form>
            <FormGroup>
              <AssistanceDocumentAddContent file={file} />
              {error && <ApiError>{error}</ApiError>}
            </FormGroup>
          </Form>
        </CustomModal>
      )}
    </Formik>
  );
};

const mapDispachToProps = {
  assistanceDocumentListLoad,
};

export default connect(null, mapDispachToProps)(AssistanceDocumentAddModal);
