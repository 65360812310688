import { FC } from "react";
import { useTranslation } from "react-i18next";
import ModalSingleButton from "./ModalSingleButton";

interface IProps {
  title: string;
  text: string;
  isOpen: boolean;
  isFunction?: boolean;
  close(): void;
  confirm: () => Promise<void>;
  confirmText?: string;
}

const ModalOk: FC<IProps> = ({
  title,
  text,
  isOpen,
  isFunction,
  close,
  confirm,
  confirmText,
}) => {
  const { t } = useTranslation();

  return (
    <ModalSingleButton
      title={title}
      text={text}
      confirmText={confirmText ?? t("common.ok")}
      isOpen={isOpen}
      isFunction={isFunction}
      close={close}
      confirm={confirm}
    />
  );
};

export default ModalOk;
