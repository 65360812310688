export enum TemplateDocumentType {
  Client = "client",
  Assistance = "assistance",
  Other = "other",
}

export interface ITemplateDocumentItem {
  id: number;
  name: string;
}
