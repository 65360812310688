import { createReducer } from "@reduxjs/toolkit";
import { IClientRiskState } from "./types";
import * as actions from "./actions";
import { StoreState } from "../storeState";
import { STORAGE_RESET } from "../storeReset";

const initialState = {
  data: [],
  state: StoreState.None,
} as IClientRiskState;

export const clientRiskReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(actions.clientRiskFetchRequest, (state) => ({
      ...state,
      state: StoreState.Loading,
    }))
    .addCase(actions.clientRiskFetchSuccess, (state, action) => ({
      ...state,
      state: StoreState.Loaded,
      data: action.payload,
    }))
    .addCase(actions.clientRiskFetchError, (state) => ({
      ...state,
      state: StoreState.Error,
    }))
    .addCase(actions.clientRiskReset, () => initialState)
    .addCase(STORAGE_RESET, () => initialState)
);
