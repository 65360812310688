import { AppThunkAction, AppThunkActionAsync } from "..";
import * as actions from "./actions";
import assistanceApi from "../../api/assistance";
import { AssistanceStateType, IAssistance } from "../../models/assistance";

export function getAssistance(assistanceId: number): AppThunkActionAsync {
  return async (dispatch) => {
    dispatch(actions.assistanceFetchRequest());
    try {
      const assistance = await assistanceApi.getAssistance(assistanceId);
      dispatch(actions.assistanceFetchSuccess(assistance.data));
    } catch {
      dispatch(actions.assistanceFetchError());
    }
  };
}

export function getDefaultAssistance(): AppThunkAction {
  return (dispatch) => {
    const data = {
      id: 0,
      state: AssistanceStateType.New,
      isInternal: false,
      name: "",
      street: "",
      houseNumber: 0,
      landRegistryNumber: 0,
      city: "",
      postCode: 0,
      isRegistered: false,

      careTypeList: [],
      targetGroupList: [],
    };

    dispatch(actions.assistanceFetchSuccess(data));
  };
}

export function updateAssistance(newData: IAssistance): AppThunkAction {
  return (dispatch) => {
    dispatch(actions.assistanceFetchSuccess(newData));
  };
}
