import { Formik } from "formik";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { SIGN_DOWN_TYPE } from "../../../models/document";
import { FormButton } from "../../../styles/button";
import Input from "../form/Input";
import SubmitForm from "../form/SubmitForm";
import CustomModal from "./CustomModal";
import { ModalContent } from "./CustomModalStyles";

interface PromiseModalSignDownTypeProps {
  resolve: (type: SIGN_DOWN_TYPE) => unknown;
  reject: () => void;
  options: { name: string; value: SIGN_DOWN_TYPE }[];
  onClose?: () => void;
  isOpen: boolean;
}

const PromiseModalSignDownType: FC<PromiseModalSignDownTypeProps> = ({
  resolve,
  reject,
  options,
  onClose,
  isOpen,
}) => {
  const { t } = useTranslation();

  const handleResolve = (type: SIGN_DOWN_TYPE) => {
    resolve(type);
  };

  const handleReject = () => {
    reject();
    onClose && onClose();
  };

  return (
    <CustomModal
      isOpen={isOpen}
      close={handleReject}
      title={t("document.signDownTypeSelectTitle")}
    >
      <ModalContent>
        <Formik<{
          type: SIGN_DOWN_TYPE;
        }>
          onSubmit={(data) => {
            handleResolve(data.type);
          }}
          initialValues={{
            type: SIGN_DOWN_TYPE.biometric,
          }}
        >
          {() => (
            <>
              <Input name="type" component="select">
                {options.map(({ value, name }) => (
                  <option value={value} key={name}>
                    {name}
                  </option>
                ))}
              </Input>
              <SubmitForm>
                <FormButton type={"submit"}> {t("common.use")} </FormButton>
              </SubmitForm>
            </>
          )}
        </Formik>
      </ModalContent>
    </CustomModal>
  );
};

export default PromiseModalSignDownType;
