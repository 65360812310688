import { FC } from "react";
import { useTranslation } from "react-i18next";

const links = [
  { id: "api", url: process.env.REACT_APP_MONITOR_API_URL },
  { id: "web", url: process.env.REACT_APP_MONITOR_WEB_URL },
  { id: "clientWeb", url: process.env.REACT_APP_MONITOR_CLIENT_WEB_URL },
  { id: "libreOffice", url: process.env.REACT_APP_MONITOR_LIBRE_OFFICE_URL },
  {
    id: "libreOfficeAdmin",
    url: process.env.REACT_APP_MONITOR_LIBRE_OFFICE_ADMIN_URL,
  },
  {
    id: "libreOfficeDiscovery",
    url: process.env.REACT_APP_MONITOR_LIBRE_OFFICE_DISCOVERY_URL,
  },
  {
    id: "libreOfficeCapabilities",
    url: process.env.REACT_APP_MONITOR_LIBRE_OFFICE_CAPABILITIES_URL,
  },
  { id: "scheduler", url: process.env.REACT_APP_MONITOR_SCHEDULER_URL },
  { id: "db", url: process.env.REACT_APP_MONITOR_DB_URL },
  { id: "dbLog", url: process.env.REACT_APP_MONITOR_DB_LOG_URL },
  { id: "storage", url: process.env.REACT_APP_MONITOR_STORAGE_URL },
];

const AdminMonitor: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <h1>{t("admin.monitor.pageTitle")}</h1>
      <ul>
        {links.map((l) => (
          <li key={l.id}>
            <a href={l.url} target="_blank" rel="noreferrer">
              {t("admin.monitor." + l.id)}
            </a>
          </li>
        ))}
      </ul>
    </>
  );
};

export default AdminMonitor;
