import { useFormikContext } from "formik";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TemplateDocumentType } from "../../models/template";
import Input from "../common/form/Input";

export interface IFormData {
  file: string;
  name: string;
  type: TemplateDocumentType;
}

interface IProps {
  file: File | null;
}

const ModuleDocumentAddContent: FC<IProps> = ({ file }) => {
  const { t } = useTranslation();

  const { errors, touched, resetForm, setFieldValue } =
    useFormikContext<IFormData>();

  useEffect(() => {
    resetForm();
    setFieldValue("file", file?.name ?? "");
    setFieldValue("name", file?.name ?? "");
  }, [file, resetForm, setFieldValue]);

  return (
    <>
      <Input
        name="file"
        label={t("document.addDocument.file")}
        disabled={true}
      />
      <Input
        name="name"
        label={t("document.addDocument.name")}
        error={touched.name && !!errors.name}
        maxLength={255}
      />
      <Input
        name="type"
        label={t("document.addDocument.type")}
        error={touched.type && !!errors.type}
        component="select"
      >
        {(
          Object.keys(TemplateDocumentType) as Array<
            keyof typeof TemplateDocumentType
          >
        ).map((key) => (
          <option key={key} value={TemplateDocumentType[key]}>
            {t("document.addDocument.types." + TemplateDocumentType[key])}
          </option>
        ))}
      </Input>
    </>
  );
};

export default ModuleDocumentAddContent;
