import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import ForgottenPassword from "./components/auth/ForgottenPassword";
import ResetPassword from "./components/auth/ResetPassword";
import Routes2 from "./Routes2";
import { RoutesRedirect } from "./RoutesRedirect";

export const Routes1: FC = () => (
  <Routes>
    <Route path="gdpr" element={<RoutesRedirect url="/gdpr.html" />} />
    <Route path="gdpr.html" element={<RoutesRedirect url="/gdpr.html" />} />
    <Route path="forgotten-password" element={<ForgottenPassword />} />
    <Route path="forgotten-password/:token" element={<ResetPassword />} />
    <Route path="*" element={<Routes2 />} />
  </Routes>
);
