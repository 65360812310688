import { Form, Formik } from "formik";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { ICareType } from "../../models/careType";
import { IApplicationState } from "../../store";
import { StoreState } from "../../store/storeState";
import {
  getDefaultCareType,
  getCareType,
  selectCareType,
  selectCareTypeState,
} from "../../store/careType";
import { FormButton } from "../../styles/button";
import { ApiError, FormGroup } from "../../styles/form";
import Input from "../common/form/Input";
import Loader from "../common/Loader";
import * as Yup from "yup";
import { careTypeListLoad } from "../../store/careTypeList";
import careTypeApi from "../../api/careType";
import { SpaceBetweenButtons } from "../../styles/spaces";
import validations from "../../utils/validations";
import SuggestionFormik from "../common/suggestion/SuggestionFormik";
import { RightType } from "../../models/auth";
import { hasRight } from "../../utils/rights";
import { selectIdentityRights } from "../../store/identity";
import { handleLoadAssistanceCategoryListingSuggestions } from "../../utils/suggestions";
import { promiseToastSave } from "../../utils/toasts";
import SubmitForm from "../common/form/SubmitForm";
import { errorSet } from "../../utils/error";

interface IProps {
  careTypeState: StoreState;
  careType: ICareType | null;
  identityRights?: RightType[];
  getCareType(careTypeId: number): void;
  getDefaultCareType(): void;
  careTypeListLoad(reload: boolean): void;
}

const AdminCareTypeDetail: FC<IProps> = ({
  careTypeState,
  careType,
  identityRights,
  getCareType,
  getDefaultCareType,
  careTypeListLoad,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { careTypeId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (careTypeId === "new") {
      getDefaultCareType();
    } else {
      getCareType(parseInt(careTypeId!));
    }
    setLoading(false);
  }, [careTypeId, getCareType, getDefaultCareType]);

  const handleSubmit = async (data: ICareType) => {
    setError(null);
    try {
      await promiseToastSave(async () => {
        if (careTypeId === "new") {
          await careTypeApi.createCareType(data);
        } else {
          await careTypeApi.updateCareType(data);
        }

        careTypeListLoad(true);
        navigate("/admin/caretype");
      });
    } catch (err) {
      errorSet(setError, err, t);
    }
  };

  const handleCancel = () => {
    navigate("/admin/caretype");
  };

  if (loading || careTypeState === StoreState.Loading) {
    return <Loader />;
  }

  if (careTypeState === StoreState.Error) {
    return t("errors.unknown");
  }

  return (
    <Formik<ICareType>
      initialValues={careType!}
      validationSchema={Yup.object({
        name: validations.stringRequired(t),
        description: validations.stringRequired(t),
        assistanceCategoryListingId: validations.idRequired(t),
      })}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, isSubmitting }) => (
        <Form>
          <h1>{t("admin.careType.detailTitle")}</h1>
          <FormGroup>
            <Input
              name="isActive"
              type="checkbox"
              label={t(`admin.careType.isActive`)}
              inputWidth="1.5rem"
              inputHeight="1.5rem"
            />
            <Input
              name="name"
              label={t("admin.careType.name")}
              error={touched.name && !!errors.name}
              maxLength={128}
            />
            <SuggestionFormik
              nameId="assistanceCategoryListingId"
              nameText="assistanceCategoryListingName"
              label={t("admin.careType.assistanceCategoryListing")}
              loadSuggestions={handleLoadAssistanceCategoryListingSuggestions}
            />
            <Input
              name="description"
              component="textarea"
              label={t("admin.careType.description")}
              error={touched.description && !!errors.description}
              inputHeight="10rem"
              maxLength={512}
            />
          </FormGroup>
          {error && <ApiError>{error}</ApiError>}
          <FormButton
            ver="secondary"
            disabled={isSubmitting}
            onClick={handleCancel}
          >
            {t("common.back")}
          </FormButton>
          {hasRight(identityRights, [RightType.WriteCareType]) && (
            <>
              <SpaceBetweenButtons />
              <SubmitForm />
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    careTypeState: selectCareTypeState(state),
    careType: selectCareType(state),
    identityRights: selectIdentityRights(state),
  };
};

const mapDispachToProps = {
  getCareType,
  getDefaultCareType,
  careTypeListLoad,
};

export default connect(mapStateToProps, mapDispachToProps)(AdminCareTypeDetail);
