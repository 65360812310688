import { createReducer } from "@reduxjs/toolkit";
import { IListingListItems, IListingListState } from "./types";
import * as actions from "./actions";
import { StoreState, StoreStateDontLoad } from "../storeState";
import { STORAGE_RESET } from "../storeReset";
import { ListingType } from "../../models/listing";

const initialItem = {
  state: StoreState.None,
  data: [],
  countState: StoreStateDontLoad.DontLoad,
  count: 0,
  page: 0,
  filter: { search: undefined, isOpen: false },
} as IListingListItems;

const initialState = {
  types: {
    [ListingType.ActionListing]: initialItem,
    [ListingType.AssistanceCategoryListing]: initialItem,
    [ListingType.ClientContactPersonListing]: initialItem,
    [ListingType.ClientRiskListing]: initialItem,
    [ListingType.SocialBenefitListing]: initialItem,
    [ListingType.TargetGroupListing]: initialItem,
    [ListingType.ContactPersonListing]: initialItem,
    [ListingType.WorkflowCategoryListing]: initialItem,
  },
} as IListingListState;

export const listingListReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(actions.listingListFetchRequest, (state, action) => ({
      ...state,
      types: {
        ...state.types,
        [action.payload.type]: {
          ...state.types[action.payload.type],
          state: StoreState.Loading,
          countState: action.payload.newCountState,
        },
      },
    }))
    .addCase(actions.listingListFetchSuccess, (state, action) => ({
      ...state,
      types: {
        ...state.types,
        [action.payload.type]: {
          ...state.types[action.payload.type],
          state: StoreState.Loaded,
          lastLoaded: new Date(),
          data: action.payload.data,
          countState: action.payload.newCountState,
          count: action.payload.newCount,
        },
      },
    }))
    .addCase(actions.listingListFetchError, (state, action) => ({
      ...state,
      types: {
        ...state.types,
        [action.payload.type]: {
          ...state.types[action.payload.type],
          state: action.payload.canceled ? StoreState.Cancel : StoreState.Error,
        },
      },
    }))
    .addCase(actions.listingListCountRequest, (state, action) => ({
      ...state,
      types: {
        ...state.types,
        [action.payload]: {
          ...state.types[action.payload],
          countState: StoreStateDontLoad.Loading,
        },
      },
    }))
    .addCase(actions.listingListCountSuccess, (state, action) => ({
      ...state,
      types: {
        ...state.types,
        [action.payload.type]: {
          ...state.types[action.payload.type],
          countState: StoreStateDontLoad.Loaded,
          count: action.payload.count,
        },
      },
    }))
    .addCase(actions.listingListCountError, (state, action) => ({
      ...state,
      types: {
        ...state.types,
        [action.payload.type]: {
          ...state.types[action.payload.type],
          countState: action.payload.canceled
            ? StoreStateDontLoad.Cancel
            : StoreStateDontLoad.Error,
        },
      },
    }))
    .addCase(actions.listingListChangeOrder, (state, action) => ({
      ...state,
      types: {
        ...state.types,
        [action.payload.type]: {
          ...state.types[action.payload.type],
          state: StoreState.None,
          orderBy: action.payload.orderBy,
          orderDesc: action.payload.orderDesc,
          page: 0,
        },
      },
    }))
    .addCase(actions.listingListChangePage, (state, action) => ({
      ...state,
      types: {
        ...state.types,
        [action.payload.type]: {
          ...state.types[action.payload.type],
          state: StoreState.None,
          page: action.payload.page,
        },
      },
    }))
    .addCase(actions.listingListChangeFilter, (state, action) => ({
      ...state,
      types: {
        ...state.types,
        [action.payload.type]: {
          ...state.types[action.payload.type],
          state: StoreState.None,
          countState: StoreStateDontLoad.DontLoad,
          filter: action.payload.filter,
          page: 0,
        },
      },
    }))
    .addCase(actions.listingListReset, (state, action) => ({
      ...state,
      types: {
        ...state.types,
        [action.payload]: {
          ...initialItem,
          filter: state.types[action.payload].filter,
        },
      },
    }))
    .addCase(actions.listingListLoadAction, (state, action) => ({
      ...state,
      types: {
        ...state.types,
        [action.payload.type]: {
          ...state.types[action.payload.type],
          state: action.payload.newState,
          countState: action.payload.newCountState,
        },
      },
    }))
    .addCase(STORAGE_RESET, () => initialState)
);
