import { IconDefinition } from "@fortawesome/fontawesome-common-types";

export enum ExportType {
  Csv = "csv",
  Xlsx = "xlsx",
}

export interface IItemIdName {
  id: number;
  name: string;
}

export interface IItemKeyCaregiver {
  id: number;
  name: string;
  showEmail: boolean;
  showMobilePhoneNumber: boolean;
  mobileNumber?: string | null;
  email?: string | null;
}

export interface ISidebarLink {
  key: string;
  to: string;
  text: string;
  icon: IconDefinition;
}
