import { CancelToken } from "axios";
import { FC, useState } from "react";
import Suggestion, { FAKE1, FAKE2, ISuggestionValue } from "./Suggestion";

interface IProps {
  name: string;
  values: ISuggestionValue[];
  showListAlways?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  loadSuggestions(
    text: string,
    cancelToken: CancelToken
  ): Promise<ISuggestionValue[]>;
  setValues(values: ISuggestionValue[]): void;
  onBlur?(): void;
}

const SuggestionList: FC<IProps> = ({
  name,
  values,
  showListAlways,
  autoFocus,
  disabled = false,
  loadSuggestions,
  setValues,
  onBlur,
}) => {
  const [fakeValue, setFakeValue] = useState<ISuggestionValue | undefined>(
    undefined
  );

  const handleSelectValue = (value: ISuggestionValue | undefined) => {
    setFakeValue({ id: fakeValue?.id === FAKE1 ? FAKE2 : FAKE1, text: "" });

    if (!value) {
      return;
    }

    if (values.find((x) => x.id === value.id)) {
      return;
    }

    setValues([...values, value]);
  };

  return (
    <Suggestion
      id={name}
      selected={fakeValue}
      showListAlways={showListAlways}
      autoFocus={autoFocus}
      loadSuggestions={loadSuggestions}
      selectValue={handleSelectValue}
      onBlur={onBlur}
      disabled={disabled}
    />
  );
};

export default SuggestionList;
