import { IDemandHistory } from "../../models/demand";
import { StoreState } from "../storeState";

export enum DemandHistoryTypes {
  DEMAND_HISTORY_FETCH_REQUEST = "@@demandHistory/FETCH_REQUEST",
  DEMAND_HISTORY_FETCH_SUCCESS = "@@demandHistory/FETCH_SUCCESS",
  DEMAND_HISTORY_FETCH_ERROR = "@@demandHistory/FETCH_ERROR",
}

export interface IDemandHistoryState {
  state: StoreState;
  data: IDemandHistory | null;
}
