import { createAction } from "@reduxjs/toolkit";
import { IItemIdName } from "../../models/common";
import { IFilterType } from "../filterType";
import { StoreState, StoreStateDontLoad } from "../storeState";
import { ClientRightUserListTypes } from "./types";

export const clientRightUserListFetchRequest = createAction<
  StoreStateDontLoad,
  ClientRightUserListTypes.CLIENT_RIGHT_USER_LIST_FETCH_REQUEST
>(ClientRightUserListTypes.CLIENT_RIGHT_USER_LIST_FETCH_REQUEST);

export const clientRightUserListFetchSuccess = createAction<
  {
    data: IItemIdName[];
    newCountState: StoreStateDontLoad;
    newCount: number;
  },
  ClientRightUserListTypes.CLIENT_RIGHT_USER_LIST_FETCH_SUCCESS
>(ClientRightUserListTypes.CLIENT_RIGHT_USER_LIST_FETCH_SUCCESS);

export const clientRightUserListFetchError = createAction<
  boolean,
  ClientRightUserListTypes.CLIENT_RIGHT_USER_LIST_FETCH_ERROR
>(ClientRightUserListTypes.CLIENT_RIGHT_USER_LIST_FETCH_ERROR);

export const clientRightUserListCountRequest = createAction<
  void,
  ClientRightUserListTypes.CLIENT_RIGHT_USER_LIST_COUNT_REQUEST
>(ClientRightUserListTypes.CLIENT_RIGHT_USER_LIST_COUNT_REQUEST);

export const clientRightUserListCountSuccess = createAction<
  number,
  ClientRightUserListTypes.CLIENT_RIGHT_USER_LIST_COUNT_SUCCESS
>(ClientRightUserListTypes.CLIENT_RIGHT_USER_LIST_COUNT_SUCCESS);

export const clientRightUserListCountError = createAction<
  boolean,
  ClientRightUserListTypes.CLIENT_RIGHT_USER_LIST_COUNT_ERROR
>(ClientRightUserListTypes.CLIENT_RIGHT_USER_LIST_COUNT_ERROR);

export const clientRightUserListChangeOrder = createAction<
  { orderBy: string; orderDesc: boolean },
  ClientRightUserListTypes.CLIENT_RIGHT_USER_LIST_CHANGE_ORDER
>(ClientRightUserListTypes.CLIENT_RIGHT_USER_LIST_CHANGE_ORDER);

export const clientRightUserListChangePage = createAction<
  number,
  ClientRightUserListTypes.CLIENT_RIGHT_USER_LIST_CHANGE_PAGE
>(ClientRightUserListTypes.CLIENT_RIGHT_USER_LIST_CHANGE_PAGE);

export const clientRightUserListChangeFilter = createAction<
  IFilterType,
  ClientRightUserListTypes.CLIENT_RIGHT_USER_LIST_CHANGE_FILTER
>(ClientRightUserListTypes.CLIENT_RIGHT_USER_LIST_CHANGE_FILTER);

export const clientRightUserListReset = createAction<
  void,
  ClientRightUserListTypes.CLIENT_RIGHT_USER_LIST_RESET
>(ClientRightUserListTypes.CLIENT_RIGHT_USER_LIST_RESET);

export const clientRightUserListLoadAction = createAction<
  { newState: StoreState; newCountState: StoreStateDontLoad },
  ClientRightUserListTypes.CLIENT_RIGHT_USER_LIST_LOAD
>(ClientRightUserListTypes.CLIENT_RIGHT_USER_LIST_LOAD);
