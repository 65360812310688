import {
  faAddressCard,
  faBalanceScaleRight,
  faCalendarAlt,
  faChevronLeft,
  faClipboard,
  faComments,
  faFileWord,
  faHandHoldingHeart,
  faHome,
  faList,
  faListOl,
  faSyringe,
  faUsers,
  faUsersCog,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Routes, Route, useParams } from "react-router";
import { RightType } from "../../models/auth";
import { IClient } from "../../models/client";
import { IApplicationState } from "../../store";
import {
  getClient,
  getDefaultClient,
  selectClient,
  selectClientState,
} from "../../store/client";
import { clientActionListLoad } from "../../store/clientActionList";
import { clientBenefitListLoad } from "../../store/clientBenefitList";
import { clientCareListLoad } from "../../store/clientCareList";
import { clientContactPersonListLoad } from "../../store/clientContactPersonList";
import { clientDebtListLoad } from "../../store/clientDebtList";
import { clientDemandListLoad } from "../../store/clientDemandList";
import { clientDocumentListLoad } from "../../store/clientDocumentList";
import { resetClientHouse } from "../../store/clientHouse";
import { clientNoteListLoad } from "../../store/clientNoteList";
import { clientRevenueListLoad } from "../../store/clientRevenueList";
import { clientRightGroupListLoad } from "../../store/clientRightGroupList";
import { clientRightUserListLoad } from "../../store/clientRightUserList";
import { resetClientRisk } from "../../store/clientRisk";
import { selectIdentityRights } from "../../store/identity";
import { StoreState } from "../../store/storeState";
import { COLORS } from "../../styles/colors";
import { filterMenu } from "../../utils/rights";
import AuthRoute from "../auth/AuthRoute";
import Loader from "../common/Loader";
import { SidebarPageContainer } from "../layout/LayoutStyles";
import Page from "../layout/Page";
import Sidebar from "../layout/Sidebar";
import ClientButton from "./ClientButton";
import ClientAction from "./ClientAction";
import ClientActionDetail from "./ClientActionDetail";
import ClientCare from "./ClientCare";
import ClientCareDetail from "./ClientCareDetail";
import ClientDemand from "./ClientDemand";
import ClientDemandDetail from "./ClientDemandDetail";
import ClientDocument from "./ClientDocument";
import ClientFinance from "./ClientFinance";
import ClientGeneral from "./ClientGeneral";
import ClientHouse from "./ClientHouse";
import ClientInfo from "./ClientInfo";
import ClientPerson from "./ClientPerson";
import ClientPersonDetail from "./ClientPersonDetail";
import ClientRight from "./ClientRight";
import ClientRisk from "./ClientRisk";
import {
  TopPanel,
  TopPanelBack,
  TopPanelCenterContainer,
  TopPanelIdContainer,
  TopPanelNameContainer,
  TopPanelRouterContainer,
  TopPanelState,
  TopPanelStateContainer,
  TopPanelStateLabel,
} from "../common/TopPanelStyles";
import ClientCalendar from "./ClientCalendar";
import { getClientStateColor, getClientStateIcon } from "./ClientHelpers";
import formatters from "../../utils/formatters";
import ClientDemandNew from "./ClientDemandNew";

const links = [
  {
    id: "general",
    icon: faAddressCard,
    rights: [],
    showAlways: true,
    showInNonFullProfile: true,
  },
  { id: "person", icon: faUsers, rights: [RightType.ReadClientContactPersons] },
  { id: "risk", icon: faSyringe, rights: [RightType.ReadClientRisk] },
  { id: "info", icon: faComments, rights: [RightType.ReadClientNotes] },
  { id: "house", icon: faHome, rights: [RightType.ReadClientHousingSituation] },
  {
    id: "finance",
    icon: faBalanceScaleRight,
    rights: [RightType.ReadClientsFinancingData],
  },
  { id: "care", icon: faHandHoldingHeart, rights: [RightType.ReadCare] },
  {
    id: "action",
    icon: faListOl,
    rights: [RightType.ReadClientAction],
    showInNonFullProfile: true,
  },
  {
    id: "calendar",
    icon: faCalendarAlt,
    rights: [RightType.ReadClientCalendar],
    showInNonFullProfile: true,
  },
  { id: "demand", icon: faList, rights: [RightType.ReadClientDemand] },
  { id: "document", icon: faFileWord, rights: [RightType.ReadClientDocuments] },
  {
    id: "right",
    icon: faUsersCog,
    rights: [RightType.ReadClientsRights],
    showInNonFullProfile: true,
  },
  {
    id: "button",
    icon: faClipboard,
    rights: [
      RightType.ChangeClientState,
      RightType.AddClientGdprAgreement,
      RightType.AddClientGdprAgreementWithoutSecret,
      RightType.AnonymiseClient,
      RightType.ExportClient,
    ],
    showInNonFullProfile: true,
  },
];

const routes = [
  {
    path: "general",
    rights: [],
    showAlways: true,
    showInNonFullProfile: true,
    component: <ClientGeneral />,
  },
  {
    path: "person",
    rights: [RightType.ReadClientContactPersons],
    component: <ClientPerson />,
  },
  {
    path: "person/:personId",
    isDetail: true,
    rights: [RightType.ReadClientContactPersons],
    component: <ClientPersonDetail />,
  },
  {
    path: "house",
    rights: [RightType.ReadClientHousingSituation],
    component: <ClientHouse />,
  },
  {
    path: "risk",
    rights: [RightType.ReadClientRisk],
    component: <ClientRisk />,
  },
  {
    path: "finance",
    rights: [RightType.ReadClientsFinancingData],
    component: <ClientFinance />,
  },
  {
    path: "info",
    rights: [RightType.ReadClientNotes],
    component: <ClientInfo />,
  },
  {
    path: "care",
    rights: [RightType.ReadCare],
    component: <ClientCare />,
  },
  {
    path: "care/:careId",
    isDetail: true,
    rights: [RightType.ReadCare],
    component: <ClientCareDetail />,
  },
  {
    path: "action",
    rights: [RightType.ReadClientAction],
    showInNonFullProfile: true,
    component: <ClientAction />,
  },
  {
    path: "action/:actionId",
    isDetail: true,
    rights: [RightType.ReadAction],
    showInNonFullProfile: true,
    component: <ClientActionDetail />,
  },
  {
    path: "calendar",
    rights: [RightType.ReadClientCalendar],
    showInNonFullProfile: true,
    component: <ClientCalendar />,
  },
  {
    path: "demand",
    rights: [RightType.ReadClientDemand],
    component: <ClientDemand />,
  },
  {
    path: "demand/new",
    isDetail: true,
    rights: [RightType.WriteDemand],
    component: <ClientDemandNew />,
  },
  {
    path: "demand/:demandId",
    isDetail: true,
    rights: [RightType.ReadDemand],
    component: <ClientDemandDetail />,
  },
  {
    path: "document",
    rights: [RightType.ReadClientDocuments],
    component: <ClientDocument />,
  },
  {
    path: "document/:folderId",
    isDetail: true,
    rights: [RightType.ReadClientDocuments],
    component: <ClientDocument />,
  },
  {
    path: "right",
    rights: [RightType.ReadClientsRights],
    showInNonFullProfile: true,
    component: <ClientRight />,
  },
  {
    path: "button",
    rights: [
      RightType.ChangeClientState,
      RightType.AddClientGdprAgreement,
      RightType.AddClientGdprAgreementWithoutSecret,
      RightType.AnonymiseClient,
      RightType.ExportClient,
    ],
    showInNonFullProfile: true,
    component: <ClientButton />,
  },
];

interface IProps {
  clientState: StoreState;
  client: IClient | null;
  identityRights?: RightType[];
  getClient(clientId: number): void;
  getDefaultClient(): void;
  resetClientHouse(): void;
  resetClientRisk(): void;
  clientRevenueListLoad(reload: boolean): void;
  clientBenefitListLoad(reload: boolean): void;
  clientDebtListLoad(reload: boolean): void;
  clientNoteListLoad(reload: boolean): void;
  clientCareListLoad(reload: boolean): void;
  clientDocumentListLoad(reload: boolean): void;
  clientContactPersonListLoad(reload: boolean): void;
  clientDemandListLoad(reload: boolean): void;
  clientActionListLoad(reload: boolean): void;
  clientRightGroupListLoad(reload: boolean): void;
  clientRightUserListLoad(reload: boolean): void;
}

const ClientDetail: FC<IProps> = ({
  clientState,
  client,
  identityRights,
  getClient,
  getDefaultClient,
  resetClientHouse,
  resetClientRisk,
  clientRevenueListLoad,
  clientBenefitListLoad,
  clientDebtListLoad,
  clientNoteListLoad,
  clientCareListLoad,
  clientDocumentListLoad,
  clientContactPersonListLoad,
  clientDemandListLoad,
  clientActionListLoad,
  clientRightGroupListLoad,
  clientRightUserListLoad,
}) => {
  const { t } = useTranslation();
  const { clientId: paramClientId } = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (paramClientId === "new") {
      getDefaultClient();
    } else {
      getClient(parseInt(paramClientId!));
    }

    resetClientHouse();
    resetClientRisk();
    clientRevenueListLoad(true);
    clientBenefitListLoad(true);
    clientDebtListLoad(true);
    clientNoteListLoad(true);
    clientCareListLoad(true);
    clientDocumentListLoad(true);
    clientContactPersonListLoad(true);
    clientDemandListLoad(true);
    clientActionListLoad(true);
    clientRightGroupListLoad(true);
    clientRightUserListLoad(true);

    setLoading(false);
  }, [
    paramClientId,
    getClient,
    getDefaultClient,
    resetClientHouse,
    resetClientRisk,
    clientRevenueListLoad,
    clientBenefitListLoad,
    clientDebtListLoad,
    clientNoteListLoad,
    clientCareListLoad,
    clientDocumentListLoad,
    clientContactPersonListLoad,
    clientDemandListLoad,
    clientActionListLoad,
    clientRightGroupListLoad,
    clientRightUserListLoad,
  ]);

  if (loading || clientState === StoreState.Loading) {
    return <Loader />;
  }

  if (clientState === StoreState.Error) {
    return t("errors.unknown");
  }

  return (
    <>
      {client!.id !== 0 && (
        <TopPanel color={getClientStateColor(client!.state)}>
          <TopPanelBack to={"/client"}>
            <FontAwesomeIcon icon={faChevronLeft} color={COLORS.sidebarColor} />
          </TopPanelBack>
          <TopPanelCenterContainer>
            <TopPanelRouterContainer>
              {t("client.topPanelDetail")}
              {" / "}
              <Routes>
                {routes
                  .filter((r) => !r.isDetail)
                  .filter((r) => r.showAlways || client!.id !== 0)
                  .filter(
                    (r) => r.showInNonFullProfile || client?.fullRegistration
                  )
                  .map((r) => (
                    <Route
                      key={r.path}
                      path={r.path + "/*"}
                      element={
                        <AuthRoute rights={r.rights}>
                          {t(`client.nav.${r.path}`)}
                        </AuthRoute>
                      }
                    />
                  ))}
              </Routes>
            </TopPanelRouterContainer>
            <TopPanelNameContainer>
              <TopPanelIdContainer>{client?.id}</TopPanelIdContainer>
              {formatters.fullName(client!)}
            </TopPanelNameContainer>
          </TopPanelCenterContainer>
          <TopPanelStateContainer>
            <TopPanelStateLabel>
              {t("client.topPanelState")}{" "}
            </TopPanelStateLabel>
            <TopPanelState color={getClientStateColor(client!.state)}>
              {getClientStateIcon(client!.state, t)}
              <TopPanelStateLabel>
                {t("client.states." + client!.state)}
              </TopPanelStateLabel>
            </TopPanelState>
          </TopPanelStateContainer>
        </TopPanel>
      )}
      <SidebarPageContainer hasTopPanel={client!.id !== 0}>
        <Sidebar
          title={t("client.detailTitle")}
          showTitle={false}
          links={filterMenu(identityRights, links)
            .filter((x) => x.showAlways || client!.id !== 0)
            .filter((r) => r.showInNonFullProfile || client?.fullRegistration)
            .map((l) => ({
              key: l.id,
              to: `/client/${paramClientId}/${l.id}`,
              text: t(`client.nav.${l.id}`),
              icon: l.icon,
            }))}
        />
        <Page hasTopPanel={client!.id !== 0}>
          <Routes>
            {routes
              .filter((r) => r.showAlways || client!.id !== 0)
              .filter((r) => r.showInNonFullProfile || client?.fullRegistration)
              .map((r) => (
                <Route
                  key={r.path}
                  path={r.path}
                  element={
                    <AuthRoute rights={r.rights}>{r.component}</AuthRoute>
                  }
                />
              ))}
          </Routes>
        </Page>
      </SidebarPageContainer>
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    clientState: selectClientState(state),
    client: selectClient(state),
    identityRights: selectIdentityRights(state),
  };
};

const mapDispachToProps = {
  getClient,
  getDefaultClient,
  resetClientHouse,
  resetClientRisk,
  clientRevenueListLoad,
  clientBenefitListLoad,
  clientDebtListLoad,
  clientNoteListLoad,
  clientCareListLoad,
  clientDocumentListLoad,
  clientContactPersonListLoad,
  clientDemandListLoad,
  clientActionListLoad,
  clientRightGroupListLoad,
  clientRightUserListLoad,
};

export default connect(mapStateToProps, mapDispachToProps)(ClientDetail);
