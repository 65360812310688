import { IItemIdName } from "../../models/common";
import { StoreState } from "../storeState";

export enum ClientRiskTypes {
  CLIENT_RISK_FETCH_REQUEST = "@@clientRisk/FETCH_REQUEST",
  CLIENT_RISK_FETCH_SUCCESS = "@@clientRisk/FETCH_SUCCESS",
  CLIENT_RISK_FETCH_ERROR = "@@clientRisk/FETCH_ERROR",
  CLIENT_RISK_RESET = "@@clientRisk/RESET",
}

export interface IClientRiskState {
  state: StoreState;
  data: IItemIdName[];
}
