import { FC } from "react";
import {
  AbstractModelFactory,
  AbstractReactFactory,
  GenerateWidgetEvent,
} from "@projectstorm/react-canvas-core";
import {
  DiagramEngine,
  LinkModel,
  NodeModel,
  NodeModelGenerics,
  PortModelAlignment,
} from "@projectstorm/react-diagrams-core";
import {
  DefaultLinkModel,
  DefaultPortModel,
  RightAngleLinkModel,
} from "@projectstorm/react-diagrams";
import {
  PortWidgetWrapperTop,
  PortWidgetWrapperBottom,
  StyledPortWidget,
  DiagramTemplateWrapper,
  DiagramHistoryColumnsDiv,
  DiagramUser,
  DiagramTopPortMode,
  DiagramTitle,
} from "./DiagramStyles";
import { DemandStateType, IDemandHistoryItem } from "../../../models/demand";
import {
  faCircleCheck,
  faCircleXmark,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { format } from "date-fns";
import { DATE_FORMAT } from "../../../utils/consts";
import { COLORS } from "../../../styles/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getHistoryDiffTime } from "./diagramHelpers";
import { WorkflowDefinitionItemTopMode } from "../../../models/workflow";
import { store } from "../../../store";
import { selectDiagramSettings } from "../../../store/diagramSettings";

const NAME = "template";

class Factory extends AbstractReactFactory<DiagramTemplateNode, DiagramEngine> {
  constructor() {
    super(NAME);
  }

  generateReactWidget(
    event: GenerateWidgetEvent<DiagramTemplateNode>
  ): JSX.Element {
    return <Widget engine={this.engine} node={event.model} />;
  }

  generateModel() {
    return new DiagramTemplateNode({
      id: 0,
      name: "",
      topPortMode: WorkflowDefinitionItemTopMode.AtLeastOne,
    });
  }
}

interface NodeProps {
  id: number;
  name: string;
  topPortMode: WorkflowDefinitionItemTopMode;
  disableClick?: boolean;
  notFound?: boolean;
  historyItem?: IDemandHistoryItem;
  highlight?: boolean;
  isLocked?: boolean;
}

export class DiagramTemplateNode extends NodeModel<NodeModelGenerics> {
  constructor(data: NodeProps) {
    super({
      type: NAME,
      extras: data,
    });
    this.addPort(new Port(PortModelAlignment.TOP));
    this.addPort(new Port(PortModelAlignment.BOTTOM));
    this.setLocked(data.isLocked);
  }

  myPortTop() {
    return this.getPort(PortModelAlignment.TOP) as Port;
  }

  myPortBottom() {
    return this.getPort(PortModelAlignment.BOTTOM) as Port;
  }

  myData() {
    return this.options.extras as NodeProps;
  }
}

interface WidgetProps {
  node: DiagramTemplateNode;
  engine: DiagramEngine;
}

const Widget: FC<WidgetProps> = ({ node, engine }) => {
  const portTop = node.myPortTop();
  const portBottom = node.myPortBottom();
  const data = node.myData();

  const portTopLinksCount = Object.keys(portTop.getLinks()).length;

  let icon = null;
  let additional1 = null;
  let additional2 = null;
  if (data.historyItem) {
    if (data.historyItem.state === DemandStateType.Finished) {
      icon = (
        <FontAwesomeIcon icon={faCircleCheck} color={COLORS.successIconColor} />
      );
    } else if (data.historyItem.state === DemandStateType.Canceled) {
      icon = <FontAwesomeIcon icon={faCircleXmark} color={COLORS.errorColor} />;
    } else {
      icon = <div></div>;
    }

    const diff = getHistoryDiffTime(data.historyItem);

    if (data.historyItem.state !== DemandStateType.New) {
      additional1 = (
        <div>
          {format(data.historyItem.finishedAt!, DATE_FORMAT)} {diff}
        </div>
      );
      additional2 = (
        <DiagramUser title={data.historyItem.userFinishedName ?? undefined}>
          {data.historyItem.userFinishedName}
        </DiagramUser>
      );
    } else {
      additional1 = <div>{diff}</div>;
    }
  }

  return (
    <DiagramTemplateWrapper
      highlight={!!data.highlight}
      canClick={!data.disableClick}
      notFound={!!data.notFound}
      historyMode={!!data.historyItem}
    >
      <PortWidgetWrapperTop>
        <StyledPortWidget port={portTop} engine={engine} />
      </PortWidgetWrapperTop>
      {portTopLinksCount > 1 && <DiagramTopPortMode mode={data.topPortMode} />}
      <DiagramTitle title={data.name}>{data.name}</DiagramTitle>
      {!!data.historyItem && (
        <>
          <DiagramHistoryColumnsDiv>
            <div>
              <FontAwesomeIcon icon={faStar} />
            </div>
            <div>{format(data.historyItem.createdAt, DATE_FORMAT)}</div>
            {icon}
            {additional1}
          </DiagramHistoryColumnsDiv>
          {additional2}
        </>
      )}
      <PortWidgetWrapperBottom>
        <StyledPortWidget port={portBottom} engine={engine} />
      </PortWidgetWrapperBottom>
    </DiagramTemplateWrapper>
  );
};

class Port extends DefaultPortModel {
  constructor(alignment: PortModelAlignment) {
    super({
      type: NAME,
      name: alignment,
      alignment: alignment,
      in: alignment === PortModelAlignment.TOP,
    });
  }

  createLinkModel(factory?: AbstractModelFactory<LinkModel>): LinkModel {
    if (factory) {
      return factory.generateModel({});
    }

    const diagramSettings = selectDiagramSettings(store.getState());
    if (diagramSettings.linkCurved) {
      return new DefaultLinkModel();
    }

    return new RightAngleLinkModel();
  }
}

export const DiagramTemplateRegister = (engine: DiagramEngine) => {
  engine.getNodeFactories().registerFactory(new Factory());
};
