import { createAction } from "@reduxjs/toolkit";
import { IUserItem } from "../../models/user";
import { StoreState, StoreStateDontLoad } from "../storeState";
import { IUserListFilter, UserListTypes } from "./types";

export const userListFetchRequest = createAction<
  StoreStateDontLoad,
  UserListTypes.USER_LIST_FETCH_REQUEST
>(UserListTypes.USER_LIST_FETCH_REQUEST);

export const userListFetchSuccess = createAction<
  { data: IUserItem[]; newCountState: StoreStateDontLoad; newCount: number },
  UserListTypes.USER_LIST_FETCH_SUCCESS
>(UserListTypes.USER_LIST_FETCH_SUCCESS);

export const userListFetchError = createAction<
  boolean,
  UserListTypes.USER_LIST_FETCH_ERROR
>(UserListTypes.USER_LIST_FETCH_ERROR);

export const userListCountRequest = createAction<
  void,
  UserListTypes.USER_LIST_COUNT_REQUEST
>(UserListTypes.USER_LIST_COUNT_REQUEST);

export const userListCountSuccess = createAction<
  number,
  UserListTypes.USER_LIST_COUNT_SUCCESS
>(UserListTypes.USER_LIST_COUNT_SUCCESS);

export const userListCountError = createAction<
  boolean,
  UserListTypes.USER_LIST_COUNT_ERROR
>(UserListTypes.USER_LIST_COUNT_ERROR);

export const userListChangeOrder = createAction<
  { orderBy: string; orderDesc: boolean },
  UserListTypes.USER_LIST_CHANGE_ORDER
>(UserListTypes.USER_LIST_CHANGE_ORDER);

export const userListChangePage = createAction<
  number,
  UserListTypes.USER_LIST_CHANGE_PAGE
>(UserListTypes.USER_LIST_CHANGE_PAGE);

export const userListChangeFilter = createAction<
  IUserListFilter,
  UserListTypes.USER_LIST_CHANGE_FILTER
>(UserListTypes.USER_LIST_CHANGE_FILTER);

export const userListReset = createAction<void, UserListTypes.USER_LIST_RESET>(
  UserListTypes.USER_LIST_RESET
);

export const userListLoadAction = createAction<
  { newState: StoreState; newCountState: StoreStateDontLoad },
  UserListTypes.USER_LIST_LOAD
>(UserListTypes.USER_LIST_LOAD);
