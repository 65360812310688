import { CancelToken } from "axios";
import { useFormikContext } from "formik";
import { FC } from "react";
import { InputContainer, InputRow } from "../../../styles/form";
import FormError from "../form/FormError";
import { InputLabel, InputTextAfter } from "../form/InputStyles";
import RequiredMark from "../form/RequiredMark";
import Suggestion, { ISuggestionValue } from "./Suggestion";

interface IProps {
  nameId: string;
  nameText: string;
  label?: string;
  textAfter?: string | JSX.Element;
  tooltipLeft?: boolean;
  showListAlways?: boolean;
  disabled?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
  loadSuggestions(
    text: string,
    cancelToken: CancelToken,
    size?: number
  ): Promise<ISuggestionValue[]>;
}

const SuggestionFormik: FC<IProps> = ({
  nameId,
  nameText,
  label,
  textAfter,
  tooltipLeft,
  showListAlways,
  disabled,
  placeholder,
  autoFocus,
  loadSuggestions,
}) => {
  const {
    values,
    errors,
    touched,
    setFieldTouched,
    setFieldValue,
    validateForm,
  } = useFormikContext<any>();

  const handleSelectValue = (value: ISuggestionValue | undefined) => {
    if (value === undefined) {
      setFieldValue(nameId, undefined, true);
      setFieldValue(nameText, undefined, true);
      validateForm({ ...values, [nameId]: undefined });
    } else {
      setFieldValue(nameId, value.id, true);
      setFieldValue(nameText, value.text, true);
      validateForm({ ...values, [nameId]: value.id });
    }
  };

  const handleBlur = () => {
    setFieldTouched(nameId, true, true);
  };

  const error = touched[nameId] && !!errors[nameId];
  const selected = values[nameId]
    ? {
        id: values[nameId],
        text: values[nameText],
      }
    : undefined;

  return (
    <InputRow>
      {label && (
        <InputLabel htmlFor={nameId}>
          {label}
          {errors[nameId] && <RequiredMark />}
        </InputLabel>
      )}
      <InputContainer>
        <Suggestion
          id={nameId}
          selected={selected}
          error={error}
          showListAlways={showListAlways}
          disabled={disabled}
          disabledBySelected={!!selected?.id}
          placeholder={placeholder}
          autoFocus={autoFocus}
          loadSuggestions={loadSuggestions}
          selectValue={handleSelectValue}
          onBlur={handleBlur}
        />
        {textAfter && <InputTextAfter>{textAfter}</InputTextAfter>}
        {error && <FormError name={nameId} tooltipLeft={tooltipLeft} />}
      </InputContainer>
    </InputRow>
  );
};

export default SuggestionFormik;
