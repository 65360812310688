import {
  faCalendarAlt,
  faChevronLeft,
  faCog,
  faHome,
  faList,
  faPlug,
  faUsers,
  faWheelchair,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RightType } from "../../models/auth";
import { ISidebarLink } from "../../models/common";
import { IApplicationState } from "../../store";
import { selectIdentityRights } from "../../store/identity";
import { COLORS } from "../../styles/colors";
import { allListingRights, filterMenu } from "../../utils/rights";
import { SetState } from "../../utils/types";
import {
  HeaderMenuLink,
  HeaderMenuLinks,
  HeaderMenuSidebarHeader,
  HeaderMenuVisibility,
} from "./HeaderMenuStyles";

const links = [
  {
    id: "calendar",
    icon: faCalendarAlt,
    rights: [
      RightType.ReadCalendarList,
      RightType.ReadCalendarMy,
      RightType.ReadCalendarTeamWeek,
      RightType.ReadCalendarTeamDay,
    ],
  },
  { id: "demand", icon: faList, rights: [RightType.ReadDemand] },
  {
    id: "client",
    icon: faUsers,
    rights: [
      RightType.ReadAllClients,
      RightType.ReadMyClients,
      RightType.ReadClientsInTheSameAssistance,
    ],
  },
  { id: "assistance", icon: faWheelchair, rights: [RightType.ReadAssistances] },
  {
    id: "module",
    icon: faPlug,
    rights: [
      RightType.ReadAllTemplates,
      RightType.ReadWorkflowTemplate,
      RightType.ReadWorkflow,
      RightType.ReadReporting,
    ],
  },
  {
    id: "admin",
    icon: faCog,
    rights: [
      RightType.ReadUsers,
      RightType.ReadRole,
      RightType.ReadUserGroups,
      RightType.ReadCareType,
      ...allListingRights,
      RightType.ReadNotification,
      RightType.ReadBasicSettings,
      RightType.ReadPublic,
      RightType.ReadMonitor,
      RightType.ReadLog,
    ],
  },
];

interface IProps {
  open: boolean;
  identityRights?: RightType[];
  sidebarTitle: string | ReactNode | undefined;
  sidebarLinks: ISidebarLink[] | undefined;
  setOpen: SetState<boolean>;
}

const HeaderMenu: FC<IProps> = ({
  open,
  identityRights,
  sidebarTitle,
  sidebarLinks,
  setOpen,
}) => {
  const { t } = useTranslation();
  const [switchedToFirst, setSwitchedToFirst] = useState(true);

  useEffect(() => {
    setSwitchedToFirst(!sidebarLinks);
  }, [sidebarLinks, open]);

  const handleSwitchMenu = () => {
    setSwitchedToFirst(true);
  };

  let firstOpen = HeaderMenuVisibility.Hide;
  let secondOpen = HeaderMenuVisibility.Hide;

  if (open) {
    if (!sidebarLinks || switchedToFirst) {
      firstOpen = HeaderMenuVisibility.ShowAlways;
    } else {
      firstOpen = HeaderMenuVisibility.LargeOnly;
      secondOpen = HeaderMenuVisibility.SmallOnly;
    }
  }

  return (
    <>
      <HeaderMenuLinks visibility={firstOpen} left={true}>
        <li key="dashboard" onClick={() => setOpen(false)}>
          <HeaderMenuLink to="/">
            {t(`header.dashboard`)}
            <FontAwesomeIcon icon={faHome} color={COLORS.menuColor} />
          </HeaderMenuLink>
        </li>

        {filterMenu(identityRights, links).map((l) => (
          <li key={l.id} onClick={() => setOpen(false)}>
            <HeaderMenuLink to={"/" + l.id}>
              {t(`header.${l.id}`)}
              <FontAwesomeIcon icon={l.icon} color={COLORS.menuColor} />
            </HeaderMenuLink>
          </li>
        ))}
      </HeaderMenuLinks>
      <HeaderMenuLinks visibility={secondOpen} left={true}>
        <HeaderMenuSidebarHeader onClick={handleSwitchMenu}>
          <FontAwesomeIcon icon={faChevronLeft} color={COLORS.sidebarColor} />
          {sidebarTitle}
        </HeaderMenuSidebarHeader>
        {sidebarLinks?.map((l) => (
          <li key={l.key} onClick={() => setOpen(false)}>
            <HeaderMenuLink to={l.to}>
              {l.text}
              <FontAwesomeIcon icon={l.icon} color={COLORS.menuColor} />
            </HeaderMenuLink>
          </li>
        ))}
      </HeaderMenuLinks>
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    identityRights: selectIdentityRights(state),
  };
};

export default connect(mapStateToProps)(HeaderMenu);
