import { createReducer } from "@reduxjs/toolkit";
import { IClientListState } from "./types";
import * as actions from "./actions";
import { StoreState, StoreStateDontLoad } from "../storeState";
import { STORAGE_RESET } from "../storeReset";

const initialState = {
  state: StoreState.None,
  data: [],
  countState: StoreStateDontLoad.DontLoad,
  count: 0,
  page: 0,
  orderBy: "LastName",
  orderDesc: false,
  filter: {
    search: undefined,
    isOpen: false,

    isStateBasic: true,
    isStateNew: true,
    isStateActive: true,
    isStateInActive: false,
    isStateRejected: false,
    isStateDead: false,
    isStateAnonymised: false,

    keyCaregiverId: 0,
    keyCaregiverName: "",

    assistanceId: 0,
    assistanceName: "",
  },
} as IClientListState;

export const clientListReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(actions.clientListFetchRequest, (state, action) => ({
      ...state,
      state: StoreState.Loading,
      countState: action.payload,
    }))
    .addCase(actions.clientListFetchSuccess, (state, action) => ({
      ...state,
      state: StoreState.Loaded,
      lastLoaded: new Date(),
      data: action.payload.data,
      countState: action.payload.newCountState,
      count: action.payload.newCount,
    }))
    .addCase(actions.clientListFetchError, (state, action) => ({
      ...state,
      state: action.payload ? StoreState.Cancel : StoreState.Error,
    }))
    .addCase(actions.clientListCountRequest, (state) => ({
      ...state,
      countState: StoreStateDontLoad.Loading,
    }))
    .addCase(actions.clientListCountSuccess, (state, action) => ({
      ...state,
      countState: StoreStateDontLoad.Loaded,
      count: action.payload,
    }))
    .addCase(actions.clientListCountError, (state, action) => ({
      ...state,
      countState: action.payload
        ? StoreStateDontLoad.Cancel
        : StoreStateDontLoad.Error,
    }))
    .addCase(actions.clientListChangeOrder, (state, action) => ({
      ...state,
      state: StoreState.None,
      orderBy: action.payload.orderBy,
      orderDesc: action.payload.orderDesc,
      page: 0,
    }))
    .addCase(actions.clientListChangePage, (state, action) => ({
      ...state,
      state: StoreState.None,
      page: action.payload,
    }))
    .addCase(actions.clientListChangeFilter, (state, action) => ({
      ...state,
      state: StoreState.None,
      countState: StoreStateDontLoad.DontLoad,
      filter: action.payload,
      page: 0,
    }))
    .addCase(actions.clientListReset, (state) => ({
      ...initialState,
      filter: state.filter,
    }))
    .addCase(actions.clientListLoadAction, (state, action) => ({
      ...state,
      state: action.payload.newState,
      countState: action.payload.newCountState,
    }))
    .addCase(STORAGE_RESET, () => initialState)
);
