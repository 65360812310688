import { AppThunkActionAsync } from "..";
import changeDemandsApi from "../../api/changeDemands";
import * as actions from "./actions";

export const getChangeDemand =
  (id: number): AppThunkActionAsync =>
  async (dispatch) => {
    dispatch(actions.changeDemandsFetchRequest());
    try {
      const response = await changeDemandsApi.getChangeDemandDetail(id);
      dispatch(actions.changeDemandsFetchSuccess(response.data));
    } catch {
      dispatch(actions.changeDemandsFetchError());
    }
  };
