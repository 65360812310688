import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router";
import { IDemandHistory, IDemandHistoryItem } from "../../models/demand";
import { IApplicationState } from "../../store";
import { StoreState } from "../../store/storeState";
import {
  getDemandHistory,
  selectDemandHistory,
  selectDemandHistoryState,
} from "../../store/demandHistory";
import { FormButton } from "../../styles/button";
import Loader from "../common/Loader";
import Page from "../layout/Page";
import createEngine from "@projectstorm/react-diagrams";
import Diagram from "../common/diagram/Diagram";
import {
  getDiagramSettings,
  selectDiagramSettings,
  selectDiagramSettingsState,
} from "../../store/diagramSettings";
import { IDiagramSettings } from "../../models/diagram";

interface IProps {
  demandHistoryState: StoreState;
  demandHistory: IDemandHistory | null;
  getDemandHistory(demandId: number): void;
  diagramSettingsState: StoreState;
  diagramSettings: IDiagramSettings;
  getDiagramSettings(): void;
}

const DemandHistory: FC<IProps> = ({
  demandHistoryState,
  demandHistory,
  getDemandHistory,
  diagramSettingsState,
  diagramSettings,
  getDiagramSettings,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { demandId } = useParams();
  const [loading, setLoading] = useState(true);
  const returnTo = new URLSearchParams(location.search).get("return");

  const [diagramEngine] = useState(
    createEngine({
      registerDefaultDeleteItemsAction: false,
      registerDefaultPanAndZoomCanvasAction: false,
      registerDefaultZoomCanvasAction: false,
      repaintDebounceMs: 0,
    })
  );

  useEffect(() => {
    getDemandHistory(parseInt(demandId!));
    getDiagramSettings();
    setLoading(false);
  }, [demandId, getDemandHistory, getDiagramSettings]);

  const handleCancel = () => {
    navigate(returnTo ?? "/demand/" + demandId);
  };

  const handleOpenDemand = (item: IDemandHistoryItem) => {
    navigate("/demand/" + item.id + "?return=/demand/" + demandId + "/history");
  };

  if (
    loading ||
    demandHistoryState === StoreState.Loading ||
    diagramSettingsState === StoreState.Loading
  ) {
    return <Loader />;
  }

  if (
    demandHistoryState === StoreState.Error ||
    diagramSettingsState === StoreState.Error
  ) {
    return t("errors.unknown");
  }

  return (
    <Page maxWidth={true}>
      <Diagram
        settings={diagramSettings}
        engine={diagramEngine}
        definition={demandHistory!.definition}
        disableChanges={true}
        demands={demandHistory!.demands}
        highlightId={parseInt(demandId!)}
        openDemand={handleOpenDemand}
      />
      <FormButton ver="secondary" type="button" onClick={handleCancel}>
        {t("common.back")}
      </FormButton>
    </Page>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    demandHistoryState: selectDemandHistoryState(state),
    demandHistory: selectDemandHistory(state),
    diagramSettingsState: selectDiagramSettingsState(state),
    diagramSettings: selectDiagramSettings(state),
  };
};

const mapDispachToProps = {
  getDemandHistory,
  getDiagramSettings,
};

export default connect(mapStateToProps, mapDispachToProps)(DemandHistory);
