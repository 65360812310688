import { FC } from "react";
import { InputLabel } from "./InputStyles";
import {
  MultiCheckInputContainer,
  MultiCheckInputRow,
} from "./MultiCheckInputStyles";

const MultiCheckInput: FC<{
  label?: string;
}> = ({ label, children }) => {
  return (
    <MultiCheckInputRow>
      {label && <InputLabel>{label}</InputLabel>}
      <MultiCheckInputContainer>{children}</MultiCheckInputContainer>
    </MultiCheckInputRow>
  );
};

export default MultiCheckInput;
