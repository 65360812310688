import { FC, ReactNode, useEffect } from "react";
import { ISidebarLink } from "../../models/common";
import { SetState } from "../../utils/types";

interface IProps {
  title: string | ReactNode;
  value: {
    setSidebarTitle?: SetState<string | ReactNode | undefined>;
    setSidebarLinks?: SetState<ISidebarLink[] | undefined>;
  };
  links: ISidebarLink[];
}

const SidebarConsumer: FC<IProps> = ({ title, value, links }) => {
  useEffect(() => {
    value.setSidebarTitle?.(title);
    value.setSidebarLinks?.(links);

    return () => {
      value.setSidebarLinks?.(undefined);
    };
  }, [title, value, links]);

  return null;
};

export default SidebarConsumer;
