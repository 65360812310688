import { AppThunkAction, AppThunkActionAsync } from "..";
import * as actions from "./actions";
import assistanceApi from "../../api/assistance";

export function getAssistancePerson(
  assistancePersonId: number
): AppThunkActionAsync {
  return async (dispatch) => {
    dispatch(actions.assistancePersonFetchRequest());
    try {
      const person = await assistanceApi.getPerson(assistancePersonId);
      dispatch(actions.assistancePersonFetchSuccess(person.data));
    } catch {
      dispatch(actions.assistancePersonFetchError());
    }
  };
}

export function getDefaultAssistancePerson(): AppThunkAction {
  return (dispatch) => {
    const data = {
      id: 0,
      type: { id: 0, name: "" },
      firstName: "",
      lastName: "",
    };

    dispatch(actions.assistancePersonFetchSuccess(data));
  };
}
