import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { IDashboardLink } from "../../models/dashboard";
import { IApplicationState } from "../../store";
import {
  getDashboardLinks,
  selectDashboardLink,
  selectDashboardLinkState,
} from "../../store/dashboardLink";
import { StoreState } from "../../store/storeState";
import Loader from "../common/Loader";
import DashboardLink from "./DashboardLink";
import { DashboardLinksContainer } from "./DashboardLinksStyles";

interface IProps {
  dashboardLinkState: StoreState;
  dashboardLinks: IDashboardLink[];
  getDashboardLinks(): void;
}

const DashboardLinks: FC<IProps> = ({
  dashboardLinkState,
  dashboardLinks,
  getDashboardLinks,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    getDashboardLinks();
  }, [getDashboardLinks]);

  if (dashboardLinkState === StoreState.Error) {
    return t("errors.unknown");
  }

  if (dashboardLinkState !== StoreState.Loaded) {
    return <Loader />;
  }

  return (
    <DashboardLinksContainer>
      {dashboardLinks.map((x, i) => (
        <DashboardLink key={i} path={x.path} />
      ))}
    </DashboardLinksContainer>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    dashboardLinkState: selectDashboardLinkState(state),
    dashboardLinks: selectDashboardLink(state),
  };
};

const mapDispachToProps = {
  getDashboardLinks,
};

export default connect(mapStateToProps, mapDispachToProps)(DashboardLinks);
