import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import { FC } from "react";
import { COLORS } from "../../../styles/colors";
import { DATE_FORMAT } from "../../../utils/consts";
import {
  CalendarDateSelectorContainer,
  CalendarDateSelectorIconWrapper,
  CalendarDateSelectorText,
} from "./CalendarStyles";

interface IProps {
  date: Date;
  onClickPrev(): void;
  onClickNext(): void;
  onReset(): void;
}

const CalendarDateSelector: FC<IProps> = ({
  date,
  onClickPrev,
  onClickNext,
  onReset,
}) => {
  return (
    <CalendarDateSelectorContainer>
      <CalendarDateSelectorIconWrapper onClick={() => onClickPrev()}>
        <FontAwesomeIcon
          icon={faAngleLeft}
          color={COLORS.calendarButtonsColor}
        />
      </CalendarDateSelectorIconWrapper>
      <CalendarDateSelectorText onClick={() => onReset()}>
        {format(date, DATE_FORMAT)}
      </CalendarDateSelectorText>
      <CalendarDateSelectorIconWrapper onClick={() => onClickNext()}>
        <FontAwesomeIcon
          icon={faAngleRight}
          color={COLORS.calendarButtonsColor}
        />
      </CalendarDateSelectorIconWrapper>
    </CalendarDateSelectorContainer>
  );
};

export default CalendarDateSelector;
