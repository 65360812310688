import React, { FC, useMemo } from "react";
import { BaseModel } from "@projectstorm/react-canvas-core";
import {
  DefaultPortModel,
  LinkModel,
  NodeModel,
  PortModelAlignment,
} from "@projectstorm/react-diagrams";
import { DiagramStartNode } from "./DiagramStart";
import { DiagramEndNode } from "./DiagramEnd";
import { DiagramMultipleNode } from "./DiagramMultiple";
import { DiagramActionType } from "./DiagramTypes";
import { useTranslation } from "react-i18next";
import { DiagramMenuItem } from "./DiagramStyles";
import { DiagramWorkflowOutNode } from "./DiagramWorkflowOut";

interface IDiagramItem {
  name: string;
  type: DiagramActionType;
}

interface IProps {
  entity: BaseModel;
  port: DefaultPortModel | null;
  action: (
    entity: BaseModel,
    port: DefaultPortModel | null,
    type: DiagramActionType
  ) => void;
}

const DiagramMenuContent: FC<IProps> = ({ entity, port, action }) => {
  const { t } = useTranslation();

  const items = useMemo(() => {
    const items: Array<IDiagramItem> = [];

    if (entity instanceof LinkModel) {
      const e = entity as LinkModel;
      const parent = e.getTargetPort().getParent();

      if (parent instanceof DiagramEndNode) {
        items.push({ name: t("diagram.add"), type: DiagramActionType.Add });
      } else {
        items.push({
          name: t("diagram.addParallel"),
          type: DiagramActionType.Add,
        });
        items.push({
          name: t("diagram.deleteLink"),
          type: DiagramActionType.Delete,
        });
      }
    } else if (entity instanceof NodeModel) {
      if (
        !(entity instanceof DiagramEndNode) &&
        !(entity instanceof DiagramStartNode)
      ) {
        items.push({
          name: t("diagram.edit"),
          type: DiagramActionType.Edit,
        });
      }

      if (!(entity instanceof DiagramWorkflowOutNode)) {
        if (
          !(entity instanceof DiagramMultipleNode) ||
          (port && port.getName() !== PortModelAlignment.TOP)
        ) {
          items.push({ name: t("diagram.add"), type: DiagramActionType.Add });
          items.push({
            name: t("diagram.addLink"),
            type: DiagramActionType.Link,
          });
        }
      }

      if (
        !(entity instanceof DiagramEndNode) &&
        !(entity instanceof DiagramStartNode)
      ) {
        items.push({
          name: t("diagram.delete"),
          type: DiagramActionType.Delete,
        });
      }
    }

    const handleClick = (item: IDiagramItem) => {
      action(entity, port, item.type);
    };

    return (
      <>
        {items.map((x) => (
          <DiagramMenuItem key={x.name} onClick={() => handleClick(x)}>
            {x.name}
          </DiagramMenuItem>
        ))}
      </>
    );
  }, [entity, port, action, t]);

  return items;
};

export default DiagramMenuContent;
