import { createAction } from "@reduxjs/toolkit";
import { IClientItem } from "../../models/client";
import { StoreState, StoreStateDontLoad } from "../storeState";
import { ClientListTypes, IClientListFilter } from "./types";

export const clientListFetchRequest = createAction<
  StoreStateDontLoad,
  ClientListTypes.CLIENT_LIST_FETCH_REQUEST
>(ClientListTypes.CLIENT_LIST_FETCH_REQUEST);

export const clientListFetchSuccess = createAction<
  { data: IClientItem[]; newCountState: StoreStateDontLoad; newCount: number },
  ClientListTypes.CLIENT_LIST_FETCH_SUCCESS
>(ClientListTypes.CLIENT_LIST_FETCH_SUCCESS);

export const clientListFetchError = createAction<
  boolean,
  ClientListTypes.CLIENT_LIST_FETCH_ERROR
>(ClientListTypes.CLIENT_LIST_FETCH_ERROR);

export const clientFillAllFieldsForSearch = createAction<
  boolean,
  ClientListTypes.CLIENT_FILL_ALL_FIELDS_FOR_SEARCH
>(ClientListTypes.CLIENT_FILL_ALL_FIELDS_FOR_SEARCH);

export const clientListCountRequest = createAction<
  void,
  ClientListTypes.CLIENT_LIST_COUNT_REQUEST
>(ClientListTypes.CLIENT_LIST_COUNT_REQUEST);

export const clientListCountSuccess = createAction<
  number,
  ClientListTypes.CLIENT_LIST_COUNT_SUCCESS
>(ClientListTypes.CLIENT_LIST_COUNT_SUCCESS);

export const clientListCountError = createAction<
  boolean,
  ClientListTypes.CLIENT_LIST_COUNT_ERROR
>(ClientListTypes.CLIENT_LIST_COUNT_ERROR);

export const clientListChangeOrder = createAction<
  { orderBy: string; orderDesc: boolean },
  ClientListTypes.CLIENT_LIST_CHANGE_ORDER
>(ClientListTypes.CLIENT_LIST_CHANGE_ORDER);

export const clientListChangePage = createAction<
  number,
  ClientListTypes.CLIENT_LIST_CHANGE_PAGE
>(ClientListTypes.CLIENT_LIST_CHANGE_PAGE);

export const clientListChangeFilter = createAction<
  IClientListFilter,
  ClientListTypes.CLIENT_LIST_CHANGE_FILTER
>(ClientListTypes.CLIENT_LIST_CHANGE_FILTER);

export const clientListReset = createAction<
  void,
  ClientListTypes.CLIENT_LIST_RESET
>(ClientListTypes.CLIENT_LIST_RESET);

export const clientListLoadAction = createAction<
  { newState: StoreState; newCountState: StoreStateDontLoad },
  ClientListTypes.CLIENT_LIST_LOAD
>(ClientListTypes.CLIENT_LIST_LOAD);
