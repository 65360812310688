import { INotification } from "../../models/notification";
import { StoreState } from "../storeState";

export enum NotificationTypes {
  NOTIFICATION_FETCH_REQUEST = "@@notification/FETCH_REQUEST",
  NOTIFICATION_FETCH_SUCCESS = "@@notification/FETCH_SUCCESS",
  NOTIFICATION_FETCH_ERROR = "@@notification/FETCH_ERROR",
}

export interface INotificationState {
  state: StoreState;
  data: INotification | null;
}
