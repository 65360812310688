import { addDays, startOfDay, subDays } from "date-fns";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CalendarItemType,
  ICalendarCol,
  ICalendarCols,
  ICalendarItem,
  ICalendarResponseItem,
} from "../../models/calendar";
import { getCalendarOneDay } from "../../utils/calendar";
import Calendar from "../common/calendar/Calendar";
import { CalendarHeader } from "../common/calendar/CalendarStyles";
import CalendarDateSelector from "../common/calendar/CalendarDateSelector";
import calendarApi from "../../api/calendar";
import userApi from "../../api/user";
import { IUserItem } from "../../models/user";
import { Button } from "../../styles/button";
import { SpaceBetweenButtons, VerticalSpace } from "../../styles/spaces";
import CalendarModal from "./CalendarModal";
import CalendarDetailModal, {
  ICalendarDetailModalData,
} from "./CalendarDetailModal";
import { useNavigate } from "react-router";
import { errorToast } from "../../utils/toasts";
import { RightType } from "../../models/auth";
import { IApplicationState } from "../../store";
import { connect } from "react-redux";
import { selectIdentityRights } from "../../store/identity";
import { hasRight } from "../../utils/rights";
import formatters from "../../utils/formatters";

interface IProps {
  identityRights?: RightType[];
}

const CalendarTeamDay: FC<IProps> = ({ identityRights }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [loadingUsers, setLoadingUsers] = useState<boolean>(true);
  const [labels, setLabels] = useState<string[]>([]);
  const [cols, setCols] = useState<ICalendarCol[]>([]);
  const [date, setDate] = useState(startOfDay(new Date()));
  const [users, setUsers] = useState<IUserItem[]>([]);
  const [checkedIds, setCheckedIds] = useState<number[]>([]);
  const [isAdding, setIsAdding] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const [openDetailData, setOpenDetailData] =
    useState<ICalendarDetailModalData | null>(null);

  const getData = async (
    date: Date,
    checkedIds: number[]
  ): Promise<ICalendarCols> => {
    let apiData = [] as ICalendarResponseItem[];
    if (checkedIds.length !== 0) {
      const response = await calendarApi.getCalendar(
        date,
        addDays(date, 1),
        checkedIds
      );
      apiData = response.data;
    }

    const checkedUsers = users
      .filter((x) => checkedIds.includes(x.id))
      .map((x) => ({
        id: x.id,
        name: formatters.fullName(x),
      }));

    const data = getCalendarOneDay(date, checkedUsers, apiData, true);
    return data;
  };

  const handleOnClick = (type: CalendarItemType, item: ICalendarItem) => {
    setOpenDetailData({ type, item });
  };

  const handlePrev = () => {
    const newDate = subDays(date, 1);
    setDate(newDate);
  };

  const handleNext = () => {
    const newDate = addDays(date, 1);
    setDate(newDate);
  };

  const handleReset = () => {
    const newDate = startOfDay(new Date());
    setDate(newDate);
  };

  const handleInputOnChange = (id: number) => {
    if (checkedIds.find((x) => x === id) === undefined) {
      setCheckedIds([...checkedIds, id]);
    } else {
      setCheckedIds(checkedIds.filter((x) => x !== id));
    }
  };

  const handleOpen = (isAdding: boolean) => {
    setIsAdding(isAdding);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleConfirm = () => {
    setReload(!reload);
    setIsOpen(false);
  };

  const handleDetailDataClose = () => {
    setOpenDetailData(null);
  };

  const handleAddAction = () => {
    navigate("/calendar/list/new?return=/calendar/teamday");
  };

  const handleSelectAll = () => {
    checkedIds.length === users.length
      ? setCheckedIds([])
      : setCheckedIds(users.map((x) => x.id));
  };

  useEffect(() => {
    (async () => {
      setLoadingUsers(true);
      try {
        //todo: better endpoint
        const users = await userApi.getList({});
        setUsers(users.data);
        setCheckedIds(users.data.map((x) => x.id));
      } catch (err) {
        errorToast();
      }
      setLoadingUsers(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  useEffect(() => {
    (async () => {
      if (loadingUsers) {
        return;
      }

      setLoadingData(true);
      try {
        const data = await getData(date, checkedIds);
        setLabels(data.labels);
        setCols(data.cols);
      } catch (err) {
        errorToast();
      }
      setLoadingData(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, checkedIds, loadingUsers, reload, t]);

  //todo: select users?
  return (
    <>
      {hasRight(identityRights, [RightType.WriteCalendarTeamDay]) && (
        <>
          <Button onClick={() => handleOpen(true)}>
            {t("calendar.addButton")}
          </Button>
          <SpaceBetweenButtons />
          <Button ver="secondary" onClick={() => handleOpen(false)}>
            {t("calendar.deleteButton")}
          </Button>
          <SpaceBetweenButtons />
          <Button ver="secondary" onClick={handleAddAction}>
            {t("calendar.addAction")}
          </Button>
        </>
      )}

      <CalendarModal
        isAdding={isAdding}
        selectUser={false}
        selectFrom={date}
        selectTo={date}
        selectOnce={false}
        isOpen={isOpen}
        close={handleClose}
        confirm={handleConfirm}
      />
      <CalendarDetailModal
        data={openDetailData}
        close={handleDetailDataClose}
      />
      <VerticalSpace />
      <div style={{ marginBottom: "0.5rem" }}>
        <input
          id="select-all"
          type="checkbox"
          style={{ width: "1.5rem", height: "1.5rem", marginRight: "0.5rem" }}
          checked={checkedIds.length === users.length}
          onChange={handleSelectAll}
        />
        <label style={{ fontWeight: "bold" }} htmlFor="select-all">
          {checkedIds.length === users.length
            ? t("common.unselectAll")
            : t("common.selectAll")}
        </label>
      </div>
      {users.map((x) => (
        <p key={x.id}>
          <input
            id={"user" + x.id}
            name={"user" + x.id}
            type="checkbox"
            style={{ width: "1.5rem", height: "1.5rem", marginRight: "0.5rem" }}
            checked={checkedIds.find((c) => c === x.id) !== undefined}
            onChange={() => handleInputOnChange(x.id)}
          />
          <label htmlFor={"user" + x.id}>{formatters.fullName(x)}</label>
        </p>
      ))}
      <CalendarHeader>
        <CalendarDateSelector
          date={date}
          onClickPrev={handlePrev}
          onClickNext={handleNext}
          onReset={handleReset}
        />
      </CalendarHeader>
      <Calendar
        labels={labels}
        cols={cols}
        onClick={handleOnClick}
        loading={loadingUsers || loadingData}
      />
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    identityRights: selectIdentityRights(state),
  };
};

export default connect(mapStateToProps)(CalendarTeamDay);
