import { CancelToken } from "axios";
import { FC, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ClientDebtType, IClientDebtItem } from "../../models/client";
import { IApplicationState } from "../../store";
import { IFilterType } from "../../store/filterType";
import {
  changeClientDebtListFilter,
  changeClientDebtListOrder,
  changeClientDebtListPage,
  getClientDebtList,
  getClientDebtListCount,
  IClientDebtListState,
  selectClientDebtList,
  clientDebtListLoad,
} from "../../store/clientDebtList";
import FilterPanel from "../common/filter/FilterPanel";
import Grid from "../common/grid/Grid";
import {
  GridIconAdd,
  GridIconDelete,
  GridIconEdit,
  GridIconSpace,
} from "../common/grid/GridIcons";
import { TableCol, TableColInput } from "../common/grid/TableCol";
import { RightType } from "../../models/auth";
import { hasClientWriteRight } from "../../utils/rights";
import { selectIdentityRights } from "../../store/identity";
import { selectClientId } from "../../store/client";
import { Form, Formik, useFormikContext } from "formik";
import Input from "../common/form/Input";
import { GridInlineButtons } from "../common/grid/GridInline";
import * as Yup from "yup";
import clientApi from "../../api/client";
import validations from "../../utils/validations";
import {
  promiseToastDeleteNoException,
  promiseToastSave,
} from "../../utils/toasts";
import { ModalYesNoFunction } from "../common/modal/ModalFunctions";

const defaultData = {
  id: 0,
  type: ClientDebtType.Loan,
  amount: 0,
  amountPerMonth: 0,
  description: "",
} as IClientDebtItem;

interface IProps {
  prov: IClientDebtListState;
  clientId?: number;
  identityRights?: RightType[];
  getClientDebtList(clientId: number, cancelToken: CancelToken): void;
  getClientDebtListCount(clientId: number, cancelToken: CancelToken): void;
  changeClientDebtListOrder(orderBy: string, orderDesc: boolean): void;
  changeClientDebtListPage(page: number): void;
  changeClientDebtListFilter(filter: IFilterType): void;
  clientDebtListLoad(reload: boolean): void;
}

const ClientFinanceDebtGridEditor: FC<{
  data: IClientDebtItem;
  handleCancel(): void;
}> = ({ data, handleCancel }) => {
  const { t } = useTranslation();
  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    resetForm,
    setFieldValue,
  } = useFormikContext<IClientDebtItem>();

  useEffect(() => {
    resetForm();
    setFieldValue("type", data.type);
    setFieldValue("amount", data.amount ? data.amount : "");
    setFieldValue(
      "amountPerMonth",
      data.amountPerMonth ? data.amountPerMonth : ""
    );
    setFieldValue("description", data.description ?? "");
  }, [data, resetForm, setFieldValue]);

  return (
    <>
      <TableColInput>
        <Input
          name="type"
          error={touched.type && !!errors.type}
          component="select"
        >
          {(
            Object.keys(ClientDebtType) as Array<keyof typeof ClientDebtType>
          ).map((key) => (
            <option key={key} value={ClientDebtType[key]}>
              {t("client.finance.debt.types." + ClientDebtType[key])}
            </option>
          ))}
        </Input>
      </TableColInput>
      <TableColInput>
        <Input
          name="amount"
          type="number"
          error={touched.amount && !!errors.amount}
        />
      </TableColInput>
      <TableColInput>
        <Input
          name="amountPerMonth"
          type="number"
          error={touched.amountPerMonth && !!errors.amountPerMonth}
        />
      </TableColInput>
      <TableColInput>
        <Input
          name="description"
          error={touched.description && !!errors.description}
          maxLength={512}
        />
      </TableColInput>
      <GridInlineButtons
        handleCancel={handleCancel}
        isSubmitting={isSubmitting}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

const ClientFinanceDebt: FC<IProps> = ({
  prov,
  clientId,
  identityRights,
  getClientDebtList,
  getClientDebtListCount,
  changeClientDebtListOrder,
  changeClientDebtListPage,
  changeClientDebtListFilter,
  clientDebtListLoad,
}) => {
  const { t } = useTranslation();
  const [inlineEditId, setInlineEditId] = useState<number | undefined>();
  const [inlineAdd, setInlineAdd] = useState(false);

  useEffect(() => {
    clientDebtListLoad(false);
  }, [clientDebtListLoad]);

  const handleEdit = (id: number) => {
    setInlineAdd(false);
    setInlineEditId(id);
  };

  const handleDelete = async (id: number) => {
    const res = await ModalYesNoFunction(
      t("client.finance.debt.delete"),
      t("client.finance.debt.deleteConfirm")
    );
    if (res) {
      await promiseToastDeleteNoException(async () => {
        await clientApi.deleteClientDebt(id);
        clientDebtListLoad(true);
      });
    }
  };

  const handleCancel = () => {
    setInlineEditId(undefined);
    setInlineAdd(false);
  };

  const handleAdd = () => {
    setInlineEditId(undefined);
    setInlineAdd(true);
  };

  const handleRenderData = (item: IClientDebtItem): ReactElement => {
    return (
      <>
        <TableCol>{t("client.finance.debt.types." + item.type)}</TableCol>
        <TableCol>{item.amount}</TableCol>
        <TableCol>{item.amountPerMonth}</TableCol>
        <TableCol>{item.description}</TableCol>
        <TableCol>
          {!inlineEditId &&
            !inlineAdd &&
            hasClientWriteRight(identityRights, [
              RightType.WriteClientsFinancingData,
            ]) && (
              <>
                <GridIconEdit onClick={() => handleEdit(item.id)} />
                <GridIconSpace />
                <GridIconDelete onClick={() => handleDelete(item.id)} />
              </>
            )}
        </TableCol>
      </>
    );
  };

  const handleSubmit = async (data: IClientDebtItem) => {
    await promiseToastSave(async () => {
      if (inlineEditId) {
        await clientApi.updateClientDebt(inlineEditId, data);
      } else {
        await clientApi.createClientDebt(clientId!, data);
      }
      clientDebtListLoad(true);
      handleCancel();
    });
  };

  const handleRenderEditor = (item: IClientDebtItem): ReactElement => {
    return (
      <ClientFinanceDebtGridEditor data={item} handleCancel={handleCancel} />
    );
  };

  const handleRowClick = (item: IClientDebtItem) => {
    setInlineEditId(item.id);
  };

  const handleGetData = (cancelToken: CancelToken) =>
    getClientDebtList(clientId!, cancelToken);

  const handleGetCount = (cancelToken: CancelToken) =>
    getClientDebtListCount(clientId!, cancelToken);

  return (
    <>
      <FilterPanel
        viewFiltration={!inlineEditId && !inlineAdd}
        title={t("client.finance.debt.detailTitle")}
        name="clientDebt"
        filter={prov.filter!}
        changeFilter={changeClientDebtListFilter}
      />

      <Formik
        initialValues={defaultData}
        validationSchema={Yup.object({
          amount: validations.numberRequiredMinMax(1, 999999999, t),
          amountPerMonth: validations.numberRequiredMinMax(1, 999999, t),
        })}
        validateOnMount={true}
        onSubmit={handleSubmit}
      >
        <Form>
          <Grid<IClientDebtItem>
            headers={[
              { captionStr: "client.finance.debt.type" },
              { captionStr: "client.finance.debt.amount" },
              { captionStr: "client.finance.debt.amountPerMonth" },
              { captionStr: "client.finance.debt.description" },
              {
                captionEl:
                  !inlineEditId &&
                  !inlineAdd &&
                  hasClientWriteRight(identityRights, [
                    RightType.WriteClientsFinancingData,
                  ]) ? (
                    <GridIconAdd onClick={handleAdd} />
                  ) : undefined,
              },
            ]}
            inlineEditId={inlineEditId}
            inlineAdd={inlineAdd}
            defaultData={defaultData}
            renderData={handleRenderData}
            renderEditor={handleRenderEditor}
            getData={handleGetData}
            getCount={handleGetCount}
            changeOrder={changeClientDebtListOrder}
            changePage={changeClientDebtListPage}
            onRowClick={
              hasClientWriteRight(identityRights, [
                RightType.WriteClientsFinancingData,
              ])
                ? handleRowClick
                : undefined
            }
            prov={prov}
          />
        </Form>
      </Formik>
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    prov: selectClientDebtList(state),
    clientId: selectClientId(state),
    identityRights: selectIdentityRights(state),
  };
};

const mapDispachToProps = {
  getClientDebtList,
  getClientDebtListCount,
  changeClientDebtListOrder,
  changeClientDebtListPage,
  changeClientDebtListFilter,
  clientDebtListLoad,
};

export default connect(mapStateToProps, mapDispachToProps)(ClientFinanceDebt);
