import { createAction } from "@reduxjs/toolkit";
import { IWorkflowItem } from "../../models/workflow";
import { IFilterType } from "../filterType";
import { StoreState, StoreStateDontLoad } from "../storeState";
import { WorkflowListTypes } from "./types";

export const workflowListFetchRequest = createAction<
  StoreStateDontLoad,
  WorkflowListTypes.WORKFLOW_LIST_FETCH_REQUEST
>(WorkflowListTypes.WORKFLOW_LIST_FETCH_REQUEST);

export const workflowListFetchSuccess = createAction<
  {
    data: IWorkflowItem[];
    newCountState: StoreStateDontLoad;
    newCount: number;
  },
  WorkflowListTypes.WORKFLOW_LIST_FETCH_SUCCESS
>(WorkflowListTypes.WORKFLOW_LIST_FETCH_SUCCESS);

export const workflowListFetchError = createAction<
  boolean,
  WorkflowListTypes.WORKFLOW_LIST_FETCH_ERROR
>(WorkflowListTypes.WORKFLOW_LIST_FETCH_ERROR);

export const workflowListCountRequest = createAction<
  void,
  WorkflowListTypes.WORKFLOW_LIST_COUNT_REQUEST
>(WorkflowListTypes.WORKFLOW_LIST_COUNT_REQUEST);

export const workflowListCountSuccess = createAction<
  number,
  WorkflowListTypes.WORKFLOW_LIST_COUNT_SUCCESS
>(WorkflowListTypes.WORKFLOW_LIST_COUNT_SUCCESS);

export const workflowListCountError = createAction<
  boolean,
  WorkflowListTypes.WORKFLOW_LIST_COUNT_ERROR
>(WorkflowListTypes.WORKFLOW_LIST_COUNT_ERROR);

export const workflowListChangeOrder = createAction<
  { orderBy: string; orderDesc: boolean },
  WorkflowListTypes.WORKFLOW_LIST_CHANGE_ORDER
>(WorkflowListTypes.WORKFLOW_LIST_CHANGE_ORDER);

export const workflowListChangePage = createAction<
  number,
  WorkflowListTypes.WORKFLOW_LIST_CHANGE_PAGE
>(WorkflowListTypes.WORKFLOW_LIST_CHANGE_PAGE);

export const workflowListChangeFilter = createAction<
  IFilterType,
  WorkflowListTypes.WORKFLOW_LIST_CHANGE_FILTER
>(WorkflowListTypes.WORKFLOW_LIST_CHANGE_FILTER);

export const workflowListReset = createAction<
  void,
  WorkflowListTypes.WORKFLOW_LIST_RESET
>(WorkflowListTypes.WORKFLOW_LIST_RESET);

export const workflowListLoadAction = createAction<
  { newState: StoreState; newCountState: StoreStateDontLoad },
  WorkflowListTypes.WORKFLOW_LIST_LOAD
>(WorkflowListTypes.WORKFLOW_LIST_LOAD);
