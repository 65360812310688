import { IAssistancePerson } from "../../models/assistance";
import { StoreState } from "../storeState";

export enum AssistancePersonTypes {
  ASSISTANCE_PERSON_FETCH_REQUEST = "@@assistancePerson/FETCH_REQUEST",
  ASSISTANCE_PERSON_FETCH_SUCCESS = "@@assistancePerson/FETCH_SUCCESS",
  ASSISTANCE_PERSON_FETCH_ERROR = "@@assistancePerson/FETCH_ERROR",
}

export interface IAssistancePersonState {
  state: StoreState;
  data: IAssistancePerson | null;
}
