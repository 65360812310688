import { IItemIdName } from "../../models/common";
import { IFilterType } from "../filterType";
import { IListType } from "../listType";

export enum ClientRightGroupListTypes {
  CLIENT_RIGHT_GROUP_LIST_FETCH_REQUEST = "@@clientRightGroupList/FETCH_REQUEST",
  CLIENT_RIGHT_GROUP_LIST_FETCH_SUCCESS = "@@clientRightGroupList/FETCH_SUCCESS",
  CLIENT_RIGHT_GROUP_LIST_FETCH_ERROR = "@@clientRightGroupList/FETCH_ERROR",
  CLIENT_RIGHT_GROUP_LIST_COUNT_REQUEST = "@@clientRightGroupList/COUNT_REQUEST",
  CLIENT_RIGHT_GROUP_LIST_COUNT_SUCCESS = "@@clientRightGroupList/COUNT_SUCCESS",
  CLIENT_RIGHT_GROUP_LIST_COUNT_ERROR = "@@clientRightGroupList/COUNT_ERROR",
  CLIENT_RIGHT_GROUP_LIST_CHANGE_ORDER = "@@clientRightGroupList/CHANGE_ORDER",
  CLIENT_RIGHT_GROUP_LIST_CHANGE_PAGE = "@@clientRightGroupList/CHANGE_PAGE",
  CLIENT_RIGHT_GROUP_LIST_CHANGE_FILTER = "@@clientRightGroupList/CHANGE_FILTER",
  CLIENT_RIGHT_GROUP_LIST_RESET = "@@clientRightGroupList/RESET",
  CLIENT_RIGHT_GROUP_LIST_LOAD = "@@clientRightGroupList/LOAD",
}

export interface IClientRightGroupListState
  extends IListType<IItemIdName, IFilterType> {}
