import { FC } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { RightType } from "../../models/auth";
import { IApplicationState } from "../../store";
import { openActionListFilter } from "../../store/actionList";
import { openAssistanceListFilter } from "../../store/assistanceList";
import { openClientListFilter } from "../../store/clientList";
import { openDemandListFilter } from "../../store/demandList";
import { selectIdentityRights } from "../../store/identity";
import { openUserListFilter } from "../../store/userList";
import { Button, ButtonContainer } from "../../styles/button";
import { hasRight } from "../../utils/rights";
import Page from "../layout/Page";

const Header = styled.h2`
  margin-bottom: 1rem;
`;

interface IProps {
  identityRights?: RightType[];
  openActionListFilter(): void;
  openDemandListFilter(): void;
  openClientListFilter(): void;
  openAssistanceListFilter(): void;
  openUserListFilter(): void;
}

const ModuleReport: FC<IProps> = ({
  identityRights,
  openActionListFilter,
  openDemandListFilter,
  openClientListFilter,
  openAssistanceListFilter,
  openUserListFilter,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleExportCalendarList = () => {
    openActionListFilter();
    navigate(`/calendar/list`);
  };

  const handleExportDemand = () => {
    openDemandListFilter();
    navigate(`/demand`);
  };

  const handleExportClient = () => {
    openClientListFilter();
    navigate(`/client`);
  };

  const handleExportAssistance = () => {
    openAssistanceListFilter();
    navigate(`/assistance`);
  };

  const handleExportUser = () => {
    openUserListFilter();
    navigate(`/admin/user`);
  };

  return (
    <Page>
      <Header>{t("module.report.dataExport.title")}</Header>

      <ButtonContainer column>
        {hasRight(identityRights, [RightType.ReadCalendarList]) && (
          <Button onClick={handleExportCalendarList}>
            {t("module.report.dataExport.calendarList")}
          </Button>
        )}

        {hasRight(identityRights, [RightType.ReadDemand]) && (
          <Button onClick={handleExportDemand}>
            {t("module.report.dataExport.demand")}
          </Button>
        )}

        {hasRight(identityRights, [
          RightType.ReadAllClients,
          RightType.ReadMyClients,
          RightType.ReadClientsInTheSameAssistance,
        ]) && (
          <Button onClick={handleExportClient}>
            {t("module.report.dataExport.client")}
          </Button>
        )}

        {hasRight(identityRights, [RightType.ReadAssistances]) && (
          <Button onClick={handleExportAssistance}>
            {t("module.report.dataExport.assistance")}
          </Button>
        )}

        {hasRight(identityRights, [RightType.ReadUsers]) && (
          <Button onClick={handleExportUser}>
            {t("module.report.dataExport.user")}
          </Button>
        )}
      </ButtonContainer>
    </Page>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    identityRights: selectIdentityRights(state),
  };
};

const mapDispatchToProps = {
  openActionListFilter,
  openDemandListFilter,
  openClientListFilter,
  openAssistanceListFilter,
  openUserListFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModuleReport);
