import { addDays, startOfWeek, subDays } from "date-fns";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CalendarItemType,
  ICalendarCol,
  ICalendarCols,
  ICalendarItem,
} from "../../models/calendar";
import {
  getCalendar,
  transformCalendarReponseSimple,
} from "../../utils/calendar";
import Calendar from "../common/calendar/Calendar";
import { CalendarHeader } from "../common/calendar/CalendarStyles";
import CalendarDateSelector from "../common/calendar/CalendarDateSelector";
import calendarApi from "../../api/calendar";
import { connect } from "react-redux";
import { IApplicationState } from "../../store";
import { selectIdentityId, selectIdentityRights } from "../../store/identity";
import CalendarModal from "./CalendarModal";
import { Button } from "../../styles/button";
import { SpaceBetweenButtons } from "../../styles/spaces";
import CalendarDetailModal, {
  ICalendarDetailModalData,
} from "./CalendarDetailModal";
import { useNavigate } from "react-router";
import { errorToast } from "../../utils/toasts";
import { hasRight } from "../../utils/rights";
import { RightType } from "../../models/auth";

interface IProps {
  identityId?: number;
  identityRights?: RightType[];
}

const CalendarMy: FC<IProps> = ({ identityId, identityRights }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [labels, setLabels] = useState<string[]>([]);
  const [cols, setCols] = useState<ICalendarCol[]>([]);
  const [mondayDate, setMondayDate] = useState(
    startOfWeek(new Date(), { weekStartsOn: 1 })
  );
  const [isAdding, setIsAdding] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const [openDetailData, setOpenDetailData] =
    useState<ICalendarDetailModalData | null>(null);

  const getData = async (mondayDate: Date): Promise<ICalendarCols> => {
    const apiData = await calendarApi.getCalendar(
      mondayDate,
      addDays(mondayDate, 7),
      [identityId!]
    );
    const transformed = transformCalendarReponseSimple(apiData.data);
    const data = getCalendar(mondayDate, 7, transformed, true);
    return data;
  };

  const handleOnClick = (type: CalendarItemType, item: ICalendarItem) => {
    setOpenDetailData({ type, item });
  };

  const handlePrev = () => {
    const newMonday = subDays(mondayDate, 7);
    setMondayDate(newMonday);
  };

  const handleNext = () => {
    const newMonday = addDays(mondayDate, 7);
    setMondayDate(newMonday);
  };

  const handleReset = () => {
    const newMonday = startOfWeek(new Date(), { weekStartsOn: 1 });
    setMondayDate(newMonday);
  };

  const handleOpen = (isAdding: boolean) => {
    setIsAdding(isAdding);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleConfirm = () => {
    setReload(!reload);
    setIsOpen(false);
  };

  const handleDetailDataClose = () => {
    setOpenDetailData(null);
  };

  const handleAddAction = () => {
    navigate("/calendar/list/new?return=/calendar/my");
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const data = await getData(mondayDate);
        setLabels(data.labels);
        setCols(data.cols);
      } catch (err) {
        errorToast();
      }
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mondayDate, reload, t]);

  return (
    <>
      {hasRight(identityRights, [RightType.WriteCalendarMy]) && (
        <>
          <Button onClick={() => handleOpen(true)}>
            {t("calendar.addButton")}
          </Button>
          <SpaceBetweenButtons />
          <Button ver="secondary" onClick={() => handleOpen(false)}>
            {t("calendar.deleteButton")}
          </Button>
          <SpaceBetweenButtons />
          <Button ver="secondary" onClick={handleAddAction}>
            {t("calendar.addAction")}
          </Button>
        </>
      )}

      <CalendarModal
        isAdding={isAdding}
        selectUser={true}
        selectFrom={mondayDate}
        selectTo={addDays(mondayDate, 6)}
        selectOnce={false}
        isOpen={isOpen}
        close={handleClose}
        confirm={handleConfirm}
      />
      <CalendarDetailModal
        data={openDetailData}
        close={handleDetailDataClose}
      />
      <CalendarHeader>
        <CalendarDateSelector
          date={mondayDate}
          onClickPrev={handlePrev}
          onClickNext={handleNext}
          onReset={handleReset}
        />
      </CalendarHeader>
      <Calendar
        labels={labels}
        cols={cols}
        loading={loading}
        onClick={handleOnClick}
      />
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    identityId: selectIdentityId(state),
    identityRights: selectIdentityRights(state),
  };
};

export default connect(mapStateToProps)(CalendarMy);
