import { FC } from "react";
import { BaseModel } from "@projectstorm/react-canvas-core";

import { DiagramMenuContainer } from "./DiagramStyles";
import DiagramMenuContent from "./DiagramMenuContent";
import { DiagramActionType } from "./DiagramTypes";
import { DefaultPortModel } from "@projectstorm/react-diagrams";

interface IProps {
  entity: BaseModel | null;
  coordinates: {
    x: number;
    y: number;
  } | null;
  port: DefaultPortModel | null;
  action: (
    entity: BaseModel,
    port: DefaultPortModel | null,
    type: DiagramActionType
  ) => void;
}

const DiagramMenu: FC<IProps> = ({ entity, coordinates, port, action }) => {
  if (!coordinates) return null;

  return (
    <DiagramMenuContainer
      style={{
        left: coordinates.x,
        top: coordinates.y,
      }}
    >
      {!!entity && (
        <DiagramMenuContent entity={entity} port={port} action={action} />
      )}
    </DiagramMenuContainer>
  );
};

export default DiagramMenu;
