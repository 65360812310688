import { AppThunkAction, AppThunkActionAsync } from "..";
import * as actions from "./actions";
import dashboardApi from "../../api/dashboard";
import { selectDashboardLinkState } from ".";
import { StoreState } from "../storeState";
import { IDashboardLink } from "../../models/dashboard";

export function getDashboardLinks(): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectDashboardLinkState(getState());
    if (state === StoreState.Loading || state === StoreState.Loaded) {
      return;
    }

    dispatch(actions.dashboardLinkFetchRequest());
    try {
      const links = await dashboardApi.getDashboardLinks();
      dispatch(actions.dashboardLinkFetchSuccess(links.data));
    } catch {
      dispatch(actions.dashboardLinkFetchError());
    }
  };
}

export function updateDashboardLinks(links: IDashboardLink[]): AppThunkAction {
  return (dispatch) => {
    dispatch(actions.dashboardLinkFetchSuccess(links));
  };
}
