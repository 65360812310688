import { IReportItem } from "../../models/report";
import { IFilterType } from "../filterType";
import { IListType } from "../listType";

export enum ReportListTypes {
  REPORT_LIST_FETCH_REQUEST = "@@reportList/FETCH_REQUEST",
  REPORT_LIST_FETCH_SUCCESS = "@@reportList/FETCH_SUCCESS",
  REPORT_LIST_FETCH_ERROR = "@@reportList/FETCH_ERROR",
  REPORT_LIST_COUNT_REQUEST = "@@reportList/COUNT_REQUEST",
  REPORT_LIST_COUNT_SUCCESS = "@@reportList/COUNT_SUCCESS",
  REPORT_LIST_COUNT_ERROR = "@@reportList/COUNT_ERROR",
  REPORT_LIST_CHANGE_ORDER = "@@reportList/CHANGE_ORDER",
  REPORT_LIST_CHANGE_PAGE = "@@reportList/CHANGE_PAGE",
  REPORT_LIST_CHANGE_FILTER = "@@reportList/CHANGE_FILTER",
  REPORT_LIST_RESET = "@@reportList/RESET",
  REPORT_LIST_LOAD = "@@reportList/LOAD",
}

export interface IReportListState extends IListType<IReportItem, IFilterType> {}
