import { createAction } from "@reduxjs/toolkit";
import { IAssistance } from "../../models/assistance";
import { AssistanceTypes } from "./types";

export const assistanceFetchRequest = createAction<
  void,
  AssistanceTypes.ASSISTANCE_FETCH_REQUEST
>(AssistanceTypes.ASSISTANCE_FETCH_REQUEST);

export const assistanceFetchSuccess = createAction<
  IAssistance,
  AssistanceTypes.ASSISTANCE_FETCH_SUCCESS
>(AssistanceTypes.ASSISTANCE_FETCH_SUCCESS);

export const assistanceFetchError = createAction<
  void,
  AssistanceTypes.ASSISTANCE_FETCH_ERROR
>(AssistanceTypes.ASSISTANCE_FETCH_ERROR);
