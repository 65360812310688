import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TemplateDocumentType } from "../../models/template";
import { Button } from "../../styles/button";
import CustomModal from "../common/modal/CustomModal";
import SelectTemplateTree, { ISelectedFile } from "./SelectTemplateTree";

interface IProps {
  type: TemplateDocumentType;
  isOpen: boolean;
  close(): void;
  confirm: (file: ISelectedFile) => Promise<void>;
}

const SelectTemplateModal: FC<IProps> = ({ type, isOpen, close, confirm }) => {
  const { t } = useTranslation();
  const [saving, setSaving] = useState(false);
  const [selectedFile, setSelectedFile] = useState<ISelectedFile | null>(null);

  useEffect(() => {
    if (!isOpen) {
      setSelectedFile(null);
    }
  }, [isOpen]);

  const handleConfirm = async () => {
    setSaving(true);
    try {
      await confirm(selectedFile!);
      close();
    } finally {
      setSaving(false);
    }
  };

  return (
    <CustomModal
      title={t("selectTemplate.title")}
      isOpen={isOpen}
      close={close}
      isBig={true}
      actions={
        <>
          <Button ver="secondary" disabled={saving} onClick={() => close()}>
            {t("common.cancel")}
          </Button>
          <Button
            disabled={saving || selectedFile === null}
            onClick={handleConfirm}
          >
            {t("common.save")}
          </Button>
        </>
      }
    >
      {selectedFile && (
        <>
          <p>
            {t("selectTemplate.selected")} {selectedFile.name}
          </p>
          <p>&nbsp;</p>
        </>
      )}
      <SelectTemplateTree
        type={type}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
      />
    </CustomModal>
  );
};

export default SelectTemplateModal;
