import { IChangeDemandDetail } from "../../models/changeDemads";
import { StoreState } from "../storeState";

export enum ChangeDemandTypes {
  CHANGE_DEMANDS_FETCH_REQUEST = "@@changeDemand/FETCH_REQUEST",
  CHANGE_DEMANDS_FETCH_SUCCESS = "@@changeDemand/FETCH_SUCCESS",
  CHANGE_DEMANDS_FETCH_ERROR = "@@changeDemand/FETCH_ERROR",
}

export interface IChangeDemandState {
  state: StoreState;
  data: IChangeDemandDetail | null;
}
