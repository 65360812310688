import { AxiosResponse } from "axios";
import { IDocumentEditOnlineUrl } from "../models/document";
import api from "../utils/api";

const getEditOnlineUrl = async (
  documentId: number
): Promise<AxiosResponse<IDocumentEditOnlineUrl>> =>
  api.get(`/api/Document/${documentId}/EditOnline`);

const getDocumentBytes = async ({
  documentId,
  clientId,
}: {
  documentId: number;
  clientId: number;
}): Promise<AxiosResponse<string>> =>
  api.get(`/api/Document/${documentId}/Client/${clientId}/Download`);

const documentApi = {
  getEditOnlineUrl,
  getDocumentBytes,
};

export default documentApi;
