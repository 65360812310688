import { WorkflowType } from "../../models/workflow";
import { StoreState } from "../storeState";

export enum WorkflowUnsetTypesTypes {
  WORKFLOW_UNSET_TYPES_FETCH_REQUEST = "@@workflowUnsetTypes/FETCH_REQUEST",
  WORKFLOW_UNSET_TYPES_FETCH_SUCCESS = "@@workflowUnsetTypes/FETCH_SUCCESS",
  WORKFLOW_UNSET_TYPES_FETCH_ERROR = "@@workflowUnsetTypes/FETCH_ERROR",
  WORKFLOW_UNSET_TYPES_RESET = "@@workflowUnsetTypes/RESET",
}

export interface IWorkflowUnsetTypesState {
  state: StoreState;
  lastLoaded?: Date;
  data: WorkflowType[];
}
