import { AxiosRequestConfig, AxiosResponse } from "axios";
import { IItemIdName } from "../models/common";
import { IRole, IRoleItem } from "../models/role";
import api from "../utils/api";

const getList = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<IRoleItem[]>> => api.get(`/api/Role/List`, config);

const getCount = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<number>> => api.get(`/api/Role/Count`, config);

const getSuggestionList = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<IItemIdName[]>> =>
  api.get(`/api/Role/Suggested/List`, config);

const getRole = async (roleId: number): Promise<AxiosResponse<IRole>> =>
  api.get(`/api/Role/${roleId}`);

const updateRole = async (role: IRole): Promise<AxiosResponse<IRole>> =>
  api.put(`/api/Role/${role.id}`, role);

const createRole = async (role: IRole): Promise<AxiosResponse<IRole>> =>
  api.post(`/api/Role`, role);

const roleApi = {
  getList,
  getCount,
  getSuggestionList,
  getRole,
  updateRole,
  createRole,
};

export default roleApi;
