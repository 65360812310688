import styled from "styled-components";

export const CustomTreeRootUl = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

export const CustomTreeUl = styled.ul`
  list-style-type: none;
  padding-inline-start: 2rem;
`;

export const CustomTreeLi = styled.li`
  user-select: none;
`;

export const CustomTreeFolder = styled.span`
  cursor: pointer;
  user-select: none;
`;

export const CustomTreeFile = styled.span`
  cursor: pointer;
  padding: 0.2rem;

  &.selected {
    color: var(--selected-color);
    background-color: var(--selected-bg);
  }
`;
