import { AxiosRequestConfig, AxiosResponse } from "axios";
import { IChangeDemandDetail, IChangeDemandItem } from "../models/changeDemads";
import api from "../utils/api";

const getChangeDemands = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<IChangeDemandItem[]>> => {
  const response = await api.get(`/api/ClientDemand/GetList`, config);
  return response;
};

const getChangeDemandsCount = async (
  config: AxiosRequestConfig
): Promise<
  AxiosResponse<{
    count: number;
  }>
> => {
  const response = await api.get(`/api/ClientDemand/GetCount`, config);
  return response;
};

const getChangeDemandDetail = async (
  id: number
): Promise<AxiosResponse<IChangeDemandDetail>> => {
  const response = await api.get(`/api/ClientDemand/${id}/OpenDemand`);
  return response;
};

const approvedChangeDemand = async (
  id: number,
  note: string
): Promise<AxiosResponse<string>> => {
  const response = await api.get(`/api/ClientDemand/${id}/AcceptDemand`, {
    params: {
      note,
    },
  });
  return response;
};

const rejectChangeDemand = async (
  id: number,
  note: string
): Promise<AxiosResponse<string>> => {
  const response = await api.get(`/api/ClientDemand/${id}/DeclineDemand`, {
    params: {
      note,
    },
  });
  return response;
};

const getUrlForDownloadDocument = async (
  demandId: number,
  documentId: number
): Promise<AxiosResponse<string>> => {
  const response = await api.get(
    `/api/ClientDemand/${demandId}/File/${documentId}`
  );
  return response;
};

const changeDemandsApi = {
  getChangeDemands,
  getChangeDemandsCount,
  getChangeDemandDetail,
  approvedChangeDemand,
  rejectChangeDemand,
  getUrlForDownloadDocument,
};

export default changeDemandsApi;
