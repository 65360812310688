import { createAction } from "@reduxjs/toolkit";
import { IChangeDemandDetail } from "../../models/changeDemads";
import { ChangeDemandTypes } from "./types";

export const changeDemandsFetchRequest = createAction<
  void,
  ChangeDemandTypes.CHANGE_DEMANDS_FETCH_REQUEST
>(ChangeDemandTypes.CHANGE_DEMANDS_FETCH_REQUEST);

export const changeDemandsFetchSuccess = createAction<
  IChangeDemandDetail,
  ChangeDemandTypes.CHANGE_DEMANDS_FETCH_SUCCESS
>(ChangeDemandTypes.CHANGE_DEMANDS_FETCH_SUCCESS);

export const changeDemandsFetchError = createAction<
  void,
  ChangeDemandTypes.CHANGE_DEMANDS_FETCH_ERROR
>(ChangeDemandTypes.CHANGE_DEMANDS_FETCH_ERROR);
