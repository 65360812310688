import { CancelToken } from "axios";
import { FC, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { IApplicationState } from "../../store";
import { IFilterType } from "../../store/filterType";
import {
  changeClientRightGroupListFilter,
  changeClientRightGroupListOrder,
  changeClientRightGroupListPage,
  getClientRightGroupList,
  getClientRightGroupListCount,
  IClientRightGroupListState,
  selectClientRightGroupList,
  clientRightGroupListLoad,
} from "../../store/clientRightGroupList";
import FilterPanel from "../common/filter/FilterPanel";
import Grid from "../common/grid/Grid";
import { GridIconAdd, GridIconRemove } from "../common/grid/GridIcons";
import { TableCol, TableColInput } from "../common/grid/TableCol";
import { RightType } from "../../models/auth";
import { selectIdentityRights } from "../../store/identity";
import { selectClientId } from "../../store/client";
import { Form, Formik, useFormikContext } from "formik";
import { GridInlineButtons } from "../common/grid/GridInline";
import * as Yup from "yup";
import clientApi from "../../api/client";
import validations from "../../utils/validations";
import SuggestionFormik from "../common/suggestion/SuggestionFormik";
import { handleLoadGroupSuggestions } from "../../utils/suggestions";
import { IItemIdName } from "../../models/common";
import { promiseToastSaveNoException } from "../../utils/toasts";
import { hasClientWriteRight } from "../../utils/rights";

const defaultData = {
  id: 0,
  name: "",
} as IItemIdName;

interface IProps {
  prov: IClientRightGroupListState;
  clientId?: number;
  identityRights?: RightType[];
  getClientRightGroupList(clientId: number, cancelToken: CancelToken): void;
  getClientRightGroupListCount(
    clientId: number,
    cancelToken: CancelToken
  ): void;
  changeClientRightGroupListOrder(orderBy: string, orderDesc: boolean): void;
  changeClientRightGroupListPage(page: number): void;
  changeClientRightGroupListFilter(filter: IFilterType): void;
  clientRightGroupListLoad(reload: boolean): void;
}

const ClientRightGroupGridEditor: FC<{
  data: IItemIdName;
  handleCancel(): void;
}> = ({ data, handleCancel }) => {
  const { isSubmitting, handleSubmit, resetForm, setFieldValue } =
    useFormikContext<IItemIdName>();

  useEffect(() => {
    resetForm();
    setFieldValue("id", data.id);
    setFieldValue("name", data.name);
  }, [data, resetForm, setFieldValue]);

  return (
    <>
      <TableColInput>
        <SuggestionFormik
          nameId="id"
          nameText="name"
          loadSuggestions={handleLoadGroupSuggestions}
          autoFocus={true}
        />
      </TableColInput>
      <GridInlineButtons
        handleCancel={handleCancel}
        isSubmitting={isSubmitting}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

const ClientRightGroup: FC<IProps> = ({
  prov,
  clientId,
  identityRights,
  getClientRightGroupList,
  getClientRightGroupListCount,
  changeClientRightGroupListOrder,
  changeClientRightGroupListPage,
  changeClientRightGroupListFilter,
  clientRightGroupListLoad,
}) => {
  const { t } = useTranslation();
  const [inlineAdd, setInlineAdd] = useState(false);

  useEffect(() => {
    clientRightGroupListLoad(false);
  }, [clientRightGroupListLoad]);

  const handleCancel = () => {
    setInlineAdd(false);
  };

  const handleAdd = () => {
    setInlineAdd(true);
  };

  const handleDelete = async (groupId: number) => {
    await clientApi.deleteClientRightGroup(clientId!, groupId);
    clientRightGroupListLoad(true);
  };

  const handleRenderData = (item: IItemIdName): ReactElement => {
    return (
      <>
        <TableCol>{item.name}</TableCol>
        <TableCol>
          {!inlineAdd &&
            hasClientWriteRight(identityRights, [
              RightType.WriteClientsRights,
            ]) && <GridIconRemove onClick={() => handleDelete(item.id)} />}
        </TableCol>
      </>
    );
  };

  const handleSubmit = async (data: IItemIdName) => {
    await promiseToastSaveNoException(async () => {
      await clientApi.createClientRightGroup(clientId!, data);
      clientRightGroupListLoad(true);
      handleCancel();
    });
  };

  const handleRenderEditor = (item: IItemIdName): ReactElement => {
    return (
      <ClientRightGroupGridEditor data={item} handleCancel={handleCancel} />
    );
  };

  const handleGetData = (cancelToken: CancelToken) =>
    getClientRightGroupList(clientId!, cancelToken);

  const handleGetCount = (cancelToken: CancelToken) =>
    getClientRightGroupListCount(clientId!, cancelToken);

  return (
    <>
      <FilterPanel
        viewFiltration={!inlineAdd}
        title={t("client.right.group.title")}
        name="clientRightGroup"
        filter={prov.filter!}
        changeFilter={changeClientRightGroupListFilter}
      />

      <Formik
        initialValues={defaultData}
        validationSchema={Yup.object({
          id: validations.idRequired(t),
        })}
        validateOnMount={true}
        onSubmit={handleSubmit}
      >
        <Form>
          <Grid<IItemIdName>
            headers={[
              { captionStr: "client.right.group.name" },
              {
                captionEl:
                  !inlineAdd &&
                  hasClientWriteRight(identityRights, [
                    RightType.WriteClientsRights,
                  ]) ? (
                    <GridIconAdd onClick={handleAdd} />
                  ) : undefined,
              },
            ]}
            inlineAdd={inlineAdd}
            defaultData={defaultData}
            renderData={handleRenderData}
            renderEditor={handleRenderEditor}
            getData={handleGetData}
            getCount={handleGetCount}
            changeOrder={changeClientRightGroupListOrder}
            changePage={changeClientRightGroupListPage}
            prov={prov}
          />
        </Form>
      </Formik>
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    prov: selectClientRightGroupList(state),
    clientId: selectClientId(state),
    identityRights: selectIdentityRights(state),
  };
};

const mapDispachToProps = {
  getClientRightGroupList,
  getClientRightGroupListCount,
  changeClientRightGroupListOrder,
  changeClientRightGroupListPage,
  changeClientRightGroupListFilter,
  clientRightGroupListLoad,
};

export default connect(mapStateToProps, mapDispachToProps)(ClientRightGroup);
