import axios from "axios";
import storageApi from "./storage";
import { addMinutes, isAfter, parseISO } from "date-fns";
import history from "../utils/history";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
});

api.interceptors.request.use(
  async (config) => {
    const tokenRefreshTimeout = storageApi.getTokenRefreshTimeout();
    const tokenTimeout = storageApi.getTokenTimeout();
    const token = storageApi.getToken();

    if (token && tokenRefreshTimeout && tokenTimeout) {
      // logout if token expired
      if (isAfter(new Date(), tokenTimeout)) {
        history.push("/login");
        return new Promise(() => false);
      }

      // renew token if it's about to expire
      if (isAfter(new Date(), tokenRefreshTimeout)) {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/Auth/TokenRenew`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        const renewData = await response.json();
        storageApi.setToken(renewData.token);
        storageApi.setIdleMinutes(renewData.userMaxIdleMinutes);
        storageApi.setTokenRefreshTimeout(
          addMinutes(new Date(), renewData.userMaxIdleMinutes)
        );
        storageApi.setTokenTimeout(
          addMinutes(new Date(), renewData.tokenValidMinutes)
        );
      }
    }

    const newToken = storageApi.getToken();
    if (newToken) {
      config.headers = {
        Authorization: newToken,
      };
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

api.interceptors.response.use(
  function (response) {
    handleDates(response.data);
    return response;
  },
  function (error) {
    if (error.request && !error.response) {
      // The request was made but no response was received.
    } else if (!error.request && !error.response) {
      // Something happened in setting up the request that triggered an Error.
    } else if (error.response.status === 401 || error.response.status === 403) {
      const token = storageApi.getToken();
      if (!token) {
        history.push("/login");
      }
    }
    return Promise.reject(error);
  }
);

//https://stackoverflow.com/questions/65692061/casting-dates-properly-from-an-api-response-in-typescript
const isoDateFormat =
  /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)$/;

function isIsoDateString(value: any): boolean {
  return value && typeof value === "string" && isoDateFormat.test(value);
}

export function handleDates(body: any) {
  if (body === null || body === undefined || typeof body !== "object")
    return body;

  for (const key of Object.keys(body)) {
    const value = body[key];
    if (isIsoDateString(value)) body[key] = parseISO(value);
    else if (typeof value === "object") handleDates(value);
  }
}

export default api;
