import { useFormikContext } from "formik";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { ClientCareStateType } from "../../models/client";
import { FormButton } from "../../styles/button";
import {
  ApiError,
  FormDesktop,
  FormGroup,
  FormTablet,
} from "../../styles/form";
import Input from "../common/form/Input";
import { SpaceBetweenButtons } from "../../styles/spaces";
import { RightType } from "../../models/auth";
import { hasClientWriteRight } from "../../utils/rights";
import SuggestionFormik from "../common/suggestion/SuggestionFormik";
import DatePicker from "../common/form/DatePicker";
import {
  handleLoadAssistanceSuggestions,
  handleLoadAssistanceCategoryListingSuggestions,
  handleLoadCareTypeSuggestions,
  handleLoadAssistanceAddressSuggestions,
} from "../../utils/suggestions";
import SubmitForm from "../common/form/SubmitForm";
import FormGroupRow from "../common/form/FormGroupRow";

export interface IClientCareForm {
  id: number;
  clientId: number;
  clientName: string;
  assistanceId: number;
  assistanceName: string;
  assistanceAddressId: number;
  assistanceAddressName: string;
  assistanceCategoryListingId: number;
  assistanceCategoryListingName: string;
  careTypeId: number;
  careTypeName: string;
  from: Date;
  to?: Date | null;
  state: ClientCareStateType;
  note?: string | null;
}

interface IProps {
  error: string | null;
  identityRights?: RightType[];
  paramClientId?: string;
}

const ClientCareDetailContent: FC<IProps> = ({
  error,
  identityRights,
  paramClientId,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { errors, touched, values, setFieldValue } =
    useFormikContext<IClientCareForm>();

  useEffect(() => {
    if (values.assistanceCategoryListingId) {
      return;
    }

    if (values.careTypeId) {
      setFieldValue("careTypeId", 0);
    }

    if (values.assistanceId) {
      setFieldValue("assistanceId", 0);
    }

    if (values.assistanceAddressId) {
      setFieldValue("assistanceAddressId", 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.assistanceCategoryListingId]);

  useEffect(() => {
    if (values.careTypeId) {
      return;
    }

    if (values.assistanceId) {
      setFieldValue("assistanceId", 0);
    }

    if (values.assistanceAddressId) {
      setFieldValue("assistanceAddressId", 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.careTypeId]);

  useEffect(() => {
    if (values.assistanceId) {
      return;
    }

    if (values.assistanceAddressId) {
      setFieldValue("assistanceAddressId", 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.assistanceId]);

  const handleCancel = () => {
    navigate(`/client/${paramClientId}/care`);
  };

  const ErrorAndButtons = () => {
    const { isSubmitting } = useFormikContext<IClientCareForm>();

    return (
      <div>
        {error && <ApiError>{error}</ApiError>}
        <FormButton
          ver="secondary"
          type="button"
          disabled={isSubmitting}
          onClick={handleCancel}
        >
          {t("common.back")}
        </FormButton>
        {hasClientWriteRight(identityRights, [RightType.WriteCare]) && (
          <>
            <SpaceBetweenButtons />
            <SubmitForm />
          </>
        )}
      </div>
    );
  };

  return (
    <>
      <FormDesktop>
        <h1>{t("client.care.detailTitle")}</h1>
        <FormGroup>
          <FormGroupRow
            gridTemplateColumns="1fr 1fr"
            names={["assistanceCategoryListingId", "careTypeId"]}
            label={t("client.care.careType")}
          >
            <SuggestionFormik
              nameId="assistanceCategoryListingId"
              nameText="assistanceCategoryListingName"
              loadSuggestions={handleLoadAssistanceCategoryListingSuggestions}
            />
            <SuggestionFormik
              nameId="careTypeId"
              nameText="careTypeName"
              loadSuggestions={(text, cancelToken, size) =>
                handleLoadCareTypeSuggestions(
                  text,
                  cancelToken,
                  size,
                  values.assistanceCategoryListingId
                )
              }
              disabled={!values.assistanceCategoryListingId}
            />
          </FormGroupRow>

          <FormGroupRow
            gridTemplateColumns="1fr 1fr"
            names={["assistanceId", "assistanceAddressId"]}
            label={t("client.care.assistanceAddress")}
          >
            <SuggestionFormik
              nameId="assistanceId"
              nameText="assistanceName"
              loadSuggestions={(text, cancelToken, size) =>
                handleLoadAssistanceSuggestions(
                  text,
                  cancelToken,
                  size,
                  values.careTypeId
                )
              }
              disabled={
                !values.assistanceCategoryListingId || !values.careTypeId
              }
            />
            <SuggestionFormik
              nameId="assistanceAddressId"
              nameText="assistanceAddressName"
              loadSuggestions={(text, cancelToken, size) =>
                handleLoadAssistanceAddressSuggestions(
                  values.assistanceId,
                  text,
                  cancelToken,
                  size,
                  values.careTypeId
                )
              }
              disabled={
                !values.assistanceCategoryListingId ||
                !values.careTypeId ||
                !values.assistanceId
              }
            />
          </FormGroupRow>

          <DatePicker name="from" label={t("client.care.from")} />
          <DatePicker name="to" label={t("client.care.to")} />
          <Input
            name="state"
            label={t("client.care.state")}
            error={touched.state && !!errors.state}
            component="select"
          >
            {(
              Object.keys(ClientCareStateType) as Array<
                keyof typeof ClientCareStateType
              >
            ).map((key) => (
              <option key={key} value={ClientCareStateType[key]}>
                {t("client.care.states." + ClientCareStateType[key])}
              </option>
            ))}
          </Input>
          <Input
            name="note"
            label={t("client.care.note")}
            error={touched.note && !!errors.note}
            maxLength={512}
          />
        </FormGroup>
        <ErrorAndButtons />
      </FormDesktop>
      <FormTablet>
        <h1>{t("client.care.detailTitle")}</h1>
        <FormGroup>
          <SuggestionFormik
            nameId="assistanceCategoryListingId"
            nameText="assistanceCategoryListingName"
            label={t("client.care.careType")}
            loadSuggestions={handleLoadAssistanceCategoryListingSuggestions}
          />
          <SuggestionFormik
            nameId="careTypeId"
            nameText="careTypeName"
            label=" "
            loadSuggestions={(text, cancelToken, size) =>
              handleLoadCareTypeSuggestions(
                text,
                cancelToken,
                size,
                values.assistanceCategoryListingId
              )
            }
            disabled={!values.assistanceCategoryListingId}
          />

          <SuggestionFormik
            nameId="assistanceId"
            nameText="assistanceName"
            label={t("client.care.assistanceAddress")}
            loadSuggestions={(text, cancelToken, size) =>
              handleLoadAssistanceSuggestions(
                text,
                cancelToken,
                size,
                values.careTypeId
              )
            }
            disabled={!values.assistanceCategoryListingId || !values.careTypeId}
          />
          <SuggestionFormik
            nameId="assistanceAddressId"
            nameText="assistanceAddressName"
            label=" "
            loadSuggestions={(text, cancelToken, size) =>
              handleLoadAssistanceAddressSuggestions(
                values.assistanceId,
                text,
                cancelToken,
                size,
                values.careTypeId
              )
            }
            disabled={
              !values.assistanceCategoryListingId ||
              !values.careTypeId ||
              !values.assistanceId
            }
          />

          <DatePicker name="from" label={t("client.care.from")} />
          <DatePicker name="to" label={t("client.care.to")} />
          <Input
            name="state"
            label={t("client.care.state")}
            error={touched.state && !!errors.state}
            component="select"
          >
            {(
              Object.keys(ClientCareStateType) as Array<
                keyof typeof ClientCareStateType
              >
            ).map((key) => (
              <option key={key} value={ClientCareStateType[key]}>
                {t("client.care.states." + ClientCareStateType[key])}
              </option>
            ))}
          </Input>
          <Input
            name="note"
            label={t("client.care.note")}
            error={touched.note && !!errors.note}
            maxLength={512}
          />
        </FormGroup>
        <ErrorAndButtons />
      </FormTablet>
    </>
  );
};

export default ClientCareDetailContent;
