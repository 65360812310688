import { AppThunkAction, AppThunkActionAsync } from "..";
import * as actions from "./actions";
import workflowTemplateApi from "../../api/workflowTemplate";

export function getWorkflowTemplate(
  workflowTemplateId: number
): AppThunkActionAsync {
  return async (dispatch) => {
    dispatch(actions.workflowTemplateFetchRequest());
    try {
      const workflowTemplate = await workflowTemplateApi.getWorkflowTemplate(
        workflowTemplateId
      );
      dispatch(actions.workflowTemplateFetchSuccess(workflowTemplate.data));
    } catch {
      dispatch(actions.workflowTemplateFetchError());
    }
  };
}

export function getDefaultWorkflowTemplate(): AppThunkAction {
  return (dispatch) => {
    const data = {
      id: 0,
      isActive: true,
      name: "",
      requirementTermInDays: 5,

      assignedUserId: 0,
      assignedUserName: "",
      canChangeAssignedUser: false,
      assignedGroupId: 0,
      assignedGroupName: "",
      canChangeAssignedGroup: false,

      notificationForCreatorOnCreateId: 0,
      notificationForCreatorOnCreateName: "",
      notificationForCreatorOnFinishId: 0,
      notificationForCreatorOnFinishName: "",
      notificationForAssignedUserOnCreateId: 0,
      notificationForAssignedUserOnCreateName: "",
      notificationForAssignedUserOnFinishId: 0,
      notificationForAssignedUserOnFinishName: "",
      notificationForAssignedGroupOnCreateId: 0,
      notificationForAssignedGroupOnCreateName: "",
      notificationForAssignedGroupOnFinishId: 0,
      notificationForAssignedGroupOnFinishName: "",
      notificationForClientOnCreateId: 0,
      notificationForClientOnCreateName: "",
      notificationForClientOnFinishId: 0,
      notificationForClientOnFinishName: "",
    };

    dispatch(actions.workflowTemplateFetchSuccess(data));
  };
}
