import * as ReselectModule from "reselect";
import { ITemplateTreeState } from ".";
import { IApplicationState } from "..";
import { ITreeReponse } from "../../components/common/tree/CustomTree";

export const selectTemplateTree: ReselectModule.ParametricSelector<
  IApplicationState,
  number,
  ITreeReponse
> = (state, id) => state.templateTree.items[id];

export const selectTemplateTreeAll: ReselectModule.Selector<
  IApplicationState,
  ITemplateTreeState
> = (state) => state.templateTree;
