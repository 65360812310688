import { createAction } from "@reduxjs/toolkit";
import { ITreeItem } from "../../components/common/tree/CustomTree";
import { TemplateTreeTypes } from "./types";

export const templateTreeFetchRequest = createAction<
  string,
  TemplateTreeTypes.TEMPLATE_TREE_FETCH_REQUEST
>(TemplateTreeTypes.TEMPLATE_TREE_FETCH_REQUEST);

export const templateTreeFetchSuccess = createAction<
  { id: string; data: ITreeItem[] },
  TemplateTreeTypes.TEMPLATE_TREE_FETCH_SUCCESS
>(TemplateTreeTypes.TEMPLATE_TREE_FETCH_SUCCESS);

export const templateTreeFetchError = createAction<
  string,
  TemplateTreeTypes.TEMPLATE_TREE_FETCH_ERROR
>(TemplateTreeTypes.TEMPLATE_TREE_FETCH_ERROR);

export const templateTreeReset = createAction<
  void,
  TemplateTreeTypes.TEMPLATE_TREE_RESET
>(TemplateTreeTypes.TEMPLATE_TREE_RESET);
