import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AssistanceStateType } from "../../models/assistance";
import Input from "../common/form/Input";
import SuggestionFormik from "../common/suggestion/SuggestionFormik";
import {
  handleLoadAssistanceCategoryListingSuggestions,
  handleLoadCareTypeSuggestions,
  handleLoadTargetGroupListingSuggestions,
} from "../../utils/suggestions";
import { useFormikContext } from "formik";
import FormGroupRow from "../common/form/FormGroupRow";
import { FormDesktopDiv, FormLaptopDiv } from "../../styles/form";

interface IAssistanceFilter {
  state?: AssistanceStateType | null;
  assistanceCategoryId: number;
  assistanceCategoryName: string;
  careTypeId: number;
  careTypeName: string;
  targetGroupId: number;
  targetGroupName: string;
}

const AssistanceFilter: FC = () => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<IAssistanceFilter>();

  useEffect(() => {
    if (!values.assistanceCategoryId) {
      return;
    }

    if (values.careTypeId) {
      setFieldValue("careTypeId", 0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.assistanceCategoryId]);

  return (
    <>
      <Input name="state" component="select" label={t("assistance.state")}>
        <option value="">{t("assistance.states.all")}</option>
        {(
          Object.keys(AssistanceStateType) as Array<
            keyof typeof AssistanceStateType
          >
        ).map((key) => (
          <option key={key} value={AssistanceStateType[key]}>
            {t("assistance.states." + AssistanceStateType[key])}
          </option>
        ))}
      </Input>

      <FormDesktopDiv>
        <FormGroupRow
          gridTemplateColumns="1fr 1fr"
          names={[]}
          label={t("assistance.careTypes")}
        >
          <SuggestionFormik
            nameId="assistanceCategoryId"
            nameText="assistanceCategoryName"
            loadSuggestions={handleLoadAssistanceCategoryListingSuggestions}
          />
          <SuggestionFormik
            nameId="careTypeId"
            nameText="careTypeName"
            loadSuggestions={(text, cancelToken) =>
              handleLoadCareTypeSuggestions(
                text,
                cancelToken,
                values.assistanceCategoryId
              )
            }
          />
        </FormGroupRow>
      </FormDesktopDiv>

      <FormLaptopDiv>
        <SuggestionFormik
          nameId="assistanceCategoryId"
          nameText="assistanceCategoryName"
          label={t("assistance.assistanceCategory")}
          loadSuggestions={handleLoadAssistanceCategoryListingSuggestions}
        />
      </FormLaptopDiv>

      <FormLaptopDiv>
        <SuggestionFormik
          nameId="careTypeId"
          nameText="careTypeName"
          label={t("assistance.careTypes")}
          loadSuggestions={(text, cancelToken, size) =>
            handleLoadCareTypeSuggestions(
              text,
              cancelToken,
              size,
              values.assistanceCategoryId
            )
          }
        />
      </FormLaptopDiv>

      <SuggestionFormik
        nameId="targetGroupId"
        nameText="targetGroupName"
        label={t("assistance.targetGroup")}
        loadSuggestions={handleLoadTargetGroupListingSuggestions}
      />
    </>
  );
};

export default AssistanceFilter;
