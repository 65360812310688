import {
  faAddressCard,
  faChevronLeft,
  faClipboard,
  faFileWord,
  faMapPin,
  faQuoteRight,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Routes, Route, useParams } from "react-router";
import { AssistanceStateType } from "../../models/assistance";
import { RightType } from "../../models/auth";
import { IApplicationState } from "../../store";
import {
  getAssistance,
  getDefaultAssistance,
  selectAssistanceDataState,
  selectAssistanceId,
  selectAssistanceName,
  selectAssistanceState,
} from "../../store/assistance";
import { assistanceAddressListLoad } from "../../store/assistanceAddressList";
import { assistanceDocumentListLoad } from "../../store/assistanceDocumentList";
import { assistancePersonListLoad } from "../../store/assistancePersonList";
import { selectIdentityRights } from "../../store/identity";
import { StoreState } from "../../store/storeState";
import { COLORS } from "../../styles/colors";
import { filterMenu } from "../../utils/rights";
import AuthRoute from "../auth/AuthRoute";
import {
  TopPanel,
  TopPanelBack,
  TopPanelCenterContainer,
  TopPanelNameContainer,
  TopPanelRouterContainer,
  TopPanelState,
  TopPanelStateContainer,
  TopPanelStateLabel,
} from "../common/TopPanelStyles";
import Loader from "../common/Loader";
import { SidebarPageContainer } from "../layout/LayoutStyles";
import Page from "../layout/Page";
import Sidebar from "../layout/Sidebar";
import AssistanceAddress from "./AssistanceAddress";
import AssistanceAddressDetail from "./AssistanceAddressDetail";
import AssistanceButton from "./AssistanceButton";
import AssistanceDescription from "./AssistanceDescription";
import AssistanceDocument from "./AssistanceDocument";
import AssistanceGeneral from "./AssistanceGeneral";
import {
  getAssistanceStateColor,
  getAssistanceStateIcon,
} from "./AssistanceHelpers";
import AssistancePerson from "./AssistancePerson";
import AssistancePersonDetail from "./AssistancePersonDetail";

const links = [
  { id: "general", icon: faAddressCard, rights: [], showAlways: true },
  { id: "person", icon: faUsers, rights: [] },
  { id: "address", icon: faMapPin, rights: [] },
  { id: "description", icon: faQuoteRight, rights: [] },
  {
    id: "document",
    icon: faFileWord,
    rights: [RightType.ReadAssistanceDocuments],
  },
  { id: "button", icon: faClipboard, rights: [RightType.WriteAssistanceState] },
];

const routes: {
  path: string;
  rights: RightType[];
  component?: ReactElement;
  showAlways?: boolean;
  isDetail?: boolean;
}[] = [
  {
    path: "general",
    rights: [],
    showAlways: true,
    component: <AssistanceGeneral />,
  },
  {
    path: "person",
    rights: [],
    component: <AssistancePerson />,
  },
  {
    path: "person/:personId",
    isDetail: true,
    rights: [],
    component: <AssistancePersonDetail />,
  },
  {
    path: "address",
    rights: [],
    component: <AssistanceAddress />,
  },
  {
    path: "address/:addressId",
    isDetail: true,
    rights: [],
    component: <AssistanceAddressDetail />,
  },
  { path: "description", rights: [], component: <AssistanceDescription /> },
  {
    path: "document",
    rights: [RightType.ReadAssistanceDocuments],
    component: <AssistanceDocument />,
  },
  {
    path: "button",
    rights: [RightType.WriteAssistanceState],
    component: <AssistanceButton />,
  },
];

interface IProps {
  assistanceState: StoreState;
  assistanceId?: number;
  assistanceName?: string;
  assistanceDataState?: AssistanceStateType;
  identityRights?: RightType[];
  getAssistance(assistanceId: number): void;
  getDefaultAssistance(): void;
  assistancePersonListLoad(reload: boolean): void;
  assistanceAddressListLoad(reload: boolean): void;
  assistanceDocumentListLoad(reload: boolean): void;
}

const AssistanceDetail: FC<IProps> = ({
  assistanceState,
  assistanceId,
  assistanceName,
  assistanceDataState,
  identityRights,
  getAssistance,
  getDefaultAssistance,
  assistancePersonListLoad,
  assistanceAddressListLoad,
  assistanceDocumentListLoad,
}) => {
  const { t } = useTranslation();
  const { assistanceId: paramAssistanceId } = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (paramAssistanceId === "new") {
      getDefaultAssistance();
    } else {
      getAssistance(parseInt(paramAssistanceId!));
    }

    assistancePersonListLoad(true);
    assistanceAddressListLoad(true);
    assistanceDocumentListLoad(true);

    setLoading(false);
  }, [
    paramAssistanceId,
    getAssistance,
    getDefaultAssistance,
    assistancePersonListLoad,
    assistanceAddressListLoad,
    assistanceDocumentListLoad,
  ]);

  if (loading || assistanceState === StoreState.Loading) {
    return <Loader />;
  }

  if (assistanceState === StoreState.Error) {
    return t("errors.unknown");
  }

  return (
    <>
      {assistanceId !== 0 && (
        <TopPanel color={getAssistanceStateColor(assistanceDataState!)}>
          <TopPanelBack to={"/assistance"}>
            <FontAwesomeIcon icon={faChevronLeft} color={COLORS.sidebarColor} />
          </TopPanelBack>
          <TopPanelCenterContainer>
            <TopPanelRouterContainer>
              {t("assistance.topPanelDetail")}
              {" / "}
              <Routes>
                {routes
                  .filter((r) => !r.isDetail)
                  .filter((r) => r.showAlways || assistanceId !== 0)
                  .map((r) => (
                    <Route
                      key={r.path}
                      path={r.path + "/*"}
                      element={
                        <AuthRoute rights={r.rights}>
                          {t(`assistance.nav.${r.path}`)}
                        </AuthRoute>
                      }
                    />
                  ))}
              </Routes>
            </TopPanelRouterContainer>
            <TopPanelNameContainer>{assistanceName}</TopPanelNameContainer>
          </TopPanelCenterContainer>
          <TopPanelStateContainer>
            <TopPanelStateLabel>{t("client.topPanelState")}</TopPanelStateLabel>
            <TopPanelState
              color={getAssistanceStateColor(assistanceDataState!)}
            >
              {getAssistanceStateIcon(assistanceDataState!, t)}
              <TopPanelStateLabel>
                {t("assistance.states." + assistanceDataState)}
              </TopPanelStateLabel>
            </TopPanelState>
          </TopPanelStateContainer>
        </TopPanel>
      )}
      <SidebarPageContainer hasTopPanel={true}>
        <Sidebar
          title={
            <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("assistance.detailTitle")}</>
          }
          showTitle={false}
          links={filterMenu(identityRights, links)
            .filter((x) => x.showAlways || assistanceId !== 0)
            .map((l) => ({
              key: l.id,
              to: `/assistance/${paramAssistanceId}/${l.id}`,
              text: t(`assistance.nav.${l.id}`),
              icon: l.icon,
            }))}
        />
        <Page hasTopPanel={true}>
          <Routes>
            {routes.map((r) =>
              r.showAlways || assistanceId !== 0 ? (
                <Route
                  key={r.path}
                  path={r.path}
                  element={
                    <AuthRoute rights={r.rights}>{r.component}</AuthRoute>
                  }
                />
              ) : undefined
            )}
          </Routes>
        </Page>
      </SidebarPageContainer>
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    assistanceState: selectAssistanceState(state),
    assistanceId: selectAssistanceId(state),
    assistanceName: selectAssistanceName(state),
    assistanceDataState: selectAssistanceDataState(state),
    identityRights: selectIdentityRights(state),
  };
};

const mapDispachToProps = {
  getAssistance,
  getDefaultAssistance,
  assistancePersonListLoad,
  assistanceAddressListLoad,
  assistanceDocumentListLoad,
};

export default connect(mapStateToProps, mapDispachToProps)(AssistanceDetail);
