import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import axios, { CancelToken, CancelTokenSource } from "axios";
import { ReactElement, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { StoreState, StoreStateDontLoad } from "../../../store/storeState";
import {
  GridHeaderContainer,
  GridOrderIcon,
  GridOrderIconContainer,
} from "./GridStyles";
import Pagination from "./Pagination";
import { Table, TableMessage } from "./table";
import { TailSpin } from "react-loader-spinner";
import { COLORS } from "../../../styles/colors";

interface IGridData {
  id: number;
}

interface IGridProps<T extends IGridData> {
  state: StoreState;
  data: T[];
  countState: StoreStateDontLoad;
  count: number;
  page: number;
  orderBy?: string;
  orderDesc?: boolean;
}

export interface IHeaderItem {
  captionStr?: string;
  captionEl?: ReactElement;
  orderName?: string;
  minContent?: boolean;
}

interface IProps<T extends IGridData> {
  headers: IHeaderItem[];
  inlineEditId?: number;
  inlineAdd?: boolean;
  defaultData?: T;
  checkbox?: boolean;
  buttons?: boolean;
  draggable?: boolean;
  droppable?: boolean;
  renderData(item: T): ReactElement;
  renderEditor?(item: T): ReactElement;
  getData(cancelToken: CancelToken): void;
  getCount(cancelToken: CancelToken): void;
  onRowClick?(item: T): void;
  onRowDoubleClick?(item: T): void;
  onDrag?(e: React.DragEvent<HTMLTableRowElement>, item: T): void;
  onDrop?(e: React.DragEvent<HTMLTableRowElement>, item: T): void;
  changeOrder(orderBy: string, orderDesc: boolean): void;
  changePage(page: number): void;
  prov: IGridProps<T>;
  firstColumnWidth?: string;
  secondColumnWidth?: string;
  thirdColumnWidth?: string;
  fourthColumnWidth?: string;
}

function Grid<T extends IGridData>({
  headers,
  inlineEditId,
  inlineAdd,
  defaultData,
  checkbox = false,
  buttons = true,
  draggable = false,
  droppable = false,
  renderData,
  renderEditor,
  getData,
  getCount,
  onRowClick,
  onRowDoubleClick,
  onDrag,
  onDrop,
  changeOrder,
  changePage,
  prov,
  thirdColumnWidth,
  firstColumnWidth,
  secondColumnWidth,
  fourthColumnWidth,
}: Readonly<IProps<T>>) {
  const { t } = useTranslation();
  const dataCancelToken = useRef<CancelTokenSource | null>(null);
  const countCancelToken = useRef<CancelTokenSource | null>(null);
  const [loading, setLoading] = useState(true);

  const isEditing = !!inlineEditId || inlineAdd;

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      countCancelToken.current?.cancel();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      dataCancelToken.current?.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (prov.state !== StoreState.None) {
      if (loading) {
        setLoading(false);
      }
      return;
    }

    dataCancelToken.current?.cancel();
    dataCancelToken.current = axios.CancelToken.source();

    getData(dataCancelToken.current.token);

    if (loading) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prov.state]);

  useEffect(() => {
    if (prov.countState !== StoreStateDontLoad.None) {
      return;
    }

    countCancelToken.current?.cancel();
    countCancelToken.current = axios.CancelToken.source();

    getCount(countCancelToken.current.token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prov.countState]);

  const headerCaption = (col: IHeaderItem) => {
    if (col.captionStr) {
      return t(col.captionStr);
    }

    return col.captionEl;
  };

  const headerOrder = (col: IHeaderItem) => {
    if (!col.orderName) {
      return;
    }

    return (
      <GridOrderIconContainer>
        <GridOrderIcon
          icon={faAngleUp}
          className={
            prov.orderBy === col.orderName && prov.orderDesc === true
              ? "active"
              : undefined
          }
          onClick={() => changeOrder(col.orderName!, true)}
        />
        <GridOrderIcon
          icon={faAngleDown}
          className={
            prov.orderBy === col.orderName && prov.orderDesc === false
              ? "active"
              : undefined
          }
          onClick={() => changeOrder(col.orderName!, false)}
        />
      </GridOrderIconContainer>
    );
  };

  const tableHeader = useMemo(
    () =>
      headers.map((col, i) => (
        <th key={i} className={col.minContent ? "min-content" : ""}>
          <GridHeaderContainer>
            {headerCaption(col)}
            {headerOrder(col)}
          </GridHeaderContainer>
        </th>
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      headers,
      prov.orderBy,
      prov.orderDesc,
      headerCaption,
      headerOrder,
      changeOrder,
    ]
  );

  const tableData = useMemo(() => {
    if (
      loading ||
      prov.state === StoreState.None ||
      prov.state === StoreState.Loading ||
      prov.state === StoreState.Cancel ||
      prov.state === StoreState.Error ||
      prov.data.length === 0
    ) {
      return;
    }

    return prov.data.map((d, i) => (
      <tr
        key={i}
        onClick={!isEditing ? () => onRowClick?.(d) : undefined}
        onDoubleClick={!isEditing ? () => onRowDoubleClick?.(d) : undefined}
        className={onRowClick && !isEditing ? "pointer" : undefined}
        draggable={draggable}
        {...(droppable && { onDragOver: (e) => e.preventDefault() })}
        onDrop={(e) => {
          const item = JSON.parse(e.dataTransfer.getData("item"));
          onDrop?.(item, d);
        }}
        onDragStart={(e) => {
          onDrag?.(e, d);
        }}
      >
        {d.id === inlineEditId ? renderEditor!(d) : renderData(d)}
      </tr>
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loading,
    prov.state,
    prov.data,
    inlineEditId,
    inlineAdd,
    renderData,
    renderEditor,
    t,
  ]);

  const numberOfColumns = tableHeader.length + (checkbox ? 1 : 0);

  return (
    <>
      <Table
        checkbox={checkbox}
        buttons={buttons}
        numberOfColumns={numberOfColumns}
        firstColumnWidth={firstColumnWidth}
        secondColumnWidth={secondColumnWidth}
        thirdColumnWidth={thirdColumnWidth}
        fourthColumnWidth={fourthColumnWidth}
      >
        <thead>
          <tr>{tableHeader}</tr>
        </thead>
        <tbody>
          {inlineAdd && <tr key="add">{renderEditor!(defaultData!)}</tr>}
          {tableData}
        </tbody>
      </Table>

      {(loading ||
        prov.state === StoreState.None ||
        prov.state === StoreState.Loading ||
        prov.state === StoreState.Cancel) && (
        <TableMessage>
          <TailSpin color={COLORS.loaderColor} width={48} height={48} />
        </TableMessage>
      )}

      {prov.state === StoreState.Error && (
        <TableMessage>{t("errors.unknown")}</TableMessage>
      )}

      {prov.state === StoreState.Loaded &&
        prov.data.length === 0 &&
        !isEditing && <TableMessage>{t("common.noData")}</TableMessage>}

      {!inlineEditId && !inlineAdd && (
        <Pagination
          state={prov.countState}
          count={prov.count}
          page={prov.page}
          changePage={changePage}
        />
      )}
    </>
  );
}

export default Grid;
