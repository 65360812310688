import { createReducer } from "@reduxjs/toolkit";
import { IDemandFilterType, IDemandListState } from "./types";
import * as actions from "./actions";
import { StoreState, StoreStateDontLoad } from "../storeState";
import { STORAGE_RESET } from "../storeReset";
import { DemandStateFilterType } from "../../models/demand";

const initialState = {
  state: StoreState.None,
  data: [],
  countState: StoreStateDontLoad.DontLoad,
  count: 0,
  page: 0,
  orderBy: "Priority",
  orderDesc: true,
  filter: {
    search: undefined,
    isOpen: false,
    from: undefined,
    to: undefined,
    state: DemandStateFilterType.New,
    users: [],
    groups: [],
    creators: [],
    clientId: undefined,
    clientName: undefined,
  } as IDemandFilterType,
} as IDemandListState;

export const demandListReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(actions.demandListFetchRequest, (state, action) => ({
      ...state,
      state: StoreState.Loading,
      countState: action.payload,
    }))
    .addCase(actions.demandListFetchSuccess, (state, action) => ({
      ...state,
      state: StoreState.Loaded,
      lastLoaded: new Date(),
      data: action.payload.data,
      countState: action.payload.newCountState,
      count: action.payload.newCount,
    }))
    .addCase(actions.demandListFetchError, (state, action) => ({
      ...state,
      state: action.payload ? StoreState.Cancel : StoreState.Error,
    }))
    .addCase(actions.demandListCountRequest, (state) => ({
      ...state,
      countState: StoreStateDontLoad.Loading,
    }))
    .addCase(actions.demandListCountSuccess, (state, action) => ({
      ...state,
      countState: StoreStateDontLoad.Loaded,
      count: action.payload,
    }))
    .addCase(actions.demandListCountError, (state, action) => ({
      ...state,
      countState: action.payload
        ? StoreStateDontLoad.Cancel
        : StoreStateDontLoad.Error,
    }))
    .addCase(actions.demandListChangeOrder, (state, action) => ({
      ...state,
      state: StoreState.None,
      orderBy: action.payload.orderBy,
      orderDesc: action.payload.orderDesc,
      page: 0,
    }))
    .addCase(actions.demandListChangePage, (state, action) => ({
      ...state,
      state: StoreState.None,
      page: action.payload,
    }))
    .addCase(actions.demandListChangeFilter, (state, action) => ({
      ...state,
      state: StoreState.None,
      countState: StoreStateDontLoad.DontLoad,
      filter: action.payload,
      page: 0,
    }))
    .addCase(actions.demandListReset, (state) => ({
      ...initialState,
      filter: state.filter,
    }))
    .addCase(actions.demandListLoadAction, (state, action) => ({
      ...state,
      state: action.payload.newState,
      countState: action.payload.newCountState,
    }))
    .addCase(STORAGE_RESET, () => initialState)
);
