import * as ReselectModule from "reselect";
import { IApplicationState } from "..";
import { IAssistancePerson } from "../../models/assistance";
import { StoreState } from "../storeState";

export const selectAssistancePersonState: ReselectModule.Selector<
  IApplicationState,
  StoreState
> = (state) => state.assistancePerson.state;

export const selectAssistancePerson: ReselectModule.Selector<
  IApplicationState,
  IAssistancePerson | null
> = (state) => state.assistancePerson.data;
