import { Form, Formik } from "formik";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { IAssistance } from "../../models/assistance";
import { IApplicationState } from "../../store";
import { selectAssistance, updateAssistance } from "../../store/assistance";
import { ApiError, FormGroup } from "../../styles/form";
import Input from "../common/form/Input";
import PhoneInput from "../common/form/PhoneInput";
import * as Yup from "yup";
import assistanceApi from "../../api/assistance";
import validations from "../../utils/validations";
import { assistanceListLoad } from "../../store/assistanceList";
import { RightType } from "../../models/auth";
import { hasRight } from "../../utils/rights";
import { selectIdentityRights } from "../../store/identity";
import { promiseToastSave } from "../../utils/toasts";
import SubmitForm from "../common/form/SubmitForm";
import { errorSet } from "../../utils/error";

interface IAssistanceForm
  extends Omit<IAssistance, "houseNumber" | "landRegistryNumber" | "postCode"> {
  houseNumber: string;
  landRegistryNumber: string;
  postCode: string;
}

interface IProps {
  assistance: IAssistance | null;
  identityRights?: RightType[];
  updateAssistance(newData: IAssistance): void;
  assistanceListLoad(reload: boolean): void;
}

const fields1: Array<{ name: keyof IAssistance; maxLen?: number }> = [
  { name: "name", maxLen: 100 },
  { name: "companyIdentificationNumber", maxLen: 15 },
  { name: "taxIdentificationNumber", maxLen: 20 },
  { name: "street", maxLen: 150 },
  { name: "houseNumber" },
  { name: "landRegistryNumber" },
  { name: "city", maxLen: 150 },
  { name: "postCode" },
];

const fields2: Array<{ name: keyof IAssistance; maxLen?: number }> = [
  { name: "dataBox", maxLen: 100 },
  { name: "webLink", maxLen: 256 },
];

const numberFields: Array<keyof IAssistance> = [
  "houseNumber",
  "landRegistryNumber",
  "postCode",
];

const AssistanceGeneral: FC<IProps> = ({
  assistance,
  identityRights,
  updateAssistance,
  assistanceListLoad,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (data: IAssistanceForm) => {
    setError(null);
    try {
      await promiseToastSave(async () => {
        const data2 = {
          ...data,
          houseNumber: +data.houseNumber,
          landRegistryNumber: +data.landRegistryNumber,
          postCode: +data.postCode,
        };

        let newData;
        if (assistance!.id === 0) {
          newData = await assistanceApi.createAssistance(data2);
        } else {
          newData = await assistanceApi.updateAssistance(data2);
        }

        updateAssistance(newData.data);
        assistanceListLoad(true);
      });
    } catch (err) {
      errorSet(setError, err, t);
    }
  };

  return (
    <Formik<IAssistanceForm>
      initialValues={{
        ...assistance!,
        mobilePhone: assistance!.mobilePhone ?? "",
        email: assistance!.email ?? "",
        phone: assistance!.phone ?? "",
        companyIdentificationNumber:
          assistance!.companyIdentificationNumber ?? "",
        taxIdentificationNumber: assistance!.taxIdentificationNumber ?? "",
        dataBox: assistance!.dataBox ?? "",
        webLink: assistance!.webLink ?? "",
        houseNumber: assistance!.houseNumber
          ? assistance!.houseNumber.toString()
          : "",
        landRegistryNumber: assistance!.landRegistryNumber
          ? assistance!.landRegistryNumber.toString()
          : "",
        postCode: assistance!.postCode ? assistance!.postCode.toString() : "",
      }}
      validationSchema={Yup.object().shape(
        {
          name: validations.stringRequired(t),
          companyIdentificationNumber: validations.companyNoOptional(t),
          taxIdentificationNumber: validations.companyVatNoOptional(t),
          street: validations.stringRequired(t),
          houseNumber: validations.intRequiredMinMax(1, 999999, t),
          landRegistryNumber: validations.intOptionalMinMax(1, 999999, t),
          city: validations.stringRequired(t),
          postCode: validations.postCodeRequired(t),
          webLink: validations.urlOptional(t),

          mobilePhone: validations.phoneOptional(t).when(["email", "phone"], {
            is: (email: any, phone: any) => !email && !phone,
            then: validations
              .phoneOptional(t)
              .required(t("assistance.anyOfValuesRequired")),
          }),
          email: validations.emailOptional(t).when(["mobilePhone", "phone"], {
            is: (mobilePhone: any, phone: any) => !mobilePhone && !phone,
            then: validations
              .emailOptional(t)
              .required(t("assistance.anyOfValuesRequired")),
          }),
          phone: validations.phoneOptional(t).when(["mobilePhone", "email"], {
            is: (mobilePhone: any, email: any) => !mobilePhone && !email,
            then: validations
              .phoneOptional(t)
              .required(t("assistance.anyOfValuesRequired")),
          }),
        },
        [
          ["mobilePhone", "email"],
          ["mobilePhone", "phone"],
          ["email", "phone"],
        ]
      )}
      enableReinitialize={true}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, isSubmitting }) => (
        <Form>
          <FormGroup>
            {fields1.map((f) => (
              <Input
                key={f.name}
                name={f.name}
                type={
                  numberFields.find((x) => x === f.name) ? "number" : "text"
                }
                label={t("assistance." + f.name)}
                error={touched[f.name] && !!errors[f.name]}
                maxLength={f.maxLen}
              />
            ))}
            <PhoneInput
              name="mobilePhone"
              label={t("assistance.mobilePhone")}
              error={touched.mobilePhone && !!errors.mobilePhone}
            />
            <PhoneInput
              name="phone"
              label={t("assistance.phone")}
              error={touched.phone && !!errors.phone}
            />
            <Input
              name="email"
              label={t("assistance.email")}
              error={touched.email && !!errors.email}
              maxLength={255}
            />
            {fields2.map((f) => (
              <Input
                key={f.name}
                name={f.name}
                type={
                  numberFields.find((x) => x === f.name) ? "number" : "text"
                }
                label={t("assistance." + f.name)}
                error={touched[f.name] && !!errors[f.name]}
                maxLength={f.maxLen}
              />
            ))}
          </FormGroup>
          {error && <ApiError>{error}</ApiError>}
          {hasRight(identityRights, [RightType.WriteAssistances]) && (
            <SubmitForm />
          )}
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    assistance: selectAssistance(state),
    identityRights: selectIdentityRights(state),
  };
};

const mapDispachToProps = {
  updateAssistance,
  assistanceListLoad,
};

export default connect(mapStateToProps, mapDispachToProps)(AssistanceGeneral);
