import { Formik, Form, FormikProps } from "formik";
import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../styles/button";
import { ApiError, FormGroup } from "../../../styles/form";
import Input from "../form/Input";
import CustomModal from "../modal/CustomModal";
import {
  DIAGRAM_SETTINGS_DEFAULT,
  IDiagramSettings,
} from "../../../models/diagram";
import { connect } from "react-redux";
import { setDiagramSettings } from "../../../store/diagramSettings";
import { errorSet } from "../../../utils/error";
import { promiseToastSave } from "../../../utils/toasts";
import authApi from "../../../api/auth";

const Checkbox: FC<{ name: string }> = ({ name }) => {
  const { t } = useTranslation();
  return (
    <Input
      name={name}
      type="checkbox"
      label={t(`diagram.settings.` + name)}
      inputWidth="1.5rem"
      inputHeight="1.5rem"
    />
  );
};

interface IProps {
  isOpen: boolean;
  settings: IDiagramSettings;
  onClose(): void;
  setDiagramSettings(data: IDiagramSettings): void;
}

const DiagramSettingsModal: FC<IProps> = ({
  isOpen,
  settings,
  onClose,
  setDiagramSettings,
}) => {
  const { t } = useTranslation();
  const formikRef = useRef<FormikProps<IDiagramSettings> | null>(null);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (isOpen) {
      formikRef.current?.resetForm();
    }
  }, [isOpen]);

  const handleSubmit = async (data: IDiagramSettings) => {
    setError(null);
    setSaving(true);
    try {
      await promiseToastSave(async () => {
        const data2 = {
          data: JSON.stringify(data),
        };

        await authApi.setDiagramSettings(data2);
        setDiagramSettings(data);
        onClose();
      });
    } catch (err) {
      errorSet(setError, err, t);
    }
    setSaving(false);
  };

  const handleReset = async () => {
    await handleSubmit(DIAGRAM_SETTINGS_DEFAULT);
  };

  return (
    <Formik<IDiagramSettings>
      initialValues={settings}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      innerRef={formikRef}
    >
      {({ isSubmitting, submitForm }) => (
        <CustomModal
          isOpen={isOpen}
          close={onClose}
          actions={
            <>
              <Button
                ver="secondary"
                type="button"
                disabled={isSubmitting || saving}
                onClick={onClose}
              >
                {t("common.close")}
              </Button>
              <Button
                ver="secondary"
                type="button"
                disabled={isSubmitting || saving}
                onClick={handleReset}
              >
                {t("common.default")}
              </Button>
              <Button
                type="button"
                onClick={submitForm}
                disabled={isSubmitting || saving}
              >
                {t("common.save")}
              </Button>
            </>
          }
          title={t("diagram.settings.title")}
        >
          <Form>
            <FormGroup labelWidth="25rem">
              {/* <Checkbox name="disableClick" /> */}
              <Checkbox name="linkColor" />
              <Checkbox name="linkTop" />
              <Checkbox name="showEnd" />
              <Checkbox name="linkCurved" />
              {/* <Checkbox name="moveNodes" /> */}
              {/* <Checkbox name="moveLinks" /> */}
              {/* <Checkbox name="pathFind" /> */}
              <Checkbox name="historyHide" />
              <Checkbox name="historyItems" />

              <hr />

              <Checkbox name="dagreEnabled" />
              <Checkbox name="dagreLinks" />
              <Input
                name="dagreRanker"
                label={t("diagram.settings.dagreRanker")}
                component="select"
              >
                <option value="network-simplex">network-simplex</option>
                <option value="tight-tree">tight-tree</option>
                <option value="longest-path">longest-path</option>
              </Input>
              {/*
              <Input
                name="dagreRankdir"
                label={t("diagram.settings.dagreRankdir")}
                component="select"
              >
                <option value="TB">{t("diagram.settings.tb")}</option>
                <option value="LR">{t("diagram.settings.lr")}</option>
              </Input>
              */}
              <Input
                name="dagreAlign"
                label={t("diagram.settings.dagreAlign")}
                component="select"
              >
                <option value="UD">{t("diagram.settings.ud")}</option>
                <option value="UL">{t("diagram.settings.ul")}</option>
              </Input>
            </FormGroup>
            {error && <ApiError>{error}</ApiError>}
          </Form>
        </CustomModal>
      )}
    </Formik>
  );
};

const mapDispachToProps = {
  setDiagramSettings,
};

export default connect(null, mapDispachToProps)(DiagramSettingsModal);
