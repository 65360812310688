import React, { ReactNode } from "react";
import { ISidebarLink } from "../../models/common";
import { SetState } from "../../utils/types";

const LayoutContext = React.createContext<{
  setSidebarTitle?: SetState<string | ReactNode | undefined>;
  setSidebarLinks?: SetState<ISidebarLink[] | undefined>;
}>({
  setSidebarTitle: undefined,
  setSidebarLinks: undefined,
});

export default LayoutContext;
