import { CancelToken } from "axios";
import { FC, ReactElement, useEffect } from "react";
import { connect } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { IAssistanceAddressItem } from "../../models/assistance";
import { IApplicationState } from "../../store";
import { IFilterType } from "../../store/filterType";
import {
  changeAssistanceAddressListFilter,
  changeAssistanceAddressListOrder,
  changeAssistanceAddressListPage,
  getAssistanceAddressList,
  getAssistanceAddressListCount,
  IAssistanceAddressListState,
  selectAssistanceAddressList,
  assistanceAddressListLoad,
} from "../../store/assistanceAddressList";
import FilterPanel from "../common/filter/FilterPanel";
import Grid from "../common/grid/Grid";
import { GridIconAdd, GridIconEdit } from "../common/grid/GridIcons";
import { TableCol } from "../common/grid/TableCol";
import { selectAssistanceId } from "../../store/assistance";
import { RightType } from "../../models/auth";
import { hasRight } from "../../utils/rights";
import { selectIdentityRights } from "../../store/identity";

interface IProps {
  assistanceId?: number;
  prov: IAssistanceAddressListState;
  identityRights?: RightType[];
  getAssistanceAddressList(
    assistanceId: number,
    cancelToken: CancelToken
  ): void;
  getAssistanceAddressListCount(
    assistanceId: number,
    cancelToken: CancelToken
  ): void;
  changeAssistanceAddressListOrder(orderBy: string, orderDesc: boolean): void;
  changeAssistanceAddressListPage(page: number): void;
  changeAssistanceAddressListFilter(filter: IFilterType): void;
  assistanceAddressListLoad(reload: boolean): void;
}

const AssistanceAddress: FC<IProps> = ({
  assistanceId,
  prov,
  identityRights,
  getAssistanceAddressList,
  getAssistanceAddressListCount,
  changeAssistanceAddressListOrder,
  changeAssistanceAddressListPage,
  changeAssistanceAddressListFilter,
  assistanceAddressListLoad,
}) => {
  const { assistanceId: paramAssistanceId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    assistanceAddressListLoad(false);
  }, [assistanceAddressListLoad]);

  const handleRenderData = (item: IAssistanceAddressItem): ReactElement => {
    return (
      <>
        <TableCol>{item.name}</TableCol>
        <TableCol>{item.street}</TableCol>
        <TableCol>{item.houseNumber}</TableCol>
        <TableCol>{item.landRegistryNumber}</TableCol>
        <TableCol>{item.city}</TableCol>
        <TableCol>
          <NavLink to={`/assistance/${paramAssistanceId}/address/${item.id}`}>
            <GridIconEdit />
          </NavLink>
        </TableCol>
      </>
    );
  };

  const handleRowClick = (item: IAssistanceAddressItem) => {
    navigate(`/assistance/${paramAssistanceId}/address/${item.id}`);
  };

  const handleGetData = (cancelToken: CancelToken) =>
    getAssistanceAddressList(assistanceId!, cancelToken);

  const handleGetCount = (cancelToken: CancelToken) =>
    getAssistanceAddressListCount(assistanceId!, cancelToken);

  return (
    <>
      <FilterPanel
        name="assistanceAddress"
        filter={prov.filter!}
        changeFilter={changeAssistanceAddressListFilter}
      />
      <Grid<IAssistanceAddressItem>
        headers={[
          { captionStr: "assistance.address.name" },
          { captionStr: "assistance.address.street" },
          { captionStr: "assistance.address.houseNumber" },
          { captionStr: "assistance.address.landRegistryNumber" },
          { captionStr: "assistance.address.city" },
          {
            captionEl: hasRight(identityRights, [
              RightType.WriteAssistances,
            ]) ? (
              <NavLink to={`/assistance/${paramAssistanceId}/address/new`}>
                <GridIconAdd />
              </NavLink>
            ) : undefined,
          },
        ]}
        renderData={handleRenderData}
        getData={handleGetData}
        getCount={handleGetCount}
        changeOrder={changeAssistanceAddressListOrder}
        changePage={changeAssistanceAddressListPage}
        onRowClick={handleRowClick}
        prov={prov}
      />
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    assistanceId: selectAssistanceId(state),
    prov: selectAssistanceAddressList(state),
    identityRights: selectIdentityRights(state),
  };
};

const mapDispachToProps = {
  getAssistanceAddressList,
  getAssistanceAddressListCount,
  changeAssistanceAddressListOrder,
  changeAssistanceAddressListPage,
  changeAssistanceAddressListFilter,
  assistanceAddressListLoad,
};

export default connect(mapStateToProps, mapDispachToProps)(AssistanceAddress);
