import { IFolderItem } from "../../models/document";
import { IFilterType } from "../filterType";
import { IListType } from "../listType";

export enum DefaultDocumentFolderListTypes {
  DEFAULT_DOCUMENT_FOLDER_LIST_FETCH_REQUEST = "@@defaultDocumentFolderList/FETCH_REQUEST",
  DEFAULT_DOCUMENT_FOLDER_LIST_FETCH_SUCCESS = "@@defaultDocumentFolderList/FETCH_SUCCESS",
  DEFAULT_DOCUMENT_FOLDER_LIST_FETCH_ERROR = "@@defaultDocumentFolderList/FETCH_ERROR",
  DEFAULT_DOCUMENT_FOLDER_LIST_COUNT_REQUEST = "@@defaultDocumentFolderList/COUNT_REQUEST",
  DEFAULT_DOCUMENT_FOLDER_LIST_COUNT_SUCCESS = "@@defaultDocumentFolderList/COUNT_SUCCESS",
  DEFAULT_DOCUMENT_FOLDER_LIST_COUNT_ERROR = "@@defaultDocumentFolderList/COUNT_ERROR",
  DEFAULT_DOCUMENT_FOLDER_LIST_CHANGE_ORDER = "@@defaultDocumentFolderList/CHANGE_ORDER",
  DEFAULT_DOCUMENT_FOLDER_LIST_CHANGE_PAGE = "@@defaultDocumentFolderList/CHANGE_PAGE",
  DEFAULT_DOCUMENT_FOLDER_LIST_CHANGE_FILTER = "@@defaultDocumentFolderList/CHANGE_FILTER",
  DEFAULT_DOCUMENT_FOLDER_LIST_RESET = "@@defaultDocumentFolderList/RESET",
  DEFAULT_DOCUMENT_FOLDER_LIST_LOAD = "@@defaultDocumentFolderList/LOAD",
}

export interface IDefaultDocumentFolderListFilter extends IFilterType {
  idFolder?: number | null;
}

export interface IDefaultDocumentFolderListState
  extends IListType<IFolderItem, IDefaultDocumentFolderListFilter> {}
