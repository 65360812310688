import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ClientStateType, IClient } from "../../models/client";
import { IApplicationState } from "../../store";
import { selectClient, updateClient } from "../../store/client";
import { ApiError, ExplanatoryNote } from "../../styles/form";
import clientApi from "../../api/client";
import { promiseToastSave } from "../../utils/toasts";
import { clientListLoad } from "../../store/clientList";
import { SetState } from "../../utils/types";
import { ClientButtonButton, ClientButtonRight } from "./ClientButtonStyles";
import { errorSet } from "../../utils/error";

interface IProps {
  client: IClient | null;
  isWorking: boolean;
  setIsWorking: SetState<boolean>;
  updateClient(newData: IClient): void;
  clientListLoad(reload: boolean): void;
}

const ClientButton: FC<IProps> = ({
  client,
  isWorking,
  setIsWorking,
  updateClient,
  clientListLoad,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);

  const changeState = async (state: ClientStateType) => {
    setIsWorking(true);
    setError(null);
    try {
      await promiseToastSave(async () => {
        const response = await clientApi.changeClientState(client!.id, state);
        updateClient(response.data);
        clientListLoad(true);
      });
    } catch (err) {
      errorSet(setError, err, t);
    }
    setIsWorking(false);
  };

  const handleActivate = async () => {
    await changeState(ClientStateType.Active);
  };

  const handleToBasic = async () => {
    await changeState(ClientStateType.Basic);
  };

  const handleReject = async () => {
    await changeState(ClientStateType.Rejected);
  };

  const handleDeactivate = async () => {
    await changeState(ClientStateType.InActive);
  };

  const handleDead = async () => {
    await changeState(ClientStateType.Dead);
  };

  let content;
  if (client!.fullRegistration) {
    content = (
      <>
        {client!.state !== ClientStateType.Active &&
          client!.state !== ClientStateType.Anonymised && (
            <>
              <ClientButtonButton
                disabled={
                  isWorking || !client!.gdprAgreementAt || !client!.keyCaregiver
                }
                onClick={handleActivate}
              >
                {client!.state === ClientStateType.New
                  ? t("client.button.approve")
                  : t("client.button.activate")}
              </ClientButtonButton>

              {(!client!.gdprAgreementAt || !client!.keyCaregiver) && (
                <ExplanatoryNote>
                  {client!.state === ClientStateType.New
                    ? (!client!.gdprAgreementAt &&
                        client!.keyCaregiver &&
                        t("client.button.approveGdprError")) ||
                      (client!.gdprAgreementAt &&
                        !client!.keyCaregiver &&
                        t("client.button.approveNoKeyCaregiverError")) ||
                      (!client!.gdprAgreementAt &&
                        !client!.keyCaregiver &&
                        t("client.button.approveGdprAndNoKeyCaregiverError"))
                    : (!client!.gdprAgreementAt &&
                        client!.keyCaregiver &&
                        t("client.button.activateGdprError")) ||
                      (client!.gdprAgreementAt &&
                        !client!.keyCaregiver &&
                        t("client.button.activateNoKeyCaregiverError")) ||
                      (!client!.gdprAgreementAt &&
                        !client!.keyCaregiver &&
                        t("client.button.activateGdprAndNoKeyCaregiverError"))}
                </ExplanatoryNote>
              )}
            </>
          )}
        {client!.state === ClientStateType.New && (
          <ClientButtonButton
            disabled={isWorking}
            onClick={handleReject}
            ver="secondary"
          >
            {t("client.button.reject")}
          </ClientButtonButton>
        )}
        {client!.state === ClientStateType.Active && (
          <>
            <ClientButtonButton
              disabled={isWorking}
              onClick={handleDeactivate}
              ver="secondary"
            >
              {t("client.button.deactivate")}
            </ClientButtonButton>

            <ClientButtonButton
              disabled={isWorking}
              onClick={handleDead}
              ver="secondary"
            >
              {t("client.button.dead")}
            </ClientButtonButton>
          </>
        )}
      </>
    );
  } else {
    content = (
      <>
        {client!.state !== ClientStateType.Basic &&
          client!.state !== ClientStateType.Anonymised && (
            <ClientButtonButton
              disabled={isWorking}
              onClick={handleToBasic}
              ver="secondary"
            >
              {t("client.button.toBasic")}
            </ClientButtonButton>
          )}
        {client!.state === ClientStateType.Basic && (
          <>
            <ClientButtonButton
              disabled={isWorking}
              onClick={handleDeactivate}
              ver="secondary"
            >
              {t("client.button.deactivate")}
            </ClientButtonButton>

            <ClientButtonButton
              disabled={isWorking}
              onClick={handleDead}
              ver="secondary"
            >
              {t("client.button.dead")}
            </ClientButtonButton>
          </>
        )}
      </>
    );
  }

  return (
    <>
      <div>
        <h2>{t("client.button.stateTitle")}</h2>
        {error && <ApiError>{error}</ApiError>}
        <ClientButtonRight>{content}</ClientButtonRight>
      </div>
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    client: selectClient(state),
  };
};

const mapDispachToProps = {
  updateClient,
  clientListLoad,
};

export default connect(mapStateToProps, mapDispachToProps)(ClientButton);
