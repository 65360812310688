import { ClientStateType } from "./client";
import { WorkflowDefinitionItemAutoType } from "./workflow";

export const WorkflowAutoTypeOptions = {
  [WorkflowDefinitionItemAutoType.State]: [
    "client.states." + ClientStateType.New,
    "client.states." + ClientStateType.Basic,
    "client.states." + ClientStateType.Active,
  ],
};
