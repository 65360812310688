export enum SearchInputType {
  Client = "searchClients",
  ClientContactPerson = "searchClientContactPersons",

  User = "searchUsers",
  Group = "searchGroups",

  Document = "searchDocuments",
  DocumentContent = "searchDocumentsContent",
  Assistance = "searchAssistances",
  AssistanceContactPerson = "searchAssistanceContactPersons",
  Listing = "searchListings",
}

export enum SearchItemType {
  Client = "Client",
  ClientContactPerson = "ContactPerson",
  User = "User",
  Group = "Group",
  Document = "Document",
  Assistance = "Assistance",
  AssistanceContactPerson = "AssistanceContactPerson",
  Listing = "Listing",
}

export enum SearchItemMasterType {
  Client = "Client",
  Assistance = "Assistance",
  Document = "Document",
}

export interface ISearchFoundItem {
  id: number;
  name: string;
  elementType: SearchItemType;

  idMaster?: number | null;
  masterName?: string | null;
  masterElementType?: SearchItemMasterType | null;
}
