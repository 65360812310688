import { Form, Formik } from "formik";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { INotification, NotificationType } from "../../models/notification";
import { IApplicationState } from "../../store";
import { StoreState } from "../../store/storeState";
import {
  getDefaultNotification,
  getNotification,
  selectNotification,
  selectNotificationState,
} from "../../store/notification";
import { FormButton } from "../../styles/button";
import { ApiError, FormGroup } from "../../styles/form";
import Input from "../common/form/Input";
import Loader from "../common/Loader";
import * as Yup from "yup";
import { notificationListLoad } from "../../store/notificationList";
import notificationApi from "../../api/notification";
import { SpaceBetweenButtons } from "../../styles/spaces";
import validations from "../../utils/validations";
import { RightType } from "../../models/auth";
import { hasRight } from "../../utils/rights";
import { selectIdentityRights } from "../../store/identity";
import { promiseToastSave } from "../../utils/toasts";
import SubmitForm from "../common/form/SubmitForm";
import { errorSet } from "../../utils/error";

interface IProps {
  notificationState: StoreState;
  notification: INotification | null;
  identityRights?: RightType[];
  getNotification(notificationId: number): void;
  getDefaultNotification(): void;
  notificationListLoad(reload: boolean): void;
}

const AdminNotificationDetail: FC<IProps> = ({
  notificationState,
  notification,
  identityRights,
  getNotification,
  getDefaultNotification,
  notificationListLoad,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { notificationId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (notificationId === "new") {
      getDefaultNotification();
    } else {
      getNotification(parseInt(notificationId!));
    }
    setLoading(false);
  }, [notificationId, getNotification, getDefaultNotification]);

  const handleSubmit = async (data: INotification) => {
    setError(null);
    try {
      await promiseToastSave(async () => {
        if (notificationId === "new") {
          await notificationApi.createNotification(data);
        } else {
          await notificationApi.updateNotification(data);
        }

        notificationListLoad(true);
        navigate("/admin/notification");
      });
    } catch (err) {
      errorSet(setError, err, t);
    }
  };

  const handleCancel = () => {
    navigate("/admin/notification");
  };

  if (loading || notificationState === StoreState.Loading) {
    return <Loader />;
  }

  if (notificationState === StoreState.Error) {
    return t("errors.unknown");
  }

  return (
    <Formik<INotification>
      initialValues={{
        ...notification!,
        emailText: notification!.emailText ?? "",
        smsText: notification!.smsText ?? "",
      }}
      validationSchema={Yup.object({
        name: validations.stringRequired(t),
        emailText: Yup.string().when("viaEmail", {
          is: (viaEmail: any) => viaEmail,
          then: validations.stringRequired(t),
        }),
        smsText: Yup.string().when("viaSms", {
          is: (viaSms: any) => viaSms,
          then: validations.stringRequired(t),
        }),
      })}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, isSubmitting, values }) => (
        <Form>
          <h1>{t("admin.notification.detailTitle")}</h1>
          <FormGroup>
            <Input
              key="isActive"
              name="isActive"
              type="checkbox"
              label={t(`admin.notification.isActive`)}
              inputWidth="1.5rem"
              inputHeight="1.5rem"
            />
            <Input
              key="name"
              name="name"
              label={t("admin.notification.name")}
              error={touched.name && !!errors.name}
              maxLength={512}
            />
            <Input
              name="type"
              label={t("admin.notification.type")}
              error={touched.type && !!errors.type}
              component="select"
            >
              {(
                Object.keys(NotificationType) as Array<
                  keyof typeof NotificationType
                >
              ).map((key) => (
                <option key={key} value={NotificationType[key]}>
                  {t("admin.notification.types." + NotificationType[key])}
                </option>
              ))}
            </Input>
            <Input
              key="viaEmail"
              name="viaEmail"
              type="checkbox"
              label={t(`admin.notification.viaEmail`)}
              error={touched.viaEmail && !!errors.viaEmail}
              inputWidth="1.5rem"
              inputHeight="1.5rem"
            />
            <Input
              name="emailText"
              component="textarea"
              label={t("admin.notification.emailText")}
              error={touched.emailText && !!errors.emailText}
              disabled={!values.viaEmail}
              inputHeight="10rem"
              maxLength={1024}
            />
            <Input
              key="viaSms"
              name="viaSms"
              type="checkbox"
              label={t(`admin.notification.viaSms`)}
              error={touched.viaSms && !!errors.viaSms}
              inputWidth="1.5rem"
              inputHeight="1.5rem"
            />
            <Input
              name="smsText"
              component="textarea"
              label={t("admin.notification.smsText")}
              error={touched.smsText && !!errors.smsText}
              disabled={!values.viaSms}
              inputHeight="10rem"
              maxLength={1024}
            />
          </FormGroup>
          {error && <ApiError>{error}</ApiError>}
          <FormButton
            ver="secondary"
            disabled={isSubmitting}
            onClick={handleCancel}
          >
            {t("common.back")}
          </FormButton>
          {hasRight(identityRights, [RightType.WriteNotification]) && (
            <>
              <SpaceBetweenButtons />
              <SubmitForm />
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    notificationState: selectNotificationState(state),
    notification: selectNotification(state),
    identityRights: selectIdentityRights(state),
  };
};

const mapDispachToProps = {
  getNotification,
  getDefaultNotification,
  notificationListLoad,
};

export default connect(
  mapStateToProps,
  mapDispachToProps
)(AdminNotificationDetail);
