import { IDiagramSettings } from "../../models/diagram";
import { StoreState } from "../storeState";

export enum DiagramSettingsTypes {
  DIAGRAM_SETTINGS_FETCH_REQUEST = "@@diagramSettings/FETCH_REQUEST",
  DIAGRAM_SETTINGS_FETCH_SUCCESS = "@@diagramSettings/FETCH_SUCCESS",
  DIAGRAM_SETTINGS_FETCH_ERROR = "@@diagramSettings/FETCH_ERROR",
}

export interface IDiagramSettingsState {
  state: StoreState;
  data: IDiagramSettings;
}
