import { AxiosRequestConfig, AxiosResponse } from "axios";
import { IItemIdName } from "../models/common";
import { INotification, INotificationItem } from "../models/notification";
import api from "../utils/api";

const getList = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<INotificationItem[]>> =>
  api.get(`/api/Notification`, config);

const getCount = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<number>> => api.get(`/api/Notification/Count`, config);

const getSuggestionList = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<IItemIdName[]>> =>
  api.get(`/api/Notification/Suggested/List`, config);

const getNotification = async (
  notificationId: number
): Promise<AxiosResponse<INotification>> =>
  api.get(`/api/Notification/${notificationId}`);

const updateNotification = async (
  notification: INotification
): Promise<AxiosResponse<INotification>> =>
  api.put(`/api/Notification/${notification.id}`, notification);

const createNotification = async (
  notification: INotification
): Promise<AxiosResponse<INotification>> =>
  api.post(`/api/Notification`, notification);

const notificationApi = {
  getList,
  getCount,
  getSuggestionList,
  getNotification,
  updateNotification,
  createNotification,
};

export default notificationApi;
