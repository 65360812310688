import { IAssistanceDocumentItem } from "../../models/assistance";
import { IFilterType } from "../filterType";
import { IListType } from "../listType";

export enum AssistanceDocumentListTypes {
  ASSISTANCE_DOCUMENT_LIST_FETCH_REQUEST = "@@assistanceDocumentList/FETCH_REQUEST",
  ASSISTANCE_DOCUMENT_LIST_FETCH_SUCCESS = "@@assistanceDocumentList/FETCH_SUCCESS",
  ASSISTANCE_DOCUMENT_LIST_FETCH_ERROR = "@@assistanceDocumentList/FETCH_ERROR",
  ASSISTANCE_DOCUMENT_LIST_COUNT_REQUEST = "@@assistanceDocumentList/COUNT_REQUEST",
  ASSISTANCE_DOCUMENT_LIST_COUNT_SUCCESS = "@@assistanceDocumentList/COUNT_SUCCESS",
  ASSISTANCE_DOCUMENT_LIST_COUNT_ERROR = "@@assistanceDocumentList/COUNT_ERROR",
  ASSISTANCE_DOCUMENT_LIST_CHANGE_ORDER = "@@assistanceDocumentList/CHANGE_ORDER",
  ASSISTANCE_DOCUMENT_LIST_CHANGE_PAGE = "@@assistanceDocumentList/CHANGE_PAGE",
  ASSISTANCE_DOCUMENT_LIST_CHANGE_FILTER = "@@assistanceDocumentList/CHANGE_FILTER",
  ASSISTANCE_DOCUMENT_LIST_RESET = "@@assistanceDocumentList/RESET",
  ASSISTANCE_DOCUMENT_LIST_LOAD = "@@assistanceDocumentList/LOAD",
}

export interface IAssistanceDocumentListFilterType extends IFilterType {
  createdFrom?: Date;
  createdTo?: Date;
  lastEditFrom?: Date;
  lastEditTo?: Date;
  authorId?: number;
  authorName?: string;
  lastEditorId?: number;
  lastEditorName?: string;
}

export interface IAssistanceDocumentListState
  extends IListType<IAssistanceDocumentItem, IAssistanceDocumentListFilterType> {}
