import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik, Form, Field, FormikProps } from "formik";
import { FC, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  WorkflowDefinitionItemAutoType,
  WorkflowDefinitionItemTopMode,
  WorkflowDefinitionItemType,
} from "../../../models/workflow";
import { Button, IconButton } from "../../../styles/button";
import { FormGroup } from "../../../styles/form";
import {
  handleLoadWorkflowSuggestions,
  handleLoadWorkflowTemplateSuggestions,
} from "../../../utils/suggestions";
import Input from "../form/Input";
import CustomModal from "../modal/CustomModal";
import SuggestionFormik from "../suggestion/SuggestionFormik";
import {
  AnswerInputWrapper,
  AddButtonWrapper,
  NodeTypeWrapper,
} from "./DiagramNodeModalStyles";
import * as Yup from "yup";
import { numberRequired, stringRequired } from "../../../utils/validations";
import { IDiagramNodeData } from "./DiagramTypes";

const addAnswer = (
  answers: string[],
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void
) => {
  const newValues = [...answers, ""];
  setFieldValue("answers", newValues);
};

const removeAnswer = (
  answers: string[],
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void,
  index: number
) => {
  const newValues = answers.filter((_, itemIndex) => itemIndex !== index);
  setFieldValue("answers", newValues);
};

interface IProps {
  initValues: IDiagramNodeData;
  editMode: boolean;
  isOpen: boolean;
  submit: (data: IDiagramNodeData) => void;
  close: () => void;
}

const DiagramNodeModal: FC<IProps> = ({
  initValues,
  editMode,
  isOpen,
  submit,
  close,
}) => {
  const { t } = useTranslation();
  const formikRef = useRef<FormikProps<IDiagramNodeData> | null>(null);

  useEffect(() => {
    if (isOpen) {
      formikRef.current?.resetForm();
    }
  }, [isOpen]);

  return (
    <Formik<IDiagramNodeData>
      initialValues={initValues}
      enableReinitialize={true}
      onSubmit={submit}
      innerRef={formikRef}
      validateOnMount={true}
      validationSchema={Yup.object().shape({
        templateId: Yup.number().when("type", {
          is: WorkflowDefinitionItemType.Template,
          then: numberRequired(t),
        }),
        question: Yup.string().when("type", {
          is: WorkflowDefinitionItemType.Question,
          then: stringRequired(t),
        }),
        answers: Yup.array().when("type", {
          is: WorkflowDefinitionItemType.Question,
          then: Yup.array().of(stringRequired(t)),
        }),
        autoType: Yup.string().when("type", {
          is: WorkflowDefinitionItemType.Auto,
          then: stringRequired(t),
        }),
        nestedWorkflowId: Yup.number().when("type", {
          is: WorkflowDefinitionItemType.Workflow,
          then: numberRequired(t),
        }),
      })}
    >
      {({ values, submitForm, setFieldValue, touched, errors }) => (
        <CustomModal
          isOpen={isOpen}
          close={close}
          actions={
            <Button type="button" onClick={submitForm}>
              {editMode
                ? t("module.workflow.nodeModal.updateConfirm")
                : t("module.workflow.nodeModal.createConfirm")}
            </Button>
          }
          title={t("module.workflow.nodeModal.createTitle")}
        >
          <Form>
            <FormGroup>
              <Input
                name="topPortMode"
                label={t("module.workflow.nodeModal.topPortMode")}
                component="select"
              >
                {Object.values(WorkflowDefinitionItemTopMode).map((type) => (
                  <option key={type} value={type}>
                    {t(`module.workflow.topModes.${type}`)}
                  </option>
                ))}
              </Input>

              <hr />
              <NodeTypeWrapper role="group">
                {Object.values(WorkflowDefinitionItemType).map((type) => (
                  <label key={type}>
                    <Field type="radio" name="type" value={type} />
                    {t(`module.workflow.nodeModal.types.${type}`)}
                  </label>
                ))}
              </NodeTypeWrapper>

              {values.type === WorkflowDefinitionItemType.Template && (
                <SuggestionFormik
                  nameId="templateId"
                  nameText="templateName"
                  label={t("module.workflow.template")}
                  loadSuggestions={handleLoadWorkflowTemplateSuggestions}
                />
              )}
              {values.type === WorkflowDefinitionItemType.Auto && (
                <Input
                  name="autoType"
                  label={t("module.workflow.nodeModal.autoTypeLabel")}
                  error={touched.autoType && !!errors.autoType}
                  component="select"
                >
                  <option value={undefined}></option>
                  {Object.values(WorkflowDefinitionItemAutoType).map((type) => (
                    <option key={type} value={type}>
                      {t(`module.workflow.autoTypes.${type}`)}
                    </option>
                  ))}
                </Input>
              )}
              {values.type === WorkflowDefinitionItemType.Workflow && (
                <SuggestionFormik
                  nameId="nestedWorkflowId"
                  nameText="nestedWorkflowName"
                  label={t("module.workflow.nestedWorkflow")}
                  loadSuggestions={handleLoadWorkflowSuggestions}
                />
              )}
              {values.type === WorkflowDefinitionItemType.Question && (
                <>
                  <Input
                    name="question"
                    label={t("module.workflow.nodeModal.question")}
                    error={touched.question && !!errors.question}
                  ></Input>
                  {values.answers?.map((_answer, index) => (
                    <AnswerInputWrapper key={index}>
                      <Input
                        name={`answers[${index}]`}
                        label={t("module.workflow.nodeModal.answer", {
                          answerNumber: index + 1,
                        })}
                        // as any due to type error in Formik
                        error={
                          !!(touched.answers as any)?.[index] &&
                          !!errors.answers?.[index]
                        }
                      ></Input>
                      {values.answers.length > 2 && (
                        <IconButton
                          type="button"
                          onClick={() => {
                            removeAnswer(values.answers, setFieldValue, index);
                          }}
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </IconButton>
                      )}
                    </AnswerInputWrapper>
                  ))}
                  {values.answers.length < 10 && (
                    <AddButtonWrapper>
                      <Button
                        type="button"
                        onClick={() => {
                          addAnswer(values.answers, setFieldValue);
                        }}
                      >
                        {t("module.workflow.nodeModal.addAnswer")}
                      </Button>
                    </AddButtonWrapper>
                  )}
                </>
              )}
            </FormGroup>
          </Form>
        </CustomModal>
      )}
    </Formik>
  );
};

export default DiagramNodeModal;
