import { createAction } from "@reduxjs/toolkit";
import { IFolderItem } from "../../models/document";
import { StoreState, StoreStateDontLoad } from "../storeState";
import { IDefaultDocumentFolderListFilter, DefaultDocumentFolderListTypes } from "./types";

export const defaultDocumentFolderListFetchRequest = createAction<
  StoreStateDontLoad,
  DefaultDocumentFolderListTypes.DEFAULT_DOCUMENT_FOLDER_LIST_FETCH_REQUEST
>(DefaultDocumentFolderListTypes.DEFAULT_DOCUMENT_FOLDER_LIST_FETCH_REQUEST);

export const defaultDocumentFolderListFetchSuccess = createAction<
  {
    data: IFolderItem[];
    newCountState: StoreStateDontLoad;
    newCount: number;
  },
  DefaultDocumentFolderListTypes.DEFAULT_DOCUMENT_FOLDER_LIST_FETCH_SUCCESS
>(DefaultDocumentFolderListTypes.DEFAULT_DOCUMENT_FOLDER_LIST_FETCH_SUCCESS);

export const defaultDocumentFolderListFetchError = createAction<
  boolean,
  DefaultDocumentFolderListTypes.DEFAULT_DOCUMENT_FOLDER_LIST_FETCH_ERROR
>(DefaultDocumentFolderListTypes.DEFAULT_DOCUMENT_FOLDER_LIST_FETCH_ERROR);

export const defaultDocumentFolderListCountRequest = createAction<
  void,
  DefaultDocumentFolderListTypes.DEFAULT_DOCUMENT_FOLDER_LIST_COUNT_REQUEST
>(DefaultDocumentFolderListTypes.DEFAULT_DOCUMENT_FOLDER_LIST_COUNT_REQUEST);

export const defaultDocumentFolderListCountSuccess = createAction<
  number,
  DefaultDocumentFolderListTypes.DEFAULT_DOCUMENT_FOLDER_LIST_COUNT_SUCCESS
>(DefaultDocumentFolderListTypes.DEFAULT_DOCUMENT_FOLDER_LIST_COUNT_SUCCESS);

export const defaultDocumentFolderListCountError = createAction<
  boolean,
  DefaultDocumentFolderListTypes.DEFAULT_DOCUMENT_FOLDER_LIST_COUNT_ERROR
>(DefaultDocumentFolderListTypes.DEFAULT_DOCUMENT_FOLDER_LIST_COUNT_ERROR);

export const defaultDocumentFolderListChangeOrder = createAction<
  { orderBy: string; orderDesc: boolean },
  DefaultDocumentFolderListTypes.DEFAULT_DOCUMENT_FOLDER_LIST_CHANGE_ORDER
>(DefaultDocumentFolderListTypes.DEFAULT_DOCUMENT_FOLDER_LIST_CHANGE_ORDER);

export const defaultDocumentFolderListChangePage = createAction<
  number,
  DefaultDocumentFolderListTypes.DEFAULT_DOCUMENT_FOLDER_LIST_CHANGE_PAGE
>(DefaultDocumentFolderListTypes.DEFAULT_DOCUMENT_FOLDER_LIST_CHANGE_PAGE);

export const defaultDocumentFolderListChangeFilter = createAction<
  IDefaultDocumentFolderListFilter,
  DefaultDocumentFolderListTypes.DEFAULT_DOCUMENT_FOLDER_LIST_CHANGE_FILTER
>(DefaultDocumentFolderListTypes.DEFAULT_DOCUMENT_FOLDER_LIST_CHANGE_FILTER);

export const defaultDocumentFolderListReset = createAction<
  void,
  DefaultDocumentFolderListTypes.DEFAULT_DOCUMENT_FOLDER_LIST_RESET
>(DefaultDocumentFolderListTypes.DEFAULT_DOCUMENT_FOLDER_LIST_RESET);

export const defaultDocumentFolderListLoadAction = createAction<
  { newState: StoreState; newCountState: StoreStateDontLoad },
  DefaultDocumentFolderListTypes.DEFAULT_DOCUMENT_FOLDER_LIST_LOAD
>(DefaultDocumentFolderListTypes.DEFAULT_DOCUMENT_FOLDER_LIST_LOAD);
