import {
  faBell,
  faCalendarAlt,
  faCog,
  faDesktop,
  faDoorOpen,
  faEllipsisH,
  faFileWord,
  faHeartbeat,
  faList,
  faPlug,
  faProjectDiagram,
  faQuestion,
  faRunning,
  faTasks,
  faUser,
  faUsers,
  faUsersCog,
  faWheelchair,
} from "@fortawesome/free-solid-svg-icons";
import { TFunction } from "i18next";

export const ALL_LINKS = [
  "/calendar",
  "/demand",
  "/client",
  "/assistance",
  "/module",
  "/module/document",
  "/module/workflow",
  "/module/report",
  "/admin",
  "/admin/user",
  "/admin/role",
  "/admin/group",
  "/admin/listing",
  "/admin/notification",
  "/admin/parameter",
  "/admin/public",
  "/admin/monitor",
  "/admin/log",
  "#public",
];

export const getLinkName = (t: TFunction, path: string) => {
  let name1 = "";
  let name2 = "";
  switch (path) {
    case "/calendar":
      name1 = "header.calendar";
      break;
    case "/demand":
      name1 = "header.demand";
      break;
    case "/client":
      name1 = "header.client";
      break;
    case "/assistance":
      name1 = "header.assistance";
      break;
    case "#public":
      name1 = "header.public";
      break;
    default:
      if (path.startsWith("/module")) {
        name1 = "header.module";
        switch (path) {
          case "/module/document":
            name2 = "module.nav.document";
            break;
          case "/module/workflow":
            name2 = "module.nav.workflow";
            break;
          case "/module/report":
            name2 = "module.nav.report";
            break;
        }

        break;
      }

      if (path.startsWith("/admin")) {
        name1 = "header.admin";
        switch (path) {
          case "/admin/user":
            name2 = "admin.nav.user";
            break;
          case "/admin/role":
            name2 = "admin.nav.role";
            break;
          case "/admin/group":
            name2 = "admin.nav.group";
            break;
          case "/admin/listing":
            name2 = "admin.nav.listing";
            break;
          case "/admin/notification":
            name2 = "admin.nav.notification";
            break;
          case "/admin/parameter":
            name2 = "admin.nav.parameter";
            break;
          case "/admin/public":
            name2 = "admin.nav.public";
            break;
          case "/admin/monitor":
            name2 = "admin.nav.monitor";
            break;
          case "/admin/log":
            name2 = "admin.nav.log";
            break;
        }

        break;
      }
  }

  if (!name1) {
    return "";
  }

  if (name2) {
    return t(name1) + " - " + t(name2);
  }

  return t(name1);
};

export const getLinkIcon = (path: string) => {
  switch (path) {
    case "/calendar":
      return faCalendarAlt;
    case "/demand":
      return faList;
    case "/client":
      return faUsers;
    case "/assistance":
      return faWheelchair;
    case "/module":
      return faPlug;
    case "/module/document":
      return faFileWord;
    case "/module/workflow":
      return faProjectDiagram;
    case "/module/report":
      return faTasks;
    case "/admin":
      return faCog;
    case "/admin/user":
      return faUser;
    case "/admin/role":
      return faUsersCog;
    case "/admin/group":
      return faUsers;
    case "/admin/listing":
      return faList;
    case "/admin/notification":
      return faBell;
    case "/admin/parameter":
      return faEllipsisH;
    case "/admin/public":
      return faRunning;
    case "/admin/monitor":
      return faHeartbeat;
    case "/admin/log":
      return faDesktop;
    case "#public":
      return faDoorOpen;
  }

  return faQuestion;
};
