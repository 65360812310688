import { createAction } from "@reduxjs/toolkit";
import { IUser } from "../../models/user";
import { UserTypes } from "./types";

export const userFetchRequest = createAction<
  void,
  UserTypes.USER_FETCH_REQUEST
>(UserTypes.USER_FETCH_REQUEST);

export const userFetchSuccess = createAction<
  IUser,
  UserTypes.USER_FETCH_SUCCESS
>(UserTypes.USER_FETCH_SUCCESS);

export const userFetchError = createAction<void, UserTypes.USER_FETCH_ERROR>(
  UserTypes.USER_FETCH_ERROR
);
