import { FC } from "react";
import Page from "../layout/Page";
import DashboardLinks from "./DashboardLinks";
import DashboardWidgets from "./DashboardWidgets";

const Dashboard: FC = () => {
  return (
    <Page>
      <DashboardLinks />
      <DashboardWidgets />
    </Page>
  );
};

export default Dashboard;
