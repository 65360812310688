import { createAction } from "@reduxjs/toolkit";
import { IClientBenefitItem } from "../../models/client";
import { IFilterType } from "../filterType";
import { StoreState, StoreStateDontLoad } from "../storeState";
import { ClientBenefitListTypes } from "./types";

export const clientBenefitListFetchRequest = createAction<
  StoreStateDontLoad,
  ClientBenefitListTypes.CLIENT_BENEFIT_LIST_FETCH_REQUEST
>(ClientBenefitListTypes.CLIENT_BENEFIT_LIST_FETCH_REQUEST);

export const clientBenefitListFetchSuccess = createAction<
  {
    data: IClientBenefitItem[];
    newCountState: StoreStateDontLoad;
    newCount: number;
  },
  ClientBenefitListTypes.CLIENT_BENEFIT_LIST_FETCH_SUCCESS
>(ClientBenefitListTypes.CLIENT_BENEFIT_LIST_FETCH_SUCCESS);

export const clientBenefitListFetchError = createAction<
  boolean,
  ClientBenefitListTypes.CLIENT_BENEFIT_LIST_FETCH_ERROR
>(ClientBenefitListTypes.CLIENT_BENEFIT_LIST_FETCH_ERROR);

export const clientBenefitListCountRequest = createAction<
  void,
  ClientBenefitListTypes.CLIENT_BENEFIT_LIST_COUNT_REQUEST
>(ClientBenefitListTypes.CLIENT_BENEFIT_LIST_COUNT_REQUEST);

export const clientBenefitListCountSuccess = createAction<
  number,
  ClientBenefitListTypes.CLIENT_BENEFIT_LIST_COUNT_SUCCESS
>(ClientBenefitListTypes.CLIENT_BENEFIT_LIST_COUNT_SUCCESS);

export const clientBenefitListCountError = createAction<
  boolean,
  ClientBenefitListTypes.CLIENT_BENEFIT_LIST_COUNT_ERROR
>(ClientBenefitListTypes.CLIENT_BENEFIT_LIST_COUNT_ERROR);

export const clientBenefitListChangeOrder = createAction<
  { orderBy: string; orderDesc: boolean },
  ClientBenefitListTypes.CLIENT_BENEFIT_LIST_CHANGE_ORDER
>(ClientBenefitListTypes.CLIENT_BENEFIT_LIST_CHANGE_ORDER);

export const clientBenefitListChangePage = createAction<
  number,
  ClientBenefitListTypes.CLIENT_BENEFIT_LIST_CHANGE_PAGE
>(ClientBenefitListTypes.CLIENT_BENEFIT_LIST_CHANGE_PAGE);

export const clientBenefitListChangeFilter = createAction<
  IFilterType,
  ClientBenefitListTypes.CLIENT_BENEFIT_LIST_CHANGE_FILTER
>(ClientBenefitListTypes.CLIENT_BENEFIT_LIST_CHANGE_FILTER);

export const clientBenefitListReset = createAction<
  void,
  ClientBenefitListTypes.CLIENT_BENEFIT_LIST_RESET
>(ClientBenefitListTypes.CLIENT_BENEFIT_LIST_RESET);

export const clientBenefitListLoadAction = createAction<
  { newState: StoreState; newCountState: StoreStateDontLoad },
  ClientBenefitListTypes.CLIENT_BENEFIT_LIST_LOAD
>(ClientBenefitListTypes.CLIENT_BENEFIT_LIST_LOAD);
