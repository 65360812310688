import { IItemIdName } from "../../models/common";
import { IFilterType } from "../filterType";
import { IListType } from "../listType";

export enum ClientRightUserListTypes {
  CLIENT_RIGHT_USER_LIST_FETCH_REQUEST = "@@clientRightUserList/FETCH_REQUEST",
  CLIENT_RIGHT_USER_LIST_FETCH_SUCCESS = "@@clientRightUserList/FETCH_SUCCESS",
  CLIENT_RIGHT_USER_LIST_FETCH_ERROR = "@@clientRightUserList/FETCH_ERROR",
  CLIENT_RIGHT_USER_LIST_COUNT_REQUEST = "@@clientRightUserList/COUNT_REQUEST",
  CLIENT_RIGHT_USER_LIST_COUNT_SUCCESS = "@@clientRightUserList/COUNT_SUCCESS",
  CLIENT_RIGHT_USER_LIST_COUNT_ERROR = "@@clientRightUserList/COUNT_ERROR",
  CLIENT_RIGHT_USER_LIST_CHANGE_ORDER = "@@clientRightUserList/CHANGE_ORDER",
  CLIENT_RIGHT_USER_LIST_CHANGE_PAGE = "@@clientRightUserList/CHANGE_PAGE",
  CLIENT_RIGHT_USER_LIST_CHANGE_FILTER = "@@clientRightUserList/CHANGE_FILTER",
  CLIENT_RIGHT_USER_LIST_RESET = "@@clientRightUserList/RESET",
  CLIENT_RIGHT_USER_LIST_LOAD = "@@clientRightUserList/LOAD",
}

export interface IClientRightUserListState
  extends IListType<IItemIdName, IFilterType> {}
