import { AssistanceStateType, IAssistanceItem } from "../../models/assistance";
import { IFilterType } from "../filterType";
import { IListType } from "../listType";

export enum AssistanceListTypes {
  ASSISTANCE_LIST_FETCH_REQUEST = "@@assistanceList/FETCH_REQUEST",
  ASSISTANCE_LIST_FETCH_SUCCESS = "@@assistanceList/FETCH_SUCCESS",
  ASSISTANCE_LIST_FETCH_ERROR = "@@assistanceList/FETCH_ERROR",
  ASSISTANCE_LIST_COUNT_REQUEST = "@@assistanceList/COUNT_REQUEST",
  ASSISTANCE_LIST_COUNT_SUCCESS = "@@assistanceList/COUNT_SUCCESS",
  ASSISTANCE_LIST_COUNT_ERROR = "@@assistanceList/COUNT_ERROR",
  ASSISTANCE_LIST_CHANGE_ORDER = "@@assistanceList/CHANGE_ORDER",
  ASSISTANCE_LIST_CHANGE_PAGE = "@@assistanceList/CHANGE_PAGE",
  ASSISTANCE_LIST_CHANGE_FILTER = "@@assistanceList/CHANGE_FILTER",
  ASSISTANCE_LIST_RESET = "@@assistanceList/RESET",
  ASSISTANCE_LIST_LOAD = "@@assistanceList/LOAD",
}

export interface IAssistanceListFilter extends IFilterType {
  state?: AssistanceStateType | null;
  assistanceCategoryId: number;
  assistanceCategoryName: string;
  careTypeId: number;
  careTypeName: string;
  targetGroupId: number;
  targetGroupName: string;
}

export interface IAssistanceListState
  extends IListType<IAssistanceItem, IAssistanceListFilter> {}
