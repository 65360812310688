import { AppThunkAction, AppThunkActionAsync } from "..";
import * as actions from "./actions";
import defaultDocumentApi from "../../api/defaultDocument";
import {
  IDefaultDocumentFolderListFilter,
  IDefaultDocumentFolderListState,
  selectDefaultDocumentFolderList,
} from ".";
import { CancelToken } from "axios";
import {
  listEffectCountFilter,
  listEffectGetFilter,
  listEffectLoad,
  listEffectReload,
  listEffectsCountCheck,
  listEffectsGetCheck,
  listEffectsGetCount,
  listEffectsGetNew,
  listEffectsGetParams,
} from "../listEffect";

export function getDefaultDocumentFolderList(
  cancelToken: CancelToken
): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const storeState = getState();
    const state = selectDefaultDocumentFolderList(storeState);
    if (listEffectsGetCheck(state.state)) {
      return;
    }

    let newState = listEffectsGetNew(state);
    dispatch(
      actions.defaultDocumentFolderListFetchRequest(newState.newCountState)
    );
    try {
      const params = listEffectsGetParams(state, newState.take);
      listEffectGetFilter(params, state.filter);
      addDefaultDocumentFolderParams(params, state);

      const folders = await defaultDocumentApi.getFolderList({
        params,
        cancelToken,
      });
      cancelToken.throwIfRequested();
      newState = listEffectsGetCount(newState, folders);

      dispatch(
        actions.defaultDocumentFolderListFetchSuccess({
          data: folders.data,
          newCountState: newState.newCountState,
          newCount: newState.newCount,
        })
      );
    } catch {
      dispatch(
        actions.defaultDocumentFolderListFetchError(
          cancelToken.reason !== undefined
        )
      );
    }
  };
}

export function getDefaultDocumentFolderListCount(
  cancelToken: CancelToken
): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const storeState = getState();
    const state = selectDefaultDocumentFolderList(storeState);
    if (listEffectsCountCheck(state.countState)) {
      return;
    }

    dispatch(actions.defaultDocumentFolderListCountRequest());
    try {
      const params = listEffectCountFilter(state.filter);
      addDefaultDocumentFolderParams(params, state);
      const count = await defaultDocumentApi.getFolderCount({
        params,
        cancelToken,
      });
      cancelToken.throwIfRequested();

      dispatch(actions.defaultDocumentFolderListCountSuccess(count.data));
    } catch {
      dispatch(
        actions.defaultDocumentFolderListCountError(
          cancelToken.reason !== undefined
        )
      );
    }
  };
}

export function changeDefaultDocumentFolderListOrder(
  orderBy: string,
  orderDesc: boolean
): AppThunkAction {
  return async (dispatch) => {
    dispatch(
      actions.defaultDocumentFolderListChangeOrder({ orderBy, orderDesc })
    );
  };
}

export function changeDefaultDocumentFolderListPage(
  page: number
): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.defaultDocumentFolderListChangePage(page));
  };
}

export function changeDefaultDocumentFolderListFilter(
  filter: IDefaultDocumentFolderListFilter
): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.defaultDocumentFolderListChangeFilter(filter));
  };
}

export function defaultDocumentFolderListLoad(reload: boolean): AppThunkAction {
  return async (dispatch, getState) => {
    const state = selectDefaultDocumentFolderList(getState());
    reload = listEffectReload(reload, state);
    if (reload) {
      dispatch(actions.defaultDocumentFolderListReset());
    } else {
      const newState = listEffectLoad(state);
      dispatch(
        actions.defaultDocumentFolderListLoadAction({
          newState: newState.newState,
          newCountState: newState.newCountState,
        })
      );
    }
  };
}

export function addDefaultDocumentFolderParams(
  params: URLSearchParams,
  state: IDefaultDocumentFolderListState
) {
  if (state.filter!.idFolder) {
    params.append("idFolder", state.filter!.idFolder.toString());
  }
}
