import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { Link } from "react-router-dom";

const FormLink: FC<{ to: string }> = ({ to }) => {
  return (
    <Link to={to}>
      <FontAwesomeIcon icon={faExternalLinkAlt} />
    </Link>
  );
};

export default FormLink;
