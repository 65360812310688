import * as ReselectModule from "reselect";
import { IApplicationState } from "..";
import { IRole } from "../../models/role";
import { StoreState } from "../storeState";

export const selectRoleState: ReselectModule.Selector<
  IApplicationState,
  StoreState
> = (state) => state.role.state;

export const selectRole: ReselectModule.Selector<
  IApplicationState,
  IRole | null
> = (state) => state.role.data;
