import { AppThunkAction, AppThunkActionAsync } from "..";
import * as actions from "./actions";
import logApi from "../../api/log";
import { ILogListFilter, ILogListState, selectLogList } from ".";
import { CancelToken } from "axios";
import {
  listEffectCountFilter,
  listEffectGetFilter,
  listEffectLoad,
  listEffectReload,
  listEffectsCountCheck,
  listEffectsGetCheck,
  listEffectsGetCount,
  listEffectsGetNew,
  listEffectsGetParams,
} from "../listEffect";
import { addDays, formatISO } from "date-fns";

export function getLogList(cancelToken: CancelToken): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectLogList(getState());
    if (listEffectsGetCheck(state.state)) {
      return;
    }

    let newState = listEffectsGetNew(state);
    dispatch(actions.logListFetchRequest(newState.newCountState));
    try {
      const params = listEffectsGetParams(state, newState.take);
      listEffectGetFilter(params, state.filter);
      addLogParams(params, state);

      const logs = await logApi.getLogList({ params, cancelToken });
      cancelToken.throwIfRequested();
      newState = listEffectsGetCount(newState, logs);

      dispatch(
        actions.logListFetchSuccess({
          data: logs.data,
          newCountState: newState.newCountState,
          newCount: newState.newCount,
        })
      );
    } catch {
      dispatch(actions.logListFetchError(cancelToken.reason !== undefined));
    }
  };
}

export function getLogListCount(cancelToken: CancelToken): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectLogList(getState());
    if (listEffectsCountCheck(state.countState)) {
      return;
    }

    dispatch(actions.logListCountRequest());
    try {
      const params = listEffectCountFilter(state.filter);
      addLogParams(params, state);

      const count = await logApi.getLogCount({ params, cancelToken });
      cancelToken.throwIfRequested();

      dispatch(actions.logListCountSuccess(count.data));
    } catch {
      dispatch(actions.logListCountError(cancelToken.reason !== undefined));
    }
  };
}

export function changeLogListOrder(
  orderBy: string,
  orderDesc: boolean
): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.logListChangeOrder({ orderBy, orderDesc }));
  };
}

export function changeLogListPage(page: number): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.logListChangePage(page));
  };
}

export function changeLogListFilter(filter: ILogListFilter): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.logListChangeFilter(filter));
  };
}

export function logListLoad(reload: boolean): AppThunkAction {
  return async (dispatch, getState) => {
    const state = selectLogList(getState());
    reload = listEffectReload(reload, state);
    if (reload) {
      dispatch(actions.logListReset());
    } else {
      const newState = listEffectLoad(state);
      dispatch(
        actions.logListLoadAction({
          newState: newState.newState,
          newCountState: newState.newCountState,
        })
      );
    }
  };
}

export function addLogParams(params: URLSearchParams, state: ILogListState) {
  if (state.filter!.from) {
    params.append("from", formatISO(state.filter!.from));
  }

  if (state.filter!.to) {
    params.append("to", formatISO(addDays(state.filter!.to, 1)));
  }

  if (state.filter!.userId) {
    params.append("userId", state.filter!.userId.toString());
  }

  if (state.filter!.clientId) {
    params.append("clientId", state.filter!.clientId.toString());
  }
}
