import { Form } from "formik";
import styled from "styled-components";
import { Button } from "../../styles/button";
import { SIZES } from "../../styles/sizes";
import SubmitForm from "../common/form/SubmitForm";

export const ClientButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const ClientButtonRight = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @media screen and (min-width: ${SIZES.tablet}) {
    margin-left: 12rem;
  }
`;

export const ClientButtonForm = styled(Form)`
  width: 22rem;
`;

export const ClientButtonButton = styled(Button)`
  display: block;
  width: 22rem;
`;

export const ClientButtonAnonymize = styled(ClientButtonButton)`
  margin-top: 1rem;
`;

export const ClientButtomSubmit = styled(SubmitForm)`
  margin-top: 0.5rem;
`;
