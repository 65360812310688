import { createReducer } from "@reduxjs/toolkit";
import { IDefaultDocumentFolderState } from "./types";
import * as actions from "./actions";
import { StoreState } from "../storeState";
import { STORAGE_RESET } from "../storeReset";

const initialState = {
  data: [],
  state: StoreState.None,
} as IDefaultDocumentFolderState;

export const defaultDocumentFolderReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(actions.defaultDocumentFolderFetchRequest, (state) => ({
      ...state,
      state: StoreState.Loading,
    }))
    .addCase(actions.defaultDocumentFolderFetchSuccess, (state, action) => ({
      ...state,
      state: StoreState.Loaded,
      data: action.payload,
    }))
    .addCase(actions.defaultDocumentFolderFetchError, (state) => ({
      ...state,
      state: StoreState.Error,
    }))
    .addCase(STORAGE_RESET, () => initialState)
);
