import { createAction } from "@reduxjs/toolkit";
import { IAction } from "../../models/calendar";
import { ActionTypes } from "./types";

export const actionFetchRequest = createAction<
  void,
  ActionTypes.ACTION_FETCH_REQUEST
>(ActionTypes.ACTION_FETCH_REQUEST);

export const actionFetchSuccess = createAction<
  IAction,
  ActionTypes.ACTION_FETCH_SUCCESS
>(ActionTypes.ACTION_FETCH_SUCCESS);

export const actionFetchError = createAction<
  void,
  ActionTypes.ACTION_FETCH_ERROR
>(ActionTypes.ACTION_FETCH_ERROR);
