import { createReducer } from "@reduxjs/toolkit";
import { StoreState, StoreStateDontLoad } from "../storeState";
import * as actions from "./actions";
import { IChangeDemandListState } from "./types";

const initialState = {
  state: StoreState.None,
  data: [],
  countState: StoreStateDontLoad.DontLoad,
  count: 0,
  page: 0,
  orderBy: "id",
  orderDesc: true,
  filter: {
    from: undefined,
    to: undefined,
    clientId: undefined,
    clientName: undefined,
    state: "",
    search: "",
    isOpen: false,
  },
} as IChangeDemandListState;

export const changeDemandListReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(actions.changeDemandListFetchRequest, (state, action) => ({
      ...state,
      state: StoreState.Loading,
      countState: action.payload,
    }))
    .addCase(actions.changeDemandListFetchSuccess, (state, action) => ({
      ...state,
      state: StoreState.Loaded,
      lastLoaded: new Date(),
      data: action.payload.data,
      countState: action.payload.newCountState,
      count: action.payload.newCount,
    }))
    .addCase(actions.changeDemandListFetchError, (state, action) => ({
      ...state,
      state: action.payload ? StoreState.Cancel : StoreState.Error,
    }))
    .addCase(actions.changeDemandListCountRequest, (state) => ({
      ...state,
      countState: StoreStateDontLoad.Loading,
    }))
    .addCase(actions.changeDemandListCountSuccess, (state, action) => ({
      ...state,
      countState: StoreStateDontLoad.Loaded,
      count: action.payload,
    }))
    .addCase(actions.changeDemandListCountError, (state, action) => ({
      ...state,
      countState: action.payload
        ? StoreStateDontLoad.Cancel
        : StoreStateDontLoad.Error,
    }))
    .addCase(actions.changeDemandListOrder, (state, action) => ({
      ...state,
      state: StoreState.None,
      orderBy: action.payload.orderBy,
      orderDesc: action.payload.orderDesc,
      page: 0,
    }))
    .addCase(actions.changeDemandListPage, (state, action) => ({
      ...state,
      state: StoreState.None,
      page: action.payload,
    }))
    .addCase(actions.changeDemandListFilter, (state, action) => ({
      ...state,
      state: StoreState.None,
      filter: action.payload,
      countState: StoreStateDontLoad.DontLoad,
      page: 0,
    }))
    .addCase(actions.changeDemandListReset, (state) => ({
      ...initialState,
      filter: state.filter,
    }))
);
