import { Form, Formik } from "formik";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../styles/button";
import CustomModal from "../common/modal/CustomModal";
import * as Yup from "yup";
import validations from "../../utils/validations";
import { ApiError, FormGroup } from "../../styles/form";
import CalendarModalContent, { IFormData } from "./CalendarModalContent";
import { IApplicationState } from "../../store";
import { selectIdentityId, selectIdentityName } from "../../store/identity";
import { connect } from "react-redux";
import {
  addTimeSlotToDate,
  calendarStringDefaultMax,
  calendarStringDefaultMin,
} from "../../utils/calendar";
import calendarApi from "../../api/calendar";
import { ICalendarAvailableRequest } from "../../models/calendar";
import { errorToast, promiseToastSave } from "../../utils/toasts";
import { errorSet } from "../../utils/error";

export interface IProps {
  isAdding: boolean;
  selectUser: boolean;
  selectFrom: Date;
  selectTo: Date;
  selectOnce: boolean;
  isOpen: boolean;
  identityId?: number;
  identityName?: string;
  close(): void;
  confirm(): void;
}

const CalendarModal: FC<IProps> = ({
  isAdding,
  selectUser,
  selectFrom,
  selectTo,
  selectOnce,
  isOpen,
  identityId,
  identityName,
  close,
  confirm,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setError(null);
  }, [isOpen]);

  const handleSubmit = async (data: IFormData) => {
    setError(null);

    if (data.timeTo < data.timeFrom) {
      setError(t("calendar.modal.errorTime"));
      errorToast(t("calendar.modal.errorTime"));
      return;
    }

    if (
      addTimeSlotToDate(data.dateFrom!, data.timeFrom!) < new Date() ||
      addTimeSlotToDate(data.dateTo!, data.timeTo!) < new Date()
    ) {
      setError(t("calendar.modal.errorDateTimePast"));
      errorToast(t("calendar.modal.errorDateTimePast"));
      return;
    }

    try {
      if (!data.once) {
        if (
          !data.monday &&
          !data.tuesday &&
          !data.wednesday &&
          !data.thursday &&
          !data.friday &&
          !data.saturday &&
          !data.sunday
        ) {
          setError(t("calendar.modal.errorDays"));
          errorToast(t("calendar.modal.errorDays"));
          return;
        }

        if (data.dateTo < data.dateFrom) {
          setError(t("calendar.modal.errorDate"));
          errorToast(t("calendar.modal.errorDays"));
          return;
        }
      }
      await promiseToastSave(async () => {
        const data2 = {
          from: addTimeSlotToDate(data.dateFrom, data.timeFrom),
          to: addTimeSlotToDate(
            data.once ? data.dateFrom : data.dateTo,
            data.timeTo
          ),

          monday: data.once ? false : data.monday,
          tuesday: data.once ? false : data.tuesday,
          wednesday: data.once ? false : data.wednesday,
          thursday: data.once ? false : data.thursday,
          friday: data.once ? false : data.friday,
          saturday: data.once ? false : data.saturday,
          sunday: data.once ? false : data.sunday,
        } as ICalendarAvailableRequest;

        if (isAdding) {
          await calendarApi.calendarAddAvailable(data.userId, data2);
        } else {
          await calendarApi.calendarDeleteAvailable(data.userId, data2);
        }
      });
      confirm();
    } catch (err) {
      errorSet(setError, err, t);
    }
  };

  return (
    <Formik<IFormData>
      initialValues={{
        userId: selectUser ? identityId! : 0,
        userName: selectUser ? identityName! : "",
        once: selectOnce,
        dateFrom: selectFrom,
        dateTo: selectTo,
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: false,
        sunday: false,
        timeFrom: calendarStringDefaultMin,
        timeTo: calendarStringDefaultMax,
      }}
      validationSchema={Yup.object({
        userId: validations.idRequired(t),
        dateFrom: validations.dateRequired(t),
        dateTo: Yup.date().when("once", {
          is: (once: any) => !once,
          then: validations.dateRequired(t),
        }),
        timeFrom: validations.stringRequired(t),
        timeTo: validations.stringRequired(t),
      })}
      validateOnMount={true}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({ isSubmitting, isValid, submitForm }) => (
        <CustomModal
          title={t("calendar.modal." + (isAdding ? "addTitle" : "deleteTitle"))}
          isOpen={isOpen}
          close={close}
          actions={
            <>
              <Button
                ver="secondary"
                disabled={isSubmitting}
                onClick={() => close()}
              >
                {t("common.cancel")}
              </Button>
              <Button
                disabled={!isValid || isSubmitting}
                onClick={() => submitForm()}
              >
                {t("common.save")}
              </Button>
            </>
          }
        >
          <Form>
            <FormGroup labelWidth="7rem">
              <CalendarModalContent canEditUser={!selectUser} isOpen={isOpen} />
              {error && <ApiError>{error}</ApiError>}
            </FormGroup>
          </Form>
        </CustomModal>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    identityId: selectIdentityId(state),
    identityName: selectIdentityName(state),
  };
};

export default connect(mapStateToProps)(CalendarModal);
