import { IAction } from "../../models/calendar";
import { StoreState } from "../storeState";

export enum ActionTypes {
  ACTION_FETCH_REQUEST = "@@action/FETCH_REQUEST",
  ACTION_FETCH_SUCCESS = "@@action/FETCH_SUCCESS",
  ACTION_FETCH_ERROR = "@@action/FETCH_ERROR",
}

export interface IActionState {
  state: StoreState;
  data: IAction | null;
}
