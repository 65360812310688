import { AppThunkAction, AppThunkActionAsync } from "..";
import * as actions from "./actions";
import templateApi from "../../api/template";
import {
  ITemplateDocumentListFilter,
  ITemplateDocumentListState,
  selectTemplateDocumentList,
} from ".";
import { CancelToken } from "axios";
import {
  listEffectCountFilter,
  listEffectGetFilter,
  listEffectLoad,
  listEffectReload,
  listEffectsCountCheck,
  listEffectsGetCheck,
  listEffectsGetCount,
  listEffectsGetNew,
  listEffectsGetParams,
} from "../listEffect";

export function getTemplateDocumentList(
  cancelToken: CancelToken
): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectTemplateDocumentList(getState());
    if (listEffectsGetCheck(state.state)) {
      return;
    }

    let newState = listEffectsGetNew(state);
    dispatch(actions.templateDocumentListFetchRequest(newState.newCountState));
    try {
      const params = listEffectsGetParams(state, newState.take);
      listEffectGetFilter(params, state.filter);
      addTemplateDocumentParams(params, state);

      const documents = await templateApi.getDocumentList({
        params,
        cancelToken,
      });
      cancelToken.throwIfRequested();
      newState = listEffectsGetCount(newState, documents);

      dispatch(
        actions.templateDocumentListFetchSuccess({
          data: documents.data,
          newCountState: newState.newCountState,
          newCount: newState.newCount,
        })
      );
    } catch {
      dispatch(
        actions.templateDocumentListFetchError(cancelToken.reason !== undefined)
      );
    }
  };
}

export function getTemplateDocumentListCount(
  cancelToken: CancelToken
): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectTemplateDocumentList(getState());
    if (listEffectsCountCheck(state.countState)) {
      return;
    }

    dispatch(actions.templateDocumentListCountRequest());
    try {
      const params = listEffectCountFilter(state.filter);
      addTemplateDocumentParams(params, state);
      const count = await templateApi.getDocumentCount({ params, cancelToken });
      cancelToken.throwIfRequested();

      dispatch(actions.templateDocumentListCountSuccess(count.data));
    } catch {
      dispatch(
        actions.templateDocumentListCountError(cancelToken.reason !== undefined)
      );
    }
  };
}

export function changeTemplateDocumentListOrder(
  orderBy: string,
  orderDesc: boolean
): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.templateDocumentListChangeOrder({ orderBy, orderDesc }));
  };
}

export function changeTemplateDocumentListPage(page: number): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.templateDocumentListChangePage(page));
  };
}

export function changeTemplateDocumentListFilter(
  filter: ITemplateDocumentListFilter
): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.templateDocumentListChangeFilter(filter));
  };
}

export function templateDocumentListLoad(reload: boolean): AppThunkAction {
  return async (dispatch, getState) => {
    const state = selectTemplateDocumentList(getState());
    reload = listEffectReload(reload, state);
    if (reload) {
      dispatch(actions.templateDocumentListReset());
    } else {
      const newState = listEffectLoad(state);
      dispatch(
        actions.templateDocumentListLoadAction({
          newState: newState.newState,
          newCountState: newState.newCountState,
        })
      );
    }
  };
}

export function addTemplateDocumentParams(
  params: URLSearchParams,
  state: ITemplateDocumentListState
) {
  if (state.filter!.idFolder) {
    params.append("idFolder", state.filter!.idFolder.toString());
  }
}
