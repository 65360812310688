import { AppThunkAction, AppThunkActionAsync } from "..";
import * as actions from "./actions";
import groupApi from "../../api/group";
import { selectGroupList } from ".";
import { IFilterType } from "../filterType";
import { CancelToken } from "axios";
import {
  listEffectCountFilter,
  listEffectGetFilter,
  listEffectLoad,
  listEffectReload,
  listEffectsCountCheck,
  listEffectsGetCheck,
  listEffectsGetCount,
  listEffectsGetNew,
  listEffectsGetParams,
} from "../listEffect";

export function getGroupList(cancelToken: CancelToken): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectGroupList(getState());
    if (listEffectsGetCheck(state.state)) {
      return;
    }

    let newState = listEffectsGetNew(state);
    dispatch(actions.groupListFetchRequest(newState.newCountState));
    try {
      const params = listEffectsGetParams(state, newState.take);
      listEffectGetFilter(params, state.filter);

      const groups = await groupApi.getList({ params, cancelToken });
      cancelToken.throwIfRequested();
      newState = listEffectsGetCount(newState, groups);

      dispatch(
        actions.groupListFetchSuccess({
          data: groups.data,
          newCountState: newState.newCountState,
          newCount: newState.newCount,
        })
      );
    } catch {
      dispatch(actions.groupListFetchError(cancelToken.reason !== undefined));
    }
  };
}

export function getGroupListCount(
  cancelToken: CancelToken
): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectGroupList(getState());
    if (listEffectsCountCheck(state.countState)) {
      return;
    }

    dispatch(actions.groupListCountRequest());
    try {
      const params = listEffectCountFilter(state.filter);
      const count = await groupApi.getCount({ params, cancelToken });
      cancelToken.throwIfRequested();

      dispatch(actions.groupListCountSuccess(count.data));
    } catch {
      dispatch(actions.groupListCountError(cancelToken.reason !== undefined));
    }
  };
}

export function changeGroupListOrder(
  orderBy: string,
  orderDesc: boolean
): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.groupListChangeOrder({ orderBy, orderDesc }));
  };
}

export function changeGroupListPage(page: number): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.groupListChangePage(page));
  };
}

export function changeGroupListFilter(filter: IFilterType): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.groupListChangeFilter(filter));
  };
}

export function groupListLoad(reload: boolean): AppThunkAction {
  return async (dispatch, getState) => {
    const state = selectGroupList(getState());
    reload = listEffectReload(reload, state);
    if (reload) {
      dispatch(actions.groupListReset());
    } else {
      const newState = listEffectLoad(state);
      dispatch(
        actions.groupListLoadAction({
          newState: newState.newState,
          newCountState: newState.newCountState,
        })
      );
    }
  };
}
