import axios, { AxiosResponse } from "axios";

const api = axios.create();

const uploadDocument = async (
  url: string,
  data: File
): Promise<AxiosResponse> =>
  api.post(url, data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });

const bucketApi = {
  uploadDocument,
};

export default bucketApi;
