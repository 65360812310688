import { createAction } from "@reduxjs/toolkit";
import { ICareTypeItem } from "../../models/careType";
import { StoreState, StoreStateDontLoad } from "../storeState";
import { CareTypeListTypes, ICareTypeListFilter } from "./types";

export const careTypeListFetchRequest = createAction<
  StoreStateDontLoad,
  CareTypeListTypes.CARE_TYPE_LIST_FETCH_REQUEST
>(CareTypeListTypes.CARE_TYPE_LIST_FETCH_REQUEST);

export const careTypeListFetchSuccess = createAction<
  {
    data: ICareTypeItem[];
    newCountState: StoreStateDontLoad;
    newCount: number;
  },
  CareTypeListTypes.CARE_TYPE_LIST_FETCH_SUCCESS
>(CareTypeListTypes.CARE_TYPE_LIST_FETCH_SUCCESS);

export const careTypeListFetchError = createAction<
  boolean,
  CareTypeListTypes.CARE_TYPE_LIST_FETCH_ERROR
>(CareTypeListTypes.CARE_TYPE_LIST_FETCH_ERROR);

export const careTypeListCountRequest = createAction<
  void,
  CareTypeListTypes.CARE_TYPE_LIST_COUNT_REQUEST
>(CareTypeListTypes.CARE_TYPE_LIST_COUNT_REQUEST);

export const careTypeListCountSuccess = createAction<
  number,
  CareTypeListTypes.CARE_TYPE_LIST_COUNT_SUCCESS
>(CareTypeListTypes.CARE_TYPE_LIST_COUNT_SUCCESS);

export const careTypeListCountError = createAction<
  boolean,
  CareTypeListTypes.CARE_TYPE_LIST_COUNT_ERROR
>(CareTypeListTypes.CARE_TYPE_LIST_COUNT_ERROR);

export const careTypeListChangeOrder = createAction<
  { orderBy: string; orderDesc: boolean },
  CareTypeListTypes.CARE_TYPE_LIST_CHANGE_ORDER
>(CareTypeListTypes.CARE_TYPE_LIST_CHANGE_ORDER);

export const careTypeListChangePage = createAction<
  number,
  CareTypeListTypes.CARE_TYPE_LIST_CHANGE_PAGE
>(CareTypeListTypes.CARE_TYPE_LIST_CHANGE_PAGE);

export const careTypeListChangeFilter = createAction<
  ICareTypeListFilter,
  CareTypeListTypes.CARE_TYPE_LIST_CHANGE_FILTER
>(CareTypeListTypes.CARE_TYPE_LIST_CHANGE_FILTER);

export const careTypeListReset = createAction<
  void,
  CareTypeListTypes.CARE_TYPE_LIST_RESET
>(CareTypeListTypes.CARE_TYPE_LIST_RESET);

export const careTypeListLoadAction = createAction<
  { newState: StoreState; newCountState: StoreStateDontLoad },
  CareTypeListTypes.CARE_TYPE_LIST_LOAD
>(CareTypeListTypes.CARE_TYPE_LIST_LOAD);
