import { createReducer } from "@reduxjs/toolkit";
import { IUserListState } from "./types";
import * as actions from "./actions";
import { StoreState, StoreStateDontLoad } from "../storeState";
import { STORAGE_RESET } from "../storeReset";

const initialState = {
  state: StoreState.None,
  data: [],
  countState: StoreStateDontLoad.DontLoad,
  count: 0,
  page: 0,
  orderBy: "LastName",
  orderDesc: false,
  filter: {
    search: undefined,
    isOpen: false,
    activeOnly: true,
    assistanceId: 0,
    assistanceName: "",
    groups: [],
  },
} as IUserListState;

export const userListReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(actions.userListFetchRequest, (state, action) => ({
      ...state,
      state: StoreState.Loading,
      countState: action.payload,
    }))
    .addCase(actions.userListFetchSuccess, (state, action) => ({
      ...state,
      state: StoreState.Loaded,
      lastLoaded: new Date(),
      data: action.payload.data,
      countState: action.payload.newCountState,
      count: action.payload.newCount,
    }))
    .addCase(actions.userListFetchError, (state, action) => ({
      ...state,
      state: action.payload ? StoreState.Cancel : StoreState.Error,
    }))
    .addCase(actions.userListCountRequest, (state) => ({
      ...state,
      countState: StoreStateDontLoad.Loading,
    }))
    .addCase(actions.userListCountSuccess, (state, action) => ({
      ...state,
      countState: StoreStateDontLoad.Loaded,
      count: action.payload,
    }))
    .addCase(actions.userListCountError, (state, action) => ({
      ...state,
      countState: action.payload
        ? StoreStateDontLoad.Cancel
        : StoreStateDontLoad.Error,
    }))
    .addCase(actions.userListChangeOrder, (state, action) => ({
      ...state,
      state: StoreState.None,
      orderBy: action.payload.orderBy,
      orderDesc: action.payload.orderDesc,
      page: 0,
    }))
    .addCase(actions.userListChangePage, (state, action) => ({
      ...state,
      state: StoreState.None,
      page: action.payload,
    }))
    .addCase(actions.userListChangeFilter, (state, action) => ({
      ...state,
      state: StoreState.None,
      countState: StoreStateDontLoad.DontLoad,
      filter: action.payload,
      page: 0,
    }))
    .addCase(actions.userListReset, (state) => ({
      ...initialState,
      filter: state.filter,
    }))
    .addCase(actions.userListLoadAction, (state, action) => ({
      ...state,
      state: action.payload.newState,
      countState: action.payload.newCountState,
    }))
    .addCase(STORAGE_RESET, () => initialState)
);
