import { Form, Formik } from "formik";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { IAction } from "../../models/calendar";
import { IApplicationState } from "../../store";
import { StoreState } from "../../store/storeState";
import {
  getDefaultAction,
  getAction,
  selectAction,
  selectActionState,
} from "../../store/action";
import { FormButton } from "../../styles/button";
import { ApiError, FormGroup } from "../../styles/form";
import Loader from "../common/Loader";
import * as Yup from "yup";
import calendarApi from "../../api/calendar";
import { SpaceBetweenButtons } from "../../styles/spaces";
import validations from "../../utils/validations";
import { RightType } from "../../models/auth";
import { hasClientWriteRight } from "../../utils/rights";
import { selectIdentityRights } from "../../store/identity";
import SuggestionFormik from "../common/suggestion/SuggestionFormik";
import {
  handleLoadActionListingSuggestions,
  handleLoadUserSuggestions,
} from "../../utils/suggestions";
import DatePicker from "../common/form/DatePicker";
import { clientActionListLoad } from "../../store/clientActionList";
import { selectClient } from "../../store/client";
import {
  addTimeSlotToDate,
  calendarStringDefaultMin,
  calendarStringDefaultMaxAction as calendarActionStringDefaultMax,
  translateTimeToTimeSlot,
} from "../../utils/calendar";
import { startOfDay } from "date-fns";
import {
  CalendarModalDayField,
  CalendarModalDays,
  CalendarModalDayText,
  CalendarModalTimes,
  CalendarModalTimesContainer,
  CalendarModalTimesLabel,
} from "../calendar/CalendarStyles";
import Input from "../common/form/Input";
import SubmitForm from "../common/form/SubmitForm";
import { errorToast, promiseToastSave } from "../../utils/toasts";
import { IClient } from "../../models/client";
import AssignUserConflicts from "../calendar/AssignUserConflicts";
import CalendarTimes from "../calendar/CalendarTimes";
import { errorSet } from "../../utils/error";
import formatters from "../../utils/formatters";

interface IActionForm {
  id: number;
  description: string;
  userCreatorId: number;
  userCreatorName: string;
  userAssigneeId: number;
  userAssigneeName: string;
  typeId: number;
  typeName: string;
  dateFrom?: Date;
  dateTo?: Date;
  timeFrom?: string;
  timeTo?: string;
  once: boolean;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
}

const dayFields = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

interface IProps {
  client: IClient | null;
  actionState: StoreState;
  action: IAction | null;
  identityRights?: RightType[];
  getAction(actionId: number): void;
  getDefaultAction(): void;
  clientActionListLoad(reload: boolean): void;
}

const ClientActionDetail: FC<IProps> = ({
  client,
  actionState,
  action,
  identityRights,
  getAction,
  getDefaultAction,
  clientActionListLoad,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { clientId: paramClientId, actionId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isLoadingConflicts, setIsLoadingConflicts] = useState<boolean>(false);
  const returnTo = new URLSearchParams(location.search).get("return");

  useEffect(() => {
    if (actionId === "new") {
      getDefaultAction();
    } else {
      getAction(parseInt(actionId!));
    }
    setLoading(false);
  }, [actionId, getAction, getDefaultAction]);

  const handleSubmit = async (data: IActionForm) => {
    setError(null);

    if (
      actionId !== "new" &&
      (action!.from! < new Date() ||
       action!.to! < new Date())
    ) {
      setError(t("calendar.modal.errorDateTimeOutdated"));
      errorToast(t("calendar.modal.errorDateTimeOutdated"));
      return;
    }

    if (data.timeTo! < data.timeFrom!) {
      setError(t("calendar.modal.errorTime"));
      errorToast(t("calendar.modal.errorTime"));
      return;
    }

    if (!data.once)
    {
      if (
        addTimeSlotToDate(data.dateFrom!, data.timeFrom!) < new Date() ||
        addTimeSlotToDate(data.dateTo!, data.timeTo!) < new Date()
      ) {
        setError(t("calendar.modal.errorDateTimePast"));
        errorToast(t("calendar.modal.errorDateTimePast"));
        return;
      }
    }
    else
    {
      if (
        addTimeSlotToDate(data.dateFrom!, data.timeFrom!) < new Date() ||
        addTimeSlotToDate(data.dateFrom!, data.timeTo!) < new Date()
      ) {
        setError(t("calendar.modal.errorDateTimePast"));
        errorToast(t("calendar.modal.errorDateTimePast"));
        return;
      }
    }

    try {
      if (actionId === "new") {
        if (!data.once) {
          if (
            !data.monday &&
            !data.tuesday &&
            !data.wednesday &&
            !data.thursday &&
            !data.friday &&
            !data.saturday &&
            !data.sunday
          ) {
            setError(t("calendar.modal.errorDays"));
            errorToast(t("calendar.modal.errorDays"));
            return;
          }

          if (data.dateTo! < data.dateFrom!) {
            setError(t("calendar.modal.errorDate"));
            errorToast(t("calendar.modal.errorDays"));
            return;
          }
        }

        await promiseToastSave(async () => {
          const data2 = {
            idClient: client!.id,
            idUserAssignee: data.userAssigneeId ? data.userAssigneeId : null,
            idActionListingType: data.typeId,
            description: data.description ? data.description : null,

            from: addTimeSlotToDate(data.dateFrom!, data.timeFrom!),
            to: addTimeSlotToDate(
              data.once ? data.dateFrom! : data.dateTo!,
              data.timeTo!
            ),

            monday: data.once ? false : data.monday,
            tuesday: data.once ? false : data.tuesday,
            wednesday: data.once ? false : data.wednesday,
            thursday: data.once ? false : data.thursday,
            friday: data.once ? false : data.friday,
            saturday: data.once ? false : data.saturday,
            sunday: data.once ? false : data.sunday,
          };

          await calendarApi.createAction(data2);
        });
      } else {
        await promiseToastSave(async () => {
          const data2 = {
            id: data.id,
            description: data.description,
            userCreator: { id: data.userCreatorId, name: data.userCreatorName },
            userAssignee: data.userAssigneeId
              ? { id: data.userAssigneeId, name: data.userAssigneeName }
              : null,
            client: {
              id: client!.id,
              name: formatters.fullName(client!),
            },
            type: { id: data.typeId, name: data.typeName },
            from: addTimeSlotToDate(data.dateFrom!, data.timeFrom!),
            to: addTimeSlotToDate(data.dateFrom!, data.timeTo!),
          };

          await calendarApi.updateAction(data2);
        });
      }

      clientActionListLoad(true);
      navigate(returnTo ?? `/client/${paramClientId}/action`);
    } catch (err) {
      errorSet(setError, err, t);
    }
  };

  const handleCancel = () => {
    navigate(returnTo ?? `/client/${paramClientId}/action`);
  };

  if (loading || actionState === StoreState.Loading) {
    return <Loader />;
  }

  if (actionState === StoreState.Error) {
    return t("errors.unknown");
  }

  return (
    <Formik<IActionForm>
      initialValues={{
        id: action!.id,
        description: action!.description ?? "",
        userCreatorId: action!.userCreator.id,
        userCreatorName: action!.userCreator.name,
        userAssigneeId: action!.userAssignee?.id ?? 0,
        userAssigneeName: action!.userAssignee?.name ?? "",
        typeId: action!.type.id,
        typeName: action!.type.name,
        dateFrom: action!.from ? startOfDay(action!.from) : undefined,
        timeFrom: action!.from
          ? translateTimeToTimeSlot(action!.from)
          : calendarStringDefaultMin,
        dateTo: action!.to ? startOfDay(action!.to) : undefined,
        timeTo: action!.to
          ? translateTimeToTimeSlot(action!.to)
          : calendarActionStringDefaultMax,
        once: actionId !== "new" ? true : false,
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: false,
        sunday: false,
      }}
      validationSchema={Yup.object({
        typeId: validations.idRequired(t),
        dateFrom: validations.dateRequired(t),
        dateTo: Yup.date().when("once", {
          is: (once: any) => !once,
          then: actionId === "new" ? validations.dateRequired(t) : Yup.date(),
        }),
        timeFrom: validations.stringRequired(t),
        timeTo: validations.stringRequired(t),
      })}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <h1>{t("client.action.detailTitle")}</h1>
          <FormGroup>
            <SuggestionFormik
              nameId="userAssigneeId"
              nameText="userAssigneeName"
              label={t("client.action.user")}
              loadSuggestions={handleLoadUserSuggestions}
            />
            <AssignUserConflicts
              setIsLoadingConflicts={setIsLoadingConflicts}
              excludedActionId={actionId !== "new" ? actionId : undefined}
            />
            <SuggestionFormik
              nameId="typeId"
              nameText="typeName"
              label={t("client.action.type")}
              loadSuggestions={handleLoadActionListingSuggestions}
            />
            <Input
              key="description"
              name="description"
              label={t(`calendar.list.description`)}
              maxLength={1024}
            />
            {actionId === "new" && (
              <Input
                key="once"
                name="once"
                type="checkbox"
                label={t(`client.action.once`)}
                inputWidth="1.5rem"
                inputHeight="1.5rem"
              />
            )}
            {actionId === "new" ? (
              <CalendarModalTimesContainer>
                <label>{t("client.action.date")}</label>
                <CalendarModalTimes>
                  <div>
                    <CalendarModalTimesLabel>
                      {t("common.from")}
                    </CalendarModalTimesLabel>
                    <DatePicker name={"dateFrom"} />
                  </div>
                  {!values.once && (
                    <div>
                      <CalendarModalTimesLabel>
                        {t("common.to")}
                      </CalendarModalTimesLabel>
                      <DatePicker name={"dateTo"} />
                    </div>
                  )}
                </CalendarModalTimes>
              </CalendarModalTimesContainer>
            ) : (
              <DatePicker name="dateFrom" label={t("client.action.date")} />
            )}
            {actionId === "new" && !values.once && (
              <CalendarModalDays>
                {dayFields.map((d) => (
                  <label key={d}>
                    <CalendarModalDayField name={d} type="checkbox" />
                    <CalendarModalDayText>
                      {t(`common.days.${d}`)}
                    </CalendarModalDayText>
                  </label>
                ))}
              </CalendarModalDays>
            )}
            <CalendarTimes />
          </FormGroup>
          {error && <ApiError>{error}</ApiError>}
          <FormButton
            ver="secondary"
            disabled={isSubmitting || isLoadingConflicts}
            onClick={handleCancel}
          >
            {t("common.back")}
          </FormButton>
          {hasClientWriteRight(identityRights, [
            RightType.WriteClientAction,
          ]) && (
            <>
              <SpaceBetweenButtons />
              <SubmitForm
                disabled={
                  isLoadingConflicts ||
                  (!!action!.from && action!.from < new Date()) ||
                  (!!action!.to && action!.to < new Date())
                }
              />
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    client: selectClient(state),
    actionState: selectActionState(state),
    action: selectAction(state),
    identityRights: selectIdentityRights(state),
  };
};

const mapDispachToProps = {
  getAction,
  getDefaultAction,
  clientActionListLoad,
};

export default connect(mapStateToProps, mapDispachToProps)(ClientActionDetail);
