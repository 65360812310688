import { createAction } from "@reduxjs/toolkit";
import { IListingItem, ListingType } from "../../models/listing";
import { IFilterType } from "../filterType";
import { StoreState, StoreStateDontLoad } from "../storeState";
import { ListingListTypes } from "./types";

export const listingListFetchRequest = createAction<
  { type: ListingType; newCountState: StoreStateDontLoad },
  ListingListTypes.LISTING_LIST_FETCH_REQUEST
>(ListingListTypes.LISTING_LIST_FETCH_REQUEST);

export const listingListFetchSuccess = createAction<
  {
    type: ListingType;
    data: IListingItem[];
    newCountState: StoreStateDontLoad;
    newCount: number;
  },
  ListingListTypes.LISTING_LIST_FETCH_SUCCESS
>(ListingListTypes.LISTING_LIST_FETCH_SUCCESS);

export const listingListFetchError = createAction<
  { type: ListingType; canceled: boolean },
  ListingListTypes.LISTING_LIST_FETCH_ERROR
>(ListingListTypes.LISTING_LIST_FETCH_ERROR);

export const listingListCountRequest = createAction<
  ListingType,
  ListingListTypes.LISTING_LIST_COUNT_REQUEST
>(ListingListTypes.LISTING_LIST_COUNT_REQUEST);

export const listingListCountSuccess = createAction<
  { type: ListingType; count: number },
  ListingListTypes.LISTING_LIST_COUNT_SUCCESS
>(ListingListTypes.LISTING_LIST_COUNT_SUCCESS);

export const listingListCountError = createAction<
  { type: ListingType; canceled: boolean },
  ListingListTypes.LISTING_LIST_COUNT_ERROR
>(ListingListTypes.LISTING_LIST_COUNT_ERROR);

export const listingListChangeOrder = createAction<
  { type: ListingType; orderBy: string; orderDesc: boolean },
  ListingListTypes.LISTING_LIST_CHANGE_ORDER
>(ListingListTypes.LISTING_LIST_CHANGE_ORDER);

export const listingListChangePage = createAction<
  { type: ListingType; page: number },
  ListingListTypes.LISTING_LIST_CHANGE_PAGE
>(ListingListTypes.LISTING_LIST_CHANGE_PAGE);

export const listingListChangeFilter = createAction<
  { type: ListingType; filter: IFilterType },
  ListingListTypes.LISTING_LIST_CHANGE_FILTER
>(ListingListTypes.LISTING_LIST_CHANGE_FILTER);

export const listingListReset = createAction<
  ListingType,
  ListingListTypes.LISTING_LIST_RESET
>(ListingListTypes.LISTING_LIST_RESET);

export const listingListLoadAction = createAction<
  {
    type: ListingType;
    newState: StoreState;
    newCountState: StoreStateDontLoad;
  },
  ListingListTypes.LISTING_LIST_LOAD
>(ListingListTypes.LISTING_LIST_LOAD);
