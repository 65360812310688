import { createAction } from "@reduxjs/toolkit";
import { IClientContactPersonItem } from "../../models/client";
import { IFilterType } from "../filterType";
import { StoreState, StoreStateDontLoad } from "../storeState";
import { ClientContactPersonListTypes } from "./types";

export const clientContactPersonListFetchRequest = createAction<
  StoreStateDontLoad,
  ClientContactPersonListTypes.CLIENT_CONTACT_PERSON_LIST_FETCH_REQUEST
>(ClientContactPersonListTypes.CLIENT_CONTACT_PERSON_LIST_FETCH_REQUEST);

export const clientContactPersonListFetchSuccess = createAction<
  {
    data: IClientContactPersonItem[];
    newCountState: StoreStateDontLoad;
    newCount: number;
  },
  ClientContactPersonListTypes.CLIENT_CONTACT_PERSON_LIST_FETCH_SUCCESS
>(ClientContactPersonListTypes.CLIENT_CONTACT_PERSON_LIST_FETCH_SUCCESS);

export const clientContactPersonListFetchError = createAction<
  boolean,
  ClientContactPersonListTypes.CLIENT_CONTACT_PERSON_LIST_FETCH_ERROR
>(ClientContactPersonListTypes.CLIENT_CONTACT_PERSON_LIST_FETCH_ERROR);

export const clientContactPersonListCountRequest = createAction<
  void,
  ClientContactPersonListTypes.CLIENT_CONTACT_PERSON_LIST_COUNT_REQUEST
>(ClientContactPersonListTypes.CLIENT_CONTACT_PERSON_LIST_COUNT_REQUEST);

export const clientContactPersonListCountSuccess = createAction<
  number,
  ClientContactPersonListTypes.CLIENT_CONTACT_PERSON_LIST_COUNT_SUCCESS
>(ClientContactPersonListTypes.CLIENT_CONTACT_PERSON_LIST_COUNT_SUCCESS);

export const clientContactPersonListCountError = createAction<
  boolean,
  ClientContactPersonListTypes.CLIENT_CONTACT_PERSON_LIST_COUNT_ERROR
>(ClientContactPersonListTypes.CLIENT_CONTACT_PERSON_LIST_COUNT_ERROR);

export const clientContactPersonListChangeOrder = createAction<
  { orderBy: string; orderDesc: boolean },
  ClientContactPersonListTypes.CLIENT_CONTACT_PERSON_LIST_CHANGE_ORDER
>(ClientContactPersonListTypes.CLIENT_CONTACT_PERSON_LIST_CHANGE_ORDER);

export const clientContactPersonListChangePage = createAction<
  number,
  ClientContactPersonListTypes.CLIENT_CONTACT_PERSON_LIST_CHANGE_PAGE
>(ClientContactPersonListTypes.CLIENT_CONTACT_PERSON_LIST_CHANGE_PAGE);

export const clientContactPersonListChangeFilter = createAction<
  IFilterType,
  ClientContactPersonListTypes.CLIENT_CONTACT_PERSON_LIST_CHANGE_FILTER
>(ClientContactPersonListTypes.CLIENT_CONTACT_PERSON_LIST_CHANGE_FILTER);

export const clientContactPersonListReset = createAction<
  void,
  ClientContactPersonListTypes.CLIENT_CONTACT_PERSON_LIST_RESET
>(ClientContactPersonListTypes.CLIENT_CONTACT_PERSON_LIST_RESET);

export const clientContactPersonListLoadAction = createAction<
  { newState: StoreState; newCountState: StoreStateDontLoad },
  ClientContactPersonListTypes.CLIENT_CONTACT_PERSON_LIST_LOAD
>(ClientContactPersonListTypes.CLIENT_CONTACT_PERSON_LIST_LOAD);
