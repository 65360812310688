import { MutableRefObject, useEffect } from "react";

export const useOnClickOutside = (
  ref: MutableRefObject<any>,
  exceptRef: MutableRefObject<any>,
  handler: () => void
) => {
  useEffect(() => {
    const listener = (event: Event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      if (exceptRef.current && exceptRef.current.contains(event.target)) {
        return;
      }

      handler();
    };
    document.addEventListener("mousedown", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, [ref, exceptRef, handler]);
};
