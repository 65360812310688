import { createReducer } from "@reduxjs/toolkit";
import { IClientDebtListState } from "./types";
import * as actions from "./actions";
import { StoreState, StoreStateDontLoad } from "../storeState";
import { STORAGE_RESET } from "../storeReset";

const initialState = {
  state: StoreState.None,
  data: [],
  countState: StoreStateDontLoad.DontLoad,
  count: 0,
  page: 0,
  filter: { search: undefined, isOpen: false },
} as IClientDebtListState;

export const clientDebtListReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(actions.clientDebtListFetchRequest, (state, action) => ({
      ...state,
      state: StoreState.Loading,
      countState: action.payload,
    }))
    .addCase(actions.clientDebtListFetchSuccess, (state, action) => ({
      ...state,
      state: StoreState.Loaded,
      lastLoaded: new Date(),
      data: action.payload.data,
      countState: action.payload.newCountState,
      count: action.payload.newCount,
    }))
    .addCase(actions.clientDebtListFetchError, (state, action) => ({
      ...state,
      state: action.payload ? StoreState.Cancel : StoreState.Error,
    }))
    .addCase(actions.clientDebtListCountRequest, (state) => ({
      ...state,
      countState: StoreStateDontLoad.Loading,
    }))
    .addCase(actions.clientDebtListCountSuccess, (state, action) => ({
      ...state,
      countState: StoreStateDontLoad.Loaded,
      count: action.payload,
    }))
    .addCase(actions.clientDebtListCountError, (state, action) => ({
      ...state,
      countState: action.payload
        ? StoreStateDontLoad.Cancel
        : StoreStateDontLoad.Error,
    }))
    .addCase(actions.clientDebtListChangeOrder, (state, action) => ({
      ...state,
      state: StoreState.None,
      orderBy: action.payload.orderBy,
      orderDesc: action.payload.orderDesc,
      page: 0,
    }))
    .addCase(actions.clientDebtListChangePage, (state, action) => ({
      ...state,
      state: StoreState.None,
      page: action.payload,
    }))
    .addCase(actions.clientDebtListChangeFilter, (state, action) => ({
      ...state,
      state: StoreState.None,
      countState: StoreStateDontLoad.DontLoad,
      filter: action.payload,
      page: 0,
    }))
    .addCase(actions.clientDebtListReset, (state) => ({
      ...initialState,
      filter: state.filter,
    }))
    .addCase(actions.clientDebtListLoadAction, (state, action) => ({
      ...state,
      state: action.payload.newState,
      countState: action.payload.newCountState,
    }))
    .addCase(STORAGE_RESET, () => initialState)
);
