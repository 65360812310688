import * as ReselectModule from "reselect";
import { IApplicationState } from "..";
import { RightType } from "../../models/auth";
import { StoreState } from "../storeState";

export const selectIdentityState: ReselectModule.Selector<
  IApplicationState,
  StoreState
> = (state) => state.identity.state;

export const selectIdentityId: ReselectModule.Selector<
  IApplicationState,
  number | undefined
> = (state) => state.identity.data?.id;

export const selectIdentityName: ReselectModule.Selector<
  IApplicationState,
  string | undefined
> = (state) => state.identity.data?.name;

export const selectIdentityRights: ReselectModule.Selector<
  IApplicationState,
  RightType[] | undefined
> = (state) => state.identity.data?.rightsList;
