import { AppThunkAction, AppThunkActionAsync } from "..";
import * as actions from "./actions";
import roleApi from "../../api/role";
import { selectRoleList } from ".";
import { IFilterType } from "../filterType";
import { CancelToken } from "axios";
import {
  listEffectCountFilter,
  listEffectGetFilter,
  listEffectLoad,
  listEffectReload,
  listEffectsCountCheck,
  listEffectsGetCheck,
  listEffectsGetCount,
  listEffectsGetNew,
  listEffectsGetParams,
} from "../listEffect";

export function getRoleList(cancelToken: CancelToken): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectRoleList(getState());
    if (listEffectsGetCheck(state.state)) {
      return;
    }

    let newState = listEffectsGetNew(state);
    dispatch(actions.roleListFetchRequest(newState.newCountState));
    try {
      const params = listEffectsGetParams(state, newState.take);
      listEffectGetFilter(params, state.filter);

      const roles = await roleApi.getList({ params, cancelToken });
      cancelToken.throwIfRequested();
      newState = listEffectsGetCount(newState, roles);

      dispatch(
        actions.roleListFetchSuccess({
          data: roles.data,
          newCountState: newState.newCountState,
          newCount: newState.newCount,
        })
      );
    } catch {
      dispatch(actions.roleListFetchError(cancelToken.reason !== undefined));
    }
  };
}

export function getRoleListCount(
  cancelToken: CancelToken
): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectRoleList(getState());
    if (listEffectsCountCheck(state.countState)) {
      return;
    }

    dispatch(actions.roleListCountRequest());
    try {
      const params = listEffectCountFilter(state.filter);
      const count = await roleApi.getCount({ params, cancelToken });
      cancelToken.throwIfRequested();

      dispatch(actions.roleListCountSuccess(count.data));
    } catch {
      dispatch(actions.roleListCountError(cancelToken.reason !== undefined));
    }
  };
}

export function changeRoleListOrder(
  orderBy: string,
  orderDesc: boolean
): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.roleListChangeOrder({ orderBy, orderDesc }));
  };
}

export function changeRoleListPage(page: number): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.roleListChangePage(page));
  };
}

export function changeRoleListFilter(filter: IFilterType): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.roleListChangeFilter(filter));
  };
}

export function roleListLoad(reload: boolean): AppThunkAction {
  return async (dispatch, getState) => {
    const state = selectRoleList(getState());
    reload = listEffectReload(reload, state);
    if (reload) {
      dispatch(actions.roleListReset());
    } else {
      const newState = listEffectLoad(state);
      dispatch(
        actions.roleListLoadAction({
          newState: newState.newState,
          newCountState: newState.newCountState,
        })
      );
    }
  };
}
