import * as ReselectModule from "reselect";
import { IListingListItems } from ".";
import { IApplicationState } from "..";
import { ListingType } from "../../models/listing";

export const selectListingList: ReselectModule.ParametricSelector<
  IApplicationState,
  ListingType,
  IListingListItems
> = (state, type) => state.listingList.types[type];
