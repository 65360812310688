import { IRole } from "../../models/role";
import { StoreState } from "../storeState";

export enum RoleTypes {
  ROLE_FETCH_REQUEST = "@@role/FETCH_REQUEST",
  ROLE_FETCH_SUCCESS = "@@role/FETCH_SUCCESS",
  ROLE_FETCH_ERROR = "@@role/FETCH_ERROR",
}

export interface IRoleState {
  state: StoreState;
  data: IRole | null;
}
