import { FC } from "react";
import { useTranslation } from "react-i18next";
import { getLinkIcon, getLinkName } from "../../utils/links";
import {
  DashboardExternalLinkContainer,
  DashboardLinkContainer,
  DashBoardLinkGroup,
  DashBoardLinkIcon,
  DashBoardLinkText,
} from "./DashboardLinksStyles";

interface IProps {
  path: string;
}

const DashboardLink: FC<IProps> = ({ path }) => {
  const { t } = useTranslation();

  if (path === "#public") {
    return (
      <DashboardExternalLinkContainer
        href={process.env.REACT_APP_PUBLIC_WEB_URL}
        target="_blank"
      >
        <DashBoardLinkGroup>
          <DashBoardLinkIcon
            icon={getLinkIcon(path)}
            //todo: color
            color={"#3B3B3B"}
            size="6x"
          />
          <DashBoardLinkText>{getLinkName(t, path)}</DashBoardLinkText>
        </DashBoardLinkGroup>
      </DashboardExternalLinkContainer>
    );
  }

  return (
    <DashboardLinkContainer to={path}>
      <DashBoardLinkGroup>
        <DashBoardLinkIcon
          icon={getLinkIcon(path)}
          //todo: color
          color={"#3B3B3B"}
          size="6x"
        />
        <DashBoardLinkText>{getLinkName(t, path)}</DashBoardLinkText>
      </DashBoardLinkGroup>
    </DashboardLinkContainer>
  );
};

export default DashboardLink;
