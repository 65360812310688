import { IWorkflow } from "../../models/workflow";
import { StoreState } from "../storeState";

export enum WorkflowTypes {
  WORKFLOW_FETCH_REQUEST = "@@workflow/FETCH_REQUEST",
  WORKFLOW_FETCH_SUCCESS = "@@workflow/FETCH_SUCCESS",
  WORKFLOW_FETCH_ERROR = "@@workflow/FETCH_ERROR",
}

export interface IWorkflowState {
  state: StoreState;
  data: IWorkflow | null;
}
