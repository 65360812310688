import { createAction } from "@reduxjs/toolkit";
import { IAssistanceItem } from "../../models/assistance";
import { StoreState, StoreStateDontLoad } from "../storeState";
import { AssistanceListTypes, IAssistanceListFilter } from "./types";

export const assistanceListFetchRequest = createAction<
  StoreStateDontLoad,
  AssistanceListTypes.ASSISTANCE_LIST_FETCH_REQUEST
>(AssistanceListTypes.ASSISTANCE_LIST_FETCH_REQUEST);

export const assistanceListFetchSuccess = createAction<
  {
    data: IAssistanceItem[];
    newCountState: StoreStateDontLoad;
    newCount: number;
  },
  AssistanceListTypes.ASSISTANCE_LIST_FETCH_SUCCESS
>(AssistanceListTypes.ASSISTANCE_LIST_FETCH_SUCCESS);

export const assistanceListFetchError = createAction<
  boolean,
  AssistanceListTypes.ASSISTANCE_LIST_FETCH_ERROR
>(AssistanceListTypes.ASSISTANCE_LIST_FETCH_ERROR);

export const assistanceListCountRequest = createAction<
  void,
  AssistanceListTypes.ASSISTANCE_LIST_COUNT_REQUEST
>(AssistanceListTypes.ASSISTANCE_LIST_COUNT_REQUEST);

export const assistanceListCountSuccess = createAction<
  number,
  AssistanceListTypes.ASSISTANCE_LIST_COUNT_SUCCESS
>(AssistanceListTypes.ASSISTANCE_LIST_COUNT_SUCCESS);

export const assistanceListCountError = createAction<
  boolean,
  AssistanceListTypes.ASSISTANCE_LIST_COUNT_ERROR
>(AssistanceListTypes.ASSISTANCE_LIST_COUNT_ERROR);

export const assistanceListChangeOrder = createAction<
  { orderBy: string; orderDesc: boolean },
  AssistanceListTypes.ASSISTANCE_LIST_CHANGE_ORDER
>(AssistanceListTypes.ASSISTANCE_LIST_CHANGE_ORDER);

export const assistanceListChangePage = createAction<
  number,
  AssistanceListTypes.ASSISTANCE_LIST_CHANGE_PAGE
>(AssistanceListTypes.ASSISTANCE_LIST_CHANGE_PAGE);

export const assistanceListChangeFilter = createAction<
  IAssistanceListFilter,
  AssistanceListTypes.ASSISTANCE_LIST_CHANGE_FILTER
>(AssistanceListTypes.ASSISTANCE_LIST_CHANGE_FILTER);

export const assistanceListReset = createAction<
  void,
  AssistanceListTypes.ASSISTANCE_LIST_RESET
>(AssistanceListTypes.ASSISTANCE_LIST_RESET);

export const assistanceListLoadAction = createAction<
  { newState: StoreState; newCountState: StoreStateDontLoad },
  AssistanceListTypes.ASSISTANCE_LIST_LOAD
>(AssistanceListTypes.ASSISTANCE_LIST_LOAD);
