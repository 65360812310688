import { Form, Formik } from "formik";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { IBasicSettings } from "../../models/basic";
import { IApplicationState } from "../../store";
import { StoreState } from "../../store/storeState";
import {
  getBasicSettings,
  selectBasicSettings,
  selectBasicSettingsState,
  updateBasicSettings,
} from "../../store/basicSettings";
import { ApiError, FormGroup } from "../../styles/form";
import Input from "../common/form/Input";
import Loader from "../common/Loader";
import * as Yup from "yup";
import basicApi from "../../api/basic";
import { RightType } from "../../models/auth";
import { hasRight } from "../../utils/rights";
import { selectIdentityRights } from "../../store/identity";
import { promiseToastSave } from "../../utils/toasts";
import SubmitForm from "../common/form/SubmitForm";
import validations from "../../utils/validations";
import SuggestionFormik from "../common/suggestion/SuggestionFormik";
import { handleLoadUserSuggestions } from "../../utils/suggestions";
import { errorSet } from "../../utils/error";

interface IBasicSettingsForm extends IBasicSettings {
  demandSystemUserId: number;
  demandSystemUserName: string;
}

interface IProps {
  basicSettingsState: StoreState;
  basicSettings: IBasicSettings | null;
  identityRights?: RightType[];
  getBasicSettings(): void;
  updateBasicSettings(newData: IBasicSettings): void;
}

const fields: Array<keyof IBasicSettings> = [
  "passwordMinLength",
  "passwordMinLowerCaseLetters",
  "passwordMinCapitalLetters",
  "passwordMinDigitsCount",
  "passwordExpirationInDays",
];

const AdminParameter: FC<IProps> = ({
  basicSettingsState,
  basicSettings,
  identityRights,
  getBasicSettings,
  updateBasicSettings,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    getBasicSettings();
    setLoading(false);
  }, [getBasicSettings]);

  const handleSubmit = async (data: IBasicSettingsForm) => {
    setError(null);
    try {
      await promiseToastSave(async () => {
        const data2 = {
          ...data,
          demandSystemUser: {
            id: data.demandSystemUserId,
            name: data.demandSystemUserName,
          },
        };

        await basicApi.updateBasicSettings(data2);
        updateBasicSettings(data2);
      });
    } catch (err) {
      errorSet(setError, err, t);
    }
  };

  if (loading || basicSettingsState === StoreState.Loading) {
    return <Loader />;
  }

  if (basicSettingsState === StoreState.Error) {
    return t("errors.unknown");
  }

  return (
    <Formik<IBasicSettingsForm>
      initialValues={{
        ...basicSettings!,
        demandSystemUserId: basicSettings!.demandSystemUser.id,
        demandSystemUserName: basicSettings!.demandSystemUser.name,
      }}
      validationSchema={Yup.object({
        passwordMinLength: validations.intRequiredMinMax(0, 99, t),
        passwordMinLowerCaseLetters: validations.intRequiredMinMax(0, 99, t),
        passwordMinCapitalLetters: validations.intRequiredMinMax(0, 99, t),
        passwordMinDigitsCount: validations.intRequiredMinMax(0, 99, t),
        passwordExpirationInDays: validations.intRequiredMinMax(0, 99999, t),
        demandSystemUserId: validations.idRequired(t),
      })}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      {({ errors, touched }) => (
        <Form>
          <h1>{t("admin.parameter.pageTitle")}</h1>
          <FormGroup>
            {fields.map((f) => (
              <Input
                key={f}
                name={f}
                type="number"
                label={t("admin.parameter." + f)}
                error={touched[f] && !!errors[f]}
              />
            ))}
            <SuggestionFormik
              nameId="demandSystemUserId"
              nameText="demandSystemUserName"
              label={t("admin.parameter.demandSystemUserId")}
              loadSuggestions={handleLoadUserSuggestions}
            />
          </FormGroup>
          {error && <ApiError>{error}</ApiError>}
          {hasRight(identityRights, [RightType.WriteBasicSettings]) && (
            <SubmitForm />
          )}
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    basicSettingsState: selectBasicSettingsState(state),
    basicSettings: selectBasicSettings(state),
    identityRights: selectIdentityRights(state),
  };
};

const mapDispachToProps = {
  getBasicSettings,
  updateBasicSettings,
};

export default connect(mapStateToProps, mapDispachToProps)(AdminParameter);
