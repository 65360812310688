import { ICareType } from "../../models/careType";
import { StoreState } from "../storeState";

export enum CareTypeTypes {
  CARE_TYPE_FETCH_REQUEST = "@@careType/FETCH_REQUEST",
  CARE_TYPE_FETCH_SUCCESS = "@@careType/FETCH_SUCCESS",
  CARE_TYPE_FETCH_ERROR = "@@careType/FETCH_ERROR",
}

export interface ICareTypeState {
  state: StoreState;
  data: ICareType | null;
}
