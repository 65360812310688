import { Formik } from "formik";
import { FC } from "react";
import { FormButton } from "../../styles/button";
import Input from "./form/Input";
import SubmitForm from "./form/SubmitForm";
import CustomModal from "./modal/CustomModal";
import { ModalContent } from "./modal/CustomModalStyles";
import { useTranslation } from "react-i18next";

interface PromiseModalWithTextProps {
  title: string;
  resolve: (value: string) => void;
  reject: () => void;
}

const PromiseModalWithText: FC<PromiseModalWithTextProps> = ({
  title,
  resolve,
  reject,
}) => {
  const { t } = useTranslation();

  const handleResolve = (note: string) => {
    resolve(note);
  };

  const handleReject = () => {
    reject();
  };

  return (
    <CustomModal isOpen close={handleReject} title={title}>
      <ModalContent>
        <Formik<{
          note: string;
        }>
          onSubmit={(data) => {
            handleResolve(data.note);
          }}
          initialValues={{
            note: "",
          }}
          key={title}
        >
          {() => (
            <>
              <Input name="note" type="text" />
              <div>
                <FormButton
                  ver="secondary"
                  type="button"
                  onClick={handleReject}
                >
                  {t("common.cancel")}
                </FormButton>
                <SubmitForm text={t("common.ok")} />
              </div>
            </>
          )}
        </Formik>
      </ModalContent>
    </CustomModal>
  );
};

export default PromiseModalWithText;
