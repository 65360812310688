import { AxiosRequestConfig, AxiosResponse } from "axios";
import { IItemIdName } from "../models/common";
import {
  IWorkflow,
  IWorkflowItem,
  IWorkflowQuestion,
  WorkflowType,
} from "../models/workflow";
import api from "../utils/api";

const getSuggestionList = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<IItemIdName[]>> =>
  api.get(`/api/Workflow/SuggestedList`, config);

const getList = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<IWorkflowItem[]>> =>
  api.get(`/api/Workflow/List`, config);

const getCount = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<number>> => api.get(`/api/Workflow/Count`, config);

const getWorkflow = async (
  workflowId: number
): Promise<AxiosResponse<IWorkflow>> => api.get(`/api/Workflow/${workflowId}`);

const updateWorkflow = async (
  workflow: IWorkflow
): Promise<AxiosResponse<IWorkflow>> =>
  api.put(`/api/Workflow/${workflow.id}`, workflow);

const createWorkflow = async (
  workflow: IWorkflow
): Promise<AxiosResponse<IWorkflow>> => api.post(`/api/Workflow`, workflow);

const getWorkflowUnsetTypes = async (): Promise<
  AxiosResponse<WorkflowType[]>
> => api.get(`/api/Workflow/UnsetTypes`);

const getRequireClient = async (
  workflowId: number
): Promise<AxiosResponse<boolean>> =>
  api.get(`/api/Workflow/${workflowId}/RequireClient`);

const getQuestions = async (
  workflowId: number,
  clientId?: number | null
): Promise<AxiosResponse<IWorkflowQuestion[]>> => {
  const params = new URLSearchParams();

  if (clientId) {
    params.append("clientId", clientId.toString());
  }

  return api.get(`/api/Workflow/${workflowId}/Questions`, {
    params,
  });
};

const workflowApi = {
  getSuggestionList,
  getList,
  getCount,
  getWorkflow,
  updateWorkflow,
  createWorkflow,
  getWorkflowUnsetTypes,
  getRequireClient,
  getQuestions,
};

export default workflowApi;
