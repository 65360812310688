import { FC, useMemo } from "react";
import {
  CalendarItemType,
  ICalendarCol,
  ICalendarItem,
} from "../../../models/calendar";
import Loader from "../Loader";
import CalendarDay from "./CalendarDay";
import {
  CalendarContent,
  CalendarGrid,
  CalendarGridWrapper,
  CalendarTime,
  CalendarTimeCell,
  CalendarTimesCol,
} from "./CalendarStyles";

interface IProps {
  labels: string[];
  cols: ICalendarCol[];
  multiMode?: boolean;
  loading: boolean;
  onClick(type: CalendarItemType, item: ICalendarItem): void;
}

const Calendar: FC<IProps> = ({
  labels,
  cols,
  multiMode,
  loading,
  onClick,
}) => {
  const timeCells = useMemo(
    () =>
      labels.map((label, i) => (
        <CalendarTimeCell key={i}>
          <CalendarTime>{label}</CalendarTime>
        </CalendarTimeCell>
      )),
    [labels]
  );

  const dataCells = useMemo(
    () =>
      cols.map((col, i) => (
        <CalendarDay
          key={i}
          col={col}
          multiMode={multiMode}
          onClick={onClick}
        />
      )),
    [cols, multiMode, onClick]
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <CalendarContent>
      <CalendarTimesCol>{timeCells}</CalendarTimesCol>
      <CalendarGridWrapper>
        <CalendarGrid>{dataCells}</CalendarGrid>
      </CalendarGridWrapper>
    </CalendarContent>
  );
};

export default Calendar;
