import {
  faCaretDown,
  faCaretUp,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../styles/colors";
import { getLinkIcon, getLinkName } from "../../utils/links";
import {
  SettingsDashboardLinkArrowContainer,
  SettingsDashboardLinkArrow,
  SettingsDashboardLinkContainer,
  SettingsDashboardLinkAddDelete,
  SettingsDashboardItemContainer,
} from "./SettingsDashboardLinksStyles";

export enum LinkMoveAction {
  Up,
  Down,
  Delete,
}

interface IProps {
  path: string;
  index: number;
  showUp: boolean;
  showDown: boolean;
  onAction(action: LinkMoveAction, index: number): void;
}

const SettingsDashboardLink: FC<IProps> = ({
  path,
  index,
  showUp,
  showDown,
  onAction,
}) => {
  const { t } = useTranslation();

  const handleOnClick = (action: LinkMoveAction) => {
    onAction(action, index);
  };

  return (
    <SettingsDashboardItemContainer>
      <SettingsDashboardLinkArrowContainer>
        <SettingsDashboardLinkArrow
          onClick={() => handleOnClick(LinkMoveAction.Up)}
          icon={faCaretUp}
          color={COLORS.textColor}
          className={showUp ? undefined : "hidden"}
        />
        <SettingsDashboardLinkArrow
          onClick={() => handleOnClick(LinkMoveAction.Down)}
          icon={faCaretDown}
          color={COLORS.textColor}
          className={showDown ? undefined : "hidden"}
        />
      </SettingsDashboardLinkArrowContainer>
      <SettingsDashboardLinkContainer>
        <FontAwesomeIcon icon={getLinkIcon(path)} color={"white"} />{" "}
        {getLinkName(t, path)}
        <SettingsDashboardLinkAddDelete
          onClick={() => handleOnClick(LinkMoveAction.Delete)}
          icon={faTimes}
          // todo color
          color={"white"}
        />
      </SettingsDashboardLinkContainer>
    </SettingsDashboardItemContainer>
  );
};

export default SettingsDashboardLink;
