import { IItemIdName } from "../../models/common";
import { StoreState } from "../storeState";

export enum TemplateFolderTypes {
  TEMPLATE_FOLDER_FETCH_REQUEST = "@@templateFolder/FETCH_REQUEST",
  TEMPLATE_FOLDER_FETCH_SUCCESS = "@@templateFolder/FETCH_SUCCESS",
  TEMPLATE_FOLDER_FETCH_ERROR = "@@templateFolder/FETCH_ERROR",
}

export interface ITemplateFolderState {
  state: StoreState;
  data: IItemIdName[];
}
