import { Form, Formik } from "formik";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { IWorkflowTemplate } from "../../models/workflowTemplate";
import { IApplicationState } from "../../store";
import { StoreState } from "../../store/storeState";
import {
  getDefaultWorkflowTemplate,
  getWorkflowTemplate,
  selectWorkflowTemplate,
  selectWorkflowTemplateState,
} from "../../store/workflowTemplate";
import { FormButton } from "../../styles/button";
import { ApiError, FormGroup } from "../../styles/form";
import Input from "../common/form/Input";
import Loader from "../common/Loader";
import * as Yup from "yup";
import { workflowTemplateListLoad } from "../../store/workflowTemplateList";
import workflowTemplateApi from "../../api/workflowTemplate";
import { SpaceBetweenButtons } from "../../styles/spaces";
import validations from "../../utils/validations";
import { RightType } from "../../models/auth";
import { hasRight } from "../../utils/rights";
import { selectIdentityRights } from "../../store/identity";
import SuggestionFormik from "../common/suggestion/SuggestionFormik";
import { DemandWorkflowNotificationFormContainer } from "../common/DemandWorkflowStyles";
import {
  handleLoadGroupSuggestions,
  handleLoadNotificationSuggestions,
  handleLoadUserSuggestions,
} from "../../utils/suggestions";
import { promiseToastSave } from "../../utils/toasts";
import SubmitForm from "../common/form/SubmitForm";
import { errorSet } from "../../utils/error";
import Page from "../layout/Page";

interface IProps {
  workflowTemplateState: StoreState;
  workflowTemplate: IWorkflowTemplate | null;
  identityRights?: RightType[];
  getWorkflowTemplate(workflowId: number): void;
  getDefaultWorkflowTemplate(): void;
  workflowTemplateListLoad(reload: boolean): void;
}

const ModuleWorkflowTemplateDetail: FC<IProps> = ({
  workflowTemplateState,
  workflowTemplate,
  identityRights,
  getWorkflowTemplate,
  getDefaultWorkflowTemplate,
  workflowTemplateListLoad,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { workflowTemplateId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (workflowTemplateId === "new") {
      getDefaultWorkflowTemplate();
    } else {
      getWorkflowTemplate(parseInt(workflowTemplateId!));
    }
    setLoading(false);
  }, [workflowTemplateId, getWorkflowTemplate, getDefaultWorkflowTemplate]);

  const handleSubmit = async (data: IWorkflowTemplate) => {
    setError(null);
    try {
      await promiseToastSave(async () => {
        const data2 = {
          ...data,
          assignedUserId: data.assignedUserId ? data.assignedUserId : null,
          assignedGroupId: data.assignedGroupId ? data.assignedGroupId : null,

          notificationForCreatorOnCreateId:
            data.notificationForCreatorOnCreateId
              ? data.notificationForCreatorOnCreateId
              : null,
          notificationForCreatorOnFinishId:
            data.notificationForCreatorOnFinishId
              ? data.notificationForCreatorOnFinishId
              : null,
          notificationForAssignedUserOnCreateId:
            data.notificationForAssignedUserOnCreateId
              ? data.notificationForAssignedUserOnCreateId
              : null,
          notificationForAssignedUserOnFinishId:
            data.notificationForAssignedUserOnFinishId
              ? data.notificationForAssignedUserOnFinishId
              : null,
          notificationForAssignedGroupOnCreateId:
            data.notificationForAssignedGroupOnCreateId
              ? data.notificationForAssignedGroupOnCreateId
              : null,
          notificationForAssignedGroupOnFinishId:
            data.notificationForAssignedGroupOnFinishId
              ? data.notificationForAssignedGroupOnFinishId
              : null,
          notificationForClientOnCreateId: data.notificationForClientOnCreateId
            ? data.notificationForClientOnCreateId
            : null,
          notificationForClientOnFinishId: data.notificationForClientOnFinishId
            ? data.notificationForClientOnFinishId
            : null,
        };

        if (workflowTemplateId === "new") {
          await workflowTemplateApi.createWorkflowTemplate(data2);
        } else {
          await workflowTemplateApi.updateWorkflowTemplate(data2);
        }

        workflowTemplateListLoad(true);
        navigate("/module/workflow-template");
      });
    } catch (err) {
      errorSet(setError, err, t);
    }
  };

  const handleCancel = () => {
    navigate("/module/workflow-template");
  };

  if (loading || workflowTemplateState === StoreState.Loading) {
    return <Loader />;
  }

  if (workflowTemplateState === StoreState.Error) {
    return t("errors.unknown");
  }

  return (
    <Page>
      <Formik<IWorkflowTemplate>
        initialValues={{
          ...workflowTemplate!,
          assignedUserId: workflowTemplate!.assignedUserId ?? 0,
          assignedGroupId: workflowTemplate!.assignedGroupId ?? 0,
        }}
        validationSchema={Yup.object().shape(
          {
            name: validations.stringRequired(t),
            requirementTermInDays: validations.intRequiredMinMax(1, 999, t),

            assignedUserId: Yup.number().when("assignedGroupId", {
              is: (assignedGroupId: any) => !assignedGroupId,
              then: Yup.number()
                .required(t("module.workflowTemplate.anyOfValuesRequired"))
                .moreThan(0, t("module.workflowTemplate.anyOfValuesRequired")),
            }),
            assignedGroupId: Yup.number().when("assignedUserId", {
              is: (assignedUserId: any) => !assignedUserId,
              then: Yup.number()
                .required(t("module.workflowTemplate.anyOfValuesRequired"))
                .moreThan(0, t("module.workflowTemplate.anyOfValuesRequired")),
            }),
          },
          [["assignedUserId", "assignedGroupId"]]
        )}
        validateOnMount={true}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form>
            <h1>{t("module.workflowTemplate.detailTitle")}</h1>
            <FormGroup>
              <Input
                key="isActive"
                name="isActive"
                type="checkbox"
                label={t(`module.workflowTemplate.isActive`)}
                inputWidth="1.5rem"
                inputHeight="1.5rem"
              />
              <Input
                key="name"
                name="name"
                label={t("module.workflowTemplate.name")}
                error={touched.name && !!errors.name}
                maxLength={512}
              />

              <SuggestionFormik
                nameId="assignedUserId"
                nameText="assignedUserName"
                label={t("module.workflowTemplate.assignedUser")}
                loadSuggestions={handleLoadUserSuggestions}
              />
              <Input
                key="canChangeAssignedUser"
                name="canChangeAssignedUser"
                type="checkbox"
                label={t(`module.workflowTemplate.canChangeAssignedUser`)}
                inputWidth="1.5rem"
                inputHeight="1.5rem"
              />
              <SuggestionFormik
                nameId="assignedGroupId"
                nameText="assignedGroupName"
                label={t("module.workflowTemplate.assignedGroup")}
                loadSuggestions={handleLoadGroupSuggestions}
              />
              <Input
                key="canChangeAssignedGroup"
                name="canChangeAssignedGroup"
                type="checkbox"
                label={t(`module.workflowTemplate.canChangeAssignedGroup`)}
                inputWidth="1.5rem"
                inputHeight="1.5rem"
              />
              <Input
                key="requirementTermInDays"
                name="requirementTermInDays"
                type="number"
                label={t(`module.workflowTemplate.requirementTermInDays`)}
                error={
                  touched.requirementTermInDays &&
                  !!errors.requirementTermInDays
                }
              />

              <DemandWorkflowNotificationFormContainer>
                <div>{t("module.workflowTemplate.sendNotification")}</div>
                <div>{t("module.workflowTemplate.notificationOnCreate")}</div>
                <div>{t("module.workflowTemplate.notificationOnFinish")}</div>

                <div>{t("module.workflowTemplate.notificationForCreator")}</div>
                <SuggestionFormik
                  nameId="notificationForCreatorOnCreateId"
                  nameText="notificationForCreatorOnCreateName"
                  loadSuggestions={handleLoadNotificationSuggestions}
                />
                <SuggestionFormik
                  nameId="notificationForCreatorOnFinishId"
                  nameText="notificationForCreatorOnFinishName"
                  loadSuggestions={handleLoadNotificationSuggestions}
                />

                <div>
                  {t("module.workflowTemplate.notificationForAssignedUser")}
                </div>
                <SuggestionFormik
                  nameId="notificationForAssignedUserOnCreateId"
                  nameText="notificationForAssignedUserOnCreateName"
                  loadSuggestions={handleLoadNotificationSuggestions}
                />
                <SuggestionFormik
                  nameId="notificationForAssignedUserOnFinishId"
                  nameText="notificationForAssignedUserOnFinishName"
                  loadSuggestions={handleLoadNotificationSuggestions}
                />

                <div>
                  {t("module.workflowTemplate.notificationForAssignedGroup")}
                </div>
                <SuggestionFormik
                  nameId="notificationForAssignedGroupOnCreateId"
                  nameText="notificationForAssignedGroupOnCreateName"
                  loadSuggestions={handleLoadNotificationSuggestions}
                />
                <SuggestionFormik
                  nameId="notificationForAssignedGroupOnFinishId"
                  nameText="notificationForAssignedGroupOnFinishName"
                  loadSuggestions={handleLoadNotificationSuggestions}
                />

                <div>{t("module.workflowTemplate.notificationForClient")}</div>
                <SuggestionFormik
                  nameId="notificationForClientOnCreateId"
                  nameText="notificationForClientOnCreateName"
                  loadSuggestions={handleLoadNotificationSuggestions}
                />
                <SuggestionFormik
                  nameId="notificationForClientOnFinishId"
                  nameText="notificationForClientOnFinishName"
                  loadSuggestions={handleLoadNotificationSuggestions}
                />
              </DemandWorkflowNotificationFormContainer>
            </FormGroup>
            {error && <ApiError>{error}</ApiError>}
            <FormButton
              ver="secondary"
              disabled={isSubmitting}
              onClick={handleCancel}
            >
              {t("common.back")}
            </FormButton>
            {hasRight(identityRights, [RightType.WriteWorkflowTemplate]) && (
              <>
                <SpaceBetweenButtons />
                <SubmitForm />
              </>
            )}
          </Form>
        )}
      </Formik>
    </Page>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    workflowTemplateState: selectWorkflowTemplateState(state),
    workflowTemplate: selectWorkflowTemplate(state),
    identityRights: selectIdentityRights(state),
  };
};

const mapDispachToProps = {
  getWorkflowTemplate,
  getDefaultWorkflowTemplate,
  workflowTemplateListLoad,
};

export default connect(
  mapStateToProps,
  mapDispachToProps
)(ModuleWorkflowTemplateDetail);
