import { IAssistanceAddressItem } from "../../models/assistance";
import { IFilterType } from "../filterType";
import { IListType } from "../listType";

export enum AssistanceAddressListTypes {
  ASSISTANCE_ADDRESS_LIST_FETCH_REQUEST = "@@assistanceAddressList/FETCH_REQUEST",
  ASSISTANCE_ADDRESS_LIST_FETCH_SUCCESS = "@@assistanceAddressList/FETCH_SUCCESS",
  ASSISTANCE_ADDRESS_LIST_FETCH_ERROR = "@@assistanceAddressList/FETCH_ERROR",
  ASSISTANCE_ADDRESS_LIST_COUNT_REQUEST = "@@assistanceAddressList/COUNT_REQUEST",
  ASSISTANCE_ADDRESS_LIST_COUNT_SUCCESS = "@@assistanceAddressList/COUNT_SUCCESS",
  ASSISTANCE_ADDRESS_LIST_COUNT_ERROR = "@@assistanceAddressList/COUNT_ERROR",
  ASSISTANCE_ADDRESS_LIST_CHANGE_ORDER = "@@assistanceAddressList/CHANGE_ORDER",
  ASSISTANCE_ADDRESS_LIST_CHANGE_PAGE = "@@assistanceAddressList/CHANGE_PAGE",
  ASSISTANCE_ADDRESS_LIST_CHANGE_FILTER = "@@assistanceAddressList/CHANGE_FILTER",
  ASSISTANCE_ADDRESS_LIST_RESET = "@@assistanceAddressList/RESET",
  ASSISTANCE_ADDRESS_LIST_LOAD = "@@assistanceAddressList/LOAD",
}

export interface IAssistanceAddressListState
  extends IListType<IAssistanceAddressItem, IFilterType> {}
