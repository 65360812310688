import { createAction } from "@reduxjs/toolkit";
import { WorkflowType } from "../../models/workflow";
import { WorkflowUnsetTypesTypes } from "./types";

export const workflowUnsetTypesFetchRequest = createAction<
  void,
  WorkflowUnsetTypesTypes.WORKFLOW_UNSET_TYPES_FETCH_REQUEST
>(WorkflowUnsetTypesTypes.WORKFLOW_UNSET_TYPES_FETCH_REQUEST);

export const workflowUnsetTypesFetchSuccess = createAction<
  WorkflowType[],
  WorkflowUnsetTypesTypes.WORKFLOW_UNSET_TYPES_FETCH_SUCCESS
>(WorkflowUnsetTypesTypes.WORKFLOW_UNSET_TYPES_FETCH_SUCCESS);

export const workflowUnsetTypesFetchError = createAction<
  void,
  WorkflowUnsetTypesTypes.WORKFLOW_UNSET_TYPES_FETCH_ERROR
>(WorkflowUnsetTypesTypes.WORKFLOW_UNSET_TYPES_FETCH_ERROR);

export const workflowUnsetTypesReset = createAction<
  void,
  WorkflowUnsetTypesTypes.WORKFLOW_UNSET_TYPES_RESET
>(WorkflowUnsetTypesTypes.WORKFLOW_UNSET_TYPES_RESET);
