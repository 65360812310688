import { AppThunkAction, AppThunkActionAsync } from "..";
import * as actions from "./actions";
import userApi from "../../api/user";

export function getUser(userId: number): AppThunkActionAsync {
  return async (dispatch) => {
    dispatch(actions.userFetchRequest());
    try {
      const user = await userApi.getUser(userId);
      dispatch(actions.userFetchSuccess(user.data));
    } catch {
      dispatch(actions.userFetchError());
    }
  };
}

export function getDefaultUser(): AppThunkAction {
  return (dispatch) => {
    const data = {
      id: 0,
      roleId: 0,
      roleName: "",
      assistanceId: 0,
      assistanceName: "",
      created: new Date(),
      isActive: true,
      login: "",
      title: "",
      firstName: "",
      lastName: "",
      degree: "",
      mobilePhone: "",
      phone: "",
      email: "",

      groups: [],
    };

    dispatch(actions.userFetchSuccess(data));
  };
}
