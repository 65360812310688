import { AppThunkAction, AppThunkActionAsync } from "..";
import * as actions from "./actions";
import careTypeApi from "../../api/careType";
import { ICareTypeListFilter, ICareTypeListState, selectCareTypeList } from ".";
import { CancelToken } from "axios";
import {
  listEffectCountFilter,
  listEffectGetFilter,
  listEffectLoad,
  listEffectReload,
  listEffectsCountCheck,
  listEffectsGetCheck,
  listEffectsGetCount,
  listEffectsGetNew,
  listEffectsGetParams,
} from "../listEffect";

export function getCareTypeList(cancelToken: CancelToken): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectCareTypeList(getState());
    if (listEffectsGetCheck(state.state)) {
      return;
    }

    let newState = listEffectsGetNew(state);
    dispatch(actions.careTypeListFetchRequest(newState.newCountState));
    try {
      const params = listEffectsGetParams(state, newState.take);
      listEffectGetFilter(params, state.filter);
      addCareTypeParams(params, state);

      const careTypes = await careTypeApi.getList({ params, cancelToken });
      cancelToken.throwIfRequested();
      newState = listEffectsGetCount(newState, careTypes);

      dispatch(
        actions.careTypeListFetchSuccess({
          data: careTypes.data,
          newCountState: newState.newCountState,
          newCount: newState.newCount,
        })
      );
    } catch {
      dispatch(
        actions.careTypeListFetchError(cancelToken.reason !== undefined)
      );
    }
  };
}

export function getCareTypeListCount(
  cancelToken: CancelToken
): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectCareTypeList(getState());
    if (listEffectsCountCheck(state.countState)) {
      return;
    }

    dispatch(actions.careTypeListCountRequest());
    try {
      const params = listEffectCountFilter(state.filter);
      addCareTypeParams(params, state);

      const count = await careTypeApi.getCount({ params, cancelToken });
      cancelToken.throwIfRequested();

      dispatch(actions.careTypeListCountSuccess(count.data));
    } catch {
      dispatch(
        actions.careTypeListCountError(cancelToken.reason !== undefined)
      );
    }
  };
}

export function changeCareTypeListOrder(
  orderBy: string,
  orderDesc: boolean
): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.careTypeListChangeOrder({ orderBy, orderDesc }));
  };
}

export function changeCareTypeListPage(page: number): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.careTypeListChangePage(page));
  };
}

export function changeCareTypeListFilter(
  filter: ICareTypeListFilter
): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.careTypeListChangeFilter(filter));
  };
}

export function openCareTypeListFilter(): AppThunkAction {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(
      actions.careTypeListChangeFilter({
        ...state.careTypeList.filter!,
        isOpen: true,
      })
    );
  };
}

export function careTypeListLoad(reload: boolean): AppThunkAction {
  return async (dispatch, getState) => {
    const state = selectCareTypeList(getState());
    reload = listEffectReload(reload, state);
    if (reload) {
      dispatch(actions.careTypeListReset());
    } else {
      const newState = listEffectLoad(state);
      dispatch(
        actions.careTypeListLoadAction({
          newState: newState.newState,
          newCountState: newState.newCountState,
        })
      );
    }
  };
}

export function addCareTypeParams(
  params: URLSearchParams,
  state: ICareTypeListState
) {
  if (state.filter!.assistanceCategoryListingId) {
    params.append(
      "AssistanceCategoryListingId",
      state.filter!.assistanceCategoryListingId.toString()
    );
  }
}
