import { FC, useEffect } from "react";

interface IProps {
  url: string;
}

export const RoutesRedirect: FC<IProps> = ({ url }) => {
  useEffect(() => {
    window.location.href = url;
  }, [url]);

  return null;
};
