import { AxiosResponse } from "axios";
import {
  IChangePasswordData,
  IChangePasswordResult,
  IForgottenPasswordData,
  IIdentity,
  ILoginData,
  ILoginResponse,
  IResetPasswordData,
  ITwoFactorData,
} from "../models/auth";
import { IDiagramSettingsDto } from "../models/diagram";
import api from "../utils/api";

const login = async (
  data: ILoginData
): Promise<AxiosResponse<ILoginResponse>> => api.post(`/api/Auth/Login`, data);

const twoFactor = async (
  data: ITwoFactorData
): Promise<AxiosResponse<ILoginResponse>> =>
  api.post(`/api/Auth/TwoFactor`, data);

const identity = async (): Promise<AxiosResponse<IIdentity>> =>
  api.get(`/api/Auth/Me/Identity`);

const sendPasswordResetLink = (
  data: IForgottenPasswordData
): Promise<AxiosResponse<boolean>> =>
  api.post(`/api/Auth/ForgottenPassword`, data);

const sendPublicPasswordResetLink = (
  data: IForgottenPasswordData
): Promise<AxiosResponse<boolean>> =>
  api.post(`/api/Auth/Public/ForgottenPassword`, data);

const resetPasswordCheck = (): Promise<AxiosResponse<ILoginResponse>> =>
  api.post(`/api/Auth/ForgottenPasswordCheck`);

const resetPassword = (
  data: IResetPasswordData
): Promise<AxiosResponse<ILoginResponse>> =>
  api.post(`/api/Auth/ForgottenPasswordChange`, data);

const changePassword = (
  data: IChangePasswordData
): Promise<AxiosResponse<IChangePasswordResult>> =>
  api.post(`/api/Auth/ChangePassword`, data);

const renewToken = (): Promise<AxiosResponse<string>> =>
  api.get(`/api/Auth/TokenRenew`);

const getDiagramSettings = async (): Promise<
  AxiosResponse<IDiagramSettingsDto>
> => api.get(`/api/Auth/Me/DiagramSettings`);

const setDiagramSettings = async (
  data: IDiagramSettingsDto
): Promise<AxiosResponse<boolean>> =>
  api.put(`/api/Auth/Me/DiagramSettings`, data);

const authApi = {
  login,
  twoFactor,
  identity,
  sendPasswordResetLink,
  sendPublicPasswordResetLink,
  resetPasswordCheck,
  resetPassword,
  changePassword,
  renewToken,
  getDiagramSettings,
  setDiagramSettings,
};

export default authApi;
