import { CancelToken } from "axios";
import { FC, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { IApplicationState } from "../../store";
import { IFilterType } from "../../store/filterType";
import FilterPanel from "../common/filter/FilterPanel";
import Grid from "../common/grid/Grid";
import { GridIconAdd, GridIconEdit } from "../common/grid/GridIcons";
import { TableCol } from "../common/grid/TableCol";
import { RightType } from "../../models/auth";
import { hasClientWriteRight } from "../../utils/rights";
import { selectIdentityRights } from "../../store/identity";
import ModuleDocumentFolderModal from "../module/ModuleDocumentFolderModal";
import { selectClientId } from "../../store/client";
import {
  changeClientFolderListFilter,
  changeClientFolderListOrder,
  changeClientFolderListPage,
  clientFolderListLoad,
  getClientFolderList,
  getClientFolderListCount,
  IClientFolderListState,
  selectClientFolderList,
} from "../../store/clientFolderList";
import clientApi from "../../api/client";
import { IFolderItem } from "../../models/document";
import { DivTextRight } from "../../styles/align";
import { ModalYesNoFunction } from "../common/modal/ModalFunctions";
import { errorToast, promiseToast } from "../../utils/toasts";
import { clientDocumentListLoad } from "../../store/clientDocumentList";

const defaultFolder: IFolderItem = {
  id: 0,
  name: "",
  users: [],
  groups: [],
  roles: [],
  assistances: [],
  isClientVisible: false,
};

interface IProps {
  prov: IClientFolderListState;
  clientId: number | undefined;
  identityRights?: RightType[];
  getClientFolderList(cancelToken: CancelToken): void;
  getClientFolderListCount(cancelToken: CancelToken): void;
  changeClientFolderListOrder(orderBy: string, orderDesc: boolean): void;
  changeClientFolderListPage(page: number): void;
  changeClientFolderListFilter(filter: IFilterType): void;
  clientFolderListLoad(reload: boolean): void;
  clientDocumentListLoad(reload: boolean): void;
}

const ClientDocumentFolder: FC<IProps> = ({
  prov,
  clientId,
  identityRights,
  getClientFolderList,
  getClientFolderListCount,
  changeClientFolderListOrder,
  changeClientFolderListPage,
  changeClientFolderListFilter,
  clientFolderListLoad,
  clientDocumentListLoad,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { folderId } = useParams();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isAdding, setIsAdding] = useState(false);
  const [openedFolder, setOpenedFolder] = useState<IFolderItem>(defaultFolder);

  useEffect(() => {
    clientFolderListLoad(false);
  }, [clientFolderListLoad]);

  const handleEdit = (item: IFolderItem) => {
    setIsAdding(false);
    setOpenedFolder(item);
    setIsOpen(true);
  };

  const handleAdd = () => {
    setIsAdding(true);
    setOpenedFolder(defaultFolder);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleConfirm = () => {
    clientFolderListLoad(true);
    setIsOpen(false);
  };

  const handleRenderData = (item: IFolderItem): ReactElement => {
    return (
      <>
        <TableCol>{item.name}</TableCol>
        <TableCol>
          {hasClientWriteRight(identityRights, [
            RightType.WriteClientDocuments,
          ]) && <GridIconEdit onClick={() => handleEdit(item)} />}
        </TableCol>
      </>
    );
  };

  const handleRowClick = (item: IFolderItem) => {
    navigate(`/client/${clientId}/document/${item.id}`);
  };
  // TODO: uncomment in CM02_BPOZ_27
  // const handleDragStart = (
  //   e: React.DragEvent<HTMLTableRowElement>,
  //   item: IFolderItem
  // ) => {
  //   e.dataTransfer.setData(
  //     "item",
  //     JSON.stringify({ id: item.id, name: item.name, isFolder: true })
  //   );
  // };

  // TODO: uncomment in CM02_BPOZ_27
  // const handleBinDrop = async (item: {
  //   id: number;
  //   name: string;
  //   isFolder: boolean;
  // }) => {
  //   if (!item.isFolder) return;
  //   const res = await ModalYesNoFunction(
  //     t("module.document.folder.deleteFolderTitle"),
  //     t("module.document.folder.deleteFolder", { name: item.name })
  //   );
  //   if (!res) return;
  // };

  const handleDropDocument = async (item: any, folder: IFolderItem) => {
    const res = await ModalYesNoFunction(
      t("module.document.moveDocumentTitle"),
      t("module.document.moveDocument", {
        documentName: item.name,
        folderName: folder.name,
      })
    );
    if (res) {
      await promiseToast(
        async () => {
          try {
            await clientApi.clientDocumentMove(item.id, folder.id);
            clientDocumentListLoad(true);
          } catch (e) {
            errorToast(t("errors.unknown"));
          }
        },
        t("pending.move"),
        t("success.move"),
        t("common.cancel")
      );
    }
  };

  return (
    <>
      <ModuleDocumentFolderModal
        folder={openedFolder}
        parentId={folderId!}
        isAdding={isAdding}
        isOpen={isOpen}
        showIsClientVisible={true}
        createFolder={(parentId, data) =>
          clientApi.createFolder(clientId!, parentId, data)
        }
        updateFolder={(folderId, data) =>
          clientApi.updateFolder(clientId!, folderId, data)
        }
        close={handleClose}
        confirm={handleConfirm}
      />

      <FilterPanel
        title={t("module.document.folder.pageTitle")}
        name="clientDocumentFolder"
        filter={prov.filter!}
        changeFilter={changeClientFolderListFilter}
      />

      <Grid<IFolderItem>
        headers={[
          { captionStr: "module.document.folder.name" },
          {
            captionEl: hasClientWriteRight(identityRights, [
              RightType.WriteClientDocuments,
            ]) ? (
              <DivTextRight>
                <GridIconAdd onClick={() => handleAdd()} />
                {/* TODO: uncomment in CM02_BPOZ_27 */}
                {/* <GridIconSpace />
                <GridIconBin
                  onDrop={handleBinDrop}
                  title={t("gridIcons.deleteFolder")}
                /> */}
              </DivTextRight>
            ) : undefined,
          },
        ]}
        renderData={handleRenderData}
        getData={getClientFolderList}
        getCount={getClientFolderListCount}
        changeOrder={changeClientFolderListOrder}
        changePage={changeClientFolderListPage}
        onRowClick={handleRowClick}
        prov={prov}
        droppable
        onDrop={handleDropDocument}
        // TODO: uncomment in CM02_BPOZ_27
        // draggable
        // onDrag={handleDragStart}
      />
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    prov: selectClientFolderList(state),
    clientId: selectClientId(state),
    identityRights: selectIdentityRights(state),
  };
};

const mapDispachToProps = {
  getClientFolderList,
  getClientFolderListCount,
  changeClientFolderListOrder,
  changeClientFolderListPage,
  changeClientFolderListFilter,
  clientFolderListLoad,
  clientDocumentListLoad,
};

export default connect(
  mapStateToProps,
  mapDispachToProps
)(ClientDocumentFolder);
