import { Fragment } from "react";
import {
  IWorkflowQuestion,
  WorkflowDefinitionItemTopMode,
  WorkflowDefinitionItemType,
  WorkflowSelectedQuestions,
} from "../../models/workflow";
import {
  DemandQuestionContainer,
  DemandQuestionOptionContainer,
  DemandQuestionOptionInput,
  DemandQuestionOptionLabel,
  DemandQuestionTitle,
} from "./DemandQuestionsStyles";

export const renderQuestions = (
  questions: IWorkflowQuestion[],
  selectedQuestions: WorkflowSelectedQuestions,
  setSelectedQuestions: (value: WorkflowSelectedQuestions) => void,
  parentId: number | null
) => {
  const renderedIds: number[] = [];
  const res = renderQuestionsInternal1(
    renderedIds,
    questions,
    selectedQuestions,
    setSelectedQuestions,
    parentId,
    parentId
  );
  return res;
};

const renderQuestionsInternal1 = (
  renderedIds: number[],
  questions: IWorkflowQuestion[],
  selectedQuestions: WorkflowSelectedQuestions,
  setSelectedQuestions: (value: WorkflowSelectedQuestions) => void,
  parentId: number | null,
  totalParentId: number | null
) => {
  const items = questions
    .filter((x) => x.parentId.includes(parentId))
    .filter((x) => !renderedIds.includes(x.id))
    .sort((a, b) => a.parallerOrder - b.parallerOrder);

  renderedIds.push(...items.map((x) => x.id));

  const res = items.map((x) => {
    if (!checkParentAllPaths(x, questions, selectedQuestions, totalParentId)) {
      return null;
    }

    return (
      <Fragment key={x.id}>
        {x.type === WorkflowDefinitionItemType.Question && (
          <DemandQuestionContainer key={x.id}>
            <DemandQuestionTitle>{x.text}</DemandQuestionTitle>
            {renderQuestionItems(
              questions,
              selectedQuestions,
              setSelectedQuestions,
              x.id
            )}
          </DemandQuestionContainer>
        )}
        {renderQuestionsInternal2(
          renderedIds,
          questions,
          selectedQuestions,
          setSelectedQuestions,
          x.id,
          totalParentId,
          x.type === WorkflowDefinitionItemType.Auto
        )}
      </Fragment>
    );
  });
  return res;
};

const renderQuestionItems = (
  questions: IWorkflowQuestion[],
  selectedQuestions: WorkflowSelectedQuestions,
  setSelectedQuestions: (value: WorkflowSelectedQuestions) => void,
  parentId: number
) => {
  const items = questions
    .filter((x) => x.parentId.includes(parentId))
    .sort((a, b) => a.parallerOrder - b.parallerOrder);

  const handleValueChange = (item: IWorkflowQuestion) => {
    setSelectedQuestions({ ...selectedQuestions, [parentId]: item.id });
  };

  const res = items.map((x) => (
    <DemandQuestionOptionContainer key={x.id}>
      <DemandQuestionOptionInput
        type="radio"
        id={`item_${x.id}`}
        name={`parent_${parentId}`}
        value={x.id}
        checked={selectedQuestions[parentId] === x.id}
        onChange={() => handleValueChange(x)}
      />
      <DemandQuestionOptionLabel htmlFor={`item_${x.id}`}>
        {x.text}
      </DemandQuestionOptionLabel>
    </DemandQuestionOptionContainer>
  ));
  return res;
};

const renderQuestionsInternal2 = (
  renderedIds: number[],
  questions: IWorkflowQuestion[],
  selectedQuestions: WorkflowSelectedQuestions,
  setSelectedQuestions: (value: WorkflowSelectedQuestions) => void,
  parentId: number,
  totalParentId: number | null,
  skipCheckSelected: boolean
) => {
  const items = questions
    .filter((x) => x.parentId.includes(parentId))
    .filter((x) => skipCheckSelected || selectedQuestions[parentId] === x.id)
    .sort((a, b) => a.parallerOrder - b.parallerOrder);

  const res = items.map((x) =>
    renderQuestionsInternal1(
      renderedIds,
      questions,
      selectedQuestions,
      setSelectedQuestions,
      x.id,
      totalParentId
    )
  );
  return res;
};

const checkParentAllPaths = (
  item: IWorkflowQuestion,
  questions: IWorkflowQuestion[],
  selectedQuestions: WorkflowSelectedQuestions,
  totalParentId: number | null
) => {
  if (item.topPortMode === WorkflowDefinitionItemTopMode.AtLeastOne) {
    return true;
  }

  const res = checkParentAllPaths2(
    item,
    questions,
    selectedQuestions,
    totalParentId
  );
  return res;
};

const checkParentAllPaths2 = (
  item: IWorkflowQuestion,
  questions: IWorkflowQuestion[],
  selectedQuestions: WorkflowSelectedQuestions,
  totalParentId: number | null
) => {
  if (
    item.parentId.length === 0 ||
    (item.parentId.length === 1 && item.parentId[0] === totalParentId)
  ) {
    return true;
  }

  for (const parentId of item.parentId) {
    if (parentId === totalParentId) {
      if (item.topPortMode === WorkflowDefinitionItemTopMode.AtLeastOne) {
        return true;
      }
    }

    //Parent is port.
    const parentPort = questions.find((x) => x.id === parentId);
    if (!parentPort) {
      continue;
    }

    const parentNode = questions.find((x) => x.id === parentPort.parentId[0]);
    if (!parentNode) {
      continue;
    }

    if (parentNode.type === WorkflowDefinitionItemType.Question) {
      const exists = selectedQuestions[parentNode.id] === parentPort.id;
      if (!exists) {
        if (item.topPortMode === WorkflowDefinitionItemTopMode.AllPaths) {
          return false;
        }

        continue;
      }
    }

    const resParent = checkParentAllPaths2(
      parentNode,
      questions,
      selectedQuestions,
      totalParentId
    );
    if (resParent) {
      if (item.topPortMode === WorkflowDefinitionItemTopMode.AtLeastOne) {
        return true;
      }
    } else {
      if (item.topPortMode === WorkflowDefinitionItemTopMode.AllPaths) {
        return false;
      }
    }
  }

  if (item.topPortMode === WorkflowDefinitionItemTopMode.AtLeastOne) {
    return false;
  } else {
    return true;
  }
};

export const isAllQuestionsSelected = (
  questions: IWorkflowQuestion[],
  selectedQuestions: WorkflowSelectedQuestions,
  parentId: number | null
) => {
  const answers: number[] = [];
  const res = isAllQuestionsSelectedInternal(
    answers,
    questions,
    selectedQuestions,
    parentId,
    parentId
  );
  return res;
};

const isAllQuestionsSelectedInternal = (
  answers: number[],
  questions: IWorkflowQuestion[],
  selectedQuestions: WorkflowSelectedQuestions,
  parentId: number | null,
  totalParentId: number | null
) => {
  const items = questions.filter((x) => x.parentId.includes(parentId));

  for (const item of items) {
    if (
      !checkParentAllPaths(item, questions, selectedQuestions, totalParentId)
    ) {
      continue;
    }

    let answer: number | undefined;
    if (item.type === WorkflowDefinitionItemType.Auto) {
      answer = questions.find((x) => x.parentId.includes(item.id))?.id;
    } else {
      answer = selectedQuestions[item.id];
    }

    const exists = !!answer;
    if (!exists) {
      return false;
    }

    if (answers.includes(answer!)) {
      continue;
    }

    answers.push(answer!);

    const items2 = questions
      .filter((x) => x.parentId.includes(item.id))
      .filter((x) => answer === x.id);

    for (const item2 of items2) {
      const exists2 = isAllQuestionsSelectedInternal(
        answers,
        questions,
        selectedQuestions,
        item2.id,
        totalParentId
      );
      if (!exists2) {
        return false;
      }
    }
  }

  return true;
};

export const getSelectedAnswers = (
  answers: number[],
  questions: IWorkflowQuestion[],
  selectedQuestions: WorkflowSelectedQuestions,
  parentId: number | null
) => {
  const items = questions.filter((x) => x.parentId.includes(parentId));

  for (const item of items) {
    let answer: number | undefined;
    if (item.type === WorkflowDefinitionItemType.Auto) {
      answer = questions.find((x) => x.parentId.includes(item.id))?.id;
    } else {
      answer = selectedQuestions[item.id];
    }

    const exists = !!answer;
    if (!exists) {
      continue;
    }

    if (answers.includes(answer!)) {
      continue;
    }

    answers.push(answer!);

    const items2 = questions
      .filter((x) => x.parentId.includes(item.id))
      .filter((x) => answer === x.id);

    for (const item2 of items2) {
      getSelectedAnswers(answers, questions, selectedQuestions, item2.id);
    }
  }
};
