import {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  IChangeDemandListState,
  changeDemandLoad,
  getChangeDemandList,
  selectChangeDemandList,
} from "../../store/changeDemandList";
import axios, { CancelToken, CancelTokenSource } from "axios";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import {
  ChangeDemandStateType,
  IChangeDemandItem,
} from "../../models/changeDemads";
import { IApplicationState } from "../../store";
import { connect } from "react-redux";
import { TableCol } from "../common/grid/TableCol";
import { format } from "date-fns";
import { DATE_FORMAT } from "../../utils/consts";
import {
  DashboardWidgetDiv,
  DashboardWidgetTableContainer,
  DashboardWidgetTitle,
} from "./DashboardWidgetsStyles";
import { DashboardDemandsTable, TableMessage } from "../common/grid/table";
import { StoreState } from "../../store/storeState";
import { TailSpin } from "react-loader-spinner";
import { COLORS } from "../../styles/colors";

interface Props {
  prov: IChangeDemandListState;
  getChangeDemandList(cancelToken: CancelToken): void;
  changeDemandLoad(reload: boolean): void;
}

const DashboardWidgetDemandChange: FC<Props> = ({
  prov,
  getChangeDemandList,
  changeDemandLoad,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const dataCancelToken = useRef<CancelTokenSource | null>(null);

  useEffect(() => {
    changeDemandLoad(true);
  }, [changeDemandLoad]);

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      dataCancelToken.current?.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (prov.state !== StoreState.None) {
      if (loading) {
        setLoading(false);
      }
      return;
    }

    dataCancelToken.current?.cancel();
    dataCancelToken.current = axios.CancelToken.source();

    getChangeDemandList(dataCancelToken.current.token);

    if (loading) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prov.state]);

  const getTextForState = (state: ChangeDemandStateType): string => {
    switch (state) {
      case ChangeDemandStateType.New:
        return t("changeDemand.stateFilter.new");
      case ChangeDemandStateType.Accepted:
        return t("changeDemand.stateFilter.accepted");
      case ChangeDemandStateType.Decline:
        return t("changeDemand.stateFilter.declined");
      default:
        return "";
    }
  };

  const handleRowClick = useCallback(
    (item: IChangeDemandItem) => {
      navigate(`/change-demand/${item.id}`);
    },
    [navigate]
  );

  const renderChangeDemandData = (item: IChangeDemandItem): ReactElement => {
    return (
      <>
        <TableCol>{getTextForState(item.state)}</TableCol>
        <TableCol>{item.clientName}</TableCol>
        <TableCol>{format(item.createdAt, DATE_FORMAT)}</TableCol>
        <TableCol />
      </>
    );
  };

  const tableData = useMemo(() => {
    if (
      loading ||
      prov.state === StoreState.None ||
      prov.state === StoreState.Loading ||
      prov.state === StoreState.Cancel ||
      prov.state === StoreState.Error ||
      prov.data.length === 0
    ) {
      return;
    }

    return (
      <tbody>
        {prov.data.slice(0, 5).map((d) => (
          <tr key={d.id} onClick={() => handleRowClick(d)}>
            {renderChangeDemandData(d)}
          </tr>
        ))}
      </tbody>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, prov.state, prov.data]);

  return (
    <DashboardWidgetDiv>
      <DashboardWidgetTitle>{t("changeDemand.pageTitle")}</DashboardWidgetTitle>
      <DashboardWidgetTableContainer>
        <DashboardDemandsTable>{tableData}</DashboardDemandsTable>
        {(loading ||
          prov.state === StoreState.None ||
          prov.state === StoreState.Loading ||
          prov.state === StoreState.Cancel) && (
          <TableMessage>
            <TailSpin color={COLORS.loaderColor} width={48} height={48} />
          </TableMessage>
        )}

        {prov.state === StoreState.Error && (
          <TableMessage>{t("errors.unknown")}</TableMessage>
        )}

        {prov.state === StoreState.Loaded && prov.data.length === 0 && (
          <TableMessage>{t("common.noData")}</TableMessage>
        )}
      </DashboardWidgetTableContainer>
    </DashboardWidgetDiv>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    prov: selectChangeDemandList(state),
  };
};

const mapDispachToProps = {
  getChangeDemandList,
  changeDemandLoad,
};

export default connect(
  mapStateToProps,
  mapDispachToProps
)(DashboardWidgetDemandChange);
