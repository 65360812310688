import { FC } from "react";
import { useTranslation } from "react-i18next";
import { RoleRightMode, RoleRightType } from "../../models/role";
import {
  AdminRoleRadio,
  AdminRoleRadioContainer,
  AdminRoleRightContainer,
} from "./AdminRoleStyles";

interface IProps {
  name: string;
  mode?: RoleRightMode;
  firstColumn?: string;
}

const AdminRoleRight: FC<IProps> = ({
  name,
  mode = RoleRightMode.Both,
  firstColumn,
}) => {
  const { t } = useTranslation();

  return (
    <AdminRoleRightContainer firstColumn={firstColumn}>
      <div>{t("admin.role." + name)}</div>
      <AdminRoleRadioContainer>
        <AdminRoleRadio name={name} type="radio" value={RoleRightType.None} />
      </AdminRoleRadioContainer>
      <AdminRoleRadioContainer
        className={mode !== RoleRightMode.Write ? undefined : "hidden"}
      >
        <AdminRoleRadio name={name} type="radio" value={RoleRightType.Read} />
      </AdminRoleRadioContainer>
      <AdminRoleRadioContainer
        className={mode !== RoleRightMode.Read ? undefined : "hidden"}
      >
        <AdminRoleRadio name={name} type="radio" value={RoleRightType.Write} />
      </AdminRoleRadioContainer>
    </AdminRoleRightContainer>
  );
};

export default AdminRoleRight;
