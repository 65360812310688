import { AppThunkAction, AppThunkActionAsync } from "..";
import * as actions from "./actions";
import assistanceApi from "../../api/assistance";
import {
  IAssistanceListFilter,
  IAssistanceListState,
  selectAssistanceList,
} from ".";
import { CancelToken } from "axios";
import {
  listEffectCountFilter,
  listEffectGetFilter,
  listEffectLoad,
  listEffectReload,
  listEffectsCountCheck,
  listEffectsGetCheck,
  listEffectsGetCount,
  listEffectsGetNew,
  listEffectsGetParams,
} from "../listEffect";

export function getAssistanceList(
  cancelToken: CancelToken
): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectAssistanceList(getState());
    if (listEffectsGetCheck(state.state)) {
      return;
    }

    let newState = listEffectsGetNew(state);
    dispatch(actions.assistanceListFetchRequest(newState.newCountState));
    try {
      const params = listEffectsGetParams(state, newState.take);
      listEffectGetFilter(params, state.filter);
      addAssistanceParams(params, state);

      const assistances = await assistanceApi.getList({ params, cancelToken });
      cancelToken.throwIfRequested();
      newState = listEffectsGetCount(newState, assistances);

      dispatch(
        actions.assistanceListFetchSuccess({
          data: assistances.data,
          newCountState: newState.newCountState,
          newCount: newState.newCount,
        })
      );
    } catch {
      dispatch(
        actions.assistanceListFetchError(cancelToken.reason !== undefined)
      );
    }
  };
}

export function getAssistanceListCount(
  cancelToken: CancelToken
): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectAssistanceList(getState());
    if (listEffectsCountCheck(state.countState)) {
      return;
    }

    dispatch(actions.assistanceListCountRequest());
    try {
      const params = listEffectCountFilter(state.filter);
      addAssistanceParams(params, state);

      const count = await assistanceApi.getCount({ params, cancelToken });
      cancelToken.throwIfRequested();

      dispatch(actions.assistanceListCountSuccess(count.data));
    } catch {
      dispatch(
        actions.assistanceListCountError(cancelToken.reason !== undefined)
      );
    }
  };
}

export function changeAssistanceListOrder(
  orderBy: string,
  orderDesc: boolean
): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.assistanceListChangeOrder({ orderBy, orderDesc }));
  };
}

export function changeAssistanceListPage(page: number): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.assistanceListChangePage(page));
  };
}

export function changeAssistanceListFilter(
  filter: IAssistanceListFilter
): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.assistanceListChangeFilter(filter));
  };
}

export function openAssistanceListFilter(): AppThunkAction {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(
      actions.assistanceListChangeFilter({
        ...state.assistanceList.filter!,
        isOpen: true,
      })
    );
  };
}

export function assistanceListLoad(reload: boolean): AppThunkAction {
  return async (dispatch, getState) => {
    const state = selectAssistanceList(getState());
    reload = listEffectReload(reload, state);
    if (reload) {
      dispatch(actions.assistanceListReset());
    } else {
      const newState = listEffectLoad(state);
      dispatch(
        actions.assistanceListLoadAction({
          newState: newState.newState,
          newCountState: newState.newCountState,
        })
      );
    }
  };
}

export function addAssistanceParams(
  params: URLSearchParams,
  state: IAssistanceListState
) {
  if (state.filter!.state) {
    params.append("state", state.filter!.state);
  }

  if (state.filter!.assistanceCategoryId) {
    params.append(
      "assistanceCategoryId",
      state.filter!.assistanceCategoryId.toString()
    );
  }

  if (state.filter!.careTypeId) {
    params.append("careTypeId", state.filter!.careTypeId.toString());
  }

  if (state.filter!.targetGroupId) {
    params.append("targetGroupId", state.filter!.targetGroupId.toString());
  }
}
