import { faCalendarAlt, faCheck } from "@fortawesome/free-solid-svg-icons";
import { isSameDay } from "date-fns";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  CalendarItemType,
  ICalendarCol,
  ICalendarItem,
} from "../../../models/calendar";
import {
  CalendarCell,
  CalendarCellEventAvailable,
  CalendarCellEventClosed,
  CalendarCellEventIcon,
  CalendarCellEventText,
  CalendarCellEventUnavailable,
  CalendarCellEventWrapper,
  CalendarDayContainer,
  CalendarDayLabel,
  CalendarDayNumber,
  CalendarTitleLabel,
} from "./CalendarStyles";

interface IProps {
  col: ICalendarCol;
  multiMode?: boolean;
  onClick(type: CalendarItemType, item: ICalendarItem): void;
}

const CalendarDay: FC<IProps> = ({ col, multiMode, onClick }) => {
  const { t } = useTranslation();

  return (
    <CalendarDayContainer>
      {col.title ? (
        <CalendarTitleLabel>{col.title}</CalendarTitleLabel>
      ) : (
        <CalendarDayLabel>
          <div>{t(`common.day.${col.date.getDay()}`)}</div>
          <CalendarDayNumber isSelected={isSameDay(col.date, new Date())}>
            {col.date.getDate()}
          </CalendarDayNumber>
        </CalendarDayLabel>
      )}
      {col.items.map((cell, i) => (
        <CalendarCell key={i} joinCount={cell.joinCount ?? 1}>
          <CalendarCellEventWrapper>
            {multiMode ? (
              <>
                {cell.data.length === 0 ? (
                  <CalendarCellEventClosed />
                ) : (
                  <>
                    {cell.data.filter((x) => !x.actionId).length !== 0 && (
                      <CalendarCellEventAvailable
                        onClick={() =>
                          onClick(CalendarItemType.Available, cell)
                        }
                      >
                        <CalendarCellEventText>
                          <CalendarCellEventIcon icon={faCheck} size={"xs"} />
                          {cell.data.filter((x) => !x.actionId).length}
                        </CalendarCellEventText>
                      </CalendarCellEventAvailable>
                    )}
                    {cell.data.filter((x) => x.actionId).length !== 0 && (
                      <CalendarCellEventUnavailable
                        onClick={() =>
                          onClick(CalendarItemType.Unavailable, cell)
                        }
                      >
                        <CalendarCellEventText>
                          <CalendarCellEventIcon
                            icon={faCalendarAlt}
                            size={"xs"}
                          />
                          {cell.data.filter((x) => x.actionId).length}
                        </CalendarCellEventText>
                      </CalendarCellEventUnavailable>
                    )}
                  </>
                )}
              </>
            ) : cell.data.length === 0 ? (
              <CalendarCellEventClosed />
            ) : cell.data[0].actionId ? (
              <CalendarCellEventUnavailable
                onClick={() => onClick(CalendarItemType.Unavailable, cell)}
              >
                <CalendarCellEventText>
                  <CalendarCellEventIcon icon={faCalendarAlt} size={"xs"} />
                  {t("calendar.unavailable")}
                </CalendarCellEventText>
              </CalendarCellEventUnavailable>
            ) : (
              <CalendarCellEventAvailable
                onClick={() => onClick(CalendarItemType.Available, cell)}
              >
                <CalendarCellEventText>
                  <CalendarCellEventIcon icon={faCheck} size={"xs"} />
                  {t("calendar.available")}
                </CalendarCellEventText>
              </CalendarCellEventAvailable>
            )}
          </CalendarCellEventWrapper>
        </CalendarCell>
      ))}
    </CalendarDayContainer>
  );
};

export default CalendarDay;
