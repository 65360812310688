import { render, unmountComponentAtNode } from "react-dom";
import ModalOk from "./ModalOk";
import ModalYesNo from "./ModalYesNo";

export const ModalYesNoFunction = (
  title: string,
  text: string
): Promise<boolean> => {
  return new Promise((resolve) => {
    const el = document.createElement("div");

    const close = () => {
      unmountComponentAtNode(el);
      resolve(false);
    };

    const confirm = () => {
      unmountComponentAtNode(el);
      resolve(true);
      return Promise.resolve();
    };

    render(
      <ModalYesNo
        title={title}
        text={text}
        isOpen={true}
        isFunction={true}
        close={close}
        confirm={confirm}
      />,
      el
    );
  });
};

export const ModalOkFunction = (
  title: string,
  text: string,
  confirmText?: string
): Promise<boolean> => {
  return new Promise((resolve) => {
    const el = document.createElement("div");

    const close = () => {
      unmountComponentAtNode(el);
      resolve(false);
    };

    const confirm = () => {
      unmountComponentAtNode(el);
      resolve(true);
      return Promise.resolve();
    };

    render(
      <ModalOk
        title={title}
        text={text}
        isOpen={true}
        isFunction={true}
        close={close}
        confirm={confirm}
        confirmText={confirmText}
      />,
      el
    );
  });
};
