import { Formik } from "formik";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { IClientCare } from "../../models/client";
import { IApplicationState } from "../../store";
import { StoreState } from "../../store/storeState";
import {
  getDefaultClientCare,
  getClientCare,
  selectClientCare,
  selectClientCareState,
} from "../../store/clientCare";
import Loader from "../common/Loader";
import * as Yup from "yup";
import { clientCareListLoad } from "../../store/clientCareList";
import clientApi from "../../api/client";
import validations from "../../utils/validations";
import { selectClientId } from "../../store/client";
import { RightType } from "../../models/auth";
import { selectIdentityRights } from "../../store/identity";
import { promiseToastSave } from "../../utils/toasts";
import { errorSet } from "../../utils/error";
import ClientCareDetailContent, {
  IClientCareForm,
} from "./ClientCareDetailContent";

interface IProps {
  clientId?: number;
  clientCareState: StoreState;
  clientCare: IClientCare | null;
  identityRights?: RightType[];
  getClientCare(clientCareId: number): void;
  getDefaultClientCare(): void;
  clientCareListLoad(reload: boolean): void;
}

const ClientCareDetail: FC<IProps> = ({
  clientId,
  clientCareState,
  clientCare,
  identityRights,
  getClientCare,
  getDefaultClientCare,
  clientCareListLoad,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { clientId: paramClientId, careId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (careId === "new") {
      getDefaultClientCare();
    } else {
      getClientCare(parseInt(careId!));
    }
    setLoading(false);
  }, [careId, getClientCare, getDefaultClientCare]);

  const handleSubmit = async (data: IClientCareForm) => {
    setError(null);
    try {
      await promiseToastSave(async () => {
        const data2 = {
          id: data.id,
          client: { id: clientId!, name: data.clientName },
          assistance: { id: data.assistanceId, name: data.assistanceName },
          assistanceAddress: {
            id: data.assistanceAddressId,
            name: data.assistanceAddressName,
          },
          assistanceCategoryListing: {
            id: data.assistanceCategoryListingId,
            name: data.assistanceCategoryListingName,
          },
          careType: { id: data.careTypeId, name: data.careTypeName },
          from: data.from,
          to: data.to,
          state: data.state,
          note: data.note,
        };
        if (careId === "new") {
          await clientApi.createClientCare(data2);
        } else {
          await clientApi.updateClientCare(data2);
        }
        clientCareListLoad(true);
        navigate(`/client/${paramClientId}/care`);
      });
    } catch (err) {
      errorSet(setError, err, t);
    }
  };

  if (loading || clientCareState === StoreState.Loading) {
    return <Loader />;
  }

  if (clientCareState === StoreState.Error) {
    return t("errors.unknown");
  }

  return (
    <Formik<IClientCareForm>
      initialValues={{
        ...clientCare!,
        clientId: clientCare!.client.id,
        clientName: clientCare!.client.name,
        assistanceId: clientCare!.assistance.id,
        assistanceName: clientCare!.assistance.name,
        assistanceAddressId: clientCare!.assistanceAddress.id,
        assistanceAddressName: clientCare!.assistanceAddress.name,
        assistanceCategoryListingId: clientCare!.assistanceCategoryListing.id,
        assistanceCategoryListingName:
          clientCare!.assistanceCategoryListing.name,
        careTypeId: clientCare!.careType.id,
        careTypeName: clientCare!.careType.name,
        note: clientCare!.note ?? "",
      }}
      validationSchema={Yup.object({
        assistanceId: validations.idRequired(t),
        assistanceAddressId: validations.idRequired(t),
        assistanceCategoryListingId: validations.idRequired(t),
        careTypeId: validations.idRequired(t),
        from: validations.dateRequired(t),
      })}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      <ClientCareDetailContent
        error={error}
        identityRights={identityRights}
        paramClientId={paramClientId}
      />
    </Formik>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    clientId: selectClientId(state),
    clientCareState: selectClientCareState(state),
    clientCare: selectClientCare(state),
    identityRights: selectIdentityRights(state),
  };
};

const mapDispachToProps = {
  getClientCare,
  getDefaultClientCare,
  clientCareListLoad,
};

export default connect(mapStateToProps, mapDispachToProps)(ClientCareDetail);
