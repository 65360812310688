import { Form, Formik } from "formik";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { IClientRiskGrid } from "../../models/client";
import { IApplicationState } from "../../store";
import { selectClientId } from "../../store/client";
import { ApiError, FormGroup } from "../../styles/form";
import * as Yup from "yup";
import clientApi from "../../api/client";
import { RightType } from "../../models/auth";
import { selectIdentityRights } from "../../store/identity";
import { hasClientWriteRight } from "../../utils/rights";
import { StoreState } from "../../store/storeState";
import Loader from "../common/Loader";
import { IItemIdName } from "../../models/common";
import {
  getClientRisk,
  selectClientRisk,
  selectClientRiskState,
  updateClientRisk,
} from "../../store/clientRisk";
import SuggestionListFormik from "../common/suggestion/SuggestionListFormik";
import { handleLoadClientRiskListingSuggestions } from "../../utils/suggestions";
import { promiseToastSave } from "../../utils/toasts";
import SubmitForm from "../common/form/SubmitForm";
import { errorSet } from "../../utils/error";

interface IProps {
  clientRiskState: StoreState;
  clientRisk: IItemIdName[];
  clientId?: number;
  identityRights?: RightType[];
  getClientRisk(clientId: number): void;
  updateClientRisk(newData: IItemIdName[]): void;
}

const ClientRisk: FC<IProps> = ({
  clientRiskState,
  clientRisk,
  clientId,
  identityRights,
  getClientRisk,
  updateClientRisk,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getClientRisk(clientId!);
    setLoading(false);
  }, [clientId, getClientRisk]);

  const handleSubmit = async (data: IClientRiskGrid) => {
    setError(null);
    try {
      await promiseToastSave(async () => {
        await clientApi.updateClientRiskList(
          clientId!,
          data.items.map((x) => x.id)
        );
        updateClientRisk(data.items);
      });
    } catch (err) {
      errorSet(setError, err, t);
    }
  };

  if (loading || clientRiskState === StoreState.Loading) {
    return <Loader />;
  }

  if (clientRiskState === StoreState.Error) {
    return t("errors.unknown");
  }

  return (
    <Formik<IClientRiskGrid>
      initialValues={{ items: clientRisk! }}
      validationSchema={Yup.object({})}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, isSubmitting }) => (
        <Form>
          <FormGroup>
            <SuggestionListFormik
              nameList="items"
              nameId="id"
              nameText="name"
              loadSuggestions={handleLoadClientRiskListingSuggestions}
            />
          </FormGroup>
          {error && <ApiError>{error}</ApiError>}
          {hasClientWriteRight(identityRights, [RightType.WriteClientRisk]) && (
            <SubmitForm />
          )}
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    clientRiskState: selectClientRiskState(state),
    clientRisk: selectClientRisk(state),
    clientId: selectClientId(state),
    identityRights: selectIdentityRights(state),
  };
};

const mapDispachToProps = {
  getClientRisk,
  updateClientRisk,
};

export default connect(mapStateToProps, mapDispachToProps)(ClientRisk);
