import { ILogItem } from "../../models/log";
import { IFilterType } from "../filterType";
import { IListType } from "../listType";

export enum LogListTypes {
  LOG_LIST_FETCH_REQUEST = "@@logList/FETCH_REQUEST",
  LOG_LIST_FETCH_SUCCESS = "@@logList/FETCH_SUCCESS",
  LOG_LIST_FETCH_ERROR = "@@logList/FETCH_ERROR",
  LOG_LIST_COUNT_REQUEST = "@@logList/COUNT_REQUEST",
  LOG_LIST_COUNT_SUCCESS = "@@logList/COUNT_SUCCESS",
  LOG_LIST_COUNT_ERROR = "@@logList/COUNT_ERROR",
  LOG_LIST_CHANGE_ORDER = "@@logList/CHANGE_ORDER",
  LOG_LIST_CHANGE_PAGE = "@@logList/CHANGE_PAGE",
  LOG_LIST_CHANGE_FILTER = "@@logList/CHANGE_FILTER",
  LOG_LIST_RESET = "@@logList/RESET",
  LOG_LIST_LOAD = "@@logList/LOAD",
}

export interface ILogListFilter extends IFilterType {
  from?: Date | null;
  to?: Date | null;
  userId: number;
  userName: string;
  clientId: number;
  clientName: string;
}

export interface ILogListState extends IListType<ILogItem, ILogListFilter> {}
