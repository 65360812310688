import { Formik, useFormikContext } from "formik";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  ClientIdentityType,
  ClientStateType,
  IClient,
} from "../../models/client";
import { IApplicationState } from "../../store";
import { selectClient, updateClient } from "../../store/client";
import { ApiError, FormGroup, FormRow } from "../../styles/form";
import Input from "../common/form/Input";
import PhoneInput from "../common/form/PhoneInput";
import * as Yup from "yup";
import { clientListLoad } from "../../store/clientList";
import clientApi from "../../api/client";
import validations from "../../utils/validations";
import { RightType } from "../../models/auth";
import { selectIdentityRights } from "../../store/identity";
import { hasClientWriteRight } from "../../utils/rights";
import { promiseToast, promiseToastSave } from "../../utils/toasts";
import SubmitForm from "../common/form/SubmitForm";
import DatePicker from "../common/form/DatePicker";
import {
  handleLoadHealthInsuranceSuggestions,
  handleLoadUserSuggestions,
} from "../../utils/suggestions";
import SuggestionFormik from "../common/suggestion/SuggestionFormik";
import { SpaceBetweenButtons } from "../../styles/spaces";
import { FormButton } from "../../styles/button";
import authApi from "../../api/auth";
import { FormDesktop, FormTablet } from "../../styles/form";
import FormGroupRow from "../common/form/FormGroupRow";
import { errorSet } from "../../utils/error";

interface IClientForm
  extends Omit<
    IClient,
    | "addressPermanentHouseNumber"
    | "addressPermanentLandRegistryNumber"
    | "addressPermanentPostCode"
    | "addressActualHouseNumber"
    | "addressActualLandRegistryNumber"
    | "addressActualPostCode"
    | "addressMailHouseNumber"
    | "addressMailLandRegistryNumber"
    | "addressMailPostCode"
  > {
  keyCaregiverId?: number;
  keyCaregiverName?: string;
  hasLogin: boolean;
  healthInsuranceId: number;
  healthInsuranceName: string;
  addressPermanentHouseNumber: string;
  addressPermanentLandRegistryNumber: string;
  addressPermanentPostCode: string;
  addressActualHouseNumber: string;
  addressActualLandRegistryNumber: string;
  addressActualPostCode: string;
  addressMailHouseNumber: string;
  addressMailLandRegistryNumber: string;
  addressMailPostCode: string;
  showKeyCaregiverPhone: boolean;
  showKeyCaregiverEmail: boolean;
}

interface IProps {
  client: IClient | null;
  identityRights?: RightType[];
  updateClient(newData: IClient): void;
  clientListLoad(reload: boolean): void;
}

// used as 2 parts in FormDesktop using slice
const fields1: Array<{ name: keyof IClient; maxLen?: number }> = [
  { name: "title", maxLen: 100 },
  { name: "firstName", maxLen: 100 },
  { name: "lastName", maxLen: 100 },
  { name: "maidenName", maxLen: 100 },
  { name: "degree", maxLen: 100 },
];

// used as 2 parts in FormDesktop using slice
const addressPermanentFields: Array<{ name: keyof IClient; maxLen?: number }> =
  [
    { name: "addressPermanentStreet", maxLen: 150 },
    { name: "addressPermanentHouseNumber" },
    { name: "addressPermanentLandRegistryNumber" },
    { name: "addressPermanentCity", maxLen: 150 },
    { name: "addressPermanentPostCode" },
  ];

// Commented fileds will be maybe restored in the future
const numberFields: Array<keyof IClient> = [
  // "addressPermanentHouseNumber",
  // "addressPermanentLandRegistryNumber",
  "addressPermanentPostCode",
  // "addressActualHouseNumber",
  "addressActualPostCode",
  // "addressActualLandRegistryNumber",
  // "addressMailHouseNumber",
  "addressMailPostCode",
  // "addressMailLandRegistryNumber",
];

const addressActualFields: Array<{
  name: keyof IClient;
  maxLen?: number;
}> = [
  { name: "addressActualStreet", maxLen: 150 },
  { name: "addressActualHouseNumber" },
  { name: "addressActualLandRegistryNumber" },
  { name: "addressActualCity", maxLen: 150 },
  { name: "addressActualPostCode" },
];

const addressMailFields: Array<{
  name: keyof IClient;
  maxLen?: number;
}> = [
  { name: "addressMailStreet", maxLen: 150 },
  { name: "addressMailHouseNumber" },
  { name: "addressMailLandRegistryNumber" },
  { name: "addressMailCity", maxLen: 150 },
  { name: "addressMailPostCode" },
];

const ClientGeneral: FC<IProps> = ({
  client,
  identityRights,
  updateClient,
  clientListLoad,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);
  const [sendPasswordInProgress, setSendPasswordInProgress] = useState(false);

  const handleSubmit = async (data: IClientForm) => {
    setError(null);
    try {
      await promiseToastSave(async () => {
        const data2 = {
          ...data,
          keyCaregiver: data.keyCaregiverId
            ? {
                id: data.keyCaregiverId!,
                name: data.keyCaregiverName!,
                showEmail: data.showKeyCaregiverEmail,
                showMobilePhoneNumber: data.showKeyCaregiverPhone,
              }
            : null,
          healthInsurance: data.healthInsuranceId
            ? { id: data.healthInsuranceId, name: data.healthInsuranceName }
            : null,
          login: data.hasLogin ? data.email : null,
          addressPermanentHouseNumber: data.addressPermanentHouseNumber,
          addressPermanentLandRegistryNumber:
            data.addressPermanentLandRegistryNumber,
          addressPermanentPostCode: +data.addressPermanentPostCode,
          addressActualHouseNumber: data.addressActualHouseNumber,
          addressActualLandRegistryNumber: data.addressActualLandRegistryNumber,
          addressActualPostCode: +data.addressActualPostCode,
          addressMailHouseNumber: data.addressMailHouseNumber,
          addressMailLandRegistryNumber: data.addressMailLandRegistryNumber,
          addressMailPostCode: +data.addressMailPostCode,
        };

        let newData;
        if (client!.id === 0) {
          newData = await clientApi.createClient(data2);
        } else {
          newData = await clientApi.updateClient(data2);
        }
        updateClient(newData.data);
        clientListLoad(true);
      });
    } catch (err) {
      errorSet(setError, err, t);
    }
  };

  const handleSendPassword = async () => {
    setError(null);
    setSendPasswordInProgress(true);
    try {
      await promiseToast(
        async () => {
          await authApi.sendPublicPasswordResetLink({ email: client!.email! });
        },
        t("sendPassword.pending"),
        t("sendPassword.success"),
        t("errors.unknown")
      );
    } catch (err) {
      errorSet(setError, err, t);
    }
    setSendPasswordInProgress(false);
  };

  const ErrorAndButtons: FC = () => {
    const { values, isSubmitting, isValid } = useFormikContext<IClientForm>();

    return (
      <>
        {error && <ApiError>{error}</ApiError>}
        {hasClientWriteRight(identityRights, [
          RightType.WriteClientGeneral,
        ]) && (
          <div>
            <SubmitForm disabled={sendPasswordInProgress} />
            {client!.id !== 0 && !!client!.login && (
              <>
                <SpaceBetweenButtons />
                <FormButton
                  ver="secondary"
                  type="button"
                  disabled={
                    isSubmitting ||
                    sendPasswordInProgress ||
                    !isValid ||
                    client!.state !== ClientStateType.Active ||
                    values.email !== client!.email ||
                    values.email !== client!.login
                  }
                  onClick={handleSendPassword}
                >
                  {t("sendPassword.button")}
                </FormButton>
              </>
            )}
          </div>
        )}
      </>
    );
  };

  const pathname = window.location.pathname;

  const isNotKeyPerson =
    !client?.firstName &&
    !client?.lastName &&
    !pathname.split("/").includes("new");

  return (
    <Formik<IClientForm>
      initialValues={{
        ...client!,
        keyCaregiverId: client!.keyCaregiver?.id,
        keyCaregiverName: client!.keyCaregiver?.name,
        hasLogin: !!client!.login,
        title: client!.title ?? "",
        degree: client!.degree ?? "",
        mobilePhone: client!.mobilePhone ?? "",
        email: client!.email ?? "",
        phone: client!.phone ?? "",
        dataBox: client!.dataBox ?? "",
        identityCode: client!.identityCode ?? "",
        addressPermanentStreet: client!.addressPermanentStreet ?? "",
        addressPermanentHouseNumber: client!.addressPermanentHouseNumber
          ? client!.addressPermanentHouseNumber.toString()
          : "",
        addressPermanentLandRegistryNumber: client!
          .addressPermanentLandRegistryNumber
          ? client!.addressPermanentLandRegistryNumber.toString()
          : "",
        addressPermanentCity: client!.addressPermanentCity ?? "",
        addressPermanentPostCode: client!.addressPermanentPostCode
          ? client!.addressPermanentPostCode.toString()
          : "",
        addressActualStreet: client!.addressActualStreet ?? "",
        addressActualHouseNumber: client!.addressActualHouseNumber
          ? client!.addressActualHouseNumber.toString()
          : "",
        addressActualLandRegistryNumber: client!.addressActualLandRegistryNumber
          ? client!.addressActualLandRegistryNumber.toString()
          : "",
        addressActualCity: client!.addressActualCity ?? "",
        addressActualPostCode: client!.addressActualPostCode
          ? client!.addressActualPostCode.toString()
          : "",
        addressMailStreet: client!.addressMailStreet ?? "",
        addressMailHouseNumber: client!.addressMailHouseNumber
          ? client!.addressMailHouseNumber.toString()
          : "",
        addressMailLandRegistryNumber: client!.addressMailLandRegistryNumber
          ? client!.addressMailLandRegistryNumber.toString()
          : "",
        addressMailCity: client!.addressMailCity ?? "",
        addressMailPostCode: client!.addressMailPostCode
          ? client!.addressMailPostCode.toString()
          : "",
        healthInsuranceId: client!.healthInsurance?.id ?? 0,
        healthInsuranceName: client!.healthInsurance?.name ?? "",
        showKeyCaregiverPhone: !!client!.keyCaregiver?.showMobilePhoneNumber,
        showKeyCaregiverEmail: !!client!.keyCaregiver?.showEmail,
      }}
      enableReinitialize={true}
      validationSchema={Yup.object({
        firstName: validations.stringRequired(t),
        lastName: validations.stringRequired(t),
        mobilePhone: validations.phoneOptional(t),
        email: validations.emailOptional(t).when(["hasLogin"], {
          is: (hasLogin: any) => hasLogin,
          then: validations
            .emailOptional(t)
            .required(t("client.emailLoginError")),
        }),
        phone: validations.phoneOptional(t),
        // addressPermanentHouseNumber: validations.intOptionalMinMax(1, 9999, t),
        addressPermanentPostCode: validations.postCodeOptional(t),
        // addressActualHouseNumber: validations
        //   .intOptional(t)
        //   .when(["addressActualIsSame"], {
        //     is: (addressActualIsSame: any) => !addressActualIsSame,
        //     then: validations.intOptionalMinMax(1, 9999, t),
        //   }),
        // addressActualPostCode: validations
        //   .intOptional(t)
        //   .when(["addressActualIsSame"], {
        //     is: (addressActualIsSame: any) => !addressActualIsSame,
        //     then: validations.postCodeOptional(t),
        //   }),
        // addressMailHouseNumber: validations
        //   .intOptional(t)
        //   .when(["addressMailIsSame"], {
        //     is: (addressMailIsSame: any) => !addressMailIsSame,
        //     then: validations.intOptionalMinMax(1, 9999, t),
        //   }),
        // addressMailPostCode: validations
        //   .intOptional(t)
        //   .when(["addressMailIsSame"], {
        //     is: (addressMailIsSame: any) => !addressMailIsSame,
        //     then: validations.postCodeOptional(t),
        //   }),
        // identificationNumber: validations.identificationNumberOptional(t),
      })}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, values }) => (
        <>
          <FormDesktop>
            <FormGroup>
              <Input
                disabled={isNotKeyPerson}
                key="fullRegistration"
                name="fullRegistration"
                type="checkbox"
                label={t(`client.fullRegistration`)}
                inputWidth="1.5rem"
                inputHeight="1.5rem"
              />
            </FormGroup>

            <hr />

            <FormRow gridTemplateColumns="1fr 2fr 2fr 2fr 1fr">
              {fields1.map((f) => (
                <Input
                  disabled={isNotKeyPerson}
                  key={f.name}
                  name={f.name}
                  type={
                    numberFields.find((x) => x === f.name) ? "number" : "text"
                  }
                  label={t("client." + f.name)}
                  error={touched[f.name] && !!errors[f.name]}
                  maxLength={f.maxLen}
                />
              ))}
            </FormRow>

            <hr />

            <FormGroup>
              <h2>{t("client.addressPermanent")}</h2>
              <FormGroupRow
                gridTemplateColumns="2fr 1fr 1fr"
                names={[
                  addressPermanentFields[0].name,
                  addressPermanentFields[1].name,
                ]}
                label={t("client.streetAndHouseNumberAndLandRegistryNumber")}
              >
                {addressPermanentFields.slice(0, 3).map((f) => (
                  <Input
                    disabled={isNotKeyPerson}
                    key={f.name}
                    name={f.name}
                    type={
                      numberFields.find((x) => x === f.name) ? "number" : "text"
                    }
                    error={touched[f.name] && !!errors[f.name]}
                    maxLength={f.maxLen}
                  />
                ))}
              </FormGroupRow>

              <FormGroupRow
                gridTemplateColumns="2fr 1fr"
                names={[
                  addressPermanentFields[2].name,
                  addressPermanentFields[3].name,
                ]}
                label={t("client.cityAndPostCode")}
              >
                {addressPermanentFields.slice(3).map((f) => (
                  <Input
                    disabled={isNotKeyPerson}
                    key={f.name}
                    name={f.name}
                    type={
                      numberFields.find((x) => x === f.name) ? "number" : "text"
                    }
                    error={touched[f.name] && !!errors[f.name]}
                    maxLength={f.maxLen}
                  />
                ))}
              </FormGroupRow>

              <Input
                disabled={isNotKeyPerson}
                key="addressActualIsSame"
                name="addressActualIsSame"
                type="checkbox"
                label={t(`client.addressActualIsSame`)}
                inputWidth="1.5rem"
                inputHeight="1.5rem"
              />
              <Input
                disabled={isNotKeyPerson}
                key="addressMailIsSame"
                name="addressMailIsSame"
                type="checkbox"
                label={t(`client.addressMailIsSame`)}
                inputWidth="1.5rem"
                inputHeight="1.5rem"
              />
            </FormGroup>

            {!values.addressActualIsSame && (
              <>
                <hr />

                <FormGroup>
                  <h2>{t("client.addressActual")}</h2>
                  <FormGroupRow
                    gridTemplateColumns="2fr 1fr 1fr"
                    names={[
                      addressActualFields[0].name,
                      addressActualFields[1].name,
                      addressActualFields[2].name,
                    ]}
                    label={t(
                      "client.streetAndHouseNumberAndLandRegistryNumber"
                    )}
                  >
                    {addressActualFields.slice(0, 3).map((f) => (
                      <Input
                        disabled={isNotKeyPerson}
                        key={f.name}
                        name={f.name}
                        type={
                          numberFields.find((x) => x === f.name)
                            ? "number"
                            : "text"
                        }
                        error={touched[f.name] && !!errors[f.name]}
                        maxLength={f.maxLen}
                      />
                    ))}
                  </FormGroupRow>

                  <FormGroupRow
                    gridTemplateColumns="2fr 1fr"
                    names={[
                      addressActualFields[2].name,
                      addressActualFields[3].name,
                    ]}
                    label={t("client.cityAndPostCode")}
                  >
                    {addressActualFields.slice(3).map((f) => (
                      <Input
                        disabled={isNotKeyPerson}
                        key={f.name}
                        name={f.name}
                        type={
                          numberFields.find((x) => x === f.name)
                            ? "number"
                            : "text"
                        }
                        error={touched[f.name] && !!errors[f.name]}
                        maxLength={f.maxLen}
                      />
                    ))}
                  </FormGroupRow>
                </FormGroup>
              </>
            )}

            {!values.addressMailIsSame && (
              <>
                <hr />

                <FormGroup>
                  <h2>{t("client.addressMail")}</h2>
                  <FormGroupRow
                    gridTemplateColumns="2fr 1fr 1fr"
                    names={[
                      addressMailFields[0].name,
                      addressMailFields[1].name,
                      addressMailFields[2].name,
                    ]}
                    label={t(
                      "client.streetAndHouseNumberAndLandRegistryNumber"
                    )}
                  >
                    {addressMailFields.slice(0, 3).map((f) => (
                      <Input
                        disabled={isNotKeyPerson}
                        key={f.name}
                        name={f.name}
                        type={
                          numberFields.find((x) => x === f.name)
                            ? "number"
                            : "text"
                        }
                        error={touched[f.name] && !!errors[f.name]}
                        maxLength={f.maxLen}
                      />
                    ))}
                  </FormGroupRow>

                  <FormGroupRow
                    gridTemplateColumns="2fr 1fr"
                    names={[
                      addressMailFields[2].name,
                      addressMailFields[3].name,
                    ]}
                    label={t("client.cityAndPostCode")}
                  >
                    {addressMailFields.slice(3).map((f) => (
                      <Input
                        disabled={isNotKeyPerson}
                        key={f.name}
                        name={f.name}
                        type={
                          numberFields.find((x) => x === f.name)
                            ? "number"
                            : "text"
                        }
                        error={touched[f.name] && !!errors[f.name]}
                        maxLength={f.maxLen}
                      />
                    ))}
                  </FormGroupRow>
                </FormGroup>
              </>
            )}

            <hr />

            <FormGroup>
              <h2>{t("client.contact")}</h2>
              <PhoneInput
                name="mobilePhone"
                label={t("client.mobilePhone")}
                error={touched.mobilePhone && !!errors.mobilePhone}
                disabled={isNotKeyPerson}
              />
              <PhoneInput
                name="phone"
                label={t("client.phone")}
                error={touched.phone && !!errors.phone}
                disabled={isNotKeyPerson}
              />
              <Input
                disabled={isNotKeyPerson}
                name="email"
                label={t("client.email")}
                error={touched.email && !!errors.email}
                maxLength={255}
              />
              <Input
                disabled={isNotKeyPerson}
                name="dataBox"
                label={t("client.dataBox")}
                error={touched.dataBox && !!errors.dataBox}
                maxLength={100}
              />
            </FormGroup>

            {values.fullRegistration && (
              <>
                <hr />

                <FormGroup>
                  <h2>{t("client.identification")}</h2>
                  <FormGroupRow
                    gridTemplateColumns="1fr 1fr"
                    names={["identityType", "identityCode"]}
                    label={t("client.identification")}
                  >
                    <Input
                      disabled={isNotKeyPerson}
                      key="identityType"
                      name="identityType"
                      component="select"
                      error={touched.identityType && !!errors.identityType}
                    >
                      <option value="">{t("client.identityTypes.null")}</option>
                      {(
                        Object.keys(ClientIdentityType) as Array<
                          keyof typeof ClientIdentityType
                        >
                      ).map((key) => (
                        <option key={key} value={ClientIdentityType[key]}>
                          {t("client.identityTypes." + ClientIdentityType[key])}
                        </option>
                      ))}
                    </Input>
                    {values.identityType &&
                      values.identityType !== ClientIdentityType.Unverified && (
                        <Input
                          disabled={isNotKeyPerson}
                          key="identityCode"
                          name="identityCode"
                          error={touched.identityCode && !!errors.identityCode}
                          maxLength={100}
                        />
                      )}
                  </FormGroupRow>

                  <FormGroupRow
                    gridTemplateColumns="1fr 1fr"
                    names={["birthDate", "identificationNumber"]}
                    label={t("client.birdthDataAndIdentificationNumber")}
                  >
                    <DatePicker name="birthDate" disabled={isNotKeyPerson} />
                    <Input
                      disabled={isNotKeyPerson}
                      key="identificationNumber"
                      name="identificationNumber"
                      error={
                        touched.identificationNumber &&
                        !!errors.identificationNumber
                      }
                    />
                  </FormGroupRow>

                  <SuggestionFormik
                    nameId="healthInsuranceId"
                    nameText="healthInsuranceName"
                    label={t("client.healthInsurance")}
                    loadSuggestions={handleLoadHealthInsuranceSuggestions}
                    disabled={isNotKeyPerson}
                  />

                  <SuggestionFormik
                    nameId="keyCaregiverId"
                    nameText="keyCaregiverName"
                    label={t("client.keyCaregiver")}
                    loadSuggestions={handleLoadUserSuggestions}
                    disabled={isNotKeyPerson}
                  />
                </FormGroup>

                <hr />

                <FormGroup>
                  <h2>{t("client.showKeyCaregiverContact")}</h2>

                  <Input
                    name="showKeyCaregiverPhone"
                    type="checkbox"
                    label={t("client.showKeyCaregiverPhone")}
                    inputWidth="1.5rem"
                    inputHeight="1.5rem"
                  />

                  <Input
                    name="showKeyCaregiverEmail"
                    type="checkbox"
                    label={t("client.showKeyCaregiverEmail")}
                    inputWidth="1.5rem"
                    inputHeight="1.5rem"
                  />
                </FormGroup>

                <hr />

                <FormGroup>
                  <Input
                    disabled={isNotKeyPerson}
                    key="hasLogin"
                    name="hasLogin"
                    type="checkbox"
                    label={t(`client.hasLogin`)}
                    inputWidth="1.5rem"
                    inputHeight="1.5rem"
                  />
                  {values.hasLogin && (
                    <Input
                      disabled={true}
                      key="login"
                      name="email"
                      label={t("client.login")}
                    />
                  )}
                </FormGroup>
              </>
            )}
            {!isNotKeyPerson && <ErrorAndButtons />}
          </FormDesktop>

          <FormTablet>
            <FormGroup>
              <Input
                disabled={isNotKeyPerson}
                key="fullRegistration"
                name="fullRegistration"
                type="checkbox"
                label={t(`client.fullRegistration`)}
                inputWidth="1.5rem"
                inputHeight="1.5rem"
              />
            </FormGroup>

            <hr />

            <FormGroup>
              {fields1.map((f) => (
                <Input
                  disabled={isNotKeyPerson}
                  key={f.name}
                  name={f.name}
                  type={
                    numberFields.find((x) => x === f.name) ? "number" : "text"
                  }
                  label={t("client." + f.name)}
                  error={touched[f.name] && !!errors[f.name]}
                  maxLength={f.maxLen}
                />
              ))}
            </FormGroup>

            <hr />

            <FormGroup>
              <h2>{t("client.addressPermanent")}</h2>
              {addressPermanentFields.map((f) => (
                <Input
                  disabled={isNotKeyPerson}
                  key={f.name}
                  name={f.name}
                  type={
                    numberFields.find((x) => x === f.name) ? "number" : "text"
                  }
                  label={t("client." + f.name)}
                  error={touched[f.name] && !!errors[f.name]}
                  maxLength={f.maxLen}
                />
              ))}
              <Input
                disabled={isNotKeyPerson}
                key="addressActualIsSame"
                name="addressActualIsSame"
                type="checkbox"
                label={t(`client.addressActualIsSame`)}
                inputWidth="1.5rem"
                inputHeight="1.5rem"
              />
              <Input
                disabled={isNotKeyPerson}
                key="addressMailIsSame"
                name="addressMailIsSame"
                type="checkbox"
                label={t(`client.addressMailIsSame`)}
                inputWidth="1.5rem"
                inputHeight="1.5rem"
              />
            </FormGroup>

            {!values.addressActualIsSame && (
              <>
                <hr />

                <FormGroup>
                  <h2>{t("client.addressActual")}</h2>
                  {addressActualFields.map((f) => (
                    <Input
                      disabled={isNotKeyPerson}
                      key={f.name}
                      name={f.name}
                      type={
                        numberFields.find((x) => x === f.name)
                          ? "number"
                          : "text"
                      }
                      label={t("client." + f.name)}
                      error={touched[f.name] && !!errors[f.name]}
                      maxLength={f.maxLen}
                    />
                  ))}
                </FormGroup>
              </>
            )}

            {!values.addressMailIsSame && (
              <>
                <hr />

                <FormGroup>
                  <h2>{t("client.addressMail")}</h2>
                  {addressMailFields.map((f) => (
                    <Input
                      disabled={isNotKeyPerson}
                      key={f.name}
                      name={f.name}
                      type={
                        numberFields.find((x) => x === f.name)
                          ? "number"
                          : "text"
                      }
                      label={t("client." + f.name)}
                      error={touched[f.name] && !!errors[f.name]}
                      maxLength={f.maxLen}
                    />
                  ))}
                </FormGroup>
              </>
            )}

            <hr />

            <FormGroup>
              <h2>{t("client.contact")}</h2>
              <PhoneInput
                name="mobilePhone"
                label={t("client.mobilePhone")}
                error={touched.mobilePhone && !!errors.mobilePhone}
              />
              <PhoneInput
                name="phone"
                label={t("client.phone")}
                error={touched.phone && !!errors.phone}
              />
              <Input
                disabled={isNotKeyPerson}
                name="email"
                label={t("client.email")}
                error={touched.email && !!errors.email}
                maxLength={255}
              />
              <Input
                disabled={isNotKeyPerson}
                name="dataBox"
                label={t("client.dataBox")}
                error={touched.dataBox && !!errors.dataBox}
                maxLength={100}
              />
            </FormGroup>

            {values.fullRegistration && (
              <>
                <hr />

                <FormGroup>
                  <h2>{t("client.identification")}</h2>
                  <Input
                    disabled={isNotKeyPerson}
                    key="identityType"
                    name="identityType"
                    component="select"
                    label={t("client.identityType")}
                    error={touched.identityType && !!errors.identityType}
                  >
                    <option value="">{t("client.identityTypes.null")}</option>
                    {(
                      Object.keys(ClientIdentityType) as Array<
                        keyof typeof ClientIdentityType
                      >
                    ).map((key) => (
                      <option key={key} value={ClientIdentityType[key]}>
                        {t("client.identityTypes." + ClientIdentityType[key])}
                      </option>
                    ))}
                  </Input>
                  {values.identityType &&
                    values.identityType !== ClientIdentityType.Unverified && (
                      <Input
                        disabled={isNotKeyPerson}
                        key="identityCode"
                        name="identityCode"
                        label={t("client.identityCode")}
                        error={touched.identityCode && !!errors.identityCode}
                        maxLength={100}
                      />
                    )}
                  <DatePicker name="birthDate" label={t("client.birthDate")} />
                  <Input
                    disabled={isNotKeyPerson}
                    key="identificationNumber"
                    name="identificationNumber"
                    label={t("client.identificationNumber")}
                    error={
                      touched.identificationNumber &&
                      !!errors.identificationNumber
                    }
                  />
                  <SuggestionFormik
                    nameId="healthInsuranceId"
                    nameText="healthInsuranceName"
                    label={t("client.healthInsurance")}
                    loadSuggestions={handleLoadHealthInsuranceSuggestions}
                    disabled={isNotKeyPerson}
                  />
                  <SuggestionFormik
                    nameId="keyCaregiverId"
                    nameText="keyCaregiverName"
                    label={t("client.keyCaregiver")}
                    loadSuggestions={handleLoadUserSuggestions}
                    disabled={isNotKeyPerson}
                  />
                </FormGroup>

                <hr />

                <FormGroup>
                  <h2>{t("client.showKeyCaregiverContact")}</h2>

                  <Input
                    name="showKeyCaregiverPhone"
                    type="checkbox"
                    label={t("client.showKeyCaregiverPhone")}
                    inputWidth="1.5rem"
                    inputHeight="1.5rem"
                  />

                  <Input
                    name="showKeyCaregiverEmail"
                    type="checkbox"
                    label={t("client.showKeyCaregiverEmail")}
                    inputWidth="1.5rem"
                    inputHeight="1.5rem"
                  />
                </FormGroup>

                <hr />

                <FormGroup>
                  <Input
                    disabled={isNotKeyPerson}
                    key="hasLogin"
                    name="hasLogin"
                    type="checkbox"
                    label={t(`client.hasLogin`)}
                    inputWidth="1.5rem"
                    inputHeight="1.5rem"
                  />
                  {values.hasLogin && (
                    <Input
                      key="login"
                      name="email"
                      label={t("client.login")}
                      disabled={true}
                    />
                  )}
                </FormGroup>
              </>
            )}
            {!isNotKeyPerson && <ErrorAndButtons />}
          </FormTablet>
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    client: selectClient(state),
    identityRights: selectIdentityRights(state),
  };
};

const mapDispachToProps = {
  updateClient,
  clientListLoad,
};

export default connect(mapStateToProps, mapDispachToProps)(ClientGeneral);
