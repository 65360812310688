import { Form, Formik } from "formik";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router";
import {
  DemandPriorityType,
  DemandStateType,
  IDemand,
} from "../../models/demand";
import { IApplicationState } from "../../store";
import { StoreState } from "../../store/storeState";
import {
  getDemand,
  selectDemand,
  selectDemandState,
  updateDemand,
} from "../../store/demand";
import { FormButton } from "../../styles/button";
import {
  ApiError,
  FormGroup,
  InputContainer,
  InputRow,
} from "../../styles/form";
import Input from "../common/form/Input";
import Loader from "../common/Loader";
import * as Yup from "yup";
import { demandListLoad } from "../../store/demandList";
import demandApi from "../../api/demand";
import { SpaceBetweenButtons } from "../../styles/spaces";
import { RightType } from "../../models/auth";
import { hasRight } from "../../utils/rights";
import { selectIdentityId, selectIdentityRights } from "../../store/identity";
import Page from "../layout/Page";
import SuggestionFormik from "../common/suggestion/SuggestionFormik";
import {
  handleLoadClientSuggestions,
  handleLoadGroupSuggestions,
  handleLoadUserSuggestions,
} from "../../utils/suggestions";
import FormLink from "../common/form/FormLink";
import { format } from "date-fns";
import { promiseToastSave } from "../../utils/toasts";
import SubmitForm from "../common/form/SubmitForm";
import { errorSet } from "../../utils/error";
import { DATE_TIME_FORMAT } from "../../utils/consts";
import { InputLabel } from "../common/form/InputStyles";
import { getClassByDate } from "../../utils/date";
import { WorkflowSelectedQuestions } from "../../models/workflow";
import {
  getSelectedAnswers,
  isAllQuestionsSelected,
  renderQuestions,
} from "./DemandQuestions";

interface IProps {
  demandState: StoreState;
  demand: IDemand | null;
  identityId?: number;
  identityRights?: RightType[];
  getDemand(demandId: number): void;
  updateDemand(newData: IDemand): void;
  demandListLoad(reload: boolean): void;
}

const DemandDetail: FC<IProps> = ({
  demandState,
  demand,
  identityId,
  identityRights,
  getDemand,
  updateDemand,
  demandListLoad,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { demandId } = useParams();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const returnTo = new URLSearchParams(location.search).get("return");
  const [selectedQuestions, setSelectedQuestions] =
    useState<WorkflowSelectedQuestions>({});

  useEffect(() => {
    getDemand(parseInt(demandId!));
    setLoading(false);
  }, [demandId, getDemand]);

  const handleSubmit = async (data: IDemand) => {
    setError(null);
    try {
      await promiseToastSave(async () => {
        const data2 = {
          ...data,
          assignedUserId: data.assignedUserId ? data.assignedUserId : null,
          assignedGroupId: data.assignedGroupId ? data.assignedGroupId : null,
          clientId: data.clientId ? data.clientId : null,
        };

        await demandApi.updateDemand(data2);
        demandListLoad(true);
        navigate(returnTo ?? "/demand");
      });
    } catch (err) {
      errorSet(setError, err, t);
    }
  };

  const handleCancel = () => {
    navigate(returnTo ?? "/demand");
  };

  const handleCancelDemand = async () => {
    setError(null);
    setSaving(true);
    try {
      await promiseToastSave(async () => {
        await demandApi.cancelDemand(demand!.id);
        demandListLoad(true);
        navigate(returnTo ?? "/demand");
      });
    } catch (err) {
      errorSet(setError, err, t);
    }
    setSaving(false);
  };

  const handleCloseDemand = async () => {
    setError(null);
    setSaving(true);
    try {
      await promiseToastSave(async () => {
        const answers: number[] = [];
        getSelectedAnswers(
          answers,
          demand!.questions,
          selectedQuestions,
          demand!.idWorkflowDefinitionItem
        );

        await demandApi.closeDemand(demand!.id, answers);
        demandListLoad(true);
        navigate(returnTo ?? "/demand");
      });
    } catch (err) {
      errorSet(setError, err, t);
    }
    setSaving(false);
  };

  const handleTakeDemand = async () => {
    setError(null);
    setSaving(true);
    try {
      await promiseToastSave(async () => {
        const newData = await demandApi.takeDemand(demand!.id);
        updateDemand(newData.data);
        demandListLoad(true);
      });
    } catch (err) {
      errorSet(setError, err, t);
    }
    setSaving(false);
  };

  const handleHistory = () => {
    navigate(`/demand/${demandId}/history`);
  };

  if (loading || demandState === StoreState.Loading) {
    return <Loader />;
  }

  if (demandState === StoreState.Error) {
    return t("errors.unknown");
  }

  const canCloseDemand = isAllQuestionsSelected(
    demand!.questions,
    selectedQuestions,
    demand!.idWorkflowDefinitionItem
  );

  return (
    <Page>
      <Formik<IDemand>
        initialValues={{
          ...demand!,
          description: demand!.description ?? "",
          assignedUserId: demand!.assignedUserId ?? 0,
          assignedUserName: demand!.assignedUserName ?? "",
          assignedGroupId: demand!.assignedGroupId ?? 0,
          assignedGroupName: demand!.assignedGroupName ?? "",
          clientId: demand!.clientId ?? 0,
          clientName: demand!.clientName ?? "",
        }}
        validationSchema={Yup.object().shape(
          {
            assignedUserId: Yup.number().when("assignedGroupId", {
              is: (assignedGroupId: any) => !assignedGroupId,
              then: Yup.number()
                .required(t("demand.anyOfValuesRequired"))
                .moreThan(0, t("demand.anyOfValuesRequired")),
            }),
            assignedGroupId: Yup.number().when("assignedUserId", {
              is: (assignedUserId: any) => !assignedUserId,
              then: Yup.number()
                .required(t("demand.anyOfValuesRequired"))
                .moreThan(0, t("demand.anyOfValuesRequired")),
            }),
          },
          [["assignedUserId", "assignedGroupId"]]
        )}
        validateOnMount={true}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, values }) => (
          <Form>
            {(demand!.state === DemandStateType.New &&
              !!demand!.assignedUserId && (
                <h1>{t("demand.detailTitleNew")}</h1>
              )) ||
              (demand!.state === DemandStateType.New &&
                !demand!.assignedUserId && (
                  <h1>{t("demand.detailTitleUnassigned")}</h1>
                )) ||
              (demand!.state === DemandStateType.Finished && (
                <h1>{t("demand.detailTitleFinished")}</h1>
              )) ||
              (demand!.state === DemandStateType.Canceled && (
                <h1>{t("demand.detailTitleCanceled")}</h1>
              ))}

            <FormGroup>
              <Input name="name" label={t("demand.name")} disabled={true} />
              <Input
                name="description"
                label={t("demand.description")}
                maxLength={128}
              />
              <SuggestionFormik
                nameId="clientId"
                nameText="clientName"
                label={t("demand.client")}
                disabled={true}
                placeholder={t("common.suggestionNoSelected")}
                textAfter={
                  values.clientId &&
                  hasRight(identityRights, [
                    RightType.ReadAllClients,
                    RightType.ReadMyClients,
                    RightType.ReadClientsInTheSameAssistance,
                  ]) ? (
                    <FormLink to={`/client/${values.clientId}/general`} />
                  ) : undefined
                }
                loadSuggestions={handleLoadClientSuggestions}
              />
              <Input
                key="priority"
                name="priority"
                component="select"
                label={t("demand.priority")}
                disabled={true}
              >
                {(
                  Object.keys(DemandPriorityType) as Array<
                    keyof typeof DemandPriorityType
                  >
                ).map((key) => (
                  <option key={key} value={DemandPriorityType[key]}>
                    {t("demand.priorities." + DemandPriorityType[key])}
                  </option>
                ))}
              </Input>
              <InputRow>
                <InputLabel>{t("demand.deadLine")}</InputLabel>
                <InputContainer>
                  <span className={getClassByDate(demand!.deadLine)}>
                    {format(demand!.deadLine, DATE_TIME_FORMAT)}
                  </span>
                </InputContainer>
              </InputRow>
              <SuggestionFormik
                nameId="assignedUserId"
                nameText="assignedUserName"
                label={t("demand.assignedUser")}
                placeholder={
                  values.canChangeAssignedUser
                    ? undefined
                    : t("common.suggestionNoSelected")
                }
                disabled={!values.canChangeAssignedUser}
                loadSuggestions={handleLoadUserSuggestions}
              />
              <SuggestionFormik
                nameId="assignedGroupId"
                nameText="assignedGroupName"
                label={t("demand.assignedGroup")}
                placeholder={
                  values.canChangeAssignedGroup
                    ? undefined
                    : t("common.suggestionNoSelected")
                }
                disabled={!values.canChangeAssignedGroup}
                loadSuggestions={handleLoadGroupSuggestions}
              />
              {demand!.state === DemandStateType.New &&
                demand!.assignedUserId === identityId &&
                renderQuestions(
                  demand!.questions,
                  selectedQuestions,
                  setSelectedQuestions,
                  demand!.idWorkflowDefinitionItem
                )}
            </FormGroup>
            {error && <ApiError>{error}</ApiError>}
            <FormButton
              ver="secondary"
              disabled={isSubmitting || saving}
              onClick={handleCancel}
            >
              {t("common.back")}
            </FormButton>
            {demand!.state === DemandStateType.New &&
              hasRight(identityRights, [RightType.WriteDemand]) && (
                <>
                  <SpaceBetweenButtons />
                  <SubmitForm disabled={saving} />
                </>
              )}
            {demand?.canTake && (
              <>
                <SpaceBetweenButtons />
                <FormButton
                  type="button"
                  disabled={isSubmitting || saving}
                  onClick={handleTakeDemand}
                >
                  {t("demand.take")}
                </FormButton>
              </>
            )}
            {demand!.state === DemandStateType.New &&
              demand!.assignedUserId === identityId && (
                <>
                  <SpaceBetweenButtons />
                  <FormButton
                    ver="secondary"
                    type="button"
                    disabled={isSubmitting || saving}
                    onClick={handleCancelDemand}
                  >
                    {t("demand.cancel")}
                  </FormButton>
                  <SpaceBetweenButtons />
                  <FormButton
                    type="button"
                    disabled={isSubmitting || saving || !canCloseDemand}
                    onClick={handleCloseDemand}
                  >
                    {t("demand.close")}
                  </FormButton>
                </>
              )}
            <SpaceBetweenButtons />
            <FormButton
              ver="secondary"
              type="button"
              disabled={isSubmitting || saving}
              onClick={handleHistory}
            >
              {t("demand.history")}
            </FormButton>
          </Form>
        )}
      </Formik>
    </Page>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    demandState: selectDemandState(state),
    demand: selectDemand(state),
    identityId: selectIdentityId(state),
    identityRights: selectIdentityRights(state),
  };
};

const mapDispachToProps = {
  getDemand,
  updateDemand,
  demandListLoad,
};

export default connect(mapStateToProps, mapDispachToProps)(DemandDetail);
