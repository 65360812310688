import { createReducer } from "@reduxjs/toolkit";
import { StoreState } from "../storeState";
import { IChangeDemandState } from "./types";
import * as actions from "./actions";

const initialState = {
  data: null,
  state: StoreState.None,
} as IChangeDemandState;

export const changeDemandReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(actions.changeDemandsFetchRequest, (state) => ({
      ...state,
      state: StoreState.Loading,
    }))
    .addCase(actions.changeDemandsFetchSuccess, (state, action) => ({
      ...state,
      state: StoreState.Loaded,
      data: action.payload,
    }))
    .addCase(actions.changeDemandsFetchError, (state) => ({
      ...state,
      state: StoreState.Error,
    }))
);
