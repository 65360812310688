import { DemandStateFilterType, IDemandItem } from "../../models/demand";
import { IGroupItem } from "../../models/group";
import { IUser } from "../../models/user";
import { IFilterType } from "../filterType";
import { IListType } from "../listType";

export enum DemandListTypes {
  DEMAND_LIST_FETCH_REQUEST = "@@demandList/FETCH_REQUEST",
  DEMAND_LIST_FETCH_SUCCESS = "@@demandList/FETCH_SUCCESS",
  DEMAND_LIST_FETCH_ERROR = "@@demandList/FETCH_ERROR",
  DEMAND_LIST_COUNT_REQUEST = "@@demandList/COUNT_REQUEST",
  DEMAND_LIST_COUNT_SUCCESS = "@@demandList/COUNT_SUCCESS",
  DEMAND_LIST_COUNT_ERROR = "@@demandList/COUNT_ERROR",
  DEMAND_LIST_CHANGE_ORDER = "@@demandList/CHANGE_ORDER",
  DEMAND_LIST_CHANGE_PAGE = "@@demandList/CHANGE_PAGE",
  DEMAND_LIST_CHANGE_FILTER = "@@demandList/CHANGE_FILTER",
  DEMAND_LIST_RESET = "@@demandList/RESET",
  DEMAND_LIST_LOAD = "@@demandList/LOAD",
}

export interface IDemandFilterType extends IFilterType {
  from?: Date;
  to?: Date;
  state?: DemandStateFilterType;
  groups: IGroupItem[];
  users: IUser[];
  creators: IUser[];
  clientId?: number;
  clientName?: string;
}

export interface IDemandListState
  extends IListType<IDemandItem, IDemandFilterType> {}
