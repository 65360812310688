import { createReducer } from "@reduxjs/toolkit";
import { ITemplateTreeState } from "./types";
import * as actions from "./actions";
import { StoreState } from "../storeState";
import { STORAGE_RESET } from "../storeReset";

const initialState = {
  items: {},
} as ITemplateTreeState;

export const templateTreeReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(actions.templateTreeFetchRequest, (state, action) => ({
      ...state,
      items: {
        ...state.items,
        [action.payload]: {
          state: StoreState.Loading,
          items: [],
        },
      },
    }))
    .addCase(actions.templateTreeFetchSuccess, (state, action) => ({
      ...state,
      items: {
        ...state.items,
        [action.payload.id]: {
          state: StoreState.Loaded,
          items: action.payload.data,
        },
      },
    }))
    .addCase(actions.templateTreeFetchError, (state, action) => ({
      ...state,
      items: {
        ...state.items,
        [action.payload]: {
          state: StoreState.Error,
          items: [],
        },
      },
    }))
    .addCase(actions.templateTreeReset, () => initialState)
    .addCase(STORAGE_RESET, () => initialState)
);
