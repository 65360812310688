import { CancelToken } from "axios";
import { ISearchFilter } from ".";
import { AppThunkActionAsync, AppThunkDispatch } from "..";
import searchApi from "../../api/search";
import { ISearchFoundItem, SearchInputType } from "../../models/search";
import { SEARCH_SIZE } from "../../utils/consts";
import * as actions from "./actions";

export function getSearchResults(
  filter: ISearchFilter,
  cancelToken: CancelToken
): AppThunkActionAsync {
  return async (dispatch) => {
    dispatch(actions.searchFetchRequest(filter));
    try {
      let data = [] as ISearchFoundItem[];
      data = await loadData(
        filter.text,
        filter.selClient,
        SearchInputType.Client,
        dispatch,
        data,
        cancelToken
      );
      data = await loadData(
        filter.text,
        filter.selClientContactPerson,
        SearchInputType.ClientContactPerson,
        dispatch,
        data,
        cancelToken
      );
      data = await loadData(
        filter.text,
        filter.selUser,
        SearchInputType.User,
        dispatch,
        data,
        cancelToken
      );
      data = await loadData(
        filter.text,
        filter.selGroup,
        SearchInputType.Group,
        dispatch,
        data,
        cancelToken
      );
      data = await loadData(
        filter.text,
        filter.selAssistance,
        SearchInputType.Assistance,
        dispatch,
        data,
        cancelToken
      );
      data = await loadData(
        filter.text,
        filter.selAssistanceContactPerson,
        SearchInputType.AssistanceContactPerson,
        dispatch,
        data,
        cancelToken
      );
      data = await loadData(
        filter.text,
        filter.selListing,
        SearchInputType.Listing,
        dispatch,
        data,
        cancelToken
      );
      data = await loadData(
        filter.text,
        filter.selDocument,
        SearchInputType.Document,
        dispatch,
        data,
        cancelToken
      );
      await loadData(
        filter.text,
        filter.selDocumentContent,
        SearchInputType.DocumentContent,
        dispatch,
        data,
        cancelToken
      );

      dispatch(actions.searchFetchSuccess());
    } catch {
      dispatch(actions.searchFetchError(cancelToken.reason !== undefined));
    }
  };
}

const loadData = async (
  text: string,
  filterSelected: boolean,
  type: SearchInputType,
  dispatch: AppThunkDispatch,
  data: ISearchFoundItem[],
  cancelToken: CancelToken
): Promise<ISearchFoundItem[]> => {
  if (!filterSelected) {
    return data;
  }

  cancelToken.throwIfRequested();

  const params = new URLSearchParams();
  params.append("search", text);
  params.append("count", SEARCH_SIZE.toString());
  params.append(type, "true");

  var newData = await searchApi.getSearch({ params, cancelToken });
  data = data.concat(newData.data);

  cancelToken.throwIfRequested();

  dispatch(actions.searchFetchPartialSuccess(data));
  return data;
};
