import * as ReselectModule from "reselect";
import { IApplicationState } from "..";
import { IDashboardLink } from "../../models/dashboard";
import { StoreState } from "../storeState";

export const selectDashboardLinkState: ReselectModule.Selector<
  IApplicationState,
  StoreState
> = (state) => state.dashboardLink.state;

export const selectDashboardLink: ReselectModule.Selector<
  IApplicationState,
  IDashboardLink[]
> = (state) => state.dashboardLink.data;
