import { AppThunkAction, AppThunkActionAsync } from "..";
import * as actions from "./actions";
import notificationApi from "../../api/notification";
import { NotificationType } from "../../models/notification";

export function getNotification(notificationId: number): AppThunkActionAsync {
  return async (dispatch) => {
    dispatch(actions.notificationFetchRequest());
    try {
      const notification = await notificationApi.getNotification(
        notificationId
      );
      dispatch(actions.notificationFetchSuccess(notification.data));
    } catch {
      dispatch(actions.notificationFetchError());
    }
  };
}

export function getDefaultNotification(): AppThunkAction {
  return (dispatch) => {
    const data = {
      id: 0,
      isActive: true,
      name: "",
      type: NotificationType.Standard,

      viaEmail: false,

      viaSms: false,
    };

    dispatch(actions.notificationFetchSuccess(data));
  };
}
