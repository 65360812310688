import { format } from "date-fns";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import { CalendarItemType, ICalendarItem } from "../../models/calendar";
import { Button } from "../../styles/button";
import { DATE_TIME_FORMAT } from "../../utils/consts";
import { Table } from "../common/grid/table";
import { TableCol } from "../common/grid/TableCol";
import CustomModal from "../common/modal/CustomModal";

export interface ICalendarDetailModalData {
  type: CalendarItemType;
  item: ICalendarItem;
}

export interface IProps {
  data: ICalendarDetailModalData | null;
  close(): void;
}

const CalendarDetailModal: FC<IProps> = ({ data, close }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const rows = useMemo(
    () =>
      data?.item.data.map((item, i) => (
        <tr key={i}>
          <TableCol>{item.userName}</TableCol>
          <TableCol>
            {item.actionId && (
              <NavLink
                to={`/calendar/list/${item.actionId}?return=${location.pathname}`}
              >
                {item.actionName}
              </NavLink>
            )}
          </TableCol>
        </tr>
      )),
    [data, location.pathname]
  );

  return (
    <CustomModal
      title={t("calendar.detailModal.title")}
      isOpen={!!data}
      close={close}
      actions={
        <>
          <Button ver="secondary" onClick={() => close()}>
            {t("common.close")}
          </Button>
        </>
      }
    >
      {data && (
        <>
          <h2>{format(data.item.datetime, DATE_TIME_FORMAT)}</h2>
          <Table checkbox={false} buttons={false}>
            <thead>
              <tr>
                <th>{t("calendar.detailModal.user")}</th>
                <th>{t("calendar.detailModal.action")}</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
        </>
      )}
    </CustomModal>
  );
};

export default CalendarDetailModal;
