import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { AssistanceStateType } from "../../models/assistance";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TFunction } from "i18next";

// todo colors
export const getAssistanceStateColor = (
  assistanceState: AssistanceStateType
) => {
  switch (assistanceState) {
    case AssistanceStateType.New:
      return "#FDDE62";
    case AssistanceStateType.Active:
      return "#61BD5B";
    case AssistanceStateType.InActive:
      return "#CDCFD3";
  }
};

export const getAssistanceStateIcon = (
  assistanceState: AssistanceStateType,
  t: TFunction
) => (
  <FontAwesomeIcon
    icon={faCircle}
    color={getAssistanceStateColor(assistanceState)}
    title={t("client.states." + assistanceState)}
  />
);
