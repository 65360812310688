import { FC } from "react";
import { GridIconCancel, GridIconSave, GridIconSpace } from "./GridIcons";
import { TableCol } from "./TableCol";

export const GridInlineButtons: FC<{
  handleCancel(): void;
  isSubmitting: boolean;
  handleSubmit(): void;
}> = ({ handleCancel, isSubmitting, handleSubmit }) => {
  return (
    <TableCol>
      <GridIconSave disabled={isSubmitting} onClick={handleSubmit} />
      <GridIconSpace />
      <GridIconCancel onClick={handleCancel} />
    </TableCol>
  );
};
