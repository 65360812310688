import { IItemIdName } from "./common";

export enum LogType {
  DocumentChange = "documentChange",
  Event = "event",
  SeachForClient = "searchForClient",
  EntityCreated = "entityCreated",
  EntityChange = "entityChange",
  EntityDeleted = "entityDeleted",
  EntityArrayUpdated = "entityArrayUpdated",
}

export interface ILogItem {
  id: number;
  type: LogType;
  dateTime: Date;
  entityName: string;
  changes?: string | null;
  user?: IItemIdName | null;
  client?: IItemIdName | null;
}
