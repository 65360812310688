import { CancelToken } from "axios";
import { FC, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { IApplicationState } from "../../store";
import { IFilterType } from "../../store/filterType";
import {
  changeClientRightUserListFilter,
  changeClientRightUserListOrder,
  changeClientRightUserListPage,
  getClientRightUserList,
  getClientRightUserListCount,
  IClientRightUserListState,
  selectClientRightUserList,
  clientRightUserListLoad,
} from "../../store/clientRightUserList";
import FilterPanel from "../common/filter/FilterPanel";
import Grid from "../common/grid/Grid";
import { GridIconAdd, GridIconRemove } from "../common/grid/GridIcons";
import { TableCol, TableColInput } from "../common/grid/TableCol";
import { RightType } from "../../models/auth";
import { hasClientWriteRight } from "../../utils/rights";
import { selectIdentityRights } from "../../store/identity";
import { selectClient } from "../../store/client";
import { Form, Formik, useFormikContext } from "formik";
import { GridInlineButtons } from "../common/grid/GridInline";
import * as Yup from "yup";
import clientApi from "../../api/client";
import validations from "../../utils/validations";
import SuggestionFormik from "../common/suggestion/SuggestionFormik";
import { handleLoadUserSuggestions } from "../../utils/suggestions";
import { IItemIdName } from "../../models/common";
import { promiseToastSaveNoException } from "../../utils/toasts";
import { IClient } from "../../models/client";

const defaultData = {
  id: 0,
  name: "",
} as IItemIdName;

interface IProps {
  prov: IClientRightUserListState;
  client: IClient | null;
  identityRights?: RightType[];
  getClientRightUserList(clientId: number, cancelToken: CancelToken): void;
  getClientRightUserListCount(clientId: number, cancelToken: CancelToken): void;
  changeClientRightUserListOrder(orderBy: string, orderDesc: boolean): void;
  changeClientRightUserListPage(page: number): void;
  changeClientRightUserListFilter(filter: IFilterType): void;
  clientRightUserListLoad(reload: boolean): void;
}

const ClientRightUserGridEditor: FC<{
  data: IItemIdName;
  handleCancel(): void;
}> = ({ data, handleCancel }) => {
  const { isSubmitting, handleSubmit, resetForm, setFieldValue } =
    useFormikContext<IItemIdName>();

  useEffect(() => {
    resetForm();
    setFieldValue("id", data.id);
    setFieldValue("name", data.name);
  }, [data, resetForm, setFieldValue]);

  return (
    <>
      <TableColInput>
        <SuggestionFormik
          nameId="id"
          nameText="name"
          loadSuggestions={handleLoadUserSuggestions}
          autoFocus={true}
        />
      </TableColInput>
      <GridInlineButtons
        handleCancel={handleCancel}
        isSubmitting={isSubmitting}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

const ClientRightUser: FC<IProps> = ({
  prov,
  client,
  identityRights,
  getClientRightUserList,
  getClientRightUserListCount,
  changeClientRightUserListOrder,
  changeClientRightUserListPage,
  changeClientRightUserListFilter,
  clientRightUserListLoad,
}) => {
  const { t } = useTranslation();
  const [inlineAdd, setInlineAdd] = useState(false);

  useEffect(() => {
    clientRightUserListLoad(false);
  }, [clientRightUserListLoad]);

  const handleCancel = () => {
    setInlineAdd(false);
  };

  const handleAdd = () => {
    setInlineAdd(true);
  };

  const handleDelete = async (userId: number) => {
    await clientApi.deleteClientRightUser(client!.id, userId);
    clientRightUserListLoad(true);
  };

  const handleRenderData = (item: IItemIdName): ReactElement => {
    return (
      <>
        <TableCol>{item.name}</TableCol>
        <TableCol>
          {!inlineAdd &&
            item.id !== client!.keyCaregiver?.id &&
            hasClientWriteRight(identityRights, [
              RightType.WriteClientsRights,
            ]) && <GridIconRemove onClick={() => handleDelete(item.id)} />}
        </TableCol>
      </>
    );
  };

  const handleSubmit = async (data: IItemIdName) => {
    await promiseToastSaveNoException(async () => {
      await clientApi.createClientRightUser(client!.id, data);
      clientRightUserListLoad(true);
      handleCancel();
    });
  };

  const handleRenderEditor = (item: IItemIdName): ReactElement => {
    return (
      <ClientRightUserGridEditor data={item} handleCancel={handleCancel} />
    );
  };

  const handleGetData = (cancelToken: CancelToken) =>
    getClientRightUserList(client!.id, cancelToken);

  const handleGetCount = (cancelToken: CancelToken) =>
    getClientRightUserListCount(client!.id, cancelToken);

  return (
    <>
      <FilterPanel
        viewFiltration={!inlineAdd}
        title={t("client.right.user.title")}
        name="clientRightUser"
        filter={prov.filter!}
        changeFilter={changeClientRightUserListFilter}
      />

      <Formik
        initialValues={defaultData}
        validationSchema={Yup.object({
          id: validations.idRequired(t),
        })}
        validateOnMount={true}
        onSubmit={handleSubmit}
      >
        <Form>
          <Grid<IItemIdName>
            headers={[
              { captionStr: "client.right.user.name" },
              {
                captionEl:
                  !inlineAdd &&
                  hasClientWriteRight(identityRights, [
                    RightType.WriteClientsRights,
                  ]) ? (
                    <GridIconAdd onClick={handleAdd} />
                  ) : undefined,
              },
            ]}
            inlineAdd={inlineAdd}
            defaultData={defaultData}
            renderData={handleRenderData}
            renderEditor={handleRenderEditor}
            getData={handleGetData}
            getCount={handleGetCount}
            changeOrder={changeClientRightUserListOrder}
            changePage={changeClientRightUserListPage}
            prov={prov}
          />
        </Form>
      </Formik>
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    prov: selectClientRightUserList(state),
    client: selectClient(state),
    identityRights: selectIdentityRights(state),
  };
};

const mapDispachToProps = {
  getClientRightUserList,
  getClientRightUserListCount,
  changeClientRightUserListOrder,
  changeClientRightUserListPage,
  changeClientRightUserListFilter,
  clientRightUserListLoad,
};

export default connect(mapStateToProps, mapDispachToProps)(ClientRightUser);
