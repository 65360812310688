import { CancelToken } from "axios";
import { Form, Formik, useFormikContext } from "formik";
import { FC, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { IHealthInsuranceItem } from "../../models/healthInsurance";
import { IApplicationState } from "../../store";
import { IFilterType } from "../../store/filterType";
import {
  changeHealthInsuranceListFilter,
  changeHealthInsuranceListOrder,
  changeHealthInsuranceListPage,
  getHealthInsuranceList,
  getHealthInsuranceListCount,
  selectHealthInsuranceList,
  healthInsuranceListLoad,
  IHealthInsuranceListState,
} from "../../store/healthInsuranceList";
import FilterPanel from "../common/filter/FilterPanel";
import Grid from "../common/grid/Grid";
import {
  GridIconAdd,
  GridIconEdit,
  GridIconIsActive,
} from "../common/grid/GridIcons";
import {
  TableCol,
  TableColCenter,
  TableColInput,
} from "../common/grid/TableCol";
import * as Yup from "yup";
import Input from "../common/form/Input";
import { GridInlineButtons } from "../common/grid/GridInline";
import healthInsuranceApi from "../../api/healthInsurance";
import validations from "../../utils/validations";
import { hasRight } from "../../utils/rights";
import { RightType } from "../../models/auth";
import { selectIdentityRights } from "../../store/identity";
import { promiseToastSaveNoException } from "../../utils/toasts";

const defaultData = {
  id: 0,
  isActive: true,
  code: "",
  name: "",
} as IHealthInsuranceItem;

interface IProps {
  prov: IHealthInsuranceListState;
  identityRights?: RightType[];
  getHealthInsuranceList(cancelToken: CancelToken): void;
  getHealthInsuranceListCount(cancelToken: CancelToken): void;
  changeHealthInsuranceListOrder(orderBy: string, orderDesc: boolean): void;
  changeHealthInsuranceListPage(page: number): void;
  changeHealthInsuranceListFilter(filter: IFilterType): void;
  healthInsuranceListLoad(reload: boolean): void;
}

const AdminHealthInsuranceHealthInsuranceGridEditor: FC<{
  data: IHealthInsuranceItem;
  handleCancel(): void;
}> = ({ data, handleCancel }) => {
  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    resetForm,
    setFieldValue,
  } = useFormikContext<IHealthInsuranceItem>();

  useEffect(() => {
    resetForm();
    setFieldValue("isActive", data.isActive);
    setFieldValue("code", data.code);
    setFieldValue("name", data.name);
  }, [data, resetForm, setFieldValue]);

  return (
    <>
      <TableColCenter>
        <Input
          name="isActive"
          type="checkbox"
          inputWidth="1.5rem"
          inputHeight="1.5rem"
        />
      </TableColCenter>
      <TableColInput>
        <Input
          name="code"
          error={touched.code && !!errors.code}
          inputWidth="4rem"
          maxLength={3}
        />
      </TableColInput>
      <TableColInput>
        <Input
          name="name"
          error={touched.name && !!errors.name}
          maxLength={255}
        />
      </TableColInput>
      <GridInlineButtons
        handleCancel={handleCancel}
        isSubmitting={isSubmitting}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

const AdminHealthInsuranceHealthInsuranceGrid: FC<IProps> = ({
  prov,
  identityRights,
  getHealthInsuranceList,
  getHealthInsuranceListCount,
  changeHealthInsuranceListOrder,
  changeHealthInsuranceListPage,
  changeHealthInsuranceListFilter,
  healthInsuranceListLoad,
}) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const [inlineEditId, setInlineEditId] = useState<number | undefined>();
  const [inlineAdd, setInlineAdd] = useState(false);

  useEffect(() => {
    healthInsuranceListLoad(false);
  }, [healthInsuranceListLoad]);

  const handleEdit = (id: number) => {
    setInlineAdd(false);
    setInlineEditId(id);
  };

  const handleCancel = () => {
    setInlineEditId(undefined);
    setInlineAdd(false);
  };

  const handleAdd = () => {
    setInlineEditId(undefined);
    setInlineAdd(true);
  };

  const handleRenderData = (item: IHealthInsuranceItem): ReactElement => {
    return (
      <>
        <TableColCenter>{item.isActive && <GridIconIsActive />}</TableColCenter>
        <TableCol>{item.code}</TableCol>
        <TableCol>{item.name}</TableCol>
        <TableCol>
          {!inlineEditId &&
            !inlineAdd &&
            hasRight(identityRights, [RightType.WriteHealthInsurance]) && (
              <GridIconEdit onClick={() => handleEdit(item.id)} />
            )}
        </TableCol>
      </>
    );
  };

  const handleSubmit = async (data: IHealthInsuranceItem) => {
    await promiseToastSaveNoException(async () => {
      if (inlineEditId) {
        await healthInsuranceApi.updateHealthInsurance(inlineEditId, data);
      } else {
        await healthInsuranceApi.addHealthInsurance(data);
      }

      healthInsuranceListLoad(true);
      handleCancel();
    });
  };

  const handleRenderEditor = (item: IHealthInsuranceItem): ReactElement => {
    return (
      <AdminHealthInsuranceHealthInsuranceGridEditor
        data={item}
        handleCancel={handleCancel}
      />
    );
  };

  return (
    <>
      <FilterPanel
        viewFiltration={!inlineEditId && !inlineAdd}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        title={t("admin.listing.healthInsurance")}
        name={"adminListingHealthInsurance"}
        filter={prov.filter!}
        changeFilter={changeHealthInsuranceListFilter}
      />
      {isExpanded && (
        <Formik<IHealthInsuranceItem>
          initialValues={defaultData}
          validationSchema={Yup.object({
            code: validations.stringRequired(t),
            name: validations.stringRequired(t),
          })}
          validateOnMount={true}
          onSubmit={handleSubmit}
        >
          <Form>
            <Grid<IHealthInsuranceItem>
              headers={[
                { captionStr: "admin.listing.isActive", minContent: true },
                { captionStr: "admin.listing.code" },
                { captionStr: "admin.listing.name" },
                {
                  captionEl:
                    !inlineEditId &&
                    !inlineAdd &&
                    hasRight(identityRights, [
                      RightType.WriteHealthInsurance,
                    ]) ? (
                      <GridIconAdd onClick={handleAdd} />
                    ) : undefined,
                },
              ]}
              inlineEditId={inlineEditId}
              inlineAdd={inlineAdd}
              defaultData={defaultData}
              renderData={handleRenderData}
              renderEditor={handleRenderEditor}
              getData={getHealthInsuranceList}
              getCount={getHealthInsuranceListCount}
              changeOrder={changeHealthInsuranceListOrder}
              changePage={changeHealthInsuranceListPage}
              prov={prov}
            />
          </Form>
        </Formik>
      )}
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    prov: selectHealthInsuranceList(state),
    identityRights: selectIdentityRights(state),
  };
};

const mapDispachToProps = {
  getHealthInsuranceList,
  getHealthInsuranceListCount,
  changeHealthInsuranceListOrder,
  changeHealthInsuranceListPage,
  changeHealthInsuranceListFilter,
  healthInsuranceListLoad,
};

export default connect(
  mapStateToProps,
  mapDispachToProps
)(AdminHealthInsuranceHealthInsuranceGrid);
