import { AppThunkAction, AppThunkActionAsync } from "..";
import * as actions from "./actions";
import clientApi from "../../api/client";
import { ClientCareStateType } from "../../models/client";
import { selectIdentityId, selectIdentityName } from "../identity";

export function getClientCare(clientCareId: number): AppThunkActionAsync {
  return async (dispatch) => {
    dispatch(actions.clientCareFetchRequest());
    try {
      const clientCare = await clientApi.getClientCare(clientCareId);
      dispatch(actions.clientCareFetchSuccess(clientCare.data));
    } catch {
      dispatch(actions.clientCareFetchError());
    }
  };
}

export function getDefaultClientCare(): AppThunkAction {
  return (dispatch, getState) => {
    const state = getState();
    const stateIdentityId = selectIdentityId(state);
    const stateIdentityName = selectIdentityName(state);

    const data = {
      id: 0,
      client: { id: 0, name: "" },
      assistance: { id: 0, name: "" },
      assistanceAddress: { id: 0, name: "" },
      assistanceCategoryListing: { id: 0, name: "" },
      careType: { id: 0, name: "" },
      user: { id: stateIdentityId!, name: stateIdentityName! },
      from: new Date(),
      state: ClientCareStateType.Preparation,
    };

    dispatch(actions.clientCareFetchSuccess(data));
  };
}
