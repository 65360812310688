import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { SIZES } from "../../styles/sizes";

//todo: colors!

export const SettingDashboardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;

  @media screen and (max-width: ${SIZES.tablet}) {
    grid-template-columns: 1fr;
  }
`;

export const SettingDashboardColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: auto;
  > h2 {
    margin: 1rem 0;
  }
`;

export const SettingsDashboardItemContainer = styled.div`
  display: flex;
  align-content: center;
  gap: 0.5rem;
`;

export const SettingsDashboardLinkContainer = styled.div`
  display: grid;
  grid-template-columns: 2rem 1fr auto;
  border-radius: 5px;
  min-height: 2.5rem;
  width: 100%;
  max-width: 25rem;
  padding: 0.5rem 1rem;
  margin: 0.4rem 0;
  align-items: center;
  color: white;
  background-color: #0e2a5e;
  @media screen and (max-width: ${SIZES.tablet}) {
    max-width: none;
  }
`;

export const SettingsDashboardLinkNewContainer = styled(
  SettingsDashboardLinkContainer
)`
  background-color: white;
  color: black;
  border: 1px solid;
  width: calc(100% - 1.75rem);
  .hidden {
    visibility: hidden;
  }
  @media screen and (max-width: ${SIZES.tablet}) {
    margin-left: 1.75rem;
  }
`;

export const SettingsDashboardLinkArrowContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  .hidden {
    visibility: hidden;
  }
`;

export const SettingsDashboardLinkArrow = styled(FontAwesomeIcon)`
  cursor: pointer;
  width: 1.25rem !important;
  height: 1.25rem !important;
  :hover {
    color: #4cc2f1 !important;
  }
`;

export const SettingsDashboardLinkAddDelete = styled(FontAwesomeIcon)`
  :hover {
    cursor: pointer;
    color: #4cc2f1 !important;
  }
`;
