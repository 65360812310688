import { FC } from "react";
import { VerticalSpace } from "../../styles/spaces";
import ClientRightGroup from "./ClientRightGroup";
import ClientRightUser from "./ClientRightUser";

const ClientRight: FC = () => {
  return (
    <>
      <ClientRightGroup />
      <VerticalSpace />
      <ClientRightUser />
    </>
  );
};

export default ClientRight;
