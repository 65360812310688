export enum NotificationType {
  Standard = "standard",
  Gdpr = "gdpr",
}

export interface INotificationItem {
  id: number;
  isActive: boolean;
  name: string;
}

export interface INotification {
  id: number;
  isActive: boolean;
  name: string;
  type: NotificationType;

  viaEmail: boolean;
  emailText?: string | null;

  viaSms: boolean;
  smsText?: string | null;
}
