import { FC, ReactNode } from "react";
import { connect } from "react-redux";
import { RightType } from "../../models/auth";
import { IApplicationState } from "../../store";
import { selectIdentityRights } from "../../store/identity";
import { hasRight } from "../../utils/rights";
import storageApi from "../../utils/storage";
import Dashboard from "../dashboard/Dashboard";
import Login from "./Login";

interface IProps {
  rights?: RightType[];
  identityRights?: RightType[];
  children?: ReactNode;
}

const AuthRoute: FC<IProps> = ({ rights, identityRights, children }) => {
  const tokenIsValid = storageApi.getTokenIsValid();

  if (!tokenIsValid) {
    return <Login />;
  }

  if (!hasRight(identityRights, rights)) {
    return <Dashboard />;
  }

  return <>{children}</>;
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    identityRights: selectIdentityRights(state),
  };
};

export default connect(mapStateToProps)(AuthRoute);
