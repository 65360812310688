import { FC } from "react";
import { TableCol } from "../common/grid/TableCol";
import { format, formatISO, startOfDay } from "date-fns";
import {
  DATE_TIME_FORMAT,
  DASHBOARD_SIZE,
  TIME_FORMAT,
} from "../../utils/consts";
import calendarApi from "../../api/calendar";
import { IActionItem } from "../../models/calendar";
import { CancelToken } from "axios";
import { IApplicationState } from "../../store";
import { selectIdentityId } from "../../store/identity";
import { connect } from "react-redux";
import DashboardWidgetComponent from "./DashboardWidgetComponent";
import { useNavigate } from "react-router";

interface IProps {
  identityId?: number;
}

const DashboardWidgetCalendarList: FC<IProps> = ({ identityId }) => {
  const navigate = useNavigate();

  const handleGetData = async (cancelToken: CancelToken) => {
    const params = new URLSearchParams();
    params.append("take", (DASHBOARD_SIZE + 1).toString());
    params.append("users", identityId!.toString());
    params.append("from", formatISO(startOfDay(new Date())));

    const data = await calendarApi.getActionList({
      params,
      cancelToken,
    });

    if (data.data.length > DASHBOARD_SIZE) {
      const count = await calendarApi.getActionCount({ params, cancelToken });

      return {
        items: data.data.slice(0, DASHBOARD_SIZE),
        count: count.data,
      };
    }

    return {
      items: data.data,
      count: data.data.length,
    };
  };

  const handleRenderData = (item: IActionItem) => {
    return (
      <>
        <TableCol>
          {format(item.from, DATE_TIME_FORMAT)}
          {" - "}
          {format(item.to, TIME_FORMAT)}
        </TableCol>
        <TableCol>{item.type?.name}</TableCol>
        <TableCol>{item.client?.name}</TableCol>
      </>
    );
  };

  const handleRowClick = (item: IActionItem) => {
    navigate(`/calendar/list/${item.id}?return=/`);
  };

  const handleCountClick = () => {
    navigate("/calendar/list");
  };

  return (
    <DashboardWidgetComponent<IActionItem>
      title={"dashboard.widgetCalendarList.title"}
      titleColor={"var(--dashboard-widget-calendar-list-title-bg)"}
      headers={[]}
      hasCount={true}
      renderData={handleRenderData}
      getData={handleGetData}
      onRowClick={handleRowClick}
      onCountClick={handleCountClick}
    />
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    identityId: selectIdentityId(state),
  };
};

export default connect(mapStateToProps)(DashboardWidgetCalendarList);
