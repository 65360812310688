import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { WorkflowType } from "../../models/workflow";
import { IApplicationState } from "../../store";
import { StoreState } from "../../store/storeState";
import {
  getWorkflowUnsetTypes,
  selectWorkflowUnsetTypes,
  selectWorkflowUnsetTypesState,
} from "../../store/workflowUnsetTypes";
import { ApiError } from "../../styles/form";
import Loader from "../common/Loader";
import { UnsetTypesList } from "./ModuleWorkflowStyles";

interface IProps {
  state: StoreState;
  unsetTypes: WorkflowType[];
  getWorkflowUnsetTypes(): void;
}

const ModuleWorkflowUnsetTypes: FC<IProps> = ({
  state,
  unsetTypes,
  getWorkflowUnsetTypes,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getWorkflowUnsetTypes();
    setLoading(false);
  }, [getWorkflowUnsetTypes]);

  if (loading || state === StoreState.Loading || state === StoreState.None) {
    return <Loader />;
  }

  if (state !== StoreState.Loaded) {
    return <ApiError>{t("errors.unknown")}</ApiError>;
  }

  if (unsetTypes.length === 0) {
    return null;
  }

  return (
    <ApiError>
      {t("module.workflow.unsetTypes")}
      <UnsetTypesList>
        {unsetTypes.map((x, i) => (
          <li key={i}>{t("module.workflow.types." + x)}</li>
        ))}
      </UnsetTypesList>
    </ApiError>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    state: selectWorkflowUnsetTypesState(state),
    unsetTypes: selectWorkflowUnsetTypes(state),
  };
};

const mapDispachToProps = {
  getWorkflowUnsetTypes,
};

export default connect(
  mapStateToProps,
  mapDispachToProps
)(ModuleWorkflowUnsetTypes);
