import { CACHE_MINUTES, PAGE_SIZE } from "../utils/consts";
import { IFilterType } from "./filterType";
import { StoreState, StoreStateDontLoad } from "./storeState";
import { addMinutes, isAfter } from "date-fns";
import { AxiosResponse } from "axios";

interface IListEffectState {
  state: StoreState;
  lastLoaded?: Date;
  countState: StoreStateDontLoad;
  count: number;
  page: number;
  orderBy?: string;
  orderDesc?: boolean;
}

interface IListEffectGetNewRes {
  newCountState: StoreStateDontLoad;
  countDontLoad: boolean;
  checkCount: boolean;
  take: number;
  newCount: number;
}

interface IListEffectLoad {
  newState: StoreState;
  newCountState: StoreStateDontLoad;
}

export function listEffectsGetCheck(state: StoreState): boolean {
  return state !== StoreState.None && state !== StoreState.Cancel;
}

export function listEffectsGetNew(
  state: IListEffectState
): IListEffectGetNewRes {
  const newCountState =
    state.countState === StoreStateDontLoad.Error
      ? StoreStateDontLoad.DontLoad
      : state.countState;
  const countDontLoad = newCountState === StoreStateDontLoad.DontLoad;
  const checkCount = countDontLoad && state.page === 0;
  const take = checkCount ? PAGE_SIZE + 1 : PAGE_SIZE;
  const newCount = state.count;

  return {
    newCountState,
    countDontLoad,
    checkCount,
    take,
    newCount,
  };
}

export function listEffectsGetParams(
  state: IListEffectState,
  take: number
): URLSearchParams {
  const params = new URLSearchParams();
  params.append("skip", (state.page * PAGE_SIZE).toString());
  params.append("take", take.toString());

  if (state.orderBy) {
    params.append("orderBy", state.orderBy);
  }

  if (state.orderDesc) {
    params.append("orderDesc", state.orderDesc ? "true" : "false");
  }

  return params;
}

export function listEffectsGetParamsForExport(
  state: IListEffectState
): URLSearchParams {
  const params = new URLSearchParams();

  if (state.orderBy) {
    params.append("orderBy", state.orderBy);
  }

  if (state.orderDesc) {
    params.append("orderDesc", state.orderDesc ? "true" : "false");
  }

  return params;
}

export function listEffectGetFilter(
  params: URLSearchParams,
  filter?: IFilterType
) {
  if (filter?.search) {
    params.append("search", filter?.search);
  }
}

export function listEffectsGetCount<T>(
  newRes: IListEffectGetNewRes,
  response: AxiosResponse<T[]>
): IListEffectGetNewRes {
  if (newRes.checkCount) {
    if (response.data.length <= PAGE_SIZE) {
      newRes.newCountState = StoreStateDontLoad.Loaded;
      newRes.newCount = response.data.length;
    } else {
      newRes.newCountState = StoreStateDontLoad.None;
      response.data = response.data.slice(0, PAGE_SIZE);
    }
  } else if (newRes.countDontLoad) {
    newRes.newCountState = StoreStateDontLoad.None;
  }

  return newRes;
}

export function listEffectsCountCheck(state: StoreStateDontLoad): boolean {
  return (
    state !== StoreStateDontLoad.None && state !== StoreStateDontLoad.Cancel
  );
}

export function listEffectCountFilter(filter?: IFilterType): URLSearchParams {
  const params = new URLSearchParams();

  if (filter?.search) {
    params.append("search", filter?.search);
  }

  return params;
}

export function listEffectReload(
  reload: boolean,
  state: IListEffectState
): boolean {
  if (reload || !state.lastLoaded) {
    return true;
  }

  return isAfter(new Date(), addMinutes(state.lastLoaded, CACHE_MINUTES));
}

export function listEffectLoad(state: IListEffectState): IListEffectLoad {
  const newState =
    state.state === StoreState.Loading || state.state === StoreState.Loaded
      ? state.state
      : StoreState.None;
  const newCountState =
    state.countState === StoreStateDontLoad.Loading ||
    state.countState === StoreStateDontLoad.Loaded
      ? state.countState
      : StoreStateDontLoad.DontLoad;

  return { newState, newCountState };
}
