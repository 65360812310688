import { IItemIdName } from "../../models/common";
import { StoreState } from "../storeState";

export enum DefaultDocumentFolderTypes {
  DEFAULT_DOCUMENT_FOLDER_FETCH_REQUEST = "@@defaultDocumentFolder/FETCH_REQUEST",
  DEFAULT_DOCUMENT_FOLDER_FETCH_SUCCESS = "@@defaultDocumentFolder/FETCH_SUCCESS",
  DEFAULT_DOCUMENT_FOLDER_FETCH_ERROR = "@@defaultDocumentFolder/FETCH_ERROR",
}

export interface IDefaultDocumentFolderState {
  state: StoreState;
  data: IItemIdName[];
}
