import { AppThunkAction, AppThunkActionAsync } from "..";
import * as actions from "./actions";
import { selectClientDemandList } from ".";
import { IFilterType } from "../filterType";
import { CancelToken } from "axios";
import {
  listEffectCountFilter,
  listEffectGetFilter,
  listEffectLoad,
  listEffectReload,
  listEffectsCountCheck,
  listEffectsGetCheck,
  listEffectsGetCount,
  listEffectsGetNew,
  listEffectsGetParams,
} from "../listEffect";
import demandApi from "../../api/demand";

export function getClientDemandList(
  clientId: number,
  cancelToken: CancelToken
): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectClientDemandList(getState());
    if (listEffectsGetCheck(state.state)) {
      return;
    }

    let newState = listEffectsGetNew(state);
    dispatch(actions.clientDemandListFetchRequest(newState.newCountState));
    try {
      const params = listEffectsGetParams(state, newState.take);
      listEffectGetFilter(params, state.filter);
      addClientParam(params, clientId);

      const demands = await demandApi.getDemandList({
        params,
        cancelToken,
      });
      cancelToken.throwIfRequested();
      newState = listEffectsGetCount(newState, demands);

      dispatch(
        actions.clientDemandListFetchSuccess({
          data: demands.data,
          newCountState: newState.newCountState,
          newCount: newState.newCount,
        })
      );
    } catch {
      dispatch(
        actions.clientDemandListFetchError(cancelToken.reason !== undefined)
      );
    }
  };
}

export function getClientDemandListCount(
  clientId: number,
  cancelToken: CancelToken
): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectClientDemandList(getState());
    if (listEffectsCountCheck(state.countState)) {
      return;
    }

    dispatch(actions.clientDemandListCountRequest());
    try {
      const params = listEffectCountFilter(state.filter);
      addClientParam(params, clientId);

      const count = await demandApi.getDemandCount({
        params,
        cancelToken,
      });
      cancelToken.throwIfRequested();

      dispatch(actions.clientDemandListCountSuccess(count.data));
    } catch {
      dispatch(
        actions.clientDemandListCountError(cancelToken.reason !== undefined)
      );
    }
  };
}

export function changeClientDemandListOrder(
  orderBy: string,
  orderDesc: boolean
): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.clientDemandListChangeOrder({ orderBy, orderDesc }));
  };
}

export function changeClientDemandListPage(page: number): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.clientDemandListChangePage(page));
  };
}

export function changeClientDemandListFilter(
  filter: IFilterType
): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.clientDemandListChangeFilter(filter));
  };
}

export function clientDemandListLoad(reload: boolean): AppThunkAction {
  return async (dispatch, getState) => {
    const state = selectClientDemandList(getState());
    reload = listEffectReload(reload, state);
    if (reload) {
      dispatch(actions.clientDemandListReset());
    } else {
      const newState = listEffectLoad(state);
      dispatch(
        actions.clientDemandListLoadAction({
          newState: newState.newState,
          newCountState: newState.newCountState,
        })
      );
    }
  };
}

function addClientParam(params: URLSearchParams, clientId: number) {
  params.append("clientId", clientId.toString());
}
