import { AxiosRequestConfig, AxiosResponse } from "axios";
import { IDemand, IDemandHistory, IDemandItem } from "../models/demand";
import api from "../utils/api";

const getDemandList = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<IDemandItem[]>> => api.get(`/api/Demand/List`, config);

const getDemandCount = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<number>> => api.get(`/api/Demand/Count`, config);

const demandListExportCsv = (
  params: URLSearchParams
): Promise<AxiosResponse<Blob>> =>
  api.get(`/api/Demand/Export/Csv`, { params, responseType: "blob" });

const demandListExportXlsx = (
  params: URLSearchParams
): Promise<AxiosResponse<Blob>> =>
  api.get(`/api/Demand/Export/Xlsx`, { params, responseType: "blob" });

const getDemand = async (demandId: number): Promise<AxiosResponse<IDemand>> =>
  api.get(`/api/Demand/${demandId}`);

const createDemand = async (
  workflowId: number,
  clientId?: number | null,
  answers?: number[] | null
): Promise<AxiosResponse<number>> =>
  api.post(`/api/Workflow/${workflowId}/Demand`, answers, {
    params: { clientId },
  });

const updateDemand = async (data: IDemand): Promise<AxiosResponse<IDemand>> =>
  api.put(`/api/Demand/${data.id}`, data);

const cancelDemand = async (
  demandId: number
): Promise<AxiosResponse<IDemand>> => api.put(`/api/Demand/${demandId}/Cancel`);

const closeDemand = async (
  demandId: number,
  answers?: number[] | null
): Promise<AxiosResponse<IDemand>> =>
  api.put(`/api/Demand/${demandId}/Close`, answers);

const takeDemand = async (demandId: number): Promise<AxiosResponse<IDemand>> =>
  api.put(`/api/Demand/${demandId}/Take`);

const getDemandHistory = async (
  demandId: number
): Promise<AxiosResponse<IDemandHistory>> =>
  api.get(`/api/Demand/${demandId}/History`);

const demandApi = {
  getDemandList,
  getDemandCount,
  demandListExportCsv,
  demandListExportXlsx,
  createDemand,
  getDemand,
  updateDemand,
  cancelDemand,
  closeDemand,
  takeDemand,
  getDemandHistory,
};

export default demandApi;
