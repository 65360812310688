import { createAction } from "@reduxjs/toolkit";
import { IActionItem } from "../../models/calendar";
import { IItemIdName } from "../../models/common";
import { StoreState, StoreStateDontLoad } from "../storeState";
import { ActionListTypes, IActionListFilter } from "./types";

export const actionListFetchRequest = createAction<
  StoreStateDontLoad,
  ActionListTypes.ACTION_LIST_FETCH_REQUEST
>(ActionListTypes.ACTION_LIST_FETCH_REQUEST);

export const actionListFetchSuccess = createAction<
  { data: IActionItem[]; newCountState: StoreStateDontLoad; newCount: number },
  ActionListTypes.ACTION_LIST_FETCH_SUCCESS
>(ActionListTypes.ACTION_LIST_FETCH_SUCCESS);

export const actionListFetchError = createAction<
  boolean,
  ActionListTypes.ACTION_LIST_FETCH_ERROR
>(ActionListTypes.ACTION_LIST_FETCH_ERROR);

export const actionListCountRequest = createAction<
  void,
  ActionListTypes.ACTION_LIST_COUNT_REQUEST
>(ActionListTypes.ACTION_LIST_COUNT_REQUEST);

export const actionListCountSuccess = createAction<
  number,
  ActionListTypes.ACTION_LIST_COUNT_SUCCESS
>(ActionListTypes.ACTION_LIST_COUNT_SUCCESS);

export const actionListCountError = createAction<
  boolean,
  ActionListTypes.ACTION_LIST_COUNT_ERROR
>(ActionListTypes.ACTION_LIST_COUNT_ERROR);

export const actionListChangeOrder = createAction<
  { orderBy: string; orderDesc: boolean },
  ActionListTypes.ACTION_LIST_CHANGE_ORDER
>(ActionListTypes.ACTION_LIST_CHANGE_ORDER);

export const actionListChangePage = createAction<
  number,
  ActionListTypes.ACTION_LIST_CHANGE_PAGE
>(ActionListTypes.ACTION_LIST_CHANGE_PAGE);

export const actionListChangeFilter = createAction<
  IActionListFilter,
  ActionListTypes.ACTION_LIST_CHANGE_FILTER
>(ActionListTypes.ACTION_LIST_CHANGE_FILTER);

export const actionListReset = createAction<
  void,
  ActionListTypes.ACTION_LIST_RESET
>(ActionListTypes.ACTION_LIST_RESET);

export const actionListLoadAction = createAction<
  { newState: StoreState; newCountState: StoreStateDontLoad },
  ActionListTypes.ACTION_LIST_LOAD
>(ActionListTypes.ACTION_LIST_LOAD);

export const actionListDefaultFilter = createAction<
  { assignedUsers: IItemIdName[]; from: Date | null; to: Date | null },
  ActionListTypes.ACTION_LIST_DEFAULT_FILTER
>(ActionListTypes.ACTION_LIST_DEFAULT_FILTER);
