import { FC } from "react";
import { useTranslation } from "react-i18next";

const links = [
  { id: "web", url: process.env.REACT_APP_PUBLIC_WEB_URL },
  { id: "admin", url: process.env.REACT_APP_PUBLIC_WEB_ADMIN_URL },
  { id: "gdpr", url: "/gdpr.html" },
];

const AdminPublic: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <h1>{t("admin.public.pageTitle")}</h1>
      <ul>
        {links.map((l) => (
          <li key={l.id}>
            <a href={l.url} target="_blank" rel="noreferrer">
              {t("admin.public." + l.id)}
            </a>
          </li>
        ))}
      </ul>
    </>
  );
};

export default AdminPublic;
