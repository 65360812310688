import React, { FC, useEffect, useRef, useState } from "react";
import {
  DagreEngine,
  DefaultLinkModelGenerics,
  DefaultPortModel,
  DiagramEngine,
  DiagramModel,
  LinkModel,
  NodeModel,
  PathFindingLinkFactory,
  PortModelAlignment,
  RightAngleLinkFactory,
} from "@projectstorm/react-diagrams";
import {
  BaseModel,
  CanvasWidget,
  Toolkit,
} from "@projectstorm/react-canvas-core";
import {
  DiagramButton,
  DiagramButtons,
  DiagramContainer,
  DiagramLinkModeButton,
  DiagramLinkModeContainer,
} from "./DiagramStyles";
import { DiagramStartRegister, DiagramStartNode } from "./DiagramStart";
import { DiagramEndRegister, DiagramEndNode } from "./DiagramEnd";
import {
  DiagramTemplateRegister,
  DiagramTemplateNode,
} from "./DiagramTemplate";
import {
  DiagramMultipleRegister,
  DiagramMultipleNode,
} from "./DiagramMultiple";
import DiagramMenu from "./DiagramMenu";
import {
  DiagramActionType,
  DiagramNodeDataInit,
  DiagramPortFoundType,
  IDiagramNodeData,
} from "./DiagramTypes";
import {
  DIAGRAM_COLORS,
  DIAGRAM_GAP_X,
  DIAGRAM_GAP_Y,
  DIAGRAM_HEIGHT,
  DIAGRAM_MIN_HEIGHT,
  DIAGRAM_MULTIPLE_FAKE_PORT_CLASS,
  DIAGRAM_OFFSET_X,
  DIAGRAM_OFFSET_Y,
  DIAGRAM_RECALCULATE_ALL,
} from "./DiagramConsts";
import {
  IWorkflowDefinition,
  WorkflowDefinitionItemAutoType,
  WorkflowDefinitionItemTopMode,
  WorkflowDefinitionItemType,
} from "../../../models/workflow";
import { useTranslation } from "react-i18next";
import DiagramNodeModal from "./DiagramNodeModal";
import { WorkflowAutoTypeOptions } from "../../../models/workflowAutoTypeOptions";
import { DemandStateType, IDemandHistoryItem } from "../../../models/demand";
import { getDefinition, historyCanShow } from "./diagramHelpers";
import { ModalOkFunction } from "../modal/ModalFunctions";
import {
  DiagramWorkflowNode,
  DiagramWorkflowRegister,
} from "./DiagramWorkflow";
import {
  DiagramWorkflowOutNode,
  DiagramWorkflowOutRegister,
} from "./DiagramWorkflowOut";
import { IDiagramSettings } from "../../../models/diagram";
import DiagramSettingsModal from "./DiagramSettingsModal";
import { store } from "../../../store";
import { selectDiagramSettings } from "../../../store/diagramSettings";
import { SpaceBetweenButtons } from "../../../styles/spaces";

interface IProps {
  settings: IDiagramSettings;
  engine: DiagramEngine;
  definition: IWorkflowDefinition[];
  disableChanges?: boolean;
  demands?: IDemandHistoryItem[];
  highlightId?: number;
  openDemand?: (item: IDemandHistoryItem) => void;
}

const Diagram: FC<IProps> = ({
  settings,
  engine,
  definition,
  disableChanges,
  demands,
  highlightId,
  openDemand,
}) => {
  const { t } = useTranslation();
  const [selectedPort, setSelectedPort] = useState<DefaultPortModel | null>(
    null
  );
  const [selectedEntity, setSelectedEntity] = useState<BaseModel | null>(null);
  const [clickCoordinates, setClickCoordinates] = useState<{
    x: number;
    y: number;
  } | null>(null);
  const diagramContainerRef = useRef<HTMLDivElement | null>(null);

  const [isLoading, setIsLoading] = useState(true);
  const recalculateRef = useRef<number[]>([DIAGRAM_RECALCULATE_ALL]);
  const [containerHeight, setContainerHeight] = useState(DIAGRAM_MIN_HEIGHT);
  const [nodeModalInitValues, setNodeModalInitValues] =
    useState<IDiagramNodeData>(DiagramNodeDataInit);
  const [nodeModalEditMode, setNodeModalEditMode] = useState(false);
  const [nodeModalData, setNodeModalData] = useState<{
    entity: BaseModel;
    port: DefaultPortModel | null;
  } | null>(null);
  const [linkModePort, setLinkModePort] = useState<DefaultPortModel | null>(
    null
  );
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const dagreEngine = useRef<DagreEngine | null>(null);

  const linkFactory = settings.pathFind
    ? engine
        .getLinkFactories()
        .getFactory<PathFindingLinkFactory>(PathFindingLinkFactory.NAME)
    : undefined;

  useEffect(() => {
    const state = engine.getStateMachine().getCurrentState() as any;

    // Code for possible disabling canvas drag.
    // state.dragCanvas.config.allowDrag = false;

    //Disable create link with mouse.
    state.dragNewLink.config.allowLooseLinks = false;

    DiagramStartRegister(engine);
    DiagramEndRegister(engine);
    DiagramTemplateRegister(engine);
    DiagramMultipleRegister(engine);
    DiagramWorkflowRegister(engine);
    DiagramWorkflowOutRegister(engine);

    engine.getLinkFactories().registerFactory(new RightAngleLinkFactory());

    engine.registerListener({
      //Rearrange after width is calculated.
      //DIAGRAM_RECALCULATE_ALL = first run
      //other = only new changes.
      rendered: () => {
        if (dagreEngine.current) {
          if (recalculateRef.current.length === 0) {
            return;
          }

          recalculateRef.current = [];

          const model = engine.getModel();
          dagreEngine.current.redistribute(model);

          //Dont read from settings.pathFind, its dont change.
          const diagramSettings = selectDiagramSettings(store.getState());
          if (diagramSettings.pathFind) {
            engine
              .getLinkFactories()
              .getFactory<PathFindingLinkFactory>(PathFindingLinkFactory.NAME)
              .calculateRoutingMatrix();
          }

          engine.repaintCanvas();

          const nodes = model.getNodes();
          const max = Math.max(...nodes.map((x) => x.getPosition().y), 0);
          resizeContainer(max);

          return;
        }

        const array = recalculateRef.current.sort();
        if (array.length === 0) {
          return;
        }

        var changed = false;
        if (array.includes(DIAGRAM_RECALCULATE_ALL)) {
          const model = engine.getModel();
          const nodes = model.getNodes();

          var y = DIAGRAM_OFFSET_Y;
          while (true) {
            // eslint-disable-next-line no-loop-func
            const node = nodes.find((x) => x.getPosition().y === y);
            if (!node) {
              break;
            }

            if (rearrangeX(y, false)) {
              changed = true;
            }
            y += DIAGRAM_GAP_Y + DIAGRAM_HEIGHT;
          }
        } else {
          for (const item of array) {
            if (rearrangeX(item, false)) {
              changed = true;
            }
          }
        }

        recalculateRef.current = [];
        if (changed) {
          engine.repaintCanvas();
        }
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const model = engine.getModel();
    createDagre();

    if (model) {
      //Change settings.
      handleRepair();
      return;
    }

    //First call.
    createModel(definition);
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  const createModel = (definition: IWorkflowDefinition[]) => {
    const model = new DiagramModel();

    const nodeStart = new DiagramStartNode({
      disableClick: disableChanges,
      isLocked: !settings.moveNodes,
    });
    nodeStart.setPosition(DIAGRAM_OFFSET_X, DIAGRAM_OFFSET_Y);
    model.addNode(nodeStart);

    const renderedItems: { [name: number]: DefaultPortModel } = {};
    addByDefinition(
      renderedItems,
      model,
      definition,
      null,
      nodeStart,
      nodeStart.myPort()
    );
    engine.setModel(model);

    if (!settings.dagreEnabled) {
      const nodes = model.getNodes();
      const max = Math.max(...nodes.map((x) => x.getPosition().y), 0);
      resizeContainer(max);
    }
  };

  const addByDefinition = (
    renderedItems: { [name: number]: DefaultPortModel },
    model: DiagramModel,
    definition: IWorkflowDefinition[],
    parentId: number | null,
    parentNode: NodeModel,
    parentPort: DefaultPortModel
  ) => {
    const items = definition
      .filter((x) => x.parentId.includes(parentId))
      .sort((a, b) => a.parallerOrder - b.parallerOrder);

    for (const item of items) {
      const renderedItem = renderedItems[item.id];
      if (renderedItem) {
        const link = parentPort.link(renderedItem, linkFactory);
        setLink(link);
        model.addAll(link);
        continue;
      }

      const demand = demands?.find(
        (x) => x.idWorkflowDefinitionItem === item.id
      );

      if (
        disableChanges &&
        (settings.historyHide || settings.historyItems) &&
        item.topPortMode === WorkflowDefinitionItemTopMode.AllPaths &&
        !demand &&
        !!demands
      ) {
        if (!historyCanShow(item, definition, demands)) {
          continue;
        }
      }

      if (item.type === WorkflowDefinitionItemType.Template) {
        const nodeTemplate = new DiagramTemplateNode({
          id: item.templateId ?? 0,
          name: item.templateName ?? "",
          topPortMode: item.topPortMode,
          disableClick: !!demands && (!demand || !demand?.hasRights),
          notFound: !!demands && !demand,
          historyItem: demand,
          highlight: !!demands && highlightId === demand?.id,
          isLocked: !settings.moveNodes,
        });
        const y = parentNode.getPosition().y + DIAGRAM_HEIGHT + DIAGRAM_GAP_Y;
        nodeTemplate.setPosition(getNextX(model, y), y);

        const link = parentPort.link(nodeTemplate.myPortTop(), linkFactory);
        setLink(link);

        model.addAll(nodeTemplate, link);

        renderedItems[item.id] = nodeTemplate.myPortTop();

        if (settings.historyHide) {
          if (demand?.state === DemandStateType.Canceled) {
            continue;
          }
        }

        addByDefinition(
          renderedItems,
          model,
          definition,
          item.id,
          nodeTemplate,
          nodeTemplate.myPortBottom()
        );
      } else if (item.type === WorkflowDefinitionItemType.Question) {
        let questions = definition
          .filter((x) => x.parentId.includes(item.id))
          .sort((a, b) => a.parallerOrder - b.parallerOrder)
          .map((x) => ({ id: x.id.toString(), name: x.text ?? "" }));

        let historyPort: string | undefined = undefined;
        let portFound = demands
          ? DiagramPortFoundType.History
          : DiagramPortFoundType.AllEnabled;
        if (demands && demand) {
          for (const question of questions) {
            const answerDemand = demands?.find(
              (x) => x.idWorkflowDefinitionItem === parseInt(question!.id)
            );

            if (answerDemand) {
              historyPort = question.id;
            }
          }
        }

        if (disableChanges && settings.historyItems) {
          if (!!historyPort && portFound === DiagramPortFoundType.History) {
            questions = questions.filter((x) => x.id === historyPort);
          }
        }

        const multiple = new DiagramMultipleNode({
          type: WorkflowDefinitionItemType.Question,
          name: item.text ?? "",
          items: questions,
          topPortMode: item.topPortMode,
          disableClick: !!demands,
          notFound: !!demands && !demand,
          historyItem: demand,
          historyPort,
          portFound,
          isLocked: !settings.moveNodes,
        });
        const y = parentNode.getPosition().y + DIAGRAM_HEIGHT + DIAGRAM_GAP_Y;
        multiple.setPosition(getNextX(model, y), y);

        const link = parentPort.link(multiple.myPortTop(), linkFactory);
        setLink(link);

        model.addAll(multiple, link);

        renderedItems[item.id] = multiple.myPortTop();

        for (const question of questions) {
          if (disableChanges && settings.historyHide) {
            if (
              !!historyPort &&
              portFound === DiagramPortFoundType.History &&
              question.id !== historyPort
            ) {
              continue;
            }
          }

          addByDefinition(
            renderedItems,
            model,
            definition,
            parseInt(question.id),
            multiple,
            multiple.myPortItem(question.id)
          );
        }
      } else if (item.type === WorkflowDefinitionItemType.Auto) {
        let options: Array<{ id: string; name: string }> = [];

        const typesMapping = Object.values(
          WorkflowAutoTypeOptions[item.autoType!]
        );
        const definitionMapping = definition
          .filter((x) => x.parentId.includes(item.id))
          .sort((a, b) => a.parallerOrder - b.parallerOrder);

        const count = Math.min(typesMapping.length, definitionMapping.length);
        for (let i = 0; i < count; i++) {
          options.push({
            id: definitionMapping[i].id.toString(),
            name: typesMapping[i],
          });
        }

        let historyPort: string | undefined = undefined;
        let portFound = demands
          ? DiagramPortFoundType.History
          : DiagramPortFoundType.AllEnabled;
        if (demands && demand) {
          for (const option of options) {
            const optionDemand = demands?.find(
              (x) => x.idWorkflowDefinitionItem === parseInt(option!.id)
            );

            if (optionDemand) {
              historyPort = option.id;
            }
          }
        }

        if (disableChanges && settings.historyItems) {
          if (!!historyPort && portFound === DiagramPortFoundType.History) {
            options = options.filter((x) => x.id === historyPort);
          }
        }

        const multiple = new DiagramMultipleNode({
          type: WorkflowDefinitionItemType.Auto,
          autoType: item.autoType,
          items: options,
          topPortMode: item.topPortMode,
          disableClick: !!demands,
          notFound: !!demands && !demand,
          historyItem: demand,
          historyPort,
          portFound,
          isLocked: !settings.moveNodes,
        });
        const y = parentNode.getPosition().y + DIAGRAM_HEIGHT + DIAGRAM_GAP_Y;
        multiple.setPosition(getNextX(model, y), y);

        const link = parentPort.link(multiple.myPortTop(), linkFactory);
        setLink(link);

        model.addAll(multiple, link);

        renderedItems[item.id] = multiple.myPortTop();

        for (const option of options) {
          if (disableChanges && settings.historyHide) {
            if (
              !!historyPort &&
              portFound === DiagramPortFoundType.History &&
              option.id !== historyPort
            ) {
              continue;
            }
          }

          addByDefinition(
            renderedItems,
            model,
            definition,
            parseInt(option.id),
            multiple,
            multiple.myPortItem(option.id)
          );
        }
      } else if (item.type === WorkflowDefinitionItemType.Workflow) {
        const nodeTemplate = new DiagramWorkflowNode({
          id: item.nestedWorkflowId ?? 0,
          name: item.nestedWorkflowName ?? "",
          topPortMode: item.topPortMode,
          disableClick: !!demands,
          notFound: !!demands && !demand,
          historyMode: !!demands,
          historyItem: demand,
          highlight: false,
          isLocked: !settings.moveNodes,
        });
        const y = parentNode.getPosition().y + DIAGRAM_HEIGHT + DIAGRAM_GAP_Y;
        nodeTemplate.setPosition(getNextX(model, y), y);

        const link = parentPort.link(nodeTemplate.myPortTop(), linkFactory);
        setLink(link);

        model.addAll(nodeTemplate, link);

        renderedItems[item.id] = nodeTemplate.myPortTop();

        addByDefinition(
          renderedItems,
          model,
          definition,
          item.id,
          nodeTemplate,
          nodeTemplate.myPortBottom()
        );
      } else if (item.type === WorkflowDefinitionItemType.WorkflowOut) {
        //Show as regular end.
        if (disableChanges && !item.nestedWorkflowId) {
          continue;
        }

        const nodeTemplate = new DiagramWorkflowOutNode({
          nestedWorkflowName: item.nestedWorkflowName,
          topPortMode: item.topPortMode,
          disableClick: !!demands,
          notFound: !!demands && !demand,
          historyItem: demand,
          highlight: false,
          isLocked: !settings.moveNodes,
        });
        const y = parentNode.getPosition().y + DIAGRAM_HEIGHT + DIAGRAM_GAP_Y;
        nodeTemplate.setPosition(getNextX(model, y), y);

        const link = parentPort.link(nodeTemplate.myPortTop(), linkFactory);
        setLink(link);

        model.addAll(nodeTemplate, link);

        renderedItems[item.id] = nodeTemplate.myPortTop();

        addByDefinition(
          renderedItems,
          model,
          definition,
          item.id,
          nodeTemplate,
          nodeTemplate.myPortBottom()
        );
      }
    }

    if (settings.showEnd && !(parentNode instanceof DiagramWorkflowOutNode)) {
      if (Object.keys(parentPort.getLinks()).length === 0) {
        let notFound = false;
        if (demands) {
          notFound = true;

          if (parentNode instanceof DiagramTemplateNode) {
            if (
              parentNode.myData().historyItem?.state ===
              DemandStateType.Finished
            ) {
              notFound = false;
            }
          } else if (parentNode instanceof DiagramMultipleNode) {
            notFound = parentPort.getName() !== parentNode.myData().historyPort;
          }
        }

        const nodeEnd = new DiagramEndNode({
          disableClick: disableChanges,
          notFound,
          isLocked: !settings.moveNodes,
        });
        const y = parentNode.getPosition().y + DIAGRAM_HEIGHT + DIAGRAM_GAP_Y;
        nodeEnd.setPosition(getNextX(model, y), y);

        const link = parentPort.link(nodeEnd.myPort(), linkFactory);
        setLink(link);

        model.addAll(nodeEnd, link);
      }
    }
  };

  const getOptionsWithId = (autoType: WorkflowDefinitionItemAutoType) => {
    const res = Object.values(WorkflowAutoTypeOptions[autoType]).map(
      (x, index) => ({ id: index.toString(), name: x })
    );
    return res;
  };

  const getNextX = (model: DiagramModel, y: number) => {
    const nodes = model.getNodes();
    const filtered = nodes
      .filter((x) => x.getPosition().y === y)
      .sort((a, b) => a.getPosition().x - b.getPosition().x);

    var left = DIAGRAM_OFFSET_X;
    for (const node of filtered) {
      left += node.width + DIAGRAM_GAP_X;
    }

    return left;
  };

  const rearrangeX = (y: number, recalculate: boolean) => {
    if (recalculate && !recalculateRef.current.includes(y)) {
      recalculateRef.current.push(y);
    }

    const model = engine.getModel();
    const nodes = model.getNodes();
    const filtered = nodes
      .filter((x) => x.getPosition().y === y)
      .sort((a, b) => a.getPosition().x - b.getPosition().x);

    var changed = false;
    var left = DIAGRAM_OFFSET_X;
    for (const node of filtered) {
      const position = node.getPosition();
      if (position.x !== left) {
        node.setPosition(left, y);
        changed = true;
      }

      left += node.width + DIAGRAM_GAP_X;
    }

    return changed;
  };

  const getOffsetFromPort = (port: DefaultPortModel): number => {
    const nodes: Array<NodeModel> = [];
    const links = port.getLinks();
    for (const key of Object.keys(links)) {
      const link = links[key];
      const parent = link.getTargetPort().getParent();
      nodes.push(parent);
    }

    if (nodes.length !== 0) {
      const sorted = nodes.sort(
        (a, b) => a.getPosition().x - b.getPosition().x
      );
      return sorted[sorted.length - 1].getPosition().x + DIAGRAM_OFFSET_X;
    }

    return 0;
  };

  const getOffsetForAdd = (port: DefaultPortModel): number => {
    //Same port, last node.
    var res = getOffsetFromPort(port);
    if (res) {
      return res;
    }

    //Left port, last node.
    const node = port.getNode();
    if (node instanceof DiagramMultipleNode) {
      const multiple = node as DiagramMultipleNode;
      const data = multiple.myData();
      const portName = port.getName();
      const index = data.items.findIndex((x) => x.id === portName);
      if (index > 0) {
        const leftPortId = data.items[index - 1].id;
        const port2 = multiple.myPortItem(leftPortId);
        res = getOffsetFromPort(port2);
        if (res) {
          return res;
        }
      }
    }

    //Left node, last port, last node.
    const position = node.getPosition();
    const model = engine.getModel();
    const nodes = model.getNodes();
    const filteredLeft = nodes
      .filter((x) => x.getPosition().y === position.y)
      .filter((x) => x.getPosition().x < position.x)
      .sort((a, b) => a.getPosition().x - b.getPosition().x)
      .reverse();
    for (const lastNode of filteredLeft) {
      if (lastNode instanceof DiagramEndNode) {
        continue;
      }

      if (lastNode instanceof DiagramMultipleNode) {
        const multiple = lastNode as DiagramMultipleNode;
        const data = multiple.myData();
        const port2 = multiple.myPortItem(data.items[data.items.length - 1].id);

        res = getOffsetFromPort(port2);
        if (res) {
          return res;
        }

        break;
      }

      res = getOffsetFromPort(
        lastNode.getPort(PortModelAlignment.BOTTOM) as DefaultPortModel
      );
      if (res) {
        return res;
      }

      break;
    }

    //Right node, first port, before.
    const filteredRight = nodes
      .filter((x) => x.getPosition().y === position.y)
      .filter((x) => x.getPosition().x > position.x)
      .sort((a, b) => a.getPosition().x - b.getPosition().x);
    for (const lastNode of filteredRight) {
      if (lastNode instanceof DiagramEndNode) {
        continue;
      }

      if (lastNode instanceof DiagramMultipleNode) {
        const multiple = lastNode as DiagramMultipleNode;
        const port2 = multiple.myPortItem(multiple.myData().items[0].id);

        res = getOffsetFromPort(port2);

        if (res) {
          return res - 2 * DIAGRAM_OFFSET_X;
        }

        break;
      }

      res = getOffsetFromPort(
        lastNode.getPort(PortModelAlignment.BOTTOM) as DefaultPortModel
      );
      if (res) {
        return res - 2 * DIAGRAM_OFFSET_X;
      }

      break;
    }

    //Behind last item.
    /*
    const y = position.y + DIAGRAM_GAP_Y + DIAGRAM_HEIGHT;
    const filtered2 = nodes
      .filter((x) => x.getPosition().y === y)
      .sort((a, b) => a.getPosition().x - b.getPosition().x);
    if (filtered2.length !== 0) {
      const lastNode = filtered2[filtered2.length - 1];
      return lastNode.getPosition().x + DIAGRAM_OFFSET_X;
    }
    */

    return 0;
  };

  const deleteEndByPort = (port: DefaultPortModel) => {
    const links = port.getLinks();
    for (const key of Object.keys(links)) {
      const link = links[key];
      const parent = link.getTargetPort().getParent();
      if (parent instanceof DiagramEndNode) {
        const y = parent.getPosition().y;
        parent.remove();
        rearrangeX(y, true);
      }
    }
  };

  const deleteEnd = (e: NodeModel) => {
    const ports = e.getPorts();
    for (const portKey of Object.keys(ports)) {
      const port = ports[portKey] as DefaultPortModel;
      if (port.serialize().in) {
        continue;
      }

      deleteEndByPort(port);
    }
  };

  const resizeContainer = (max: number) => {
    max += DIAGRAM_HEIGHT + DIAGRAM_OFFSET_Y;

    if (max > containerHeight) {
      setContainerHeight(max);
    }
  };

  const setAllNodeLinkMode = (linkMode: boolean) => {
    const model = engine.getModel();
    const nodes = model.getNodes();

    for (const node of nodes) {
      if (node instanceof DiagramStartNode) {
        const data = node.myData();
        data.disableClick = linkMode;
        data.notFound = linkMode;
        data.highlight = false;
      } else if (node instanceof DiagramEndNode) {
        const data = node.myData();
        data.disableClick = linkMode;
        data.notFound = linkMode;
      } else if (node instanceof DiagramTemplateNode) {
        const data = node.myData();
        data.disableClick = linkMode;
        data.notFound = linkMode;
        data.highlight = false;
      } else if (node instanceof DiagramMultipleNode) {
        const data = node.myData();
        data.disableClick = linkMode;
        data.notFound = linkMode;
        data.highlight = false;
        data.portFound = linkMode
          ? DiagramPortFoundType.AllDisabled
          : DiagramPortFoundType.AllEnabled;
      } else if (node instanceof DiagramWorkflowNode) {
        const data = node.myData();
        data.disableClick = linkMode;
        data.notFound = linkMode;
        data.highlight = false;
      } else if (node instanceof DiagramWorkflowOutNode) {
        const data = node.myData();
        data.disableClick = linkMode;
        data.notFound = linkMode;
        data.highlight = false;
      }

      //engine.repaintCanvas() dont work, change position and rearrange all at end
      node.setPosition(node.getPosition().x + 1, node.getPosition().y);
    }
  };

  const getAllParentNodeIds = (entity: NodeModel, list: string[]) => {
    if (
      entity instanceof DiagramStartNode ||
      entity instanceof DiagramEndNode
    ) {
      return;
    }

    const id = entity.getID();
    if (list.includes(id)) {
      return;
    }

    list.push(id);

    const links = entity.getPort(PortModelAlignment.TOP)!.getLinks();
    for (const key of Object.keys(links)) {
      const parent = links[key].getSourcePort().getParent();
      getAllParentNodeIds(parent, list);
    }
  };

  const handleAction = (
    entity: BaseModel,
    port: DefaultPortModel | null,
    type: DiagramActionType
  ) => {
    const model = engine.getModel();

    if (type === DiagramActionType.Add) {
      setNodeModalInitValues(DiagramNodeDataInit);
      setNodeModalEditMode(false);
      setNodeModalData({ entity, port });
    } else if (type === DiagramActionType.Link) {
      if (!(entity instanceof NodeModel)) {
        return;
      }

      let p;
      if (entity instanceof DiagramEndNode) {
        const links = entity.myPort().getLinks();
        const link = links[Object.keys(links)[0]];
        p = link.getSourcePort() as DefaultPortModel;
        entity = p.getParent() as NodeModel;
      } else if (entity instanceof DiagramMultipleNode) {
        p = port!;
      } else if (entity instanceof DiagramWorkflowOutNode) {
        return;
      } else {
        p = entity.getPort(PortModelAlignment.BOTTOM) as DefaultPortModel;
      }

      const portList: string[] = [];
      const links = p!.getLinks();
      for (const key of Object.keys(links)) {
        const link = links[key];
        const parent = link.getTargetPort().getParent();
        portList.push(parent.getID());
      }

      const parentList: string[] = [];
      getAllParentNodeIds(entity as NodeModel, parentList);

      const allNodes = model.getNodes();
      const allAvailable = allNodes
        .filter(
          (x) =>
            x instanceof DiagramTemplateNode ||
            x instanceof DiagramMultipleNode ||
            x instanceof DiagramWorkflowNode ||
            x instanceof DiagramWorkflowOutNode
        )
        .filter((x) => !portList.includes(x.getID()))
        .filter((x) => !parentList.includes(x.getID()));

      if (allAvailable.length === 0) {
        ModalOkFunction(t("diagram.addLink"), t("diagram.linkCantText"));
        return;
      }

      setAllNodeLinkMode(true);

      if (entity instanceof DiagramStartNode) {
        entity.myData().highlight = true;
      } else if (entity instanceof DiagramTemplateNode) {
        entity.myData().highlight = true;
      } else if (entity instanceof DiagramMultipleNode) {
        entity.myData().highlight = true;
      } else if (entity instanceof DiagramWorkflowNode) {
        entity.myData().highlight = true;
      }

      for (const availableItem of allAvailable) {
        if (availableItem instanceof DiagramTemplateNode) {
          const data = availableItem.myData();
          data.disableClick = false;
          data.notFound = false;
        } else if (availableItem instanceof DiagramMultipleNode) {
          const data = availableItem.myData();
          data.disableClick = false;
          data.notFound = false;
          data.portFound = DiagramPortFoundType.AllEnabled;
        } else if (availableItem instanceof DiagramWorkflowNode) {
          const data = availableItem.myData();
          data.disableClick = false;
          data.notFound = false;
        } else if (availableItem instanceof DiagramWorkflowOutNode) {
          const data = availableItem.myData();
          data.disableClick = false;
          data.notFound = false;
        }
      }

      setLinkModePort(p);
      //Data in node isnt state, we need repaint.
      //engine.repaintCanvas();
      //engine.repaintCanvas() dont work, rearrange all
      recalculateRef.current.push(DIAGRAM_RECALCULATE_ALL);
    } else if (type === DiagramActionType.Delete) {
      if (entity instanceof LinkModel) {
        const e = entity as LinkModel;
        const parent = e.getTargetPort().getParent();

        if (!(parent instanceof DiagramEndNode)) {
          e.remove();
        }
      } else if (entity instanceof NodeModel) {
        if (
          !(entity instanceof DiagramEndNode) &&
          !(entity instanceof DiagramStartNode)
        ) {
          //Delete End.
          deleteEnd(entity as NodeModel);

          const ports: Array<DefaultPortModel> = [];
          const links = entity.getPort(PortModelAlignment.TOP)!.getLinks();
          for (const key of Object.keys(links)) {
            ports.push(links[key].getSourcePort() as DefaultPortModel);
          }

          const y = entity.getPosition().y;
          entity.remove();
          rearrangeX(y, true);

          if (settings.showEnd) {
            for (const port of ports) {
              if (Object.keys(port.getLinks()).length !== 0) {
                continue;
              }

              const parent = port.getParent();
              const endX = getOffsetForAdd(port);
              const endY =
                parent.getPosition().y + DIAGRAM_GAP_Y + DIAGRAM_HEIGHT;
              const end1 = new DiagramEndNode({
                isLocked: !settings.moveNodes,
              });
              end1.setPosition(endX, endY);
              const link2 = port.link(end1.myPort(), linkFactory);
              setLink(link2);

              model.addAll(end1, link2);
              rearrangeX(endY, true);
            }
          }
        }
      }
    } else if (type === DiagramActionType.Edit) {
      if (entity instanceof DiagramTemplateNode) {
        const data = entity.myData();
        setNodeModalInitValues({
          type: WorkflowDefinitionItemType.Template,
          question: "",
          answers: ["", ""],
          autoType: undefined,
          templateId: data.id,
          templateName: data.name,
          topPortMode: data.topPortMode,
          nestedWorkflowId: undefined,
          nestedWorkflowName: undefined,
        });
        setNodeModalEditMode(true);
        setNodeModalData({ entity, port: null });
      } else if (entity instanceof DiagramMultipleNode) {
        const data = entity.myData();
        setNodeModalInitValues({
          type: data.type,
          question:
            data.type === WorkflowDefinitionItemType.Question ? data.name : "",
          answers:
            data.type === WorkflowDefinitionItemType.Question
              ? data.items.map((x) => x.name)
              : ["", ""],
          autoType:
            data.type === WorkflowDefinitionItemType.Auto
              ? data.autoType
              : undefined,
          templateId: undefined,
          templateName: undefined,
          topPortMode: data.topPortMode,
          nestedWorkflowId: undefined,
          nestedWorkflowName: undefined,
        });
        setNodeModalEditMode(true);
        setNodeModalData({ entity, port: null });
      } else if (entity instanceof DiagramWorkflowNode) {
        const data = entity.myData();
        setNodeModalInitValues({
          type: WorkflowDefinitionItemType.Workflow,
          question: "",
          answers: ["", ""],
          autoType: undefined,
          templateId: undefined,
          templateName: undefined,
          topPortMode: data.topPortMode,
          nestedWorkflowId: data.id,
          nestedWorkflowName: data.name,
        });
        setNodeModalEditMode(true);
        setNodeModalData({ entity, port: null });
      } else if (entity instanceof DiagramWorkflowOutNode) {
        const data = entity.myData();
        setNodeModalInitValues({
          type: WorkflowDefinitionItemType.WorkflowOut,
          question: "",
          answers: ["", ""],
          autoType: undefined,
          templateId: undefined,
          templateName: undefined,
          topPortMode: data.topPortMode,
          nestedWorkflowId: undefined,
          nestedWorkflowName: undefined,
        });
        setNodeModalEditMode(true);
        setNodeModalData({ entity, port: null });
      }
    }
  };

  const handleNodeModalSubmit = (data: IDiagramNodeData) => {
    if (!nodeModalData) {
      return;
    }

    const model = engine.getModel();
    const { entity, port } = nodeModalData;

    if (nodeModalEditMode) {
      const oldEntity = entity as NodeModel;
      const oldPosition = oldEntity.getPosition();

      //Create new node to same position.
      const newNode = newNodeFromModal(data);
      if (!newNode) {
        return;
      }

      newNode.setPosition(oldPosition.x, oldPosition.y);
      model.addAll(newNode);

      //Change top links to new node.
      const newTopPort = newNode.getPort(PortModelAlignment.TOP);
      const links = oldEntity.getPort(PortModelAlignment.TOP)!.getLinks();
      for (const key of Object.keys(links)) {
        const link = links[key];
        link.setTargetPort(newTopPort);
      }

      //Change bottom links to new node.
      const oldPorts = getBottomPortList(oldEntity);
      const newPorts = getBottomPortList(newNode);
      const portCount = Math.min(oldPorts.length, newPorts.length);

      //Same ports.
      for (let i = 0; i < portCount; i++) {
        const oldPort = oldPorts[i];
        const newPort = newPorts[i];

        const links = oldPort.getLinks();
        for (const key of Object.keys(links)) {
          const link = links[key];
          link.setSourcePort(newPort);
        }
      }

      //Missing port, delete link.
      if (newPorts.length < oldPorts.length) {
        for (let i = newPorts.length; i < oldPorts.length; i++) {
          const oldPort = oldPorts[i];
          deleteEndByPort(oldPort);
        }
      }

      //New ports, add end.
      if (settings.showEnd) {
        const endY = oldPosition.y + DIAGRAM_GAP_Y + DIAGRAM_HEIGHT;

        if (newPorts.length > oldPorts.length) {
          for (let i = oldPorts.length; i < newPorts.length; i++) {
            const newPort = newPorts[i];

            const endNode = new DiagramEndNode({
              isLocked: !settings.moveNodes,
            });
            endNode.setPosition(getOffsetForAdd(newPort), endY);

            const link = newPort.link(endNode.myPort(), linkFactory);
            setLink(link);

            model.addAll(endNode, link);
            rearrangeX(endY, true);
          }
        }
      }

      //Remove old node.
      oldEntity.remove();
      rearrangeX(oldPosition.y, true);

      setNodeModalData(null);
      return;
    }

    let p: DefaultPortModel;

    if (entity instanceof LinkModel) {
      const link = entity;
      p = link.getSourcePort() as DefaultPortModel;
    } else if (entity instanceof NodeModel) {
      if (entity instanceof DiagramMultipleNode) {
        if (port && port.getName() !== PortModelAlignment.TOP) {
          p = port;
        } else {
          return;
        }
      } else if (entity instanceof DiagramEndNode) {
        const links = entity.getPort(PortModelAlignment.TOP)!.getLinks();
        const link = links[Object.keys(links)[0]];
        p = link.getSourcePort() as DefaultPortModel;
      } else {
        p = entity.getPort(PortModelAlignment.BOTTOM) as DefaultPortModel;
      }
    } else {
      return;
    }

    //Delete End.
    deleteEndByPort(p);

    const e = p.getParent();
    const position = e.getPosition();

    const newX = getOffsetForAdd(p);
    const newY = position.y + DIAGRAM_GAP_Y + DIAGRAM_HEIGHT;

    const newNode = newNodeFromModal(data);
    if (!newNode) {
      return;
    }

    newNode.setPosition(newX, newY);
    const link1 = p.link(newNode.myPortTop(), linkFactory);
    setLink(link1);

    model.addAll(newNode, link1);
    rearrangeX(newY, true);

    const endY = newY + DIAGRAM_GAP_Y + DIAGRAM_HEIGHT;

    if (settings.showEnd && !(newNode instanceof DiagramWorkflowOutNode)) {
      const ports = newNode.getPorts();
      for (const portKey of Object.keys(ports)) {
        const port = ports[portKey] as DefaultPortModel;
        if (port.serialize().in) {
          continue;
        }

        const endNode = new DiagramEndNode({
          isLocked: !settings.moveNodes,
        });
        endNode.setPosition(getOffsetForAdd(port), endY);

        const link = port.link(endNode.myPort(), linkFactory);
        setLink(link);

        model.addAll(endNode, link);
        rearrangeX(endY, true);
      }
    }

    resizeContainer(endY);
    setNodeModalData(null);
  };

  const newNodeFromModal = (data: IDiagramNodeData) => {
    if (data.type === WorkflowDefinitionItemType.Question) {
      return new DiagramMultipleNode({
        type: WorkflowDefinitionItemType.Question,
        name: data.question!,
        items: data.answers.map((answer, index) => ({
          id: (index + 1).toString(),
          name: answer,
        })),
        topPortMode: data.topPortMode,
        isLocked: !settings.moveNodes,
      });
    } else if (data.type === WorkflowDefinitionItemType.Template) {
      return new DiagramTemplateNode({
        id: data.templateId!,
        name: data.templateName!,
        topPortMode: data.topPortMode,
        isLocked: !settings.moveNodes,
      });
    } else if (data.type === WorkflowDefinitionItemType.Auto) {
      return new DiagramMultipleNode({
        type: WorkflowDefinitionItemType.Auto,
        autoType: data.autoType,
        items: getOptionsWithId(data.autoType!),
        topPortMode: data.topPortMode,
        isLocked: !settings.moveNodes,
      });
    } else if (data.type === WorkflowDefinitionItemType.Workflow) {
      return new DiagramWorkflowNode({
        id: data.nestedWorkflowId!,
        name: data.nestedWorkflowName!,
        topPortMode: data.topPortMode,
        isLocked: !settings.moveNodes,
      });
    } else if (data.type === WorkflowDefinitionItemType.WorkflowOut) {
      return new DiagramWorkflowOutNode({
        topPortMode: data.topPortMode,
        isLocked: !settings.moveNodes,
      });
    }

    return null;
  };

  const getBottomPortList = (entity: NodeModel): Array<DefaultPortModel> => {
    if (
      entity instanceof DiagramTemplateNode ||
      entity instanceof DiagramWorkflowNode
    ) {
      return [entity.myPortBottom()];
    }

    if (entity instanceof DiagramMultipleNode) {
      const data = entity.myData();
      return data.items.map((x) => entity.myPortItem(x.id));
    }

    return [];
  };

  const handleNodeModalClose = () => {
    setNodeModalData(null);
  };

  const handleRepair = () => {
    if (disableChanges) {
      recalculateRef.current = [DIAGRAM_RECALCULATE_ALL];
      createModel(definition);
      return;
    }

    const newDefinition = getDefinition(engine);
    recalculateRef.current = [DIAGRAM_RECALCULATE_ALL];
    createModel(newDefinition);
  };

  const handleDiagramContainerClick = (
    event: React.MouseEvent<HTMLDivElement>
  ) => {
    if (settings.disableClick) {
      return;
    }

    let element = engine.getMouseElement(event);
    let port: DefaultPortModel | null = null;

    if (!element) {
      setClickCoordinates(null);
      setSelectedEntity(null);
      setSelectedPort(null);
      return;
    }

    if (element instanceof DiagramMultipleNode) {
      const target = event.target as Element;

      const container = Toolkit.closest(
        target,
        `.${DIAGRAM_MULTIPLE_FAKE_PORT_CLASS}[data-id]`
      );
      if (container) {
        const portId = container.getAttribute("data-id");
        if (portId) {
          port = element.myPortItem(portId);
        }
      }
    } else if (element instanceof DefaultPortModel) {
      port = element;
      element = element.getParent();
    }

    if (disableChanges) {
      if (element instanceof DiagramTemplateNode) {
        const data = element.myData();
        if (data.historyItem?.hasRights === true) {
          openDemand?.(data.historyItem);
        }
      }
      return;
    }

    if (linkModePort) {
      const model = engine.getModel();

      if (
        element instanceof DiagramTemplateNode ||
        element instanceof DiagramWorkflowNode ||
        element instanceof DiagramWorkflowOutNode
      ) {
        if (element.myData().disableClick) {
          return;
        }

        deleteEndByPort(linkModePort);

        const link = linkModePort.link(element.myPortTop(), linkFactory);
        setLink(link);
        model.addAll(link);

        handleCloseLinkMode();
      } else if (element instanceof DiagramMultipleNode) {
        if (element.myData().disableClick) {
          return;
        }

        deleteEndByPort(linkModePort);

        const link = linkModePort.link(element.myPortTop(), linkFactory);
        setLink(link);
        model.addAll(link);

        handleCloseLinkMode();
      }

      return;
    }

    const rect = diagramContainerRef.current?.getBoundingClientRect();
    if (!rect) {
      return;
    }
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    setClickCoordinates({ x, y });

    setSelectedEntity(element);
    setSelectedPort(port);
  };

  const handleCloseLinkMode = () => {
    setLinkModePort(null);
    setAllNodeLinkMode(false);
    //Data in node isnt state, we need repaint.
    //engine.repaintCanvas();
    //engine.repaintCanvas() dont work, rearrange all
    recalculateRef.current.push(DIAGRAM_RECALCULATE_ALL);
  };

  const setLink = (link: LinkModel<DefaultLinkModelGenerics>) => {
    if (settings.linkColor) {
      var randomColor =
        DIAGRAM_COLORS[Math.floor(Math.random() * DIAGRAM_COLORS.length)];
      link.getOptions().color = randomColor;
    }

    link.setLocked(!settings.moveLinks);
  };

  const createDagre = () => {
    if (!settings.dagreEnabled) {
      dagreEngine.current = null;
      return;
    }

    dagreEngine.current = new DagreEngine({
      graph: {
        ranker: settings.dagreRanker,
        rankdir: settings.dagreRankdir,
        align: settings.dagreAlign === "UL" ? "UL" : undefined,
        marginx: DIAGRAM_OFFSET_X,
        marginy: DIAGRAM_OFFSET_Y,

        /*
        width?: number | undefined;
        height?: number | undefined;
        compound?: boolean | undefined;
        rankdir?: string | undefined;
        align?: string | undefined;
        nodesep?: number | undefined;
        edgesep?: number | undefined;
        ranksep?: number | undefined;
        marginx?: number | undefined;
        marginy?: number | undefined;
        acyclicer?: string | undefined;
        ranker?: string | undefined;
        */
      },
      includeLinks: settings.dagreLinks,
    });
  };

  const handleSettingsOpen = () => {
    setIsSettingsOpen(true);
  };

  const handleSettingsClose = () => {
    setIsSettingsOpen(false);
  };

  return (
    <>
      <DiagramNodeModal
        initValues={nodeModalInitValues}
        editMode={nodeModalEditMode}
        isOpen={!!nodeModalData}
        close={handleNodeModalClose}
        submit={handleNodeModalSubmit}
      />
      <DiagramSettingsModal
        isOpen={isSettingsOpen}
        settings={settings}
        onClose={handleSettingsClose}
      />
      <DiagramContainer
        optLinkTop={settings.linkTop}
        style={{ height: containerHeight }}
        ref={diagramContainerRef}
        onClick={handleDiagramContainerClick}
      >
        <DiagramButtons>
          <DiagramButton
            type="button"
            ver="secondary"
            onClick={handleSettingsOpen}
            disabled={!!linkModePort}
          >
            {t("common.settings")}
          </DiagramButton>
          <SpaceBetweenButtons />
          <DiagramButton
            type="button"
            ver="secondary"
            onClick={handleRepair}
            disabled={!!linkModePort}
          >
            {t("diagram.repair")}
          </DiagramButton>
        </DiagramButtons>
        {linkModePort && (
          <DiagramLinkModeContainer>
            {t("diagram.linkModeText")}
            <DiagramLinkModeButton
              type="button"
              ver="secondary"
              onClick={handleCloseLinkMode}
            >
              {t("diagram.linkModeButton")}
            </DiagramLinkModeButton>
          </DiagramLinkModeContainer>
        )}
        <DiagramMenu
          entity={selectedEntity}
          coordinates={clickCoordinates}
          port={selectedPort}
          action={handleAction}
        />
        {!isLoading && (
          <CanvasWidget engine={engine} className="diagram-canvas" />
        )}
      </DiagramContainer>
    </>
  );
};

export default Diagram;
