import { AppThunkAction, AppThunkActionAsync } from "..";
import * as actions from "./actions";
import demandApi from "../../api/demand";
import { IDemandFilterType, IDemandListState, selectDemandList } from ".";
import { CancelToken } from "axios";
import {
  listEffectCountFilter,
  listEffectGetFilter,
  listEffectLoad,
  listEffectReload,
  listEffectsCountCheck,
  listEffectsGetCheck,
  listEffectsGetCount,
  listEffectsGetNew,
  listEffectsGetParams,
} from "../listEffect";
import { addDays, formatISO } from "date-fns";
import { DemandStateFilterType } from "../../models/demand";

export function getDemandList(cancelToken: CancelToken): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectDemandList(getState());
    if (listEffectsGetCheck(state.state)) {
      return;
    }

    let newState = listEffectsGetNew(state);
    dispatch(actions.demandListFetchRequest(newState.newCountState));
    try {
      const params = listEffectsGetParams(state, newState.take);
      listEffectGetFilter(params, state.filter);
      addDemandParams(params, state);

      const demands = await demandApi.getDemandList({ params, cancelToken });
      cancelToken.throwIfRequested();
      newState = listEffectsGetCount(newState, demands);

      dispatch(
        actions.demandListFetchSuccess({
          data: demands.data,
          newCountState: newState.newCountState,
          newCount: newState.newCount,
        })
      );
    } catch {
      dispatch(actions.demandListFetchError(cancelToken.reason !== undefined));
    }
  };
}

export function getDemandListCount(
  cancelToken: CancelToken
): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectDemandList(getState());
    if (listEffectsCountCheck(state.countState)) {
      return;
    }

    dispatch(actions.demandListCountRequest());
    try {
      const params = listEffectCountFilter(state.filter);
      addDemandParams(params, state);

      const count = await demandApi.getDemandCount({ params, cancelToken });
      cancelToken.throwIfRequested();

      dispatch(actions.demandListCountSuccess(count.data));
    } catch {
      dispatch(actions.demandListCountError(cancelToken.reason !== undefined));
    }
  };
}

export function changeDemandListOrder(
  orderBy: string,
  orderDesc: boolean
): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.demandListChangeOrder({ orderBy, orderDesc }));
  };
}

export function changeDemandListPage(page: number): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.demandListChangePage(page));
  };
}

export function changeDemandListFilter(
  filter: IDemandFilterType
): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.demandListChangeFilter(filter));
  };
}

export function openDemandListFilter(): AppThunkAction {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(
      actions.demandListChangeFilter({
        ...state.demandList.filter!,
        isOpen: true,
      })
    );
  };
}

export function demandListLoad(reload: boolean): AppThunkAction {
  return async (dispatch, getState) => {
    const state = selectDemandList(getState());
    reload = listEffectReload(reload, state);
    if (reload) {
      dispatch(actions.demandListReset());
    } else {
      const newState = listEffectLoad(state);
      dispatch(
        actions.demandListLoadAction({
          newState: newState.newState,
          newCountState: newState.newCountState,
        })
      );
    }
  };
}

export function addDemandParams(
  params: URLSearchParams,
  state: IDemandListState
) {
  if (state.filter!.from) {
    params.append("From", formatISO(state.filter!.from));
  }
  if (state.filter!.to) {
    params.append("To", formatISO(addDays(state.filter!.to, 1)));
  }
  if (state.filter!.state) {
    switch (state.filter!.state) {
      case DemandStateFilterType.All:
        break;
      case DemandStateFilterType.New:
        params.append("state", "new");
        break;
      case DemandStateFilterType.AfterDeadline:
        params.append("state", "new");
        params.append("afterDeadLine", "true");
        break;
      case DemandStateFilterType.NotAssigned:
        params.append("state", "new");
        params.append("notAssigned", "true");
        break;
      case DemandStateFilterType.NotAssignedAfterDeadline:
        params.append("state", "new");
        params.append("notAssigned", "true");
        params.append("afterDeadLine", "true");
        break;
      case DemandStateFilterType.AssignedAfterDeadline:
        params.append("state", "new");
        params.append("notAssigned", "false");
        break;
      case DemandStateFilterType.Finished:
        params.append("state", "finished");
        break;
      case DemandStateFilterType.Canceled:
        params.append("state", "canceled");
        break;
    }
  }
  for (const group of state.filter!.groups) {
    params.append("Groups", group.id.toString());
  }
  for (const user of state.filter!.users) {
    params.append("Users", user.id.toString());
  }
  for (const creator of state.filter!.creators) {
    params.append("Creators", creator.id.toString());
  }
  if (state.filter!.clientId) {
    params.append("ClientId", state.filter!.clientId.toString());
  }
}
