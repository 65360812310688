import { createAction } from "@reduxjs/toolkit";
import { IDiagramSettings } from "../../models/diagram";
import { DiagramSettingsTypes } from "./types";

export const diagramSettingsFetchRequest = createAction<
  void,
  DiagramSettingsTypes.DIAGRAM_SETTINGS_FETCH_REQUEST
>(DiagramSettingsTypes.DIAGRAM_SETTINGS_FETCH_REQUEST);

export const diagramSettingsFetchSuccess = createAction<
  IDiagramSettings,
  DiagramSettingsTypes.DIAGRAM_SETTINGS_FETCH_SUCCESS
>(DiagramSettingsTypes.DIAGRAM_SETTINGS_FETCH_SUCCESS);

export const diagramSettingsFetchError = createAction<
  void,
  DiagramSettingsTypes.DIAGRAM_SETTINGS_FETCH_ERROR
>(DiagramSettingsTypes.DIAGRAM_SETTINGS_FETCH_ERROR);
