import * as ReselectModule from "reselect";
import { IApplicationState } from "..";
import { IItemIdName } from "../../models/common";
import { StoreState } from "../storeState";

export const selectDefaultDocumentFolderState: ReselectModule.Selector<
  IApplicationState,
  StoreState
> = (state) => state.defaultDocumentFolder.state;

export const selectDefaultDocumentFolder: ReselectModule.Selector<
  IApplicationState,
  IItemIdName[]
> = (state) => state.defaultDocumentFolder.data;
