import { FC } from "react";
import { useTranslation } from "react-i18next";
import Page from "../layout/Page";
import SettingsDashboardLinks from "./SettingsDashboardLinks";

const Settings: FC = () => {
  const { t } = useTranslation();

  return (
    <Page>
      <h1>{t("settings.pageTitle")}</h1>
      <SettingsDashboardLinks />
    </Page>
  );
};

export default Settings;
