import { Form, Formik } from "formik";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { IAssistance } from "../../models/assistance";
import { IApplicationState } from "../../store";
import { selectAssistance, updateAssistance } from "../../store/assistance";
import { ApiError, FormGroup } from "../../styles/form";
import Input from "../common/form/Input";
import * as Yup from "yup";
import assistanceApi from "../../api/assistance";
import { RightType } from "../../models/auth";
import { hasRight } from "../../utils/rights";
import { selectIdentityRights } from "../../store/identity";
import validations from "../../utils/validations";
import { promiseToastSave } from "../../utils/toasts";
import SubmitForm from "../common/form/SubmitForm";
import { errorSet } from "../../utils/error";

interface IProps {
  assistance: IAssistance | null;
  identityRights?: RightType[];
  updateAssistance(newData: IAssistance): void;
}

const AssistanceDescription: FC<IProps> = ({
  assistance,
  identityRights,
  updateAssistance,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (data: IAssistance) => {
    setError(null);
    try {
      await promiseToastSave(async () => {
        const newData = await assistanceApi.updateAssistance(data);
        updateAssistance(newData.data);
      });
    } catch (err) {
      errorSet(setError, err, t);
    }
  };

  return (
    <Formik<IAssistance>
      initialValues={{
        ...assistance!,
        registrationNo: assistance!.registrationNo ?? "",
        conditions: assistance!.conditions ?? "",
        description: assistance!.description ?? "",
      }}
      validationSchema={Yup.object({
        registrationNo: Yup.string().when("isRegistered", {
          is: (isRegistered: any) => isRegistered,
          then: validations.stringRequired(t),
        }),
      })}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, isSubmitting }) => (
        <Form>
          <FormGroup>
            <Input
              name="isInternal"
              type="checkbox"
              label={t("assistance.description.isInternal")}
              inputWidth="1.5rem"
              inputHeight="1.5rem"
            />
            <Input
              name="isRegistered"
              type="checkbox"
              label={t("assistance.description.isRegistered")}
              inputWidth="1.5rem"
              inputHeight="1.5rem"
            />
            <Input
              name="registrationNo"
              label={t("assistance.description.registrationNo")}
              error={touched.registrationNo && !!errors.registrationNo}
              maxLength={150}
            />
            <Input
              name="description"
              component="textarea"
              label={t("assistance.description.description")}
              inputHeight="10rem"
              maxLength={512}
            />
            <Input
              name="conditions"
              component="textarea"
              label={t("assistance.description.conditions")}
              inputHeight="10rem"
              maxLength={512}
            />
          </FormGroup>
          {error && <ApiError>{error}</ApiError>}
          {hasRight(identityRights, [RightType.WriteAssistances]) && (
            <SubmitForm />
          )}
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    assistance: selectAssistance(state),
    identityRights: selectIdentityRights(state),
  };
};

const mapDispachToProps = {
  updateAssistance,
};

export default connect(
  mapStateToProps,
  mapDispachToProps
)(AssistanceDescription);
