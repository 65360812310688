import { createAction } from "@reduxjs/toolkit";
import { IGroupItem } from "../../models/group";
import { IFilterType } from "../filterType";
import { StoreState, StoreStateDontLoad } from "../storeState";
import { GroupListTypes } from "./types";

export const groupListFetchRequest = createAction<
  StoreStateDontLoad,
  GroupListTypes.GROUP_LIST_FETCH_REQUEST
>(GroupListTypes.GROUP_LIST_FETCH_REQUEST);

export const groupListFetchSuccess = createAction<
  { data: IGroupItem[]; newCountState: StoreStateDontLoad; newCount: number },
  GroupListTypes.GROUP_LIST_FETCH_SUCCESS
>(GroupListTypes.GROUP_LIST_FETCH_SUCCESS);

export const groupListFetchError = createAction<
  boolean,
  GroupListTypes.GROUP_LIST_FETCH_ERROR
>(GroupListTypes.GROUP_LIST_FETCH_ERROR);

export const groupListCountRequest = createAction<
  void,
  GroupListTypes.GROUP_LIST_COUNT_REQUEST
>(GroupListTypes.GROUP_LIST_COUNT_REQUEST);

export const groupListCountSuccess = createAction<
  number,
  GroupListTypes.GROUP_LIST_COUNT_SUCCESS
>(GroupListTypes.GROUP_LIST_COUNT_SUCCESS);

export const groupListCountError = createAction<
  boolean,
  GroupListTypes.GROUP_LIST_COUNT_ERROR
>(GroupListTypes.GROUP_LIST_COUNT_ERROR);

export const groupListChangeOrder = createAction<
  { orderBy: string; orderDesc: boolean },
  GroupListTypes.GROUP_LIST_CHANGE_ORDER
>(GroupListTypes.GROUP_LIST_CHANGE_ORDER);

export const groupListChangePage = createAction<
  number,
  GroupListTypes.GROUP_LIST_CHANGE_PAGE
>(GroupListTypes.GROUP_LIST_CHANGE_PAGE);

export const groupListChangeFilter = createAction<
  IFilterType,
  GroupListTypes.GROUP_LIST_CHANGE_FILTER
>(GroupListTypes.GROUP_LIST_CHANGE_FILTER);

export const groupListReset = createAction<
  void,
  GroupListTypes.GROUP_LIST_RESET
>(GroupListTypes.GROUP_LIST_RESET);

export const groupListLoadAction = createAction<
  { newState: StoreState; newCountState: StoreStateDontLoad },
  GroupListTypes.GROUP_LIST_LOAD
>(GroupListTypes.GROUP_LIST_LOAD);
