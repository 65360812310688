import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ClientStateType, IClient } from "../../models/client";
import { IApplicationState } from "../../store";
import { selectClient } from "../../store/client";
import { ApiError } from "../../styles/form";
import clientApi from "../../api/client";
import { downloadBlob } from "../../utils/download";
import { promiseToast } from "../../utils/toasts";
import { SetState } from "../../utils/types";
import { ClientButtonButton, ClientButtonRight } from "./ClientButtonStyles";
import { errorSet } from "../../utils/error";

interface IProps {
  client: IClient | null;
  isWorking: boolean;
  setIsWorking: SetState<boolean>;
}

const ClientButton: FC<IProps> = ({ client, isWorking, setIsWorking }) => {
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);

  const handleExport = async () => {
    setIsWorking(true);
    setError(null);
    try {
      await promiseToast(
        async () => {
          const response = await clientApi.exportClient(client!.id);
          await downloadBlob(response);
        },
        t("pending.download"),
        t("success.download"),
        t("errors.unknown")
      );
    } catch (err) {
      errorSet(setError, err, t);
    }
    setIsWorking(false);
  };

  return (
    <>
      <hr />
      <div>
        <h2>{t("client.button.exportTitle")}</h2>
        {error && <ApiError>{error}</ApiError>}
        <ClientButtonRight>
          <ClientButtonButton
            disabled={isWorking}
            onClick={handleExport}
            ver={
              client!.state !== ClientStateType.Active ? "secondary" : undefined
            }
          >
            {t("client.button.export")}
          </ClientButtonButton>
        </ClientButtonRight>
      </div>
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    client: selectClient(state),
  };
};

export default connect(mapStateToProps)(ClientButton);
