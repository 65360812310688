import * as ReselectModule from "reselect";
import { IApplicationState } from "..";
import { IBasicSettings } from "../../models/basic";
import { StoreState } from "../storeState";

export const selectBasicSettingsState: ReselectModule.Selector<
  IApplicationState,
  StoreState
> = (state) => state.basicSettings.state;

export const selectBasicSettings: ReselectModule.Selector<
  IApplicationState,
  IBasicSettings | null
> = (state) => state.basicSettings.data;
