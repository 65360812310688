import { createAction } from "@reduxjs/toolkit";
import { IIdentity } from "../../models/auth";
import { IdentityTypes } from "./types";

export const identityFetchRequest = createAction<
  void,
  IdentityTypes.IDENTITY_FETCH_REQUEST
>(IdentityTypes.IDENTITY_FETCH_REQUEST);

export const identityFetchSuccess = createAction<
  IIdentity,
  IdentityTypes.IDENTITY_FETCH_SUCCESS
>(IdentityTypes.IDENTITY_FETCH_SUCCESS);

export const identityFetchError = createAction<
  void,
  IdentityTypes.IDENTITY_FETCH_ERROR
>(IdentityTypes.IDENTITY_FETCH_ERROR);
