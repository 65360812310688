import { IAssistancePersonItem } from "../../models/assistance";
import { IFilterType } from "../filterType";
import { IListType } from "../listType";

export enum AssistancePersonListTypes {
  ASSISTANCE_PERSON_LIST_FETCH_REQUEST = "@@assistancePersonList/FETCH_REQUEST",
  ASSISTANCE_PERSON_LIST_FETCH_SUCCESS = "@@assistancePersonList/FETCH_SUCCESS",
  ASSISTANCE_PERSON_LIST_FETCH_ERROR = "@@assistancePersonList/FETCH_ERROR",
  ASSISTANCE_PERSON_LIST_COUNT_REQUEST = "@@assistancePersonList/COUNT_REQUEST",
  ASSISTANCE_PERSON_LIST_COUNT_SUCCESS = "@@assistancePersonList/COUNT_SUCCESS",
  ASSISTANCE_PERSON_LIST_COUNT_ERROR = "@@assistancePersonList/COUNT_ERROR",
  ASSISTANCE_PERSON_LIST_CHANGE_ORDER = "@@assistancePersonList/CHANGE_ORDER",
  ASSISTANCE_PERSON_LIST_CHANGE_PAGE = "@@assistancePersonList/CHANGE_PAGE",
  ASSISTANCE_PERSON_LIST_CHANGE_FILTER = "@@assistancePersonList/CHANGE_FILTER",
  ASSISTANCE_PERSON_LIST_RESET = "@@assistancePersonList/RESET",
  ASSISTANCE_PERSON_LIST_LOAD = "@@assistancePersonList/LOAD",
}

export interface IAssistancePersonListState
  extends IListType<IAssistancePersonItem, IFilterType> {}
