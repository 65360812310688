import {
  faBookOpen,
  faCheck,
  faChevronDown,
  faChevronUp,
  faDownload,
  faFolderOpen,
  faPen,
  faPlus,
  faTimes,
  faTrash,
  faUpload,
  faEye,
  faEyeSlash,
  faPenToSquare,
  faHourglass,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { COLORS } from "../../../styles/colors";

export const GridIconCountdown: FC<{ time?: string | null }> = ({ time }) => {
  const { t } = useTranslation();
  return (
    <GridIconStyle
      style={{ cursor: "help" }}
      icon={faHourglass}
      title={t("gridIcons.countdown", { time })}
    />
  );
};

export const GridIconEdit: FC<{ onClick?(): void }> = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <GridIconStyle
      icon={faPen}
      title={t("gridIcons.edit")}
      onClick={(e) => {
        if (onClick) {
          e.stopPropagation();
          onClick();
        }
      }}
    />
  );
};

export const GridIconDetail: FC<{ onClick?(): void }> = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <GridIconStyle
      icon={faBookOpen}
      title={t("gridIcons.detail")}
      onClick={(e) => {
        if (onClick) {
          e.stopPropagation();
          onClick();
        }
      }}
    />
  );
};

export const GridIconBin: FC<{
  onDrop(item: {
    id: number;
    name: string;
    isFile?: boolean;
    isFolder?: boolean;
  }): void;
  title: string;
}> = ({ onDrop, title }) => (
  <GridIconStyle
    icon={faTrash}
    title={title}
    onDragOver={(e) => e.preventDefault()}
    onDrop={(e) => {
      const item = JSON.parse(e.dataTransfer.getData("item"));
      onDrop(item);
    }}
  />
);

export const GridIconDelete: FC<{ onClick?(): void }> = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <GridIconStyle
      icon={faTrash}
      title={t("gridIcons.delete")}
      onClick={(e) => {
        if (onClick) {
          e.stopPropagation();
          onClick();
        }
      }}
    />
  );
};

export const GridIconRemove: FC<{ onClick?(): void }> = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <GridIconStyle
      icon={faTimes}
      title={t("gridIcons.remove")}
      onClick={(e) => {
        if (onClick) {
          e.stopPropagation();
          onClick();
        }
      }}
    />
  );
};

export const GridIconAdd: FC<{ onClick?(): void }> = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <GridIconStyle
      icon={faPlus}
      title={t("gridIcons.add")}
      onClick={(e) => {
        if (onClick) {
          e.stopPropagation();
          onClick();
        }
      }}
    />
  );
};

export const GridIconFromTemplate: FC<{ onClick?(): void }> = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <GridIconStyle
      icon={faFolderOpen}
      title={t("gridIcons.fromTemplate")}
      onClick={(e) => {
        if (onClick) {
          e.stopPropagation();
          onClick();
        }
      }}
    />
  );
};

export const GridIconSave: FC<{
  disabled?: boolean;
  onClick?(): void;
  title?: string;
}> = ({ disabled, onClick, title }) => {
  const { t } = useTranslation();
  return (
    <GridIconStyle
      icon={faCheck}
      title={title ? title : t("gridIcons.save")}
      onClick={(e) => {
        if (!disabled && onClick) {
          e.stopPropagation();
          onClick();
        }
      }}
    />
  );
};

export const GridIconCancel: FC<{ onClick?(): void }> = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <GridIconStyle
      icon={faTimes}
      title={t("gridIcons.cancel")}
      onClick={(e) => {
        if (onClick) {
          e.stopPropagation();
          onClick();
        }
      }}
    />
  );
};

export const GridIconIsActive: FC = () => {
  const { t } = useTranslation();
  return (
    <FontAwesomeIcon
      icon={faCheck}
      title={t("gridIcons.active")}
      color={COLORS.successIconColor}
    />
  );
};

export const GridIconFolderOpen: FC<{ onClick?(): void }> = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <GridIconStyle
      icon={faFolderOpen}
      title={t("gridIcons.folderOpen")}
      onClick={(e) => {
        if (onClick) {
          e.stopPropagation();
          onClick();
        }
      }}
    />
  );
};

export const GridIconDownload: FC<{ onClick?(): void }> = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <GridIconStyle
      icon={faDownload}
      title={t("gridIcons.download")}
      onClick={(e) => {
        if (onClick) {
          e.stopPropagation();
          onClick();
        }
      }}
    />
  );
};

export const GridIconUpload: FC<{ onClick?(): void }> = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <GridIconStyle
      icon={faUpload}
      title={t("gridIcons.upload")}
      onClick={(e) => {
        if (onClick) {
          e.stopPropagation();
          onClick();
        }
      }}
    />
  );
};

export const GridIconExpand: FC<{ onClick?(): void }> = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <GridIconStyle
      noBorder
      icon={faChevronDown}
      title={t("gridIcons.expand")}
      onClick={(e) => {
        if (onClick) {
          e.stopPropagation();
          onClick();
        }
      }}
    />
  );
};

export const GridIconCollapse: FC<{ onClick?(): void }> = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <GridIconStyle
      noBorder
      icon={faChevronUp}
      title={t("gridIcons.collapse")}
      onClick={(e) => {
        if (onClick) {
          e.stopPropagation();
          onClick();
        }
      }}
    />
  );
};

export const GridCloseEye: FC<{ onClick?(): void }> = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <GridIconStyle
      icon={faEyeSlash}
      title={t("gridIcons.closeEye")}
      onClick={(e) => {
        if (onClick) {
          e.stopPropagation();
          onClick();
        }
      }}
    />
  );
};

export const GridOpenEye: FC<{ onClick?(): void }> = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <GridIconStyle
      icon={faEye}
      title={t("gridIcons.openEye")}
      onClick={(e) => {
        if (onClick) {
          e.stopPropagation();
          onClick();
        }
      }}
    />
  );
};

export const GridIconSignDown: FC<{ onClick?(): void }> = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <GridIconStyle
      onClick={(e) => {
        if (onClick) {
          e.stopPropagation();
          onClick();
        }
      }}
      icon={faPenToSquare}
      title={t("gridIcons.signDown")}
    />
  );
};

export const GridIconSpace = styled.div`
  display: inline-block;
  width: 0.4rem;
`;

export const GridIconStyle = styled(FontAwesomeIcon)<{ noBorder?: boolean }>`
  cursor: pointer;
  padding: 0.3rem;
  width: 2rem !important;
  height: 2rem !important;
  border: ${({ noBorder }) =>
    noBorder ? undefined : "2px solid var(--text-color)"};
  border-radius: 50%;

  &:hover {
    color: var(--table-body-color-hover);
  }
`;
