import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { FormButton } from "../../styles/button";
import {
  ApiError,
  FormGroup,
  InputContainer,
  InputRow,
} from "../../styles/form";
import { demandListLoad } from "../../store/demandList";
import { SpaceBetweenButtons } from "../../styles/spaces";
import Page from "../layout/Page";
import {
  handleLoadClientSuggestions,
  handleLoadWorkflowSuggestions,
} from "../../utils/suggestions";
import Suggestion, { ISuggestionValue } from "../common/suggestion/Suggestion";
import workflowApi from "../../api/workflow";
import {
  IWorkflowQuestion,
  WorkflowSelectedQuestions,
} from "../../models/workflow";
import { errorSet } from "../../utils/error";
import { InputLabel } from "../common/form/InputStyles";
import { TailSpin } from "react-loader-spinner";
import { COLORS } from "../../styles/colors";
import {
  getSelectedAnswers,
  isAllQuestionsSelected,
  renderQuestions,
} from "./DemandQuestions";
import demandApi from "../../api/demand";
import { promiseToastSave } from "../../utils/toasts";

interface IProps {
  demandListLoad(reload: boolean): void;
}

const DemandNew: FC<IProps> = ({ demandListLoad }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedWorkflow, setSelectedWorkflow] = useState<
    ISuggestionValue | undefined
  >();
  const [requireClient, setRequireClient] = useState(false);
  const [selectedClient, setSelectedClient] = useState<
    ISuggestionValue | undefined
  >();
  const [questions, setQuestions] = useState<IWorkflowQuestion[]>([]);
  const [selectedQuestions, setSelectedQuestions] =
    useState<WorkflowSelectedQuestions>({});

  const canSave =
    !loading &&
    !saving &&
    !!selectedWorkflow &&
    (!requireClient || !!selectedClient) &&
    isAllQuestionsSelected(questions, selectedQuestions, null);

  const handleSelectWorkflow = async (value: ISuggestionValue | undefined) => {
    setLoading(true);
    setError(null);
    setSelectedWorkflow(undefined);
    setRequireClient(false);
    setSelectedClient(undefined);
    setQuestions([]);

    if (value) {
      try {
        const res1 = await workflowApi.getRequireClient(value.id);
        setRequireClient(res1.data);

        if (!res1.data) {
          const res2 = await workflowApi.getQuestions(value.id, null);
          setQuestions(res2.data);
        }

        setSelectedWorkflow(value);
      } catch (err) {
        errorSet(setError, err, t);
      }
    }

    setLoading(false);
  };

  const handleSelectClient = async (value: ISuggestionValue | undefined) => {
    setLoading(true);
    setError(null);
    setSelectedClient(undefined);
    setQuestions([]);

    if (value) {
      try {
        const res2 = await workflowApi.getQuestions(
          selectedWorkflow!.id,
          value.id
        );
        setQuestions(res2.data);
        setSelectedClient(value);
      } catch (err) {
        errorSet(setError, err, t);
      }
    }

    setLoading(false);
  };

  const handleSave = async () => {
    setSaving(true);
    setError(null);
    try {
      await promiseToastSave(async () => {
        const answers: number[] = [];
        getSelectedAnswers(answers, questions, selectedQuestions, null);

        await demandApi.createDemand(
          selectedWorkflow!.id,
          requireClient ? selectedClient!.id : null,
          answers
        );

        demandListLoad(true);
        navigate("/demand");
      });
    } catch (err) {
      errorSet(setError, err, t);
    }

    setSaving(false);
  };

  const handleCancel = () => {
    navigate("/demand");
  };

  return (
    <Page>
      <h1>{t("demand.detailTitleNew")}</h1>
      <FormGroup>
        <InputRow>
          <InputLabel htmlFor={"workflowId"}>{t("demand.workflow")}</InputLabel>
          <InputContainer>
            <Suggestion
              id="workflowId"
              selected={selectedWorkflow}
              disabledBySelected={!!selectedWorkflow}
              placeholder={t("demand.workflowPlaceholder")}
              loadSuggestions={handleLoadWorkflowSuggestions}
              selectValue={handleSelectWorkflow}
            />
          </InputContainer>
        </InputRow>
        {requireClient && (
          <InputRow>
            <InputLabel htmlFor={"clientId"}>{t("demand.client")}</InputLabel>
            <InputContainer>
              <Suggestion
                id="clientId"
                selected={selectedClient}
                disabledBySelected={!!selectedClient}
                loadSuggestions={handleLoadClientSuggestions}
                selectValue={handleSelectClient}
              />
            </InputContainer>
          </InputRow>
        )}
        {renderQuestions(
          questions,
          selectedQuestions,
          setSelectedQuestions,
          null
        )}
        {loading && (
          <TailSpin color={COLORS.loaderColor} width={48} height={48} />
        )}
      </FormGroup>
      {error && <ApiError>{error}</ApiError>}
      <FormButton
        type="button"
        ver="secondary"
        disabled={loading}
        onClick={handleCancel}
      >
        {t("common.back")}
      </FormButton>
      <SpaceBetweenButtons />
      <FormButton type="button" disabled={!canSave} onClick={handleSave}>
        {t("common.save")}
      </FormButton>
    </Page>
  );
};

const mapDispachToProps = {
  demandListLoad,
};

export default connect(null, mapDispachToProps)(DemandNew);
