import { createReducer } from "@reduxjs/toolkit";
import { IActionListState } from "./types";
import * as actions from "./actions";
import { StoreState, StoreStateDontLoad } from "../storeState";
import { STORAGE_RESET } from "../storeReset";

const initialState = {
  state: StoreState.None,
  data: [],
  countState: StoreStateDontLoad.DontLoad,
  count: 0,
  page: 0,
  filter: {
    search: undefined,
    isOpen: false,
    isDefault: true,
    assignedUsers: [],
    from: null,
    to: null,
    notAssigned: false,
    clientId: null,
    clientName: null,
  },
} as IActionListState;

export const actionListReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(actions.actionListFetchRequest, (state, action) => ({
      ...state,
      state: StoreState.Loading,
      countState: action.payload,
    }))
    .addCase(actions.actionListFetchSuccess, (state, action) => ({
      ...state,
      state: StoreState.Loaded,
      lastLoaded: new Date(),
      data: action.payload.data,
      countState: action.payload.newCountState,
      count: action.payload.newCount,
    }))
    .addCase(actions.actionListFetchError, (state, action) => ({
      ...state,
      state: action.payload ? StoreState.Cancel : StoreState.Error,
    }))
    .addCase(actions.actionListCountRequest, (state) => ({
      ...state,
      countState: StoreStateDontLoad.Loading,
    }))
    .addCase(actions.actionListCountSuccess, (state, action) => ({
      ...state,
      countState: StoreStateDontLoad.Loaded,
      count: action.payload,
    }))
    .addCase(actions.actionListCountError, (state, action) => ({
      ...state,
      countState: action.payload
        ? StoreStateDontLoad.Cancel
        : StoreStateDontLoad.Error,
    }))
    .addCase(actions.actionListChangeOrder, (state, action) => ({
      ...state,
      state: StoreState.None,
      orderBy: action.payload.orderBy,
      orderDesc: action.payload.orderDesc,
      page: 0,
    }))
    .addCase(actions.actionListChangePage, (state, action) => ({
      ...state,
      state: StoreState.None,
      page: action.payload,
    }))
    .addCase(actions.actionListChangeFilter, (state, action) => ({
      ...state,
      state: StoreState.None,
      countState: StoreStateDontLoad.DontLoad,
      filter: { ...action.payload, isDefault: false },
      page: 0,
    }))
    .addCase(actions.actionListReset, (state) => ({
      ...initialState,
      filter: state.filter,
    }))
    .addCase(actions.actionListLoadAction, (state, action) => ({
      ...state,
      state: action.payload.newState,
      countState: action.payload.newCountState,
    }))
    .addCase(actions.actionListDefaultFilter, (state, action) => ({
      ...state,
      filter: {
        ...state.filter!,
        isDefault: false,
        assignedUsers: action.payload.assignedUsers,
        from: action.payload.from,
        to: action.payload.to,
        notAssigned: false,
        clientId: null,
        clientName: null,
      },
    }))
    .addCase(STORAGE_RESET, () => initialState)
);
