import { selectDiagramSettingsState } from ".";
import { AppThunkAction, AppThunkActionAsync } from "..";
import { StoreState } from "../storeState";
import * as actions from "./actions";
import {
  DIAGRAM_SETTINGS_DEFAULT,
  IDiagramSettings,
} from "../../models/diagram";
import authApi from "../../api/auth";

export function getDiagramSettings(): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectDiagramSettingsState(getState());
    if (state !== StoreState.None) {
      return;
    }

    dispatch(actions.diagramSettingsFetchRequest());
    try {
      const diagramSettings = await authApi.getDiagramSettings();
      const data = {
        ...DIAGRAM_SETTINGS_DEFAULT,
        ...JSON.parse(diagramSettings.data.data),
      };
      dispatch(actions.diagramSettingsFetchSuccess(data));
    } catch {
      dispatch(actions.diagramSettingsFetchError());
    }
  };
}

export function setDiagramSettings(data: IDiagramSettings): AppThunkAction {
  return (dispatch) => {
    dispatch(actions.diagramSettingsFetchSuccess(data));
  };
}
