import { CancelToken } from "axios";
import { FC, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { IApplicationState } from "../../store";
import { IFilterType } from "../../store/filterType";
import FilterPanel from "../common/filter/FilterPanel";
import Grid from "../common/grid/Grid";
import {
  GridIconAdd,
  GridIconDelete,
  GridIconEdit,
  GridIconSpace,
} from "../common/grid/GridIcons";
import { TableCol } from "../common/grid/TableCol";
import { RightType } from "../../models/auth";
import { hasRight } from "../../utils/rights";
import { selectIdentityRights } from "../../store/identity";
import ModuleDocumentFolderModal from "./ModuleDocumentFolderModal";
import defaultDocumentApi from "../../api/defaultDocument";
import { IFolderItem } from "../../models/document";
import {
  changeDefaultDocumentFolderListFilter,
  changeDefaultDocumentFolderListOrder,
  changeDefaultDocumentFolderListPage,
  defaultDocumentFolderListLoad,
  getDefaultDocumentFolderList,
  getDefaultDocumentFolderListCount,
  IDefaultDocumentFolderListState,
  selectDefaultDocumentFolderList,
} from "../../store/defaultDocumentFolderList";
import { promiseToastDeleteNoException } from "../../utils/toasts";
import { ModalYesNoFunction } from "../common/modal/ModalFunctions";

const defaultFolder: IFolderItem = {
  id: 0,
  name: "",
  users: [],
  groups: [],
  roles: [],
  assistances: [],
  isClientVisible: false,
};

interface IProps {
  prov: IDefaultDocumentFolderListState;
  identityRights?: RightType[];
  getDefaultDocumentFolderList(cancelToken: CancelToken): void;
  getDefaultDocumentFolderListCount(cancelToken: CancelToken): void;
  changeDefaultDocumentFolderListOrder(
    orderBy: string,
    orderDesc: boolean
  ): void;
  changeDefaultDocumentFolderListPage(page: number): void;
  changeDefaultDocumentFolderListFilter(filter: IFilterType): void;
  defaultDocumentFolderListLoad(reload: boolean): void;
}

const ModuleDocumentFolder: FC<IProps> = ({
  prov,
  identityRights,
  getDefaultDocumentFolderList,
  getDefaultDocumentFolderListCount,
  changeDefaultDocumentFolderListOrder,
  changeDefaultDocumentFolderListPage,
  changeDefaultDocumentFolderListFilter,
  defaultDocumentFolderListLoad,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { folderId } = useParams();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isAdding, setIsAdding] = useState(false);
  const [openedFolder, setOpenedFolder] = useState<IFolderItem>(defaultFolder);

  useEffect(() => {
    defaultDocumentFolderListLoad(false);
  }, [defaultDocumentFolderListLoad]);

  const handleEdit = (item: IFolderItem) => {
    setIsAdding(false);
    setOpenedFolder(item);
    setIsOpen(true);
  };

  const handleDelete = async (item: IFolderItem) => {
    const res = await ModalYesNoFunction(
      t("module.defaultDocument.folder.delete"),
      t("module.defaultDocument.folder.deleteConfirm")
    );
    if (res) {
      await promiseToastDeleteNoException(async () => {
        await defaultDocumentApi.deleteFolder(item.id);
        defaultDocumentFolderListLoad(true);
      });
    }
  };

  const handleAdd = () => {
    setIsAdding(true);
    setOpenedFolder(defaultFolder);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleConfirm = () => {
    defaultDocumentFolderListLoad(true);
    setIsOpen(false);
  };

  const handleRenderData = (item: IFolderItem): ReactElement => {
    return (
      <>
        <TableCol>{item.name}</TableCol>
        <TableCol>
          {hasRight(identityRights, [
            //todo: right?
            RightType.WriteAllTemplates,
          ]) && (
            <>
              <GridIconEdit onClick={() => handleEdit(item)} />
              <GridIconSpace />
              <GridIconDelete onClick={() => handleDelete(item)} />
            </>
          )}
        </TableCol>
      </>
    );
  };

  const handleRowClick = (item: IFolderItem) => {
    navigate(`/module/default-document/${item.id}`);
  };

  return (
    <>
      <ModuleDocumentFolderModal
        folder={openedFolder}
        parentId={folderId!}
        isAdding={isAdding}
        isOpen={isOpen}
        showIsClientVisible={true}
        createFolder={defaultDocumentApi.createFolder}
        updateFolder={defaultDocumentApi.updateFolder}
        close={handleClose}
        confirm={handleConfirm}
      />

      <FilterPanel
        title={t("module.document.folder.pageTitle")}
        name="moduleDocumentFolder"
        filter={prov.filter!}
        changeFilter={changeDefaultDocumentFolderListFilter}
      />

      <Grid<IFolderItem>
        headers={[
          { captionStr: "module.document.folder.name" },
          {
            captionEl: hasRight(identityRights, [
              //todo: right?
              RightType.WriteAllTemplates,
            ]) ? (
              <GridIconAdd onClick={() => handleAdd()} />
            ) : undefined,
          },
        ]}
        renderData={handleRenderData}
        getData={getDefaultDocumentFolderList}
        getCount={getDefaultDocumentFolderListCount}
        changeOrder={changeDefaultDocumentFolderListOrder}
        changePage={changeDefaultDocumentFolderListPage}
        onRowClick={handleRowClick}
        prov={prov}
      />
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    prov: selectDefaultDocumentFolderList(state),
    identityRights: selectIdentityRights(state),
  };
};

const mapDispachToProps = {
  getDefaultDocumentFolderList,
  getDefaultDocumentFolderListCount,
  changeDefaultDocumentFolderListOrder,
  changeDefaultDocumentFolderListPage,
  changeDefaultDocumentFolderListFilter,
  defaultDocumentFolderListLoad,
};

export default connect(
  mapStateToProps,
  mapDispachToProps
)(ModuleDocumentFolder);
