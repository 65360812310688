import { INotificationItem } from "../../models/notification";
import { IFilterType } from "../filterType";
import { IListType } from "../listType";

export enum NotificationListTypes {
  NOTIFICATION_LIST_FETCH_REQUEST = "@@notificationList/FETCH_REQUEST",
  NOTIFICATION_LIST_FETCH_SUCCESS = "@@notificationList/FETCH_SUCCESS",
  NOTIFICATION_LIST_FETCH_ERROR = "@@notificationList/FETCH_ERROR",
  NOTIFICATION_LIST_COUNT_REQUEST = "@@notificationList/COUNT_REQUEST",
  NOTIFICATION_LIST_COUNT_SUCCESS = "@@notificationList/COUNT_SUCCESS",
  NOTIFICATION_LIST_COUNT_ERROR = "@@notificationList/COUNT_ERROR",
  NOTIFICATION_LIST_CHANGE_ORDER = "@@notificationList/CHANGE_ORDER",
  NOTIFICATION_LIST_CHANGE_PAGE = "@@notificationList/CHANGE_PAGE",
  NOTIFICATION_LIST_CHANGE_FILTER = "@@notificationList/CHANGE_FILTER",
  NOTIFICATION_LIST_RESET = "@@notificationList/RESET",
  NOTIFICATION_LIST_LOAD = "@@notificationList/LOAD",
}

export interface INotificationListState
  extends IListType<INotificationItem, IFilterType> {}
