import { createReducer } from "@reduxjs/toolkit";
import { IAssistanceDocumentListState } from "./types";
import * as actions from "./actions";
import { StoreState, StoreStateDontLoad } from "../storeState";
import { STORAGE_RESET } from "../storeReset";

const initialState = {
  state: StoreState.None,
  data: [],
  countState: StoreStateDontLoad.DontLoad,
  count: 0,
  page: 0,
  orderBy: "Name",
  orderDesc: false,
  filter: { search: undefined, isOpen: false },
} as IAssistanceDocumentListState;

export const assistanceDocumentListReducer = createReducer(
  initialState,
  (builder) =>
    builder
      .addCase(actions.assistanceDocumentListFetchRequest, (state, action) => ({
        ...state,
        state: StoreState.Loading,
        countState: action.payload,
      }))
      .addCase(actions.assistanceDocumentListFetchSuccess, (state, action) => ({
        ...state,
        state: StoreState.Loaded,
        lastLoaded: new Date(),
        data: action.payload.data,
        countState: action.payload.newCountState,
        count: action.payload.newCount,
      }))
      .addCase(actions.assistanceDocumentListFetchError, (state, action) => ({
        ...state,
        state: action.payload ? StoreState.Cancel : StoreState.Error,
      }))
      .addCase(actions.assistanceDocumentListCountRequest, (state) => ({
        ...state,
        countState: StoreStateDontLoad.Loading,
      }))
      .addCase(actions.assistanceDocumentListCountSuccess, (state, action) => ({
        ...state,
        countState: StoreStateDontLoad.Loaded,
        count: action.payload,
      }))
      .addCase(actions.assistanceDocumentListCountError, (state, action) => ({
        ...state,
        countState: action.payload
          ? StoreStateDontLoad.Cancel
          : StoreStateDontLoad.Error,
      }))
      .addCase(actions.assistanceDocumentListChangeOrder, (state, action) => ({
        ...state,
        state: StoreState.None,
        orderBy: action.payload.orderBy,
        orderDesc: action.payload.orderDesc,
        page: 0,
      }))
      .addCase(actions.assistanceDocumentListChangePage, (state, action) => ({
        ...state,
        state: StoreState.None,
        page: action.payload,
      }))
      .addCase(actions.assistanceDocumentListChangeFilter, (state, action) => ({
        ...state,
        state: StoreState.None,
        countState: StoreStateDontLoad.DontLoad,
        filter: action.payload,
        page: 0,
      }))
      .addCase(actions.assistanceDocumentListReset, (state) => ({
        ...initialState,
        filter: state.filter,
      }))
      .addCase(actions.assistanceDocumentListLoadAction, (state, action) => ({
        ...state,
        state: action.payload.newState,
        countState: action.payload.newCountState,
      }))
      .addCase(STORAGE_RESET, () => initialState)
);
