import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { ClientStateType } from "../../models/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as BasicClientIcon } from "../../images/client-basic.svg";
import { ReactComponent as RejectedClientIcon } from "../../images/client-rejected.svg";
import { ReactComponent as AnonymizedClientIcon } from "../../images/client-anonymized.svg";
import { TFunction } from "i18next";

// todo colors
export const getClientStateColor = (clientState: ClientStateType) => {
  switch (clientState) {
    case ClientStateType.Basic:
      return "#61BD5B";
    case ClientStateType.New:
      return "#FDDE62";
    case ClientStateType.Active:
      return "#61BD5B";
    case ClientStateType.InActive:
      return "#CDCFD3";
    case ClientStateType.Rejected:
      return "#E3444C";
    case ClientStateType.Dead:
      return "#E3444C";
    case ClientStateType.Anonymised:
      return "#CDCFD3";
  }
};

export const getClientStateIcon = (
  clientState: ClientStateType,
  t: TFunction
) => {
  switch (clientState) {
    case ClientStateType.Basic:
      return <BasicClientIcon title={t("client.states." + clientState)} />;
    case ClientStateType.New:
    case ClientStateType.Active:
    case ClientStateType.InActive:
    case ClientStateType.Dead:
      return (
        <FontAwesomeIcon
          icon={faCircle}
          color={getClientStateColor(clientState)}
          title={t("client.states." + clientState)}
        />
      );
    case ClientStateType.Rejected:
      return <RejectedClientIcon title={t("client.states." + clientState)} />;
    case ClientStateType.Anonymised:
      return <AnonymizedClientIcon title={t("client.states." + clientState)} />;
  }
};
