import { IChangeDemandItem } from "../../models/changeDemads";
import { IFilterType } from "../filterType";
import { IListType } from "../listType";

export enum ChangeDemandListTypes {
  CHANGE_DEMAND_LIST_FETCH_REQUEST = "@@changeDemandList/FETCH_REQUEST",
  CHANGE_DEMAND_LIST_FETCH_ERROR = "@@changeDemandList/FETCH_ERROR",
  CHANGE_DEMAND_LIST_FETCH_SUCCESS = "@@changeDemandList/FETCH_SUCCESS",

  CHANGE_DEMAND_LIST_COUNT_REQUEST = "@@changeDemandList/COUNT_REQUEST",
  CHANGE_DEMAND_LIST_COUNT_SUCCESS = "@@changeDemandList/COUNT_SUCCESS",
  CHANGE_DEMAND_LIST_COUNT_ERROR = "@@changeDemandList/COUNT_ERROR",

  CHANGE_DEMAND_LIST_RESET = "@@changeDemandList/RESET",
  CHANGE_DEMAND_LIST_LOAD = "@@changeDemandList/LOAD",

  CHANGE_DEMAND_LIST_ORDER = "@@changeDemandList/ORDER",
  CHANGE_DEMAND_LIST_PAGE = "@@changeDemandList/PAGE",
  CHANGE_DEMAND_LIST_FILTER = "@@changeDemandList/FILTER",
}

export interface IChangeDemandFilterType extends IFilterType {
  from?: Date;
  to?: Date;
  clientId?: number;
  clientName?: string;
  state: string;
}
export interface IChangeDemandListState
  extends IListType<IChangeDemandItem, IChangeDemandFilterType> {}
