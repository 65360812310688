import { TFunction } from "i18next";
import { render } from "react-dom";
import documentApi from "../../api/document";
import { errorToast } from "../../utils/toasts";
import DocumentEditOnline from "./DocumentEditOnline";

export async function startDocumentEditOnline(
  documentId: number,
  t: TFunction
) {
  try {
    const response = await documentApi.getEditOnlineUrl(documentId);

    const el = document.createElement("div");
    document.body.appendChild(el);
    render(
      <DocumentEditOnline
        token={response.data.token}
        url={response.data.url}
      />,
      el
    );
  } catch (err) {
    errorToast();
  }
}
