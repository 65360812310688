import { createAction } from "@reduxjs/toolkit";
import { INotification } from "../../models/notification";
import { NotificationTypes } from "./types";

export const notificationFetchRequest = createAction<
  void,
  NotificationTypes.NOTIFICATION_FETCH_REQUEST
>(NotificationTypes.NOTIFICATION_FETCH_REQUEST);

export const notificationFetchSuccess = createAction<
  INotification,
  NotificationTypes.NOTIFICATION_FETCH_SUCCESS
>(NotificationTypes.NOTIFICATION_FETCH_SUCCESS);

export const notificationFetchError = createAction<
  void,
  NotificationTypes.NOTIFICATION_FETCH_ERROR
>(NotificationTypes.NOTIFICATION_FETCH_ERROR);
