import * as ReselectModule from "reselect";
import { IApplicationState } from "..";
import { AssistanceStateType, IAssistance } from "../../models/assistance";
import { StoreState } from "../storeState";

export const selectAssistanceState: ReselectModule.Selector<
  IApplicationState,
  StoreState
> = (state) => state.assistance.state;

export const selectAssistance: ReselectModule.Selector<
  IApplicationState,
  IAssistance | null
> = (state) => state.assistance.data;

export const selectAssistanceId: ReselectModule.Selector<
  IApplicationState,
  number | undefined
> = (state) => state.assistance.data?.id;

export const selectAssistanceName: ReselectModule.Selector<
  IApplicationState,
  string | undefined
> = (state) => state.assistance.data?.name;

export const selectAssistanceDataState: ReselectModule.Selector<
  IApplicationState,
  AssistanceStateType | undefined
> = (state) => state.assistance.data?.state;
