import { AppThunkActionAsync } from "..";
import * as actions from "./actions";
import demandApi from "../../api/demand";

export function getDemandHistory(demandId: number): AppThunkActionAsync {
  return async (dispatch) => {
    dispatch(actions.demandHistoryFetchRequest());
    try {
      const demand = await demandApi.getDemandHistory(demandId);
      dispatch(actions.demandHistoryFetchSuccess(demand.data));
    } catch {
      dispatch(actions.demandHistoryFetchError());
    }
  };
}
