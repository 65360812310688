import { IClientNoteItem } from "../../models/client";
import { IFilterType } from "../filterType";
import { IListType } from "../listType";

export enum ClientNoteListTypes {
  CLIENT_NOTE_LIST_FETCH_REQUEST = "@@clientNoteList/FETCH_REQUEST",
  CLIENT_NOTE_LIST_FETCH_SUCCESS = "@@clientNoteList/FETCH_SUCCESS",
  CLIENT_NOTE_LIST_FETCH_ERROR = "@@clientNoteList/FETCH_ERROR",
  CLIENT_NOTE_LIST_COUNT_REQUEST = "@@clientNoteList/COUNT_REQUEST",
  CLIENT_NOTE_LIST_COUNT_SUCCESS = "@@clientNoteList/COUNT_SUCCESS",
  CLIENT_NOTE_LIST_COUNT_ERROR = "@@clientNoteList/COUNT_ERROR",
  CLIENT_NOTE_LIST_CHANGE_ORDER = "@@clientNoteList/CHANGE_ORDER",
  CLIENT_NOTE_LIST_CHANGE_PAGE = "@@clientNoteList/CHANGE_PAGE",
  CLIENT_NOTE_LIST_CHANGE_FILTER = "@@clientNoteList/CHANGE_FILTER",
  CLIENT_NOTE_LIST_RESET = "@@clientNoteList/RESET",
  CLIENT_NOTE_LIST_LOAD = "@@clientNoteList/LOAD",
}

export interface IClientNoteListState
  extends IListType<IClientNoteItem, IFilterType> {}
