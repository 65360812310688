import { useMemo, useState } from "react";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { IItemIdName } from "../../models/common";
import { IApplicationState } from "../../store";
import { StoreState } from "../../store/storeState";
import {
  changeTemplateDocumentListFilter,
  ITemplateDocumentListFilter,
  ITemplateDocumentListState,
  selectTemplateDocumentList,
} from "../../store/templateDocumentList";
import {
  getDefaultTemplateFolder,
  getTemplateFolder,
  selectTemplateFolder,
  selectTemplateFolderState,
} from "../../store/templateFolder";
import {
  changeTemplateFolderListFilter,
  ITemplateFolderListFilter,
  ITemplateFolderListState,
  selectTemplateFolderList,
} from "../../store/templateFolderList";
import { COLORS } from "../../styles/colors";
import { VerticalSpace } from "../../styles/spaces";
import Loader from "../common/Loader";
import BreadCrumb from "../common/navigation/BreadCrumb";
import ModuleDocumentDocument from "./ModuleDocumentDocument";
import ModuleDocumentFolder from "./ModuleDocumentFolder";
import { TailSpin } from "react-loader-spinner";
import { SpanRed } from "../../styles/text";
import Page from "../layout/Page";

interface IProps {
  templateFolderState: StoreState;
  templateFolder: IItemIdName[];
  templateFolderListState: ITemplateFolderListState;
  templateDocumentListState: ITemplateDocumentListState;
  getTemplateFolder(folderId: string): void;
  getDefaultTemplateFolder(): void;
  changeTemplateFolderListFilter(filter: ITemplateFolderListFilter): void;
  changeTemplateDocumentListFilter(filter: ITemplateDocumentListFilter): void;
}

const ModuleDocument: FC<IProps> = ({
  templateFolderState,
  templateFolder,
  templateFolderListState,
  templateDocumentListState,
  getTemplateFolder,
  getDefaultTemplateFolder,
  changeTemplateFolderListFilter,
  changeTemplateDocumentListFilter,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const { folderId } = useParams();

  useEffect(() => {
    if (folderId) {
      getTemplateFolder(folderId);
    } else {
      getDefaultTemplateFolder();
    }

    changeTemplateFolderListFilter({
      ...templateFolderListState.filter!,
      idFolder: folderId ? parseInt(folderId) : null,
    });
    changeTemplateDocumentListFilter({
      ...templateDocumentListState.filter!,
      idFolder: folderId ? parseInt(folderId) : null,
    });

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderId]);

  const breadCrumbItems = useMemo(() => {
    const items = [];
    items.push({ label: t("module.document.root"), to: "/module/document" });

    items.push(
      ...templateFolder.map((x) => ({
        label: x.name,
        to: `/module/document/${x.id}`,
      }))
    );

    return items;
  }, [templateFolder, t]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Page>
      <h1>{t("module.document.pageTitle")}</h1>
      <p>
        {templateFolderState === StoreState.Loading && (
          <TailSpin color={COLORS.loaderColor} width={16} height={16} />
        )}
        {templateFolderState === StoreState.Error && (
          <SpanRed>{t("errors.unknown")}</SpanRed>
        )}
        {templateFolderState === StoreState.Loaded && (
          <BreadCrumb items={breadCrumbItems} />
        )}
      </p>
      <VerticalSpace />
      <ModuleDocumentFolder />
      <VerticalSpace />
      <ModuleDocumentDocument />
    </Page>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    templateFolderState: selectTemplateFolderState(state),
    templateFolder: selectTemplateFolder(state),
    templateFolderListState: selectTemplateFolderList(state),
    templateDocumentListState: selectTemplateDocumentList(state),
  };
};

const mapDispachToProps = {
  getTemplateFolder,
  getDefaultTemplateFolder,
  changeTemplateFolderListFilter,
  changeTemplateDocumentListFilter,
};

export default connect(mapStateToProps, mapDispachToProps)(ModuleDocument);
