import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { IApplicationState } from "../../store";
import { ApiError } from "../../styles/form";
import assistanceApi from "../../api/assistance";
import { ActionButton } from "../../styles/button";
import { selectAssistance, updateAssistance } from "../../store/assistance";
import { AssistanceStateType, IAssistance } from "../../models/assistance";
import { promiseToastSave } from "../../utils/toasts";
import { assistanceListLoad } from "../../store/assistanceList";
import { errorSet } from "../../utils/error";

interface IProps {
  assistance: IAssistance | null;
  updateAssistance(newData: IAssistance): void;
  assistanceListLoad(reload: boolean): void;
}

const AssistanceButton: FC<IProps> = ({
  assistance,
  updateAssistance,
  assistanceListLoad,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);
  const [isWorking, setIsWorking] = useState(false);

  const changeState = async (newState: AssistanceStateType) => {
    setIsWorking(true);
    setError(null);
    try {
      await promiseToastSave(async () => {
        const response = await assistanceApi.changeAssistanceState(
          assistance!.id,
          newState
        );
        updateAssistance(response.data);
        assistanceListLoad(true);
      });
    } catch (err) {
      errorSet(setError, err, t);
    }
    setIsWorking(false);
  };

  const handleActivate = async () => {
    await changeState(AssistanceStateType.Active);
  };

  const handleDeactivate = async () => {
    await changeState(AssistanceStateType.InActive);
  };

  return (
    <>
      {error && <ApiError>{error}</ApiError>}
      {assistance!.state !== AssistanceStateType.Active && (
        <>
          <ActionButton disabled={isWorking} onClick={handleActivate}>
            {assistance!.state === AssistanceStateType.New
              ? t("assistance.button.approve")
              : t("assistance.button.activate")}
          </ActionButton>
        </>
      )}
      {assistance!.state === AssistanceStateType.Active && (
        <>
          <ActionButton
            disabled={isWorking}
            onClick={handleDeactivate}
            ver="secondary"
          >
            {t("assistance.button.deactivate")}
          </ActionButton>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    assistance: selectAssistance(state),
  };
};

const mapDispachToProps = {
  updateAssistance,
  assistanceListLoad,
};

export default connect(mapStateToProps, mapDispachToProps)(AssistanceButton);
