import { FC } from "react";
import { PageContent, PageContentWrapper } from "./LayoutStyles";

interface IProps {
  hasTopPanel?: boolean;
  maxWidth?: boolean;
}

const Page: FC<IProps> = ({ hasTopPanel, maxWidth, children }) => {
  return (
    <PageContent>
      <PageContentWrapper hasTopPanel={hasTopPanel} maxWidth={maxWidth}>
        {children}
      </PageContentWrapper>
    </PageContent>
  );
};

export default Page;
