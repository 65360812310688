import { createAction } from "@reduxjs/toolkit";
import { IHealthInsuranceItem } from "../../models/healthInsurance";
import { IFilterType } from "../filterType";
import { StoreState, StoreStateDontLoad } from "../storeState";
import { HealthInsuranceListTypes } from "./types";

export const healthInsuranceListFetchRequest = createAction<
  StoreStateDontLoad,
  HealthInsuranceListTypes.HEALTH_INSURANCE_LIST_FETCH_REQUEST
>(HealthInsuranceListTypes.HEALTH_INSURANCE_LIST_FETCH_REQUEST);

export const healthInsuranceListFetchSuccess = createAction<
  {
    data: IHealthInsuranceItem[];
    newCountState: StoreStateDontLoad;
    newCount: number;
  },
  HealthInsuranceListTypes.HEALTH_INSURANCE_LIST_FETCH_SUCCESS
>(HealthInsuranceListTypes.HEALTH_INSURANCE_LIST_FETCH_SUCCESS);

export const healthInsuranceListFetchError = createAction<
  boolean,
  HealthInsuranceListTypes.HEALTH_INSURANCE_LIST_FETCH_ERROR
>(HealthInsuranceListTypes.HEALTH_INSURANCE_LIST_FETCH_ERROR);

export const healthInsuranceListCountRequest = createAction<
  void,
  HealthInsuranceListTypes.HEALTH_INSURANCE_LIST_COUNT_REQUEST
>(HealthInsuranceListTypes.HEALTH_INSURANCE_LIST_COUNT_REQUEST);

export const healthInsuranceListCountSuccess = createAction<
  number,
  HealthInsuranceListTypes.HEALTH_INSURANCE_LIST_COUNT_SUCCESS
>(HealthInsuranceListTypes.HEALTH_INSURANCE_LIST_COUNT_SUCCESS);

export const healthInsuranceListCountError = createAction<
  boolean,
  HealthInsuranceListTypes.HEALTH_INSURANCE_LIST_COUNT_ERROR
>(HealthInsuranceListTypes.HEALTH_INSURANCE_LIST_COUNT_ERROR);

export const healthInsuranceListChangeOrder = createAction<
  { orderBy: string; orderDesc: boolean },
  HealthInsuranceListTypes.HEALTH_INSURANCE_LIST_CHANGE_ORDER
>(HealthInsuranceListTypes.HEALTH_INSURANCE_LIST_CHANGE_ORDER);

export const healthInsuranceListChangePage = createAction<
  number,
  HealthInsuranceListTypes.HEALTH_INSURANCE_LIST_CHANGE_PAGE
>(HealthInsuranceListTypes.HEALTH_INSURANCE_LIST_CHANGE_PAGE);

export const healthInsuranceListChangeFilter = createAction<
  IFilterType,
  HealthInsuranceListTypes.HEALTH_INSURANCE_LIST_CHANGE_FILTER
>(HealthInsuranceListTypes.HEALTH_INSURANCE_LIST_CHANGE_FILTER);

export const healthInsuranceListReset = createAction<
  void,
  HealthInsuranceListTypes.HEALTH_INSURANCE_LIST_RESET
>(HealthInsuranceListTypes.HEALTH_INSURANCE_LIST_RESET);

export const healthInsuranceListLoadAction = createAction<
  { newState: StoreState; newCountState: StoreStateDontLoad },
  HealthInsuranceListTypes.HEALTH_INSURANCE_LIST_LOAD
>(HealthInsuranceListTypes.HEALTH_INSURANCE_LIST_LOAD);
