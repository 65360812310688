import { AxiosResponse, CancelToken } from "axios";
import { FC, ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { IAssistanceItem } from "../../models/assistance";
import { IApplicationState } from "../../store";
import { IFilterType } from "../../store/filterType";
import {
  changeAssistanceListFilter,
  changeAssistanceListOrder,
  changeAssistanceListPage,
  getAssistanceList,
  getAssistanceListCount,
  IAssistanceListState,
  selectAssistanceList,
  assistanceListLoad,
  addAssistanceParams,
} from "../../store/assistanceList";
import FilterPanel from "../common/filter/FilterPanel";
import Grid from "../common/grid/Grid";
import { GridIconAdd, GridIconDetail } from "../common/grid/GridIcons";
import { TableCol, TableColCenter } from "../common/grid/TableCol";
import Page from "../layout/Page";
import { RightType } from "../../models/auth";
import { hasRight } from "../../utils/rights";
import { selectIdentityRights } from "../../store/identity";
import { ExportType } from "../../models/common";
import {
  listEffectGetFilter,
  listEffectsGetParamsForExport,
} from "../../store/listEffect";
import assistanceApi from "../../api/assistance";
import { getAssistanceStateIcon } from "./AssistanceHelpers";
import AssistanceFilter from "./AssistanceFilter";

interface IProps {
  prov: IAssistanceListState;
  identityRights?: RightType[];
  getAssistanceList(cancelToken: CancelToken): void;
  getAssistanceListCount(cancelToken: CancelToken): void;
  changeAssistanceListOrder(orderBy: string, orderDesc: boolean): void;
  changeAssistanceListPage(page: number): void;
  changeAssistanceListFilter(filter: IFilterType): void;
  assistanceListLoad(reload: boolean): void;
}

const Assistance: FC<IProps> = ({
  prov,
  identityRights,
  getAssistanceList,
  getAssistanceListCount,
  changeAssistanceListOrder,
  changeAssistanceListPage,
  changeAssistanceListFilter,
  assistanceListLoad,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    assistanceListLoad(false);
  }, [assistanceListLoad]);

  const handleRenderData = (item: IAssistanceItem): ReactElement => {
    return (
      <>
        <TableColCenter>{getAssistanceStateIcon(item.state, t)}</TableColCenter>
        <TableCol>{item.name}</TableCol>
        <TableCol>
          <NavLink to={`/assistance/${item.id}/general`}>
            <GridIconDetail />
          </NavLink>
        </TableCol>
      </>
    );
  };

  const handleRowClick = (item: IAssistanceItem) => {
    navigate(`/assistance/${item.id}/general`);
  };

  const handleExport = async (
    type: ExportType
  ): Promise<AxiosResponse<Blob>> => {
    const params = listEffectsGetParamsForExport(prov);
    listEffectGetFilter(params, prov.filter);
    addAssistanceParams(params, prov);

    if (type === ExportType.Csv) {
      return await assistanceApi.assistanceListExportCsv(params);
    }

    return await assistanceApi.assistanceListExportXlsx(params);
  };

  return (
    <Page>
      <FilterPanel
        title={t("assistance.pageTitle")}
        name="assistance"
        filter={prov.filter!}
        changeFilter={changeAssistanceListFilter}
        doExport={handleExport}
        initialValues={{
          state: prov.filter!.state,
          assistanceCategoryId: prov.filter!.assistanceCategoryId,
          assistanceCategoryName: prov.filter!.assistanceCategoryName,
          careTypeId: prov.filter!.careTypeId,
          careTypeName: prov.filter!.careTypeName,
          targetGroupId: prov.filter!.targetGroupId,
          targetGroupName: prov.filter!.targetGroupName,
        }}
      >
        <AssistanceFilter />
      </FilterPanel>
      <Grid<IAssistanceItem>
        headers={[
          {
            captionStr: "assistance.state",
            minContent: true,
            orderName: "State",
          },
          { captionStr: "assistance.name", orderName: "Name" },
          {
            captionEl: hasRight(identityRights, [
              RightType.WriteAssistances,
            ]) ? (
              <NavLink to={`/assistance/new/general`}>
                <GridIconAdd />
              </NavLink>
            ) : undefined,
          },
        ]}
        renderData={handleRenderData}
        getData={getAssistanceList}
        getCount={getAssistanceListCount}
        changeOrder={changeAssistanceListOrder}
        changePage={changeAssistanceListPage}
        onRowClick={handleRowClick}
        prov={prov}
      />
    </Page>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    prov: selectAssistanceList(state),
    identityRights: selectIdentityRights(state),
  };
};

const mapDispachToProps = {
  getAssistanceList,
  getAssistanceListCount,
  changeAssistanceListOrder,
  changeAssistanceListPage,
  changeAssistanceListFilter,
  assistanceListLoad,
};

export default connect(mapStateToProps, mapDispachToProps)(Assistance);
