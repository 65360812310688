import { AppThunkAction, AppThunkActionAsync } from "..";
import * as actions from "./actions";
import clientApi from "../../api/client";
import { IClientHouse } from "../../models/client";
import { selectClientHouseState } from ".";
import { StoreState } from "../storeState";

export function getClientHouse(clientId: number): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectClientHouseState(getState());
    if (state === StoreState.Loading || state === StoreState.Loaded) {
      return;
    }

    dispatch(actions.clientHouseFetchRequest());
    try {
      const clientHouse = await clientApi.getClientHouse(clientId);
      dispatch(actions.clientHouseFetchSuccess(clientHouse.data));
    } catch {
      dispatch(actions.clientHouseFetchError());
    }
  };
}

export function updateClientHouse(newData: IClientHouse): AppThunkAction {
  return (dispatch) => {
    dispatch(actions.clientHouseFetchSuccess(newData));
  };
}

export function resetClientHouse(): AppThunkAction {
  return (dispatch) => {
    dispatch(actions.clientHouseReset());
  };
}
