import { FC } from "react";
import { VerticalSpace } from "../../styles/spaces";
import ClientFinanceBenefit from "./ClientFinanceBenefit";
import ClientFinanceDebt from "./ClientFinanceDebt";
import ClientFinanceRevenue from "./ClientFinanceRevenue";

const ClientFinance: FC = () => {
  return (
    <>
      <ClientFinanceRevenue />
      <VerticalSpace />
      <ClientFinanceBenefit />
      <VerticalSpace />
      <ClientFinanceDebt />
    </>
  );
};

export default ClientFinance;
