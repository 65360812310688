import { AppThunkAction, AppThunkActionAsync } from "..";
import * as actions from "./actions";
import clientApi from "../../api/client";
import { ClientStateType, IClient } from "../../models/client";

export function getClient(clientId: number): AppThunkActionAsync {
  return async (dispatch) => {
    dispatch(actions.clientFetchRequest());
    try {
      const client = await clientApi.getClient(clientId);
      dispatch(actions.clientFetchSuccess(client.data));
    } catch {
      dispatch(actions.clientFetchError());
    }
  };
}

export function getDefaultClient(): AppThunkAction {
  return (dispatch) => {
    const data = {
      id: 0,
      state: ClientStateType.New,
      firstName: "",
      lastName: "",

      fullRegistration: false,

      addressActualIsSame: true,
      addressMailIsSame: true,
    };

    dispatch(actions.clientFetchSuccess(data));
  };
}

export function updateClient(newData: IClient): AppThunkAction {
  return (dispatch) => {
    dispatch(actions.clientFetchSuccess(newData));
  };
}
