import { useFormikContext } from "formik";
import { FC, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { timeSlots } from "../../utils/calendar";
import Input from "../common/form/Input";
import { IActionForm } from "./CalendarListDetail";
import {
  CalendarModalTimes,
  CalendarModalTimesContainer,
  CalendarModalTimesLabel,
} from "./CalendarStyles";

const CalendarTimes: FC = () => {
  const { values, setValues } = useFormikContext<IActionForm>();
  const { t } = useTranslation();

  const timeOptionsFrom = useMemo(
    () =>
      timeSlots.slice(0, -1).map((val) => (
        <option key={val} value={val}>
          {val}
        </option>
      )),
    []
  );

  const timeOptionsTo = useMemo(
    () =>
      timeSlots
        .filter((x) => x > values.timeFrom!)
        .map((val) => (
          <option key={val} value={val}>
            {val}
          </option>
        )),
    [values.timeFrom]
  );

  useEffect(() => {
    const filtered = timeSlots.filter((x) => x > values.timeFrom!);

    if (filtered[0] > values.timeTo!) {
      setValues({ ...values, timeTo: filtered[0] });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.timeFrom]);

  return (
    <CalendarModalTimesContainer>
      <label>{t("client.action.time")}</label>
      <CalendarModalTimes>
        <div>
          <CalendarModalTimesLabel>{t("common.from")}</CalendarModalTimesLabel>
          <Input name="timeFrom" component="select">
            {timeOptionsFrom}
          </Input>
        </div>
        <div>
          <CalendarModalTimesLabel>{t("common.to")}</CalendarModalTimesLabel>
          <Input name="timeTo" component="select">
            {timeOptionsTo}
          </Input>
        </div>
      </CalendarModalTimes>
    </CalendarModalTimesContainer>
  );
};

export default CalendarTimes;
