import styled from "styled-components";
import { SIZES } from "../../../styles/sizes";
import { GridHeaderContainer } from "./GridStyles";

// everything inside <td> should be wrapped around by a <div> to prevent collapsing

// todo colors
export const Table = styled.table<{
  numberOfColumns?: number;
  checkbox?: boolean;
  buttons?: boolean;
  firstColumnWidth?: string;
  secondColumnWidth?: string;
  thirdColumnWidth?: string;
  fourthColumnWidth?: string;
}>`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 0.65rem;

  tr {
    transition: background-color 0.2s ease-in-out;
  }

  tr .min-content {
    width: 1%;
  }

  tbody {
    tr {
      height: 2.5rem;
      td:nth-child(${({ checkbox }) => (checkbox ? "n + 2" : "n + 1")})${({
          buttons,
        }) => (buttons !== false ? ":not(:last-child)" : "")} {
        background-color: var(--table-body-bg);

        &::before {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: -1;
          box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.55);
        }
      }

      &:hover {
        td:nth-child(${({ checkbox }) => (checkbox ? "n + 2" : "n + 1")})${({
            buttons,
          }) => buttons !== false && ":not(:last-child)"} {
          background-color: #fbf5ee;
        }
      }
    }
  }

  th {
    font-weight: bold;
    white-space: pre;
  }

  td {
    position: relative;
    max-width: calc(
      ${({ numberOfColumns }) =>
        numberOfColumns ? `120vw / ${numberOfColumns}` : "100%"}
    );
  }

  td,
  th {
    padding: 0.35rem 1rem;
    svg {
      width: 1rem;
    }

    :last-child {
      ${({ buttons }) => buttons !== false && "width: 1%;"}
      ${({ buttons }) => buttons !== false && "padding-right: 0;"}
      ${GridHeaderContainer} {
        ${({ buttons }) => buttons !== false && "justify-content: flex-end;"}
      }
    }
    :first-child {
      ${({ checkbox }) => checkbox !== false && "width: 1%;"}
      ${({ checkbox }) => checkbox !== false && "padding-left: 0;"}
      ${({ firstColumnWidth, numberOfColumns }) =>
        firstColumnWidth &&
        `max-width: calc(${firstColumnWidth} / ${numberOfColumns ?? 1});`}
    }
    :nth-child(3) {
      ${({ secondColumnWidth, numberOfColumns }) =>
        secondColumnWidth &&
        `max-width: calc(${secondColumnWidth} / ${numberOfColumns ?? 1});`}
    }
    :nth-child(3) {
      ${({ thirdColumnWidth, numberOfColumns }) =>
        thirdColumnWidth &&
        `max-width: calc(${thirdColumnWidth} / ${numberOfColumns ?? 1});`}
    }
    :nth-child(4) {
      ${({ fourthColumnWidth, numberOfColumns }) =>
        fourthColumnWidth &&
        `max-width: calc(${fourthColumnWidth} / ${numberOfColumns ?? 1});`}
    }
  }

  .width100 {
    width: 100%;
    word-break: break-all;
    word-wrap: break-word;
  }

  .center {
    margin: 0 auto;
  }

  .nowrap {
    white-space: nowrap;
  }

  .extra-text {
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;

    word-break: break-all;
    word-wrap: break-word;
  }

  .error {
    color: var(--error-color);
  }

  .warning {
    color: var(--warning-color);
  }

  .pointer {
    cursor: pointer;
  }
`;

export const DashboardTable = styled.table`
  position: relative;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  tbody {
    tr {
      :hover {
        background-color: #fbf5ee;
        cursor: pointer;
      }
    }
  }

  tr {
    text-align: left;
  }

  td,
  th {
    color: black;
    padding: 0.5rem;

    :nth-child(1) {
      width: 25%;
    }
    :nth-child(2) {
      width: 45%;
    }
    :nth-child(3) {
      width: 30%;
    }
  }
`;

export const DashboardDemandsTable = styled(DashboardTable)`
  td,
  th {
    color: ${({ color }) => color ?? "var(--font-color)"};

    :nth-child(1) {
      width: max-content;
    }
    :nth-child(2) {
      width: max-content;
      font-weight: bold;
      text-transform: uppercase;
      white-space: nowrap;

      @media screen and (max-width: ${SIZES.tablet}) {
        display: none;
      }
    }
    :nth-child(3) {
      width: 30%;
    }
    :nth-child(4) {
      width: max-content;
    }
    :nth-child(5) {
      width: max-content;
    }
    :nth-child(6) {
      width: 30%;
    }
  }
`;

export const SmallTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  td,
  th {
    color: var(--text-color);
    text-align: left;
    padding: 0.25rem 0.75rem;
    border: solid 1px;
  }
`;

export const TableMessage = styled.div`
  display: flex;
  height: 4rem;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: #fbf5ee;
`;
