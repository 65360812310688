import { createAction } from "@reduxjs/toolkit";
import { IRole } from "../../models/role";
import { RoleTypes } from "./types";

export const roleFetchRequest = createAction<
  void,
  RoleTypes.ROLE_FETCH_REQUEST
>(RoleTypes.ROLE_FETCH_REQUEST);

export const roleFetchSuccess = createAction<
  IRole,
  RoleTypes.ROLE_FETCH_SUCCESS
>(RoleTypes.ROLE_FETCH_SUCCESS);

export const roleFetchError = createAction<void, RoleTypes.ROLE_FETCH_ERROR>(
  RoleTypes.ROLE_FETCH_ERROR
);
