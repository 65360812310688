import { AxiosResponse, CancelToken } from "axios";
import { FC, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { ClientStateType, IClientItem } from "../../models/client";
import { IApplicationState } from "../../store";
import { IFilterType } from "../../store/filterType";
import {
  changeClientListFilter,
  changeClientListOrder,
  changeClientListPage,
  getClientList,
  getClientListCount,
  IClientListState,
  selectClientList,
  clientListLoad,
  addClientParams,
} from "../../store/clientList";
import FilterPanel from "../common/filter/FilterPanel";
import Grid from "../common/grid/Grid";
import {
  // GridCloseEye,
  GridIconAdd,
  GridIconDetail,
  // GridOpenEye,
} from "../common/grid/GridIcons";
import { TableCol, TableColCenter } from "../common/grid/TableCol";
import Page from "../layout/Page";
import { RightType } from "../../models/auth";
import { hasClientWriteRight } from "../../utils/rights";
import { selectIdentityRights } from "../../store/identity";
import {
  handleLoadAssistanceSuggestions,
  handleLoadUserSuggestions,
} from "../../utils/suggestions";
import SuggestionFormik from "../common/suggestion/SuggestionFormik";
import Input from "../common/form/Input";
import { ExportType } from "../../models/common";
import clientApi from "../../api/client";
import {
  listEffectGetFilter,
  listEffectsGetParamsForExport,
} from "../../store/listEffect";
import { getClientStateIcon } from "./ClientHelpers";
import MultiCheckInput from "../common/form/MultiCheckInput";

interface IProps {
  prov: IClientListState;
  identityRights?: RightType[];
  getClientList(cancelToken: CancelToken): void;
  getClientListCount(cancelToken: CancelToken): void;
  changeClientListOrder(orderBy: string, orderDesc: boolean): void;
  changeClientListPage(page: number): void;
  changeClientListFilter(filter: IFilterType): void;
  clientListLoad(reload: boolean): void;
}

const Client: FC<IProps> = ({
  prov,
  identityRights,
  getClientList,
  getClientListCount,
  changeClientListOrder,
  changeClientListPage,
  changeClientListFilter,
  clientListLoad,
}) => {
  const { t } = useTranslation();
  // const [userDataWithVisibility, setUserDataWithVisibility] = useState<
  //   {
  //     id: string;
  //     visible: boolean;
  //   }[]
  // >();
  const [isIdFieldFilled, setIsIdFilled] = useState<boolean>(false);
  const [isTextSearchFilled, setIsTextSearchFilled] = useState<boolean>(false);

  useEffect(() => {
    clientListLoad(false);
  }, [clientListLoad]);

  const navigate = useNavigate();

  // useEffect(() => {
  //   setUserDataWithVisibility(
  //     prov.data.map((p) => ({
  //       id: p.id.toString(),
  //       visible: false,
  //     }))
  //   );
  // }, [prov.data]);

  const handleRenderData = (item: IClientItem): ReactElement => {
    // const handleShowClick = () => {
    //   setUserDataWithVisibility((prev) => {
    //     return prev?.map((p) => {
    //       if (p.id === item.id.toString()) {
    //         return {
    //           ...p,
    //           visible: !p.visible,
    //         };
    //       }
    //       return p;
    //     });
    //   });
    // };

    // const isVisible = userDataWithVisibility?.find(
    //   (p) => p.id === item.id.toString()
    // )?.visible;

    // const getHideText = (text?: string | null) => {
    //   return isVisible ? text : "***";
    // };

    return (
      <>
        <TableColCenter>{getClientStateIcon(item.state, t)}</TableColCenter>
        <TableCol> {item.id.toString()} </TableCol>
        <TableCol>{item.title}</TableCol>
        <TableCol>{item.firstName}</TableCol>
        <TableCol>{item.lastName}</TableCol>
        <TableCol>{item.degree}</TableCol>
        <TableCol>
          <NavLink to={`/client/${item.id}/general`}>
            <GridIconDetail />
          </NavLink>
          {/* It will be restore in futrure */}
          {/* {item.title !== "***" &&
            (isVisible ? (
              <GridOpenEye onClick={handleShowClick} />
            ) : (
              <GridCloseEye onClick={handleShowClick} />
            ))} */}
        </TableCol>
      </>
    );
  };

  const handleIdFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 0) {
      setIsIdFilled(true);
    } else {
      setIsIdFilled(false);
    }
  };

  const handleTextSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 0) {
      setIsTextSearchFilled(true);
    } else {
      setIsTextSearchFilled(false);
    }
  };

  const handleRowClick = (item: IClientItem) => {
    navigate(`/client/${item.id}/general`);
  };

  const handleExport = async (
    type: ExportType
  ): Promise<AxiosResponse<Blob>> => {
    const params = listEffectsGetParamsForExport(prov);
    listEffectGetFilter(params, prov.filter);
    addClientParams(params, prov);

    if (type === ExportType.Csv) {
      return await clientApi.clientListExportCsv(params);
    }

    return await clientApi.clientListExportXlsx(params);
  };

  return (
    <Page>
      <FilterPanel
        title={t("client.pageTitle")}
        name="client"
        filter={prov.filter!}
        changeFilter={changeClientListFilter}
        doExport={handleExport}
        initialValues={{
          isStateBasic: prov.filter!.isStateBasic,
          isStateNew: prov.filter!.isStateNew,
          isStateActive: prov.filter!.isStateActive,
          isStateInActive: prov.filter!.isStateInActive,
          isStateRejected: prov.filter!.isStateRejected,
          isStateDead: prov.filter!.isStateDead,
          isStateAnonymised: prov.filter!.isStateAnonymised,
          keyCaregiverId: prov.filter!.keyCaregiverId,
          keyCaregiverName: prov.filter!.keyCaregiverName,
          assistanceId: prov.filter!.assistanceId,
          assistanceName: prov.filter!.assistanceName,
          clientId: prov.filter!.clientId,
          clientFirstName: prov.filter!.clientFirstName,
          clientLastName: prov.filter!.clientLastName,
          clientIdentityCode: prov.filter!.clientIdentityCode,
          clientCity: prov.filter!.clientCity,
          clientStreet: prov.filter!.clientStreet,
          searchById: prov.filter!.searchById,
        }}
      >
        <MultiCheckInput label={t("client.state")}>
          <Input
            textAfter={t("client.states." + ClientStateType.Basic)}
            name="isStateBasic"
            type="checkbox"
            inputWidth="1.5rem"
            inputHeight="1.5rem"
          />
          <Input
            textAfter={t("client.states." + ClientStateType.New)}
            name="isStateNew"
            type="checkbox"
            inputWidth="1.5rem"
            inputHeight="1.5rem"
          />
          <Input
            textAfter={t("client.states." + ClientStateType.Active)}
            name="isStateActive"
            type="checkbox"
            inputWidth="1.5rem"
            inputHeight="1.5rem"
          />
          <Input
            textAfter={t("client.states." + ClientStateType.InActive)}
            name="isStateInActive"
            type="checkbox"
            inputWidth="1.5rem"
            inputHeight="1.5rem"
          />
          <Input
            textAfter={t("client.states." + ClientStateType.Rejected)}
            name="isStateRejected"
            type="checkbox"
            inputWidth="1.5rem"
            inputHeight="1.5rem"
          />
          <Input
            textAfter={t("client.states." + ClientStateType.Dead)}
            name="isStateDead"
            type="checkbox"
            inputWidth="1.5rem"
            inputHeight="1.5rem"
          />
          <Input
            textAfter={t("client.states." + ClientStateType.Anonymised)}
            name="isStateAnonymised"
            type="checkbox"
            inputWidth="1.5rem"
            inputHeight="1.5rem"
          />
        </MultiCheckInput>

        <SuggestionFormik
          nameId="keyCaregiverId"
          nameText="keyCaregiverName"
          label={t("client.keyCaregiver")}
          loadSuggestions={handleLoadUserSuggestions}
        />
        <SuggestionFormik
          nameId="assistanceId"
          nameText="assistanceName"
          label={t("client.assistance")}
          loadSuggestions={handleLoadAssistanceSuggestions}
        />
        <h2> {t("client.searchById")} </h2>
        <Input
          type="number"
          name="clientId"
          inputWidth="10rem"
          inputHeight="2rem"
          label={t("client.id")}
          onChange={handleIdFieldChange}
          disabled={isTextSearchFilled}
        />
        <h2> {t("client.searchByClient")} </h2>
        <p> {t("client.searchByClientHelp")} </p>
        <Input
          type="text"
          name="clientFirstName"
          inputWidth="10rem"
          inputHeight="2rem"
          label={t("client.firstName")}
          onChange={handleTextSearchChange}
          disabled={isIdFieldFilled}
        />
        <Input
          type="text"
          name="clientLastName"
          inputWidth="10rem"
          inputHeight="2rem"
          label={t("client.lastName")}
          disabled={isIdFieldFilled}
          onChange={handleTextSearchChange}
        />
        <Input
          type="text"
          name="clientIdentityCode"
          inputWidth="10rem"
          inputHeight="2rem"
          label={t("client.identityCode")}
          disabled={isIdFieldFilled}
          onChange={handleTextSearchChange}
        />
        <Input
          type="text"
          name="clientCity"
          inputWidth="10rem"
          inputHeight="2rem"
          label={t("client.addressMailCity")}
          disabled={isIdFieldFilled}
          onChange={handleTextSearchChange}
        />
        <Input
          type="text"
          name="clientStreet"
          inputWidth="10rem"
          inputHeight="2rem"
          label={t("client.addressActualStreet")}
          disabled={isIdFieldFilled}
          onChange={handleTextSearchChange}
        />
      </FilterPanel>
      <Grid<IClientItem>
        headers={[
          { captionStr: "client.state", orderName: "State", minContent: true },
          {
            captionStr: "client.id",
            orderName: "Id",
          },
          { captionStr: "client.title", orderName: "Title" },
          { captionStr: "client.firstName", orderName: "FirstName" },
          {
            captionStr: "client.lastName",
            orderName: "LastName",
          },
          { captionStr: "client.degree", orderName: "Degree" },
          {
            captionEl: hasClientWriteRight(identityRights, [
              RightType.CreateClient,
            ]) ? (
              <NavLink to={`/client/new/general`}>
                <GridIconAdd />
              </NavLink>
            ) : undefined,
          },
        ]}
        renderData={handleRenderData}
        getData={getClientList}
        getCount={getClientListCount}
        changeOrder={changeClientListOrder}
        changePage={changeClientListPage}
        onRowClick={handleRowClick}
        prov={prov}
      />
    </Page>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    prov: selectClientList(state),
    identityRights: selectIdentityRights(state),
  };
};

const mapDispachToProps = {
  getClientList,
  getClientListCount,
  changeClientListOrder,
  changeClientListPage,
  changeClientListFilter,
  clientListLoad,
};

export default connect(mapStateToProps, mapDispachToProps)(Client);
