import { Form, Formik } from "formik";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import authApi from "../../api/auth";
import { IChangePasswordData } from "../../models/auth";
import storageApi from "../../utils/storage";
import * as Yup from "yup";
import { connect } from "react-redux";
import { LoginButton, LoginCard, LoginContainer } from "./styles";
import { ApiError, FormGroup } from "../../styles/form";
import Input from "../common/form/Input";
import { useNavigate } from "react-router";
import { SmallPage } from "../layout/LayoutStyles";
import validations from "../../utils/validations";
import { promiseToast } from "../../utils/toasts";
import Loader from "../common/Loader";
import {
  getPasswordStrength,
  selectPasswordStrength,
  selectPasswordStrengthState,
} from "../../store/passwordStrength";
import { StoreState } from "../../store/storeState";
import { IPasswordStrength } from "../../models/basic";
import { IApplicationState } from "../../store";
import { addMinutes } from "date-fns";
import { errorSet } from "../../utils/error";

const initialValues: IChangePasswordData = {
  oldPassword: "",
  newPassword: "",
  newPasswordRepeat: "",
};

interface IProps {
  passwordStrengthState: StoreState;
  passwordStrength: IPasswordStrength | null;
  getPasswordStrength(): void;
}

const ChangePassword: FC<IProps> = ({
  passwordStrengthState,
  passwordStrength,
  getPasswordStrength,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (data: IChangePasswordData) => {
    setError(null);
    try {
      await promiseToast(
        async () => {
          const response = await authApi.changePassword(data);

          storageApi.setToken(response.data.token ?? "");
          storageApi.setIdleMinutes(response.data.userMaxIdleMinutes ?? 0);
          storageApi.setTokenRefreshTimeout(
            addMinutes(new Date(), response.data.userMaxIdleMinutes ?? 0)
          );
          storageApi.setTokenTimeout(
            addMinutes(new Date(), response.data.tokenValidMinutes ?? 0)
          );

          navigate("/");
        },
        t("changePassword.changePending"),
        t("changePassword.changeSuccess"),
        t("errors.unknown")
      );
    } catch (err) {
      errorSet(setError, err, t);
    }
  };

  useEffect(() => {
    getPasswordStrength();
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading || passwordStrengthState === StoreState.Loading) {
    return <Loader />;
  }

  return (
    <SmallPage>
      <LoginContainer>
        <h1>{t("changePassword.title")}</h1>
        <LoginCard>
          <Formik<IChangePasswordData>
            initialValues={initialValues}
            validationSchema={Yup.object({
              oldPassword: validations.stringRequired(t),
              newPassword: validations.passwordStrength(
                t,
                passwordStrength!.passwordMinLength,
                passwordStrength!.passwordMinDigitsCount,
                passwordStrength!.passwordMinCapitalLetters,
                passwordStrength!.passwordMinLowerCaseLetters
              ),
              newPasswordRepeat: validations
                .stringRequired(t)
                .oneOf(
                  [Yup.ref("newPassword")],
                  t("changePassword.passMismatch")
                ),
            })}
            validateOnMount={true}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form>
                <FormGroup labelWidth="13rem">
                  <Input
                    type="password"
                    name="oldPassword"
                    label={t("changePassword.oldPassword")}
                    error={touched.oldPassword && !!errors.oldPassword}
                    autoComplete="current-password"
                  />
                  <Input
                    type="password"
                    name="newPassword"
                    label={t("changePassword.newPassword")}
                    error={touched.newPassword && !!errors.newPassword}
                    autoComplete="new-password"
                  />
                  <Input
                    type="password"
                    name="newPasswordRepeat"
                    label={t("changePassword.newPasswordRepeat")}
                    error={
                      touched.newPasswordRepeat && !!errors.newPasswordRepeat
                    }
                    autoComplete="new-password"
                  />
                  {errors.newPassword && (
                    <ApiError>
                      {t("changePassword.passwordRequirements", {
                        passwordMinLength: passwordStrength!.passwordMinLength,
                        passwordMinDigitsCount:
                          passwordStrength!.passwordMinDigitsCount,
                        passwordMinCapitalLetters:
                          passwordStrength!.passwordMinCapitalLetters,
                        passwordMinLowerCaseLetters:
                          passwordStrength!.passwordMinLowerCaseLetters,
                      })}
                    </ApiError>
                  )}
                  {error && <ApiError>{error}</ApiError>}
                </FormGroup>
                <LoginButton type="submit" disabled={isSubmitting}>
                  {t("changePassword.savePassword")}
                </LoginButton>
              </Form>
            )}
          </Formik>
        </LoginCard>
      </LoginContainer>
    </SmallPage>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    passwordStrengthState: selectPasswordStrengthState(state),
    passwordStrength: selectPasswordStrength(state),
  };
};

const mapDispachToProps = {
  getPasswordStrength,
};

export default connect(mapStateToProps, mapDispachToProps)(ChangePassword);
