import styled from "styled-components";
import { SIZES } from "../../styles/sizes";

export const SmallPage = styled.main`
  height: calc(100vh - var(--header-height));
  padding: 1rem;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SidebarPageContainer = styled.main<{
  hasTopPanel?: boolean;
}>`
  height: ${({ hasTopPanel }) =>
    hasTopPanel
      ? "calc(100vh - var(--header-height) - 4rem)"
      : "calc(100vh - var(--header-height))"};
  display: grid;

  grid-template-columns: 1fr;

  @media screen and (min-width: ${SIZES.laptop}) {
    grid-template-columns: auto 1fr;
  }
`;

export const PageContent = styled.div`
  width: 100%;
  overflow-y: auto;
`;

export const PageContentWrapper = styled.div<{
  hasTopPanel?: boolean;
  maxWidth?: boolean;
}>`
  position: relative;

  height: auto;
  min-height: ${({ hasTopPanel }) =>
    hasTopPanel
      ? "calc(100vh - var(--header-height) - 4rem)"
      : "calc(100vh - var(--header-height))"};
  width: 100%;

  ${({ maxWidth }) => (maxWidth ? "" : `max-width: ${SIZES.desktop};`)}

  margin: 0 auto;
  padding: 1.5rem;

  @media screen and (min-width: ${SIZES.tablet}) {
    padding: 2rem;
  }
`;
