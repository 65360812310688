import { createAction } from "@reduxjs/toolkit";
import { IWorkflowTemplate } from "../../models/workflowTemplate";
import { WorkflowTemplateTypes } from "./types";

export const workflowTemplateFetchRequest = createAction<
  void,
  WorkflowTemplateTypes.WORKFLOW_TEMPLATE_FETCH_REQUEST
>(WorkflowTemplateTypes.WORKFLOW_TEMPLATE_FETCH_REQUEST);

export const workflowTemplateFetchSuccess = createAction<
  IWorkflowTemplate,
  WorkflowTemplateTypes.WORKFLOW_TEMPLATE_FETCH_SUCCESS
>(WorkflowTemplateTypes.WORKFLOW_TEMPLATE_FETCH_SUCCESS);

export const workflowTemplateFetchError = createAction<
  void,
  WorkflowTemplateTypes.WORKFLOW_TEMPLATE_FETCH_ERROR
>(WorkflowTemplateTypes.WORKFLOW_TEMPLATE_FETCH_ERROR);
