import * as ReselectModule from "reselect";
import { IApplicationState } from "..";
import { IDiagramSettings } from "../../models/diagram";
import { StoreState } from "../storeState";

export const selectDiagramSettingsState: ReselectModule.Selector<
  IApplicationState,
  StoreState
> = (state) => state.diagramSettings.state;

export const selectDiagramSettings: ReselectModule.Selector<
  IApplicationState,
  IDiagramSettings
> = (state) => state.diagramSettings.data;
