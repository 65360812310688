import { ITemplateDocumentItem } from "../../models/template";
import { IFilterType } from "../filterType";
import { IListType } from "../listType";

export enum TemplateDocumentListTypes {
  TEMPLATE_DOCUMENT_LIST_FETCH_REQUEST = "@@templateDocumentList/FETCH_REQUEST",
  TEMPLATE_DOCUMENT_LIST_FETCH_SUCCESS = "@@templateDocumentList/FETCH_SUCCESS",
  TEMPLATE_DOCUMENT_LIST_FETCH_ERROR = "@@templateDocumentList/FETCH_ERROR",
  TEMPLATE_DOCUMENT_LIST_COUNT_REQUEST = "@@templateDocumentList/COUNT_REQUEST",
  TEMPLATE_DOCUMENT_LIST_COUNT_SUCCESS = "@@templateDocumentList/COUNT_SUCCESS",
  TEMPLATE_DOCUMENT_LIST_COUNT_ERROR = "@@templateDocumentList/COUNT_ERROR",
  TEMPLATE_DOCUMENT_LIST_CHANGE_ORDER = "@@templateDocumentList/CHANGE_ORDER",
  TEMPLATE_DOCUMENT_LIST_CHANGE_PAGE = "@@templateDocumentList/CHANGE_PAGE",
  TEMPLATE_DOCUMENT_LIST_CHANGE_FILTER = "@@templateDocumentList/CHANGE_FILTER",
  TEMPLATE_DOCUMENT_LIST_RESET = "@@templateDocumentList/RESET",
  TEMPLATE_DOCUMENT_LIST_LOAD = "@@templateDocumentList/LOAD",
}

export interface ITemplateDocumentListFilter extends IFilterType {
  idFolder?: number | null;
}

export interface ITemplateDocumentListState
  extends IListType<ITemplateDocumentItem, ITemplateDocumentListFilter> {}
