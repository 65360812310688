import { AxiosRequestConfig, AxiosResponse } from "axios";
import { IItemIdName } from "../models/common";
import {
  IWorkflowTemplate,
  IWorkflowTemplateItem,
} from "../models/workflowTemplate";
import api from "../utils/api";

const getSuggestionList = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<IItemIdName[]>> =>
  api.get(`/api/WorkflowTemplate/SuggestedList`, config);

const getList = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<IWorkflowTemplateItem[]>> =>
  api.get(`/api/WorkflowTemplate/List`, config);

const getCount = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<number>> =>
  api.get(`/api/WorkflowTemplate/Count`, config);

const getWorkflowTemplate = async (
  workflowId: number
): Promise<AxiosResponse<IWorkflowTemplate>> =>
  api.get(`/api/WorkflowTemplate/${workflowId}`);

const updateWorkflowTemplate = async (
  workflow: IWorkflowTemplate
): Promise<AxiosResponse<IWorkflowTemplate>> =>
  api.put(`/api/WorkflowTemplate/${workflow.id}`, workflow);

const createWorkflowTemplate = async (
  workflow: IWorkflowTemplate
): Promise<AxiosResponse<IWorkflowTemplate>> =>
  api.post(`/api/WorkflowTemplate`, workflow);

const workflowTemplateApi = {
  getSuggestionList,
  getList,
  getCount,
  getWorkflowTemplate,
  updateWorkflowTemplate,
  createWorkflowTemplate,
};

export default workflowTemplateApi;
