import { AppThunkAction, AppThunkActionAsync } from "..";
import * as actions from "./actions";
import actionApi from "../../api/calendar";
import { IActionListFilter, IActionListState, selectActionList } from ".";
import { CancelToken } from "axios";
import {
  listEffectCountFilter,
  listEffectGetFilter,
  listEffectLoad,
  listEffectReload,
  listEffectsCountCheck,
  listEffectsGetCheck,
  listEffectsGetCount,
  listEffectsGetNew,
  listEffectsGetParams,
} from "../listEffect";
import { addDays, formatISO } from "date-fns";
import { IItemIdName } from "../../models/common";

export function getActionList(cancelToken: CancelToken): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectActionList(getState());
    if (listEffectsGetCheck(state.state)) {
      return;
    }

    let newState = listEffectsGetNew(state);
    dispatch(actions.actionListFetchRequest(newState.newCountState));
    try {
      const params = listEffectsGetParams(state, newState.take);
      listEffectGetFilter(params, state.filter);
      addActionParams(params, state);

      const actionsResponse = await actionApi.getActionList({
        params,
        cancelToken,
      });
      cancelToken.throwIfRequested();
      newState = listEffectsGetCount(newState, actionsResponse);

      dispatch(
        actions.actionListFetchSuccess({
          data: actionsResponse.data,
          newCountState: newState.newCountState,
          newCount: newState.newCount,
        })
      );
    } catch {
      dispatch(actions.actionListFetchError(cancelToken.reason !== undefined));
    }
  };
}

export function getActionListCount(
  cancelToken: CancelToken
): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectActionList(getState());
    if (listEffectsCountCheck(state.countState)) {
      return;
    }

    dispatch(actions.actionListCountRequest());
    try {
      const params = listEffectCountFilter(state.filter);
      addActionParams(params, state);
      const count = await actionApi.getActionCount({ params, cancelToken });
      cancelToken.throwIfRequested();

      dispatch(actions.actionListCountSuccess(count.data));
    } catch {
      dispatch(actions.actionListCountError(cancelToken.reason !== undefined));
    }
  };
}

export function changeActionListOrder(
  orderBy: string,
  orderDesc: boolean
): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.actionListChangeOrder({ orderBy, orderDesc }));
  };
}

export function changeActionListPage(page: number): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.actionListChangePage(page));
  };
}

export function changeActionListFilter(
  filter: IActionListFilter
): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.actionListChangeFilter(filter));
  };
}

export function openActionListFilter(): AppThunkAction {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(
      actions.actionListChangeFilter({
        ...state.actionList.filter!,
        isOpen: true,
      })
    );
  };
}

export function actionListLoad(reload: boolean): AppThunkAction {
  return async (dispatch, getState) => {
    const state = selectActionList(getState());
    reload = listEffectReload(reload, state);
    if (reload) {
      dispatch(actions.actionListReset());
    } else {
      const newState = listEffectLoad(state);
      dispatch(
        actions.actionListLoadAction({
          newState: newState.newState,
          newCountState: newState.newCountState,
        })
      );
    }
  };
}

export function addActionParams(
  params: URLSearchParams,
  state: IActionListState
) {
  if (state.filter!.notAssigned) {
    params.append("notAssigned", "true");
  } else {
    for (const user of state.filter!.assignedUsers) {
      params.append("assignedUsers", user.id.toString());
    }
  }

  if (state.filter!.clientId) {
    params.append("assignedClient", state.filter!.clientId.toString());
  }

  if (state.filter!.from) {
    params.append("from", formatISO(state.filter!.from));
  }

  if (state.filter!.to) {
    params.append("to", formatISO(addDays(state.filter!.to, 1)));
  }
}

export function actionListSetDefaultFilter(
  assignedUsers: IItemIdName[],
  from: Date | null,
  to: Date | null
): AppThunkAction {
  return async (dispatch, getState) => {
    const state = selectActionList(getState());
    if (!state.filter!.isDefault) {
      return;
    }

    dispatch(actions.actionListDefaultFilter({ assignedUsers, from, to }));
  };
}
