import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import dashboardApi from "../../api/dashboard";
import { IDashboardLink } from "../../models/dashboard";
import { IApplicationState } from "../../store";
import {
  getDashboardLinks,
  selectDashboardLink,
  selectDashboardLinkState,
  updateDashboardLinks,
} from "../../store/dashboardLink";
import { StoreState } from "../../store/storeState";
import { ApiError } from "../../styles/form";
import { MAX_DASHBOARD_LINKS } from "../../utils/consts";
import { ALL_LINKS } from "../../utils/links";
import { promiseToastSave } from "../../utils/toasts";
import Loader from "../common/Loader";
import SettingsDashboardLink, { LinkMoveAction } from "./SettingsDashboardLink";
import SettingsDashboardLinkNew from "./SettingsDashboardLinkNew";
import {
  SettingDashboardColumn,
  SettingDashboardContainer,
} from "./SettingsDashboardLinksStyles";
import { errorSet } from "../../utils/error";

interface IProps {
  dashboardLinkState: StoreState;
  dashboardLinks: IDashboardLink[];
  getDashboardLinks(): void;
  updateDashboardLinks(links: IDashboardLink[]): void;
}

const SettingsDashboardLinks: FC<IProps> = ({
  dashboardLinkState,
  dashboardLinks,
  getDashboardLinks,
  updateDashboardLinks,
}) => {
  const { t } = useTranslation();
  const [links, setLinks] = useState(dashboardLinks);
  const linksRef = useRef<IDashboardLink[]>();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    return () => {
      if (
        linksRef.current &&
        JSON.stringify(linksRef.current) !== JSON.stringify(links)
      ) {
        handleSubmit(linksRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    linksRef.current = links;
  }, [links]);

  useEffect(() => {
    getDashboardLinks();
  }, [getDashboardLinks]);

  useEffect(() => {
    setLinks(dashboardLinks);
  }, [dashboardLinks]);

  const handleOnAction = (action: LinkMoveAction, index: number) => {
    const newLinks = links.slice();

    if (action === LinkMoveAction.Delete) {
      newLinks.splice(index, 1);
      setLinks(newLinks);
      return;
    }

    const newIndex = action === LinkMoveAction.Up ? index - 1 : index + 1;
    newLinks.splice(newIndex, 0, newLinks.splice(index, 1)[0]);
    setLinks(newLinks);
  };

  const handleOnAdd = (path: string) => {
    setLinks(links.concat({ path }));
  };

  const handleSubmit = async (links: IDashboardLink[]) => {
    setError(null);
    try {
      await promiseToastSave(async () => {
        await dashboardApi.updateDashboardLinks({
          paths: links.map((x) => x.path),
        });
        updateDashboardLinks(links);
      });
    } catch (err) {
      errorSet(setError, err, t);
    }
  };

  if (dashboardLinkState === StoreState.Error) {
    return t("errors.unknown");
  }

  if (dashboardLinkState !== StoreState.Loaded) {
    return <Loader />;
  }

  return (
    <>
      <h1>{t("settings.links.title")}</h1>

      <SettingDashboardContainer>
        <SettingDashboardColumn>
          <h2>{t("settings.links.exists")}</h2>
          {links.map((x, i) => (
            <SettingsDashboardLink
              key={i}
              path={x.path}
              index={i}
              showUp={i !== 0}
              showDown={i !== links.length - 1}
              onAction={handleOnAction}
            />
          ))}
        </SettingDashboardColumn>
        <SettingDashboardColumn>
          <h2>{t("settings.links.new")}</h2>
          {ALL_LINKS.filter((x) => !links.find((y) => y.path === x)).map(
            (x, i) => (
              <SettingsDashboardLinkNew
                key={i}
                path={x}
                canAdd={links.length < MAX_DASHBOARD_LINKS}
                onAdd={handleOnAdd}
              />
            )
          )}
        </SettingDashboardColumn>
      </SettingDashboardContainer>
      {error && <ApiError>{error}</ApiError>}
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    dashboardLinkState: selectDashboardLinkState(state),
    dashboardLinks: selectDashboardLink(state),
  };
};

const mapDispachToProps = {
  getDashboardLinks,
  updateDashboardLinks,
};

export default connect(
  mapStateToProps,
  mapDispachToProps
)(SettingsDashboardLinks);
