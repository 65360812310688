import { Field } from "formik";
import styled from "styled-components";
import { SIZES } from "../../styles/sizes";

export const CalendarModalTimesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 0.5rem;
  align-items: center;

  @media screen and (min-width: ${SIZES.mobile}) {
    grid-template-columns: 6rem 1fr;
  }
`;

export const CalendarModalTimes = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;

  > div {
    display: flex;
    align-items: center;
  }
`;

export const CalendarModalTimesLabel = styled.div`
  display: flex;
  margin-right: 0.5rem;
`;

export const CalendarModalDays = styled.div`
  display: flex;
  flex-wrap: wrap;

  > * {
    margin: 0.5rem;
  }
`;

export const CalendarModalDayField = styled(Field)`
  width: 1.5rem;
  height: 1.5rem;
`;

export const CalendarModalDayText = styled.div`
  display: inline;
  margin-left: 0.5rem;
  position: relative;
  top: -20%;
`;

export const CalendarModalToDiv = styled.div`
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`;
