import styled from "styled-components";

export const DemandWorkflowFieldset = styled.fieldset`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-top: 1.5rem;
  padding: 2rem;
`;

export const DemandWorkflowNotificationFormContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr 1fr;
  grid-row-gap: 0.75rem;
  grid-column-gap: 1rem;
  margin-top: 1.5rem;
  align-items: center;
`;
