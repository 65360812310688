import styled from "styled-components";
import { textInput } from "../../../styles/form";

export const SuggestionContainer = styled.div<{
  $error?: boolean;
}>`
  width: 100%;
  position: relative;

  input {
    ${(props) => textInput(props)}
  }

  .react-autosuggest__suggestions-container {
    position: absolute;
    left: 0;
    top: 2.5rem;
    z-index: 800;
  }

  .react-autosuggest__suggestions-container--open {
    border: 1px solid var(--border-focus);
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: var(--suggestion-highlighted-bg);
  }

  ul {
    list-style: none;
    background-color: var(--background);
  }

  li {
    padding: 0.75rem 1rem 0.75rem 1rem;
    cursor: pointer;
  }
`;

export const SuggestionListUl = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  list-style: none;
`;

export const SuggestionItem = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 0.5rem;
  min-height: 2rem;
  padding: 0.25rem 0.5rem;
  font-size: 0.9rem;
  color: white;
  background-color: #0e2a5e;
  border-radius: 5px;

  svg {
    &:hover {
      color: #4cc2f1;
    }
    cursor: pointer;
  }
`;

export const SuggestionClearContainer = styled.div`
  position: absolute;
  right: 0.3rem;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
`;
