import { CancelToken } from "axios";
import { FC, ReactElement, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ITemplateDocumentItem } from "../../models/template";
import { IApplicationState } from "../../store";
import { IFilterType } from "../../store/filterType";
import {
  changeTemplateDocumentListFilter,
  changeTemplateDocumentListOrder,
  changeTemplateDocumentListPage,
  getTemplateDocumentList,
  getTemplateDocumentListCount,
  ITemplateDocumentListState,
  selectTemplateDocumentList,
  templateDocumentListLoad,
} from "../../store/templateDocumentList";
import FilterPanel from "../common/filter/FilterPanel";
import Grid from "../common/grid/Grid";
import {
  GridIconAdd,
  GridIconDownload,
  GridIconEdit,
  GridIconSpace,
  GridIconUpload,
} from "../common/grid/GridIcons";
import { TableCol } from "../common/grid/TableCol";
import { RightType } from "../../models/auth";
import { selectIdentityRights } from "../../store/identity";
import { hasRight } from "../../utils/rights";
import ModuleDocumentAddModal from "./ModuleDocumentAddModal";
import { useParams } from "react-router";
import { DivTextRight } from "../../styles/align";
import templateApi from "../../api/template";
import bucketApi from "../../api/bucket";
import { startDocumentEditOnline } from "../document/DocumentEditOnlineStart";
import { promiseToast, promiseToastSave } from "../../utils/toasts";

interface IProps {
  prov: ITemplateDocumentListState;
  identityRights?: RightType[];
  getTemplateDocumentList(cancelToken: CancelToken): void;
  getTemplateDocumentListCount(cancelToken: CancelToken): void;
  changeTemplateDocumentListOrder(orderBy: string, orderDesc: boolean): void;
  changeTemplateDocumentListPage(page: number): void;
  changeTemplateDocumentListFilter(filter: IFilterType): void;
  templateDocumentListLoad(reload: boolean): void;
}

const ModuleDocumentDocument: FC<IProps> = ({
  prov,
  identityRights,
  getTemplateDocumentList,
  getTemplateDocumentListCount,
  changeTemplateDocumentListOrder,
  changeTemplateDocumentListPage,
  changeTemplateDocumentListFilter,
  templateDocumentListLoad,
}) => {
  const { t } = useTranslation();
  const [addFile, setAddFile] = useState<File | null>(null);
  const [addIsOpen, setAddIsOpen] = useState(false);
  const [uploadDocumentId, setUploadDocumentId] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);
  const { folderId } = useParams();

  useEffect(() => {
    templateDocumentListLoad(false);
  }, [templateDocumentListLoad]);

  const handleAdd = () => {
    setUploadDocumentId(0);
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleClose = () => {
    setAddIsOpen(false);

    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const handleFileChange = async (files: FileList | null) => {
    if (!files || files.length < 1) {
      return;
    }

    const file = files[0];

    if (uploadDocumentId === 0) {
      setAddFile(file);
      setAddIsOpen(true);
      return;
    }

    try {
      await doUpload(file);
    } finally {
      if (inputRef.current) {
        inputRef.current.value = "";
      }
    }
  };

  const handleDownload = async (item: ITemplateDocumentItem) => {
    try {
      await promiseToast(
        async () => {
          const url = await templateApi.getDocumentDownloadUrl(item.id);

          const link = document.createElement("a");
          link.href = url.data;
          link.download = item.name;
          link.target = "_blank";
          link.click();
        },
        t("pending.download"),
        t("success.download"),
        t("errors.unknown")
      );
    } catch {
      //Nothing.
    }
  };

  const handleUpload = async (item: ITemplateDocumentItem) => {
    setUploadDocumentId(item.id);
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const doUpload = async (file: File) => {
    await promiseToastSave(async () => {
      const uploadUrl = await templateApi.getDocumentUploadUrl(
        null,
        null,
        uploadDocumentId.toString()
      );

      await bucketApi.uploadDocument(uploadUrl.data, file);
      await templateApi.updateDocument(uploadDocumentId);

      templateDocumentListLoad(true);
    });
  };

  const handleEditOnline = async (item: ITemplateDocumentItem) => {
    await startDocumentEditOnline(item.id, t);
  };

  const handleRenderData = (item: ITemplateDocumentItem): ReactElement => {
    return (
      <>
        <TableCol>{item.name}</TableCol>
        <TableCol>
          <GridIconDownload onClick={() => handleDownload(item)} />
          {hasRight(identityRights, [RightType.WriteAllTemplates]) && (
            <>
              <GridIconSpace />
              <GridIconUpload onClick={() => handleUpload(item)} />
              <GridIconSpace />
              <GridIconEdit onClick={() => handleEditOnline(item)} />
            </>
          )}
        </TableCol>
      </>
    );
  };

  return (
    <>
      <input
        ref={inputRef}
        type="file"
        hidden
        onChange={({ target }) => handleFileChange(target.files)}
      />
      <ModuleDocumentAddModal
        file={addFile}
        folderId={folderId!}
        isOpen={addIsOpen}
        close={handleClose}
      />

      <FilterPanel
        title={t("module.document.pageTitle")}
        name="moduleDocumentDocument"
        filter={prov.filter!}
        changeFilter={changeTemplateDocumentListFilter}
      />
      <Grid<ITemplateDocumentItem>
        headers={[
          { captionStr: "document.name" },
          {
            captionEl: hasRight(identityRights, [
              RightType.WriteAllTemplates,
            ]) ? (
              <DivTextRight>
                <GridIconAdd onClick={handleAdd} />
              </DivTextRight>
            ) : undefined,
          },
        ]}
        renderData={handleRenderData}
        getData={getTemplateDocumentList}
        getCount={getTemplateDocumentListCount}
        changeOrder={changeTemplateDocumentListOrder}
        changePage={changeTemplateDocumentListPage}
        prov={prov}
      />
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    prov: selectTemplateDocumentList(state),
    identityRights: selectIdentityRights(state),
  };
};

const mapDispachToProps = {
  getTemplateDocumentList,
  getTemplateDocumentListCount,
  changeTemplateDocumentListOrder,
  changeTemplateDocumentListPage,
  changeTemplateDocumentListFilter,
  templateDocumentListLoad,
};

export default connect(
  mapStateToProps,
  mapDispachToProps
)(ModuleDocumentDocument);
