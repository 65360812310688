import { AppThunkAction, AppThunkActionAsync } from "..";
import * as actions from "./actions";
import templateApi from "../../api/template";
import {
  ITemplateFolderListFilter,
  ITemplateFolderListState,
  selectTemplateFolderList,
} from ".";
import { CancelToken } from "axios";
import {
  listEffectCountFilter,
  listEffectGetFilter,
  listEffectLoad,
  listEffectReload,
  listEffectsCountCheck,
  listEffectsGetCheck,
  listEffectsGetCount,
  listEffectsGetNew,
  listEffectsGetParams,
} from "../listEffect";

export function getTemplateFolderList(
  cancelToken: CancelToken
): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectTemplateFolderList(getState());
    if (listEffectsGetCheck(state.state)) {
      return;
    }

    let newState = listEffectsGetNew(state);
    dispatch(actions.templateFolderListFetchRequest(newState.newCountState));
    try {
      const params = listEffectsGetParams(state, newState.take);
      listEffectGetFilter(params, state.filter);
      addTemplateFolderParams(params, state);

      const folders = await templateApi.getFolderList({ params, cancelToken });
      cancelToken.throwIfRequested();
      newState = listEffectsGetCount(newState, folders);

      dispatch(
        actions.templateFolderListFetchSuccess({
          data: folders.data,
          newCountState: newState.newCountState,
          newCount: newState.newCount,
        })
      );
    } catch {
      dispatch(
        actions.templateFolderListFetchError(cancelToken.reason !== undefined)
      );
    }
  };
}

export function getTemplateFolderListCount(
  cancelToken: CancelToken
): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectTemplateFolderList(getState());
    if (listEffectsCountCheck(state.countState)) {
      return;
    }

    dispatch(actions.templateFolderListCountRequest());
    try {
      const params = listEffectCountFilter(state.filter);
      addTemplateFolderParams(params, state);
      const count = await templateApi.getFolderCount({ params, cancelToken });
      cancelToken.throwIfRequested();

      dispatch(actions.templateFolderListCountSuccess(count.data));
    } catch {
      dispatch(
        actions.templateFolderListCountError(cancelToken.reason !== undefined)
      );
    }
  };
}

export function changeTemplateFolderListOrder(
  orderBy: string,
  orderDesc: boolean
): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.templateFolderListChangeOrder({ orderBy, orderDesc }));
  };
}

export function changeTemplateFolderListPage(page: number): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.templateFolderListChangePage(page));
  };
}

export function changeTemplateFolderListFilter(
  filter: ITemplateFolderListFilter
): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.templateFolderListChangeFilter(filter));
  };
}

export function templateFolderListLoad(reload: boolean): AppThunkAction {
  return async (dispatch, getState) => {
    const state = selectTemplateFolderList(getState());
    reload = listEffectReload(reload, state);
    if (reload) {
      dispatch(actions.templateFolderListReset());
    } else {
      const newState = listEffectLoad(state);
      dispatch(
        actions.templateFolderListLoadAction({
          newState: newState.newState,
          newCountState: newState.newCountState,
        })
      );
    }
  };
}

export function addTemplateFolderParams(
  params: URLSearchParams,
  state: ITemplateFolderListState
) {
  if (state.filter!.idFolder) {
    params.append("idFolder", state.filter!.idFolder.toString());
  }
}
