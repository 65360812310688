import { Form, Formik } from "formik";
import { FC, useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { IApplicationState } from "../../store";
import {
  getSearchResults,
  ISearchFilter,
  selectSearchData,
  selectSearchFilter,
  selectSearchState,
} from "../../store/search";
import { StoreState } from "../../store/storeState";
import Page from "../layout/Page";
import * as Yup from "yup";
import validations from "../../utils/validations";
import { FormGroup } from "../../styles/form";
import Input from "../common/form/Input";
import SubmitForm from "../common/form/SubmitForm";
import {
  faAngleDoubleDown,
  faAngleDoubleUp,
  faArrowAltCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import {
  SearchIcon,
  SearchResultContainer,
  SearchResultLink,
  SearchTextContainer,
} from "./SearchStyles";
import axios, { CancelToken, CancelTokenSource } from "axios";
import {
  ISearchFoundItem,
  SearchItemMasterType,
  SearchItemType,
} from "../../models/search";
import { FormButton } from "../../styles/button";
import { VerticalSpace } from "../../styles/spaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const fields: Array<keyof ISearchFilter> = [
  "selClient",
  "selClientContactPerson",
  "selUser",
  "selGroup",
  "selDocument",
  "selDocumentContent",
  "selAssistance",
  "selAssistanceContactPerson",
  "selListing",
];

interface IProps {
  searchFilter: ISearchFilter;
  searchState: StoreState;
  searchData: ISearchFoundItem[];
  getSearchResults(filter: ISearchFilter, cancelToken: CancelToken): void;
}

const Search: FC<IProps> = ({
  searchFilter,
  searchState,
  searchData,
  getSearchResults,
}) => {
  const { t } = useTranslation();
  const cancelToken = useRef<CancelTokenSource | null>(null);

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      cancelToken.current?.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (data: ISearchFilter) => {
    cancelToken.current?.cancel();
    var token = axios.CancelToken.source();
    cancelToken.current = token;

    getSearchResults(data, token.token);
  };

  const handleCancel = () => {
    cancelToken.current?.cancel();
  };

  let searchStateComponent: JSX.Element | null = null;
  switch (searchState) {
    case StoreState.None:
      searchStateComponent = <SubmitForm text="search.button" />;
      break;
    case StoreState.Loading:
      searchStateComponent = (
        <>
          <FormButton type="button" onClick={handleCancel}>
            {t("search.cancelButton")}
          </FormButton>
          <p>{t("search.loading")}</p>
        </>
      );
      break;
    case StoreState.Loaded:
      if (searchData.length === 0) {
        searchStateComponent = (
          <>
            <SubmitForm text="search.button" />
            <p>{t("search.notFound")}</p>
          </>
        );
      } else {
        searchStateComponent = (
          <>
            <SubmitForm text="search.button" />
            <p>
              {t("search.loaded").replaceAll(
                "{count}",
                searchData.length.toString()
              )}
            </p>
          </>
        );
      }
      break;
    case StoreState.Error:
      searchStateComponent = (
        <>
          <SubmitForm text="search.button" />
          <p>{t("errors.unknown")}</p>
        </>
      );
      break;
    case StoreState.Cancel:
      searchStateComponent = (
        <>
          <SubmitForm text="search.button" />
          <p>{t("search.cancel")}</p>
        </>
      );
      break;
  }

  const getLink = (item: ISearchFoundItem) => {
    switch (item.elementType) {
      case SearchItemType.Client:
        return `/client/${item.id}/general`;
      case SearchItemType.ClientContactPerson:
        return `/client/${item.idMaster}/person/${item.id}`;
      case SearchItemType.User:
        return `/admin/user/${item.id}`;
      case SearchItemType.Group:
        return `/admin/group`;
      case SearchItemType.Document:
        switch (item.masterElementType) {
          case SearchItemMasterType.Client:
            return `/client/${item.idMaster}/document`;
          case SearchItemMasterType.Assistance:
            return `/assistance/${item.idMaster}/document`;
          case SearchItemMasterType.Document:
            return `/module/document/${item.idMaster}`;
          default:
            return "/";
        }
      case SearchItemType.Assistance:
        return `/assistance/${item.id}/general`;
      case SearchItemType.AssistanceContactPerson:
        return `/assistance/${item.idMaster}/person/${item.id}`;
      case SearchItemType.Listing:
        return `/admin/listing`;
      default:
        return "/";
    }
  };

  const getText = useCallback(
    (item: ISearchFoundItem) => {
      switch (item.elementType) {
        case SearchItemType.Client:
          return (
            <>
              {t("search.types.client")}: <b>{item.name}</b>
            </>
          );
        case SearchItemType.ClientContactPerson:
          return (
            <>
              {t("search.types.client")}: {item.masterName}
              {" / "}
              {t("search.types.contactPerson")}: <b>{item.name}</b>
            </>
          );
        case SearchItemType.User:
          return (
            <>
              {t("search.types.user")}: <b>{item.name}</b>
            </>
          );
        case SearchItemType.Group:
          return (
            <>
              {t("search.types.group")}: <b>{item.name}</b>
            </>
          );
        case SearchItemType.Document:
          switch (item.masterElementType) {
            case SearchItemMasterType.Client:
              return (
                <>
                  {t("search.types.client")}: {item.masterName}
                  {" / "}
                  {t("search.types.document")}: <b>{item.name}</b>
                </>
              );
            case SearchItemMasterType.Assistance:
              return (
                <>
                  {t("search.types.assistance")}: {item.masterName}
                  {" / "}
                  {t("search.types.document")}: <b>{item.name}</b>
                </>
              );
            case SearchItemMasterType.Document:
              return (
                <>
                  {t("search.types.template")}: <b>{item.name}</b>
                </>
              );
            default:
              return "/";
          }
        case SearchItemType.Assistance:
          return (
            <>
              {t("search.types.assistance")}: <b>{item.name}</b>
            </>
          );
        case SearchItemType.AssistanceContactPerson:
          return (
            <>
              {t("search.types.assistance")}: {item.masterName}
              {" / "}
              {t("search.types.contactPerson")}: <b>{item.name}</b>
            </>
          );
        case SearchItemType.Listing:
          return (
            <>
              {t("search.types.listing")}: <b>{item.name}</b>
            </>
          );
        default:
          return "/";
      }
    },
    [t]
  );

  const data = useMemo(
    () =>
      searchData.map((item, i) => (
        <SearchResultContainer key={i}>
          <FontAwesomeIcon icon={faArrowAltCircleRight} />
          <SearchResultLink to={getLink(item)}>
            {getText(item)}
          </SearchResultLink>
        </SearchResultContainer>
      )),
    [searchData, getText]
  );

  return (
    <Page>
      <h1>{t("search.pageTitle")}</h1>
      <Formik<ISearchFilter>
        initialValues={searchFilter}
        validationSchema={Yup.object({
          text: validations.stringMin(3, t),
        })}
        validateOnMount={true}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, values, setFieldValue }) => (
          <Form>
            <FormGroup>
              <SearchTextContainer>
                <Input name="text" error={touched.text && !!errors.text} />
                <SearchIcon
                  icon={values.open ? faAngleDoubleUp : faAngleDoubleDown}
                  size="2x"
                  onClick={() => setFieldValue("open", !values.open)}
                />
              </SearchTextContainer>
              {values.open && (
                <>
                  {fields.map((f) => (
                    <Input
                      key={f}
                      name={f}
                      type="checkbox"
                      label={t("search." + f)}
                      error={touched[f] && !!errors[f]}
                      inputWidth="1.5rem"
                      inputHeight="1.5rem"
                    />
                  ))}
                </>
              )}
            </FormGroup>
            {searchStateComponent}
          </Form>
        )}
      </Formik>
      <VerticalSpace />
      {data}
    </Page>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    searchFilter: selectSearchFilter(state),
    searchState: selectSearchState(state),
    searchData: selectSearchData(state),
  };
};

const mapDispachToProps = {
  getSearchResults,
};

export default connect(mapStateToProps, mapDispachToProps)(Search);
