import { addDays, startOfWeek, subDays } from "date-fns";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CalendarItemType,
  ICalendarCol,
  ICalendarCols,
  ICalendarItem,
  ICalendarResponseItem,
} from "../../models/calendar";
import {
  getCalendar,
  transformCalendarReponseMulti,
} from "../../utils/calendar";
import Calendar from "../common/calendar/Calendar";
import { CalendarHeader } from "../common/calendar/CalendarStyles";
import CalendarDateSelector from "../common/calendar/CalendarDateSelector";
import calendarApi from "../../api/calendar";
import { IUserItem } from "../../models/user";
import userApi from "../../api/user";
import { Button } from "../../styles/button";
import { SpaceBetweenButtons, VerticalSpace } from "../../styles/spaces";
import CalendarModal from "./CalendarModal";
import CalendarDetailModal, {
  ICalendarDetailModalData,
} from "./CalendarDetailModal";
import { useNavigate } from "react-router";
import { errorToast } from "../../utils/toasts";
import { RightType } from "../../models/auth";
import { IApplicationState } from "../../store";
import { selectIdentityRights } from "../../store/identity";
import { connect } from "react-redux";
import { hasRight } from "../../utils/rights";
import formatters from "../../utils/formatters";

interface IProps {
  identityRights?: RightType[];
}

const CalendarTeamWeek: FC<IProps> = ({ identityRights }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [loadingUsers, setLoadingUsers] = useState<boolean>(true);
  const [labels, setLabels] = useState<string[]>([]);
  const [cols, setCols] = useState<ICalendarCol[]>([]);
  const [mondayDate, setMondayDate] = useState(
    startOfWeek(new Date(), { weekStartsOn: 1 })
  );
  const [users, setUsers] = useState<IUserItem[]>([]);
  const [selectedUserIds, setSelectedUserIds] = useState<number[]>([]);
  const [isAdding, setIsAdding] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const [openDetailData, setOpenDetailData] =
    useState<ICalendarDetailModalData | null>(null);

  const getData = async (
    mondayDate: Date,
    checkedIds: number[]
  ): Promise<ICalendarCols> => {
    let apiData = [] as ICalendarResponseItem[];
    if (checkedIds.length !== 0) {
      const response = await calendarApi.getCalendar(
        mondayDate,
        addDays(mondayDate, 7),
        checkedIds
      );
      apiData = response.data;
    }

    const transformed = transformCalendarReponseMulti(apiData);
    const data = getCalendar(mondayDate, 7, transformed, false);
    return data;
  };

  const handleOnClick = (type: CalendarItemType, item: ICalendarItem) => {
    setOpenDetailData({ type, item });
  };

  const handlePrev = () => {
    const newMonday = subDays(mondayDate, 7);
    setMondayDate(newMonday);
  };

  const handleNext = () => {
    const newMonday = addDays(mondayDate, 7);
    setMondayDate(newMonday);
  };

  const handleReset = () => {
    const newMonday = startOfWeek(new Date(), { weekStartsOn: 1 });
    setMondayDate(newMonday);
  };

  const handleInputOnChange = (id: number) => {
    if (selectedUserIds.find((x) => x === id) === undefined) {
      setSelectedUserIds([...selectedUserIds, id]);
    } else {
      setSelectedUserIds(selectedUserIds.filter((x) => x !== id));
    }
  };

  const handleOpen = (isAdding: boolean) => {
    setIsAdding(isAdding);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleConfirm = () => {
    setReload(!reload);
    setIsOpen(false);
  };

  const handleDetailDataClose = () => {
    setOpenDetailData(null);
  };

  const handleAddAction = () => {
    navigate("/calendar/list/new?return=/calendar/teamweek");
  };

  const handleSelectAll = () => {
    selectedUserIds.length === users.length
      ? setSelectedUserIds([])
      : setSelectedUserIds(users.map((x) => x.id));
  };

  useEffect(() => {
    (async () => {
      setLoadingUsers(true);
      try {
        //todo: better endpoint
        const users = await userApi.getList({});
        setUsers(users.data);
        setSelectedUserIds(users.data.map((x) => x.id));
      } catch (err) {
        errorToast();
      }
      setLoadingUsers(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  useEffect(() => {
    (async () => {
      if (loadingUsers) {
        return;
      }

      setLoadingData(true);
      try {
        const data = await getData(mondayDate, selectedUserIds);
        setLabels(data.labels);
        setCols(data.cols);
      } catch (err) {
        errorToast();
      }
      setLoadingData(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mondayDate, selectedUserIds, loadingUsers, reload, t]);

  //todo: users!
  return (
    <>
      {hasRight(identityRights, [RightType.WriteCalendarTeamWeek]) && (
        <>
          <Button onClick={() => handleOpen(true)}>
            {t("calendar.addButton")}
          </Button>
          <SpaceBetweenButtons />
          <Button ver="secondary" onClick={() => handleOpen(false)}>
            {t("calendar.deleteButton")}
          </Button>
          <SpaceBetweenButtons />
          <Button ver="secondary" onClick={handleAddAction}>
            {t("calendar.addAction")}
          </Button>
        </>
      )}

      <CalendarModal
        isAdding={isAdding}
        selectUser={false}
        selectFrom={mondayDate}
        selectTo={addDays(mondayDate, 6)}
        selectOnce={false}
        isOpen={isOpen}
        close={handleClose}
        confirm={handleConfirm}
      />
      <CalendarDetailModal
        data={openDetailData}
        close={handleDetailDataClose}
      />
      <VerticalSpace />
      <div style={{ marginBottom: "0.5rem" }}>
        <input
          id="select-all"
          type="checkbox"
          style={{ width: "1.5rem", height: "1.5rem", marginRight: "0.5rem" }}
          checked={selectedUserIds.length === users.length}
          onChange={handleSelectAll}
        />
        <label style={{ fontWeight: "bold" }} htmlFor="select-all">
          {selectedUserIds.length === users.length
            ? t("common.unselectAll")
            : t("common.selectAll")}
        </label>
      </div>
      {users.map((x) => (
        <p key={x.id}>
          <input
            id={"user" + x.id}
            name={"user" + x.id}
            type="checkbox"
            style={{ width: "1.5rem", height: "1.5rem", marginRight: "0.5rem" }}
            checked={selectedUserIds.find((c) => c === x.id) !== undefined}
            onChange={() => handleInputOnChange(x.id)}
          />
          <label htmlFor={"user" + x.id}>{formatters.fullName(x)}</label>
        </p>
      ))}
      <CalendarHeader>
        <CalendarDateSelector
          date={mondayDate}
          onClickPrev={handlePrev}
          onClickNext={handleNext}
          onReset={handleReset}
        />
      </CalendarHeader>
      <Calendar
        labels={labels}
        cols={cols}
        onClick={handleOnClick}
        multiMode={true}
        loading={loadingUsers || loadingData}
      />
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    identityRights: selectIdentityRights(state),
  };
};

export default connect(mapStateToProps)(CalendarTeamWeek);
