import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const SearchTextContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SearchIcon = styled(FontAwesomeIcon)`
  margin-left: 1rem;
`;

export const SearchResultContainer = styled.div`
  margin-top: 0.2rem;
`;

export const SearchResultLink = styled(Link)`
  margin-left: 0.2rem;
  text-decoration: none;
  font-size: 1.2rem;
`;
