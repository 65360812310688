import { AppThunkAction, AppThunkActionAsync } from "..";
import * as actions from "./actions";
import workflowApi from "../../api/workflow";
import {
  selectWorkflowUnsetTypesLastLoaded,
  selectWorkflowUnsetTypesState,
} from ".";
import { StoreState } from "../storeState";
import { addMinutes, isAfter } from "date-fns";
import { CACHE_MINUTES } from "../../utils/consts";

export function getWorkflowUnsetTypes(): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = getState();
    const unsetTypesState = selectWorkflowUnsetTypesState(state);
    if (unsetTypesState === StoreState.Loading) {
      return;
    }

    if (unsetTypesState === StoreState.Loaded) {
      const unsetTypesLastLoaded = selectWorkflowUnsetTypesLastLoaded(state);
      if (
        !!unsetTypesLastLoaded &&
        !isAfter(new Date(), addMinutes(unsetTypesLastLoaded, CACHE_MINUTES))
      ) {
        return;
      }
    }

    dispatch(actions.workflowUnsetTypesFetchRequest());
    try {
      const unsetTypes = await workflowApi.getWorkflowUnsetTypes();
      dispatch(actions.workflowUnsetTypesFetchSuccess(unsetTypes.data));
    } catch {
      dispatch(actions.workflowUnsetTypesFetchError());
    }
  };
}

export function resetWorkflowUnsetTypes(): AppThunkAction {
  return (dispatch) => {
    dispatch(actions.workflowUnsetTypesReset());
  };
}
