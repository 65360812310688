import { CancelToken } from "axios";
import { FC, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { IApplicationState } from "../../store";
import { IFilterType } from "../../store/filterType";
import {
  changeTemplateFolderListFilter,
  changeTemplateFolderListOrder,
  changeTemplateFolderListPage,
  getTemplateFolderList,
  getTemplateFolderListCount,
  ITemplateFolderListState,
  selectTemplateFolderList,
  templateFolderListLoad,
} from "../../store/templateFolderList";
import FilterPanel from "../common/filter/FilterPanel";
import Grid from "../common/grid/Grid";
import { GridIconAdd, GridIconEdit } from "../common/grid/GridIcons";
import { TableCol } from "../common/grid/TableCol";
import { RightType } from "../../models/auth";
import { hasRight } from "../../utils/rights";
import { selectIdentityRights } from "../../store/identity";
import ModuleDocumentFolderModal from "./ModuleDocumentFolderModal";
import templateApi from "../../api/template";
import { IFolderItem } from "../../models/document";

const defaultFolder: IFolderItem = {
  id: 0,
  name: "",
  users: [],
  groups: [],
  roles: [],
  assistances: [],
  isClientVisible: false,
};

interface IProps {
  prov: ITemplateFolderListState;
  identityRights?: RightType[];
  getTemplateFolderList(cancelToken: CancelToken): void;
  getTemplateFolderListCount(cancelToken: CancelToken): void;
  changeTemplateFolderListOrder(orderBy: string, orderDesc: boolean): void;
  changeTemplateFolderListPage(page: number): void;
  changeTemplateFolderListFilter(filter: IFilterType): void;
  templateFolderListLoad(reload: boolean): void;
}

const ModuleDocumentFolder: FC<IProps> = ({
  prov,
  identityRights,
  getTemplateFolderList,
  getTemplateFolderListCount,
  changeTemplateFolderListOrder,
  changeTemplateFolderListPage,
  changeTemplateFolderListFilter,
  templateFolderListLoad,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { folderId } = useParams();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isAdding, setIsAdding] = useState(false);
  const [openedFolder, setOpenedFolder] = useState<IFolderItem>(defaultFolder);

  useEffect(() => {
    templateFolderListLoad(false);
  }, [templateFolderListLoad]);

  const handleEdit = (item: IFolderItem) => {
    setIsAdding(false);
    setOpenedFolder(item);
    setIsOpen(true);
  };

  const handleAdd = () => {
    setIsAdding(true);
    setOpenedFolder(defaultFolder);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleConfirm = () => {
    templateFolderListLoad(true);
    setIsOpen(false);
  };

  const handleRenderData = (item: IFolderItem): ReactElement => {
    return (
      <>
        <TableCol>{item.name}</TableCol>
        <TableCol>
          {hasRight(identityRights, [RightType.WriteAllTemplates]) && (
            <GridIconEdit onClick={() => handleEdit(item)} />
          )}
        </TableCol>
      </>
    );
  };

  const handleRowClick = (item: IFolderItem) => {
    navigate(`/module/document/${item.id}`);
  };

  return (
    <>
      <ModuleDocumentFolderModal
        folder={openedFolder}
        parentId={folderId!}
        isAdding={isAdding}
        isOpen={isOpen}
        showIsClientVisible={false}
        createFolder={templateApi.createFolder}
        updateFolder={templateApi.updateFolder}
        close={handleClose}
        confirm={handleConfirm}
      />

      <FilterPanel
        title={t("module.document.folder.pageTitle")}
        name="moduleDocumentFolder"
        filter={prov.filter!}
        changeFilter={changeTemplateFolderListFilter}
      />

      <Grid<IFolderItem>
        headers={[
          { captionStr: "module.document.folder.name" },
          {
            captionEl: hasRight(identityRights, [
              RightType.WriteAllTemplates,
            ]) ? (
              <GridIconAdd onClick={() => handleAdd()} />
            ) : undefined,
          },
        ]}
        renderData={handleRenderData}
        getData={getTemplateFolderList}
        getCount={getTemplateFolderListCount}
        changeOrder={changeTemplateFolderListOrder}
        changePage={changeTemplateFolderListPage}
        onRowClick={handleRowClick}
        prov={prov}
      />
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    prov: selectTemplateFolderList(state),
    identityRights: selectIdentityRights(state),
  };
};

const mapDispachToProps = {
  getTemplateFolderList,
  getTemplateFolderListCount,
  changeTemplateFolderListOrder,
  changeTemplateFolderListPage,
  changeTemplateFolderListFilter,
  templateFolderListLoad,
};

export default connect(
  mapStateToProps,
  mapDispachToProps
)(ModuleDocumentFolder);
