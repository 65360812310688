import { IGroupItem } from "../../models/group";
import { IFilterType } from "../filterType";
import { IListType } from "../listType";

export enum GroupListTypes {
  GROUP_LIST_FETCH_REQUEST = "@@groupList/FETCH_REQUEST",
  GROUP_LIST_FETCH_SUCCESS = "@@groupList/FETCH_SUCCESS",
  GROUP_LIST_FETCH_ERROR = "@@groupList/FETCH_ERROR",
  GROUP_LIST_COUNT_REQUEST = "@@groupList/COUNT_REQUEST",
  GROUP_LIST_COUNT_SUCCESS = "@@groupList/COUNT_SUCCESS",
  GROUP_LIST_COUNT_ERROR = "@@groupList/COUNT_ERROR",
  GROUP_LIST_CHANGE_ORDER = "@@groupList/CHANGE_ORDER",
  GROUP_LIST_CHANGE_PAGE = "@@groupList/CHANGE_PAGE",
  GROUP_LIST_CHANGE_FILTER = "@@groupList/CHANGE_FILTER",
  GROUP_LIST_RESET = "@@groupList/RESET",
  GROUP_LIST_LOAD = "@@groupList/LOAD",
}

export interface IGroupListState extends IListType<IGroupItem, IFilterType> {}
