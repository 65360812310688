import { CancelToken } from "axios";
import { FC, ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { IClient, IClientCareItem } from "../../models/client";
import { IApplicationState } from "../../store";
import { IFilterType } from "../../store/filterType";
import {
  changeClientCareListFilter,
  changeClientCareListOrder,
  changeClientCareListPage,
  getClientCareList,
  getClientCareListCount,
  IClientCareListState,
  selectClientCareList,
  clientCareListLoad,
} from "../../store/clientCareList";
import FilterPanel from "../common/filter/FilterPanel";
import Grid from "../common/grid/Grid";
import { GridIconAdd, GridIconEdit } from "../common/grid/GridIcons";
import { TableCol } from "../common/grid/TableCol";
import { RightType } from "../../models/auth";
import { hasClientWriteRight } from "../../utils/rights";
import { selectIdentityRights } from "../../store/identity";
import { selectClient } from "../../store/client";
import { useNavigate, useParams } from "react-router";
import { NavLink } from "react-router-dom";
import { format } from "date-fns";
import { DATE_FORMAT } from "../../utils/consts";

interface IProps {
  prov: IClientCareListState;
  client: IClient | null;
  identityRights?: RightType[];
  getClientCareList(clientId: number, cancelToken: CancelToken): void;
  getClientCareListCount(clientId: number, cancelToken: CancelToken): void;
  changeClientCareListOrder(orderBy: string, orderDesc: boolean): void;
  changeClientCareListPage(page: number): void;
  changeClientCareListFilter(filter: IFilterType): void;
  clientCareListLoad(reload: boolean): void;
}

const ClientCare: FC<IProps> = ({
  prov,
  client,
  identityRights,
  getClientCareList,
  getClientCareListCount,
  changeClientCareListOrder,
  changeClientCareListPage,
  changeClientCareListFilter,
  clientCareListLoad,
}) => {
  const { t } = useTranslation();
  const { clientId: paramClientId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    clientCareListLoad(false);
  }, [clientCareListLoad]);

  const handleRenderData = (item: IClientCareItem): ReactElement => {
    return (
      <>
        <TableCol>{item.assistanceAddress.name}</TableCol>
        <TableCol>{item.careType.name}</TableCol>
        <TableCol>{format(item.from, DATE_FORMAT)}</TableCol>
        <TableCol>{item.to && format(item.to, DATE_FORMAT)}</TableCol>
        <TableCol>{t("client.care.states." + item.state)}</TableCol>
        <TableCol>
          <NavLink to={`/client/${paramClientId}/care/${item.id}`}>
            <GridIconEdit />
          </NavLink>
        </TableCol>
      </>
    );
  };

  const handleRowClick = (item: IClientCareItem) => {
    navigate(`/client/${paramClientId}/care/${item.id}`);
  };

  const handleGetData = (cancelToken: CancelToken) =>
    getClientCareList(client!.id, cancelToken);

  const handleGetCount = (cancelToken: CancelToken) =>
    getClientCareListCount(client!.id, cancelToken);

  return (
    <>
      <FilterPanel
        name="clientCare"
        filter={prov.filter!}
        changeFilter={changeClientCareListFilter}
      />
      <Grid<IClientCareItem>
        headers={[
          { captionStr: "client.care.assistanceAddress" },
          { captionStr: "client.care.careType" },
          { captionStr: "client.care.from" },
          { captionStr: "client.care.to" },
          { captionStr: "client.care.state" },
          {
            captionEl: hasClientWriteRight(identityRights, [
              RightType.WriteCare,
            ]) ? (
              <NavLink to={`/client/${paramClientId}/care/new`}>
                <GridIconAdd />
              </NavLink>
            ) : undefined,
          },
        ]}
        renderData={handleRenderData}
        getData={handleGetData}
        getCount={handleGetCount}
        changeOrder={changeClientCareListOrder}
        changePage={changeClientCareListPage}
        onRowClick={handleRowClick}
        prov={prov}
      />
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    prov: selectClientCareList(state),
    client: selectClient(state),
    identityRights: selectIdentityRights(state),
  };
};

const mapDispachToProps = {
  getClientCareList,
  getClientCareListCount,
  changeClientCareListOrder,
  changeClientCareListPage,
  changeClientCareListFilter,
  clientCareListLoad,
};

export default connect(mapStateToProps, mapDispachToProps)(ClientCare);
