import { Form, Formik } from "formik";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router";
import {
  IWorkflow,
  WorkflowPriorityType,
  WorkflowType,
} from "../../models/workflow";
import { IApplicationState } from "../../store";
import { StoreState } from "../../store/storeState";
import {
  getDefaultWorkflow,
  getWorkflow,
  selectWorkflow,
  selectWorkflowState,
} from "../../store/workflow";
import { FormButton } from "../../styles/button";
import { ApiError, FormGroup } from "../../styles/form";
import Input from "../common/form/Input";
import Loader from "../common/Loader";
import * as Yup from "yup";
import { workflowListLoad } from "../../store/workflowList";
import workflowApi from "../../api/workflow";
import { SpaceBetweenButtons } from "../../styles/spaces";
import validations from "../../utils/validations";
import { RightType } from "../../models/auth";
import { hasRight } from "../../utils/rights";
import { selectIdentityRights } from "../../store/identity";
import { promiseToastSave } from "../../utils/toasts";
import SubmitForm from "../common/form/SubmitForm";
import { errorSet } from "../../utils/error";
import { resetWorkflowUnsetTypes } from "../../store/workflowUnsetTypes";
import createEngine from "@projectstorm/react-diagrams";
import Diagram from "../common/diagram/Diagram";
import { getDefinition } from "../common/diagram/diagramHelpers";
import SuggestionFormik from "../common/suggestion/SuggestionFormik";
import { handleLoadWorkflowCategoryListingSuggestions } from "../../utils/suggestions";
import Page from "../layout/Page";
import { IDiagramSettings } from "../../models/diagram";
import {
  getDiagramSettings,
  selectDiagramSettings,
  selectDiagramSettingsState,
} from "../../store/diagramSettings";

interface IProps {
  workflowState: StoreState;
  workflow: IWorkflow | null;
  identityRights?: RightType[];
  getWorkflow(workflowId: number): void;
  getDefaultWorkflow(): void;
  workflowListLoad(reload: boolean): void;
  resetWorkflowUnsetTypes(): void;
  diagramSettingsState: StoreState;
  diagramSettings: IDiagramSettings;
  getDiagramSettings(): void;
}

const ModuleWorkflowDetail: FC<IProps> = ({
  workflowState,
  workflow,
  identityRights,
  getWorkflow,
  getDefaultWorkflow,
  workflowListLoad,
  resetWorkflowUnsetTypes,
  diagramSettingsState,
  diagramSettings,
  getDiagramSettings,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { workflowId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const [diagramEngine] = useState(
    createEngine({
      registerDefaultDeleteItemsAction: false,
      registerDefaultPanAndZoomCanvasAction: false,
      registerDefaultZoomCanvasAction: false,
      repaintDebounceMs: 0,
    })
  );

  useEffect(() => {
    if (workflowId === "new") {
      getDefaultWorkflow();
    } else {
      getWorkflow(parseInt(workflowId!));
    }
    getDiagramSettings();
    setLoading(false);
  }, [workflowId, getWorkflow, getDefaultWorkflow, getDiagramSettings]);

  const handleSubmit = async (data: IWorkflow) => {
    setError(null);
    try {
      await promiseToastSave(async () => {
        const workflow = {
          ...data,
          definition: getDefinition(diagramEngine),
        };

        if (workflowId === "new") {
          await workflowApi.createWorkflow(workflow);
        } else {
          await workflowApi.updateWorkflow(workflow);
        }

        workflowListLoad(true);
        resetWorkflowUnsetTypes();
        navigate("/module/workflow");
      });
    } catch (err) {
      errorSet(setError, err, t);
    }
  };

  const handleCancel = () => {
    navigate("/module/workflow");
  };

  if (
    loading ||
    workflowState === StoreState.Loading ||
    diagramSettingsState === StoreState.Loading
  ) {
    return <Loader />;
  }

  if (
    workflowState === StoreState.Error ||
    diagramSettingsState === StoreState.Error
  ) {
    return t("errors.unknown");
  }

  return (
    <Page maxWidth={true}>
      <Formik<IWorkflow>
        initialValues={workflow!}
        validationSchema={Yup.object({
          name: validations.stringRequired(t),
          categoryId: validations.idRequired(t),
        })}
        validateOnMount={true}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, isSubmitting, values }) => (
          <Form>
            <h1>{t("module.workflow.detailTitle")}</h1>
            <FormGroup>
              <Input
                key="isActive"
                name="isActive"
                type="checkbox"
                label={t(`module.workflow.isActive`)}
                inputWidth="1.5rem"
                inputHeight="1.5rem"
              />
              <Input
                key="name"
                name="name"
                label={t("module.workflow.name")}
                error={touched.name && !!errors.name}
                maxLength={512}
              />
              <SuggestionFormik
                nameId="categoryId"
                nameText="categoryName"
                label={t("module.workflow.category")}
                loadSuggestions={handleLoadWorkflowCategoryListingSuggestions}
              />
              <Input
                name="type"
                label={t("module.workflow.type")}
                error={touched.type && !!errors.type}
                component="select"
              >
                {(
                  Object.keys(WorkflowType) as Array<keyof typeof WorkflowType>
                ).map((key) => (
                  <option key={key} value={WorkflowType[key]}>
                    {t("module.workflow.types." + WorkflowType[key])}
                  </option>
                ))}
              </Input>
              <Input
                name="priority"
                label={t("module.workflow.priority")}
                error={touched.priority && !!errors.priority}
                component="select"
              >
                {(
                  Object.keys(WorkflowPriorityType) as Array<
                    keyof typeof WorkflowPriorityType
                  >
                ).map((key) => (
                  <option key={key} value={WorkflowPriorityType[key]}>
                    {t(
                      "module.workflow.priorities." + WorkflowPriorityType[key]
                    )}
                  </option>
                ))}
              </Input>
            </FormGroup>

            <Diagram
              settings={diagramSettings}
              engine={diagramEngine}
              definition={values.definition}
            />

            {error && <ApiError>{error}</ApiError>}
            <FormButton
              ver="secondary"
              disabled={isSubmitting}
              onClick={handleCancel}
            >
              {t("common.back")}
            </FormButton>
            {hasRight(identityRights, [RightType.WriteWorkflow]) && (
              <>
                <SpaceBetweenButtons />
                <SubmitForm />
              </>
            )}
          </Form>
        )}
      </Formik>
    </Page>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    workflowState: selectWorkflowState(state),
    workflow: selectWorkflow(state),
    identityRights: selectIdentityRights(state),
    diagramSettingsState: selectDiagramSettingsState(state),
    diagramSettings: selectDiagramSettings(state),
  };
};

const mapDispachToProps = {
  getWorkflow,
  getDefaultWorkflow,
  workflowListLoad,
  resetWorkflowUnsetTypes,
  getDiagramSettings,
};

export default connect(
  mapStateToProps,
  mapDispachToProps
)(ModuleWorkflowDetail);
