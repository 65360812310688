import styled from "styled-components";
import { SIZES } from "../../../styles/sizes";

export const FilterTitleContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const FilterContainer = styled.div`
  margin-bottom: 0.5rem;
`;

export const FilterHeaderContainer = styled.div`
  min-height: 2.5rem;
  display: flex;
`;

export const FilterSearchContainer = styled.div`
  margin-left: auto;
  padding-left: 1rem;
  min-width: 15rem;
  width: 40%;
  display: flex;
  align-items: center;
`;

export const FilterHeaderIcon = styled.div`
  margin-left: 0.5rem;
`;

export const FilterExtendedContainer = styled.div<{ open: boolean }>`
  position: relative;
  top: 0.5rem;

  grid-template-columns: 1fr;
  grid-gap: 1rem;

  margin-top: 0.5rem;
  padding: 1rem;

  /* TODO color */
  background-color: #faf0e7;

  display: ${({ open }) => (open ? "grid" : "none")};

  @media screen and (min-width: ${SIZES.tablet}) {
    grid-template-columns: 2fr min-content;
  }
`;
