export enum ListingType {
  ActionListing = "actionListing",
  AssistanceCategoryListing = "assistanceCategoryListing",
  ClientContactPersonListing = "clientContactPersonListing",
  ClientRiskListing = "clientRiskListing",
  SocialBenefitListing = "socialBenefitListing",
  TargetGroupListing = "targetGroupListing",
  ContactPersonListing = "contactPersonListing",
  WorkflowCategoryListing = "workflowCategoryListing",
}

export interface IListingItem {
  id: number;
  isActive: boolean;
  name: string;
}

export interface IListingEdit {
  name: string;
  isActive: boolean;
}
