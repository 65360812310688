import * as ReselectModule from "reselect";
import { IApplicationState } from "..";
import { ICareType } from "../../models/careType";
import { StoreState } from "../storeState";

export const selectCareTypeState: ReselectModule.Selector<
  IApplicationState,
  StoreState
> = (state) => state.careType.state;

export const selectCareType: ReselectModule.Selector<
  IApplicationState,
  ICareType | null
> = (state) => state.careType.data;
