export enum RoleRightType {
  None = "none",
  Read = "read",
  Write = "write",
}

export enum RoleRightMode {
  Both = "both",
  Read = "read",
  Write = "write",
}

export interface IRoleItem {
  id: number;
  name: string;
  description: string;
  usersCount: number;
}

export interface IRole {
  id: number;
  name: string;
  description: string;

  createAssistance: boolean;
  readAssistances: boolean;
  readAllAssistances: boolean;
  writeAssistances: boolean;
  writeAllAssistances: boolean;
  writeAssistanceState: boolean;
  readWorkflowTemplate: boolean;
  writeWorkflowTemplate: boolean;
  readWorkflow: boolean;
  writeWorkflow: boolean;

  readAllClients: boolean;
  readMyClients: boolean;
  readClientsInTheSameAssistance: boolean;
  readClientsFinancingData: boolean;
  readClientNotes: boolean;
  readClientContactPersons: boolean;
  readClientsRights: boolean;
  readClientRisk: boolean;
  readClientHousingSituation: boolean;
  readClientAction: boolean;
  readClientDemand: boolean;

  writeClientContactPersons: boolean;
  createClient: boolean;
  writeAllClients: boolean;
  writeMyClients: boolean;
  writeClientsInTheSameAssistance: boolean;
  writeClientGeneral: boolean;
  writeClientNotes: boolean;
  writeClientsFinancingData: boolean;
  writeClientsRights: boolean;
  writeClientRisk: boolean;
  writeClientHousingSituation: boolean;
  writeClientAction: boolean;
  writeClientDemand: boolean;
  changeClientState: boolean;

  readAllUsers: boolean;
  readUsersInTheSameGroup: boolean;
  readUsersInTheSameAssistance: boolean;
  readUsers: boolean;
  writeUsers: boolean;
  addUsers: boolean;
  writeAllUsers: boolean;
  writeUsersInTheSameGroup: boolean;
  writeUsersInTheSameAssistance: boolean;
  changeUserIsActive: boolean;
  addClientGdprAgreement: boolean;
  addClientGdprAgreementWithoutSecret: boolean;

  readActionListing: boolean;
  writeActionListing: boolean;
  readAssistanceCategoryListing: boolean;
  writeAssistanceCategoryListing: boolean;
  readClientContactPersonListing: boolean;
  writeClientContactPersonListing: boolean;
  readClientRiskListing: boolean;
  writeClientRiskListing: boolean;
  readSocialBenefitListing: boolean;
  writeSocialBenefitListing: boolean;
  readTargetGroupListing: boolean;
  writeTargetGroupListing: boolean;
  readContactPersonListing: boolean;
  writeContactPersonListing: boolean;
  readWorkflowCategoryListing: boolean;
  writeWorkflowCategoryListing: boolean;

  readHealthInsurance: boolean;
  writeHealthInsurance: boolean;

  readBasicSettings: boolean;
  writeBasicSettings: boolean;
  readPublic: boolean;
  readMonitor: boolean;
  readLog: boolean;
  readUserGroups: boolean;
  writeUserGroups: boolean;
  readCareType: boolean;
  writeCareType: boolean;
  readRole: boolean;
  writeRole: boolean;
  changeRoleRights: boolean;
  anonymiseClient: boolean;
  exportClient: boolean;

  readDemand: boolean;
  writeDemand: boolean;
  assignDemand: boolean;
  changeAssignedUserDemand: boolean;
  changeAssignedGroupDemand: boolean;

  readAllActions: boolean;
  readAction: boolean;
  writeAllActions: boolean;
  writeAction: boolean;
  cancelAction: boolean;
  updateAssignedUserAction: boolean;
  deleteAction: boolean;

  readAllCalendars: boolean;
  readCalendar: boolean;
  readClientCalendar: boolean;
  writeAllCalendars: boolean;
  writeCalendar: boolean;
  writeClientCalendar: boolean;
  readCalendarList: boolean;
  writeCalendarList: boolean;
  readCalendarMy: boolean;
  writeCalendarMy: boolean;
  readCalendarTeamWeek: boolean;
  writeCalendarTeamWeek: boolean;
  readCalendarTeamDay: boolean;
  writeCalendarTeamDay: boolean;

  readAllTemplates: boolean;
  readClientDocuments: boolean;
  writeClientDocuments: boolean;
  readAssistanceDocuments: boolean;
  writeAssistanceDocuments: boolean;
  writeAllTemplates: boolean;
  deleteAnyDocument: boolean;
  readDeletedDocuments: boolean;
  readFolders: boolean;
  writeFolders: boolean;
  softDeleteFolders: boolean;

  readCare: boolean;
  writeCare: boolean;
  readNotification: boolean;
  writeNotification: boolean;
  readReporting: boolean;
  writeReporting: boolean;
}
