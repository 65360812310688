import { AppThunkAction, AppThunkActionAsync } from "..";
import * as actions from "./actions";
import userApi from "../../api/user";
import { IUserListFilter, IUserListState, selectUserList } from ".";
import { CancelToken } from "axios";
import {
  listEffectCountFilter,
  listEffectGetFilter,
  listEffectLoad,
  listEffectReload,
  listEffectsCountCheck,
  listEffectsGetCheck,
  listEffectsGetCount,
  listEffectsGetNew,
  listEffectsGetParams,
} from "../listEffect";

export function getUserList(cancelToken: CancelToken): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectUserList(getState());
    if (listEffectsGetCheck(state.state)) {
      return;
    }

    let newState = listEffectsGetNew(state);
    dispatch(actions.userListFetchRequest(newState.newCountState));
    try {
      const params = listEffectsGetParams(state, newState.take);
      listEffectGetFilter(params, state.filter);
      addUserParams(params, state);

      const users = await userApi.getList({ params, cancelToken });
      cancelToken.throwIfRequested();
      newState = listEffectsGetCount(newState, users);

      dispatch(
        actions.userListFetchSuccess({
          data: users.data,
          newCountState: newState.newCountState,
          newCount: newState.newCount,
        })
      );
    } catch {
      dispatch(actions.userListFetchError(cancelToken.reason !== undefined));
    }
  };
}

export function getUserListCount(
  cancelToken: CancelToken
): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectUserList(getState());
    if (listEffectsCountCheck(state.countState)) {
      return;
    }

    dispatch(actions.userListCountRequest());
    try {
      const params = listEffectCountFilter(state.filter);
      addUserParams(params, state);

      const count = await userApi.getCount({ params, cancelToken });
      cancelToken.throwIfRequested();

      dispatch(actions.userListCountSuccess(count.data));
    } catch {
      dispatch(actions.userListCountError(cancelToken.reason !== undefined));
    }
  };
}

export function changeUserListOrder(
  orderBy: string,
  orderDesc: boolean
): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.userListChangeOrder({ orderBy, orderDesc }));
  };
}

export function changeUserListPage(page: number): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.userListChangePage(page));
  };
}

export function changeUserListFilter(filter: IUserListFilter): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.userListChangeFilter(filter));
  };
}

export function openUserListFilter(): AppThunkAction {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(
      actions.userListChangeFilter({
        ...state.userList.filter!,
        isOpen: true,
      })
    );
  };
}

export function userListLoad(reload: boolean): AppThunkAction {
  return async (dispatch, getState) => {
    const state = selectUserList(getState());
    reload = listEffectReload(reload, state);
    if (reload) {
      dispatch(actions.userListReset());
    } else {
      const newState = listEffectLoad(state);
      dispatch(
        actions.userListLoadAction({
          newState: newState.newState,
          newCountState: newState.newCountState,
        })
      );
    }
  };
}

export function addUserParams(params: URLSearchParams, state: IUserListState) {
  params.append("isActive", state.filter!.activeOnly ? "true" : "false");

  if (state.filter!.assistanceId) {
    params.append("assistanceId", state.filter!.assistanceId.toString());
  }

  for (const group of state.filter!.groups) {
    params.append("groups", group.id.toString());
  }
}
