import { CancelToken } from "axios";
import { FC, ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { RightType } from "../../models/auth";
import { ICareTypeItem } from "../../models/careType";
import { IApplicationState } from "../../store";
import { IFilterType } from "../../store/filterType";
import { selectIdentityRights } from "../../store/identity";
import {
  changeCareTypeListFilter,
  changeCareTypeListOrder,
  changeCareTypeListPage,
  getCareTypeList,
  getCareTypeListCount,
  ICareTypeListState,
  selectCareTypeList,
  careTypeListLoad,
} from "../../store/careTypeList";
import { hasRight } from "../../utils/rights";
import { handleLoadAssistanceCategoryListingSuggestions } from "../../utils/suggestions";
import FilterPanel from "../common/filter/FilterPanel";
import Grid from "../common/grid/Grid";
import {
  GridIconAdd,
  GridIconEdit,
  GridIconIsActive,
} from "../common/grid/GridIcons";
import { TableCol, TableColCenter } from "../common/grid/TableCol";
import SuggestionFormik from "../common/suggestion/SuggestionFormik";

interface IProps {
  prov: ICareTypeListState;
  identityRights?: RightType[];
  getCareTypeList(cancelToken: CancelToken): void;
  getCareTypeListCount(cancelToken: CancelToken): void;
  changeCareTypeListOrder(orderBy: string, orderDesc: boolean): void;
  changeCareTypeListPage(page: number): void;
  changeCareTypeListFilter(filter: IFilterType): void;
  careTypeListLoad(reload: boolean): void;
}

const AdminCareType: FC<IProps> = ({
  prov,
  identityRights,
  getCareTypeList,
  getCareTypeListCount,
  changeCareTypeListOrder,
  changeCareTypeListPage,
  changeCareTypeListFilter,
  careTypeListLoad,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    careTypeListLoad(false);
  }, [careTypeListLoad]);

  const handleRenderData = (item: ICareTypeItem): ReactElement => {
    return (
      <>
        <TableColCenter>{item.isActive && <GridIconIsActive />}</TableColCenter>
        <TableCol>{item.name}</TableCol>
        <TableCol>
          <NavLink to={`/admin/caretype/${item.id}`}>
            <GridIconEdit />
          </NavLink>
        </TableCol>
      </>
    );
  };

  const handleRowClick = (item: ICareTypeItem) => {
    navigate(`/admin/caretype/${item.id}`);
  };

  return (
    <>
      <FilterPanel
        title={t("admin.careType.pageTitle")}
        name="adminCareType"
        filter={prov.filter!}
        changeFilter={changeCareTypeListFilter}
        initialValues={{
          assistanceCategoryListingId: prov.filter!.assistanceCategoryListingId,
          assistanceCategoryListingName:
            prov.filter!.assistanceCategoryListingName,
        }}
      >
        <SuggestionFormik
          nameId="assistanceCategoryListingId"
          nameText="assistanceCategoryListingName"
          label={t("admin.careType.assistanceCategoryListing")}
          loadSuggestions={handleLoadAssistanceCategoryListingSuggestions}
        />
      </FilterPanel>
      <Grid<ICareTypeItem>
        headers={[
          {
            captionStr: "admin.careType.isActive",
            minContent: true,
          },
          { captionStr: "admin.careType.name" },
          {
            captionEl: hasRight(identityRights, [RightType.WriteCareType]) ? (
              <NavLink to={`/admin/caretype/new`}>
                <GridIconAdd />
              </NavLink>
            ) : undefined,
          },
        ]}
        renderData={handleRenderData}
        getData={getCareTypeList}
        getCount={getCareTypeListCount}
        changeOrder={changeCareTypeListOrder}
        changePage={changeCareTypeListPage}
        onRowClick={handleRowClick}
        prov={prov}
      />
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    prov: selectCareTypeList(state),
    identityRights: selectIdentityRights(state),
  };
};

const mapDispachToProps = {
  getCareTypeList,
  getCareTypeListCount,
  changeCareTypeListOrder,
  changeCareTypeListPage,
  changeCareTypeListFilter,
  careTypeListLoad,
};

export default connect(mapStateToProps, mapDispachToProps)(AdminCareType);
