import { CancelToken } from "axios";
import { FC, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { IClientBenefitItem } from "../../models/client";
import { IApplicationState } from "../../store";
import { IFilterType } from "../../store/filterType";
import {
  changeClientBenefitListFilter,
  changeClientBenefitListOrder,
  changeClientBenefitListPage,
  getClientBenefitList,
  getClientBenefitListCount,
  IClientBenefitListState,
  selectClientBenefitList,
  clientBenefitListLoad,
} from "../../store/clientBenefitList";
import FilterPanel from "../common/filter/FilterPanel";
import Grid from "../common/grid/Grid";
import {
  GridIconAdd,
  GridIconDelete,
  GridIconEdit,
  GridIconSpace,
} from "../common/grid/GridIcons";
import { TableCol, TableColInput } from "../common/grid/TableCol";
import { RightType } from "../../models/auth";
import { hasClientWriteRight } from "../../utils/rights";
import { selectIdentityRights } from "../../store/identity";
import { selectClientId } from "../../store/client";
import { Form, Formik, useFormikContext } from "formik";
import Input from "../common/form/Input";
import { GridInlineButtons } from "../common/grid/GridInline";
import * as Yup from "yup";
import clientApi from "../../api/client";
import validations from "../../utils/validations";
import SuggestionFormik from "../common/suggestion/SuggestionFormik";
import { handleLoadSocialBenefitListingSuggestions } from "../../utils/suggestions";
import {
  promiseToastDeleteNoException,
  promiseToastSave,
} from "../../utils/toasts";
import { ModalYesNoFunction } from "../common/modal/ModalFunctions";

const defaultData = {
  id: 0,
  typeId: 0,
  typeName: "",
  amountPerMonth: 0,
  description: "",
} as IClientBenefitItem;

interface IProps {
  prov: IClientBenefitListState;
  clientId?: number;
  identityRights?: RightType[];
  getClientBenefitList(clientId: number, cancelToken: CancelToken): void;
  getClientBenefitListCount(clientId: number, cancelToken: CancelToken): void;
  changeClientBenefitListOrder(orderBy: string, orderDesc: boolean): void;
  changeClientBenefitListPage(page: number): void;
  changeClientBenefitListFilter(filter: IFilterType): void;
  clientBenefitListLoad(reload: boolean): void;
}

const ClientFinanceBenefitGridEditor: FC<{
  data: IClientBenefitItem;
  handleCancel(): void;
}> = ({ data, handleCancel }) => {
  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    resetForm,
    setFieldValue,
  } = useFormikContext<IClientBenefitItem>();

  useEffect(() => {
    resetForm();
    setFieldValue("typeId", data.typeId);
    setFieldValue("typeName", data.typeName);
    setFieldValue(
      "amountPerMonth",
      data.amountPerMonth ? data.amountPerMonth : ""
    );
    setFieldValue("description", data.description ?? "");
  }, [data, resetForm, setFieldValue]);

  return (
    <>
      <TableColInput>
        <SuggestionFormik
          nameId="typeId"
          nameText="typeName"
          loadSuggestions={handleLoadSocialBenefitListingSuggestions}
        />
      </TableColInput>
      <TableColInput>
        <Input
          name="amountPerMonth"
          type="number"
          error={touched.amountPerMonth && !!errors.amountPerMonth}
        />
      </TableColInput>
      <TableColInput>
        <Input
          name="description"
          error={touched.description && !!errors.description}
          maxLength={512}
        />
      </TableColInput>
      <GridInlineButtons
        handleCancel={handleCancel}
        isSubmitting={isSubmitting}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

const ClientFinanceBenefit: FC<IProps> = ({
  prov,
  clientId,
  identityRights,
  getClientBenefitList,
  getClientBenefitListCount,
  changeClientBenefitListOrder,
  changeClientBenefitListPage,
  changeClientBenefitListFilter,
  clientBenefitListLoad,
}) => {
  const { t } = useTranslation();
  const [inlineEditId, setInlineEditId] = useState<number | undefined>();
  const [inlineAdd, setInlineAdd] = useState(false);

  useEffect(() => {
    clientBenefitListLoad(false);
  }, [clientBenefitListLoad]);

  const handleEdit = (id: number) => {
    setInlineAdd(false);
    setInlineEditId(id);
  };

  const handleDelete = async (id: number) => {
    const res = await ModalYesNoFunction(
      t("client.finance.benefit.delete"),
      t("client.finance.benefit.deleteConfirm")
    );
    if (res) {
      await promiseToastDeleteNoException(async () => {
        await clientApi.deleteClientBenefit(id);
        clientBenefitListLoad(true);
      });
    }
  };

  const handleCancel = () => {
    setInlineEditId(undefined);
    setInlineAdd(false);
  };

  const handleAdd = () => {
    setInlineEditId(undefined);
    setInlineAdd(true);
  };

  const handleRenderData = (item: IClientBenefitItem): ReactElement => {
    return (
      <>
        <TableCol>{item.typeName}</TableCol>
        <TableCol>{item.amountPerMonth}</TableCol>
        <TableCol>{item.description}</TableCol>
        <TableCol>
          {!inlineEditId &&
            !inlineAdd &&
            hasClientWriteRight(identityRights, [
              RightType.WriteClientsFinancingData,
            ]) && (
              <>
                <GridIconEdit onClick={() => handleEdit(item.id)} />
                <GridIconSpace />
                <GridIconDelete onClick={() => handleDelete(item.id)} />
              </>
            )}
        </TableCol>
      </>
    );
  };

  const handleSubmit = async (data: IClientBenefitItem) => {
    await promiseToastSave(async () => {
      if (inlineEditId) {
        await clientApi.updateClientBenefit(inlineEditId, data);
      } else {
        await clientApi.createClientBenefit(clientId!, data);
      }
      clientBenefitListLoad(true);
      handleCancel();
    });
  };

  const handleRenderEditor = (item: IClientBenefitItem): ReactElement => {
    return (
      <ClientFinanceBenefitGridEditor data={item} handleCancel={handleCancel} />
    );
  };

  const handleRowClick = (item: IClientBenefitItem) => {
    setInlineEditId(item.id);
  };

  const handleGetData = (cancelToken: CancelToken) =>
    getClientBenefitList(clientId!, cancelToken);

  const handleGetCount = (cancelToken: CancelToken) =>
    getClientBenefitListCount(clientId!, cancelToken);

  return (
    <>
      <FilterPanel
        viewFiltration={!inlineEditId && !inlineAdd}
        title={t("client.finance.benefit.detailTitle")}
        name="clientBenefit"
        filter={prov.filter!}
        changeFilter={changeClientBenefitListFilter}
      />

      <Formik
        initialValues={defaultData}
        validationSchema={Yup.object({
          typeId: validations.idRequired(t),
          amountPerMonth: validations.numberRequiredMinMax(1, 999999, t),
        })}
        validateOnMount={true}
        onSubmit={handleSubmit}
      >
        <Form>
          <Grid<IClientBenefitItem>
            headers={[
              { captionStr: "client.finance.benefit.type" },
              { captionStr: "client.finance.benefit.amountPerMonth" },
              { captionStr: "client.finance.benefit.description" },
              {
                captionEl:
                  !inlineEditId &&
                  !inlineAdd &&
                  hasClientWriteRight(identityRights, [
                    RightType.WriteClientsFinancingData,
                  ]) ? (
                    <GridIconAdd onClick={handleAdd} />
                  ) : undefined,
              },
            ]}
            inlineEditId={inlineEditId}
            inlineAdd={inlineAdd}
            defaultData={defaultData}
            renderData={handleRenderData}
            renderEditor={handleRenderEditor}
            getData={handleGetData}
            getCount={handleGetCount}
            changeOrder={changeClientBenefitListOrder}
            changePage={changeClientBenefitListPage}
            onRowClick={
              hasClientWriteRight(identityRights, [
                RightType.WriteClientsFinancingData,
              ])
                ? handleRowClick
                : undefined
            }
            prov={prov}
          />
        </Form>
      </Formik>
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    prov: selectClientBenefitList(state),
    clientId: selectClientId(state),
    identityRights: selectIdentityRights(state),
  };
};

const mapDispachToProps = {
  getClientBenefitList,
  getClientBenefitListCount,
  changeClientBenefitListOrder,
  changeClientBenefitListPage,
  changeClientBenefitListFilter,
  clientBenefitListLoad,
};

export default connect(
  mapStateToProps,
  mapDispachToProps
)(ClientFinanceBenefit);
