import { createAction } from "@reduxjs/toolkit";
import { IClientDebtItem } from "../../models/client";
import { IFilterType } from "../filterType";
import { StoreState, StoreStateDontLoad } from "../storeState";
import { ClientDebtListTypes } from "./types";

export const clientDebtListFetchRequest = createAction<
  StoreStateDontLoad,
  ClientDebtListTypes.CLIENT_DEBT_LIST_FETCH_REQUEST
>(ClientDebtListTypes.CLIENT_DEBT_LIST_FETCH_REQUEST);

export const clientDebtListFetchSuccess = createAction<
  {
    data: IClientDebtItem[];
    newCountState: StoreStateDontLoad;
    newCount: number;
  },
  ClientDebtListTypes.CLIENT_DEBT_LIST_FETCH_SUCCESS
>(ClientDebtListTypes.CLIENT_DEBT_LIST_FETCH_SUCCESS);

export const clientDebtListFetchError = createAction<
  boolean,
  ClientDebtListTypes.CLIENT_DEBT_LIST_FETCH_ERROR
>(ClientDebtListTypes.CLIENT_DEBT_LIST_FETCH_ERROR);

export const clientDebtListCountRequest = createAction<
  void,
  ClientDebtListTypes.CLIENT_DEBT_LIST_COUNT_REQUEST
>(ClientDebtListTypes.CLIENT_DEBT_LIST_COUNT_REQUEST);

export const clientDebtListCountSuccess = createAction<
  number,
  ClientDebtListTypes.CLIENT_DEBT_LIST_COUNT_SUCCESS
>(ClientDebtListTypes.CLIENT_DEBT_LIST_COUNT_SUCCESS);

export const clientDebtListCountError = createAction<
  boolean,
  ClientDebtListTypes.CLIENT_DEBT_LIST_COUNT_ERROR
>(ClientDebtListTypes.CLIENT_DEBT_LIST_COUNT_ERROR);

export const clientDebtListChangeOrder = createAction<
  { orderBy: string; orderDesc: boolean },
  ClientDebtListTypes.CLIENT_DEBT_LIST_CHANGE_ORDER
>(ClientDebtListTypes.CLIENT_DEBT_LIST_CHANGE_ORDER);

export const clientDebtListChangePage = createAction<
  number,
  ClientDebtListTypes.CLIENT_DEBT_LIST_CHANGE_PAGE
>(ClientDebtListTypes.CLIENT_DEBT_LIST_CHANGE_PAGE);

export const clientDebtListChangeFilter = createAction<
  IFilterType,
  ClientDebtListTypes.CLIENT_DEBT_LIST_CHANGE_FILTER
>(ClientDebtListTypes.CLIENT_DEBT_LIST_CHANGE_FILTER);

export const clientDebtListReset = createAction<
  void,
  ClientDebtListTypes.CLIENT_DEBT_LIST_RESET
>(ClientDebtListTypes.CLIENT_DEBT_LIST_RESET);

export const clientDebtListLoadAction = createAction<
  { newState: StoreState; newCountState: StoreStateDontLoad },
  ClientDebtListTypes.CLIENT_DEBT_LIST_LOAD
>(ClientDebtListTypes.CLIENT_DEBT_LIST_LOAD);
