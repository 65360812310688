import { createAction } from "@reduxjs/toolkit";
import { IDashboardLink } from "../../models/dashboard";
import { DashboardLinkTypes } from "./types";

export const dashboardLinkFetchRequest = createAction<
  void,
  DashboardLinkTypes.DASHBOARD_LINK_FETCH_REQUEST
>(DashboardLinkTypes.DASHBOARD_LINK_FETCH_REQUEST);

export const dashboardLinkFetchSuccess = createAction<
  IDashboardLink[],
  DashboardLinkTypes.DASHBOARD_LINK_FETCH_SUCCESS
>(DashboardLinkTypes.DASHBOARD_LINK_FETCH_SUCCESS);

export const dashboardLinkFetchError = createAction<
  void,
  DashboardLinkTypes.DASHBOARD_LINK_FETCH_ERROR
>(DashboardLinkTypes.DASHBOARD_LINK_FETCH_ERROR);
