import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { SIZES } from "../../styles/sizes";

export const HeaderContainer = styled.header`
  height: var(--header-height);
  width: 100%;

  display: flex;
  align-items: center;

  background-color: var(--header-bg);

  svg {
    transition: all 0.15s ease-in-out;
    color: var(--header-color) !important;
  }

  a,
  button {
    text-decoration: none;
    background-color: var(--header-bg);
  }

  button {
    outline: none;
    border: none;
    cursor: pointer;
    height: auto;
    padding: 0.5rem 1rem;
    border-radius: 15px;
  }

  button: hover {
    color: var(--header-color-hover) !important;
    background-color: var(--header-bg-hover) !important;

    svg {
      color: var(--header-color-hover) !important;
    }
  }

  button.active {
    color: var(--header-color-active) !important;
    background-color: var(--header-bg-active) !important;

    svg {
      color: var(--header-color-active) !important;
    }
  }
`;

export const HeaderIconButton = styled.button`
  margin-left: 0.5rem;
`;

export const HeaderLogo = styled.img`
  margin-left: 1rem;
  height: 60px;
`;

export const HeaderUserSection = styled.div`
  position: absolute;
  right: 0.5rem;
  display: flex;
  align-items: center;
`;

export const HeaderSearchLink = styled(NavLink)`
  padding: 0.5rem 1rem;
  border-radius: 15px;

  &: hover {
    color: var(--header-color-hover) !important;
    background-color: var(--header-bg-hover) !important;

    svg {
      color: var(--header-color-hover) !important;
    }
  }

  &.active {
    color: var(--header-color-active) !important;
    background-color: var(--header-bg-active) !important;

    svg {
      color: var(--header-color-active) !important;
    }
  }
`;

export const HeaderUserName = styled.div`
  margin-left: 0.5rem;
  color: var(--header-color);
  font-weight: bold;
  display: none;

  @media screen and (min-width: ${SIZES.tablet}) {
    display: block;
  }
`;

export const HeaderUserButton = styled.button`
  margin-left: 0.5rem;
`;
