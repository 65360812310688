import { createReducer } from "@reduxjs/toolkit";
import { IAssistancePersonListState } from "./types";
import * as actions from "./actions";
import { StoreState, StoreStateDontLoad } from "../storeState";
import { STORAGE_RESET } from "../storeReset";

const initialState = {
  state: StoreState.None,
  data: [],
  countState: StoreStateDontLoad.DontLoad,
  count: 0,
  page: 0,
  filter: { search: undefined, isOpen: false },
} as IAssistancePersonListState;

export const assistancePersonListReducer = createReducer(
  initialState,
  (builder) =>
    builder
      .addCase(actions.assistancePersonListFetchRequest, (state, action) => ({
        ...state,
        state: StoreState.Loading,
        countState: action.payload,
      }))
      .addCase(actions.assistancePersonListFetchSuccess, (state, action) => ({
        ...state,
        state: StoreState.Loaded,
        lastLoaded: new Date(),
        data: action.payload.data,
        countState: action.payload.newCountState,
        count: action.payload.newCount,
      }))
      .addCase(actions.assistancePersonListFetchError, (state, action) => ({
        ...state,
        state: action.payload ? StoreState.Cancel : StoreState.Error,
      }))
      .addCase(actions.assistancePersonListCountRequest, (state) => ({
        ...state,
        countState: StoreStateDontLoad.Loading,
      }))
      .addCase(actions.assistancePersonListCountSuccess, (state, action) => ({
        ...state,
        countState: StoreStateDontLoad.Loaded,
        count: action.payload,
      }))
      .addCase(actions.assistancePersonListCountError, (state, action) => ({
        ...state,
        countState: action.payload
          ? StoreStateDontLoad.Cancel
          : StoreStateDontLoad.Error,
      }))
      .addCase(actions.assistancePersonListChangeOrder, (state, action) => ({
        ...state,
        state: StoreState.None,
        orderBy: action.payload.orderBy,
        orderDesc: action.payload.orderDesc,
        page: 0,
      }))
      .addCase(actions.assistancePersonListChangePage, (state, action) => ({
        ...state,
        state: StoreState.None,
        page: action.payload,
      }))
      .addCase(actions.assistancePersonListChangeFilter, (state, action) => ({
        ...state,
        state: StoreState.None,
        countState: StoreStateDontLoad.DontLoad,
        filter: action.payload,
        page: 0,
      }))
      .addCase(actions.assistancePersonListReset, (state) => ({
        ...initialState,
        filter: state.filter,
      }))
      .addCase(actions.assistancePersonListLoadAction, (state, action) => ({
        ...state,
        state: action.payload.newState,
        countState: action.payload.newCountState,
      }))
      .addCase(STORAGE_RESET, () => initialState)
);
