import { createAction } from "@reduxjs/toolkit";
import { IDemandHistory } from "../../models/demand";
import { DemandHistoryTypes } from "./types";

export const demandHistoryFetchRequest = createAction<
  void,
  DemandHistoryTypes.DEMAND_HISTORY_FETCH_REQUEST
>(DemandHistoryTypes.DEMAND_HISTORY_FETCH_REQUEST);

export const demandHistoryFetchSuccess = createAction<
  IDemandHistory,
  DemandHistoryTypes.DEMAND_HISTORY_FETCH_SUCCESS
>(DemandHistoryTypes.DEMAND_HISTORY_FETCH_SUCCESS);

export const demandHistoryFetchError = createAction<
  void,
  DemandHistoryTypes.DEMAND_HISTORY_FETCH_ERROR
>(DemandHistoryTypes.DEMAND_HISTORY_FETCH_ERROR);
