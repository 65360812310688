import * as ReselectModule from "reselect";
import { IApplicationState } from "..";
import { IWorkflowTemplate } from "../../models/workflowTemplate";
import { StoreState } from "../storeState";

export const selectWorkflowTemplateState: ReselectModule.Selector<
  IApplicationState,
  StoreState
> = (state) => state.workflowTemplate.state;

export const selectWorkflowTemplate: ReselectModule.Selector<
  IApplicationState,
  IWorkflowTemplate | null
> = (state) => state.workflowTemplate.data;
