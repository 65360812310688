import { CancelToken } from "axios";
import { FC, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  ITemplateDocumentItem,
  TemplateDocumentType,
} from "../../models/template";
import { IApplicationState } from "../../store";
import { IFilterType } from "../../store/filterType";
import FilterPanel from "../common/filter/FilterPanel";
import Grid from "../common/grid/Grid";
import { GridIconFromTemplate, GridIconRemove } from "../common/grid/GridIcons";
import { TableCol } from "../common/grid/TableCol";
import { RightType } from "../../models/auth";
import { selectIdentityRights } from "../../store/identity";
import { hasRight } from "../../utils/rights";
import { useParams } from "react-router";
import { DivTextRight } from "../../styles/align";
import { promiseToastDelete, promiseToastSave } from "../../utils/toasts";
import { IDefaultDocumentDocumentItem } from "../../models/defaultDocument";
import defaultDocumentApi from "../../api/defaultDocument";
import { ISelectedFile } from "../selectTemplate/SelectTemplateTree";
import {
  changeDefaultDocumentDocumentListFilter,
  changeDefaultDocumentDocumentListOrder,
  changeDefaultDocumentDocumentListPage,
  defaultDocumentDocumentListLoad,
  getDefaultDocumentDocumentList,
  getDefaultDocumentDocumentListCount,
  IDefaultDocumentDocumentListState,
  selectDefaultDocumentDocumentList,
} from "../../store/defaultDocumentDocumentList";
import SelectTemplateModal from "../selectTemplate/SelectTemplateModal";

interface IProps {
  prov: IDefaultDocumentDocumentListState;
  identityRights?: RightType[];
  getDefaultDocumentDocumentList(
    folderId: string,
    cancelToken: CancelToken
  ): void;
  getDefaultDocumentDocumentListCount(
    folderId: string,
    cancelToken: CancelToken
  ): void;
  changeDefaultDocumentDocumentListOrder(
    orderBy: string,
    orderDesc: boolean
  ): void;
  changeDefaultDocumentDocumentListPage(page: number): void;
  changeDefaultDocumentDocumentListFilter(filter: IFilterType): void;
  defaultDocumentDocumentListLoad(reload: boolean): void;
}

const ModuleDefaultDocumentDocument: FC<IProps> = ({
  prov,
  identityRights,
  getDefaultDocumentDocumentList,
  getDefaultDocumentDocumentListCount,
  changeDefaultDocumentDocumentListOrder,
  changeDefaultDocumentDocumentListPage,
  changeDefaultDocumentDocumentListFilter,
  defaultDocumentDocumentListLoad,
}) => {
  const { t } = useTranslation();
  const [templateIsOpen, setTemplateIsOpen] = useState(false);
  const { folderId } = useParams();

  useEffect(() => {
    defaultDocumentDocumentListLoad(false);
  }, [defaultDocumentDocumentListLoad]);

  const handleFromTemplate = () => {
    setTemplateIsOpen(true);
  };

  const handleCloseTemplate = () => {
    setTemplateIsOpen(false);
  };

  const handleConfirmTemplate = async (file: ISelectedFile) => {
    await promiseToastSave(async () => {
      await defaultDocumentApi.addDocumentFromTemplate(folderId, file.id);
      defaultDocumentDocumentListLoad(true);
    });
  };

  const handleRemove = async (item: IDefaultDocumentDocumentItem) => {
    await promiseToastDelete(async () => {
      await defaultDocumentApi.removeDocument(folderId, item.id);
      defaultDocumentDocumentListLoad(true);
    });
  };

  const handleGetData = async (cancelToken: CancelToken) => {
    return getDefaultDocumentDocumentList(folderId!, cancelToken);
  };

  const handleGetCount = async (cancelToken: CancelToken) => {
    return getDefaultDocumentDocumentListCount(folderId!, cancelToken);
  };

  const handleRenderData = (
    item: IDefaultDocumentDocumentItem
  ): ReactElement => {
    return (
      <>
        <TableCol>{item.name}</TableCol>
        <TableCol>
          {/* //todo: rights? */}
          {hasRight(identityRights, [RightType.WriteAllTemplates]) && (
            <GridIconRemove
              onClick={() => {
                handleRemove(item);
              }}
            />
          )}
        </TableCol>
      </>
    );
  };

  return (
    <>
      <SelectTemplateModal
        type={TemplateDocumentType.Client}
        isOpen={templateIsOpen}
        close={handleCloseTemplate}
        confirm={handleConfirmTemplate}
      />

      <FilterPanel
        title={t("module.document.pageTitle")}
        name="moduleDocumentDocument"
        filter={prov.filter!}
        changeFilter={changeDefaultDocumentDocumentListFilter}
      />
      <Grid<ITemplateDocumentItem>
        headers={[
          { captionStr: "document.name" },
          {
            captionEl: hasRight(
              identityRights,
              [RightType.WriteAllTemplates, RightType.ReadAllTemplates],
              true
            ) ? (
              <DivTextRight>
                <GridIconFromTemplate onClick={handleFromTemplate} />
              </DivTextRight>
            ) : undefined,
          },
        ]}
        renderData={handleRenderData}
        getData={handleGetData}
        getCount={handleGetCount}
        changeOrder={changeDefaultDocumentDocumentListOrder}
        changePage={changeDefaultDocumentDocumentListPage}
        prov={prov}
      />
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    prov: selectDefaultDocumentDocumentList(state),
    identityRights: selectIdentityRights(state),
  };
};

const mapDispachToProps = {
  getDefaultDocumentDocumentList,
  getDefaultDocumentDocumentListCount,
  changeDefaultDocumentDocumentListOrder,
  changeDefaultDocumentDocumentListPage,
  changeDefaultDocumentDocumentListFilter,
  defaultDocumentDocumentListLoad,
};

export default connect(
  mapStateToProps,
  mapDispachToProps
)(ModuleDefaultDocumentDocument);
