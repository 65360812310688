import { FC } from "react";
import { useTranslation } from "react-i18next";
import { AdminRoleCategoryLabel, AdminRoleCheck } from "./AdminRoleStyles";

interface IProps {
  name: string;
}

const AdminRoleCategory: FC<IProps> = ({ name }) => {
  const { t } = useTranslation();

  return (
    <div>
      <AdminRoleCheck id={name} name={name} type="checkbox" />
      <AdminRoleCategoryLabel htmlFor={name}>
        {t("admin.role." + name)}
      </AdminRoleCategoryLabel>
    </div>
  );
};

export default AdminRoleCategory;
