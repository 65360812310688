import styled from "styled-components";

export const AdminLogDescriptionCol = styled.div`
  width: 100%;
`;

export const AdminLogDescriptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const ExpandControlWrapper = styled.div`
  align-self: start;
`;
