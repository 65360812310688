import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PortWidget } from "@projectstorm/react-diagrams";
import styled from "styled-components";
import { WorkflowDefinitionItemTopMode } from "../../../models/workflow";
import { Button } from "../../../styles/button";
import { DIAGRAM_HEIGHT, DIAGRAM_WIDTH } from "./DiagramConsts";

export const DiagramContainer = styled.div<{ optLinkTop: boolean }>`
  width: 100%;
  background-color: #f4f5f5;
  position: relative;
  border-radius: 10px;
  margin-top: 1rem;

  .diagram-canvas {
    height: 100%;
    min-height: 100%;
    width: 100%;
  }

  .diagram-canvas > svg {
    ${({ optLinkTop }) => (optLinkTop ? "z-index: 1;" : "")}
  }
`;

export const DiagramMenuContainer = styled.ul`
  position: absolute;
  list-style-type: none;
  border: 1px solid var(--border-focus);
  background-color: #ffffff;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.2), 0 4px 6px -4px rgb(0 0 0 / 0.2);
  z-index: 10;
`;

export const DiagramMenuItem = styled.li`
  padding: 0.4rem;
  cursor: pointer;

  &:hover {
    background-color: var(--suggestion-highlighted-bg);
  }
`;

export const DiagramButtons = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 5;
`;

export const DiagramButton = styled(Button)`
  font-size: 0.75rem;
  padding: 5px 5px;
`;

//todo: colors!
export const DiagramStartEndWrapper = styled.div<{
  highlight: boolean;
  canClick: boolean;
  notFound: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  width: ${DIAGRAM_WIDTH}px;
  height: ${DIAGRAM_HEIGHT}px;
  color: ${({ notFound }) => (notFound ? "#B3B3B3" : "#000000")};
  background-color: ${({ notFound }) => (notFound ? "#FFFFFF" : "#E9EDF2")};
  border: ${({ highlight }) => (highlight ? "4" : "2")}px solid
    ${({ highlight, notFound }) =>
      highlight ? "#00BFFD" : notFound ? "#ECEFF4" : "#3E557E"};
  border-radius: 5px;
  padding: 10px;
  cursor: ${({ canClick }) => (canClick ? "pointer" : "not-allowed")};
  box-shadow: 1px 1px 5px #b6c4d3;
  font-size: 1.2rem;
`;

//todo: colors
export const DiagramTemplateWrapper = styled.div<{
  highlight: boolean;
  notFound: boolean;
  canClick: boolean;
  historyMode: boolean;
}>`
  position: relative;

  ${({ historyMode }) =>
    historyMode
      ? `
        font-size: 1rem;
      `
      : `
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        text-align: center;
        font-size: 1.2rem;
      `}

  width: ${DIAGRAM_WIDTH}px;
  height: ${DIAGRAM_HEIGHT}px;
  color: ${({ notFound }) => (notFound ? "#B3B3B3" : "#FFFFFF")};
  background-color: ${({ notFound }) => (notFound ? "#FFFFFF" : "#3E557E")};
  border: ${({ highlight }) => (highlight ? "4" : "2")}px solid
    ${({ highlight, notFound }) =>
      highlight ? "#00BFFD" : notFound ? "#ECEFF4" : "#3E557E"};
  border-radius: 5px;
  padding: 10px;
  cursor: ${({ canClick }) => (canClick ? "pointer" : "not-allowed")};
  box-shadow: 1px 1px 5px #3e557e;
`;

//todo: color
export const DiagramMultipleWrapper = styled.div<{
  highlight: boolean;
  notFound: boolean;
  canClick: boolean;
  maxWidth: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: ${DIAGRAM_WIDTH}px;
  height: ${DIAGRAM_HEIGHT}px;
  color: ${({ notFound }) => (notFound ? "#B3B3B3" : "#000000")};
  background-color: #ffffff;
  border: ${({ highlight }) => (highlight ? "4" : "2")}px solid
    ${({ highlight, notFound }) =>
      highlight ? "#00BFFD" : notFound ? "#ECEFF4" : "#3E557E"};
  border-radius: 5px;
  padding: 10px;
  padding-bottom: 0;
  cursor: ${({ canClick }) => (canClick ? "pointer" : "not-allowed")};
  box-shadow: 1px 1px 5px #b6c4d3;
  font-size: 1.2rem;

  ${({ maxWidth }) =>
    maxWidth
      ? `
    width: ${DIAGRAM_WIDTH}px;
    max-width: ${DIAGRAM_WIDTH}px;
  `
      : ""};
`;

//todo: colors
export const DiagramWorkflowWrapper = styled.div<{
  highlight: boolean;
  notFound: boolean;
  canClick: boolean;
  historyMode: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  width: ${DIAGRAM_WIDTH}px;
  height: ${DIAGRAM_HEIGHT}px;
  color: ${({ notFound, historyMode }) =>
    notFound ? "#B3B3B3" : historyMode ? "#000000" : "#FFFFFF"};
  background-color: ${({ notFound, historyMode }) =>
    notFound ? "#FFFFFF" : historyMode ? "#E9EDF2" : "#6C8EB2"};
  border: ${({ highlight }) => (highlight ? "4" : "2")}px solid
    ${({ highlight, notFound, historyMode }) =>
      highlight
        ? "#00BFFD"
        : notFound
        ? "#ECEFF4"
        : historyMode
        ? "#3E557E"
        : "#6C8EB2"};
  border-radius: 5px;
  padding: 10px;
  cursor: ${({ canClick }) => (canClick ? "pointer" : "not-allowed")};
  box-shadow: 1px 1px 5px #3e557e;
  font-size: 1.2rem;
`;

//todo: colors
export const DiagramWorkflowOutWrapper = styled.div<{
  highlight: boolean;
  notFound: boolean;
  canClick: boolean;
  historyMode: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  width: ${DIAGRAM_WIDTH}px;
  height: ${DIAGRAM_HEIGHT}px;
  color: ${({ notFound, historyMode }) =>
    notFound ? "#B3B3B3" : historyMode ? "#000000" : "#FFFFFF"};
  background-color: ${({ notFound, historyMode }) =>
    notFound ? "#FFFFFF" : historyMode ? "#E9EDF2" : "#FF4343"};
  border: ${({ highlight }) => (highlight ? "4" : "2")}px solid
    ${({ highlight, notFound, historyMode }) =>
      highlight
        ? "#00BFFD"
        : notFound
        ? "#ECEFF4"
        : historyMode
        ? "#3E557E"
        : "#FF4343"};
  border-radius: 5px;
  padding: 10px;
  cursor: ${({ canClick }) => (canClick ? "pointer" : "not-allowed")};
  box-shadow: 1px 1px 5px #3e557e;
  font-size: 1.2rem;
`;

export const DiagramHistoryColumnsDiv = styled.div`
  display: grid;
  grid-template-columns: 1.25rem auto;
  font-size: 0.8rem;
`;

export const DiagramUser = styled.div`
  font-size: 0.8rem;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const DiagramTitle = styled.div`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const DiagramStateIcon = styled(FontAwesomeIcon)`
  margin-right: 0.5rem;
`;

export const DiagramLinkModeContainer = styled.div`
  padding-left: 1rem;
  padding-top: 1rem;
`;

export const DiagramLinkModeButton = styled(Button)`
  margin-left: 0.5rem;
  font-size: 0.75rem;
  padding: 5px 5px;
`;

export const PortWidgetWrapperBottom = styled.div`
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0px;
  bottom: 0px;
`;

export const PortWidgetWrapperBottomMultiple = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: flex-end;
  gap: 10px;
`;

export const PortWidgetWrapperBottomMultiple2 = styled.div<{ count: number }>`
  display: grid;
  grid-template-columns: repeat(${({ count }) => count}, 1fr);
  grid-column-gap: 10px;
`;

export const PortWidgetWrapperTop = styled.div`
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0px;
  top: 0px;
`;

export const StyledPortWidget = styled(PortWidget)`
  width: 100%;
  height: 100%;
`;

export const StyledPortWidgetMultipleContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
`;

//todo: color
export const StyledPortWidgetMultiple = styled.div<{
  notFound: boolean;
  disabled: boolean;
}>`
  color: ${({ notFound }) => (notFound ? "#3E557E" : "#FFFFFF")};
  background-color: ${({ notFound }) => (notFound ? "#FFFFFF" : "#3E557E")};
  border: 1px solid ${({ disabled }) => (disabled ? "#B3B3B3" : "#3e557e")};
  border-radius: 4px;
  padding: 5px;
  margin-bottom: 3px;
  font-size: 0.8rem;
`;

//todo: colors
export const DiagramTopPortMode = styled.div<{
  mode: WorkflowDefinitionItemTopMode;
}>`
  position: absolute;
  top: -1rem;
  left: 50%;
  transform: translate(-50%, 0);
  width: 2rem;
  height: 1rem;
  border: 2px solid #3e557e;
  border-radius: 100px 100px 0 0;
  background-color: ${({ mode }) =>
    mode === WorkflowDefinitionItemTopMode.AllPaths ? "#3e557e" : "#FFFFFF"};
`;
