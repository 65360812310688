import { AppThunkAction, AppThunkActionAsync } from "..";
import * as actions from "./actions";
import listingApi from "../../api/listing";
import { selectListingList } from ".";
import { IFilterType } from "../filterType";
import { CancelToken } from "axios";
import {
  listEffectCountFilter,
  listEffectGetFilter,
  listEffectLoad,
  listEffectReload,
  listEffectsCountCheck,
  listEffectsGetCheck,
  listEffectsGetCount,
  listEffectsGetNew,
  listEffectsGetParams,
} from "../listEffect";
import { ListingType } from "../../models/listing";

export function getListingList(
  type: ListingType,
  cancelToken: CancelToken
): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectListingList(getState(), type);
    if (listEffectsGetCheck(state.state)) {
      return;
    }

    let newState = listEffectsGetNew(state);
    dispatch(
      actions.listingListFetchRequest({
        type,
        newCountState: newState.newCountState,
      })
    );
    try {
      const params = listEffectsGetParams(state, newState.take);
      listEffectGetFilter(params, state.filter);

      const listings = await listingApi.getList(type, { params, cancelToken });
      cancelToken.throwIfRequested();
      newState = listEffectsGetCount(newState, listings);

      dispatch(
        actions.listingListFetchSuccess({
          type,
          data: listings.data,
          newCountState: newState.newCountState,
          newCount: newState.newCount,
        })
      );
    } catch {
      dispatch(
        actions.listingListFetchError({
          type,
          canceled: cancelToken.reason !== undefined,
        })
      );
    }
  };
}

export function getListingListCount(
  type: ListingType,
  cancelToken: CancelToken
): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectListingList(getState(), type);
    if (listEffectsCountCheck(state.countState)) {
      return;
    }

    dispatch(actions.listingListCountRequest(type));
    try {
      const params = listEffectCountFilter(state.filter);
      const count = await listingApi.getCount(type, { params, cancelToken });
      cancelToken.throwIfRequested();

      dispatch(actions.listingListCountSuccess({ type, count: count.data }));
    } catch {
      dispatch(
        actions.listingListCountError({
          type,
          canceled: cancelToken.reason !== undefined,
        })
      );
    }
  };
}

export function changeListingListOrder(
  type: ListingType,
  orderBy: string,
  orderDesc: boolean
): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.listingListChangeOrder({ type, orderBy, orderDesc }));
  };
}

export function changeListingListPage(
  type: ListingType,
  page: number
): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.listingListChangePage({ type, page }));
  };
}

export function changeListingListFilter(
  type: ListingType,
  filter: IFilterType
): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.listingListChangeFilter({ type, filter }));
  };
}

export function listingListLoad(
  type: ListingType,
  reload: boolean
): AppThunkAction {
  return async (dispatch, getState) => {
    const state = selectListingList(getState(), type);
    reload = listEffectReload(reload, state);
    if (reload) {
      dispatch(actions.listingListReset(type));
    } else {
      const newState = listEffectLoad(state);
      dispatch(
        actions.listingListLoadAction({
          type,
          newState: newState.newState,
          newCountState: newState.newCountState,
        })
      );
    }
  };
}
