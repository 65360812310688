import { createReducer } from "@reduxjs/toolkit";
import { ISearchState } from "./types";
import * as actions from "./actions";
import { StoreState } from "../storeState";
import { STORAGE_RESET } from "../storeReset";

const initialState = {
  filter: {
    text: "",
    open: false,
    selClient: true,
    selClientContactPerson: true,
    selUser: true,
    selGroup: true,
    selDocument: true,
    selDocumentContent: true,
    selAssistance: true,
    selAssistanceContactPerson: true,
    selListing: true,
  },
  state: StoreState.None,
  data: [],
} as ISearchState;

export const searchReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(actions.searchFetchRequest, (state, action) => ({
      ...state,
      filter: action.payload,
      state: StoreState.Loading,
      data: [],
    }))
    .addCase(actions.searchFetchPartialSuccess, (state, action) => ({
      ...state,
      state: StoreState.Loading,
      data: action.payload,
    }))
    .addCase(actions.searchFetchSuccess, (state) => ({
      ...state,
      state: StoreState.Loaded,
    }))
    .addCase(actions.searchFetchError, (state, action) => ({
      ...state,
      state: action.payload ? StoreState.Cancel : StoreState.Error,
    }))
    .addCase(STORAGE_RESET, () => initialState)
);
