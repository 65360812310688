import { createAction } from "@reduxjs/toolkit";
import { IDemandItem } from "../../models/demand";
import { StoreState, StoreStateDontLoad } from "../storeState";
import { DemandListTypes, IDemandFilterType } from "./types";

export const demandListFetchRequest = createAction<
  StoreStateDontLoad,
  DemandListTypes.DEMAND_LIST_FETCH_REQUEST
>(DemandListTypes.DEMAND_LIST_FETCH_REQUEST);

export const demandListFetchSuccess = createAction<
  { data: IDemandItem[]; newCountState: StoreStateDontLoad; newCount: number },
  DemandListTypes.DEMAND_LIST_FETCH_SUCCESS
>(DemandListTypes.DEMAND_LIST_FETCH_SUCCESS);

export const demandListFetchError = createAction<
  boolean,
  DemandListTypes.DEMAND_LIST_FETCH_ERROR
>(DemandListTypes.DEMAND_LIST_FETCH_ERROR);

export const demandListCountRequest = createAction<
  void,
  DemandListTypes.DEMAND_LIST_COUNT_REQUEST
>(DemandListTypes.DEMAND_LIST_COUNT_REQUEST);

export const demandListCountSuccess = createAction<
  number,
  DemandListTypes.DEMAND_LIST_COUNT_SUCCESS
>(DemandListTypes.DEMAND_LIST_COUNT_SUCCESS);

export const demandListCountError = createAction<
  boolean,
  DemandListTypes.DEMAND_LIST_COUNT_ERROR
>(DemandListTypes.DEMAND_LIST_COUNT_ERROR);

export const demandListChangeOrder = createAction<
  { orderBy: string; orderDesc: boolean },
  DemandListTypes.DEMAND_LIST_CHANGE_ORDER
>(DemandListTypes.DEMAND_LIST_CHANGE_ORDER);

export const demandListChangePage = createAction<
  number,
  DemandListTypes.DEMAND_LIST_CHANGE_PAGE
>(DemandListTypes.DEMAND_LIST_CHANGE_PAGE);

export const demandListChangeFilter = createAction<
  IDemandFilterType,
  DemandListTypes.DEMAND_LIST_CHANGE_FILTER
>(DemandListTypes.DEMAND_LIST_CHANGE_FILTER);

export const demandListReset = createAction<
  void,
  DemandListTypes.DEMAND_LIST_RESET
>(DemandListTypes.DEMAND_LIST_RESET);

export const demandListLoadAction = createAction<
  { newState: StoreState; newCountState: StoreStateDontLoad },
  DemandListTypes.DEMAND_LIST_LOAD
>(DemandListTypes.DEMAND_LIST_LOAD);
