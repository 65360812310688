import { faCog, faKey, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { IApplicationState } from "../../store";
import { selectIdentityName } from "../../store/identity";
import { COLORS } from "../../styles/colors";
import { SetState } from "../../utils/types";
import {
  HeaderMenuLink,
  HeaderMenuLinks,
  HeaderMenuUserName,
  HeaderMenuVisibility,
} from "./HeaderMenuStyles";

const links = [
  { id: "settings", href: "/settings", icon: faCog },
  { id: "changePassword", href: "/change-password", icon: faKey },
  { id: "logout", href: "/login", icon: faSignOutAlt },
];

interface IProps {
  open: boolean;
  setOpen: SetState<boolean>;
  identityName?: string;
}

const HeaderUserMenu: FC<IProps> = ({ open, setOpen, identityName }) => {
  const { t } = useTranslation();

  return (
    <HeaderMenuLinks
      visibility={
        open ? HeaderMenuVisibility.ShowAlways : HeaderMenuVisibility.Hide
      }
      left={false}
    >
      <HeaderMenuUserName key="userName">{identityName}</HeaderMenuUserName>

      {links.map((l) => (
        <li key={l.id} onClick={() => setOpen(false)}>
          <HeaderMenuLink to={l.href}>
            {t(`header.${l.id}`)}
            <FontAwesomeIcon icon={l.icon} color={COLORS.menuColor} />
          </HeaderMenuLink>
        </li>
      ))}
    </HeaderMenuLinks>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    identityName: selectIdentityName(state),
  };
};

export default connect(mapStateToProps)(HeaderUserMenu);
