import { CancelToken } from "axios";
import { FC, ReactElement, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { IClientDocumentItem, SignedEnum } from "../../models/client";
import { IApplicationState } from "../../store";
import { IFilterType } from "../../store/filterType";
import {
  changeClientDocumentListFilter,
  changeClientDocumentListOrder,
  changeClientDocumentListPage,
  getClientDocumentList,
  getClientDocumentListCount,
  IClientDocumentListState,
  selectClientDocumentList,
  clientDocumentListLoad,
} from "../../store/clientDocumentList";
import FilterPanel from "../common/filter/FilterPanel";
import Grid from "../common/grid/Grid";
import {
  GridIconAdd,
  GridIconBin,
  GridIconCountdown,
  GridIconDownload,
  GridIconEdit,
  GridIconFromTemplate,
  GridIconSave,
  GridIconSignDown,
  GridIconSpace,
  GridIconUpload,
} from "../common/grid/GridIcons";
import { TableCol } from "../common/grid/TableCol";
import { RightType } from "../../models/auth";
import { hasClientWriteRight, hasRight } from "../../utils/rights";
import { selectIdentityRights } from "../../store/identity";
import { selectClientId, selectClientPhoneNumber } from "../../store/client";
import { DivTextRight } from "../../styles/align";
import SelectTemplateModal from "../selectTemplate/SelectTemplateModal";
import { ISelectedFile } from "../selectTemplate/SelectTemplateTree";
import clientApi from "../../api/client";
import { TemplateDocumentType } from "../../models/template";
import bucketApi from "../../api/bucket";
import ClientDocumentAddModal from "./ClientDocumentAddModal";
import { startDocumentEditOnline } from "../document/DocumentEditOnlineStart";
import { errorToast, promiseToast, promiseToastSave } from "../../utils/toasts";
import SuggestionFormik from "../common/suggestion/SuggestionFormik";
import { handleLoadUserSuggestions } from "../../utils/suggestions";
import {
  CalendarModalTimesContainer,
  CalendarModalTimes,
  CalendarModalTimesLabel,
} from "../calendar/CalendarStyles";
import DatePicker from "../common/form/DatePicker";
import { format } from "date-fns";
import { DATE_TIME_FORMAT } from "../../utils/consts";
import { useParams } from "react-router";

import { Pdf } from "signify-pdf";
import "signify-pdf/dist/index.css";
import PromiseModalSignDownType from "../common/modal/PromiseModalSignDownType";
import { SIGN_DOWN_TYPE } from "../../models/document";
import digitalSignatureApi from "../../api/digitalSignature";
import PromiseModalWithText from "../common/PromiseModalWithText";
import documentApi from "../../api/document";
import { PDFDocument } from "pdf-lib";
import {
  ModalOkFunction,
  ModalYesNoFunction,
} from "../common/modal/ModalFunctions";
import { ClientDocumentDocumentEditModal } from "./ClientDocumentDocumentEditModal";
import { getDaysDifference } from "../../utils/date";

interface IProps {
  prov: IClientDocumentListState;
  clientId?: number;
  clientPhoneNumber?: string | null;
  identityRights?: RightType[];
  isArchiveFolder: boolean;
  getClientDocumentList(
    clientId: number,
    folderId: string,
    cancelToken: CancelToken
  ): void;
  getClientDocumentListCount(
    clientId: number,
    folderId: string,
    cancelToken: CancelToken
  ): void;
  changeClientDocumentListOrder(orderBy: string, orderDesc: boolean): void;
  changeClientDocumentListPage(page: number): void;
  changeClientDocumentListFilter(filter: IFilterType): void;
  clientDocumentListLoad(reload: boolean): void;
}

const ClientDocumentDocument: FC<IProps> = ({
  prov,
  clientId,
  identityRights,
  isArchiveFolder,
  getClientDocumentList,
  getClientDocumentListCount,
  changeClientDocumentListOrder,
  changeClientDocumentListPage,
  changeClientDocumentListFilter,
  clientDocumentListLoad,
  clientPhoneNumber,
}) => {
  const { t } = useTranslation();
  const [addFile, setAddFile] = useState<File | null>(null);
  const [addIsOpen, setAddIsOpen] = useState(false);
  const [templateIsOpen, setTemplateIsOpen] = useState(false);
  const [uploadDocumentId, setUploadDocumentId] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);
  const { folderId } = useParams();
  const [isModalForSignDownOpen, setIsModalForSignDownOpen] = useState(false);
  const [modalEditDocument, setModalEditDocument] = useState(false);
  const [editDocument, setEditDocument] = useState<IClientDocumentItem | null>(
    null
  );
  const [pdfFile, setPdfFile] = useState<File | null>(null);
  const [codeForSignDown, setCodeForSignDown] = useState<null | string>(null);
  const [signDownModalData, setSignDownModalData] = useState<null | {
    resolve: (type: SIGN_DOWN_TYPE) => void;
    reject: () => void;
  }>(null);
  const [codeModalData, setCodeModalData] = useState<null | {
    resolve: (text: string) => unknown;
    reject: () => void;
    title: string;
  }>(null);

  useEffect(() => {
    clientDocumentListLoad(false);
  }, [clientDocumentListLoad]);

  const handleAdd = async () => {
    const res = await ModalOkFunction(
      t("common.warning"),
      t("pdfSignature.uploadDocument.uploadFileDisclaimer"),
      t("common.upload")
    );

    if (res) {
      setUploadDocumentId(0);
      if (inputRef.current) {
        inputRef.current.click();
      }
    }
  };

  const handleAddClose = () => {
    setAddIsOpen(false);

    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const handleFileChange = async (files: FileList | null) => {
    if (!files || files.length < 1) {
      return;
    }

    const file = files[0];

    if (uploadDocumentId === 0) {
      setAddFile(file);
      setAddIsOpen(true);
      return;
    }

    try {
      await doUpload(file);
    } finally {
      if (inputRef.current) {
        inputRef.current.value = "";
      }
    }
  };

  const handleDownload = async (item: IClientDocumentItem) => {
    try {
      await promiseToast(
        async () => {
          const url = await clientApi.getClientDocumentDownloadUrl(
            clientId!,
            item.id
          );

          const link = document.createElement("a");
          link.href = url.data;
          link.download = item.name;
          link.target = "_blank";
          link.click();
        },
        t("pending.download"),
        t("success.download"),
        t("errors.unknown")
      );
    } catch {
      //Nothing.
    }
  };

  const handleUpload = async (item: IClientDocumentItem) => {
    setUploadDocumentId(item.id);
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const doUpload = async (file: File) => {
    await promiseToastSave(async () => {
      const uploadUrl = await clientApi.getClientDocumentUploadUrl(
        clientId!,
        folderId,
        null,
        uploadDocumentId.toString()
      );

      await bucketApi.uploadDocument(uploadUrl.data, file);
      await clientApi.updateClientDocument(clientId!, uploadDocumentId);

      clientDocumentListLoad(true);
    });
  };

  const handleFromTemplate = () => {
    setTemplateIsOpen(true);
  };

  const handleCloseTemplate = () => {
    setTemplateIsOpen(false);
  };

  const handleConfirmTemplate = async (file: ISelectedFile) => {
    await promiseToastSave(async () => {
      await clientApi.addClientDocumentFromTemplate(
        clientId!,
        folderId,
        file.id
      );
      clientDocumentListLoad(true);
    });
  };

  const handleEdit = async (item: IClientDocumentItem) => {
    setEditDocument(item);
    setModalEditDocument(true);
  };

  const handleRowDoubleClick = async (item: IClientDocumentItem) => {
    if (isArchiveFolder) return;
    if (
      hasClientWriteRight(identityRights, [RightType.WriteClientDocuments]) &&
      item.state !== SignedEnum.signed
    ) {
      await startDocumentEditOnline(item.id, t);
    } else {
      errorToast(t("pdfSignature.alreadySigned"));
    }
  };

  const handleRestore = async (item: IClientDocumentItem) => {
    const res = await ModalYesNoFunction(
      t("module.document.restoreDocumentTitle"),
      t("module.document.restoreDocument", {
        name: item.name,
        folder: item.cachedFolder?.name ?? "",
      })
    );
    if (res) {
      await promiseToast(
        async () => {
          try {
            await clientApi.clientDocumentRestore(item.id);
            clientDocumentListLoad(true);
          } catch (e) {
            errorToast(t("errors.unknown"));
          }
        },
        t("pending.restore"),
        t("success.restored"),
        t("common.cancel")
      );
    }
  };

  const handleDownloadFile = async (documentId: number, clientId: number) => {
    const encodedBytes = await documentApi.getDocumentBytes({
      documentId,
      clientId,
    });

    // Decode the base64 string to a byte array
    const decodedBytes = atob(encodedBytes.data);
    const byteArray = new Uint8Array(decodedBytes.length);
    for (let i = 0; i < decodedBytes.length; i++) {
      byteArray[i] = decodedBytes.charCodeAt(i);
    }

    // Create a Blob from the byte array
    const blob = new Blob([byteArray], { type: "application/octet-stream" });

    // Create a File from the Blob
    const file = new File([blob], "downloadedFile", {
      type: "application/octet-stream",
    });

    return file;
  };

  const handleSignDownClick = async (item: IClientDocumentItem) => {
    if (!clientId) {
      throw new Error("missing client Id");
    }

    setUploadDocumentId(item.id);
    try {
      let type = SIGN_DOWN_TYPE.biometric;

      if (item.state === SignedEnum.pending) {
        type = SIGN_DOWN_TYPE.code;
      }

      const typePromise = new Promise(
        (resolve: (type: SIGN_DOWN_TYPE) => void, reject) => {
          setSignDownModalData({
            resolve,
            reject,
          });
        }
      );

      if (item.state !== SignedEnum.pending) {
        type = await typePromise;
      }

      setSignDownModalData(null);

      if (
        type === SIGN_DOWN_TYPE.code ||
        type === SIGN_DOWN_TYPE.codeNotInPerson
      ) {
        let signInPerson = true;

        if (type === SIGN_DOWN_TYPE.codeNotInPerson) {
          signInPerson = false;
        }

        if (item.state !== SignedEnum.pending) {
          await promiseToast(
            async () => {
              await digitalSignatureApi.sendVerificationSms({
                clientId,
                documentId: item.id,
                signInPerson,
              });

              await new Promise((resolve) => setTimeout(resolve, 1000));
            },
            t("document.smsState.pending"),
            t("document.smsState.succesfull"),
            t("document.smsState.error")
          );
        }

        if (type === SIGN_DOWN_TYPE.codeNotInPerson) return;

        const codePromise = new Promise(
          (resolve: (code: string) => void, reject) => {
            setCodeModalData({
              title: t("document.writeCode"),
              resolve,
              reject,
            });
          }
        );

        const userCode = await codePromise;
        setCodeModalData(null);

        const guidForSignDown = await digitalSignatureApi.checkCodeValidity(
          userCode,
          item.id
        );

        setCodeForSignDown(guidForSignDown.data.digitalSignature);
      }

      const file = await handleDownloadFile(item.id, clientId);

      const blob = new Blob([file], { type: "application/pdf" });
      const pdfFile = new File([blob], item.name, {
        type: "application/pdf",
      });

      setPdfFile(pdfFile);
      setIsModalForSignDownOpen(true);
    } catch (e) {
      setSignDownModalData(null);
      setCodeModalData(null);
    }
  };

  const handleDragStart = (
    e: React.DragEvent<HTMLTableRowElement>,
    item: IClientDocumentItem
  ) => {
    e.dataTransfer.setData(
      "item",
      JSON.stringify({ id: item.id, name: item.name, isFile: true })
    );
  };

  const handleBinDrop = async (item: {
    id: number;
    name: string;
    isFile: boolean;
  }) => {
    if (!item.isFile) return;
    const res = await ModalYesNoFunction(
      t("module.document.deleteDocumentTitle"),
      t("module.document.deleteDocument", { name: item.name })
    );
    if (res) {
      await promiseToast(
        async () => {
          try {
            await clientApi.clientDocumentArchive(item.id);
            clientDocumentListLoad(true);
          } catch (e) {
            errorToast(t("errors.unknown"));
          }
        },
        t("pending.delete"),
        t("success.deleteFile"),
        t("common.cancel")
      );
    }
  };

  const handleRenderData = (item: IClientDocumentItem): ReactElement => {
    const isDocumentSignable = item.name.split(".").includes("pdf");
    const isPublic = item.isClientVisible;

    return (
      <>
        <TableCol isPublic={isPublic}>{item.name}</TableCol>
        <TableCol isPublic={isPublic}>
          {format(item.createdAt, DATE_TIME_FORMAT)}
        </TableCol>
        <TableCol isPublic={isPublic}>
          {format(item.lastEditedAt, DATE_TIME_FORMAT)}
        </TableCol>
        <TableCol isPublic={isPublic}>{item.author.name}</TableCol>
        <TableCol isPublic={isPublic}>{item.lastEditor.name}</TableCol>
        <TableCol>
          {isArchiveFolder ? (
            <>
              <GridIconSave
                title={t("gridIcons.restore")}
                onClick={() => handleRestore(item)}
              />
              <GridIconSpace />
              <GridIconCountdown time={getDaysDifference(item.archivedAt)} />
            </>
          ) : (
            <>
              {hasClientWriteRight(identityRights, [
                RightType.WriteClientDocuments,
              ]) &&
                item.state !== SignedEnum.signed && (
                  <>
                    <GridIconEdit onClick={() => handleEdit(item)} />
                    <GridIconSpace />
                  </>
                )}
              <GridIconDownload onClick={() => handleDownload(item)} />
              {hasClientWriteRight(identityRights, [
                RightType.WriteClientDocuments,
              ]) &&
                item.state !== SignedEnum.signed && (
                  <>
                    <GridIconSpace />
                    <GridIconUpload onClick={() => handleUpload(item)} />
                  </>
                )}
              {isDocumentSignable && item.state !== SignedEnum.signed && (
                <>
                  <GridIconSpace />
                  <GridIconSignDown onClick={() => handleSignDownClick(item)} />
                </>
              )}
            </>
          )}
        </TableCol>
      </>
    );
  };

  const handleSaveSignedDocument = async (file: PDFDocument) => {
    try {
      await promiseToastSave(async () => {
        const uploadUrl = await clientApi.getClientDocumentUploadUrl(
          clientId!,
          folderId,
          null,
          uploadDocumentId.toString()
        );

        const blob = await file.save();
        const fileToSend = new File([blob], "signedDocument.pdf", {
          type: "application/pdf",
        });

        await bucketApi.uploadDocument(uploadUrl.data, fileToSend);
        await clientApi.updateClientDocument(clientId!, uploadDocumentId);

        if (!codeForSignDown) {
          await digitalSignatureApi.sendDocumentIsSignBiometrically({
            clientId: clientId!,
            docuemntId: uploadDocumentId,
          });
        }
      });
    } catch (e) {
      console.log(e);
    } finally {
      setIsModalForSignDownOpen(false);
      setPdfFile(null);
      clientDocumentListLoad(true);
      setCodeForSignDown(null);
    }
  };

  const handleGetData = (cancelToken: CancelToken) =>
    getClientDocumentList(clientId!, folderId!, cancelToken);

  const handleGetCount = (cancelToken: CancelToken) =>
    getClientDocumentListCount(clientId!, folderId!, cancelToken);

  const handleCloseModalForSignDown = () => {
    setIsModalForSignDownOpen(false);
    setPdfFile(null);
  };

  const getOptionsForSignDownTypeModal = (): {
    name: string;
    value: SIGN_DOWN_TYPE;
  }[] => {
    const array: {
      name: string;
      value: SIGN_DOWN_TYPE;
    }[] = [];

    if (clientPhoneNumber) {
      // array.push({
      //   value: SIGN_DOWN_TYPE.code,
      //   name: t("document.signDownTypeCod"),
      // });

      array.push({
        value: SIGN_DOWN_TYPE.codeNotInPerson,
        name: t("document.signDownTypeCodNotInPerson"),
      });
    }

    array.push({
      value: SIGN_DOWN_TYPE.biometric,
      name: t("document.signDownTypeBio"),
    });

    return array;
  };

  return (
    <>
      <input
        ref={inputRef}
        type="file"
        hidden
        onChange={({ target }) => handleFileChange(target.files)}
      />
      <ClientDocumentDocumentEditModal
        open={modalEditDocument}
        document={editDocument}
        clientId={clientId ?? 0}
        onClose={() => {
          setModalEditDocument(false);
        }}
        onConfirm={() => {
          setModalEditDocument(false);
          clientDocumentListLoad(true);
        }}
      />
      <ClientDocumentAddModal
        file={addFile}
        clientId={clientId!}
        isOpen={addIsOpen}
        close={handleAddClose}
      />
      <SelectTemplateModal
        type={TemplateDocumentType.Client}
        isOpen={templateIsOpen}
        close={handleCloseTemplate}
        confirm={handleConfirmTemplate}
      />

      <FilterPanel
        title={t("module.document.pageTitle")}
        name="clientDocument"
        filter={prov.filter!}
        changeFilter={changeClientDocumentListFilter}
        initialValues={{
          createdFrom: prov.filter!.createdFrom,
          createdTo: prov.filter!.createdTo,
          lastEditFrom: prov.filter!.lastEditFrom,
          lastEditTo: prov.filter!.lastEditTo,
          authorId: prov.filter!.authorId,
          authorName: prov.filter!.authorName,
          lastEditorId: prov.filter!.lastEditorId,
          lastEditorName: prov.filter!.lastEditorName,
        }}
      >
        <CalendarModalTimesContainer>
          <label>{t("document.createdAt")}</label>
          <CalendarModalTimes>
            <div>
              <CalendarModalTimesLabel>
                {t("common.from")}
              </CalendarModalTimesLabel>
              <DatePicker name={"createdFrom"} />
            </div>
            <div>
              <CalendarModalTimesLabel>
                {t("common.to")}
              </CalendarModalTimesLabel>
              <DatePicker name={"createdTo"} />
            </div>
          </CalendarModalTimes>
        </CalendarModalTimesContainer>
        <CalendarModalTimesContainer>
          <label>{t("document.lastEditedAt")}</label>
          <CalendarModalTimes>
            <div>
              <CalendarModalTimesLabel>
                {t("common.from")}
              </CalendarModalTimesLabel>
              <DatePicker name={"lastEditFrom"} />
            </div>
            <div>
              <CalendarModalTimesLabel>
                {t("common.to")}
              </CalendarModalTimesLabel>
              <DatePicker name={"lastEditTo"} />
            </div>
          </CalendarModalTimes>
        </CalendarModalTimesContainer>
        <SuggestionFormik
          nameId="authorId"
          nameText="authorName"
          label={t("document.author")}
          loadSuggestions={handleLoadUserSuggestions}
        />
        <SuggestionFormik
          nameId="lastEditorId"
          nameText="lastEditorName"
          label={t("document.lastEditor")}
          loadSuggestions={handleLoadUserSuggestions}
        />
      </FilterPanel>
      <Grid<IClientDocumentItem>
        headers={[
          {
            captionStr: "document.name",
            orderName: "Name",
          },
          {
            captionStr: "document.createdAt",
            orderName: "CreatedAt",
          },
          {
            captionStr: "document.lastEditedAt",
            orderName: "LastEditedAt",
          },
          {
            captionStr: "document.author",
            orderName: "Author",
          },
          {
            captionStr: "document.lastEditor",
            orderName: "LastEditor",
          },
          {
            captionEl:
              hasClientWriteRight(identityRights, [
                RightType.WriteClientDocuments,
              ]) && !isArchiveFolder ? (
                <DivTextRight>
                  <GridIconAdd onClick={handleAdd} />
                  <GridIconSpace />
                  <GridIconBin
                    onDrop={handleBinDrop}
                    title={t("gridIcons.deleteFile")}
                  />
                  {hasRight(identityRights, [RightType.ReadAllTemplates]) && (
                    <>
                      <GridIconSpace />
                      <GridIconFromTemplate onClick={handleFromTemplate} />
                    </>
                  )}
                </DivTextRight>
              ) : undefined,
          },
        ]}
        renderData={handleRenderData}
        getData={handleGetData}
        getCount={handleGetCount}
        changeOrder={changeClientDocumentListOrder}
        changePage={changeClientDocumentListPage}
        prov={prov}
        draggable={!isArchiveFolder}
        onDrag={handleDragStart}
        onRowDoubleClick={handleRowDoubleClick}
      />
      {pdfFile && (
        <Pdf
          open={isModalForSignDownOpen}
          file={pdfFile}
          onClose={handleCloseModalForSignDown}
          code={codeForSignDown ?? undefined}
          customPdfDownloadFunction={handleSaveSignedDocument}
          texts={{
            save: t("pdfSignature.save"),
            reset: t("pdfSignature.reset"),
            close: t("pdfSignature.close"),
            addSignature: t("pdfSignature.addSignature"),
            placeHolderTooltip: t("pdfSignature.placeholderTooltip"),
            download: t("pdfSignature.download"),
            signDown: t("pdfSignature.signDown"),
            readPdfHelper: t("pdfSignature.readPdfHelper"),
            reded: t("pdfSignature.reded"),
          }}
          customStyles={{
            button: {
              padding: "4px 8px",
              fontSize: "1.2rem",
              border: "none",
              outline: "none",
              borderRadius: "5px",
            },
          }}
          shouldDownload={false}
        />
      )}
      {!!signDownModalData && (
        <PromiseModalSignDownType
          isOpen={Boolean(signDownModalData)}
          resolve={signDownModalData.resolve}
          reject={signDownModalData.reject}
          options={getOptionsForSignDownTypeModal()}
        />
      )}

      {!!codeModalData && (
        <PromiseModalWithText
          title={codeModalData.title}
          resolve={codeModalData.resolve}
          reject={codeModalData.reject}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    prov: selectClientDocumentList(state),
    clientId: selectClientId(state),
    clientPhoneNumber: selectClientPhoneNumber(state),
    identityRights: selectIdentityRights(state),
  };
};

const mapDispachToProps = {
  getClientDocumentList,
  getClientDocumentListCount,
  changeClientDocumentListOrder,
  changeClientDocumentListPage,
  changeClientDocumentListFilter,
  clientDocumentListLoad,
};

export default connect(
  mapStateToProps,
  mapDispachToProps
)(ClientDocumentDocument);
