import { AppThunkAction, AppThunkActionAsync } from "..";
import * as actions from "./actions";
import clientApi from "../../api/client";
import {
  IClientDocumentListFilterType,
  IClientDocumentListState,
  selectClientDocumentList,
} from ".";
import { CancelToken } from "axios";
import {
  listEffectCountFilter,
  listEffectGetFilter,
  listEffectLoad,
  listEffectReload,
  listEffectsCountCheck,
  listEffectsGetCheck,
  listEffectsGetCount,
  listEffectsGetNew,
  listEffectsGetParams,
} from "../listEffect";
import { addDays, formatISO } from "date-fns";

export function getClientDocumentList(
  clientId: number,
  folderId: string,
  cancelToken: CancelToken
): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectClientDocumentList(getState());
    if (listEffectsGetCheck(state.state)) {
      return;
    }

    let newState = listEffectsGetNew(state);
    dispatch(actions.clientDocumentListFetchRequest(newState.newCountState));
    try {
      const params = listEffectsGetParams(state, newState.take);
      listEffectGetFilter(params, state.filter);
      addParams(params, state);

      const documents = await clientApi.getClientDocumentList(
        clientId,
        folderId,
        {
          params,
          cancelToken,
        }
      );
      cancelToken.throwIfRequested();
      newState = listEffectsGetCount(newState, documents);

      dispatch(
        actions.clientDocumentListFetchSuccess({
          data: documents.data,
          newCountState: newState.newCountState,
          newCount: newState.newCount,
        })
      );
    } catch {
      dispatch(
        actions.clientDocumentListFetchError(cancelToken.reason !== undefined)
      );
    }
  };
}

export function getClientDocumentListCount(
  clientId: number,
  folderId: string,
  cancelToken: CancelToken
): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectClientDocumentList(getState());
    if (listEffectsCountCheck(state.countState)) {
      return;
    }

    dispatch(actions.clientDocumentListCountRequest());
    try {
      const params = listEffectCountFilter(state.filter);
      addParams(params, state);
      const count = await clientApi.getClientDocumentCount(clientId, folderId, {
        params,
        cancelToken,
      });
      cancelToken.throwIfRequested();

      dispatch(actions.clientDocumentListCountSuccess(count.data));
    } catch {
      dispatch(
        actions.clientDocumentListCountError(cancelToken.reason !== undefined)
      );
    }
  };
}

export function changeClientDocumentListOrder(
  orderBy: string,
  orderDesc: boolean
): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.clientDocumentListChangeOrder({ orderBy, orderDesc }));
  };
}

export function changeClientDocumentListPage(page: number): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.clientDocumentListChangePage(page));
  };
}

export function changeClientDocumentListFilter(
  filter: IClientDocumentListFilterType
): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.clientDocumentListChangeFilter(filter));
  };
}

export function clientDocumentListLoad(reload: boolean): AppThunkAction {
  return async (dispatch, getState) => {
    const state = selectClientDocumentList(getState());
    reload = listEffectReload(reload, state);
    if (reload) {
      dispatch(actions.clientDocumentListReset());
    } else {
      const newState = listEffectLoad(state);
      dispatch(
        actions.clientDocumentListLoadAction({
          newState: newState.newState,
          newCountState: newState.newCountState,
        })
      );
    }
  };
}

function addParams(params: URLSearchParams, state: IClientDocumentListState) {
  if (state.filter!.createdFrom) {
    params.append("CreatedFrom", formatISO(state.filter!.createdFrom));
  }
  if (state.filter!.createdTo) {
    params.append("CreatedTo", formatISO(addDays(state.filter!.createdTo, 1)));
  }
  if (state.filter!.lastEditFrom) {
    params.append("LastEditFrom", formatISO(state.filter!.lastEditFrom));
  }
  if (state.filter!.lastEditTo) {
    params.append(
      "LastEditTo",
      formatISO(addDays(state.filter!.lastEditTo, 1))
    );
  }
  if (state.filter!.authorId) {
    params.append("IdAuthor", state.filter!.authorId.toString());
  }
  if (state.filter!.lastEditorId) {
    params.append("IdLastEditor", state.filter!.lastEditorId.toString());
  }
}
