import * as ReselectModule from "reselect";
import { IApplicationState } from "..";
import { IUser } from "../../models/user";
import { StoreState } from "../storeState";

export const selectUserState: ReselectModule.Selector<
  IApplicationState,
  StoreState
> = (state) => state.user.state;

export const selectUser: ReselectModule.Selector<
  IApplicationState,
  IUser | null
> = (state) => state.user.data;
