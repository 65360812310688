import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { TableCol, TableColCenter } from "../common/grid/TableCol";
import demandApi from "../../api/demand";
import {
  DemandStateFilterType,
  DemandStateType,
  IDemandItem,
} from "../../models/demand";
import { getClassByDate } from "../../utils/date";
import { format } from "date-fns";
import {
  DATE_FORMAT,
  DASHBOARD_SIZE,
  DASHBOARD_REFRESH_MS,
} from "../../utils/consts";
import axios, { CancelToken } from "axios";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { DashboardDemandsTable, TableMessage } from "../common/grid/table";
import { TailSpin } from "react-loader-spinner";
import { COLORS } from "../../styles/colors";
import {
  DashboardWidgetDiv,
  DashboardWidgetTableContainer,
  DashboardWidgetTitle,
} from "./DashboardWidgetsStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faTasks } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { errorSet } from "../../utils/error";
import DashboardWidgetCounter from "./DashboardWidgetCounter";
import {
  changeDemandListFilter,
  IDemandFilterType,
} from "../../store/demandList";
import { connect } from "react-redux";

interface IDashboardWidgetDemandItems {
  items: IDemandItem[];
  count: number;
}

interface IProps {
  changeDemandListFilter(filter: IDemandFilterType): void;
}

const DashboardWidgetDemands: FC<IProps> = ({ changeDemandListFilter }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [demandsToProcess, setDemandsToProcess] =
    useState<IDashboardWidgetDemandItems>({ items: [], count: 0 });
  const [demandsNotAssigned, setDemandsNotAssigned] =
    useState<IDashboardWidgetDemandItems>({ items: [], count: 0 });
  const [demandsAfterDeadLine, setDemandsAfterDeadLine] =
    useState<IDashboardWidgetDemandItems>({ items: [], count: 0 });
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [lastRefresh, setLastRefresh] = useState<Date | null>(null);

  const getDemans = async (
    params: URLSearchParams,
    cancelToken: CancelToken
  ) => {
    const data = await demandApi.getDemandList({
      params,
      cancelToken,
    });

    if (data.data.length > DASHBOARD_SIZE) {
      const count = await demandApi.getDemandCount({ params, cancelToken });

      return {
        items: data.data.slice(0, DASHBOARD_SIZE),
        count: count.data,
      };
    }

    return {
      items: data.data,
      count: data.data.length,
    };
  };

  const getDemandsToProcess = async (cancelToken: CancelToken) => {
    const params = new URLSearchParams();
    params.append("take", (DASHBOARD_SIZE + 1).toString());
    params.append("state", DemandStateType.New);
    params.append("notAssigned", "false");
    params.append("afterDeadLine", "false");

    return getDemans(params, cancelToken);
  };

  const getDemandsNotAssigned = async (cancelToken: CancelToken) => {
    const params = new URLSearchParams();
    params.append("take", (DASHBOARD_SIZE + 1).toString());
    params.append("state", DemandStateType.New);
    params.append("notAssigned", "true");
    params.append("afterDeadLine", "false");

    return getDemans(params, cancelToken);
  };

  const getDemandsAfterDeadLine = async (cancelToken: CancelToken) => {
    const params = new URLSearchParams();
    params.append("take", (DASHBOARD_SIZE + 1).toString());
    params.append("state", DemandStateType.New);
    params.append("afterDeadLine", "true");

    return getDemans(params, cancelToken);
  };

  useEffect(() => {
    const token = axios.CancelToken.source();
    let timeout: NodeJS.Timeout | null = null;

    (async () => {
      try {
        setError(null);
        setLoading(true);
        setDemandsToProcess({ items: [], count: 0 });
        setDemandsNotAssigned({ items: [], count: 0 });
        setDemandsAfterDeadLine({ items: [], count: 0 });

        const toProcess = await getDemandsToProcess(token.token);
        const notAssigned = await getDemandsNotAssigned(token.token);
        const afterDeadLine = await getDemandsAfterDeadLine(token.token);
        setDemandsToProcess(toProcess);
        setDemandsNotAssigned(notAssigned);
        setDemandsAfterDeadLine(afterDeadLine);

        timeout = setTimeout(() => {
          setLastRefresh(new Date());
        }, DASHBOARD_REFRESH_MS);
      } catch (err) {
        errorSet(setError, err, t);
      }
      setLoading(false);
    })();

    return () => {
      token.cancel();

      if (timeout) {
        clearTimeout(timeout);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastRefresh]);

  const handleRenderData = (item: IDemandItem) => {
    return (
      <>
        <TableCol>{item.name}</TableCol>
        <TableCol>{format(item.createdAt, DATE_FORMAT)}</TableCol>
        <TableCol>
          <span className={getClassByDate(item.deadLine)}>
            {format(item.deadLine, DATE_FORMAT)}
          </span>
        </TableCol>
        <TableCol>{item.clientName}</TableCol>
      </>
    );
  };

  const handleRowClick = useCallback(
    (item: IDemandItem) => {
      navigate(`/demand/${item.id}?return=/`);
    },
    [navigate]
  );

  const handleOpen = (state: DemandStateFilterType) => {
    const filter = {
      isOpen: true,
      state: state,
      groups: [],
      users: [],
      creators: [],
    };

    changeDemandListFilter(filter);
    navigate("/demand");
  };

  const handleOpenToProcess = useCallback(() => {
    handleOpen(DemandStateFilterType.New);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenNotAssigned = useCallback(() => {
    handleOpen(DemandStateFilterType.NotAssigned);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenAfterDeadLine = useCallback(() => {
    handleOpen(DemandStateFilterType.AfterDeadline);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const demandsToProcessTableData = useMemo(() => {
    if (loading || error) return;

    return (
      <>
        <DashboardWidgetCounter
          count={demandsToProcess.items.length}
          total={demandsToProcess.count}
          onClick={handleOpenToProcess}
        />
        <tbody>
          {demandsToProcess.items.map((item) => (
            <tr key={item.id} onClick={() => handleRowClick(item)}>
              <TableColCenter>
                <FontAwesomeIcon icon={faTasks} />
              </TableColCenter>
              <TableCol>{t("dashboard.widgetDemands.toProcess")}</TableCol>
              {handleRenderData(item)}
            </tr>
          ))}
        </tbody>
      </>
    );
  }, [
    demandsToProcess,
    handleRowClick,
    loading,
    t,
    error,
    handleOpenToProcess,
  ]);

  const demandsNotAssignedTableData = useMemo(() => {
    if (loading || error) return;

    return (
      <>
        <DashboardWidgetCounter
          count={demandsNotAssigned.items.length}
          total={demandsNotAssigned.count}
          onClick={handleOpenNotAssigned}
        />
        <tbody>
          {demandsNotAssigned.items.map((item) => (
            <tr key={item.id} onClick={() => handleRowClick(item)}>
              <TableColCenter>
                <FontAwesomeIcon icon={faUser} />
              </TableColCenter>
              <TableCol>{t("dashboard.widgetDemands.notAssigned")}</TableCol>
              {handleRenderData(item)}
            </tr>
          ))}
        </tbody>
      </>
    );
  }, [
    demandsNotAssigned,
    handleRowClick,
    loading,
    t,
    error,
    handleOpenNotAssigned,
  ]);

  const demandsAfterDeadLineTableData = useMemo(() => {
    if (loading || error) return;

    return (
      <>
        <DashboardWidgetCounter
          count={demandsAfterDeadLine.items.length}
          total={demandsAfterDeadLine.count}
          onClick={handleOpenAfterDeadLine}
        />
        <tbody>
          {demandsAfterDeadLine.items.map((item) => (
            <tr key={item.id} onClick={() => handleRowClick(item)}>
              <TableColCenter>
                <FontAwesomeIcon icon={faCalendarAlt} />
              </TableColCenter>
              <TableCol>{t("dashboard.widgetDemands.afterDeadLine")}</TableCol>
              {handleRenderData(item)}
            </tr>
          ))}
        </tbody>
      </>
    );
  }, [
    demandsAfterDeadLine,
    handleRowClick,
    loading,
    t,
    error,
    handleOpenAfterDeadLine,
  ]);

  return (
    <DashboardWidgetDiv>
      <DashboardWidgetTitle>
        {t("dashboard.widgetDemands.title")}
      </DashboardWidgetTitle>
      <DashboardWidgetTableContainer>
        <DashboardDemandsTable>
          {demandsToProcessTableData}
        </DashboardDemandsTable>
        {demandsToProcess.items.length !== 0 &&
          (demandsNotAssigned.items.length !== 0 ||
            demandsAfterDeadLine.items.length !== 0) && <hr />}
        <DashboardDemandsTable>
          {demandsNotAssignedTableData}
        </DashboardDemandsTable>
        {demandsNotAssigned.items.length !== 0 &&
          demandsAfterDeadLine.items.length !== 0 && <hr />}
        {/* todo color */}
        <DashboardDemandsTable color={"var(--error-color)"}>
          {demandsAfterDeadLineTableData}
        </DashboardDemandsTable>

        {loading && (
          <TableMessage>
            <TailSpin color={COLORS.loaderColor} width={48} height={48} />
          </TableMessage>
        )}

        {!loading && error && <TableMessage>{error}</TableMessage>}

        {!loading &&
          !error &&
          demandsToProcess.items.length === 0 &&
          demandsNotAssigned.items.length === 0 &&
          demandsAfterDeadLine.items.length === 0 && (
            <TableMessage>{t("common.noData")}</TableMessage>
          )}
      </DashboardWidgetTableContainer>
    </DashboardWidgetDiv>
  );
};

const mapDispachToProps = {
  changeDemandListFilter,
};

export default connect(null, mapDispachToProps)(DashboardWidgetDemands);
