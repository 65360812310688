import { FC } from "react";
import {
  DashboardWidgetCounterContainer,
  DashboardWidgetCounterText,
} from "./DashboardWidgetsStyles";

interface IProps {
  count: number;
  total: number;
  onClick(): void;
}

const DashboardWidgetCounter: FC<IProps> = ({ count, total, onClick }) => {
  return (
    <DashboardWidgetCounterContainer>
      <DashboardWidgetCounterText onClick={() => onClick()}>
        {count}/{total}
      </DashboardWidgetCounterText>
    </DashboardWidgetCounterContainer>
  );
};

export default DashboardWidgetCounter;
