import * as ReselectModule from "reselect";
import { IApplicationState } from "..";
import { IItemIdName } from "../../models/common";
import { StoreState } from "../storeState";

export const selectClientRiskState: ReselectModule.Selector<
  IApplicationState,
  StoreState
> = (state) => state.clientRisk.state;

export const selectClientRisk: ReselectModule.Selector<
  IApplicationState,
  IItemIdName[]
> = (state) => state.clientRisk.data;
