import { AppThunkAction, AppThunkActionAsync } from "..";
import * as actions from "./actions";
import clientApi from "../../api/client";
import { selectClientRiskState } from ".";
import { StoreState } from "../storeState";
import { IItemIdName } from "../../models/common";

export function getClientRisk(clientId: number): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectClientRiskState(getState());
    if (state === StoreState.Loading || state === StoreState.Loaded) {
      return;
    }

    dispatch(actions.clientRiskFetchRequest());
    try {
      const clientRisk = await clientApi.getClientRiskList(clientId);
      dispatch(actions.clientRiskFetchSuccess(clientRisk.data));
    } catch {
      dispatch(actions.clientRiskFetchError());
    }
  };
}

export function updateClientRisk(newData: IItemIdName[]): AppThunkAction {
  return (dispatch) => {
    dispatch(actions.clientRiskFetchSuccess(newData));
  };
}

export function resetClientRisk(): AppThunkAction {
  return (dispatch) => {
    dispatch(actions.clientRiskReset());
  };
}
