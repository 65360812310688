import { AppThunkAction, AppThunkActionAsync } from "..";
import * as actions from "./actions";
import calendarApi from "../../api/calendar";
import { addHours, addMinutes, startOfHour } from "date-fns";
import { CALENDAR_STEP } from "../../utils/consts";

export function getAction(actionId: number): AppThunkActionAsync {
  return async (dispatch) => {
    dispatch(actions.actionFetchRequest());
    try {
      const action = await calendarApi.getAction(actionId);
      dispatch(actions.actionFetchSuccess(action.data));
    } catch {
      dispatch(actions.actionFetchError());
    }
  };
}

export function getDefaultAction(): AppThunkAction {
  return (dispatch) => {
    const dateTimeFrom = startOfHour(addHours(new Date(), 2));
    const dateTimeTo = addMinutes(dateTimeFrom, CALENDAR_STEP);

    const data = {
      id: 0,
      userCreator: { id: 0, name: "" },
      userAssignee: { id: 0, name: "" },
      client: { id: 0, name: "" },
      type: { id: 0, name: "" },
      from: dateTimeFrom,
      to: dateTimeTo,
    };

    dispatch(actions.actionFetchSuccess(data));
  };
}
