import { AxiosRequestConfig, AxiosResponse } from "axios";
import { IItemIdName } from "../models/common";
import { IListingEdit, IListingItem, ListingType } from "../models/listing";
import api from "../utils/api";

const getList = async (
  type: ListingType,
  config: AxiosRequestConfig
): Promise<AxiosResponse<IListingItem[]>> =>
  api.get(`/api/Listing/${type}`, config);

const getCount = async (
  type: ListingType,
  config: AxiosRequestConfig
): Promise<AxiosResponse<number>> =>
  api.get(`/api/Listing/${type}/Count`, config);

const getSuggestionList = async (
  type: ListingType,
  config: AxiosRequestConfig
): Promise<AxiosResponse<IItemIdName[]>> =>
  api.get(`/api/Listing/${type}/Suggested/List`, config);

const addListing = async (
  type: ListingType,
  data: IListingEdit
): Promise<AxiosResponse> => api.post(`/api/Listing/${type}`, data);

const updateListing = async (
  id: number,
  data: IListingEdit
): Promise<AxiosResponse> => api.put(`/api/Listing/${id}`, data);

const listingApi = {
  getList,
  getCount,
  getSuggestionList,
  addListing,
  updateListing,
};

export default listingApi;
