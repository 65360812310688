import { createAction } from "@reduxjs/toolkit";
import { IWorkflow } from "../../models/workflow";
import { WorkflowTypes } from "./types";

export const workflowFetchRequest = createAction<
  void,
  WorkflowTypes.WORKFLOW_FETCH_REQUEST
>(WorkflowTypes.WORKFLOW_FETCH_REQUEST);

export const workflowFetchSuccess = createAction<
  IWorkflow,
  WorkflowTypes.WORKFLOW_FETCH_SUCCESS
>(WorkflowTypes.WORKFLOW_FETCH_SUCCESS);

export const workflowFetchError = createAction<
  void,
  WorkflowTypes.WORKFLOW_FETCH_ERROR
>(WorkflowTypes.WORKFLOW_FETCH_ERROR);
