import { AppThunkAction, AppThunkActionAsync } from "..";
import * as actions from "./actions";
import healthInsuranceApi from "../../api/healthInsurance";
import { selectHealthInsuranceList } from ".";
import { CancelToken } from "axios";
import {
  listEffectCountFilter,
  listEffectGetFilter,
  listEffectLoad,
  listEffectReload,
  listEffectsCountCheck,
  listEffectsGetCheck,
  listEffectsGetCount,
  listEffectsGetNew,
  listEffectsGetParams,
} from "../listEffect";
import { IFilterType } from "../filterType";

export function getHealthInsuranceList(
  cancelToken: CancelToken
): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectHealthInsuranceList(getState());
    if (listEffectsGetCheck(state.state)) {
      return;
    }

    let newState = listEffectsGetNew(state);
    dispatch(actions.healthInsuranceListFetchRequest(newState.newCountState));
    try {
      const params = listEffectsGetParams(state, newState.take);
      listEffectGetFilter(params, state.filter);

      const healthInsurances = await healthInsuranceApi.getList({
        params,
        cancelToken,
      });
      cancelToken.throwIfRequested();
      newState = listEffectsGetCount(newState, healthInsurances);

      dispatch(
        actions.healthInsuranceListFetchSuccess({
          data: healthInsurances.data,
          newCountState: newState.newCountState,
          newCount: newState.newCount,
        })
      );
    } catch {
      dispatch(
        actions.healthInsuranceListFetchError(cancelToken.reason !== undefined)
      );
    }
  };
}

export function getHealthInsuranceListCount(
  cancelToken: CancelToken
): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectHealthInsuranceList(getState());
    if (listEffectsCountCheck(state.countState)) {
      return;
    }

    dispatch(actions.healthInsuranceListCountRequest());
    try {
      const params = listEffectCountFilter(state.filter);

      const count = await healthInsuranceApi.getCount({ params, cancelToken });
      cancelToken.throwIfRequested();

      dispatch(actions.healthInsuranceListCountSuccess(count.data));
    } catch {
      dispatch(
        actions.healthInsuranceListCountError(cancelToken.reason !== undefined)
      );
    }
  };
}

export function changeHealthInsuranceListOrder(
  orderBy: string,
  orderDesc: boolean
): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.healthInsuranceListChangeOrder({ orderBy, orderDesc }));
  };
}

export function changeHealthInsuranceListPage(page: number): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.healthInsuranceListChangePage(page));
  };
}

export function changeHealthInsuranceListFilter(
  filter: IFilterType
): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.healthInsuranceListChangeFilter(filter));
  };
}

export function healthInsuranceListLoad(reload: boolean): AppThunkAction {
  return async (dispatch, getState) => {
    const state = selectHealthInsuranceList(getState());
    reload = listEffectReload(reload, state);
    if (reload) {
      dispatch(actions.healthInsuranceListReset());
    } else {
      const newState = listEffectLoad(state);
      dispatch(
        actions.healthInsuranceListLoadAction({
          newState: newState.newState,
          newCountState: newState.newCountState,
        })
      );
    }
  };
}
