import { FC } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RightType } from "../../models/auth";
import { ListingType } from "../../models/listing";
import { IApplicationState } from "../../store";
import { selectIdentityRights } from "../../store/identity";
import { VerticalSpace } from "../../styles/spaces";
import { hasRight } from "../../utils/rights";
import AdminListingGrid from "./AdminListingGrid";
import AdminListingHealthInsuranceGrid from "./AdminListingHealthInsuranceGrid";

interface IProps {
  identityRights?: RightType[];
}

const AdminListing: FC<IProps> = ({ identityRights }) => {
  const { t } = useTranslation();

  return (
    <>
      <h1>{t("admin.listing.pageTitle")}</h1>
      <VerticalSpace />
      {hasRight(identityRights, [RightType.ReadTargetGroupListing]) && (
        <>
          <AdminListingGrid
            type={ListingType.TargetGroupListing}
            writeRight={RightType.WriteTargetGroupListing}
          />
          <VerticalSpace />
        </>
      )}
      {hasRight(identityRights, [RightType.ReadClientContactPersonListing]) && (
        <>
          <AdminListingGrid
            type={ListingType.ClientContactPersonListing}
            writeRight={RightType.WriteClientContactPersonListing}
          />
          <VerticalSpace />
        </>
      )}
      {hasRight(identityRights, [RightType.ReadContactPersonListing]) && (
        <>
          <AdminListingGrid
            type={ListingType.ContactPersonListing}
            writeRight={RightType.WriteContactPersonListing}
          />
          <VerticalSpace />
        </>
      )}
      {hasRight(identityRights, [RightType.ReadAssistanceCategoryListing]) && (
        <>
          <AdminListingGrid
            type={ListingType.AssistanceCategoryListing}
            writeRight={RightType.WriteAssistanceCategoryListing}
          />
          <VerticalSpace />
        </>
      )}
      {hasRight(identityRights, [RightType.ReadClientRiskListing]) && (
        <>
          <AdminListingGrid
            type={ListingType.ClientRiskListing}
            writeRight={RightType.WriteClientRiskListing}
          />
          <VerticalSpace />
        </>
      )}
      {hasRight(identityRights, [RightType.ReadSocialBenefitListing]) && (
        <>
          <AdminListingGrid
            type={ListingType.SocialBenefitListing}
            writeRight={RightType.WriteSocialBenefitListing}
          />
          <VerticalSpace />
        </>
      )}
      {hasRight(identityRights, [RightType.ReadActionListing]) && (
        <>
          <AdminListingGrid
            type={ListingType.ActionListing}
            writeRight={RightType.WriteActionListing}
          />
          <VerticalSpace />
        </>
      )}
      {hasRight(identityRights, [RightType.ReadHealthInsurance]) && (
        <>
          <AdminListingHealthInsuranceGrid />
          <VerticalSpace />
        </>
      )}
      {hasRight(identityRights, [RightType.ReadWorkflowCategoryListing]) && (
        <>
          <AdminListingGrid
            type={ListingType.WorkflowCategoryListing}
            writeRight={RightType.WriteWorkflowCategoryListing}
          />
          <VerticalSpace />
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    identityRights: selectIdentityRights(state),
  };
};

export default connect(mapStateToProps)(AdminListing);
