import { ITreeReponse } from "../../components/common/tree/CustomTree";

export enum TemplateTreeTypes {
  TEMPLATE_TREE_FETCH_REQUEST = "@@templateTree/FETCH_REQUEST",
  TEMPLATE_TREE_FETCH_SUCCESS = "@@templateTree/FETCH_SUCCESS",
  TEMPLATE_TREE_FETCH_ERROR = "@@templateTree/FETCH_ERROR",
  TEMPLATE_TREE_RESET = "@@templateTree/RESET",
}

export interface ITemplateTreeState {
  items: Record<string, ITreeReponse>;
}
