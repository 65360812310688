import { FC } from "react";
import { connect } from "react-redux";
import { Route, Routes } from "react-router-dom";
import Login from "./components/auth/Login";
import Dashboard from "./components/dashboard/Dashboard";
import { IApplicationState } from "./store";
import { StoreState } from "./store/storeState";
import { getIdentity, selectIdentityState } from "./store/identity";
import storageApi from "./utils/storage";
import LoaderPage from "./components/layout/LoaderPage";
import AuthRoute from "./components/auth/AuthRoute";
import Calendar from "./components/calendar/Calendar";
import Demand from "./components/demand/Demand";
import Client from "./components/client/Client";
import Assistance from "./components/assistance/Assistance";
import Module from "./components/module/Module";
import Admin from "./components/admin/Admin";
import Settings from "./components/settings/Settings";
import AssistanceDetail from "./components/assistance/AssistanceDetail";
import ClientDetail from "./components/client/ClientDetail";
import { RightType } from "./models/auth";
import DemandDetail from "./components/demand/DemandDetail";
import { allListingRights } from "./utils/rights";
import Search from "./components/search/Search";
import ChangePassword from "./components/auth/ChangePassword";
import DemandNew from "./components/demand/DemandNew";
import DemandHistory from "./components/demand/DemandHistory";
import ChangeDemand from "./components/ChangeDemand/ChangeDemand";

interface IProps {
  identityState: StoreState;
  getIdentity(): void;
}

const Routes2: FC<IProps> = ({ identityState, getIdentity }) => {
  const tokenIsValid = storageApi.getTokenIsValid();

  if (!tokenIsValid || identityState === StoreState.Error) {
    return <Login />;
  }

  if (identityState === StoreState.None) {
    getIdentity();
    return <LoaderPage />;
  }

  if (identityState === StoreState.Loading) {
    return <LoaderPage />;
  }

  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route
        path="calendar/*"
        element={
          <AuthRoute rights={[RightType.ReadAction, RightType.ReadCalendar]}>
            <Calendar />
          </AuthRoute>
        }
      />
      <Route
        path="demand"
        element={
          <AuthRoute rights={[RightType.ReadDemand]}>
            <Demand />
          </AuthRoute>
        }
      />
      <Route
        path="demand/new"
        element={
          <AuthRoute rights={[RightType.WriteDemand]}>
            <DemandNew />
          </AuthRoute>
        }
      />
      <Route
        path="demand/:demandId/history"
        element={
          <AuthRoute rights={[RightType.ReadDemand]}>
            <DemandHistory />
          </AuthRoute>
        }
      />
      <Route
        path="demand/:demandId"
        element={
          <AuthRoute rights={[RightType.ReadDemand]}>
            <DemandDetail />
          </AuthRoute>
        }
      />
      <Route
        path="change-demand/:changeDemandId"
        element={
          <AuthRoute rights={[RightType.WriteDemand]}>
            <ChangeDemand />
          </AuthRoute>
        }
      />
      <Route
        path="client"
        element={
          <AuthRoute
            rights={[
              RightType.ReadAllClients,
              RightType.ReadMyClients,
              RightType.ReadClientsInTheSameAssistance,
            ]}
          >
            <Client />
          </AuthRoute>
        }
      />
      <Route
        path="client/:clientId/*"
        element={
          <AuthRoute
            rights={[
              RightType.ReadAllClients,
              RightType.ReadMyClients,
              RightType.ReadClientsInTheSameAssistance,
            ]}
          >
            <ClientDetail />
          </AuthRoute>
        }
      />
      <Route
        path="assistance"
        element={
          <AuthRoute rights={[RightType.ReadAssistances]}>
            <Assistance />
          </AuthRoute>
        }
      />
      <Route
        path="assistance/:assistanceId/*"
        element={
          <AuthRoute rights={[RightType.ReadAssistances]}>
            <AssistanceDetail />
          </AuthRoute>
        }
      />
      <Route
        path="module/*"
        element={
          <AuthRoute
            rights={[
              RightType.ReadAllTemplates,
              RightType.ReadWorkflowTemplate,
              RightType.ReadWorkflow,
              RightType.ReadReporting,
            ]}
          >
            <Module />
          </AuthRoute>
        }
      />
      <Route
        path="admin/*"
        element={
          <AuthRoute
            rights={[
              RightType.ReadUsers,
              RightType.ReadRole,
              RightType.ReadUserGroups,
              RightType.ReadCareType,
              ...allListingRights,
              RightType.ReadNotification,
              RightType.ReadBasicSettings,
              RightType.ReadPublic,
              RightType.ReadMonitor,
              RightType.ReadLog,
            ]}
          >
            <Admin />
          </AuthRoute>
        }
      />
      <Route
        path="settings"
        element={
          <AuthRoute>
            <Settings />
          </AuthRoute>
        }
      />
      <Route
        path="search"
        element={
          <AuthRoute>
            <Search />
          </AuthRoute>
        }
      />
      <Route
        path="change-password"
        element={
          <AuthRoute>
            <ChangePassword />
          </AuthRoute>
        }
      />
      <Route
        path="*"
        element={
          <AuthRoute>
            <Dashboard />
          </AuthRoute>
        }
      />
    </Routes>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    identityState: selectIdentityState(state),
  };
};

const mapDispachToProps = {
  getIdentity,
};

export default connect(mapStateToProps, mapDispachToProps)(Routes2);
