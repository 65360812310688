import { AppThunkAction, AppThunkActionAsync } from "..";
import * as actions from "./actions";
import assistanceApi from "../../api/assistance";
import { selectAssistancePersonList } from ".";
import { IFilterType } from "../filterType";
import { CancelToken } from "axios";
import {
  listEffectCountFilter,
  listEffectGetFilter,
  listEffectLoad,
  listEffectReload,
  listEffectsCountCheck,
  listEffectsGetCheck,
  listEffectsGetCount,
  listEffectsGetNew,
  listEffectsGetParams,
} from "../listEffect";

export function getAssistancePersonList(
  assistanceId: number,
  cancelToken: CancelToken
): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectAssistancePersonList(getState());
    if (listEffectsGetCheck(state.state)) {
      return;
    }

    let newState = listEffectsGetNew(state);
    dispatch(actions.assistancePersonListFetchRequest(newState.newCountState));
    try {
      const params = listEffectsGetParams(state, newState.take);
      listEffectGetFilter(params, state.filter);

      const persons = await assistanceApi.getPersonList(assistanceId, {
        params,
        cancelToken,
      });
      cancelToken.throwIfRequested();
      newState = listEffectsGetCount(newState, persons);

      dispatch(
        actions.assistancePersonListFetchSuccess({
          data: persons.data,
          newCountState: newState.newCountState,
          newCount: newState.newCount,
        })
      );
    } catch {
      dispatch(
        actions.assistancePersonListFetchError(cancelToken.reason !== undefined)
      );
    }
  };
}

export function getAssistancePersonListCount(
  assistanceId: number,
  cancelToken: CancelToken
): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectAssistancePersonList(getState());
    if (listEffectsCountCheck(state.countState)) {
      return;
    }

    dispatch(actions.assistancePersonListCountRequest());
    try {
      const params = listEffectCountFilter(state.filter);
      const count = await assistanceApi.getPersonCount(assistanceId, {
        params,
        cancelToken,
      });
      cancelToken.throwIfRequested();

      dispatch(actions.assistancePersonListCountSuccess(count.data));
    } catch {
      dispatch(
        actions.assistancePersonListCountError(cancelToken.reason !== undefined)
      );
    }
  };
}

export function changeAssistancePersonListOrder(
  orderBy: string,
  orderDesc: boolean
): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.assistancePersonListChangeOrder({ orderBy, orderDesc }));
  };
}

export function changeAssistancePersonListPage(page: number): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.assistancePersonListChangePage(page));
  };
}

export function changeAssistancePersonListFilter(
  filter: IFilterType
): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.assistancePersonListChangeFilter(filter));
  };
}

export function assistancePersonListLoad(reload: boolean): AppThunkAction {
  return async (dispatch, getState) => {
    const state = selectAssistancePersonList(getState());
    reload = listEffectReload(reload, state);
    if (reload) {
      dispatch(actions.assistancePersonListReset());
    } else {
      const newState = listEffectLoad(state);
      dispatch(
        actions.assistancePersonListLoadAction({
          newState: newState.newState,
          newCountState: newState.newCountState,
        })
      );
    }
  };
}
