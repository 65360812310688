import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { SIZES } from "../../styles/sizes";

export enum HeaderMenuVisibility {
  Hide = "hide",
  ShowAlways = "showAlways",
  LargeOnly = "largeOnly",
  SmallOnly = "smallOnly",
}

export const HeaderMenuLinks = styled.ul<{
  visibility: HeaderMenuVisibility;
  left: boolean;
}>`
  height: auto;
  flex-flow: row;
  align-items: center;
  padding-bottom: 1rem;

  position: absolute;
  top: var(--header-height);
  z-index: 999;

  list-style: none;
  background-color: var(--menu-bg);

  ${({ left }) =>
    left
      ? "left: 0; border-radius: 0 0 4px 0; box-shadow: 5px 5px 10px var(--menu-shadow);"
      : "right: 0; border-radius: 0 0 0 4px; box-shadow: -5px 5px 10px var(--menu-shadow);"}

  display: ${({ visibility }) =>
    visibility === HeaderMenuVisibility.ShowAlways ||
    visibility === HeaderMenuVisibility.SmallOnly
      ? "block"
      : "none"};

  @media screen and (min-width: ${SIZES.laptop}) {
    ${({ visibility }) =>
      visibility === HeaderMenuVisibility.LargeOnly && "display: block;"}
    ${({ visibility }) =>
      visibility === HeaderMenuVisibility.SmallOnly && "display: none;"}
  }

  svg {
    transition: all 0.15s ease-in-out;
    color: var(--menu-color) !important;

    align-items: left;
    position: absolute;
    right: 1rem;
  }

  a {
    text-decoration: none;
    background-color: var(--menu-bg);
  }

  a: hover {
    color: var(--menu-color-hover) !important;
    background-color: var(--menu-bg-hover) !important;

    svg {
      color: var(--menu-color-hover) !important;
    }
  }

  a.active {
    color: var(--menu-color-active) !important;
    background-color: var(--menu-bg-active) !important;

    svg {
      color: var(--menu-color-active) !important;
    }
  }
`;

export const HeaderMenuLink = styled(NavLink)`
  height: 100%;
  width: 100%;
  padding: 0.75rem 2.5rem 0.75rem 1rem;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;

  text-transform: uppercase;
  color: var(--menu-color);
  font-size: 1rem;
  transition: all 0.15s ease-in-out;
`;

export const HeaderMenuSidebarHeader = styled.li`
  cursor: pointer;
  height: 100%;
  width: 100%;
  padding: 0.75rem 1rem 0.75rem 1rem;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;

  text-transform: uppercase;
  color: var(--menu-color);
  font-size: 1rem;
  transition: all 0.15s ease-in-out;

  svg {
    position: absolute;
    left: 1rem;
  }

  &: hover {
    color: var(--menu-color-hover) !important;
    background-color: var(--menu-bg-hover) !important;

    svg {
      color: var(--menu-color-hover) !important;
    }
  }
`;

export const HeaderMenuUserName = styled.li`
  height: 100%;
  width: 100%;
  padding: 0.75rem 1rem 0.75rem 1rem;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;

  color: var(--menu-color);

  display: block;

  @media screen and (min-width: ${SIZES.tablet}) {
    display: none;
  }
`;
