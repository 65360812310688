import styled from "styled-components";
import { SIZES } from "../../../styles/sizes";

export const FilterExportButtonsContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;

  @media screen and (min-width: ${SIZES.tablet}) {
    flex-direction: column;
  }
`;
