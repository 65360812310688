import { AppThunkAction, AppThunkActionAsync } from "..";
import * as actions from "./actions";
import templateApi from "../../api/template";

export function getTemplateFolder(folderId: string): AppThunkActionAsync {
  return async (dispatch) => {
    dispatch(actions.templateFolderFetchRequest());
    try {
      const folder = await templateApi.getFolder(folderId);
      dispatch(actions.templateFolderFetchSuccess(folder.data));
    } catch {
      dispatch(actions.templateFolderFetchError());
    }
  };
}

export function getDefaultTemplateFolder(): AppThunkAction {
  return (dispatch) => {
    dispatch(actions.templateFolderFetchSuccess([]));
  };
}
