import * as ReselectModule from "reselect";
import { IApplicationState } from "..";
import { IChangeDemandDetail } from "../../models/changeDemads";
import { StoreState } from "../storeState";

export const selectChangeDemand: ReselectModule.Selector<
  IApplicationState,
  IChangeDemandDetail | null
> = (state) => state.changeDemand.data;

export const selectChangeDemandState: ReselectModule.Selector<
  IApplicationState,
  StoreState
> = (state) => state.changeDemand.state;
