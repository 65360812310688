import {
  faBell,
  faDesktop,
  faEllipsisH,
  faHeartbeat,
  faList,
  faRunning,
  faUser,
  faUsers,
  faUsersCog,
} from "@fortawesome/free-solid-svg-icons";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Route, Routes } from "react-router";
import { RightType } from "../../models/auth";
import { IApplicationState } from "../../store";
import { selectIdentityRights } from "../../store/identity";
import { allListingRights, filterMenu } from "../../utils/rights";
import AuthRoute from "../auth/AuthRoute";
import { SidebarPageContainer } from "../layout/LayoutStyles";
import Page from "../layout/Page";
import Sidebar from "../layout/Sidebar";
import AdminCareType from "./AdminCareType";
import AdminCareTypeDetail from "./AdminCareTypeDetail";
import AdminGroup from "./AdminGroup";
import AdminListing from "./AdminListing";
import AdminLog from "./AdminLog";
import AdminMonitor from "./AdminMonitor";
import AdminNotification from "./AdminNotification";
import AdminNotificationDetail from "./AdminNotificationDetail";
import AdminParameter from "./AdminParameter";
import AdminPublic from "./AdminPublic";
import AdminRole from "./AdminRole";
import AdminRoleDetail from "./AdminRoleDetail";
import AdminUser from "./AdminUser";
import AdminUserDetail from "./AdminUserDetail";

const links = [
  { id: "user", icon: faUser, rights: [RightType.ReadUsers] },
  { id: "role", icon: faUsersCog, rights: [RightType.ReadRole] },
  { id: "group", icon: faUsers, rights: [RightType.ReadUserGroups] },
  { id: "caretype", icon: faUsers, rights: [RightType.ReadCareType] },
  { id: "listing", icon: faList, rights: allListingRights },
  { id: "notification", icon: faBell, rights: [RightType.ReadNotification] },
  { id: "parameter", icon: faEllipsisH, rights: [RightType.ReadBasicSettings] },
  { id: "public", icon: faRunning, rights: [RightType.ReadPublic] },
  { id: "monitor", icon: faHeartbeat, rights: [RightType.ReadMonitor] },
  { id: "log", icon: faDesktop, rights: [RightType.ReadLog] },
];

interface IProps {
  identityRights?: RightType[];
}

const Admin: FC<IProps> = ({ identityRights }) => {
  const { t } = useTranslation();

  return (
    <SidebarPageContainer>
      <Sidebar
        title={t("admin.title")}
        showTitle={true}
        links={filterMenu(identityRights, links).map((l) => ({
          key: l.id,
          to: "/admin/" + l.id,
          text: t(`admin.nav.${l.id}`),
          icon: l.icon,
        }))}
      />
      <Page>
        <Routes>
          <Route
            path="user"
            element={
              <AuthRoute rights={[RightType.ReadUsers]}>
                <AdminUser />
              </AuthRoute>
            }
          />
          <Route
            path="user/:userId"
            element={
              <AuthRoute rights={[RightType.ReadUsers]}>
                <AdminUserDetail />
              </AuthRoute>
            }
          />
          <Route
            path="role"
            element={
              <AuthRoute rights={[RightType.ReadRole]}>
                <AdminRole />
              </AuthRoute>
            }
          />
          <Route
            path="role/:roleId"
            element={
              <AuthRoute rights={[RightType.ReadRole]}>
                <AdminRoleDetail />
              </AuthRoute>
            }
          />
          <Route
            path="group"
            element={
              <AuthRoute rights={[RightType.ReadUserGroups]}>
                <AdminGroup />
              </AuthRoute>
            }
          />
          <Route
            path="caretype"
            element={
              <AuthRoute rights={[RightType.ReadCareType]}>
                <AdminCareType />
              </AuthRoute>
            }
          />
          <Route
            path="caretype/:careTypeId"
            element={
              <AuthRoute rights={[RightType.ReadCareType]}>
                <AdminCareTypeDetail />
              </AuthRoute>
            }
          />
          <Route
            path="listing"
            element={
              <AuthRoute rights={allListingRights}>
                <AdminListing />
              </AuthRoute>
            }
          />
          <Route
            path="notification"
            element={
              <AuthRoute rights={[RightType.ReadNotification]}>
                <AdminNotification />
              </AuthRoute>
            }
          />
          <Route
            path="notification/:notificationId"
            element={
              <AuthRoute rights={[RightType.ReadNotification]}>
                <AdminNotificationDetail />
              </AuthRoute>
            }
          />
          <Route
            path="parameter"
            element={
              <AuthRoute rights={[RightType.ReadBasicSettings]}>
                <AdminParameter />
              </AuthRoute>
            }
          />
          <Route
            path="public"
            element={
              <AuthRoute rights={[RightType.ReadPublic]}>
                <AdminPublic />
              </AuthRoute>
            }
          />
          <Route
            path="monitor"
            element={
              <AuthRoute rights={[RightType.ReadMonitor]}>
                <AdminMonitor />
              </AuthRoute>
            }
          />
          <Route
            path="log"
            element={
              <AuthRoute rights={[RightType.ReadLog]}>
                <AdminLog />
              </AuthRoute>
            }
          />
        </Routes>
      </Page>
    </SidebarPageContainer>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    identityRights: selectIdentityRights(state),
  };
};

export default connect(mapStateToProps)(Admin);
