import { AppThunkAction, AppThunkActionAsync } from "..";
import * as actions from "./actions";
import roleApi from "../../api/role";

export function getRole(roleId: number): AppThunkActionAsync {
  return async (dispatch) => {
    dispatch(actions.roleFetchRequest());
    try {
      const role = await roleApi.getRole(roleId);
      dispatch(actions.roleFetchSuccess(role.data));
    } catch {
      dispatch(actions.roleFetchError());
    }
  };
}

export function getDefaultRole(): AppThunkAction {
  return (dispatch) => {
    const data = {
      id: 0,
      name: "",
      description: "",

      createAssistance: false,
      readAssistances: false,
      readAllAssistances: false,
      writeAssistances: false,
      writeAllAssistances: false,
      writeAssistanceState: false,
      readWorkflowTemplate: false,
      writeWorkflowTemplate: false,
      readWorkflow: false,
      writeWorkflow: false,

      readAllClients: false,
      readMyClients: false,
      readClientsInTheSameAssistance: false,
      readClientsFinancingData: false,
      readClientNotes: false,
      readClientContactPersons: false,
      readClientsRights: false,
      readClientRisk: false,
      readClientHousingSituation: false,
      readClientAction: false,
      readClientDemand: false,

      writeClientContactPersons: false,
      createClient: false,
      writeAllClients: false,
      writeMyClients: false,
      writeClientsInTheSameAssistance: false,
      writeClientGeneral: false,
      writeClientNotes: false,
      writeClientsFinancingData: false,
      writeClientsRights: false,
      writeClientRisk: false,
      writeClientHousingSituation: false,
      writeClientAction: false,
      writeClientDemand: false,
      changeClientState: false,

      readAllUsers: false,
      readUsersInTheSameGroup: false,
      readUsersInTheSameAssistance: false,
      readUsers: false,
      writeUsers: false,
      addUsers: false,
      writeAllUsers: false,
      writeUsersInTheSameGroup: false,
      writeUsersInTheSameAssistance: false,
      changeUserIsActive: false,
      addClientGdprAgreement: false,
      addClientGdprAgreementWithoutSecret: false,

      readActionListing: false,
      writeActionListing: false,
      readAssistanceCategoryListing: false,
      writeAssistanceCategoryListing: false,
      readClientContactPersonListing: false,
      writeClientContactPersonListing: false,
      readClientRiskListing: false,
      writeClientRiskListing: false,
      readSocialBenefitListing: false,
      writeSocialBenefitListing: false,
      readTargetGroupListing: false,
      writeTargetGroupListing: false,
      readContactPersonListing: false,
      writeContactPersonListing: false,
      readWorkflowCategoryListing: false,
      writeWorkflowCategoryListing: false,

      readHealthInsurance: false,
      writeHealthInsurance: false,

      readBasicSettings: false,
      writeBasicSettings: false,
      readPublic: false,
      readMonitor: false,
      readLog: false,
      readUserGroups: false,
      writeUserGroups: false,
      readCareType: false,
      writeCareType: false,
      readRole: false,
      writeRole: false,
      changeRoleRights: false,
      anonymiseClient: false,
      exportClient: false,

      readDemand: false,
      writeDemand: false,
      assignDemand: false,
      changeAssignedUserDemand: false,
      changeAssignedGroupDemand: false,

      readAllActions: false,
      readAction: false,
      writeAllActions: false,
      writeAction: false,
      cancelAction: false,
      updateAssignedUserAction: false,
      deleteAction: false,

      readAllCalendars: false,
      readCalendar: false,
      readClientCalendar: false,
      writeAllCalendars: false,
      writeCalendar: false,
      writeClientCalendar: false,
      readCalendarList: false,
      writeCalendarList: false,
      readCalendarMy: false,
      writeCalendarMy: false,
      readCalendarTeamWeek: false,
      writeCalendarTeamWeek: false,
      readCalendarTeamDay: false,
      writeCalendarTeamDay: false,

      readAllTemplates: false,
      readClientDocuments: false,
      writeClientDocuments: false,
      readAssistanceDocuments: false,
      writeAssistanceDocuments: false,
      writeAllTemplates: false,
      deleteAnyDocument: false,
      readDeletedDocuments: false,
      readFolders: false,
      writeFolders: false,
      softDeleteFolders: false,

      readCare: false,
      writeCare: false,
      readNotification: false,
      writeNotification: false,
      readReporting: false,
      writeReporting: false,
    };

    dispatch(actions.roleFetchSuccess(data));
  };
}
