import { AxiosRequestConfig, AxiosResponse } from "axios";
import { IItemIdName } from "../models/common";
import { IHealthInsuranceItem } from "../models/healthInsurance";
import api from "../utils/api";

const getList = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<IHealthInsuranceItem[]>> =>
  api.get(`/api/HealthInsurance`, config);

const getCount = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<number>> =>
  api.get(`/api/HealthInsurance/Count`, config);

const getSuggestionList = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<IItemIdName[]>> =>
  api.get(`/api/HealthInsurance/Suggested/List`, config);

const addHealthInsurance = async (
  data: IHealthInsuranceItem
): Promise<AxiosResponse> => api.post(`/api/HealthInsurance`, data);

const updateHealthInsurance = async (
  id: number,
  data: IHealthInsuranceItem
): Promise<AxiosResponse> => api.put(`/api/HealthInsurance/${id}`, data);

const healthInsuranceApi = {
  getList,
  getCount,
  getSuggestionList,
  addHealthInsurance,
  updateHealthInsurance,
};

export default healthInsuranceApi;
