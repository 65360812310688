import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../styles/colors";
import { getLinkIcon, getLinkName } from "../../utils/links";
import {
  SettingsDashboardLinkAddDelete,
  SettingsDashboardLinkNewContainer,
} from "./SettingsDashboardLinksStyles";

interface IProps {
  path: string;
  canAdd: boolean;
  onAdd(path: string): void;
}

const SettingsDashboardLinkNew: FC<IProps> = ({ path, canAdd, onAdd }) => {
  const { t } = useTranslation();

  const handleOnClick = () => {
    onAdd(path);
  };

  return (
    <SettingsDashboardLinkNewContainer>
      <FontAwesomeIcon icon={getLinkIcon(path)} color={COLORS.textColor} />{" "}
      {getLinkName(t, path)}
      <SettingsDashboardLinkAddDelete
        onClick={handleOnClick}
        icon={faPlus}
        color={COLORS.textColor}
        className={canAdd ? undefined : "hidden"}
      />
    </SettingsDashboardLinkNewContainer>
  );
};

export default SettingsDashboardLinkNew;
