import { AxiosRequestConfig, AxiosResponse } from "axios";
import { IDefaultDocumentDocumentItem } from "../models/defaultDocument";
import { IItemIdName } from "../models/common";
import { IFolderItem } from "../models/document";
import api from "../utils/api";

const getFolderList = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<IFolderItem[]>> =>
  api.get(`/api/Documents/Default/Folders`, config);

const getFolderCount = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<number>> =>
  api.get(`/api/Documents/Default/Folders/Count`, config);

const getFolder = async (
  folderId: string | undefined
): Promise<AxiosResponse<IItemIdName[]>> =>
  api.get(`/api/Documents/Default/Folders/${folderId ?? "0"}/Path`);

const createFolder = async (
  parentId: string,
  data: IFolderItem
): Promise<AxiosResponse<IFolderItem>> => {
  const params = new URLSearchParams();
  if (parentId) {
    params.append("parentId", parentId);
  }
  return api.post(`/api/Documents/Default/Folders`, data, {
    params,
  });
};

const updateFolder = async (
  folderId: string | undefined,
  data: IFolderItem
): Promise<AxiosResponse<IFolderItem>> =>
  api.put(`/api/Documents/Default/Folder/${folderId}`, data);

const deleteFolder = async (folderId: number): Promise<AxiosResponse> =>
  api.delete(`/api/Documents/Default/Folder/${folderId}`);

const getDocumentList = async (
  folderId: string | undefined,
  config: AxiosRequestConfig
): Promise<AxiosResponse<IDefaultDocumentDocumentItem[]>> =>
  api.get(`/api/Documents/Default`, {
    ...config,
    params: { ...config.params, IdFolder: folderId },
  });

const getDocumentCount = async (
  folderId: string | undefined,
  config: AxiosRequestConfig
): Promise<AxiosResponse<number>> =>
  api.get(`/api/Documents/Default/Count`, {
    ...config,
    params: { ...config.params, IdFolder: folderId },
  });

const addDocumentFromTemplate = async (
  folderId: string | undefined,
  templateId: number
): Promise<AxiosResponse<IDefaultDocumentDocumentItem>> =>
  api.post(`/api/Documents/Default`, undefined, {
    params: { folderId, templateId },
  });

const removeDocument = async (
  folderId: string | undefined,
  documentId: number
): Promise<AxiosResponse> =>
  api.delete(
    `/api/Documents/Default/Folder/${folderId ?? "0"}/Document/${documentId}`
  );

const defaultDocumentApi = {
  getFolderList,
  getFolderCount,
  getFolder,
  createFolder,
  updateFolder,
  deleteFolder,

  getDocumentList,
  getDocumentCount,
  addDocumentFromTemplate,
  removeDocument,
};

export default defaultDocumentApi;
