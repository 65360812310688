import { createAction } from "@reduxjs/toolkit";
import { IChangeDemandItem } from "../../models/changeDemads";
import { StoreState, StoreStateDontLoad } from "../storeState";
import { ChangeDemandListTypes, IChangeDemandFilterType } from "./types";

export const changeDemandListFetchRequest = createAction<
  StoreStateDontLoad,
  ChangeDemandListTypes.CHANGE_DEMAND_LIST_FETCH_REQUEST
>(ChangeDemandListTypes.CHANGE_DEMAND_LIST_FETCH_REQUEST);

export const changeDemandListFetchError = createAction<
  boolean,
  ChangeDemandListTypes.CHANGE_DEMAND_LIST_FETCH_ERROR
>(ChangeDemandListTypes.CHANGE_DEMAND_LIST_FETCH_ERROR);

export const changeDemandListFetchSuccess = createAction<
  {
    data: IChangeDemandItem[];
    newCountState: StoreStateDontLoad;
    newCount: number;
  },
  ChangeDemandListTypes.CHANGE_DEMAND_LIST_FETCH_SUCCESS
>(ChangeDemandListTypes.CHANGE_DEMAND_LIST_FETCH_SUCCESS);

export const changeDemandListCountRequest = createAction<
  void,
  ChangeDemandListTypes.CHANGE_DEMAND_LIST_COUNT_REQUEST
>(ChangeDemandListTypes.CHANGE_DEMAND_LIST_COUNT_REQUEST);

export const changeDemandListCountSuccess = createAction<
  number,
  ChangeDemandListTypes.CHANGE_DEMAND_LIST_COUNT_SUCCESS
>(ChangeDemandListTypes.CHANGE_DEMAND_LIST_COUNT_SUCCESS);

export const changeDemandListCountError = createAction<
  boolean,
  ChangeDemandListTypes.CHANGE_DEMAND_LIST_COUNT_ERROR
>(ChangeDemandListTypes.CHANGE_DEMAND_LIST_COUNT_ERROR);

export const changeDemandListReset = createAction<
  void,
  ChangeDemandListTypes.CHANGE_DEMAND_LIST_RESET
>(ChangeDemandListTypes.CHANGE_DEMAND_LIST_RESET);

export const changeDemandListLoad = createAction<
  {
    newState: StoreState;
    newCountState: StoreStateDontLoad;
  },
  ChangeDemandListTypes.CHANGE_DEMAND_LIST_LOAD
>(ChangeDemandListTypes.CHANGE_DEMAND_LIST_LOAD);

export const changeDemandListOrder = createAction<
  {
    orderBy: string;
    orderDesc: boolean;
  },
  ChangeDemandListTypes.CHANGE_DEMAND_LIST_ORDER
>(ChangeDemandListTypes.CHANGE_DEMAND_LIST_ORDER);

export const changeDemandListPage = createAction<
  number,
  ChangeDemandListTypes.CHANGE_DEMAND_LIST_PAGE
>(ChangeDemandListTypes.CHANGE_DEMAND_LIST_PAGE);

export const changeDemandListFilter = createAction<
  IChangeDemandFilterType,
  ChangeDemandListTypes.CHANGE_DEMAND_LIST_FILTER
>(ChangeDemandListTypes.CHANGE_DEMAND_LIST_FILTER);
