import { AppThunkAction, AppThunkActionAsync } from "..";
import * as actions from "./actions";
import defaultDocumentApi from "../../api/defaultDocument";
import {
  IDefaultDocumentDocumentListFilter,
  selectDefaultDocumentDocumentList,
} from ".";
import { CancelToken } from "axios";
import {
  listEffectCountFilter,
  listEffectGetFilter,
  listEffectLoad,
  listEffectReload,
  listEffectsCountCheck,
  listEffectsGetCheck,
  listEffectsGetCount,
  listEffectsGetNew,
  listEffectsGetParams,
} from "../listEffect";

export function getDefaultDocumentDocumentList(
  folderId: string,
  cancelToken: CancelToken
): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectDefaultDocumentDocumentList(getState());
    if (listEffectsGetCheck(state.state)) {
      return;
    }

    let newState = listEffectsGetNew(state);
    dispatch(
      actions.defaultDocumentDocumentListFetchRequest(newState.newCountState)
    );
    try {
      const params = listEffectsGetParams(state, newState.take);
      listEffectGetFilter(params, state.filter);

      const documents = await defaultDocumentApi.getDocumentList(folderId, {
        params,
        cancelToken,
      });
      cancelToken.throwIfRequested();
      newState = listEffectsGetCount(newState, documents);

      dispatch(
        actions.defaultDocumentDocumentListFetchSuccess({
          data: documents.data,
          newCountState: newState.newCountState,
          newCount: newState.newCount,
        })
      );
    } catch {
      dispatch(
        actions.defaultDocumentDocumentListFetchError(
          cancelToken.reason !== undefined
        )
      );
    }
  };
}

export function getDefaultDocumentDocumentListCount(
  folderId: string,
  cancelToken: CancelToken
): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectDefaultDocumentDocumentList(getState());
    if (listEffectsCountCheck(state.countState)) {
      return;
    }

    dispatch(actions.defaultDocumentDocumentListCountRequest());
    try {
      const params = listEffectCountFilter(state.filter);
      const count = await defaultDocumentApi.getDocumentCount(folderId, {
        params,
        cancelToken,
      });
      cancelToken.throwIfRequested();

      dispatch(actions.defaultDocumentDocumentListCountSuccess(count.data));
    } catch {
      dispatch(
        actions.defaultDocumentDocumentListCountError(
          cancelToken.reason !== undefined
        )
      );
    }
  };
}

export function changeDefaultDocumentDocumentListOrder(
  orderBy: string,
  orderDesc: boolean
): AppThunkAction {
  return async (dispatch) => {
    dispatch(
      actions.defaultDocumentDocumentListChangeOrder({ orderBy, orderDesc })
    );
  };
}

export function changeDefaultDocumentDocumentListPage(
  page: number
): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.defaultDocumentDocumentListChangePage(page));
  };
}

export function changeDefaultDocumentDocumentListFilter(
  filter: IDefaultDocumentDocumentListFilter
): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.defaultDocumentDocumentListChangeFilter(filter));
  };
}

export function defaultDocumentDocumentListLoad(
  reload: boolean
): AppThunkAction {
  return async (dispatch, getState) => {
    const state = selectDefaultDocumentDocumentList(getState());
    reload = listEffectReload(reload, state);
    if (reload) {
      dispatch(actions.defaultDocumentDocumentListReset());
    } else {
      const newState = listEffectLoad(state);
      dispatch(
        actions.defaultDocumentDocumentListLoadAction({
          newState: newState.newState,
          newCountState: newState.newCountState,
        })
      );
    }
  };
}
