import { createAction } from "@reduxjs/toolkit";
import { IItemIdName } from "../../models/common";
import { IFilterType } from "../filterType";
import { StoreState, StoreStateDontLoad } from "../storeState";
import { ClientRightGroupListTypes } from "./types";

export const clientRightGroupListFetchRequest = createAction<
  StoreStateDontLoad,
  ClientRightGroupListTypes.CLIENT_RIGHT_GROUP_LIST_FETCH_REQUEST
>(ClientRightGroupListTypes.CLIENT_RIGHT_GROUP_LIST_FETCH_REQUEST);

export const clientRightGroupListFetchSuccess = createAction<
  {
    data: IItemIdName[];
    newCountState: StoreStateDontLoad;
    newCount: number;
  },
  ClientRightGroupListTypes.CLIENT_RIGHT_GROUP_LIST_FETCH_SUCCESS
>(ClientRightGroupListTypes.CLIENT_RIGHT_GROUP_LIST_FETCH_SUCCESS);

export const clientRightGroupListFetchError = createAction<
  boolean,
  ClientRightGroupListTypes.CLIENT_RIGHT_GROUP_LIST_FETCH_ERROR
>(ClientRightGroupListTypes.CLIENT_RIGHT_GROUP_LIST_FETCH_ERROR);

export const clientRightGroupListCountRequest = createAction<
  void,
  ClientRightGroupListTypes.CLIENT_RIGHT_GROUP_LIST_COUNT_REQUEST
>(ClientRightGroupListTypes.CLIENT_RIGHT_GROUP_LIST_COUNT_REQUEST);

export const clientRightGroupListCountSuccess = createAction<
  number,
  ClientRightGroupListTypes.CLIENT_RIGHT_GROUP_LIST_COUNT_SUCCESS
>(ClientRightGroupListTypes.CLIENT_RIGHT_GROUP_LIST_COUNT_SUCCESS);

export const clientRightGroupListCountError = createAction<
  boolean,
  ClientRightGroupListTypes.CLIENT_RIGHT_GROUP_LIST_COUNT_ERROR
>(ClientRightGroupListTypes.CLIENT_RIGHT_GROUP_LIST_COUNT_ERROR);

export const clientRightGroupListChangeOrder = createAction<
  { orderBy: string; orderDesc: boolean },
  ClientRightGroupListTypes.CLIENT_RIGHT_GROUP_LIST_CHANGE_ORDER
>(ClientRightGroupListTypes.CLIENT_RIGHT_GROUP_LIST_CHANGE_ORDER);

export const clientRightGroupListChangePage = createAction<
  number,
  ClientRightGroupListTypes.CLIENT_RIGHT_GROUP_LIST_CHANGE_PAGE
>(ClientRightGroupListTypes.CLIENT_RIGHT_GROUP_LIST_CHANGE_PAGE);

export const clientRightGroupListChangeFilter = createAction<
  IFilterType,
  ClientRightGroupListTypes.CLIENT_RIGHT_GROUP_LIST_CHANGE_FILTER
>(ClientRightGroupListTypes.CLIENT_RIGHT_GROUP_LIST_CHANGE_FILTER);

export const clientRightGroupListReset = createAction<
  void,
  ClientRightGroupListTypes.CLIENT_RIGHT_GROUP_LIST_RESET
>(ClientRightGroupListTypes.CLIENT_RIGHT_GROUP_LIST_RESET);

export const clientRightGroupListLoadAction = createAction<
  { newState: StoreState; newCountState: StoreStateDontLoad },
  ClientRightGroupListTypes.CLIENT_RIGHT_GROUP_LIST_LOAD
>(ClientRightGroupListTypes.CLIENT_RIGHT_GROUP_LIST_LOAD);
