import { AppThunkAction, AppThunkActionAsync } from "..";
import * as actions from "./actions";
import defaultDocumentApi from "../../api/defaultDocument";

export function getDefaultDocumentFolder(
  folderId: string
): AppThunkActionAsync {
  return async (dispatch) => {
    dispatch(actions.defaultDocumentFolderFetchRequest());
    try {
      const folder = await defaultDocumentApi.getFolder(folderId);
      dispatch(actions.defaultDocumentFolderFetchSuccess(folder.data));
    } catch {
      dispatch(actions.defaultDocumentFolderFetchError());
    }
  };
}

export function getDefaulDocumentDefaultFolder(): AppThunkAction {
  return (dispatch) => {
    dispatch(actions.defaultDocumentFolderFetchSuccess([]));
  };
}
