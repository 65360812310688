import { IWorkflowTemplate } from "../../models/workflowTemplate";
import { StoreState } from "../storeState";

export enum WorkflowTemplateTypes {
  WORKFLOW_TEMPLATE_FETCH_REQUEST = "@@workflowTemplate/FETCH_REQUEST",
  WORKFLOW_TEMPLATE_FETCH_SUCCESS = "@@workflowTemplate/FETCH_SUCCESS",
  WORKFLOW_TEMPLATE_FETCH_ERROR = "@@workflowTemplate/FETCH_ERROR",
}

export interface IWorkflowTemplateState {
  state: StoreState;
  data: IWorkflowTemplate | null;
}
