import { AxiosResponse, CancelToken } from "axios";
import { FC, ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import userApi from "../../api/user";
import { RightType } from "../../models/auth";
import { ExportType } from "../../models/common";
import { IUserItem } from "../../models/user";
import { IApplicationState } from "../../store";
import { IFilterType } from "../../store/filterType";
import { selectIdentityRights } from "../../store/identity";
import {
  listEffectGetFilter,
  listEffectsGetParamsForExport,
} from "../../store/listEffect";
import {
  addUserParams,
  changeUserListFilter,
  changeUserListOrder,
  changeUserListPage,
  getUserList,
  getUserListCount,
  IUserListState,
  selectUserList,
  userListLoad,
} from "../../store/userList";
import { hasRight } from "../../utils/rights";
import {
  handleLoadAssistanceSuggestions,
  handleLoadGroupSuggestions,
} from "../../utils/suggestions";
import FilterPanel from "../common/filter/FilterPanel";
import Input from "../common/form/Input";
import Grid from "../common/grid/Grid";
import {
  GridIconAdd,
  GridIconEdit,
  GridIconIsActive,
} from "../common/grid/GridIcons";
import { TableCol, TableColCenter } from "../common/grid/TableCol";
import SuggestionFormik from "../common/suggestion/SuggestionFormik";
import SuggestionListFormik from "../common/suggestion/SuggestionListFormik";

interface IProps {
  prov: IUserListState;
  identityRights?: RightType[];
  getUserList(cancelToken: CancelToken): void;
  getUserListCount(cancelToken: CancelToken): void;
  changeUserListOrder(orderBy: string, orderDesc: boolean): void;
  changeUserListPage(page: number): void;
  changeUserListFilter(filter: IFilterType): void;
  userListLoad(reload: boolean): void;
}

const AdminUser: FC<IProps> = ({
  prov,
  identityRights,
  getUserList,
  getUserListCount,
  changeUserListOrder,
  changeUserListPage,
  changeUserListFilter,
  userListLoad,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    userListLoad(false);
  }, [userListLoad]);

  const handleRenderData = (item: IUserItem): ReactElement => {
    return (
      <>
        <TableColCenter>{item.isActive && <GridIconIsActive />}</TableColCenter>
        <TableCol>{item.title}</TableCol>
        <TableCol>{item.firstName}</TableCol>
        <TableCol>{item.lastName}</TableCol>
        <TableCol>{item.degree}</TableCol>
        <TableCol>{item.assistanceName}</TableCol>
        <TableCol>
          <NavLink to={`/admin/user/${item.id}`}>
            <GridIconEdit />
          </NavLink>
        </TableCol>
      </>
    );
  };

  const handleRowClick = (item: IUserItem) => {
    navigate(`/admin/user/${item.id}`);
  };

  const handleExport = async (
    type: ExportType
  ): Promise<AxiosResponse<Blob>> => {
    const params = listEffectsGetParamsForExport(prov);
    listEffectGetFilter(params, prov.filter);
    addUserParams(params, prov);

    if (type === ExportType.Csv) {
      return await userApi.userListExportCsv(params);
    }

    return await userApi.userListExportXlsx(params);
  };

  return (
    <>
      <FilterPanel
        title={t("admin.user.pageTitle")}
        name="adminUser"
        filter={prov.filter!}
        changeFilter={changeUserListFilter}
        doExport={handleExport}
        initialValues={{
          activeOnly: prov.filter!.activeOnly,
          assistanceId: prov.filter!.assistanceId,
          assistanceName: prov.filter!.assistanceName,
          groups: prov.filter!.groups,
        }}
      >
        <Input
          key="activeOnly"
          name="activeOnly"
          type="checkbox"
          label={t(`admin.user.isActive`)}
          inputWidth="1.5rem"
          inputHeight="1.5rem"
        />
        <SuggestionFormik
          nameId="assistanceId"
          nameText="assistanceName"
          label={t("admin.user.assistance")}
          loadSuggestions={handleLoadAssistanceSuggestions}
        />
        <SuggestionListFormik
          nameList="groups"
          nameId="id"
          nameText="name"
          label={t("admin.user.group")}
          loadSuggestions={handleLoadGroupSuggestions}
        />
      </FilterPanel>
      <Grid<IUserItem>
        headers={[
          {
            captionStr: "admin.user.isActive",
            orderName: "IsActive",
            minContent: true,
          },
          { captionStr: "admin.user.title" },
          { captionStr: "admin.user.firstName", orderName: "FirstName" },
          {
            captionStr: "admin.user.lastName",
            orderName: "LastName",
          },
          { captionStr: "admin.user.degree" },
          { captionStr: "admin.user.assistance", orderName: "Assistance" },
          {
            captionEl: hasRight(identityRights, [RightType.AddUsers]) ? (
              <NavLink to={`/admin/user/new`}>
                <GridIconAdd />
              </NavLink>
            ) : undefined,
          },
        ]}
        renderData={handleRenderData}
        getData={getUserList}
        getCount={getUserListCount}
        changeOrder={changeUserListOrder}
        changePage={changeUserListPage}
        onRowClick={handleRowClick}
        prov={prov}
      />
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    prov: selectUserList(state),
    identityRights: selectIdentityRights(state),
  };
};

const mapDispachToProps = {
  getUserList,
  getUserListCount,
  changeUserListOrder,
  changeUserListPage,
  changeUserListFilter,
  userListLoad,
};

export default connect(mapStateToProps, mapDispachToProps)(AdminUser);
