import { createAction } from "@reduxjs/toolkit";
import { IAssistanceAddressItem } from "../../models/assistance";
import { IFilterType } from "../filterType";
import { StoreState, StoreStateDontLoad } from "../storeState";
import { AssistanceAddressListTypes } from "./types";

export const assistanceAddressListFetchRequest = createAction<
  StoreStateDontLoad,
  AssistanceAddressListTypes.ASSISTANCE_ADDRESS_LIST_FETCH_REQUEST
>(AssistanceAddressListTypes.ASSISTANCE_ADDRESS_LIST_FETCH_REQUEST);

export const assistanceAddressListFetchSuccess = createAction<
  {
    data: IAssistanceAddressItem[];
    newCountState: StoreStateDontLoad;
    newCount: number;
  },
  AssistanceAddressListTypes.ASSISTANCE_ADDRESS_LIST_FETCH_SUCCESS
>(AssistanceAddressListTypes.ASSISTANCE_ADDRESS_LIST_FETCH_SUCCESS);

export const assistanceAddressListFetchError = createAction<
  boolean,
  AssistanceAddressListTypes.ASSISTANCE_ADDRESS_LIST_FETCH_ERROR
>(AssistanceAddressListTypes.ASSISTANCE_ADDRESS_LIST_FETCH_ERROR);

export const assistanceAddressListCountRequest = createAction<
  void,
  AssistanceAddressListTypes.ASSISTANCE_ADDRESS_LIST_COUNT_REQUEST
>(AssistanceAddressListTypes.ASSISTANCE_ADDRESS_LIST_COUNT_REQUEST);

export const assistanceAddressListCountSuccess = createAction<
  number,
  AssistanceAddressListTypes.ASSISTANCE_ADDRESS_LIST_COUNT_SUCCESS
>(AssistanceAddressListTypes.ASSISTANCE_ADDRESS_LIST_COUNT_SUCCESS);

export const assistanceAddressListCountError = createAction<
  boolean,
  AssistanceAddressListTypes.ASSISTANCE_ADDRESS_LIST_COUNT_ERROR
>(AssistanceAddressListTypes.ASSISTANCE_ADDRESS_LIST_COUNT_ERROR);

export const assistanceAddressListChangeOrder = createAction<
  { orderBy: string; orderDesc: boolean },
  AssistanceAddressListTypes.ASSISTANCE_ADDRESS_LIST_CHANGE_ORDER
>(AssistanceAddressListTypes.ASSISTANCE_ADDRESS_LIST_CHANGE_ORDER);

export const assistanceAddressListChangePage = createAction<
  number,
  AssistanceAddressListTypes.ASSISTANCE_ADDRESS_LIST_CHANGE_PAGE
>(AssistanceAddressListTypes.ASSISTANCE_ADDRESS_LIST_CHANGE_PAGE);

export const assistanceAddressListChangeFilter = createAction<
  IFilterType,
  AssistanceAddressListTypes.ASSISTANCE_ADDRESS_LIST_CHANGE_FILTER
>(AssistanceAddressListTypes.ASSISTANCE_ADDRESS_LIST_CHANGE_FILTER);

export const assistanceAddressListReset = createAction<
  void,
  AssistanceAddressListTypes.ASSISTANCE_ADDRESS_LIST_RESET
>(AssistanceAddressListTypes.ASSISTANCE_ADDRESS_LIST_RESET);

export const assistanceAddressListLoadAction = createAction<
  { newState: StoreState; newCountState: StoreStateDontLoad },
  AssistanceAddressListTypes.ASSISTANCE_ADDRESS_LIST_LOAD
>(AssistanceAddressListTypes.ASSISTANCE_ADDRESS_LIST_LOAD);
