import { AppThunkAction, AppThunkActionAsync } from "..";
import * as actions from "./actions";
import demandApi from "../../api/demand";
import { IDemand } from "../../models/demand";

export function getDemand(demandId: number): AppThunkActionAsync {
  return async (dispatch) => {
    dispatch(actions.demandFetchRequest());
    try {
      const demand = await demandApi.getDemand(demandId);
      dispatch(actions.demandFetchSuccess(demand.data));
    } catch {
      dispatch(actions.demandFetchError());
    }
  };
}

export function updateDemand(newData: IDemand): AppThunkAction {
  return (dispatch) => {
    dispatch(actions.demandFetchSuccess(newData));
  };
}
