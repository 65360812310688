import { AxiosResponse } from "axios";
import api from "../utils/api";
import { SignedEnum } from "../models/client";

const sendVerificationSms = async ({
  clientId,
  documentId,
  signInPerson = true,
}: {
  clientId: number;
  documentId: number;
  signInPerson?: boolean;
}): Promise<AxiosResponse<true>> => {
  const url = `/api/DigitalSignature/${clientId}/Document/${documentId}/CreateSmsToSignDocument`;

  const params = new URLSearchParams();

  params.append("signInPerson", `${signInPerson}`);

  const response = await api.post(url, undefined, {
    params,
  });

  return response;
};

const checkCodeValidity = async (
  code: string,
  documentId: number
): Promise<
  AxiosResponse<{
    digitalSignature: string;
    clientId: number;
    documentId: number;
    state: SignedEnum;
  }>
> => {
  const params = new URLSearchParams();

  params.append("code", `${code}`);
  params.append("documentId", `${documentId}`);

  const response = await api.post(
    "/api/DigitalSignature/VerifySmsAndSign",
    undefined,
    { params }
  );

  return response;
};

const sendDocumentIsSignBiometrically = async ({
  clientId,
  docuemntId,
}: {
  clientId: number;
  docuemntId: number;
}): Promise<AxiosResponse<true>> =>
  await api.post(
    `/api/DigitalSignature/${clientId}/Document/${docuemntId}/SignDocumentBiometrically`
  );

const digitalSignatureApi = Object.freeze({
  sendVerificationSms,
  checkCodeValidity,
  sendDocumentIsSignBiometrically,
});

export default digitalSignatureApi;
