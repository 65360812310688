import { addDays } from "date-fns";

export function getClassByDate(date: Date): string {
  const now = new Date();

  if (date < now) {
    return "error";
  }

  if (date < addDays(now, 3)) {
    return "warning";
  }

  return "";
}

export const getDaysDifference = (
  dateString?: string | null
): string | undefined => {
  if (!dateString) return undefined;

  const targetDate = new Date(dateString);
  const currentDate = new Date();
  const differenceInDays = Math.ceil(
    (targetDate.getTime() - currentDate.getTime()) / (1000 * 3600 * 24)
  );

  return (30 - Math.abs(differenceInDays)).toString();
};
