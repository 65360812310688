import { useFormikContext } from "formik";
import { FC, useEffect, useState } from "react";
import { ActionConflictReason, IActionConflict } from "../../models/calendar";
import { IActionForm } from "./CalendarListDetail";
import { TailSpin } from "react-loader-spinner";
import { COLORS } from "../../styles/colors";
import { DATE_TIME_FORMAT, PAGE_SIZE, TIME_FORMAT } from "../../utils/consts";
import { format } from "date-fns";
import { addTimeSlotToDate } from "../../utils/calendar";
import calendarApi from "../../api/calendar";
import { SetState } from "../../utils/types";
import { errorToast } from "../../utils/toasts";
import { useTranslation } from "react-i18next";
import axios from "axios";

interface IAssignUserConflictsProps {
  setIsLoadingConflicts: SetState<boolean>;
  excludedActionId?: string;
}

const AssignUserConflicts: FC<IAssignUserConflictsProps> = ({
  setIsLoadingConflicts,
  excludedActionId,
}) => {
  const { values } = useFormikContext<IActionForm>();

  const { t } = useTranslation();

  const [conflicts, setConflicts] = useState<{
    data: IActionConflict[];
    loading: boolean;
    tooMuch: boolean;
  }>({ loading: false, data: [], tooMuch: false });

  useEffect(() => {
    const token = axios.CancelToken.source();

    (async () => {
      if (
        values.userAssigneeId &&
        values.dateFrom &&
        (values.dateTo || values.once)
      ) {
        const data = {
          idClient: values.clientId ? values.clientId : null,
          idUserAssignee: values.userAssigneeId ? values.userAssigneeId : null,
          idActionListingType: values.typeId,
          description: values.description ? values.description : null,

          from: addTimeSlotToDate(values.dateFrom!, values.timeFrom!),
          to: addTimeSlotToDate(
            values.once ? values.dateFrom! : values.dateTo!,
            values.timeTo!
          ),

          monday: values.once ? false : values.monday,
          tuesday: values.once ? false : values.tuesday,
          wednesday: values.once ? false : values.wednesday,
          thursday: values.once ? false : values.thursday,
          friday: values.once ? false : values.friday,
          saturday: values.once ? false : values.saturday,
          sunday: values.once ? false : values.sunday,
        };

        if (data.from < new Date() || data.to < new Date()) {
          setConflicts({ data: [], loading: false, tooMuch: false });
          setIsLoadingConflicts(false);
          return;
        }

        setIsLoadingConflicts(true);
        setConflicts({ data: [], loading: true, tooMuch: false });

        try {
          const response = await calendarApi.checkAssignAvailability(
            values.userAssigneeId,
            data,
            excludedActionId,
            PAGE_SIZE + 1,
            token.token
          );

          if (response.data.length > PAGE_SIZE) {
            setConflicts({
              data: response.data.slice(0, PAGE_SIZE),
              loading: false,
              tooMuch: true,
            });
          } else {
            setConflicts({
              data: response.data,
              loading: false,
              tooMuch: false,
            });
          }
        } catch {
          if (!token.token.reason) {
            errorToast(t("calendar.assignment.conflicts.errorLoading"));
            setConflicts({ data: [], loading: false, tooMuch: false });
          }
        } finally {
          if (!token.token.reason) {
            setIsLoadingConflicts(false);
          }
        }
      } else {
        setConflicts({ data: [], loading: false, tooMuch: false });
        setIsLoadingConflicts(false);
      }
    })();

    return () => {
      token.cancel();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.userAssigneeId,
    values.once,
    values.dateFrom,
    values.dateTo,
    values.timeFrom,
    values.timeTo,
    values.sunday,
    values.monday,
    values.tuesday,
    values.wednesday,
    values.thursday,
    values.friday,
    values.saturday,
  ]);

  if (!values.userAssigneeId) return null;
  return (
    <>
      {conflicts.data.length > 0 && (
        <p>{t("calendar.assignment.conflicts.title")}</p>
      )}
      {conflicts.loading ? (
        <TailSpin color={COLORS.loaderColor} width={48} height={48} />
      ) : (
        <ul>
          {conflicts.data
            .filter(
              (x) => x.conflictReason !== ActionConflictReason.IsAssignable
            )
            .map((x, index) => (
              <li key={index}>
                {format(x.timeInterval.from, DATE_TIME_FORMAT)}
                {" - "}
                {format(x.timeInterval.to, TIME_FORMAT)}
                {" ("}
                {x.conflictReason === ActionConflictReason.Unavailable &&
                  t("calendar.assignment.conflicts.unavailable")}
                {x.conflictReason === ActionConflictReason.NotWorking &&
                  t("calendar.assignment.conflicts.notWorking")}
                {")"}
              </li>
            ))}
          {conflicts.tooMuch && <li>...</li>}
        </ul>
      )}
    </>
  );
};

export default AssignUserConflicts;
