import {
  DefaultPortModel,
  DiagramEngine,
  NodeModel,
} from "@projectstorm/react-diagrams";
import { formatDistance } from "date-fns";
import { DemandStateType, IDemandHistoryItem } from "../../../models/demand";
import {
  IWorkflowDefinition,
  WorkflowDefinitionItemTopMode,
  WorkflowDefinitionItemType,
} from "../../../models/workflow";
import { DiagramEndNode } from "./DiagramEnd";
import { DiagramMultipleNode } from "./DiagramMultiple";
import { DiagramStartNode } from "./DiagramStart";
import { DiagramTemplateNode } from "./DiagramTemplate";
import { cs as dateCs } from "date-fns/locale";
import { DiagramWorkflowNode } from "./DiagramWorkflow";
import { DiagramWorkflowOutNode } from "./DiagramWorkflowOut";

export const getDefinition = (engine: DiagramEngine) => {
  const newDefinition: Array<IWorkflowDefinition> = [];

  const model = engine.getModel();
  const nodes = model.getNodes();
  const node = nodes.find(
    (x) => x instanceof DiagramStartNode
  ) as DiagramStartNode;

  if (node) {
    const idDict: { [name: string]: number } = {};

    var id = 1;
    for (const item of nodes) {
      idDict[item.getID()] = id;
      id++;

      if (item instanceof DiagramMultipleNode) {
        const multiple = item as DiagramMultipleNode;
        const data = multiple.myData();

        for (const item of data.items) {
          const port = multiple.myPortItem(item.id);
          idDict[port.getID()] = id;
          id++;
        }
      }
    }

    getDefinitionPort(engine, newDefinition, idDict, null, node.myPort());
  }

  return newDefinition;
};

const getDefinitionNode = (
  engine: DiagramEngine,
  newDefinition: IWorkflowDefinition[],
  idDict: { [name: string]: number },
  parentId: number | null,
  parentNode: NodeModel,
  parallerOrder: number
) => {
  if (parentNode instanceof DiagramEndNode) {
    return;
  }

  const id = idDict[parentNode.getID()];

  const existingDefinition = newDefinition.find((x) => x.id === id);
  if (existingDefinition) {
    existingDefinition.parentId.push(parentId);
    return;
  }

  if (parentNode instanceof DiagramMultipleNode) {
    const multiple = parentNode as DiagramMultipleNode;
    const data = multiple.myData();

    newDefinition.push({
      id,
      parentId: [parentId],
      parallerOrder,
      type: data.type,
      text:
        data.type === WorkflowDefinitionItemType.Question ? data.name : null,
      autoType:
        data.type === WorkflowDefinitionItemType.Auto ? data.autoType : null,
      templateId: null,
      templateName: null,
      topPortMode: data.topPortMode,
      nestedWorkflowId: null,
      nestedWorkflowName: null,
    });

    var itemParallerOrder = 0;
    for (const item of data.items) {
      const port = multiple.myPortItem(item.id);
      const portId = idDict[port.getID()];

      newDefinition.push({
        id: portId,
        parentId: [id],
        parallerOrder: itemParallerOrder,
        type: data.type,
        text:
          data.type === WorkflowDefinitionItemType.Question ? item.name : null,
        autoType:
          data.type === WorkflowDefinitionItemType.Auto ? data.autoType : null,
        templateId: null,
        templateName: null,
        topPortMode: data.topPortMode,
        nestedWorkflowId: null,
        nestedWorkflowName: null,
      });

      getDefinitionPort(engine, newDefinition, idDict, portId, port);
      itemParallerOrder++;
    }
  } else if (parentNode instanceof DiagramTemplateNode) {
    const data = parentNode.myData();

    newDefinition.push({
      id,
      parentId: [parentId],
      parallerOrder,
      type: WorkflowDefinitionItemType.Template,
      text: null,
      autoType: null,
      templateId: data.id,
      templateName: data.name,
      topPortMode: data.topPortMode,
      nestedWorkflowId: null,
      nestedWorkflowName: null,
    });

    getDefinitionPort(
      engine,
      newDefinition,
      idDict,
      id,
      parentNode.myPortBottom()
    );
  } else if (parentNode instanceof DiagramWorkflowNode) {
    const data = parentNode.myData();

    newDefinition.push({
      id,
      parentId: [parentId],
      parallerOrder,
      type: WorkflowDefinitionItemType.Workflow,
      text: null,
      autoType: null,
      templateId: null,
      templateName: null,
      topPortMode: data.topPortMode,
      nestedWorkflowId: data.id,
      nestedWorkflowName: data.name,
    });

    getDefinitionPort(
      engine,
      newDefinition,
      idDict,
      id,
      parentNode.myPortBottom()
    );
  } else if (parentNode instanceof DiagramWorkflowOutNode) {
    const data = parentNode.myData();

    newDefinition.push({
      id,
      parentId: [parentId],
      parallerOrder,
      type: WorkflowDefinitionItemType.WorkflowOut,
      text: null,
      autoType: null,
      templateId: null,
      templateName: null,
      topPortMode: data.topPortMode,
      nestedWorkflowId: null,
      nestedWorkflowName: null,
    });
  }
};

export const getDefinitionPort = (
  engine: DiagramEngine,
  newDefinition: IWorkflowDefinition[],
  idDict: { [name: string]: number },
  parentId: number | null,
  parentPort: DefaultPortModel
) => {
  const nodes: Array<NodeModel> = [];
  const links = parentPort.getLinks();
  for (const key of Object.keys(links)) {
    const link = links[key];
    const parent = link.getTargetPort().getParent();
    nodes.push(parent);
  }

  if (nodes.length !== 0) {
    const sorted = nodes.sort((a, b) => a.getPosition().x - b.getPosition().x);

    let i = 0;
    for (const item of sorted) {
      getDefinitionNode(engine, newDefinition, idDict, parentId, item, i);
      i++;
    }
  }
};

export const getHistoryDiffTime = (historyItem: IDemandHistoryItem) => {
  const createdAt = historyItem.createdAt;
  const finishedAt = historyItem.finishedAt ?? new Date();

  const diff = formatDistance(finishedAt, createdAt, {
    includeSeconds: false,
    locale: dateCs,
  });
  return `(${diff})`;
};

export const historyCanShow = (
  item: IWorkflowDefinition,
  definition: IWorkflowDefinition[],
  demands: IDemandHistoryItem[]
) => {
  if (
    item.parentId.length === 0 ||
    (item.parentId.length === 1 && item.parentId[0] === null)
  ) {
    return true;
  }

  for (const parentId of item.parentId) {
    if (parentId === null) {
      if (item.topPortMode === WorkflowDefinitionItemTopMode.AtLeastOne) {
        return true;
      }
    }

    const parentItem = definition.find((x) => x.id === parentId);
    if (!parentItem) {
      continue;
    }

    const parentDemand = demands?.find(
      (x) => x.idWorkflowDefinitionItem === parentItem.id
    );

    let res: boolean;
    if (
      parentItem.type === WorkflowDefinitionItemType.Question ||
      parentItem.type === WorkflowDefinitionItemType.Auto
    ) {
      const parentItemParent = definition.find(
        (x) => x.id === parentItem.parentId[0]
      );
      if (!parentItemParent) {
        continue;
      }

      const parentDemandParent = demands?.find(
        (x) => x.idWorkflowDefinitionItem === parentItemParent.id
      );

      if (parentDemandParent) {
        res = parentDemand?.state === DemandStateType.Finished;
      } else {
        res = historyCanShow(parentItemParent, definition, demands);
      }
    } else {
      if (parentDemand) {
        res = parentDemand.state !== DemandStateType.Canceled;
      } else {
        res = historyCanShow(parentItem, definition, demands);
      }
    }

    if (res) {
      if (item.topPortMode === WorkflowDefinitionItemTopMode.AtLeastOne) {
        return true;
      }
    } else {
      if (item.topPortMode === WorkflowDefinitionItemTopMode.AllPaths) {
        return false;
      }
    }
  }

  if (item.topPortMode === WorkflowDefinitionItemTopMode.AtLeastOne) {
    return false;
  } else {
    return true;
  }
};
