import styled from "styled-components";
import { InputRow } from "../../../styles/form";
import { SIZES } from "../../../styles/sizes";

export const MultiCheckInputRow = styled(InputRow)`
  @media screen and (min-width: ${SIZES.mobile}) {
    align-items: start;
  }
`;

export const MultiCheckInputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
`;
