import { AxiosRequestConfig, AxiosResponse } from "axios";
import { IItemIdName } from "../models/common";
import { IUser, IUserItem } from "../models/user";
import api from "../utils/api";

const getList = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<IUserItem[]>> => api.get(`/api/User/List`, config);

const getCount = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<number>> => api.get(`/api/User/Count`, config);

const userListExportCsv = (
  params: URLSearchParams
): Promise<AxiosResponse<Blob>> =>
  api.get(`/api/User/Export/Csv`, { params, responseType: "blob" });

const userListExportXlsx = (
  params: URLSearchParams
): Promise<AxiosResponse<Blob>> =>
  api.get(`/api/User/Export/Xlsx`, { params, responseType: "blob" });

const getSuggestionList = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<IItemIdName[]>> =>
  api.get(`/api/User/Suggested/List`, config);

const getUser = async (userId: number): Promise<AxiosResponse<IUser>> =>
  api.get(`/api/User/${userId}`);

const updateUser = async (user: IUser): Promise<AxiosResponse<IUser>> =>
  api.put(`/api/User/${user.id}`, user);

const createUser = async (user: IUser): Promise<AxiosResponse<IUser>> =>
  api.post(`/api/User`, user);

const userApi = {
  getList,
  getCount,
  userListExportCsv,
  userListExportXlsx,
  getSuggestionList,
  getUser,
  updateUser,
  createUser,
};

export default userApi;
