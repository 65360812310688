import React, { FC } from "react";
import {
  AbstractModelFactory,
  AbstractReactFactory,
  GenerateWidgetEvent,
} from "@projectstorm/react-canvas-core";
import {
  DiagramEngine,
  LinkModel,
  NodeModel,
  NodeModelGenerics,
  PortModelAlignment,
} from "@projectstorm/react-diagrams-core";
import {
  DefaultLinkModel,
  DefaultPortModel,
  RightAngleLinkModel,
} from "@projectstorm/react-diagrams";
import { useTranslation } from "react-i18next";
import {
  DiagramStartEndWrapper,
  PortWidgetWrapperTop,
  StyledPortWidget,
} from "./DiagramStyles";
import { store } from "../../../store";
import { selectDiagramSettings } from "../../../store/diagramSettings";

const NAME = "end";

class Factory extends AbstractReactFactory<DiagramEndNode, DiagramEngine> {
  constructor() {
    super(NAME);
  }

  generateReactWidget(event: GenerateWidgetEvent<DiagramEndNode>): JSX.Element {
    return <Widget engine={this.engine} node={event.model} />;
  }

  generateModel() {
    return new DiagramEndNode({});
  }
}

interface NodeProps {
  disableClick?: boolean;
  notFound?: boolean;
  isLocked?: boolean;
}

export class DiagramEndNode extends NodeModel<NodeModelGenerics> {
  constructor(data: NodeProps) {
    super({
      type: NAME,
      extras: data,
    });
    this.addPort(new Port());
    this.setLocked(data.isLocked);
  }

  myPort() {
    return this.getPort(PortModelAlignment.TOP) as Port;
  }

  myData() {
    return this.options.extras as NodeProps;
  }
}

interface WidgetProps {
  node: DiagramEndNode;
  engine: DiagramEngine;
}

const Widget: FC<WidgetProps> = ({ node, engine }) => {
  const { t } = useTranslation();
  const port = node.myPort();
  const data = node.myData();

  return (
    <DiagramStartEndWrapper
      highlight={false}
      canClick={!data.disableClick}
      notFound={!!data.notFound}
    >
      <PortWidgetWrapperTop>
        <StyledPortWidget port={port} engine={engine} />
      </PortWidgetWrapperTop>
      {t("diagram.end")}
    </DiagramStartEndWrapper>
  );
};

class Port extends DefaultPortModel {
  constructor() {
    super({
      type: NAME,
      name: PortModelAlignment.TOP,
      alignment: PortModelAlignment.TOP,
      in: true,
    });
  }

  createLinkModel(factory?: AbstractModelFactory<LinkModel>): LinkModel {
    if (factory) {
      return factory.generateModel({});
    }

    const diagramSettings = selectDiagramSettings(store.getState());
    if (diagramSettings.linkCurved) {
      return new DefaultLinkModel();
    }

    return new RightAngleLinkModel();
  }
}

export const DiagramEndRegister = (engine: DiagramEngine) => {
  engine.getNodeFactories().registerFactory(new Factory());
};
