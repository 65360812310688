import { IItemIdName } from "./common";

export enum AssistanceStateType {
  New = "new",
  Active = "active",
  InActive = "inActive",
}

export interface IAssistanceItem {
  id: number;
  state: AssistanceStateType;
  name: string;
}

export interface IAssistance {
  id: number;
  state: AssistanceStateType;
  isInternal: boolean;
  name: string;
  street: string;
  houseNumber: number;
  landRegistryNumber: number;
  city: string;
  postCode: number;

  mobilePhone?: string | null;
  email?: string | null;
  phone?: string | null;

  companyIdentificationNumber?: string | null;
  taxIdentificationNumber?: string | null;
  dataBox?: string | null;
  webLink?: string | null;

  isRegistered: boolean;
  registrationNo?: string | null;
  conditions?: string | null;
  description?: string | null;
}

export interface IAssistancePersonItem {
  id: number;
  type: IItemIdName;
  title?: string | null;
  firstName: string;
  lastName: string;
  degree?: string | null;
}

export interface IAssistancePerson {
  id: number;
  type: IItemIdName;
  title?: string | null;
  firstName: string;
  lastName: string;
  degree?: string | null;

  mobilePhone?: string | null;
  phone?: string | null;
  email?: string | null;
}

export interface IAssistanceAddressItem {
  id: number;
  name: string;

  street: string;
  houseNumber: number;
  landRegistryNumber: number;
  city: string;
}

export interface IAssistanceAddress {
  id: number;
  name: string;
  street: string;
  houseNumber: number;
  landRegistryNumber: number;
  city: string;
  postCode: number;

  mobilePhone?: string | null;
  email?: string | null;
  webLink?: string | null;
  phone?: string | null;

  latitude: number;
  longitude: number;

  openMonday: string;
  openTuesday: string;
  openWednesday: string;
  openThursday: string;
  openFriday: string;
  openSaturday: string;
  openSunday: string;

  careTypeList: IItemIdName[];
  targetGroupList: IItemIdName[];
  hasWritePermission?: boolean;
}

export interface IAssistanceDocumentItem {
  id: number;
  name: string;
  author: IItemIdName;
  createdAt: Date;
  lastEditedAt: Date;
  lastEditor: IItemIdName;
}
