import { FC } from "react";
import DashboardWidgetCalendarList from "./DashboardWidgetCalendarList";
import { DashboardWidgetContainer } from "./DashboardWidgetsStyles";
import DashboardWidgetDemands from "./DashboardWidgetDemands";
import DashboardWidgetDemandChange from "./DashboardWidgetDemandChange";

const DashboardWidgets: FC = () => {
  return (
    <DashboardWidgetContainer>
      <DashboardWidgetDemands />
      <DashboardWidgetDemandChange />
      <DashboardWidgetCalendarList />
    </DashboardWidgetContainer>
  );
};

export default DashboardWidgets;
