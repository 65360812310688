import * as ReselectModule from "reselect";
import { IApplicationState } from "..";
import { IWorkflow } from "../../models/workflow";
import { StoreState } from "../storeState";

export const selectWorkflowState: ReselectModule.Selector<
  IApplicationState,
  StoreState
> = (state) => state.workflow.state;

export const selectWorkflow: ReselectModule.Selector<
  IApplicationState,
  IWorkflow | null
> = (state) => state.workflow.data;
