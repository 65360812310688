import { Form, Formik } from "formik";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  ClientHouseLivingType,
  ClientHouseOwnershipType,
  IClientHouse,
} from "../../models/client";
import { IApplicationState } from "../../store";
import { selectClientId } from "../../store/client";
import { ApiError, FormGroup } from "../../styles/form";
import Input from "../common/form/Input";
import * as Yup from "yup";
import clientApi from "../../api/client";
import { RightType } from "../../models/auth";
import { selectIdentityRights } from "../../store/identity";
import { hasClientWriteRight } from "../../utils/rights";
import { StoreState } from "../../store/storeState";
import Loader from "../common/Loader";
import {
  getClientHouse,
  selectClientHouse,
  selectClientHouseState,
  updateClientHouse,
} from "../../store/clientHouse";
import { promiseToastSave } from "../../utils/toasts";
import SubmitForm from "../common/form/SubmitForm";
import validations from "../../utils/validations";
import { errorSet } from "../../utils/error";

interface IClientHouseForm
  extends Omit<
    IClientHouse,
    "floorNumber" | "personsInHousehold" | "livingLength" | "costPerMonth"
  > {
  floorNumber: string;
  personsInHousehold: string;
  livingLength: string;
  costPerMonth: string;
}

interface IProps {
  clientHouseState: StoreState;
  clientHouse: IClientHouse | null;
  clientId?: number;
  identityRights?: RightType[];
  getClientHouse(clientId: number): void;
  updateClientHouse(newData: IClientHouse): void;
}

const fields: Array<{ name: keyof IClientHouse; maxLen?: number }> = [
  { name: "floorNumber" },
  { name: "conditions", maxLen: 512 },
  { name: "barriers", maxLen: 256 },
  { name: "personsInHousehold" },
  { name: "livingLength" },
  { name: "costPerMonth" },
];

const numberFields: Array<keyof IClientHouse> = [
  "floorNumber",
  "personsInHousehold",
  "livingLength",
  "costPerMonth",
];

const ClientHouse: FC<IProps> = ({
  clientHouseState,
  clientHouse,
  clientId,
  identityRights,
  getClientHouse,
  updateClientHouse,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getClientHouse(clientId!);
    setLoading(false);
  }, [clientId, getClientHouse]);

  const handleSubmit = async (data: IClientHouseForm) => {
    setError(null);
    try {
      await promiseToastSave(async () => {
        const data2 = {
          ...data,
          floorNumber: +data.floorNumber,
          personsInHousehold: +data.personsInHousehold,
          livingLength: +data.livingLength,
          costPerMonth: +data.costPerMonth,
        };

        const newData = await clientApi.updateClientHouse(clientId!, data2);
        updateClientHouse(newData.data);
      });
    } catch (err) {
      errorSet(setError, err, t);
    }
  };

  if (loading || clientHouseState === StoreState.Loading) {
    return <Loader />;
  }

  if (clientHouseState === StoreState.Error) {
    return t("errors.unknown");
  }

  return (
    <Formik<IClientHouseForm>
      initialValues={{
        ...clientHouse!,
        livingType: clientHouse!.livingType ?? "",
        ownershipType: clientHouse!.ownershipType ?? "",
        floorNumber: clientHouse!.floorNumber
          ? clientHouse!.floorNumber.toString()
          : "",
        conditions: clientHouse!.conditions ?? "",
        barriers: clientHouse!.barriers ?? "",
        personsInHousehold: clientHouse!.personsInHousehold
          ? clientHouse!.personsInHousehold.toString()
          : "",
        livingLength: clientHouse!.livingLength
          ? clientHouse!.livingLength.toString()
          : "",
        costPerMonth: clientHouse!.costPerMonth
          ? clientHouse!.costPerMonth.toString()
          : "",
        termination: clientHouse!.termination ?? false,
      }}
      validationSchema={Yup.object({
        floorNumber: validations.intOptionalMinMax(0, 99, t),
        personsInHousehold: validations.intOptionalMinMax(0, 99, t),
        livingLength: validations.intOptionalMinMax(0, 999999, t),
        costPerMonth: validations.numberOptionalMinMax(0, 999999, t),
      })}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, isSubmitting }) => (
        <Form>
          <FormGroup>
            <Input
              key="livingType"
              name="livingType"
              label={t("client.house.livingType")}
              error={touched.livingType && !!errors.livingType}
              component="select"
            >
              <option value="">{t("client.house.livingTypes.null")}</option>
              {(
                Object.keys(ClientHouseLivingType) as Array<
                  keyof typeof ClientHouseLivingType
                >
              ).map((key) => (
                <option key={key} value={ClientHouseLivingType[key]}>
                  {t("client.house.livingTypes." + ClientHouseLivingType[key])}
                </option>
              ))}
            </Input>
            <Input
              key="ownershipType"
              name="ownershipType"
              label={t("client.house.ownershipType")}
              error={touched.ownershipType && !!errors.ownershipType}
              component="select"
            >
              <option value="">{t("client.house.ownershipTypes.null")}</option>
              {(
                Object.keys(ClientHouseOwnershipType) as Array<
                  keyof typeof ClientHouseOwnershipType
                >
              ).map((key) => (
                <option key={key} value={ClientHouseOwnershipType[key]}>
                  {t(
                    "client.house.ownershipTypes." +
                      ClientHouseOwnershipType[key]
                  )}
                </option>
              ))}
            </Input>
            {fields.map((f) => (
              <Input
                key={f.name}
                name={f.name}
                type={
                  numberFields.find((x) => x === f.name) ? "number" : "text"
                }
                label={t("client.house." + f.name)}
                error={touched[f.name] && !!errors[f.name]}
                maxLength={f.maxLen}
              />
            ))}
            <Input
              key="termination"
              name="termination"
              type="checkbox"
              label={t("client.house.termination")}
              error={touched.termination && !!errors.termination}
              inputWidth="1.5rem"
              inputHeight="1.5rem"
            />
          </FormGroup>
          {error && <ApiError>{error}</ApiError>}
          {hasClientWriteRight(identityRights, [
            RightType.WriteClientHousingSituation,
          ]) && <SubmitForm />}
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    clientHouseState: selectClientHouseState(state),
    clientHouse: selectClientHouse(state),
    clientId: selectClientId(state),
    identityRights: selectIdentityRights(state),
  };
};

const mapDispachToProps = {
  getClientHouse,
  updateClientHouse,
};

export default connect(mapStateToProps, mapDispachToProps)(ClientHouse);
