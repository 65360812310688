import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { fadeIn } from "../../../styles/effects";
import { SIZES } from "../../../styles/sizes";

export const CalendarHeader = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-gap: 1rem;
  margin: 1rem 0;

  @media screen and (min-width: ${SIZES.tablet}) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

export const CalendarContent = styled.div`
  display: flex;
  animation: ${fadeIn} 0.15s ease-in-out;
`;

export const CalendarTimesCol = styled.div`
  padding-top: 5rem;
  padding-right: 0.5rem;
`;

export const CalendarTimeCell = styled.div`
  height: 3rem;
  user-select: none;
`;

export const CalendarTime = styled.div`
  transform: translateY(-50%);
`;

export const CalendarGridWrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const CalendarGrid = styled.div`
  display: flex;
  align-items: flex-end;
  overflow-x: auto;
  overflow-y: hidden;
  margin: -0.25rem;
`;

export const CalendarCell = styled.div<{ joinCount: number }>`
  position: relative;
  height: ${({ joinCount }) => joinCount * 3}rem;
  border-top: 1px solid var(--calendar-border);
  border-right: 1px solid var(--calendar-border);
  border-left: 1px solid var(--calendar-border);

  &:last-child {
    border-bottom: 1px solid var(--calendar-border);
  }
`;

export const CalendarCellEventWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: -0.25rem;
  padding: 0.5rem;
  user-select: none;
`;

export const CalendarCellEvent = styled.div`
  width: 100%;
  margin: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CalendarCellEventAvailable = styled(CalendarCellEvent)`
  color: var(--calendar-available-color);
  background-color: var(--calendar-available-bg);
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: var(--calendar-available-hover);
  }
`;

export const CalendarCellEventUnavailable = styled(CalendarCellEvent)`
  color: var(--calendar-unavailable-color);
  background-color: var(--calendar-unavailable-bg);
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: var(--calendar-unavailable-hover);
  }
`;

export const CalendarCellEventClosed = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--disabled-bg);
`;

export const CalendarCellEventText = styled.div`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 0.85rem;
  animation: ${fadeIn} 0.3s ease-in-out;
`;

export const CalendarCellEventIcon = styled(FontAwesomeIcon)`
  margin-right: 0.25rem;
`;

export const CalendarDayContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% / 7);
  min-width: 8rem;
  margin: 0.25rem;
`;

export const CalendarDayLabel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  height: 4.5rem;
  padding: 0.25rem;
  margin-bottom: 0.5rem;
`;

export const CalendarTitleLabel = styled.span`
  text-align: center;
  font-weight: bold;
  padding: 0.25rem;
  margin-bottom: 0.5rem;
`;

export const CalendarDayNumber = styled.div<{ isSelected?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  width: 2.5rem;
  font-size: 1.6rem;
  border-radius: 50%;
  ${({ isSelected }) =>
    isSelected &&
    `background-color: var(--calendar-actual-day-bg); color: var(--background);`}
`;

export const CalendarDateSelectorContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 2.5rem;
  padding: 0.5rem;
  border-radius: 3px;
  border: 1px solid #b0b0b0;
  font-weight: bold;
  user-select: none;

  @media screen and (min-width: ${SIZES.tablet}) {
    width: 14rem;
  }
`;

export const CalendarDateSelectorText = styled.div`
  cursor: pointer;
`;

export const CalendarDateSelectorIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: var(--calendar-buttons-bg);
  height: 100%;
  padding: 0 0.5rem;
`;
