import { Form, Formik } from "formik";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router";
import {
  ClientStateType,
  IClient,
  IClientContactPerson,
} from "../../models/client";
import { IApplicationState } from "../../store";
import { StoreState } from "../../store/storeState";
import {
  getDefaultClientContactPerson,
  getClientContactPerson,
  selectClientContactPerson,
  selectClientContactPersonState,
} from "../../store/clientContactPerson";
import { FormButton } from "../../styles/button";
import { ApiError, FormGroup } from "../../styles/form";
import Input from "../common/form/Input";
import PhoneInput from "../common/form/PhoneInput";
import Loader from "../common/Loader";
import * as Yup from "yup";
import { clientContactPersonListLoad } from "../../store/clientContactPersonList";
import clientApi from "../../api/client";
import { SpaceBetweenButtons } from "../../styles/spaces";
import validations from "../../utils/validations";
import { selectClient } from "../../store/client";
import { RightType } from "../../models/auth";
import { hasClientWriteRight } from "../../utils/rights";
import { selectIdentityRights } from "../../store/identity";
import SuggestionFormik from "../common/suggestion/SuggestionFormik";
import { handleLoadClientContactPersonListingSuggestions } from "../../utils/suggestions";
import { promiseToast, promiseToastSave } from "../../utils/toasts";
import SubmitForm from "../common/form/SubmitForm";
import authApi from "../../api/auth";
import { errorSet } from "../../utils/error";

interface IClientContactPersonForm extends IClientContactPerson {
  hasLogin: boolean;
  typeId: number;
  typeName: string;
}

const fields: Array<{ name: keyof IClientContactPerson; maxLen?: number }> = [
  { name: "title", maxLen: 100 },
  { name: "firstName", maxLen: 100 },
  { name: "lastName", maxLen: 100 },
  { name: "degree", maxLen: 100 },
];

interface IProps {
  client: IClient | null;
  clientContactPersonState: StoreState;
  clientContactPerson: IClientContactPerson | null;
  identityRights?: RightType[];
  getClientContactPerson(clientContactPersonId: number): void;
  getDefaultClientContactPerson(): void;
  clientContactPersonListLoad(reload: boolean): void;
}

const ClientPersonDetail: FC<IProps> = ({
  client,
  clientContactPersonState,
  clientContactPerson,
  identityRights,
  getClientContactPerson,
  getDefaultClientContactPerson,
  clientContactPersonListLoad,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { clientId: paramClientId, personId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [sendPasswordInProgress, setSendPasswordInProgress] = useState(false);

  useEffect(() => {
    if (personId === "new") {
      getDefaultClientContactPerson();
    } else {
      getClientContactPerson(parseInt(personId!));
    }
    setLoading(false);
  }, [personId, getClientContactPerson, getDefaultClientContactPerson]);

  const handleSubmit = async (data: IClientContactPersonForm) => {
    setError(null);
    try {
      await promiseToastSave(async () => {
        const data2 = {
          ...data,
          type: { id: data.typeId, name: data.typeName },
          login: data.hasLogin ? data.email : null,
        };

        if (personId === "new") {
          await clientApi.createClientContactPerson(client!.id, data2);
        } else {
          await clientApi.updateClientContactPerson(data2);
        }
        clientContactPersonListLoad(true);
        navigate(`/client/${paramClientId}/person`);
      });
    } catch (err) {
      errorSet(setError, err, t);
    }
  };

  const handleCancel = () => {
    navigate(`/client/${paramClientId}/person`);
  };

  const handleSendPassword = async () => {
    setError(null);
    setSendPasswordInProgress(true);
    try {
      await promiseToast(
        async () => {
          await authApi.sendPublicPasswordResetLink({
            email: clientContactPerson!.email!,
          });
        },
        t("sendPassword.pending"),
        t("sendPassword.success"),
        t("errors.unknown")
      );
    } catch (err) {
      errorSet(setError, err, t);
    }
    setSendPasswordInProgress(false);
  };

  if (loading || clientContactPersonState === StoreState.Loading) {
    return <Loader />;
  }

  if (clientContactPersonState === StoreState.Error) {
    return t("errors.unknown");
  }

  return (
    <Formik<IClientContactPersonForm>
      initialValues={{
        ...clientContactPerson!,
        hasLogin: !!clientContactPerson!.login,
        typeId: clientContactPerson!.type.id,
        typeName: clientContactPerson!.type.name,
        title: clientContactPerson!.title ?? "",
        degree: clientContactPerson!.title ?? "",
        mobilePhone: clientContactPerson!.mobilePhone ?? "",
        email: clientContactPerson!.email ?? "",
        phone: clientContactPerson!.phone ?? "",
      }}
      validationSchema={Yup.object().shape(
        {
          typeId: validations.idRequired(t),
          firstName: validations.stringRequired(t),
          lastName: validations.stringRequired(t),

          mobilePhone: validations.phoneOptional(t).when(["email", "phone"], {
            is: (email: any, phone: any) => !email && !phone,
            then: validations
              .phoneOptional(t)
              .required(t("client.person.anyOfValuesRequired")),
          }),
          email: validations
            .emailOptional(t)
            .when(["mobilePhone", "phone"], {
              is: (mobilePhone: any, phone: any) => !mobilePhone && !phone,
              then: validations
                .emailOptional(t)
                .required(t("client.person.anyOfValuesRequired")),
            })
            .when(["hasLogin"], {
              is: (hasLogin: any) => hasLogin,
              then: Yup.string().required(t("client.person.emailLoginError")),
            }),
          phone: validations.phoneOptional(t).when(["mobilePhone", "email"], {
            is: (mobilePhone: any, email: any) => !mobilePhone && !email,
            then: validations
              .phoneOptional(t)
              .required(t("client.person.anyOfValuesRequired")),
          }),
        },
        [
          ["mobilePhone", "email"],
          ["mobilePhone", "phone"],
          ["email", "phone"],
        ]
      )}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, isSubmitting, values, isValid }) => (
        <Form>
          <h1>{t("client.person.detailTitle")}</h1>
          <FormGroup>
            <Input
              key="isActive"
              name="isActive"
              type="checkbox"
              label={t(`client.person.isActive`)}
              inputWidth="1.5rem"
              inputHeight="1.5rem"
            />
            <SuggestionFormik
              nameId="typeId"
              nameText="typeName"
              label={t("client.person.type")}
              loadSuggestions={handleLoadClientContactPersonListingSuggestions}
            />
            {fields.map((f) => (
              <Input
                key={f.name}
                name={f.name}
                label={t("client.person." + f.name)}
                error={touched[f.name] && !!errors[f.name]}
                maxLength={f.maxLen}
              />
            ))}
            <PhoneInput
              name="mobilePhone"
              label={t("client.person.mobilePhone")}
              error={touched.mobilePhone && !!errors.mobilePhone}
            />
            <PhoneInput
              name="phone"
              label={t("client.person.phone")}
              error={touched.phone && !!errors.phone}
            />
            <Input
              name="email"
              label={t("client.person.email")}
              error={touched.email && !!errors.email}
              maxLength={255}
            />
            <Input
              key="hasLogin"
              name="hasLogin"
              type="checkbox"
              label={t(`client.person.hasLogin`)}
              inputWidth="1.5rem"
              inputHeight="1.5rem"
            />
            {values.hasLogin && (
              <Input
                key="login"
                name="email"
                label={t("client.person.login")}
                disabled={true}
              />
            )}
          </FormGroup>
          {error && <ApiError>{error}</ApiError>}
          <FormButton
            ver="secondary"
            disabled={isSubmitting || sendPasswordInProgress}
            onClick={handleCancel}
          >
            {t("common.back")}
          </FormButton>
          {hasClientWriteRight(identityRights, [
            RightType.WriteClientContactPersons,
          ]) && (
            <>
              <SpaceBetweenButtons />
              <SubmitForm disabled={sendPasswordInProgress} />
              {personId !== "new" && values.hasLogin && (
                <>
                  <SpaceBetweenButtons />
                  <FormButton
                    ver="secondary"
                    type="button"
                    disabled={
                      isSubmitting ||
                      sendPasswordInProgress ||
                      !isValid ||
                      client!.state !== ClientStateType.Active ||
                      values.email !== clientContactPerson!.email ||
                      values.email !== clientContactPerson!.login
                    }
                    onClick={handleSendPassword}
                  >
                    {t("sendPassword.button")}
                  </FormButton>
                </>
              )}
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    client: selectClient(state),
    clientContactPersonState: selectClientContactPersonState(state),
    clientContactPerson: selectClientContactPerson(state),
    identityRights: selectIdentityRights(state),
  };
};

const mapDispachToProps = {
  getClientContactPerson,
  getDefaultClientContactPerson,
  clientContactPersonListLoad,
};

export default connect(mapStateToProps, mapDispachToProps)(ClientPersonDetail);
