import React, { ReactNode, useState } from "react";
import { FC } from "react";
import { ISidebarLink } from "../../models/common";
import Header from "./Header";
import LayoutContext from "./LayoutContext";

const Layout: FC = ({ children }) => {
  const [sidebarTitle, setSidebarTitle] = useState<
    string | ReactNode | undefined
  >();
  const [sidebarLinks, setSidebarLinks] = useState<
    ISidebarLink[] | undefined
  >();

  return (
    <LayoutContext.Provider value={{ setSidebarLinks, setSidebarTitle }}>
      <Header sidebarTitle={sidebarTitle} sidebarLinks={sidebarLinks} />
      {children}
    </LayoutContext.Provider>
  );
};

export default Layout;
