import { IDashboardLink } from "../../models/dashboard";
import { StoreState } from "../storeState";

export enum DashboardLinkTypes {
  DASHBOARD_LINK_FETCH_REQUEST = "@@dashboardLink/FETCH_REQUEST",
  DASHBOARD_LINK_FETCH_SUCCESS = "@@dashboardLink/FETCH_SUCCESS",
  DASHBOARD_LINK_FETCH_ERROR = "@@dashboardLink/FETCH_ERROR",
}

export interface IDashboardLinkState {
  state: StoreState;
  data: IDashboardLink[];
}
