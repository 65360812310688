import { createAction } from "@reduxjs/toolkit";
import { ICareType } from "../../models/careType";
import { CareTypeTypes } from "./types";

export const careTypeFetchRequest = createAction<
  void,
  CareTypeTypes.CARE_TYPE_FETCH_REQUEST
>(CareTypeTypes.CARE_TYPE_FETCH_REQUEST);

export const careTypeFetchSuccess = createAction<
  ICareType,
  CareTypeTypes.CARE_TYPE_FETCH_SUCCESS
>(CareTypeTypes.CARE_TYPE_FETCH_SUCCESS);

export const careTypeFetchError = createAction<
  void,
  CareTypeTypes.CARE_TYPE_FETCH_ERROR
>(CareTypeTypes.CARE_TYPE_FETCH_ERROR);
