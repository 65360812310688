import { IUser } from "../../models/user";
import { StoreState } from "../storeState";

export enum UserTypes {
  USER_FETCH_REQUEST = "@@user/FETCH_REQUEST",
  USER_FETCH_SUCCESS = "@@user/FETCH_SUCCESS",
  USER_FETCH_ERROR = "@@user/FETCH_ERROR",
}

export interface IUserState {
  state: StoreState;
  data: IUser | null;
}
