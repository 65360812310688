import { useFormikContext } from "formik";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { handleLoadUserSuggestions } from "../../utils/suggestions";
import DatePicker from "../common/form/DatePicker";
import Input from "../common/form/Input";
import RequiredMark from "../common/form/RequiredMark";
import SuggestionFormik from "../common/suggestion/SuggestionFormik";
import {
  CalendarModalDayField,
  CalendarModalDays,
  CalendarModalDayText,
  CalendarModalTimes,
  CalendarModalTimesContainer,
  CalendarModalTimesLabel,
} from "./CalendarStyles";
import CalendarTimes from "./CalendarTimes";

export interface IFormData {
  userId: number;
  userName: string;
  once: boolean;
  dateFrom: Date;
  dateTo: Date;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
  timeFrom: string;
  timeTo: string;
}

const dayFields = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

interface IProps {
  canEditUser: boolean;
  isOpen: boolean;
}

const CalendarModalContent: FC<IProps> = ({ canEditUser, isOpen }) => {
  const { t } = useTranslation();

  const { values, errors, resetForm } =
    useFormikContext<IFormData>();


  useEffect(() => {
    resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <>
      {canEditUser && (
        <SuggestionFormik
          nameId="userId"
          nameText="userName"
          label={t("calendar.modal.user")}
          loadSuggestions={handleLoadUserSuggestions}
        />
      )}
      <Input
        key="once"
        name="once"
        type="checkbox"
        label={t(`calendar.modal.once`)}
        inputWidth="1.5rem"
        inputHeight="1.5rem"
      />
      <CalendarModalTimesContainer>
        <label>{t("calendar.modal.date")}</label>
        <CalendarModalTimes>
          <div>
            <CalendarModalTimesLabel>
              {t("common.from")}
              {errors.dateFrom && <RequiredMark />}
            </CalendarModalTimesLabel>
            <DatePicker name={"dateFrom"} />
          </div>
          {!values.once && (
            <div>
              <CalendarModalTimesLabel>
                {t("common.to")}
                {errors.dateTo && <RequiredMark />}
              </CalendarModalTimesLabel>
              <DatePicker name={"dateTo"} />
            </div>
          )}
        </CalendarModalTimes>
      </CalendarModalTimesContainer>
      {!values.once && (
        <CalendarModalDays>
          {dayFields.map((d) => (
            <label key={d}>
              <CalendarModalDayField name={d} type="checkbox" />
              <CalendarModalDayText>
                {t(`common.days.${d}`)}
              </CalendarModalDayText>
            </label>
          ))}
        </CalendarModalDays>
      )}
      <CalendarTimes />
    </>
  );
};

export default CalendarModalContent;
