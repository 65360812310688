import { createReducer } from "@reduxjs/toolkit";
import { IAssistanceListState } from "./types";
import * as actions from "./actions";
import { StoreState, StoreStateDontLoad } from "../storeState";
import { STORAGE_RESET } from "../storeReset";
import { AssistanceStateType } from "../../models/assistance";

const initialState = {
  state: StoreState.None,
  data: [],
  countState: StoreStateDontLoad.DontLoad,
  count: 0,
  page: 0,
  orderBy: "Name",
  orderDesc: false,
  filter: {
    search: undefined,
    isOpen: false,
    state: AssistanceStateType.Active,
    assistanceCategoryId: 0,
    assistanceCategoryName: "",
    careTypeId: 0,
    careTypeName: "",
    targetGroupId: 0,
    targetGroupName: "",
  },
} as IAssistanceListState;

export const assistanceListReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(actions.assistanceListFetchRequest, (state, action) => ({
      ...state,
      state: StoreState.Loading,
      countState: action.payload,
    }))
    .addCase(actions.assistanceListFetchSuccess, (state, action) => ({
      ...state,
      state: StoreState.Loaded,
      lastLoaded: new Date(),
      data: action.payload.data,
      countState: action.payload.newCountState,
      count: action.payload.newCount,
    }))
    .addCase(actions.assistanceListFetchError, (state, action) => ({
      ...state,
      state: action.payload ? StoreState.Cancel : StoreState.Error,
    }))
    .addCase(actions.assistanceListCountRequest, (state) => ({
      ...state,
      countState: StoreStateDontLoad.Loading,
    }))
    .addCase(actions.assistanceListCountSuccess, (state, action) => ({
      ...state,
      countState: StoreStateDontLoad.Loaded,
      count: action.payload,
    }))
    .addCase(actions.assistanceListCountError, (state, action) => ({
      ...state,
      countState: action.payload
        ? StoreStateDontLoad.Cancel
        : StoreStateDontLoad.Error,
    }))
    .addCase(actions.assistanceListChangeOrder, (state, action) => ({
      ...state,
      state: StoreState.None,
      orderBy: action.payload.orderBy,
      orderDesc: action.payload.orderDesc,
      page: 0,
    }))
    .addCase(actions.assistanceListChangePage, (state, action) => ({
      ...state,
      state: StoreState.None,
      page: action.payload,
    }))
    .addCase(actions.assistanceListChangeFilter, (state, action) => ({
      ...state,
      state: StoreState.None,
      countState: StoreStateDontLoad.DontLoad,
      filter: action.payload,
      page: 0,
    }))
    .addCase(actions.assistanceListReset, (state) => ({
      ...initialState,
      filter: state.filter,
    }))
    .addCase(actions.assistanceListLoadAction, (state, action) => ({
      ...state,
      state: action.payload.newState,
      countState: action.payload.newCountState,
    }))
    .addCase(STORAGE_RESET, () => initialState)
);
