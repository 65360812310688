import { AxiosRequestConfig, AxiosResponse, CancelToken } from "axios";
import { formatISO } from "date-fns";
import {
  IAction,
  IActionConflict,
  IActionCreateRequest,
  IActionItem,
  ICalendarAvailableRequest,
  ICalendarResponseItem,
} from "../models/calendar";
import api from "../utils/api";

const getActionList = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<IActionItem[]>> => api.get(`/api/Actions`, config);

const getActionCount = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<number>> => api.get(`/api/Actions/Count`, config);

const actionListExportCsv = (
  params: URLSearchParams
): Promise<AxiosResponse<Blob>> =>
  api.get(`/api/Actions/Export/Csv`, { params, responseType: "blob" });

const actionListExportXlsx = (
  params: URLSearchParams
): Promise<AxiosResponse<Blob>> =>
  api.get(`/api/Actions/Export/Xlsx`, { params, responseType: "blob" });

const getAction = async (actionId: number): Promise<AxiosResponse<IAction>> =>
  api.get(`/api/Action/${actionId}`);

const updateAction = async (action: IAction): Promise<AxiosResponse<IAction>> =>
  api.put(`/api/Action/${action.id}`, action);

const checkAssignAvailabilityByActions = async (
  userId: number,
  actionIds: number[],
  limit: number,
  cancelToken: CancelToken
): Promise<AxiosResponse<IActionConflict[]>> =>
  api.put(
    `/api/Actions/AssignedUser/${userId}/UnAvailable/ByActions`,
    actionIds,
    { params: { limit }, cancelToken }
  );

const checkAssignAvailability = async (
  userId: number,
  action: IActionCreateRequest,
  excludedActionId: string | undefined,
  limit: number,
  cancelToken: CancelToken
): Promise<AxiosResponse<IActionConflict[]>> =>
  api.put(`/api/Actions/AssignedUser/${userId}/UnAvailable`, action, {
    params: { excludedActionId, limit },
    cancelToken,
  });

const updateAssignedUsers = async (
  userId: number | null,
  actionIds: number[]
): Promise<AxiosResponse<IAction[]>> =>
  api.put(`/api/Actions/AssignedUser`, actionIds, {
    params: { userId: userId },
  });

const deleteAction = async (
  actionId: number
): Promise<AxiosResponse<boolean>> => api.delete(`/api/Action/${actionId}`);

const createAction = async (
  action: IActionCreateRequest
): Promise<AxiosResponse<void>> => api.post(`/api/Action`, action);

const getCalendar = async (
  from: Date,
  to: Date,
  users: number[]
): Promise<AxiosResponse<ICalendarResponseItem[]>> => {
  const params = new URLSearchParams();
  params.append("from", formatISO(from));
  params.append("to", formatISO(to));

  for (const user of users) {
    params.append("users", user.toString());
  }

  return api.get(`/api/Calendar`, { params });
};

const calendarAddAvailable = async (
  userId: number,
  data: ICalendarAvailableRequest
): Promise<AxiosResponse<boolean>> =>
  api.post(`/api/User/${userId}/Calendar/Available`, data);

const calendarDeleteAvailable = async (
  userId: number,
  data: ICalendarAvailableRequest
): Promise<AxiosResponse<boolean>> =>
  api.put(`/api/User/${userId}/Calendar/Available/Cancel`, data);

const calendarApi = {
  getActionList,
  getActionCount,
  actionListExportCsv,
  actionListExportXlsx,
  getAction,
  updateAction,
  checkAssignAvailability,
  checkAssignAvailabilityByActions,
  updateAssignedUsers,
  deleteAction,
  createAction,
  getCalendar,
  calendarAddAvailable,
  calendarDeleteAvailable,
};

export default calendarApi;
