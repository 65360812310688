import { IClientContactPersonItem } from "../../models/client";
import { IFilterType } from "../filterType";
import { IListType } from "../listType";

export enum ClientContactPersonListTypes {
  CLIENT_CONTACT_PERSON_LIST_FETCH_REQUEST = "@@clientContactPersonList/FETCH_REQUEST",
  CLIENT_CONTACT_PERSON_LIST_FETCH_SUCCESS = "@@clientContactPersonList/FETCH_SUCCESS",
  CLIENT_CONTACT_PERSON_LIST_FETCH_ERROR = "@@clientContactPersonList/FETCH_ERROR",
  CLIENT_CONTACT_PERSON_LIST_COUNT_REQUEST = "@@clientContactPersonList/COUNT_REQUEST",
  CLIENT_CONTACT_PERSON_LIST_COUNT_SUCCESS = "@@clientContactPersonList/COUNT_SUCCESS",
  CLIENT_CONTACT_PERSON_LIST_COUNT_ERROR = "@@clientContactPersonList/COUNT_ERROR",
  CLIENT_CONTACT_PERSON_LIST_CHANGE_ORDER = "@@clientContactPersonList/CHANGE_ORDER",
  CLIENT_CONTACT_PERSON_LIST_CHANGE_PAGE = "@@clientContactPersonList/CHANGE_PAGE",
  CLIENT_CONTACT_PERSON_LIST_CHANGE_FILTER = "@@clientContactPersonList/CHANGE_FILTER",
  CLIENT_CONTACT_PERSON_LIST_RESET = "@@clientContactPersonList/RESET",
  CLIENT_CONTACT_PERSON_LIST_LOAD = "@@clientContactPersonList/LOAD",
}

export interface IClientContactPersonListState
  extends IListType<IClientContactPersonItem, IFilterType> {}
