import { faFolder, faFolderOpen } from "@fortawesome/free-solid-svg-icons";
import { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import { TemplateDocumentType } from "../../models/template";
import { IApplicationState } from "../../store";
import { StoreState } from "../../store/storeState";
import {
  getTemplateTree,
  ITemplateTreeState,
  resetTemplateTree,
  selectTemplateTreeAll,
} from "../../store/templateTree";
import Loader from "../common/Loader";
import CustomTree, { ITreeItem, ITreeReponse } from "../common/tree/CustomTree";

export interface ISelectedFile {
  id: number;
  name: string;
}

interface IProps {
  type: TemplateDocumentType;
  selectedFile: ISelectedFile | null;
  templateTreeAll: ITemplateTreeState;
  setSelectedFile(file: ISelectedFile): void;
  getTemplateTree(id: number, type: TemplateDocumentType | null): void;
  resetTemplateTree(): void;
}

const SelectTemplateTree: FC<IProps> = ({
  type,
  selectedFile,
  templateTreeAll,
  setSelectedFile,
  getTemplateTree,
  resetTemplateTree,
}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    resetTemplateTree();
    setLoading(false);
  }, [resetTemplateTree]);

  const handleGetItems = (id: number): ITreeReponse => {
    getTemplateTree(id, type);
    const res = templateTreeAll.items[id.toString()] ?? {
      state: StoreState.Loading,
      items: [],
    };
    return res;
  };

  const handleSelect = (item: ITreeItem) => {
    setSelectedFile(item);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <CustomTree
      selectedFile={selectedFile?.id}
      openedIcon={faFolderOpen}
      closedIcon={faFolder}
      getItems={handleGetItems}
      select={handleSelect}
    />
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    templateTreeAll: selectTemplateTreeAll(state),
  };
};

const mapDispachToProps = {
  getTemplateTree,
  resetTemplateTree,
};

export default connect(mapStateToProps, mapDispachToProps)(SelectTemplateTree);
