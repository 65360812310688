import { IIdentity } from "../../models/auth";
import { StoreState } from "../storeState";

export enum IdentityTypes {
  IDENTITY_FETCH_REQUEST = "@@identity/FETCH_REQUEST",
  IDENTITY_FETCH_SUCCESS = "@@identity/FETCH_SUCCESS",
  IDENTITY_FETCH_ERROR = "@@identity/FETCH_ERROR",
}

export interface IIdentityState {
  state: StoreState;
  data: IIdentity | null;
}
