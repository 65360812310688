import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DashboardTable } from "../common/grid/table";
import {
  DashboardWidgetDiv,
  DashboardWidgetTableContainer,
  DashboardWidgetTitle,
} from "./DashboardWidgetsStyles";
import { TailSpin } from "react-loader-spinner";
import { COLORS } from "../../styles/colors";
import axios, { CancelToken } from "axios";
import { TableMessage } from "../common/grid/table";
import { errorSet } from "../../utils/error";
import DashboardWidgetCounter from "./DashboardWidgetCounter";
import { DASHBOARD_REFRESH_MS } from "../../utils/consts";

interface IWidgetComponentItem {
  id: number;
}

interface IWidgetComponentItems<T extends IWidgetComponentItem> {
  items: T[];
  count: number | null;
}

interface IHeaderItem {
  captionStr?: string;
  captionEl?: ReactElement;
}

interface IProps<T extends IWidgetComponentItem> {
  title: string;
  titleColor: string;
  headers: IHeaderItem[];
  hasCount: boolean;
  getData(cancelToken: CancelToken): Promise<IWidgetComponentItems<T>>;
  onRowClick?(item: T): void;
  renderData(item: T): ReactElement;
  onCountClick?(): void;
}

function DashboardWidgetComponent<T extends IWidgetComponentItem>({
  title,
  headers,
  titleColor,
  hasCount,
  getData,
  onRowClick,
  renderData,
  onCountClick,
}: IProps<T>) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<IWidgetComponentItems<T>>({
    items: [],
    count: hasCount ? 0 : null,
  });
  const [error, setError] = useState<string | null>(null);
  const [lastRefresh, setLastRefresh] = useState<Date | null>(null);

  useEffect(() => {
    const token = axios.CancelToken.source();
    let timeout: NodeJS.Timeout | null = null;

    (async () => {
      try {
        setError(null);
        setLoading(true);
        setData({ items: [], count: hasCount ? 0 : null });

        const res = await getData(token.token);
        setData(res);

        timeout = setTimeout(() => {
          setLastRefresh(new Date());
        }, DASHBOARD_REFRESH_MS);
      } catch (err) {
        errorSet(setError, err, t);
      }
      setLoading(false);
    })();

    return () => {
      token.cancel();

      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [getData, t, hasCount, lastRefresh]);

  const headerCaption = useCallback(
    (col: IHeaderItem) => {
      if (col.captionStr) {
        return t(col.captionStr);
      }

      return col.captionEl;
    },
    [t]
  );

  const tableHeader = useMemo(
    () => headers.map((col, i) => <th key={i}>{headerCaption(col)}</th>),
    [headers, headerCaption]
  );

  const tableData = useMemo(() => {
    if (loading || error || data.items.length === 0) return;

    return (
      <>
        {hasCount && (
          <DashboardWidgetCounter
            count={data.items.length}
            total={data.count!}
            onClick={() => onCountClick?.()}
          />
        )}
        {data.items.map((item) => (
          <tr key={item.id} onClick={() => onRowClick?.(item)}>
            {renderData(item)}
          </tr>
        ))}
      </>
    );
  }, [data, loading, error, hasCount, onRowClick, renderData, onCountClick]);

  return (
    <DashboardWidgetDiv>
      <DashboardWidgetTitle color={titleColor}>{t(title)}</DashboardWidgetTitle>
      <DashboardWidgetTableContainer>
        <DashboardTable>
          <thead>
            <tr>{tableHeader}</tr>
          </thead>
          <tbody>{tableData}</tbody>
        </DashboardTable>

        {loading && (
          <TableMessage>
            <TailSpin color={COLORS.loaderColor} width={48} height={48} />
          </TableMessage>
        )}

        {!loading && error && <TableMessage>{error}</TableMessage>}

        {!loading && !error && data.items.length === 0 && (
          <TableMessage>{t("common.noData")}</TableMessage>
        )}
      </DashboardWidgetTableContainer>
    </DashboardWidgetDiv>
  );
}

export default DashboardWidgetComponent;
