import styled from "styled-components";

export const VerticalSpace = styled.div`
  height: 3rem;
`;

export const SpaceBetweenButtons = styled.div`
  display: inline-block;
  width: 1rem;
`;

export const VerticalGap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
