import { IDefaultDocumentDocumentItem } from "../../models/defaultDocument";
import { IFilterType } from "../filterType";
import { IListType } from "../listType";

export enum DefaultDocumentDocumentListTypes {
  DEFAULT_DOCUMENT_DOCUMENT_LIST_FETCH_REQUEST = "@@defaultDocumentDocumentList/FETCH_REQUEST",
  DEFAULT_DOCUMENT_DOCUMENT_LIST_FETCH_SUCCESS = "@@defaultDocumentDocumentList/FETCH_SUCCESS",
  DEFAULT_DOCUMENT_DOCUMENT_LIST_FETCH_ERROR = "@@defaultDocumentDocumentList/FETCH_ERROR",
  DEFAULT_DOCUMENT_DOCUMENT_LIST_COUNT_REQUEST = "@@defaultDocumentDocumentList/COUNT_REQUEST",
  DEFAULT_DOCUMENT_DOCUMENT_LIST_COUNT_SUCCESS = "@@defaultDocumentDocumentList/COUNT_SUCCESS",
  DEFAULT_DOCUMENT_DOCUMENT_LIST_COUNT_ERROR = "@@defaultDocumentDocumentList/COUNT_ERROR",
  DEFAULT_DOCUMENT_DOCUMENT_LIST_CHANGE_ORDER = "@@defaultDocumentDocumentList/CHANGE_ORDER",
  DEFAULT_DOCUMENT_DOCUMENT_LIST_CHANGE_PAGE = "@@defaultDocumentDocumentList/CHANGE_PAGE",
  DEFAULT_DOCUMENT_DOCUMENT_LIST_CHANGE_FILTER = "@@defaultDocumentDocumentList/CHANGE_FILTER",
  DEFAULT_DOCUMENT_DOCUMENT_LIST_RESET = "@@defaultDocumentDocumentList/RESET",
  DEFAULT_DOCUMENT_DOCUMENT_LIST_LOAD = "@@defaultDocumentDocumentList/LOAD",
}

export interface IDefaultDocumentDocumentListFilter extends IFilterType {
  idFolder?: number | null;
}

export interface IDefaultDocumentDocumentListState
  extends IListType<
    IDefaultDocumentDocumentItem,
    IDefaultDocumentDocumentListFilter
  > {}
