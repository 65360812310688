import { IWorkflowDefinition, IWorkflowQuestion } from "./workflow";

export enum DemandStateType {
  New = "new",
  Finished = "finished",
  Canceled = "canceled",
}

export enum DemandPriorityType {
  Low = "low",
  Medium = "medium",
  High = "high",
}

export enum DemandStateFilterType {
  All = "all",
  New = "new",
  AfterDeadline = "afterDeadline",
  NotAssigned = "notAssigned",
  NotAssignedAfterDeadline = "notAssignedAfterDeadline",
  AssignedAfterDeadline = "assignedAfterDeadline",
  Finished = "finished",
  Canceled = "canceled",
}

export interface IDemandItem {
  id: number;
  name: string;
  state: DemandStateType;
  createdAt: Date;
  deadLine: Date;
  priority: DemandPriorityType;
  clientName?: string | null;
  assignedName?: string | null;
  isUserAssigned: boolean;
}

export interface IDemand {
  id: number;
  idWorkflowDefinitionItem: number;
  name: string;
  state: DemandStateType;
  priority: DemandPriorityType;
  description?: string | null;
  deadLine: Date;

  assignedUserId?: number | null;
  assignedUserName?: string | null;
  canChangeAssignedUser: boolean;

  assignedGroupId?: number | null;
  assignedGroupName?: string | null;
  canChangeAssignedGroup: boolean;

  clientId?: number | null;
  clientName?: string | null;

  canTake: boolean;
  questions: IWorkflowQuestion[];
}

export interface IDemandHistory {
  definition: IWorkflowDefinition[];
  demands: IDemandHistoryItem[];
}

export interface IDemandHistoryItem {
  id: number;
  idWorkflowDefinitionItem: number;
  state: DemandStateType;
  createdAt: Date;
  finishedAt?: Date | null;
  userFinishedId?: number | null;
  userFinishedName?: string | null;
  hasRights: boolean;
}
