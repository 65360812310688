import styled from "styled-components";

export const NodeTypeWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 2rem !important;

  > label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
`;

export const AnswerInputWrapper = styled.div`
  display: flex;
  gap: 1rem;
`;

export const AddButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
`;
