import { CancelToken } from "axios";
import { FC, ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { RightType } from "../../models/auth";
import { IWorkflowTemplateItem } from "../../models/workflowTemplate";
import { IApplicationState } from "../../store";
import { IFilterType } from "../../store/filterType";
import { selectIdentityRights } from "../../store/identity";
import {
  changeWorkflowTemplateListFilter,
  changeWorkflowTemplateListOrder,
  changeWorkflowTemplateListPage,
  getWorkflowTemplateList,
  getWorkflowTemplateListCount,
  IWorkflowTemplateListState,
  selectWorkflowTemplateList,
  workflowTemplateListLoad,
} from "../../store/workflowTemplateList";
import { hasRight } from "../../utils/rights";
import FilterPanel from "../common/filter/FilterPanel";
import Grid from "../common/grid/Grid";
import {
  GridIconAdd,
  GridIconEdit,
  GridIconIsActive,
} from "../common/grid/GridIcons";
import { TableCol, TableColCenter } from "../common/grid/TableCol";
import Page from "../layout/Page";

interface IProps {
  prov: IWorkflowTemplateListState;
  identityRights?: RightType[];
  getWorkflowTemplateList(cancelToken: CancelToken): void;
  getWorkflowTemplateListCount(cancelToken: CancelToken): void;
  changeWorkflowTemplateListOrder(orderBy: string, orderDesc: boolean): void;
  changeWorkflowTemplateListPage(page: number): void;
  changeWorkflowTemplateListFilter(filter: IFilterType): void;
  workflowTemplateListLoad(reload: boolean): void;
}

const ModuleWorkflowTemplate: FC<IProps> = ({
  prov,
  identityRights,
  getWorkflowTemplateList,
  getWorkflowTemplateListCount,
  changeWorkflowTemplateListOrder,
  changeWorkflowTemplateListPage,
  changeWorkflowTemplateListFilter,
  workflowTemplateListLoad,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    workflowTemplateListLoad(false);
  }, [workflowTemplateListLoad]);

  const handleRenderData = (item: IWorkflowTemplateItem): ReactElement => {
    return (
      <>
        <TableColCenter>{item.isActive && <GridIconIsActive />}</TableColCenter>
        <TableCol>{item.name}</TableCol>
        <TableCol>
          <NavLink to={`/module/workflow-template/${item.id}`}>
            <GridIconEdit />
          </NavLink>
        </TableCol>
      </>
    );
  };

  const handleRowClick = (item: IWorkflowTemplateItem) => {
    navigate(`/module/workflow-template/${item.id}`);
  };

  return (
    <Page>
      <FilterPanel
        title={t("module.workflowTemplate.pageTitle")}
        name="moduleWorkflowTemplate"
        filter={prov.filter!}
        changeFilter={changeWorkflowTemplateListFilter}
      />
      <Grid<IWorkflowTemplateItem>
        headers={[
          { captionStr: "module.workflowTemplate.isActive", minContent: true },
          { captionStr: "module.workflowTemplate.name" },
          {
            captionEl: hasRight(identityRights, [
              RightType.WriteWorkflowTemplate,
            ]) ? (
              <NavLink to={`/module/workflow-template/new`}>
                <GridIconAdd />
              </NavLink>
            ) : undefined,
          },
        ]}
        renderData={handleRenderData}
        getData={getWorkflowTemplateList}
        getCount={getWorkflowTemplateListCount}
        changeOrder={changeWorkflowTemplateListOrder}
        changePage={changeWorkflowTemplateListPage}
        onRowClick={handleRowClick}
        prov={prov}
      />
    </Page>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    prov: selectWorkflowTemplateList(state),
    identityRights: selectIdentityRights(state),
  };
};

const mapDispachToProps = {
  getWorkflowTemplateList,
  getWorkflowTemplateListCount,
  changeWorkflowTemplateListOrder,
  changeWorkflowTemplateListPage,
  changeWorkflowTemplateListFilter,
  workflowTemplateListLoad,
};

export default connect(
  mapStateToProps,
  mapDispachToProps
)(ModuleWorkflowTemplate);
