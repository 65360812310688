import { IAssistance } from "../../models/assistance";
import { StoreState } from "../storeState";

export enum AssistanceTypes {
  ASSISTANCE_FETCH_REQUEST = "@@assistance/FETCH_REQUEST",
  ASSISTANCE_FETCH_SUCCESS = "@@assistance/FETCH_SUCCESS",
  ASSISTANCE_FETCH_ERROR = "@@assistance/FETCH_ERROR",
}

export interface IAssistanceState {
  state: StoreState;
  data: IAssistance | null;
}
