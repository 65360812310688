import { IWorkflowItem } from "../../models/workflow";
import { IFilterType } from "../filterType";
import { IListType } from "../listType";

export enum WorkflowListTypes {
  WORKFLOW_LIST_FETCH_REQUEST = "@@workflowList/FETCH_REQUEST",
  WORKFLOW_LIST_FETCH_SUCCESS = "@@workflowList/FETCH_SUCCESS",
  WORKFLOW_LIST_FETCH_ERROR = "@@workflowList/FETCH_ERROR",
  WORKFLOW_LIST_COUNT_REQUEST = "@@workflowList/COUNT_REQUEST",
  WORKFLOW_LIST_COUNT_SUCCESS = "@@workflowList/COUNT_SUCCESS",
  WORKFLOW_LIST_COUNT_ERROR = "@@workflowList/COUNT_ERROR",
  WORKFLOW_LIST_CHANGE_ORDER = "@@workflowList/CHANGE_ORDER",
  WORKFLOW_LIST_CHANGE_PAGE = "@@workflowList/CHANGE_PAGE",
  WORKFLOW_LIST_CHANGE_FILTER = "@@workflowList/CHANGE_FILTER",
  WORKFLOW_LIST_RESET = "@@workflowList/RESET",
  WORKFLOW_LIST_LOAD = "@@workflowList/LOAD",
}

export interface IWorkflowListState
  extends IListType<IWorkflowItem, IFilterType> {}
