import { CancelToken } from "axios";
import { AppThunkAction, AppThunkActionAsync } from "..";
import changeDemandsApi from "../../api/changeDemands";
import {
  listEffectsGetCheck,
  listEffectsGetNew,
  listEffectsCountCheck,
  listEffectLoad,
  listEffectsGetParams,
  listEffectGetFilter,
  listEffectsGetCount,
  listEffectCountFilter,
} from "../listEffect";
import * as actions from "./actions";
import { selectChangeDemandList } from "./selectors";
import { IChangeDemandFilterType, IChangeDemandListState } from "./types";

export const getChangeDemandList =
  (cancelToken: CancelToken, clientId?: number): AppThunkActionAsync =>
  async (dispatch, getState) => {
    const state = selectChangeDemandList(getState());
    let newState = listEffectsGetNew(state);

    if (listEffectsGetCheck(state.state)) {
      return;
    }

    dispatch(actions.changeDemandListFetchRequest(newState.newCountState));
    try {
      const params = listEffectsGetParams(state, newState.take);
      listEffectGetFilter(params, state.filter);
      addChangeDemandParams(params, state);

      if (!!clientId) params.append("clientId", clientId.toString());

      const demands = await changeDemandsApi.getChangeDemands({
        params,
        cancelToken,
      });
      cancelToken.throwIfRequested();
      newState = listEffectsGetCount(newState, demands);

      dispatch(
        actions.changeDemandListFetchSuccess({
          data: demands.data,
          newCountState: newState.newCountState,
          newCount: newState.newCount,
        })
      );
    } catch (error) {
      dispatch(
        actions.changeDemandListFetchError(cancelToken.reason !== undefined)
      );
    }
  };

export const getChangeDemandListCount =
  (cancelToken: CancelToken, clientId?: number): AppThunkActionAsync =>
  async (dispatch, getState) => {
    const state = selectChangeDemandList(getState());

    if (listEffectsCountCheck(state.countState)) {
      return;
    }

    dispatch(actions.changeDemandListCountRequest());

    try {
      const params = listEffectCountFilter(state.filter);
      addChangeDemandParams(params, state);

      if (!!clientId) params.append("clientId", clientId.toString());

      const count = await changeDemandsApi.getChangeDemandsCount({
        cancelToken,
        params,
      });

      cancelToken.throwIfRequested();

      dispatch(actions.changeDemandListCountSuccess(count.data?.count));
    } catch (error) {
      dispatch(
        actions.changeDemandListCountError(cancelToken.reason !== undefined)
      );
    }
  };

export const changeDemandLoad =
  (reload: boolean): AppThunkActionAsync =>
  async (dispatch, getState) => {
    const state = selectChangeDemandList(getState());
    if (reload) {
      dispatch(actions.changeDemandListReset());
    } else {
      const newState = listEffectLoad(state);
      dispatch(actions.changeDemandListLoad(newState));
    }
  };

export const changeChangeDemandListOrder =
  (orderBy: string, orderDesc: boolean): AppThunkActionAsync =>
  async (dispatch) => {
    dispatch(actions.changeDemandListOrder({ orderBy, orderDesc }));
  };

export const changeChangeDemandListPage =
  (page: number): AppThunkActionAsync =>
  async (dispatch) => {
    dispatch(actions.changeDemandListPage(page));
  };

export const changeChangeDemandListFilter =
  (filter: IChangeDemandFilterType): AppThunkActionAsync =>
  async (dispatch) => {
    dispatch(actions.changeDemandListFilter(filter));
  };

export const openChangeDemandListFilter =
  (): AppThunkAction => async (dispatch, getState) => {
    const state = getState();
    dispatch(
      actions.changeDemandListFilter({
        ...state.changeDemandList.filter!,
        isOpen: true,
      })
    );
  };

export const addChangeDemandParams = (
  params: URLSearchParams,
  state: IChangeDemandListState
) => {
  if (state.filter?.state) {
    params.append("state", state.filter.state);
  }
};
