import { RightType } from "../models/auth";
import { RoleRightType } from "../models/role";

interface IMenuItem {
  rights: RightType[];
}

export const hasRight = (
  identityRights?: RightType[],
  requiredRights?: RightType[],
  requiredAll?: boolean
): boolean => {
  if (identityRights === undefined || identityRights.length === 0) {
    return false;
  }

  if (requiredRights === undefined || requiredRights.length === 0) {
    return true;
  }

  if (requiredAll) {
    return requiredRights.every((x) => identityRights.includes(x));
  } else {
    return requiredRights.some((x) => identityRights.includes(x));
  }
};

export const hasClientWriteRight = (
  identityRights?: RightType[],
  requiredRights?: RightType[],
  requiredAll?: boolean
): boolean => {
  return (
    hasRight(identityRights, [
      RightType.WriteAllClients,
      RightType.WriteMyClients,
      RightType.WriteClientsInTheSameAssistance,
    ]) && hasRight(identityRights, requiredRights, requiredAll)
  );
};

export const filterMenu = <T extends IMenuItem>(
  identityRights: RightType[] | undefined,
  items: T[]
): T[] => items.filter((x) => hasRight(identityRights, x.rights));

export const allListingRights = [
  RightType.ReadActionListing,
  RightType.ReadAssistanceCategoryListing,
  RightType.ReadClientContactPersonListing,
  RightType.ReadClientRiskListing,
  RightType.ReadSocialBenefitListing,
  RightType.ReadTargetGroupListing,
  RightType.ReadContactPersonListing,
  RightType.ReadWorkflowCategoryListing,
  RightType.ReadHealthInsurance,
];

export const getRoleType = (read: boolean, write: boolean) => {
  if (write) {
    return RoleRightType.Write;
  }

  if (read) {
    return RoleRightType.Read;
  }

  return RoleRightType.None;
};

export const getRoleTypeRead = (read: boolean) => {
  return read ? RoleRightType.Read : RoleRightType.None;
};

export const getRoleTypeWrite = (write: boolean) => {
  return write ? RoleRightType.Write : RoleRightType.None;
};
