import { ISearchFoundItem } from "../../models/search";
import { StoreState } from "../storeState";

export enum SearchTypes {
  SEARCH_FETCH_REQUEST = "@@search/FETCH_REQUEST",
  SEARCH_FETCH_PARTIAL_SUCCESS = "@@search/FETCH_PARTIAL_SUCCESS",
  SEARCH_FETCH_SUCCESS = "@@search/FETCH_SUCCESS",
  SEARCH_FETCH_ERROR = "@@search/FETCH_ERROR",
}

export interface ISearchFilter {
  text: string;
  open: boolean;
  selClient: boolean;
  selClientContactPerson: boolean;
  selUser: boolean;
  selGroup: boolean;
  selDocument: boolean;
  selDocumentContent: boolean;
  selAssistance: boolean;
  selAssistanceContactPerson: boolean;
  selListing: boolean;
}

export interface ISearchState {
  filter: ISearchFilter;
  state: StoreState;
  data: ISearchFoundItem[];
}
