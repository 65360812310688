import { Field } from "formik";
import styled from "styled-components";

export const AdminRoleHeader = styled.div`
  display: grid;
  grid-template-columns: 10rem 5rem 5rem 5rem 20rem;
  margin-top: 2rem;
  margin-left: 2rem;
  text-align: center;
  align-items: end;
`;

export const AdminRoleCheck = styled(Field)`
  width: 1.5rem;
  height: 1.5rem;
`;

export const AdminRoleCategoryLabel = styled.label`
  font-size: 1.4rem;
  font-weight: bold;
  margin-left: 0.5rem;
`;

export const AdminRoleSubCategoryLabel = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
`;

export const AdminRoleRightContainer = styled.div<{ firstColumn?: string }>`
  display: grid;
  grid-template-columns: ${({ firstColumn }) =>
      firstColumn ? firstColumn : "10rem"} 5rem 5rem 5rem 20rem;

  .hidden {
    visibility: hidden;
  }
`;

export const AdminRoleRadioContainer = styled.div`
  text-align: center;
`;

export const AdminRoleRadio = styled(Field)`
  width: 1.5rem;
  height: 1.5rem;
`;

export const AdminRoleCategoryContainer = styled.div`
  margin-left: 2rem;
`;

export const AdminRoleLimitationsRow = styled.div`
  display: grid;
  grid-template-columns: 10rem 15rem 20rem;
  margin-bottom: 0.2rem;
  align-items: center;
`;
