import { IClientRevenueItem } from "../../models/client";
import { IFilterType } from "../filterType";
import { IListType } from "../listType";

export enum ClientRevenueListTypes {
  CLIENT_REVENUE_LIST_FETCH_REQUEST = "@@clientRevenueList/FETCH_REQUEST",
  CLIENT_REVENUE_LIST_FETCH_SUCCESS = "@@clientRevenueList/FETCH_SUCCESS",
  CLIENT_REVENUE_LIST_FETCH_ERROR = "@@clientRevenueList/FETCH_ERROR",
  CLIENT_REVENUE_LIST_COUNT_REQUEST = "@@clientRevenueList/COUNT_REQUEST",
  CLIENT_REVENUE_LIST_COUNT_SUCCESS = "@@clientRevenueList/COUNT_SUCCESS",
  CLIENT_REVENUE_LIST_COUNT_ERROR = "@@clientRevenueList/COUNT_ERROR",
  CLIENT_REVENUE_LIST_CHANGE_ORDER = "@@clientRevenueList/CHANGE_ORDER",
  CLIENT_REVENUE_LIST_CHANGE_PAGE = "@@clientRevenueList/CHANGE_PAGE",
  CLIENT_REVENUE_LIST_CHANGE_FILTER = "@@clientRevenueList/CHANGE_FILTER",
  CLIENT_REVENUE_LIST_RESET = "@@clientRevenueList/RESET",
  CLIENT_REVENUE_LIST_LOAD = "@@clientRevenueList/LOAD",
}

export interface IClientRevenueListState
  extends IListType<IClientRevenueItem, IFilterType> {}
