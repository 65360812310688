import { IActionItem } from "../../models/calendar";
import { IItemIdName } from "../../models/common";
import { IFilterType } from "../filterType";
import { IListType } from "../listType";

export enum ActionListTypes {
  ACTION_LIST_FETCH_REQUEST = "@@actionList/FETCH_REQUEST",
  ACTION_LIST_FETCH_SUCCESS = "@@actionList/FETCH_SUCCESS",
  ACTION_LIST_FETCH_ERROR = "@@actionList/FETCH_ERROR",
  ACTION_LIST_COUNT_REQUEST = "@@actionList/COUNT_REQUEST",
  ACTION_LIST_COUNT_SUCCESS = "@@actionList/COUNT_SUCCESS",
  ACTION_LIST_COUNT_ERROR = "@@actionList/COUNT_ERROR",
  ACTION_LIST_CHANGE_ORDER = "@@actionList/CHANGE_ORDER",
  ACTION_LIST_CHANGE_PAGE = "@@actionList/CHANGE_PAGE",
  ACTION_LIST_CHANGE_FILTER = "@@actionList/CHANGE_FILTER",
  ACTION_LIST_RESET = "@@actionList/RESET",
  ACTION_LIST_LOAD = "@@actionList/LOAD",
  ACTION_LIST_DEFAULT_FILTER = "@@actionList/DEFAULT_FILTER",
}

export interface IActionListFilter extends IFilterType {
  isDefault: boolean;
  assignedUsers: IItemIdName[];
  from?: Date | null;
  to?: Date | null;
  notAssigned?: boolean | null;
  clientId?: number | null;
  clientName?: string | null;
}

export interface IActionListState
  extends IListType<IActionItem, IActionListFilter> {}
