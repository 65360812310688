import { addMinutes } from "date-fns";
import { Form, Formik } from "formik";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import authApi from "../../api/auth";
import { ITwoFactorData, LoginResponseStatusType } from "../../models/auth";
import storageApi from "../../utils/storage";
import * as Yup from "yup";
import { connect } from "react-redux";
import { getIdentity } from "../../store/identity";
import { LoginButton, LoginCardActionsRight } from "./styles";
import { ApiError, FormGroup } from "../../styles/form";
import Input from "../common/form/Input";
import { useNavigate, useLocation } from "react-router";
import { promiseToast } from "../../utils/toasts";
import { SetState } from "../../utils/types";
import { Link } from "react-router-dom";

const initialValues: ITwoFactorData = {
  secret: "",
};

interface IProps {
  twoFactorToken: string;
  setTwoFactorToken: SetState<string>;
  getIdentity(): void;
}

const LoginTwoFactor: FC<IProps> = ({
  twoFactorToken,
  setTwoFactorToken,
  getIdentity,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [loginResponseStatus, setLoginResponseStatus] =
    useState<LoginResponseStatusType>(LoginResponseStatusType.None);

  const handleSubmit = async (data: ITwoFactorData) => {
    try {
      await promiseToast(
        async () => {
          setLoginResponseStatus(LoginResponseStatusType.None);
          let response;
          try {
            storageApi.setToken(twoFactorToken);
            response = await authApi.twoFactor(data);
          } catch {
            setLoginResponseStatus(LoginResponseStatusType.Error);
            throw new Error();
          } finally {
            storageApi.setToken("");
          }

          if (response.data.status !== LoginResponseStatusType.Ok) {
            setLoginResponseStatus(response.data.status);
            throw new Error();
          }

          storageApi.setToken(response.data.token ?? "");
          storageApi.setIdleMinutes(response.data.userMaxIdleMinutes ?? 0);
          storageApi.setTokenRefreshTimeout(
            addMinutes(new Date(), response.data.userMaxIdleMinutes ?? 0)
          );
          storageApi.setTokenTimeout(
            addMinutes(new Date(), response.data.tokenValidMinutes ?? 0)
          );

          getIdentity();

          if (location.pathname === "/login") {
            navigate("/");
          }
        },
        t("pending.login"),
        t("success.login"),
        t("errors.login")
      );
    } catch {
      //Nothing.
    }
  };

  const handleBack = () => {
    setTwoFactorToken("");
  };

  return (
    <>
      <Formik<ITwoFactorData>
        initialValues={initialValues}
        validationSchema={Yup.object({
          secret: Yup.number()
            .required(t("login.secretNotValid"))
            .moreThan(99999, t("login.secretNotValid"))
            .lessThan(1000000, t("login.secretNotValid")),
        })}
        validateOnMount={true}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form>
            <FormGroup labelWidth="7rem">
              <Input
                name="secret"
                label={t("login.secret")}
                type="number"
                error={touched.secret && !!errors.secret}
                maxLength={6}
              />
              {loginResponseStatus ===
                LoginResponseStatusType.BadLoginOrPass && (
                <ApiError>{t("login.badSecret")}</ApiError>
              )}
              {loginResponseStatus ===
                LoginResponseStatusType.TooMuchAttempts && (
                <ApiError>{t("login.tooMuchAttemptsSecret")}</ApiError>
              )}
              {loginResponseStatus === LoginResponseStatusType.Error && (
                <ApiError>{t("errors.unknown")}</ApiError>
              )}
            </FormGroup>
            <LoginButton type="submit" disabled={isSubmitting}>
              {t("login.submit")}
            </LoginButton>
          </Form>
        )}
      </Formik>
      <LoginCardActionsRight>
        <Link to="#" onClick={handleBack}>
          {t("common.back")}
        </Link>
      </LoginCardActionsRight>
    </>
  );
};

const mapDispachToProps = {
  getIdentity,
};

export default connect(null, mapDispachToProps)(LoginTwoFactor);
