import { createAction } from "@reduxjs/toolkit";
import { IAssistancePersonItem } from "../../models/assistance";
import { IFilterType } from "../filterType";
import { StoreState, StoreStateDontLoad } from "../storeState";
import { AssistancePersonListTypes } from "./types";

export const assistancePersonListFetchRequest = createAction<
  StoreStateDontLoad,
  AssistancePersonListTypes.ASSISTANCE_PERSON_LIST_FETCH_REQUEST
>(AssistancePersonListTypes.ASSISTANCE_PERSON_LIST_FETCH_REQUEST);

export const assistancePersonListFetchSuccess = createAction<
  {
    data: IAssistancePersonItem[];
    newCountState: StoreStateDontLoad;
    newCount: number;
  },
  AssistancePersonListTypes.ASSISTANCE_PERSON_LIST_FETCH_SUCCESS
>(AssistancePersonListTypes.ASSISTANCE_PERSON_LIST_FETCH_SUCCESS);

export const assistancePersonListFetchError = createAction<
  boolean,
  AssistancePersonListTypes.ASSISTANCE_PERSON_LIST_FETCH_ERROR
>(AssistancePersonListTypes.ASSISTANCE_PERSON_LIST_FETCH_ERROR);

export const assistancePersonListCountRequest = createAction<
  void,
  AssistancePersonListTypes.ASSISTANCE_PERSON_LIST_COUNT_REQUEST
>(AssistancePersonListTypes.ASSISTANCE_PERSON_LIST_COUNT_REQUEST);

export const assistancePersonListCountSuccess = createAction<
  number,
  AssistancePersonListTypes.ASSISTANCE_PERSON_LIST_COUNT_SUCCESS
>(AssistancePersonListTypes.ASSISTANCE_PERSON_LIST_COUNT_SUCCESS);

export const assistancePersonListCountError = createAction<
  boolean,
  AssistancePersonListTypes.ASSISTANCE_PERSON_LIST_COUNT_ERROR
>(AssistancePersonListTypes.ASSISTANCE_PERSON_LIST_COUNT_ERROR);

export const assistancePersonListChangeOrder = createAction<
  { orderBy: string; orderDesc: boolean },
  AssistancePersonListTypes.ASSISTANCE_PERSON_LIST_CHANGE_ORDER
>(AssistancePersonListTypes.ASSISTANCE_PERSON_LIST_CHANGE_ORDER);

export const assistancePersonListChangePage = createAction<
  number,
  AssistancePersonListTypes.ASSISTANCE_PERSON_LIST_CHANGE_PAGE
>(AssistancePersonListTypes.ASSISTANCE_PERSON_LIST_CHANGE_PAGE);

export const assistancePersonListChangeFilter = createAction<
  IFilterType,
  AssistancePersonListTypes.ASSISTANCE_PERSON_LIST_CHANGE_FILTER
>(AssistancePersonListTypes.ASSISTANCE_PERSON_LIST_CHANGE_FILTER);

export const assistancePersonListReset = createAction<
  void,
  AssistancePersonListTypes.ASSISTANCE_PERSON_LIST_RESET
>(AssistancePersonListTypes.ASSISTANCE_PERSON_LIST_RESET);

export const assistancePersonListLoadAction = createAction<
  { newState: StoreState; newCountState: StoreStateDontLoad },
  AssistancePersonListTypes.ASSISTANCE_PERSON_LIST_LOAD
>(AssistancePersonListTypes.ASSISTANCE_PERSON_LIST_LOAD);
