import { CancelToken } from "axios";
import { Form, Formik, useFormikContext } from "formik";
import { FC, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { IGroupEdit, IGroupItem } from "../../models/group";
import { IApplicationState } from "../../store";
import { IFilterType } from "../../store/filterType";
import {
  changeGroupListFilter,
  changeGroupListOrder,
  changeGroupListPage,
  getGroupList,
  getGroupListCount,
  IGroupListState,
  selectGroupList,
  groupListLoad,
} from "../../store/groupList";
import FilterPanel from "../common/filter/FilterPanel";
import Grid from "../common/grid/Grid";
import {
  GridIconAdd,
  GridIconDelete,
  GridIconEdit,
  GridIconIsActive,
  GridIconSpace,
} from "../common/grid/GridIcons";
import {
  TableCol,
  TableColInput,
  TableColCenter,
} from "../common/grid/TableCol";
import * as Yup from "yup";
import groupApi from "../../api/group";
import Input from "../common/form/Input";
import { GridInlineButtons } from "../common/grid/GridInline";
import validations from "../../utils/validations";
import { RightType } from "../../models/auth";
import { hasRight } from "../../utils/rights";
import { selectIdentityRights } from "../../store/identity";
import { promiseToastSaveNoException } from "../../utils/toasts";
import { ModalYesNoFunction } from "../common/modal/ModalFunctions";

const defaultData = {
  id: 0,
  isActive: true,
  name: "",
} as IGroupItem;

interface IProps {
  prov: IGroupListState;
  identityRights?: RightType[];
  getGroupList(cancelToken: CancelToken): void;
  getGroupListCount(cancelToken: CancelToken): void;
  changeGroupListOrder(orderBy: string, orderDesc: boolean): void;
  changeGroupListPage(page: number): void;
  changeGroupListFilter(filter: IFilterType): void;
  groupListLoad(reload: boolean): void;
}

const AdminGroupGridEditor: FC<{ data: IGroupEdit; handleCancel(): void }> = ({
  data,
  handleCancel,
}) => {
  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    resetForm,
    setFieldValue,
  } = useFormikContext<IGroupEdit>();

  useEffect(() => {
    resetForm();
    setFieldValue("name", data.name);
    setFieldValue("isActive", data.isActive);
  }, [data, resetForm, setFieldValue]);

  return (
    <>
      <TableColCenter>
        <Input
          name="isActive"
          type="checkbox"
          inputWidth="1.5rem"
          inputHeight="1.5rem"
        />
      </TableColCenter>
      <TableColInput>
        <Input
          name="name"
          error={touched.name && !!errors.name}
          maxLength={100}
        />
      </TableColInput>
      <GridInlineButtons
        handleCancel={handleCancel}
        isSubmitting={isSubmitting}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

const AdminGroup: FC<IProps> = ({
  prov,
  identityRights,
  getGroupList,
  getGroupListCount,
  changeGroupListOrder,
  changeGroupListPage,
  changeGroupListFilter,
  groupListLoad,
}) => {
  const { t } = useTranslation();
  const [inlineEditId, setInlineEditId] = useState<number | undefined>();
  const [inlineAdd, setInlineAdd] = useState(false);

  useEffect(() => {
    groupListLoad(false);
  }, [groupListLoad]);

  const handleEdit = (id: number) => {
    setInlineAdd(false);
    setInlineEditId(id);
  };

  const handleDelete = async (id: number) => {
    const res = await ModalYesNoFunction(
      t("admin.group.delete"),
      t("admin.group.deleteConfirm")
    );
    if (res) {
      await promiseToastSaveNoException(async () => {
        await groupApi.deleteGroup(id);
        groupListLoad(true);
      });
    }
  };

  const handleCancel = () => {
    setInlineEditId(undefined);
    setInlineAdd(false);
  };

  const handleAdd = () => {
    setInlineEditId(undefined);
    setInlineAdd(true);
  };

  const handleRenderData = (item: IGroupItem): ReactElement => {
    return (
      <>
        <TableColCenter>{item.isActive && <GridIconIsActive />}</TableColCenter>
        <TableCol>{item.name}</TableCol>
        <TableCol>{item.count}</TableCol>
        <TableCol>
          {!inlineEditId &&
            !inlineAdd &&
            hasRight(identityRights, [RightType.WriteUserGroups]) && (
              <>
                <GridIconEdit onClick={() => handleEdit(item.id)} />
                <GridIconSpace />
                <GridIconDelete onClick={() => handleDelete(item.id)} />
              </>
            )}
        </TableCol>
      </>
    );
  };

  const handleSubmit = async (data: IGroupEdit) => {
    await promiseToastSaveNoException(async () => {
      if (inlineEditId) {
        await groupApi.updateGroup(inlineEditId, data);
      } else {
        await groupApi.addGroup(data);
      }

      groupListLoad(true);
      handleCancel();
    });
  };

  const handleRenderEditor = (item: IGroupItem): ReactElement => {
    return (
      <AdminGroupGridEditor
        data={{ name: item.name, isActive: item.isActive }}
        handleCancel={handleCancel}
      />
    );
  };

  const handleRowClick = (item: IGroupItem) => {
    setInlineEditId(item.id);
  };

  return (
    <>
      <FilterPanel
        viewFiltration={!inlineEditId && !inlineAdd}
        title={t("admin.group.pageTitle")}
        name="adminGroup"
        filter={prov.filter!}
        changeFilter={changeGroupListFilter}
      />

      <Formik
        initialValues={{ name: "", isActive: true }}
        validationSchema={Yup.object({
          name: validations.stringRequired(t),
        })}
        validateOnMount={true}
        onSubmit={handleSubmit}
      >
        <Form>
          <Grid<IGroupItem>
            headers={[
              { captionStr: "admin.group.isActive", minContent: true },
              { captionStr: "admin.group.name" },
              { captionStr: "admin.group.count" },
              {
                captionEl:
                  !inlineEditId &&
                  !inlineAdd &&
                  hasRight(identityRights, [RightType.WriteUserGroups]) ? (
                    <GridIconAdd onClick={handleAdd} />
                  ) : undefined,
              },
            ]}
            inlineEditId={inlineEditId}
            inlineAdd={inlineAdd}
            defaultData={defaultData}
            renderData={handleRenderData}
            renderEditor={handleRenderEditor}
            getData={getGroupList}
            getCount={getGroupListCount}
            changeOrder={changeGroupListOrder}
            changePage={changeGroupListPage}
            onRowClick={
              hasRight(identityRights, [RightType.WriteUserGroups])
                ? handleRowClick
                : undefined
            }
            prov={prov}
          />
        </Form>
      </Formik>
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    prov: selectGroupList(state),
    identityRights: selectIdentityRights(state),
  };
};

const mapDispachToProps = {
  getGroupList,
  getGroupListCount,
  changeGroupListOrder,
  changeGroupListPage,
  changeGroupListFilter,
  groupListLoad,
};

export default connect(mapStateToProps, mapDispachToProps)(AdminGroup);
