import { IFolderItem } from "../../models/document";
import { IFilterType } from "../filterType";
import { IListType } from "../listType";

export enum TemplateFolderListTypes {
  TEMPLATE_FOLDER_LIST_FETCH_REQUEST = "@@templateFolderList/FETCH_REQUEST",
  TEMPLATE_FOLDER_LIST_FETCH_SUCCESS = "@@templateFolderList/FETCH_SUCCESS",
  TEMPLATE_FOLDER_LIST_FETCH_ERROR = "@@templateFolderList/FETCH_ERROR",
  TEMPLATE_FOLDER_LIST_COUNT_REQUEST = "@@templateFolderList/COUNT_REQUEST",
  TEMPLATE_FOLDER_LIST_COUNT_SUCCESS = "@@templateFolderList/COUNT_SUCCESS",
  TEMPLATE_FOLDER_LIST_COUNT_ERROR = "@@templateFolderList/COUNT_ERROR",
  TEMPLATE_FOLDER_LIST_CHANGE_ORDER = "@@templateFolderList/CHANGE_ORDER",
  TEMPLATE_FOLDER_LIST_CHANGE_PAGE = "@@templateFolderList/CHANGE_PAGE",
  TEMPLATE_FOLDER_LIST_CHANGE_FILTER = "@@templateFolderList/CHANGE_FILTER",
  TEMPLATE_FOLDER_LIST_RESET = "@@templateFolderList/RESET",
  TEMPLATE_FOLDER_LIST_LOAD = "@@templateFolderList/LOAD",
}

export interface ITemplateFolderListFilter extends IFilterType {
  idFolder?: number | null;
}

export interface ITemplateFolderListState
  extends IListType<IFolderItem, ITemplateFolderListFilter> {}
