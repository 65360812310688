import { selectIdentityState } from ".";
import { AppThunkActionAsync } from "..";
import { StoreState } from "../storeState";
import * as actions from "./actions";
import authApi from "../../api/auth";

export function getIdentity(): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectIdentityState(getState());
    if (state !== StoreState.None) {
      return;
    }

    dispatch(actions.identityFetchRequest());
    try {
      const identity = await authApi.identity();
      dispatch(actions.identityFetchSuccess(identity.data));
    } catch {
      dispatch(actions.identityFetchError());
    }
  };
}
