import { AxiosResponse } from "axios";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ExportType } from "../../../models/common";
import { Button } from "../../../styles/button";
import { downloadBlob } from "../../../utils/download";
import { promiseToast } from "../../../utils/toasts";
import { FilterExportButtonsContainer } from "./FilterExportButtonsStyles";

interface IProps {
  doExport(type: ExportType): Promise<AxiosResponse<Blob>>;
}

const FilterExportButtons: FC<IProps> = ({ doExport }) => {
  const { t } = useTranslation();

  const handleExport = async (type: ExportType) => {
    try {
      await promiseToast(
        async () => {
          const response = await doExport(type);
          await downloadBlob(response);
        },
        t("pending.export"),
        t("success.export"),
        t("errors.unknown")
      );
    } catch {
      //Noting.
    }
  };

  const handleExportCsv = () => {
    handleExport(ExportType.Csv);
  };

  const handleExportXlsx = () => {
    handleExport(ExportType.Xlsx);
  };

  return (
    <FilterExportButtonsContainer>
      <Button ver="secondary" onClick={handleExportCsv}>
        {t("common.exportCsv")}
      </Button>
      <Button ver="secondary" onClick={handleExportXlsx}>
        {t("common.exportXlsx")}
      </Button>
    </FilterExportButtonsContainer>
  );
};

export default FilterExportButtons;
