import { IRoleItem } from "../../models/role";
import { IFilterType } from "../filterType";
import { IListType } from "../listType";

export enum RoleListTypes {
  ROLE_LIST_FETCH_REQUEST = "@@roleList/FETCH_REQUEST",
  ROLE_LIST_FETCH_SUCCESS = "@@roleList/FETCH_SUCCESS",
  ROLE_LIST_FETCH_ERROR = "@@roleList/FETCH_ERROR",
  ROLE_LIST_COUNT_REQUEST = "@@roleList/COUNT_REQUEST",
  ROLE_LIST_COUNT_SUCCESS = "@@roleList/COUNT_SUCCESS",
  ROLE_LIST_COUNT_ERROR = "@@roleList/COUNT_ERROR",
  ROLE_LIST_CHANGE_ORDER = "@@roleList/CHANGE_ORDER",
  ROLE_LIST_CHANGE_PAGE = "@@roleList/CHANGE_PAGE",
  ROLE_LIST_CHANGE_FILTER = "@@roleList/CHANGE_FILTER",
  ROLE_LIST_RESET = "@@roleList/RESET",
  ROLE_LIST_LOAD = "@@roleList/LOAD",
}

export interface IRoleListState extends IListType<IRoleItem, IFilterType> {}
