import { CancelToken } from "axios";
import { FC, ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { DemandPriorityType, IDemandItem } from "../../models/demand";
import { IApplicationState } from "../../store";
import { IFilterType } from "../../store/filterType";
import {
  changeClientDemandListFilter,
  changeClientDemandListOrder,
  changeClientDemandListPage,
  getClientDemandList,
  getClientDemandListCount,
  IClientDemandListState,
  selectClientDemandList,
  clientDemandListLoad,
} from "../../store/clientDemandList";
import FilterPanel from "../common/filter/FilterPanel";
import Grid from "../common/grid/Grid";
import { GridIconAdd, GridIconEdit } from "../common/grid/GridIcons";
import { TableCol } from "../common/grid/TableCol";
import { RightType } from "../../models/auth";
import { hasClientWriteRight } from "../../utils/rights";
import { selectIdentityRights } from "../../store/identity";
import { selectClientId } from "../../store/client";
import { useNavigate, useParams } from "react-router";
import { NavLink } from "react-router-dom";
import { getClassByDate } from "../../utils/date";
import { DATE_FORMAT, DATE_TIME_FORMAT } from "../../utils/consts";
import { format } from "date-fns";
import {
  IChangeDemandListState,
  selectChangeDemandList,
} from "../../store/changeDemandList";
import {
  getChangeDemandList,
  getChangeDemandListCount,
  changeDemandLoad,
  changeChangeDemandListOrder,
  changeChangeDemandListPage,
  changeChangeDemandListFilter,
} from "../../store/changeDemandList";
import Input from "../common/form/Input";
import {
  ChangeDemandStateType,
  IChangeDemandItem,
} from "../../models/changeDemads";
import InputSelectSeparator from "../common/form/InputSelectSeparator";

interface IProps {
  prov: IClientDemandListState;
  prov2: IChangeDemandListState;
  clientId?: number;
  identityRights?: RightType[];
  getClientDemandList(clientId: number, cancelToken: CancelToken): void;
  getClientDemandListCount(clientId: number, cancelToken: CancelToken): void;
  changeClientDemandListOrder(orderBy: string, orderDesc: boolean): void;
  changeClientDemandListPage(page: number): void;
  changeClientDemandListFilter(filter: IFilterType): void;
  clientDemandListLoad(reload: boolean): void;
  // changeDemand
  getChangeDemandList(cancelToken: CancelToken, clientId?: number): void;
  getChangeDemandListCount(cancelToken: CancelToken, clientId?: number): void;
  changeDemandLoad(reload: boolean): void;
  changeChangeDemandListOrder(orderBy: string, orderDesc: boolean): void;
  changeChangeDemandListPage(page: number): void;
  changeChangeDemandListFilter(filter: IFilterType): void;
}

const ClientDemand: FC<IProps> = ({
  prov,
  clientId,
  identityRights,
  getClientDemandList,
  getClientDemandListCount,
  changeClientDemandListOrder,
  changeClientDemandListPage,
  changeClientDemandListFilter,
  clientDemandListLoad,
  // changeDemand
  prov2,
  getChangeDemandList,
  getChangeDemandListCount,
  changeDemandLoad,
  changeChangeDemandListOrder,
  changeChangeDemandListPage,
  changeChangeDemandListFilter,
}) => {
  const { t } = useTranslation();
  const { clientId: paramClientId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    clientDemandListLoad(true);
  }, [clientDemandListLoad]);

  useEffect(() => {
    changeDemandLoad(true);
  }, [changeDemandLoad]);

  const handleRenderData = (item: IDemandItem): ReactElement => {
    return (
      <>
        <TableCol>{t("demand.states." + item.state)}</TableCol>
        <TableCol>
          <span className={getClassByDate(item.deadLine)}>
            {format(item.deadLine, DATE_TIME_FORMAT)}
          </span>
        </TableCol>
        <TableCol>
          <span
            className={
              item.priority === DemandPriorityType.High ? "error" : undefined
            }
          >
            {t("demand.priorities." + item.priority)}
          </span>
        </TableCol>
        <TableCol>{item.name}</TableCol>
        <TableCol>{item.assignedName}</TableCol>
        <TableCol>
          <NavLink to={`/client/${paramClientId}/demand/${item.id}`}>
            <GridIconEdit />
          </NavLink>
        </TableCol>
      </>
    );
  };

  const handleRowClick = (item: IDemandItem) => {
    navigate(`/client/${paramClientId}/demand/${item.id}`);
  };

  const handleGetData = (cancelToken: CancelToken) =>
    getClientDemandList(clientId!, cancelToken);

  const handleGetCount = (cancelToken: CancelToken) =>
    getClientDemandListCount(clientId!, cancelToken);

  const getTextForState = (state: ChangeDemandStateType): string => {
    switch (state) {
      case ChangeDemandStateType.New:
        return t("changeDemand.stateFilter.new");
      case ChangeDemandStateType.Accepted:
        return t("changeDemand.stateFilter.accepted");
      case ChangeDemandStateType.Decline:
        return t("changeDemand.stateFilter.declined");
      default:
        return "";
    }
  };

  const handleRowClick2 = (item: IChangeDemandItem) => {
    navigate(`/change-demand/${item.id}`);
  };

  const renderChangeDemandData = (item: IChangeDemandItem): ReactElement => {
    return (
      <>
        <TableCol>{getTextForState(item.state)}</TableCol>
        <TableCol>{t("changeDemand.changePersonalData")}</TableCol>
        <TableCol>{format(item.createdAt, DATE_FORMAT)}</TableCol>
        <TableCol>{item.clientName}</TableCol>
        <TableCol />
      </>
    );
  };

  return (
    <>
      <FilterPanel
        title={t("demand.pageTitle")}
        name="clientDemand"
        filter={prov.filter!}
        changeFilter={changeClientDemandListFilter}
      />
      <Grid<IDemandItem>
        headers={[
          { captionStr: "client.demand.state" },
          { captionStr: "client.demand.deadLine" },
          { captionStr: "client.demand.priority" },
          { captionStr: "client.demand.name" },
          { captionStr: "client.demand.assignedName" },
          {
            captionEl: hasClientWriteRight(identityRights, [
              RightType.WriteClientDemand,
            ]) ? (
              <NavLink to={`/client/${paramClientId}/demand/new`}>
                <GridIconAdd />
              </NavLink>
            ) : undefined,
          },
        ]}
        renderData={handleRenderData}
        getData={handleGetData}
        getCount={handleGetCount}
        changeOrder={changeClientDemandListOrder}
        changePage={changeClientDemandListPage}
        onRowClick={handleRowClick}
        prov={prov}
      />

      <br />

      <FilterPanel
        title={t("changeDemand.clientPageTitle")}
        name="changeDemand"
        filter={prov2.filter!}
        changeFilter={changeChangeDemandListFilter}
        initialValues={{}}
      >
        <Input name="state" component="select" label={t("demand.state")}>
          <option value={ChangeDemandStateType.All}>
            {t("demand.stateFilter.all")}
          </option>
          <InputSelectSeparator />
          <option value={ChangeDemandStateType.New}>
            {t("changeDemand.stateFilter.new")}
          </option>
          <option value={ChangeDemandStateType.Accepted}>
            {t("changeDemand.stateFilter.accepted")}
          </option>
          <option value={ChangeDemandStateType.Decline}>
            {t("changeDemand.stateFilter.declined")}
          </option>
        </Input>
      </FilterPanel>

      <Grid<IChangeDemandItem>
        headers={[
          {
            captionStr: "changeDemand.state",
            orderName: "state",
            minContent: true,
          },
          { captionStr: "changeDemand.requestName" },
          {
            captionStr: "changeDemand.created",
            orderName: "createdAt",
            minContent: true,
          },
          { captionStr: "changeDemand.assignedName" },
          {},
        ]}
        renderData={renderChangeDemandData}
        getData={(cancelToken: CancelToken) =>
          getChangeDemandList(cancelToken, clientId)
        }
        getCount={(cancelToken) => {
          getChangeDemandListCount(cancelToken, clientId);
        }}
        changeOrder={changeChangeDemandListOrder}
        changePage={changeChangeDemandListPage}
        onRowClick={handleRowClick2}
        prov={prov2}
      />
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    prov: selectClientDemandList(state),
    prov2: selectChangeDemandList(state),
    clientId: selectClientId(state),
    identityRights: selectIdentityRights(state),
  };
};

const mapDispachToProps = {
  // demand
  getClientDemandList,
  getClientDemandListCount,
  changeClientDemandListOrder,
  changeClientDemandListPage,
  changeClientDemandListFilter,
  clientDemandListLoad,
  // changeDemand
  getChangeDemandList,
  getChangeDemandListCount,
  changeDemandLoad,
  changeChangeDemandListOrder,
  changeChangeDemandListPage,
  changeChangeDemandListFilter,
};

export default connect(mapStateToProps, mapDispachToProps)(ClientDemand);
