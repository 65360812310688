import { createAction } from "@reduxjs/toolkit";
import { IItemIdName } from "../../models/common";
import { TemplateFolderTypes } from "./types";

export const templateFolderFetchRequest = createAction<
  void,
  TemplateFolderTypes.TEMPLATE_FOLDER_FETCH_REQUEST
>(TemplateFolderTypes.TEMPLATE_FOLDER_FETCH_REQUEST);

export const templateFolderFetchSuccess = createAction<
  IItemIdName[],
  TemplateFolderTypes.TEMPLATE_FOLDER_FETCH_SUCCESS
>(TemplateFolderTypes.TEMPLATE_FOLDER_FETCH_SUCCESS);

export const templateFolderFetchError = createAction<
  void,
  TemplateFolderTypes.TEMPLATE_FOLDER_FETCH_ERROR
>(TemplateFolderTypes.TEMPLATE_FOLDER_FETCH_ERROR);
