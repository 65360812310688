import * as ReselectModule from "reselect";
import { IApplicationState } from "..";
import { ISearchFilter } from ".";
import { StoreState } from "../storeState";
import { ISearchFoundItem } from "../../models/search";

export const selectSearchFilter: ReselectModule.Selector<
  IApplicationState,
  ISearchFilter
> = (state) => state.search.filter;

export const selectSearchState: ReselectModule.Selector<
  IApplicationState,
  StoreState
> = (state) => state.search.state;

export const selectSearchData: ReselectModule.Selector<
  IApplicationState,
  ISearchFoundItem[]
> = (state) => state.search.data;
