export interface IDiagramSettings {
  disableClick: boolean;
  linkColor: boolean;
  linkTop: boolean;
  showEnd: boolean;
  linkCurved: boolean;
  moveNodes: boolean;
  moveLinks: boolean;
  pathFind: boolean;
  historyHide: boolean;
  historyItems: boolean;
  dagreEnabled: boolean;
  dagreLinks: boolean;
  dagreRanker: string;
  dagreRankdir: string;
  dagreAlign: string;
}

export const DIAGRAM_SETTINGS_DEFAULT: IDiagramSettings = {
  disableClick: false,
  linkColor: true,
  linkTop: true,
  showEnd: true,
  linkCurved: true,
  moveNodes: false,
  moveLinks: false,
  pathFind: false,
  historyHide: false,
  historyItems: false,
  dagreEnabled: true,
  dagreLinks: true,
  dagreRanker: "network-simplex",
  dagreRankdir: "TB",
  dagreAlign: "UD",
};

export interface IDiagramSettingsDto {
  data: string;
}
