import { AppThunkAction, AppThunkActionAsync } from "..";
import * as actions from "./actions";
import careTypeApi from "../../api/careType";
import { ICareType } from "../../models/careType";

export function getCareType(careTypeId: number): AppThunkActionAsync {
  return async (dispatch) => {
    dispatch(actions.careTypeFetchRequest());
    try {
      const careType = await careTypeApi.getCareType(careTypeId);
      dispatch(actions.careTypeFetchSuccess(careType.data));
    } catch {
      dispatch(actions.careTypeFetchError());
    }
  };
}

export function getDefaultCareType(): AppThunkAction {
  return (dispatch) => {
    const data = {
      id: 0,
      isActive: true,
      name: "",
      description: "",
      assistanceCategoryListingId: 0,
      assistanceCategoryListingName: "",
    };

    dispatch(actions.careTypeFetchSuccess(data));
  };
}

export function updateCareType(newData: ICareType): AppThunkAction {
  return (dispatch) => {
    dispatch(actions.careTypeFetchSuccess(newData));
  };
}
