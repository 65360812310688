import { FC, ReactNode, useRef, useState } from "react";
import HeaderMenu from "./HeaderMenu";
import {
  HeaderLogo,
  HeaderContainer,
  HeaderIconButton,
  HeaderUserButton,
  HeaderUserName,
  HeaderUserSection,
  HeaderSearchLink,
} from "./HeaderStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faSearch, faUser } from "@fortawesome/free-solid-svg-icons";
import HeaderUserMenu from "./HeaderUserMenu";
import { IApplicationState } from "../../store";
import { selectIdentityName, selectIdentityState } from "../../store/identity";
import { connect } from "react-redux";
import { StoreState } from "../../store/storeState";
import { useOnClickOutside } from "../../utils/hooks";
import Logo from "../../images/logo.svg";
import { NavLink } from "react-router-dom";
import { ISidebarLink } from "../../models/common";

interface IProps {
  identityState: StoreState;
  identityName?: string;
  sidebarTitle: string | ReactNode | undefined;
  sidebarLinks: ISidebarLink[] | undefined;
}

const Header: FC<IProps> = ({
  identityState,
  identityName,
  sidebarTitle,
  sidebarLinks,
}) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [openUser, setOpenUser] = useState(false);

  const refMenu = useRef(null);
  const refMenuButton = useRef(null);
  useOnClickOutside(refMenu, refMenuButton, () => setOpenMenu(false));

  const refUser = useRef(null);
  const refUserButton = useRef(null);
  useOnClickOutside(refUser, refUserButton, () => setOpenUser(false));

  if (identityState !== StoreState.Loaded) {
    return (
      <HeaderContainer>
        <NavLink to="/">
          <HeaderLogo src={Logo} />
        </NavLink>
      </HeaderContainer>
    );
  }

  return (
    <>
      <nav ref={refMenu}>
        <HeaderMenu
          open={openMenu}
          setOpen={setOpenMenu}
          sidebarTitle={sidebarTitle}
          sidebarLinks={sidebarLinks}
        />
      </nav>
      <nav ref={refUser}>
        <HeaderUserMenu open={openUser} setOpen={setOpenUser} />
      </nav>
      <HeaderContainer>
        <HeaderIconButton
          ref={refMenuButton}
          className={openMenu ? "active" : undefined}
          onClick={() => setOpenMenu((prev) => !prev)}
        >
          <FontAwesomeIcon icon={faBars} size="3x" />
        </HeaderIconButton>
        <NavLink to="/">
          <HeaderLogo src={Logo} />
        </NavLink>
        <HeaderUserSection>
          <HeaderSearchLink to="/search">
            <FontAwesomeIcon icon={faSearch} size="2x" />
          </HeaderSearchLink>
          <HeaderUserName>{identityName}</HeaderUserName>
          <HeaderUserButton
            ref={refUserButton}
            className={openUser ? "active" : undefined}
            onClick={() => setOpenUser((prev) => !prev)}
          >
            <FontAwesomeIcon icon={faUser} size="3x" />
          </HeaderUserButton>
        </HeaderUserSection>
      </HeaderContainer>
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    identityState: selectIdentityState(state),
    identityName: selectIdentityName(state),
  };
};

export default connect(mapStateToProps)(Header);
