import { CancelToken } from "axios";
import { FC, ReactElement, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { IAssistanceDocumentItem } from "../../models/assistance";
import { IApplicationState } from "../../store";
import { IFilterType } from "../../store/filterType";
import {
  changeAssistanceDocumentListFilter,
  changeAssistanceDocumentListOrder,
  changeAssistanceDocumentListPage,
  getAssistanceDocumentList,
  getAssistanceDocumentListCount,
  IAssistanceDocumentListState,
  selectAssistanceDocumentList,
  assistanceDocumentListLoad,
} from "../../store/assistanceDocumentList";
import FilterPanel from "../common/filter/FilterPanel";
import Grid from "../common/grid/Grid";
import {
  GridIconAdd,
  GridIconDownload,
  GridIconEdit,
  GridIconFromTemplate,
  GridIconSpace,
  GridIconUpload,
} from "../common/grid/GridIcons";
import { TableCol } from "../common/grid/TableCol";
import { RightType } from "../../models/auth";
import { hasRight } from "../../utils/rights";
import { selectIdentityRights } from "../../store/identity";
import { selectAssistanceId } from "../../store/assistance";
import { DivTextRight } from "../../styles/align";
import SelectTemplateModal from "../selectTemplate/SelectTemplateModal";
import { ISelectedFile } from "../selectTemplate/SelectTemplateTree";
import assistanceApi from "../../api/assistance";
import { TemplateDocumentType } from "../../models/template";
import bucketApi from "../../api/bucket";
import AssistanceDocumentAddModal from "./AssistanceDocumentAddModal";
import { startDocumentEditOnline } from "../document/DocumentEditOnlineStart";
import { promiseToast, promiseToastSaveNoException } from "../../utils/toasts";
import { handleLoadUserSuggestions } from "../../utils/suggestions";
import {
  CalendarModalTimesContainer,
  CalendarModalTimes,
  CalendarModalTimesLabel,
} from "../calendar/CalendarStyles";
import DatePicker from "../common/form/DatePicker";
import SuggestionFormik from "../common/suggestion/SuggestionFormik";
import { format } from "date-fns";
import { DATE_TIME_FORMAT } from "../../utils/consts";

interface IProps {
  prov: IAssistanceDocumentListState;
  assistanceId?: number;
  identityRights?: RightType[];
  getAssistanceDocumentList(
    assistanceId: number,
    cancelToken: CancelToken
  ): void;
  getAssistanceDocumentListCount(
    assistanceId: number,
    cancelToken: CancelToken
  ): void;
  changeAssistanceDocumentListOrder(orderBy: string, orderDesc: boolean): void;
  changeAssistanceDocumentListPage(page: number): void;
  changeAssistanceDocumentListFilter(filter: IFilterType): void;
  assistanceDocumentListLoad(reload: boolean): void;
}

const AssistanceDocument: FC<IProps> = ({
  prov,
  assistanceId,
  identityRights,
  getAssistanceDocumentList,
  getAssistanceDocumentListCount,
  changeAssistanceDocumentListOrder,
  changeAssistanceDocumentListPage,
  changeAssistanceDocumentListFilter,
  assistanceDocumentListLoad,
}) => {
  const { t } = useTranslation();
  const [addFile, setAddFile] = useState<File | null>(null);
  const [addIsOpen, setAddIsOpen] = useState(false);
  const [templateIsOpen, setTemplateIsOpen] = useState(false);
  const [uploadDocumentId, setUploadDocumentId] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    assistanceDocumentListLoad(false);
  }, [assistanceDocumentListLoad]);

  const handleAdd = () => {
    setUploadDocumentId(0);
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleAddClose = () => {
    setAddIsOpen(false);

    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const handleFileChange = async (files: FileList | null) => {
    if (!files || files.length < 1) {
      return;
    }

    const file = files[0];

    if (uploadDocumentId === 0) {
      setAddFile(file);
      setAddIsOpen(true);
      return;
    }

    try {
      await doUpload(file);
    } finally {
      if (inputRef.current) {
        inputRef.current.value = "";
      }
    }
  };

  const handleDownload = async (item: IAssistanceDocumentItem) => {
    try {
      await promiseToast(
        async () => {
          const url = await assistanceApi.getAssistanceDocumentDownloadUrl(
            assistanceId!,
            item.id
          );

          const link = document.createElement("a");
          link.href = url.data;
          link.download = item.name;
          link.target = "_blank";
          link.click();
        },
        t("pending.download"),
        t("success.download"),
        t("errors.unknown")
      );
    } catch {
      //Noting.
    }
  };

  const handleUpload = async (item: IAssistanceDocumentItem) => {
    setUploadDocumentId(item.id);
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const doUpload = async (file: File) => {
    await promiseToastSaveNoException(async () => {
      const uploadUrl = await assistanceApi.getAssistanceDocumentUploadUrl(
        assistanceId!,
        null,
        uploadDocumentId.toString()
      );

      await bucketApi.uploadDocument(uploadUrl.data, file);
      await assistanceApi.updateAssistanceDocument(
        assistanceId!,
        uploadDocumentId
      );

      assistanceDocumentListLoad(true);
    });
  };

  const handleFromTemplate = () => {
    setTemplateIsOpen(true);
  };

  const handleCloseTemplate = () => {
    setTemplateIsOpen(false);
  };

  const handleConfirmTemplate = async (file: ISelectedFile) => {
    await promiseToastSaveNoException(async () => {
      await assistanceApi.addAssistanceDocumentFromTemplate(
        assistanceId!,
        file.id
      );
      assistanceDocumentListLoad(true);
    });
  };

  const handleEditOnline = async (item: IAssistanceDocumentItem) => {
    await startDocumentEditOnline(item.id, t);
  };

  const handleRenderData = (item: IAssistanceDocumentItem): ReactElement => {
    return (
      <>
        <TableCol>{item.name}</TableCol>
        <TableCol>{format(item.createdAt, DATE_TIME_FORMAT)}</TableCol>
        <TableCol>{format(item.lastEditedAt, DATE_TIME_FORMAT)}</TableCol>
        <TableCol>{item.author.name}</TableCol>
        <TableCol>{item.lastEditor.name}</TableCol>
        <TableCol>
          {hasRight(
            identityRights,
            [RightType.WriteAssistances, RightType.WriteAssistanceDocuments],
            true
          ) && (
            <>
              <GridIconEdit onClick={() => handleEditOnline(item)} />
              <GridIconSpace />
            </>
          )}
          <GridIconDownload onClick={() => handleDownload(item)} />
          {hasRight(
            identityRights,
            [RightType.WriteAssistances, RightType.WriteAssistanceDocuments],
            true
          ) && (
            <>
              <GridIconSpace />
              <GridIconUpload onClick={() => handleUpload(item)} />
            </>
          )}
        </TableCol>
      </>
    );
  };

  const handleGetData = (cancelToken: CancelToken) =>
    getAssistanceDocumentList(assistanceId!, cancelToken);

  const handleGetCount = (cancelToken: CancelToken) =>
    getAssistanceDocumentListCount(assistanceId!, cancelToken);

  return (
    <>
      <input
        ref={inputRef}
        type="file"
        hidden
        onChange={({ target }) => handleFileChange(target.files)}
      />
      <AssistanceDocumentAddModal
        file={addFile}
        assistanceId={assistanceId!}
        isOpen={addIsOpen}
        close={handleAddClose}
      />
      <SelectTemplateModal
        type={TemplateDocumentType.Assistance}
        isOpen={templateIsOpen}
        close={handleCloseTemplate}
        confirm={handleConfirmTemplate}
      />

      <FilterPanel
        name="assistanceDocument"
        filter={prov.filter!}
        changeFilter={changeAssistanceDocumentListFilter}
        initialValues={{
          createdFrom: prov.filter!.createdFrom,
          createdTo: prov.filter!.createdTo,
          lastEditFrom: prov.filter!.lastEditFrom,
          lastEditTo: prov.filter!.lastEditTo,
          authorId: prov.filter!.authorId,
          authorName: prov.filter!.authorName,
          lastEditorId: prov.filter!.lastEditorId,
          lastEditorName: prov.filter!.lastEditorName,
        }}
      >
        <CalendarModalTimesContainer>
          <label>{t("document.createdAt")}</label>
          <CalendarModalTimes>
            <div>
              <CalendarModalTimesLabel>
                {t("common.from")}
              </CalendarModalTimesLabel>
              <DatePicker name={"createdFrom"} />
            </div>
            <div>
              <CalendarModalTimesLabel>
                {t("common.to")}
              </CalendarModalTimesLabel>
              <DatePicker name={"createdTo"} />
            </div>
          </CalendarModalTimes>
        </CalendarModalTimesContainer>
        <CalendarModalTimesContainer>
          <label>{t("document.lastEditedAt")}</label>
          <CalendarModalTimes>
            <div>
              <CalendarModalTimesLabel>
                {t("common.from")}
              </CalendarModalTimesLabel>
              <DatePicker name={"lastEditFrom"} />
            </div>
            <div>
              <CalendarModalTimesLabel>
                {t("common.to")}
              </CalendarModalTimesLabel>
              <DatePicker name={"lastEditTo"} />
            </div>
          </CalendarModalTimes>
        </CalendarModalTimesContainer>
        <SuggestionFormik
          nameId="authorId"
          nameText="authorName"
          label={t("document.author")}
          loadSuggestions={handleLoadUserSuggestions}
        />
        <SuggestionFormik
          nameId="lastEditorId"
          nameText="lastEditorName"
          label={t("document.lastEditor")}
          loadSuggestions={handleLoadUserSuggestions}
        />
      </FilterPanel>
      <Grid<IAssistanceDocumentItem>
        headers={[
          {
            captionStr: "document.name",
            orderName: "Name",
          },
          {
            captionStr: "document.createdAt",
            orderName: "CreatedAt",
          },
          {
            captionStr: "document.lastEditedAt",
            orderName: "LastEditedAt",
          },
          {
            captionStr: "document.author",
            orderName: "Author",
          },
          {
            captionStr: "document.lastEditor",
            orderName: "LastEditor",
          },
          {
            captionEl: hasRight(
              identityRights,
              [RightType.WriteAssistances, RightType.WriteAssistanceDocuments],
              true
            ) ? (
              <DivTextRight>
                <GridIconAdd onClick={handleAdd} />
                {hasRight(identityRights, [RightType.ReadAllTemplates]) && (
                  <>
                    <GridIconSpace />
                    <GridIconFromTemplate onClick={handleFromTemplate} />
                  </>
                )}
              </DivTextRight>
            ) : undefined,
          },
        ]}
        renderData={handleRenderData}
        getData={handleGetData}
        getCount={handleGetCount}
        changeOrder={changeAssistanceDocumentListOrder}
        changePage={changeAssistanceDocumentListPage}
        prov={prov}
      />
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    prov: selectAssistanceDocumentList(state),
    assistanceId: selectAssistanceId(state),
    identityRights: selectIdentityRights(state),
  };
};

const mapDispachToProps = {
  getAssistanceDocumentList,
  getAssistanceDocumentListCount,
  changeAssistanceDocumentListOrder,
  changeAssistanceDocumentListPage,
  changeAssistanceDocumentListFilter,
  assistanceDocumentListLoad,
};

export default connect(mapStateToProps, mapDispachToProps)(AssistanceDocument);
