import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ClientStateType, IClient } from "../../models/client";
import { IApplicationState } from "../../store";
import { selectClient, updateClient } from "../../store/client";
import { ApiError, FormGroup } from "../../styles/form";
import { RightType } from "../../models/auth";
import { selectIdentityRights } from "../../store/identity";
import { hasRight } from "../../utils/rights";
import clientApi from "../../api/client";
import { promiseToast, promiseToastSave } from "../../utils/toasts";
import { clientListLoad } from "../../store/clientList";
import { format } from "date-fns";
import { DATE_FORMAT } from "../../utils/consts";
import { SetState } from "../../utils/types";
import { Formik } from "formik";
import * as Yup from "yup";
import Input from "../common/form/Input";
import { ModalYesNoFunction } from "../common/modal/ModalFunctions";
import {
  ClientButtomSubmit,
  ClientButtonAnonymize,
  ClientButtonButton,
  ClientButtonForm,
  ClientButtonRight,
} from "./ClientButtonStyles";
import { errorSet } from "../../utils/error";

interface ISecretForm {
  secret: string;
}

interface IProps {
  client: IClient | null;
  identityRights?: RightType[];
  isWorking: boolean;
  setIsWorking: SetState<boolean>;
  updateClient(newData: IClient): void;
  clientListLoad(reload: boolean): void;
}

const ClientButton: FC<IProps> = ({
  client,
  identityRights,
  isWorking,
  setIsWorking,
  updateClient,
  clientListLoad,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);
  const [codeSended, setCodeSended] = useState(false);

  if (!client!.fullRegistration) {
    return null;
  }

  const getGdprSignText = () => {
    let text = client!.gdprAgreementWithoutSecret
      ? t("client.button.gdprByUser")
      : t("client.button.gdprByClient");
    text = text
      .replaceAll("{day}", format(client!.gdprAgreementAt!, DATE_FORMAT))
      .replaceAll("{user}", client!.gdprAgreementAddedByUser?.name ?? "");
    return text;
  };

  const addAgreement = async (secret?: string | null) => {
    setIsWorking(true);
    setError(null);
    try {
      await promiseToastSave(async () => {
        const response = await clientApi.clientAddGdprAgreement(
          client!.id,
          secret
        );
        updateClient(response.data);
        clientListLoad(true);
      });
    } catch (err) {
      errorSet(setError, err, t);
    }
    setIsWorking(false);
  };

  const handleSendCode = async () => {
    setIsWorking(true);
    setError(null);
    try {
      await promiseToast(
        async () => {
          await clientApi.clientSendGdprAgreementCode(client!.id);
          setCodeSended(true);
        },
        t("client.button.gdprCodePending"),
        t("client.button.gdprCodeSucess"),
        t("errors.unknown")
      );
    } catch (err) {
      errorSet(setError, err, t);
    }
    setIsWorking(false);
  };

  const handleAgreementWithoutCode = async () => {
    await addAgreement();
  };

  const handleSecretSubmit = async (data: ISecretForm) => {
    await addAgreement(data.secret);
  };

  const handleAnonymize = async () => {
    setIsWorking(true);
    setError(null);
    try {
      const res = await ModalYesNoFunction(
        t("client.button.anonymize"),
        t("client.button.anonymizeConfirm")
      );
      if (res) {
        await promiseToastSave(async () => {
          const response = await clientApi.anonymiseClient(client!.id);
          updateClient(response.data);
          clientListLoad(true);
        });
      }
    } catch (err) {
      errorSet(setError, err, t);
    }
    setIsWorking(false);
  };

  let agreementText: JSX.Element | null = null;
  let agreementContent: JSX.Element | null = null;
  let anonymizeButton: JSX.Element | null = null;

  if (client!.fullRegistration) {
    if (client!.gdprAgreementAt) {
      agreementText = <p>{getGdprSignText()}</p>;
    } else {
      agreementContent = (
        <>
          {hasRight(identityRights, [RightType.AddClientGdprAgreement]) && (
            <>
              {codeSended ? (
                <>
                  <Formik<ISecretForm>
                    initialValues={{ secret: "" }}
                    enableReinitialize={true}
                    validationSchema={Yup.object({
                      secret: Yup.number()
                        .required(t("client.button.gdprSecretNotValid"))
                        .moreThan(99999, t("client.button.gdprSecretNotValid"))
                        .lessThan(
                          1000000,
                          t("client.button.gdprSecretNotValid")
                        ),
                    })}
                    validateOnMount={true}
                    onSubmit={handleSecretSubmit}
                  >
                    {({ errors, touched }) => (
                      <ClientButtonForm>
                        <FormGroup labelWidth="max-content">
                          <Input
                            name="secret"
                            label={t("client.button.gdprSecret")}
                            type="number"
                            error={touched.secret && !!errors.secret}
                            maxLength={6}
                          />
                        </FormGroup>
                        <ClientButtomSubmit disabled={isWorking} />
                      </ClientButtonForm>
                    )}
                  </Formik>
                </>
              ) : (
                <ClientButtonButton
                  disabled={isWorking}
                  onClick={handleSendCode}
                  ver="primary"
                >
                  {t("client.button.gdprWithCode")}
                </ClientButtonButton>
              )}
            </>
          )}
          {hasRight(identityRights, [
            RightType.AddClientGdprAgreementWithoutSecret,
          ]) && (
            <ClientButtonButton
              disabled={isWorking}
              onClick={handleAgreementWithoutCode}
              ver={
                hasRight(identityRights, [RightType.AddClientGdprAgreement])
                  ? "secondary"
                  : "primary"
              }
            >
              {t("client.button.gdprWithoutCode")}
            </ClientButtonButton>
          )}
        </>
      );
    }
  }

  if (
    client!.state !== ClientStateType.Anonymised &&
    hasRight(identityRights, [RightType.AnonymiseClient])
  ) {
    anonymizeButton = (
      <ClientButtonAnonymize
        disabled={isWorking}
        onClick={handleAnonymize}
        ver="secondary"
      >
        {t("client.button.anonymize")}
      </ClientButtonAnonymize>
    );
  }

  return (
    <>
      <hr />
      <div>
        <h2>{t("client.button.gdprTitle")}</h2>
        {error && (
          <ApiError style={{ marginTop: 0, marginBottom: "1rem" }}>
            {error}
          </ApiError>
        )}
        {agreementText}
        {(!!agreementContent || !!anonymizeButton) && (
          <ClientButtonRight>
            {agreementContent}
            {anonymizeButton}
          </ClientButtonRight>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    client: selectClient(state),
    identityRights: selectIdentityRights(state),
  };
};

const mapDispachToProps = {
  updateClient,
  clientListLoad,
};

export default connect(mapStateToProps, mapDispachToProps)(ClientButton);
