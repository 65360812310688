import { createAction } from "@reduxjs/toolkit";
import { IWorkflowTemplateItem } from "../../models/workflowTemplate";
import { IFilterType } from "../filterType";
import { StoreState, StoreStateDontLoad } from "../storeState";
import { WorkflowTemplateListTypes } from "./types";

export const workflowTemplateListFetchRequest = createAction<
  StoreStateDontLoad,
  WorkflowTemplateListTypes.WORKFLOW_TEMPLATE_LIST_FETCH_REQUEST
>(WorkflowTemplateListTypes.WORKFLOW_TEMPLATE_LIST_FETCH_REQUEST);

export const workflowTemplateListFetchSuccess = createAction<
  {
    data: IWorkflowTemplateItem[];
    newCountState: StoreStateDontLoad;
    newCount: number;
  },
  WorkflowTemplateListTypes.WORKFLOW_TEMPLATE_LIST_FETCH_SUCCESS
>(WorkflowTemplateListTypes.WORKFLOW_TEMPLATE_LIST_FETCH_SUCCESS);

export const workflowTemplateListFetchError = createAction<
  boolean,
  WorkflowTemplateListTypes.WORKFLOW_TEMPLATE_LIST_FETCH_ERROR
>(WorkflowTemplateListTypes.WORKFLOW_TEMPLATE_LIST_FETCH_ERROR);

export const workflowTemplateListCountRequest = createAction<
  void,
  WorkflowTemplateListTypes.WORKFLOW_TEMPLATE_LIST_COUNT_REQUEST
>(WorkflowTemplateListTypes.WORKFLOW_TEMPLATE_LIST_COUNT_REQUEST);

export const workflowTemplateListCountSuccess = createAction<
  number,
  WorkflowTemplateListTypes.WORKFLOW_TEMPLATE_LIST_COUNT_SUCCESS
>(WorkflowTemplateListTypes.WORKFLOW_TEMPLATE_LIST_COUNT_SUCCESS);

export const workflowTemplateListCountError = createAction<
  boolean,
  WorkflowTemplateListTypes.WORKFLOW_TEMPLATE_LIST_COUNT_ERROR
>(WorkflowTemplateListTypes.WORKFLOW_TEMPLATE_LIST_COUNT_ERROR);

export const workflowTemplateListChangeOrder = createAction<
  { orderBy: string; orderDesc: boolean },
  WorkflowTemplateListTypes.WORKFLOW_TEMPLATE_LIST_CHANGE_ORDER
>(WorkflowTemplateListTypes.WORKFLOW_TEMPLATE_LIST_CHANGE_ORDER);

export const workflowTemplateListChangePage = createAction<
  number,
  WorkflowTemplateListTypes.WORKFLOW_TEMPLATE_LIST_CHANGE_PAGE
>(WorkflowTemplateListTypes.WORKFLOW_TEMPLATE_LIST_CHANGE_PAGE);

export const workflowTemplateListChangeFilter = createAction<
  IFilterType,
  WorkflowTemplateListTypes.WORKFLOW_TEMPLATE_LIST_CHANGE_FILTER
>(WorkflowTemplateListTypes.WORKFLOW_TEMPLATE_LIST_CHANGE_FILTER);

export const workflowTemplateListReset = createAction<
  void,
  WorkflowTemplateListTypes.WORKFLOW_TEMPLATE_LIST_RESET
>(WorkflowTemplateListTypes.WORKFLOW_TEMPLATE_LIST_RESET);

export const workflowTemplateListLoadAction = createAction<
  { newState: StoreState; newCountState: StoreStateDontLoad },
  WorkflowTemplateListTypes.WORKFLOW_TEMPLATE_LIST_LOAD
>(WorkflowTemplateListTypes.WORKFLOW_TEMPLATE_LIST_LOAD);
