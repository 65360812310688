import { AppThunkAction, AppThunkActionAsync } from "..";
import * as actions from "./actions";
import templateApi from "../../api/template";
import { selectTemplateTree } from ".";
import { StoreState } from "../storeState";
import { TemplateDocumentType } from "../../models/template";

export function getTemplateTree(
  id: number,
  type: TemplateDocumentType | null
): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectTemplateTree(getState(), id);
    if (
      state?.state === StoreState.Loaded ||
      state?.state === StoreState.Loading ||
      //Error causes a loop, dont load again.
      state?.state === StoreState.Error
    ) {
      return;
    }

    const sid = id.toString();
    dispatch(actions.templateTreeFetchRequest(sid));
    try {
      const params = new URLSearchParams();
      if (id) {
        params.append("idFolder", sid);
      }

      if (type) {
        params.append("usedFor", type);
      }

      const folders = await templateApi.getFolderList({ params });
      const files = await templateApi.getDocumentList({ params });

      const list1 = folders.data.map((x) => ({
        id: x.id,
        name: x.name,
        isFolder: true,
      }));
      const list2 = files.data.map((x) => ({
        id: x.id,
        name: x.name,
        isFolder: false,
      }));

      const allList = list1.concat(list2);

      dispatch(actions.templateTreeFetchSuccess({ id: sid, data: allList }));
    } catch {
      dispatch(actions.templateTreeFetchError(sid));
    }
  };
}

export function resetTemplateTree(): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.templateTreeReset());
  };
}
