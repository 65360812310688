import { IBasicSettings } from "../../models/basic";
import { StoreState } from "../storeState";

export enum BasicSettingsTypes {
  BASIC_SETTINGS_FETCH_REQUEST = "@@basicSettings/FETCH_REQUEST",
  BASIC_SETTINGS_FETCH_SUCCESS = "@@basicSettings/FETCH_SUCCESS",
  BASIC_SETTINGS_FETCH_ERROR = "@@basicSettings/FETCH_ERROR",
}

export interface IBasicSettingsState {
  state: StoreState;
  data: IBasicSettings | null;
}
