import { IItemIdName } from "../../models/common";
import { IUserItem } from "../../models/user";
import { IFilterType } from "../filterType";
import { IListType } from "../listType";

export enum UserListTypes {
  USER_LIST_FETCH_REQUEST = "@@userList/FETCH_REQUEST",
  USER_LIST_FETCH_SUCCESS = "@@userList/FETCH_SUCCESS",
  USER_LIST_FETCH_ERROR = "@@userList/FETCH_ERROR",
  USER_LIST_COUNT_REQUEST = "@@userList/COUNT_REQUEST",
  USER_LIST_COUNT_SUCCESS = "@@userList/COUNT_SUCCESS",
  USER_LIST_COUNT_ERROR = "@@userList/COUNT_ERROR",
  USER_LIST_CHANGE_ORDER = "@@userList/CHANGE_ORDER",
  USER_LIST_CHANGE_PAGE = "@@userList/CHANGE_PAGE",
  USER_LIST_CHANGE_FILTER = "@@userList/CHANGE_FILTER",
  USER_LIST_RESET = "@@userList/RESET",
  USER_LIST_LOAD = "@@userList/LOAD",
}

export interface IUserListFilter extends IFilterType {
  activeOnly: boolean;
  assistanceId: number;
  assistanceName: string;
  groups: IItemIdName[];
}

export interface IUserListState extends IListType<IUserItem, IUserListFilter> {}
