import { FC } from "react";
import {
  AbstractModelFactory,
  AbstractReactFactory,
  GenerateWidgetEvent,
} from "@projectstorm/react-canvas-core";
import {
  DiagramEngine,
  LinkModel,
  NodeModel,
  NodeModelGenerics,
  PortModelAlignment,
} from "@projectstorm/react-diagrams-core";
import {
  DefaultLinkModel,
  DefaultPortModel,
  RightAngleLinkModel,
} from "@projectstorm/react-diagrams";
import {
  PortWidgetWrapperTop,
  PortWidgetWrapperBottom,
  StyledPortWidget,
  DiagramWorkflowWrapper,
  DiagramTopPortMode,
  DiagramTitle,
  DiagramStateIcon,
} from "./DiagramStyles";
import { DemandStateType, IDemandHistoryItem } from "../../../models/demand";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { COLORS } from "../../../styles/colors";
import { WorkflowDefinitionItemTopMode } from "../../../models/workflow";
import { useTranslation } from "react-i18next";
import { store } from "../../../store";
import { selectDiagramSettings } from "../../../store/diagramSettings";

const NAME = "workflow";

class Factory extends AbstractReactFactory<DiagramWorkflowNode, DiagramEngine> {
  constructor() {
    super(NAME);
  }

  generateReactWidget(
    event: GenerateWidgetEvent<DiagramWorkflowNode>
  ): JSX.Element {
    return <Widget engine={this.engine} node={event.model} />;
  }

  generateModel() {
    return new DiagramWorkflowNode({
      id: 0,
      name: "",
      topPortMode: WorkflowDefinitionItemTopMode.AtLeastOne,
    });
  }
}

interface NodeProps {
  id: number;
  name: string;
  topPortMode: WorkflowDefinitionItemTopMode;
  disableClick?: boolean;
  notFound?: boolean;
  historyMode?: boolean;
  historyItem?: IDemandHistoryItem;
  highlight?: boolean;
  isLocked?: boolean;
}

export class DiagramWorkflowNode extends NodeModel<NodeModelGenerics> {
  constructor(data: NodeProps) {
    super({
      type: NAME,
      extras: data,
    });
    this.addPort(new Port(PortModelAlignment.TOP));
    this.addPort(new Port(PortModelAlignment.BOTTOM));
    this.setLocked(data.isLocked);
  }

  myPortTop() {
    return this.getPort(PortModelAlignment.TOP) as Port;
  }

  myPortBottom() {
    return this.getPort(PortModelAlignment.BOTTOM) as Port;
  }

  myData() {
    return this.options.extras as NodeProps;
  }
}

interface WidgetProps {
  node: DiagramWorkflowNode;
  engine: DiagramEngine;
}

const Widget: FC<WidgetProps> = ({ node, engine }) => {
  const { t } = useTranslation();
  const portTop = node.myPortTop();
  const portBottom = node.myPortBottom();
  const data = node.myData();

  const portTopLinksCount = Object.keys(portTop.getLinks()).length;

  const name =
    (!!data.historyMode ? t("diagram.beginning") + " " : "") + data.name;

  let icon = null;
  if (data.historyItem && data.historyItem.state === DemandStateType.Finished) {
    icon = (
      <DiagramStateIcon icon={faCircleCheck} color={COLORS.successIconColor} />
    );
  }

  return (
    <DiagramWorkflowWrapper
      highlight={!!data.highlight}
      canClick={!data.disableClick}
      notFound={!!data.notFound}
      historyMode={!!data.historyItem}
    >
      <PortWidgetWrapperTop>
        <StyledPortWidget port={portTop} engine={engine} />
      </PortWidgetWrapperTop>
      {portTopLinksCount > 1 && <DiagramTopPortMode mode={data.topPortMode} />}
      <DiagramTitle title={name}>
        {icon}
        {name}
      </DiagramTitle>
      <PortWidgetWrapperBottom>
        <StyledPortWidget port={portBottom} engine={engine} />
      </PortWidgetWrapperBottom>
    </DiagramWorkflowWrapper>
  );
};

class Port extends DefaultPortModel {
  constructor(alignment: PortModelAlignment) {
    super({
      type: NAME,
      name: alignment,
      alignment: alignment,
      in: alignment === PortModelAlignment.TOP,
    });
  }

  createLinkModel(factory?: AbstractModelFactory<LinkModel>): LinkModel {
    if (factory) {
      return factory.generateModel({});
    }

    const diagramSettings = selectDiagramSettings(store.getState());
    if (diagramSettings.linkCurved) {
      return new DefaultLinkModel();
    }

    return new RightAngleLinkModel();
  }
}

export const DiagramWorkflowRegister = (engine: DiagramEngine) => {
  engine.getNodeFactories().registerFactory(new Factory());
};
