import { CancelToken } from "axios";
import { FC, ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { RightType } from "../../models/auth";
import { INotificationItem } from "../../models/notification";
import { IApplicationState } from "../../store";
import { IFilterType } from "../../store/filterType";
import { selectIdentityRights } from "../../store/identity";
import {
  changeNotificationListFilter,
  changeNotificationListOrder,
  changeNotificationListPage,
  getNotificationList,
  getNotificationListCount,
  INotificationListState,
  selectNotificationList,
  notificationListLoad,
} from "../../store/notificationList";
import { hasRight } from "../../utils/rights";
import FilterPanel from "../common/filter/FilterPanel";
import Grid from "../common/grid/Grid";
import {
  GridIconAdd,
  GridIconEdit,
  GridIconIsActive,
} from "../common/grid/GridIcons";
import { TableCol, TableColCenter } from "../common/grid/TableCol";

interface IProps {
  prov: INotificationListState;
  identityRights?: RightType[];
  getNotificationList(cancelToken: CancelToken): void;
  getNotificationListCount(cancelToken: CancelToken): void;
  changeNotificationListOrder(orderBy: string, orderDesc: boolean): void;
  changeNotificationListPage(page: number): void;
  changeNotificationListFilter(filter: IFilterType): void;
  notificationListLoad(reload: boolean): void;
}

const AdminNotification: FC<IProps> = ({
  prov,
  identityRights,
  getNotificationList,
  getNotificationListCount,
  changeNotificationListOrder,
  changeNotificationListPage,
  changeNotificationListFilter,
  notificationListLoad,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    notificationListLoad(false);
  }, [notificationListLoad]);

  const handleRenderData = (item: INotificationItem): ReactElement => {
    return (
      <>
        <TableColCenter>{item.isActive && <GridIconIsActive />}</TableColCenter>
        <TableCol>{item.name}</TableCol>
        <TableCol>
          <NavLink to={`/admin/notification/${item.id}`}>
            <GridIconEdit />
          </NavLink>
        </TableCol>
      </>
    );
  };

  const handleRowClick = (item: INotificationItem) => {
    navigate(`/admin/notification/${item.id}`);
  };

  return (
    <>
      <FilterPanel
        title={t("admin.notification.pageTitle")}
        name="adminNotification"
        filter={prov.filter!}
        changeFilter={changeNotificationListFilter}
      />
      <Grid<INotificationItem>
        headers={[
          { captionStr: "admin.notification.isActive", minContent: true },
          { captionStr: "admin.notification.name" },
          {
            captionEl: hasRight(identityRights, [
              RightType.WriteNotification,
            ]) ? (
              <NavLink to={`/admin/notification/new`}>
                <GridIconAdd />
              </NavLink>
            ) : undefined,
          },
        ]}
        renderData={handleRenderData}
        getData={getNotificationList}
        getCount={getNotificationListCount}
        changeOrder={changeNotificationListOrder}
        changePage={changeNotificationListPage}
        onRowClick={handleRowClick}
        prov={prov}
      />
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    prov: selectNotificationList(state),
    identityRights: selectIdentityRights(state),
  };
};

const mapDispachToProps = {
  getNotificationList,
  getNotificationListCount,
  changeNotificationListOrder,
  changeNotificationListPage,
  changeNotificationListFilter,
  notificationListLoad,
};

export default connect(mapStateToProps, mapDispachToProps)(AdminNotification);
