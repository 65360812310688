import { Form, Formik } from "formik";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { IAssistancePerson } from "../../models/assistance";
import { IApplicationState } from "../../store";
import { StoreState } from "../../store/storeState";
import {
  getDefaultAssistancePerson,
  getAssistancePerson,
  selectAssistancePerson,
  selectAssistancePersonState,
} from "../../store/assistancePerson";
import { FormButton } from "../../styles/button";
import { ApiError, FormGroup } from "../../styles/form";
import Input from "../common/form/Input";
import PhoneInput from "../common/form/PhoneInput";
import Loader from "../common/Loader";
import * as Yup from "yup";
import { assistancePersonListLoad } from "../../store/assistancePersonList";
import assistanceApi from "../../api/assistance";
import { SpaceBetweenButtons } from "../../styles/spaces";
import validations from "../../utils/validations";
import { selectAssistanceId } from "../../store/assistance";
import { RightType } from "../../models/auth";
import { hasRight } from "../../utils/rights";
import { selectIdentityRights } from "../../store/identity";
import { promiseToastSave } from "../../utils/toasts";
import SubmitForm from "../common/form/SubmitForm";
import SuggestionFormik from "../common/suggestion/SuggestionFormik";
import { handleLoadContactPersonListingSuggestions } from "../../utils/suggestions";
import { errorSet } from "../../utils/error";

export interface IAssistancePersonForm extends IAssistancePerson {
  typeId: number;
  typeName: string;
}

const fields: Array<{ name: keyof IAssistancePerson; maxLen?: number }> = [
  { name: "title", maxLen: 100 },
  { name: "firstName", maxLen: 100 },
  { name: "lastName", maxLen: 100 },
  { name: "degree", maxLen: 100 },
];

interface IProps {
  assistanceId?: number;
  assistancePersonState: StoreState;
  assistancePerson: IAssistancePerson | null;
  identityRights?: RightType[];
  getAssistancePerson(assistancePersonId: number): void;
  getDefaultAssistancePerson(): void;
  assistancePersonListLoad(reload: boolean): void;
}

const AssistancePersonDetail: FC<IProps> = ({
  assistanceId,
  assistancePersonState,
  assistancePerson,
  identityRights,
  getAssistancePerson,
  getDefaultAssistancePerson,
  assistancePersonListLoad,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { assistanceId: paramAssistanceId, personId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (personId === "new") {
      getDefaultAssistancePerson();
    } else {
      getAssistancePerson(parseInt(personId!));
    }
    setLoading(false);
  }, [personId, getAssistancePerson, getDefaultAssistancePerson]);

  const handleSubmit = async (data: IAssistancePersonForm) => {
    setError(null);

    const assistancePerson = {
      ...data,
      type: { id: data.typeId, name: data.typeName },
    } as IAssistancePerson;

    try {
      await promiseToastSave(async () => {
        if (personId === "new") {
          await assistanceApi.createPerson(assistanceId!, assistancePerson);
        } else {
          await assistanceApi.updatePerson(assistancePerson);
        }

        assistancePersonListLoad(true);
        navigate(`/assistance/${paramAssistanceId}/person`);
      });
    } catch (err) {
      errorSet(setError, err, t);
    }
  };

  const handleCancel = () => {
    navigate(`/assistance/${paramAssistanceId}/person`);
  };

  if (loading || assistancePersonState === StoreState.Loading) {
    return <Loader />;
  }

  if (assistancePersonState === StoreState.Error) {
    return t("errors.unknown");
  }

  return (
    <Formik<IAssistancePersonForm>
      initialValues={{
        ...assistancePerson!,
        typeId: assistancePerson!.type.id,
        typeName: assistancePerson!.type.name,
        title: assistancePerson!.title ?? "",
        degree: assistancePerson!.degree ?? "",
        mobilePhone: assistancePerson!.mobilePhone ?? "",
        phone: assistancePerson!.phone ?? "",
        email: assistancePerson!.email ?? "",
      }}
      validationSchema={Yup.object({
        typeId: validations.idRequired(t),
        firstName: validations.stringRequired(t),
        lastName: validations.stringRequired(t),
        mobilePhone: validations.phoneOptional(t),
        phone: validations.phoneOptional(t),
        email: validations.emailOptional(t),
      })}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, isSubmitting }) => (
        <Form>
          <h1>{t("assistance.person.detailTitle")}</h1>
          <FormGroup>
            <SuggestionFormik
              nameId="typeId"
              nameText="typeName"
              label={t("assistance.person.type")}
              loadSuggestions={handleLoadContactPersonListingSuggestions}
            />
            {fields.map((f) => (
              <Input
                key={f.name}
                name={f.name}
                label={t("assistance.person." + f.name)}
                error={touched[f.name] && !!errors[f.name]}
                maxLength={f.maxLen}
              />
            ))}
            <PhoneInput
              name="mobilePhone"
              label={t("assistance.person.mobilePhone")}
              error={touched.mobilePhone && !!errors.mobilePhone}
            />
            <PhoneInput
              name="phone"
              label={t("assistance.person.phone")}
              error={touched.phone && !!errors.phone}
            />
            <Input
              name="email"
              label={t("assistance.person.email")}
              error={touched.email && !!errors.email}
              maxLength={255}
            />
          </FormGroup>
          {error && <ApiError>{error}</ApiError>}
          <FormButton
            ver="secondary"
            disabled={isSubmitting}
            onClick={handleCancel}
          >
            {t("common.back")}
          </FormButton>
          {hasRight(identityRights, [RightType.WriteAssistances]) && (
            <>
              <SpaceBetweenButtons />
              <SubmitForm />
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    assistanceId: selectAssistanceId(state),
    assistancePersonState: selectAssistancePersonState(state),
    assistancePerson: selectAssistancePerson(state),
    identityRights: selectIdentityRights(state),
  };
};

const mapDispachToProps = {
  getAssistancePerson,
  getDefaultAssistancePerson,
  assistancePersonListLoad,
};

export default connect(
  mapStateToProps,
  mapDispachToProps
)(AssistancePersonDetail);
