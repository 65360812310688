import { IHealthInsuranceItem } from "../../models/healthInsurance";
import { IFilterType } from "../filterType";
import { IListType } from "../listType";

export enum HealthInsuranceListTypes {
  HEALTH_INSURANCE_LIST_FETCH_REQUEST = "@@healthInsuranceList/FETCH_REQUEST",
  HEALTH_INSURANCE_LIST_FETCH_SUCCESS = "@@healthInsuranceList/FETCH_SUCCESS",
  HEALTH_INSURANCE_LIST_FETCH_ERROR = "@@healthInsuranceList/FETCH_ERROR",
  HEALTH_INSURANCE_LIST_COUNT_REQUEST = "@@healthInsuranceList/COUNT_REQUEST",
  HEALTH_INSURANCE_LIST_COUNT_SUCCESS = "@@healthInsuranceList/COUNT_SUCCESS",
  HEALTH_INSURANCE_LIST_COUNT_ERROR = "@@healthInsuranceList/COUNT_ERROR",
  HEALTH_INSURANCE_LIST_CHANGE_ORDER = "@@healthInsuranceList/CHANGE_ORDER",
  HEALTH_INSURANCE_LIST_CHANGE_PAGE = "@@healthInsuranceList/CHANGE_PAGE",
  HEALTH_INSURANCE_LIST_CHANGE_FILTER = "@@healthInsuranceList/CHANGE_FILTER",
  HEALTH_INSURANCE_LIST_RESET = "@@healthInsuranceList/RESET",
  HEALTH_INSURANCE_LIST_LOAD = "@@healthInsuranceList/LOAD",
}

export interface IHealthInsuranceListState
  extends IListType<IHealthInsuranceItem, IFilterType> {}
