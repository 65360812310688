import { AxiosRequestConfig, AxiosResponse } from "axios";
import { ILogItem } from "../models/log";
import api from "../utils/api";

const getLogList = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<ILogItem[]>> => api.get(`/api/Log/List`, config);

const getLogCount = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<number>> => api.get(`/api/Log/Count`, config);

const logApi = {
  getLogList,
  getLogCount,
};

export default logApi;
