import { IClientDebtItem } from "../../models/client";
import { IFilterType } from "../filterType";
import { IListType } from "../listType";

export enum ClientDebtListTypes {
  CLIENT_DEBT_LIST_FETCH_REQUEST = "@@clientDebtList/FETCH_REQUEST",
  CLIENT_DEBT_LIST_FETCH_SUCCESS = "@@clientDebtList/FETCH_SUCCESS",
  CLIENT_DEBT_LIST_FETCH_ERROR = "@@clientDebtList/FETCH_ERROR",
  CLIENT_DEBT_LIST_COUNT_REQUEST = "@@clientDebtList/COUNT_REQUEST",
  CLIENT_DEBT_LIST_COUNT_SUCCESS = "@@clientDebtList/COUNT_SUCCESS",
  CLIENT_DEBT_LIST_COUNT_ERROR = "@@clientDebtList/COUNT_ERROR",
  CLIENT_DEBT_LIST_CHANGE_ORDER = "@@clientDebtList/CHANGE_ORDER",
  CLIENT_DEBT_LIST_CHANGE_PAGE = "@@clientDebtList/CHANGE_PAGE",
  CLIENT_DEBT_LIST_CHANGE_FILTER = "@@clientDebtList/CHANGE_FILTER",
  CLIENT_DEBT_LIST_RESET = "@@clientDebtList/RESET",
  CLIENT_DEBT_LIST_LOAD = "@@clientDebtList/LOAD",
}

export interface IClientDebtListState
  extends IListType<IClientDebtItem, IFilterType> {}
