import { FC, useState } from "react";
import { useFormikContext } from "formik";
import { InputContainer, InputRow } from "../../../styles/form";
import { COLORS } from "../../../styles/colors";
import { InputLabel, InputTextAfter } from "./InputStyles";
import FormError from "./FormError";
import RequiredMark from "./RequiredMark";
import PhoneInputComponent from "react-phone-input-2";
import "react-phone-input-2/lib/high-res.css";
import { PhoneInputWrapper } from "./PhoneInputStyles";

const PhoneInput: FC<{
  name: string;
  label?: string;
  error?: boolean;
  textAfter?: string | JSX.Element;
  disabled?: boolean;
  tooltipLeft?: boolean;
}> = ({ name, label, error, textAfter, disabled, tooltipLeft }) => {
  const { errors, values, setFieldTouched, setFieldValue } =
    useFormikContext<any>();
  const [lastDialCode, setLastDialCode] = useState("+420");

  const handleGetValue = () => {
    let value = values[name];

    if (!value) {
      value = lastDialCode;
    }

    return value;
  };

  const handleChange = (
    value: string,
    country: { dialCode?: string },
    event: React.ChangeEvent<HTMLInputElement>,
    formattedValue: string
  ) => {
    const dialCode = "+" + country.dialCode;
    setLastDialCode(dialCode);

    if (formattedValue === dialCode) {
      formattedValue = "";
    }

    setFieldTouched(name);
    setFieldValue(name, formattedValue);
  };

  return (
    <InputRow>
      {label && (
        <InputLabel htmlFor={name}>
          {label}
          {errors[name] && <RequiredMark />}
        </InputLabel>
      )}
      <PhoneInputWrapper error={error}>
        <InputContainer>
          <PhoneInputComponent
            value={handleGetValue()}
            onChange={handleChange}
            onBlur={() => setFieldTouched(name)}
            inputProps={{ name, autoComplete: "off" }}
            inputStyle={{
              backgroundColor: disabled ? COLORS.disabledBg : undefined,
            }}
            country="cz"
            preferredCountries={["cz", "sk"]}
            countryCodeEditable={false}
            buttonStyle={{
              borderColor: error ? COLORS.borderError : COLORS.border,
            }}
            disabled={disabled}
          />
          {textAfter && <InputTextAfter>{textAfter}</InputTextAfter>}
          {error && <FormError name={name} tooltipLeft={tooltipLeft} />}
        </InputContainer>
      </PhoneInputWrapper>
    </InputRow>
  );
};

export default PhoneInput;
