import { AxiosRequestConfig, AxiosResponse } from "axios";
import { ICareType, ICareTypeItem } from "../models/careType";
import { IItemIdName } from "../models/common";
import api from "../utils/api";

const getSuggestionList = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<IItemIdName[]>> =>
  api.get(`/api/CareType/Suggested`, config);

const getList = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<ICareTypeItem[]>> => api.get(`/api/CareType`, config);

const getCount = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<number>> => api.get(`/api/CareType/Count`, config);

const getCareType = async (
  careTypeId: number
): Promise<AxiosResponse<ICareType>> => api.get(`/api/CareType/${careTypeId}`);

const updateCareType = async (
  careType: ICareType
): Promise<AxiosResponse<ICareType>> =>
  api.put(`/api/CareType/${careType.id}`, careType);

const createCareType = async (
  careType: ICareType
): Promise<AxiosResponse<ICareType>> => api.post(`/api/CareType`, careType);

const careTypeApi = {
  getSuggestionList,
  getList,
  getCount,
  getCareType,
  updateCareType,
  createCareType,
};

export default careTypeApi;
