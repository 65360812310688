import { CancelToken } from "axios";
import { FC, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { IActionItem } from "../../models/calendar";
import { IApplicationState } from "../../store";
import { IFilterType } from "../../store/filterType";
import {
  changeClientActionListFilter,
  changeClientActionListOrder,
  changeClientActionListPage,
  getClientActionList,
  getClientActionListCount,
  IClientActionListState,
  selectClientActionList,
  clientActionListLoad,
} from "../../store/clientActionList";
import FilterPanel from "../common/filter/FilterPanel";
import Grid from "../common/grid/Grid";
import { TableCol } from "../common/grid/TableCol";
import { RightType } from "../../models/auth";
import { selectIdentityRights } from "../../store/identity";
import { selectClientId } from "../../store/client";
import { DATE_TIME_FORMAT, TIME_FORMAT } from "../../utils/consts";
import { format } from "date-fns";
import { useNavigate, useParams } from "react-router";
import { NavLink } from "react-router-dom";
import { GridIconAdd, GridIconEdit } from "../common/grid/GridIcons";
import { hasClientWriteRight } from "../../utils/rights";
import { GridSelectedCheck } from "../common/grid/GridComponents";
import { ActionButton } from "../../styles/button";
import calendarApi from "../../api/calendar";
import { promiseToastDeleteNoException } from "../../utils/toasts";
import AssignUserModal from "../calendar/AssignUserModal";

interface IProps {
  prov: IClientActionListState;
  clientId?: number;
  identityId?: number;
  identityName?: string;
  identityRights?: RightType[];
  getClientActionList(clientId: number, cancelToken: CancelToken): void;
  getClientActionListCount(clientId: number, cancelToken: CancelToken): void;
  changeClientActionListOrder(orderBy: string, orderDesc: boolean): void;
  changeClientActionListPage(page: number): void;
  changeClientActionListFilter(filter: IFilterType): void;
  clientActionListLoad(reload: boolean): void;
}

const ClientAction: FC<IProps> = ({
  prov,
  clientId,
  identityRights,
  getClientActionList,
  getClientActionListCount,
  changeClientActionListOrder,
  changeClientActionListPage,
  changeClientActionListFilter,
  clientActionListLoad,
}) => {
  const { t } = useTranslation();
  const { clientId: paramClientId } = useParams();
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [assignmentIsOpen, setAssignmentIsOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    clientActionListLoad(false);
  }, [clientActionListLoad]);

  useEffect(() => {
    setSelectedIds([]);
  }, [prov.filter]);

  const handleSelectId = (item: IActionItem) => {
    if (selectedIds.find((x) => x === item.id) === undefined) {
      setSelectedIds([...selectedIds, item.id]);
    } else {
      setSelectedIds(selectedIds.filter((x) => x !== item.id));
    }
  };

  const handleSelectAll = () => {
    if (prov.data.find((x) => !selectedIds.includes(x.id)) === undefined) {
      setSelectedIds(
        selectedIds.filter(
          (x) => prov.data.find((y) => y.id === x) === undefined
        )
      );
    } else {
      setSelectedIds([
        ...selectedIds,
        ...prov.data.map((x) => x.id).filter((x) => !selectedIds.includes(x)),
      ]);
    }
  };

  const handleRenderData = (item: IActionItem): ReactElement => {
    return (
      <>
        <TableCol>
          <GridSelectedCheck
            checked={selectedIds.find((x) => x === item.id) !== undefined}
            onChange={() => handleSelectId(item)}
          />
        </TableCol>
        <TableCol>
          {item.userAssignee ? (
            item.userAssignee.name
          ) : (
            <span className="error">{t("client.action.unassigned")}</span>
          )}
        </TableCol>
        <TableCol>{item.description}</TableCol>
        <TableCol>{item.type.name}</TableCol>
        <TableCol>
          {format(item.from, DATE_TIME_FORMAT)}
          {" - "}
          {format(item.to, TIME_FORMAT)}
        </TableCol>
        <TableCol>
          <NavLink to={`/client/${paramClientId}/action/${item.id}`}>
            <GridIconEdit />
          </NavLink>
        </TableCol>
      </>
    );
  };

  const handleRowClick = (item: IActionItem) => {
    navigate(`/client/${paramClientId}/action/${item.id}`);
  };

  const handleGetData = (cancelToken: CancelToken) =>
    getClientActionList(clientId!, cancelToken);

  const handleGetCount = (cancelToken: CancelToken) =>
    getClientActionListCount(clientId!, cancelToken);

  const handleAssigneUser = async () => {
    setAssignmentIsOpen(true);
  };

  const handleCloseAssignment = () => {
    setAssignmentIsOpen(false);
  };

  const handleConfirmAssignment = () => {
    setAssignmentIsOpen(false);
    setSelectedIds([]);
    clientActionListLoad(true);
  };

  const handleDeleteSelectedActions = async () => {
    await promiseToastDeleteNoException(async () => {
      for (const id of selectedIds) {
        await calendarApi.deleteAction(id);
      }
      setSelectedIds([]);
      clientActionListLoad(true);
    });
  };

  return (
    <>
      <AssignUserModal
        isOpen={assignmentIsOpen}
        selectedActionIds={selectedIds}
        close={handleCloseAssignment}
        confirm={handleConfirmAssignment}
      />
      <FilterPanel
        name="clientAction"
        filter={prov.filter!}
        changeFilter={changeClientActionListFilter}
      />
      <Grid<IActionItem>
        headers={[
          {
            captionEl: (
              <>
                {prov.data.length > 1 && (
                  <GridSelectedCheck
                    checked={
                      prov.data.find((x) => !selectedIds.includes(x.id)) ===
                      undefined
                    }
                    onChange={handleSelectAll}
                  />
                )}
              </>
            ),
          },
          { captionStr: "client.action.user" },
          { captionStr: "client.action.description" },
          { captionStr: "client.action.type" },
          { captionStr: "client.action.date" },
          {
            captionEl: hasClientWriteRight(identityRights, [
              RightType.WriteClientAction,
            ]) ? (
              <NavLink to={`/client/${paramClientId}/action/new`}>
                <GridIconAdd />
              </NavLink>
            ) : undefined,
          },
        ]}
        renderData={handleRenderData}
        getData={handleGetData}
        getCount={handleGetCount}
        changeOrder={changeClientActionListOrder}
        changePage={changeClientActionListPage}
        onRowClick={handleRowClick}
        checkbox={true}
        prov={prov}
      />
      <ActionButton
        ver="secondary"
        disabled={selectedIds.length === 0}
        onClick={handleAssigneUser}
      >
        {t("calendar.list.assigneUser")}
      </ActionButton>
      <ActionButton
        ver="secondary"
        disabled={selectedIds.length === 0}
        onClick={handleDeleteSelectedActions}
      >
        {t("common.delete")}
      </ActionButton>
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    prov: selectClientActionList(state),
    clientId: selectClientId(state),
    identityRights: selectIdentityRights(state),
  };
};

const mapDispachToProps = {
  getClientActionList,
  getClientActionListCount,
  changeClientActionListOrder,
  changeClientActionListPage,
  changeClientActionListFilter,
  clientActionListLoad,
};

export default connect(mapStateToProps, mapDispachToProps)(ClientAction);
