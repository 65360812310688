import { createAction } from "@reduxjs/toolkit";
import { IReportItem } from "../../models/report";
import { IFilterType } from "../filterType";
import { StoreState, StoreStateDontLoad } from "../storeState";
import { ReportListTypes } from "./types";

export const reportListFetchRequest = createAction<
  StoreStateDontLoad,
  ReportListTypes.REPORT_LIST_FETCH_REQUEST
>(ReportListTypes.REPORT_LIST_FETCH_REQUEST);

export const reportListFetchSuccess = createAction<
  { data: IReportItem[]; newCountState: StoreStateDontLoad; newCount: number },
  ReportListTypes.REPORT_LIST_FETCH_SUCCESS
>(ReportListTypes.REPORT_LIST_FETCH_SUCCESS);

export const reportListFetchError = createAction<
  boolean,
  ReportListTypes.REPORT_LIST_FETCH_ERROR
>(ReportListTypes.REPORT_LIST_FETCH_ERROR);

export const reportListCountRequest = createAction<
  void,
  ReportListTypes.REPORT_LIST_COUNT_REQUEST
>(ReportListTypes.REPORT_LIST_COUNT_REQUEST);

export const reportListCountSuccess = createAction<
  number,
  ReportListTypes.REPORT_LIST_COUNT_SUCCESS
>(ReportListTypes.REPORT_LIST_COUNT_SUCCESS);

export const reportListCountError = createAction<
  boolean,
  ReportListTypes.REPORT_LIST_COUNT_ERROR
>(ReportListTypes.REPORT_LIST_COUNT_ERROR);

export const reportListChangeOrder = createAction<
  { orderBy: string; orderDesc: boolean },
  ReportListTypes.REPORT_LIST_CHANGE_ORDER
>(ReportListTypes.REPORT_LIST_CHANGE_ORDER);

export const reportListChangePage = createAction<
  number,
  ReportListTypes.REPORT_LIST_CHANGE_PAGE
>(ReportListTypes.REPORT_LIST_CHANGE_PAGE);

export const reportListChangeFilter = createAction<
  IFilterType,
  ReportListTypes.REPORT_LIST_CHANGE_FILTER
>(ReportListTypes.REPORT_LIST_CHANGE_FILTER);

export const reportListReset = createAction<
  void,
  ReportListTypes.REPORT_LIST_RESET
>(ReportListTypes.REPORT_LIST_RESET);

export const reportListLoadAction = createAction<
  { newState: StoreState; newCountState: StoreStateDontLoad },
  ReportListTypes.REPORT_LIST_LOAD
>(ReportListTypes.REPORT_LIST_LOAD);
