export const pascalCaseWithSpaces = (input: string) =>
  input
    .replace(/(_|-)/g, " ")
    .trim()
    .replace(/\w\S*/g, function (str) {
      return str.charAt(0).toUpperCase() + str.substr(1);
    })
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2");

export const keepFileExtensions = (originalName: string, newName: string) => {
  const lastIndex = originalName.lastIndexOf(".");
  if (lastIndex !== -1) {
    const originalExt = originalName.substring(lastIndex);

    if (!newName.toLowerCase().endsWith(originalExt.toLowerCase())) {
      newName = newName + originalExt;
    }
  }
  return newName;
};
