import { Form, Formik } from "formik";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { IRole, RoleRightMode, RoleRightType } from "../../models/role";
import { IApplicationState } from "../../store";
import { StoreState } from "../../store/storeState";
import {
  getDefaultRole,
  getRole,
  selectRole,
  selectRoleState,
} from "../../store/role";
import { FormButton } from "../../styles/button";
import { ApiError, FormGroup } from "../../styles/form";
import Input from "../common/form/Input";
import Loader from "../common/Loader";
import * as Yup from "yup";
import { roleListLoad } from "../../store/roleList";
import roleApi from "../../api/role";
import validations from "../../utils/validations";
import { SpaceBetweenButtons } from "../../styles/spaces";
import { RightType } from "../../models/auth";
import {
  getRoleType,
  getRoleTypeRead,
  getRoleTypeWrite,
  hasRight,
} from "../../utils/rights";
import { selectIdentityRights } from "../../store/identity";
import { promiseToastSave } from "../../utils/toasts";
import SubmitForm from "../common/form/SubmitForm";
import AdminRoleCategory from "./AdminRoleCategory";
import AdminRoleRight from "./AdminRoleRight";
import {
  AdminRoleCategoryContainer,
  AdminRoleHeader,
  AdminRoleLimitationsRow,
  AdminRoleRadio,
  AdminRoleRadioContainer,
  AdminRoleRightContainer,
  AdminRoleSubCategoryLabel,
} from "./AdminRoleStyles";
import { errorSet } from "../../utils/error";

enum ClientDetailType {
  None = "none",
  My = "my",
  Assistance = "assistance",
  MyAndAssistance = "myAndAssistance",
  All = "all",
}

enum UserDetailType {
  Group = "group",
  Assistance = "assistance",
  GroupAndAssistance = "groupAndAssistance",
  All = "all",
}

interface IRoleForm extends IRole {
  name: string;
  description: string;

  categoryCalendar: boolean;
  calendarList: RoleRightType;
  calendarMy: RoleRightType;
  calendarTeamWeek: RoleRightType;
  calendarTeamDay: RoleRightType;

  categoryDemand: boolean;
  demandDetail: RoleRightType;

  categoryClient: boolean;
  clientCreate: RoleRightType;
  clientDetail: ClientDetailType;
  clientGeneral: RoleRightType;
  clientContactPerson: RoleRightType;
  clientRisk: RoleRightType;
  clientNote: RoleRightType;
  clientHousingSituation: RoleRightType;
  clientFinancingData: RoleRightType;
  clientCare: RoleRightType;
  clientAction: RoleRightType;
  clientCalendar: RoleRightType;
  clientDemand: RoleRightType;
  clientDocument: RoleRightType;
  clientRights: RoleRightType;
  clientChangeState: RoleRightType;
  clientAnonymise: RoleRightType;
  clientExport: RoleRightType;
  clientGdprAgreement: RoleRightType;
  clientGdprAgreementWithoutSecret: RoleRightType;

  categoryAssistance: boolean;
  assistanceCreate: RoleRightType;
  assistanceGeneral: RoleRightType;
  assistanceChangeState: RoleRightType;

  categoryModule: boolean;
  moduleDocument: RoleRightType;
  moduleWorkflowTemplate: RoleRightType;
  moduleWorkflow: RoleRightType;
  moduleReporting: RoleRightType;

  categoryAdmin: boolean;
  categoryAdminUser: RoleRightType;
  adminUserAdd: RoleRightType;
  adminUserDetail: UserDetailType;
  adminUserChangeState: RoleRightType;
  adminRole: RoleRightType;
  adminGroup: RoleRightType;
  adminCareType: RoleRightType;
  adminListing: RoleRightType;
  adminNotification: RoleRightType;
  adminBasicSettings: RoleRightType;
  adminPublic: RoleRightType;
  adminMonitor: RoleRightType;
  adminLog: RoleRightType;
}

interface IProps {
  roleState: StoreState;
  role: IRole | null;
  identityRights?: RightType[];
  getRole(roleId: number): void;
  getDefaultRole(): void;
  roleListLoad(reload: boolean): void;
}

const fields: Array<{ name: keyof IRole; maxLen?: number }> = [
  { name: "name", maxLen: 100 },
  { name: "description", maxLen: 255 },
];

const AdminRoleDetail: FC<IProps> = ({
  roleState,
  role,
  identityRights,
  getRole,
  getDefaultRole,
  roleListLoad,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { roleId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (roleId === "new") {
      getDefaultRole();
    } else {
      getRole(parseInt(roleId!));
    }

    setLoading(false);
  }, [roleId, getRole, getDefaultRole]);

  const handleSubmit = async (data: IRoleForm) => {
    setError(null);
    try {
      await promiseToastSave(async () => {
        const data2 = {
          ...data,

          readCalendarList:
            data.categoryCalendar && data.calendarList !== RoleRightType.None,
          writeCalendarList:
            data.categoryCalendar && data.calendarList === RoleRightType.Write,
          readCalendarMy:
            data.categoryCalendar && data.calendarMy !== RoleRightType.None,
          writeCalendarMy:
            data.categoryCalendar && data.calendarMy === RoleRightType.Write,
          readCalendarTeamWeek:
            data.categoryCalendar &&
            data.calendarTeamWeek !== RoleRightType.None,
          writeCalendarTeamWeek:
            data.categoryCalendar &&
            data.calendarTeamWeek === RoleRightType.Write,
          readCalendarTeamDay:
            data.categoryCalendar &&
            data.calendarTeamDay !== RoleRightType.None,
          writeCalendarTeamDay:
            data.categoryCalendar &&
            data.calendarTeamDay === RoleRightType.Write,
          readAllActions:
            data.categoryCalendar && data.calendarList !== RoleRightType.None,
          readAction:
            data.categoryCalendar && data.calendarList !== RoleRightType.None,
          writeAllActions:
            data.categoryCalendar && data.calendarList === RoleRightType.Write,
          writeAction:
            data.categoryCalendar && data.calendarList === RoleRightType.Write,
          cancelAction:
            data.categoryCalendar && data.calendarList === RoleRightType.Write,
          updateAssignedUserAction:
            data.categoryCalendar && data.calendarList === RoleRightType.Write,
          deleteAction:
            data.categoryCalendar && data.calendarList === RoleRightType.Write,
          readAllCalendars:
            data.categoryCalendar &&
            (data.calendarMy !== RoleRightType.None ||
              data.calendarTeamWeek !== RoleRightType.None ||
              data.calendarTeamDay !== RoleRightType.None),
          readCalendar:
            data.categoryCalendar &&
            (data.calendarMy !== RoleRightType.None ||
              data.calendarTeamWeek !== RoleRightType.None ||
              data.calendarTeamDay !== RoleRightType.None),
          writeAllCalendars:
            data.categoryCalendar &&
            (data.calendarMy === RoleRightType.Write ||
              data.calendarTeamWeek === RoleRightType.Write ||
              data.calendarTeamDay === RoleRightType.Write),
          writeCalendar:
            data.categoryCalendar &&
            (data.calendarMy === RoleRightType.Write ||
              data.calendarTeamWeek === RoleRightType.Write ||
              data.calendarTeamDay === RoleRightType.Write),

          readDemand:
            data.categoryDemand && data.demandDetail !== RoleRightType.None,
          writeDemand:
            data.categoryDemand && data.demandDetail === RoleRightType.Write,
          assignDemand:
            data.categoryDemand && data.demandDetail === RoleRightType.Write,
          changeAssignedUserDemand:
            data.categoryDemand && data.demandDetail === RoleRightType.Write,
          changeAssignedGroupDemand:
            data.categoryDemand && data.demandDetail === RoleRightType.Write,

          createClient:
            data.categoryClient && data.clientCreate !== RoleRightType.None,
          readMyClients:
            data.categoryClient &&
            (data.clientDetail === ClientDetailType.My ||
              data.clientDetail === ClientDetailType.MyAndAssistance),
          readClientsInTheSameAssistance:
            data.categoryClient &&
            (data.clientDetail === ClientDetailType.Assistance ||
              data.clientDetail === ClientDetailType.MyAndAssistance),
          readAllClients:
            data.categoryClient && data.clientDetail === ClientDetailType.All,
          writeMyClients:
            data.categoryClient &&
            (data.clientDetail === ClientDetailType.My ||
              data.clientDetail === ClientDetailType.MyAndAssistance),
          writeClientsInTheSameAssistance:
            data.categoryClient &&
            (data.clientDetail === ClientDetailType.Assistance ||
              data.clientDetail === ClientDetailType.MyAndAssistance),
          writeAllClients:
            data.categoryClient && data.clientDetail === ClientDetailType.All,
          writeClientGeneral:
            data.categoryClient &&
            data.clientDetail !== ClientDetailType.None &&
            data.clientGeneral === RoleRightType.Write,
          readClientContactPersons:
            data.categoryClient &&
            data.clientDetail !== ClientDetailType.None &&
            data.clientContactPerson !== RoleRightType.None,
          writeClientContactPersons:
            data.categoryClient &&
            data.clientDetail !== ClientDetailType.None &&
            data.clientContactPerson === RoleRightType.Write,
          readClientRisk:
            data.categoryClient &&
            data.clientDetail !== ClientDetailType.None &&
            data.clientRisk !== RoleRightType.None,
          writeClientRisk:
            data.categoryClient &&
            data.clientDetail !== ClientDetailType.None &&
            data.clientRisk === RoleRightType.Write,
          readClientNotes:
            data.categoryClient &&
            data.clientDetail !== ClientDetailType.None &&
            data.clientNote !== RoleRightType.None,
          writeClientNotes:
            data.categoryClient &&
            data.clientDetail !== ClientDetailType.None &&
            data.clientNote === RoleRightType.Write,
          readClientHousingSituation:
            data.categoryClient &&
            data.clientDetail !== ClientDetailType.None &&
            data.clientHousingSituation !== RoleRightType.None,
          writeClientHousingSituation:
            data.categoryClient &&
            data.clientDetail !== ClientDetailType.None &&
            data.clientHousingSituation === RoleRightType.Write,
          readClientsFinancingData:
            data.categoryClient &&
            data.clientDetail !== ClientDetailType.None &&
            data.clientFinancingData !== RoleRightType.None,
          writeClientsFinancingData:
            data.categoryClient &&
            data.clientDetail !== ClientDetailType.None &&
            data.clientFinancingData === RoleRightType.Write,
          readCare:
            data.categoryClient &&
            data.clientDetail !== ClientDetailType.None &&
            data.clientCare !== RoleRightType.None,
          writeCare:
            data.categoryClient &&
            data.clientDetail !== ClientDetailType.None &&
            data.clientCare === RoleRightType.Write,
          readClientAction:
            data.categoryClient &&
            data.clientDetail !== ClientDetailType.None &&
            data.clientAction !== RoleRightType.None,
          writeClientAction:
            data.categoryClient &&
            data.clientDetail !== ClientDetailType.None &&
            data.clientAction === RoleRightType.Write,
          readClientCalendar:
            data.categoryClient &&
            data.clientDetail !== ClientDetailType.None &&
            data.clientCalendar !== RoleRightType.None,
          writeClientCalendar:
            data.categoryClient &&
            data.clientDetail !== ClientDetailType.None &&
            data.clientCalendar === RoleRightType.Write,
          readClientDemand:
            data.categoryClient &&
            data.clientDetail !== ClientDetailType.None &&
            data.clientDemand !== RoleRightType.None,
          writeClientDemand:
            data.categoryClient &&
            data.clientDetail !== ClientDetailType.None &&
            data.clientDemand === RoleRightType.Write,
          readClientDocuments:
            data.categoryClient &&
            data.clientDetail !== ClientDetailType.None &&
            data.clientDocument !== RoleRightType.None,
          writeClientDocuments:
            data.categoryClient &&
            data.clientDetail !== ClientDetailType.None &&
            data.clientDocument === RoleRightType.Write,
          readClientsRights:
            data.categoryClient &&
            data.clientDetail !== ClientDetailType.None &&
            data.clientRights !== RoleRightType.None,
          writeClientsRights:
            data.categoryClient &&
            data.clientDetail !== ClientDetailType.None &&
            data.clientRights === RoleRightType.Write,
          changeClientState:
            data.categoryClient &&
            data.clientDetail !== ClientDetailType.None &&
            data.clientChangeState !== RoleRightType.None,
          anonymiseClient:
            data.categoryClient &&
            data.clientDetail !== ClientDetailType.None &&
            data.clientAnonymise !== RoleRightType.None,
          exportClient:
            data.categoryClient &&
            data.clientDetail !== ClientDetailType.None &&
            data.clientExport !== RoleRightType.None,
          addClientGdprAgreement:
            data.categoryClient &&
            data.clientDetail !== ClientDetailType.None &&
            data.clientGdprAgreement !== RoleRightType.None,
          addClientGdprAgreementWithoutSecret:
            data.categoryClient &&
            data.clientDetail !== ClientDetailType.None &&
            data.clientGdprAgreementWithoutSecret !== RoleRightType.None,

          createAssistance:
            data.categoryAssistance &&
            data.assistanceCreate !== RoleRightType.None,
          readAssistances:
            data.categoryAssistance &&
            data.assistanceGeneral !== RoleRightType.None,
          readAllAssistances:
            data.categoryAssistance &&
            data.assistanceGeneral !== RoleRightType.None,
          writeAssistances:
            data.categoryAssistance &&
            data.assistanceGeneral === RoleRightType.Write,
          writeAllAssistances:
            data.categoryAssistance &&
            data.assistanceGeneral === RoleRightType.Write,
          writeAssistanceState:
            data.categoryAssistance &&
            data.assistanceGeneral !== RoleRightType.None &&
            data.assistanceChangeState !== RoleRightType.None,
          readAssistanceDocuments:
            data.categoryAssistance &&
            data.assistanceGeneral !== RoleRightType.None,
          writeAssistanceDocuments:
            data.categoryAssistance &&
            data.assistanceGeneral === RoleRightType.Write,

          readAllTemplates:
            data.categoryModule && data.moduleDocument !== RoleRightType.None,
          writeAllTemplates:
            data.categoryModule && data.moduleDocument === RoleRightType.Write,
          readFolders:
            data.categoryModule && data.moduleDocument !== RoleRightType.None,
          writeFolders:
            data.categoryModule && data.moduleDocument === RoleRightType.Write,
          deleteAnyDocument:
            data.categoryModule && data.moduleDocument === RoleRightType.Write,
          readDeletedDocuments:
            data.categoryModule && data.moduleDocument !== RoleRightType.None,
          softDeleteFolders:
            data.categoryModule && data.moduleDocument === RoleRightType.Write,
          readWorkflowTemplate:
            data.categoryModule &&
            data.moduleWorkflowTemplate !== RoleRightType.None,
          writeWorkflowTemplate:
            data.categoryModule &&
            data.moduleWorkflowTemplate === RoleRightType.Write,
          readWorkflow:
            data.categoryModule && data.moduleWorkflow !== RoleRightType.None,
          writeWorkflow:
            data.categoryModule && data.moduleWorkflow === RoleRightType.Write,
          readReporting:
            data.categoryModule && data.moduleReporting !== RoleRightType.None,
          writeReporting:
            data.categoryModule && data.moduleReporting === RoleRightType.Write,

          readAllUsers:
            data.categoryAdmin &&
            data.categoryAdminUser !== RoleRightType.None &&
            data.adminUserDetail === UserDetailType.All,
          readUsersInTheSameGroup:
            data.categoryAdmin &&
            data.categoryAdminUser !== RoleRightType.None &&
            (data.adminUserDetail === UserDetailType.Group ||
              data.adminUserDetail === UserDetailType.GroupAndAssistance),
          readUsersInTheSameAssistance:
            data.categoryAdmin &&
            data.categoryAdminUser !== RoleRightType.None &&
            (data.adminUserDetail === UserDetailType.Assistance ||
              data.adminUserDetail === UserDetailType.GroupAndAssistance),
          readUsers:
            data.categoryAdmin && data.categoryAdminUser !== RoleRightType.None,
          writeAllUsers:
            data.categoryAdmin &&
            data.categoryAdminUser === RoleRightType.Write &&
            data.adminUserDetail === UserDetailType.All,
          writeUsersInTheSameGroup:
            data.categoryAdmin &&
            data.categoryAdminUser === RoleRightType.Write &&
            (data.adminUserDetail === UserDetailType.Group ||
              data.adminUserDetail === UserDetailType.GroupAndAssistance),
          writeUsersInTheSameAssistance:
            data.categoryAdmin &&
            data.categoryAdminUser === RoleRightType.Write &&
            (data.adminUserDetail === UserDetailType.Assistance ||
              data.adminUserDetail === UserDetailType.GroupAndAssistance),
          writeUsers:
            data.categoryAdmin &&
            data.categoryAdminUser === RoleRightType.Write,
          addUsers:
            data.categoryAdmin &&
            data.categoryAdminUser !== RoleRightType.None &&
            data.adminUserAdd !== RoleRightType.None,
          changeUserIsActive:
            data.categoryAdmin &&
            data.categoryAdminUser !== RoleRightType.None &&
            data.adminUserChangeState !== RoleRightType.None,
          readRole: data.categoryAdmin && data.adminRole !== RoleRightType.None,
          writeRole:
            data.categoryAdmin && data.adminRole === RoleRightType.Write,
          changeRoleRights:
            data.categoryAdmin && data.adminRole === RoleRightType.Write,
          readUserGroups:
            data.categoryAdmin && data.adminGroup !== RoleRightType.None,
          writeUserGroups:
            data.categoryAdmin && data.adminGroup === RoleRightType.Write,
          readCareType:
            data.categoryAdmin && data.adminCareType !== RoleRightType.None,
          writeCareType:
            data.categoryAdmin && data.adminCareType === RoleRightType.Write,
          readActionListing:
            data.categoryAdmin && data.adminListing !== RoleRightType.None,
          writeActionListing:
            data.categoryAdmin && data.adminListing === RoleRightType.Write,
          readAssistanceCategoryListing:
            data.categoryAdmin && data.adminListing !== RoleRightType.None,
          writeAssistanceCategoryListing:
            data.categoryAdmin && data.adminListing === RoleRightType.Write,
          readClientContactPersonListing:
            data.categoryAdmin && data.adminListing !== RoleRightType.None,
          writeClientContactPersonListing:
            data.categoryAdmin && data.adminListing === RoleRightType.Write,
          readClientRiskListing:
            data.categoryAdmin && data.adminListing !== RoleRightType.None,
          writeClientRiskListing:
            data.categoryAdmin && data.adminListing === RoleRightType.Write,
          readSocialBenefitListing:
            data.categoryAdmin && data.adminListing !== RoleRightType.None,
          writeSocialBenefitListing:
            data.categoryAdmin && data.adminListing === RoleRightType.Write,
          readTargetGroupListing:
            data.categoryAdmin && data.adminListing !== RoleRightType.None,
          writeTargetGroupListing:
            data.categoryAdmin && data.adminListing === RoleRightType.Write,
          readContactPersonListing:
            data.categoryAdmin && data.adminListing !== RoleRightType.None,
          writeContactPersonListing:
            data.categoryAdmin && data.adminListing === RoleRightType.Write,
          readWorkflowCategoryListing:
            data.categoryAdmin && data.adminListing !== RoleRightType.None,
          writeWorkflowCategoryListing:
            data.categoryAdmin && data.adminListing === RoleRightType.Write,
          readHealthInsurance:
            data.categoryAdmin && data.adminListing !== RoleRightType.None,
          writeHealthInsurance:
            data.categoryAdmin && data.adminListing === RoleRightType.Write,
          readNotification:
            data.categoryAdmin && data.adminNotification !== RoleRightType.None,
          writeNotification:
            data.categoryAdmin &&
            data.adminNotification === RoleRightType.Write,
          readBasicSettings:
            data.categoryAdmin &&
            data.adminBasicSettings !== RoleRightType.None,
          writeBasicSettings:
            data.categoryAdmin &&
            data.adminBasicSettings === RoleRightType.Write,
          readPublic:
            data.categoryAdmin && data.adminPublic !== RoleRightType.None,
          readMonitor:
            data.categoryAdmin && data.adminMonitor !== RoleRightType.None,
          readLog: data.categoryAdmin && data.adminLog !== RoleRightType.None,
        } as IRole;

        if (roleId === "new") {
          await roleApi.createRole(data2);
        } else {
          await roleApi.updateRole(data2);
        }
        roleListLoad(true);
        navigate("/admin/role");
      });
    } catch (err) {
      errorSet(setError, err, t);
    }
  };

  const handleCancel = () => {
    navigate("/admin/role");
  };

  const getClientDetailValue = () => {
    if (role!.readAllClients) {
      return ClientDetailType.All;
    }

    if (role!.readMyClients && role!.readClientsInTheSameAssistance) {
      return ClientDetailType.MyAndAssistance;
    }

    if (role!.readMyClients) {
      return ClientDetailType.My;
    }

    if (role!.readClientsInTheSameAssistance) {
      return ClientDetailType.Assistance;
    }

    return ClientDetailType.None;
  };

  const getAssistanceGeneralValue = () => {
    if (role!.readAllAssistances) {
      return getRoleType(true, role!.writeAllAssistances);
    }

    if (role!.readAssistances) {
      return getRoleType(true, role!.writeAssistances);
    }

    return RoleRightType.None;
  };

  const getCategoryAdminUserValue = () => {
    if (role!.readAllUsers) {
      return getRoleType(true, role!.writeAllUsers);
    }

    if (role!.readUsersInTheSameGroup && role!.readUsersInTheSameAssistance) {
      return getRoleType(
        true,
        role!.writeUsersInTheSameGroup && role!.writeUsersInTheSameAssistance
      );
    }

    if (role!.readUsersInTheSameGroup) {
      return getRoleType(true, role!.writeUsersInTheSameGroup);
    }

    if (role!.readUsersInTheSameAssistance) {
      return getRoleType(true, role!.writeUsersInTheSameAssistance);
    }

    return RoleRightType.None;
  };

  const getAdminUserDetailValue = () => {
    if (role!.readAllUsers) {
      return UserDetailType.All;
    }

    if (role!.readUsersInTheSameGroup && role!.readUsersInTheSameAssistance) {
      return UserDetailType.GroupAndAssistance;
    }

    if (role!.readUsersInTheSameGroup) {
      return UserDetailType.Group;
    }

    if (role!.readUsersInTheSameAssistance) {
      return UserDetailType.Assistance;
    }

    return UserDetailType.Group;
  };

  if (loading || roleState === StoreState.Loading) {
    return <Loader />;
  }

  if (roleState === StoreState.Error) {
    return t("errors.unknown");
  }

  return (
    <Formik<IRoleForm>
      initialValues={{
        ...role!,

        categoryCalendar:
          role!.readCalendarList ||
          role!.readCalendarMy ||
          role!.readCalendarTeamWeek ||
          role!.readCalendarTeamDay,
        calendarList: getRoleType(
          role!.readCalendarList,
          role!.writeCalendarList
        ),
        calendarMy: getRoleType(role!.readCalendarMy, role!.writeCalendarMy),
        calendarTeamWeek: getRoleType(
          role!.readCalendarTeamWeek,
          role!.writeCalendarTeamWeek
        ),
        calendarTeamDay: getRoleType(
          role!.readCalendarTeamDay,
          role!.writeCalendarTeamDay
        ),

        categoryDemand: role!.readDemand || role!.writeDemand,
        demandDetail: getRoleType(role!.readDemand, role!.writeDemand),

        categoryClient:
          role!.createClient ||
          role!.readMyClients ||
          role!.readClientsInTheSameAssistance ||
          role!.readAllClients,
        clientCreate: getRoleTypeWrite(role!.createClient),
        clientDetail: getClientDetailValue(),
        clientGeneral: getRoleType(true, role!.writeClientGeneral),
        clientContactPerson: getRoleType(
          role!.readClientContactPersons,
          role!.writeClientContactPersons
        ),
        clientRisk: getRoleType(role!.readClientRisk, role!.writeClientRisk),
        clientNote: getRoleType(role!.readClientNotes, role!.writeClientNotes),
        clientHousingSituation: getRoleType(
          role!.readClientHousingSituation,
          role!.writeClientHousingSituation
        ),
        clientFinancingData: getRoleType(
          role!.readClientsFinancingData,
          role!.writeClientsFinancingData
        ),
        clientCare: getRoleType(role!.readCare, role!.writeCare),
        clientAction: getRoleType(
          role!.readClientAction,
          role!.writeClientAction
        ),
        clientCalendar: getRoleType(
          role!.readClientCalendar,
          role!.writeClientCalendar
        ),
        clientDemand: getRoleType(
          role!.readClientDemand,
          role!.writeClientDemand
        ),
        clientDocument: getRoleType(
          role!.readClientDocuments,
          role!.writeClientDocuments
        ),
        clientRights: getRoleType(
          role!.readClientsRights,
          role!.writeClientsRights
        ),
        clientChangeState: getRoleTypeWrite(role!.changeClientState),
        clientAnonymise: getRoleTypeWrite(role!.anonymiseClient),
        clientExport: getRoleTypeWrite(role!.exportClient),
        clientGdprAgreement: getRoleTypeWrite(role!.addClientGdprAgreement),
        clientGdprAgreementWithoutSecret: getRoleTypeWrite(
          role!.addClientGdprAgreementWithoutSecret
        ),

        categoryAssistance:
          role!.createAssistance ||
          role!.readAllAssistances ||
          role!.readAssistances,
        assistanceCreate: getRoleTypeWrite(role!.createAssistance),
        assistanceGeneral: getAssistanceGeneralValue(),
        assistanceChangeState: getRoleTypeWrite(role!.writeAssistanceState),

        categoryModule:
          role!.readAllTemplates ||
          role!.writeAllTemplates ||
          role!.readWorkflowTemplate ||
          role!.writeWorkflowTemplate ||
          role!.readWorkflow ||
          role!.writeWorkflow ||
          role!.readReporting ||
          role!.writeReporting,
        moduleDocument: getRoleType(
          role!.readAllTemplates,
          role!.writeAllTemplates
        ),
        moduleWorkflowTemplate: getRoleType(
          role!.readWorkflowTemplate,
          role!.writeWorkflowTemplate
        ),
        moduleWorkflow: getRoleType(role!.readWorkflow, role!.writeWorkflow),
        moduleReporting: getRoleType(role!.readReporting, role!.writeReporting),

        categoryAdmin:
          role!.readUsersInTheSameGroup ||
          role!.readUsersInTheSameAssistance ||
          role!.readAllUsers ||
          role!.readRole ||
          role!.writeRole ||
          role!.readUserGroups ||
          role!.writeUserGroups ||
          role!.readCareType ||
          role!.writeCareType ||
          role!.readActionListing ||
          role!.writeActionListing ||
          role!.readAssistanceCategoryListing ||
          role!.writeAssistanceCategoryListing ||
          role!.readClientContactPersonListing ||
          role!.writeClientContactPersonListing ||
          role!.readClientRiskListing ||
          role!.writeClientRiskListing ||
          role!.readSocialBenefitListing ||
          role!.writeSocialBenefitListing ||
          role!.readTargetGroupListing ||
          role!.writeTargetGroupListing ||
          role!.readContactPersonListing ||
          role!.writeContactPersonListing ||
          role!.readWorkflowCategoryListing ||
          role!.writeWorkflowCategoryListing ||
          role!.readHealthInsurance ||
          role!.writeHealthInsurance ||
          role!.readNotification ||
          role!.writeNotification ||
          role!.readBasicSettings ||
          role!.writeBasicSettings ||
          role!.readPublic ||
          role!.readMonitor ||
          role!.readLog,
        categoryAdminUser: getCategoryAdminUserValue(),
        adminUserAdd: getRoleTypeWrite(role!.addUsers),
        adminUserDetail: getAdminUserDetailValue(),
        adminUserChangeState: getRoleTypeWrite(role!.changeUserIsActive),
        adminRole: getRoleType(role!.readRole, role!.writeRole),
        adminGroup: getRoleType(role!.readUserGroups, role!.writeUserGroups),
        adminCareType: getRoleType(role!.readCareType, role!.writeCareType),
        adminListing: getRoleType(
          role!.readActionListing &&
            role!.readAssistanceCategoryListing &&
            role!.readClientContactPersonListing &&
            role!.readClientRiskListing &&
            role!.readSocialBenefitListing &&
            role!.readTargetGroupListing &&
            role!.readContactPersonListing &&
            role!.readWorkflowCategoryListing &&
            role!.readHealthInsurance,
          role!.writeActionListing &&
            role!.writeAssistanceCategoryListing &&
            role!.writeClientContactPersonListing &&
            role!.writeClientRiskListing &&
            role!.writeSocialBenefitListing &&
            role!.writeTargetGroupListing &&
            role!.writeContactPersonListing &&
            role!.writeHealthInsurance
        ),
        adminNotification: getRoleType(
          role!.readNotification,
          role!.writeNotification
        ),
        adminBasicSettings: getRoleType(
          role!.readBasicSettings,
          role!.writeBasicSettings
        ),
        adminPublic: getRoleTypeRead(role!.readPublic),
        adminMonitor: getRoleTypeRead(role!.readMonitor),
        adminLog: getRoleTypeRead(role!.readLog),
      }}
      validationSchema={Yup.object({
        name: validations.stringRequired(t),
        description: validations.stringRequired(t),
      })}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, isSubmitting, values }) => (
        <Form>
          <h1>{t("admin.role.detailTitle")}</h1>
          <FormGroup>
            {fields.map((f) => (
              <Input
                key={f.name}
                name={f.name}
                label={t("admin.role." + f.name)}
                error={touched[f.name] && !!errors[f.name]}
                maxLength={f.maxLen}
              />
            ))}
            {hasRight(identityRights, [RightType.ChangeRoleRights]) && (
              <>
                <AdminRoleHeader>
                  <div></div>
                  <div>{t("admin.role.rightNone")}</div>
                  <div>{t("admin.role.rightRead")}</div>
                  <div>{t("admin.role.rightWrite")}</div>
                </AdminRoleHeader>
                <AdminRoleCategory name="categoryCalendar" />
                {values.categoryCalendar && (
                  <AdminRoleCategoryContainer>
                    <AdminRoleRight name="calendarList" />
                    <AdminRoleRight name="calendarMy" />
                    <AdminRoleRight name="calendarTeamWeek" />
                    <AdminRoleRight name="calendarTeamDay" />
                  </AdminRoleCategoryContainer>
                )}

                <AdminRoleCategory name="categoryDemand" />
                {values.categoryDemand && (
                  <AdminRoleCategoryContainer>
                    <AdminRoleRight name="demandDetail" />
                  </AdminRoleCategoryContainer>
                )}

                <AdminRoleCategory name="categoryClient" />
                {values.categoryClient && (
                  <AdminRoleCategoryContainer>
                    <AdminRoleRight
                      name="clientCreate"
                      mode={RoleRightMode.Write}
                    />
                    <AdminRoleLimitationsRow>
                      <div>{t("admin.role.limitations")}</div>
                      <div />
                      <Input name="clientDetail" component="select">
                        {(
                          Object.keys(ClientDetailType) as Array<
                            keyof typeof ClientDetailType
                          >
                        ).map((key) => (
                          <option key={key} value={ClientDetailType[key]}>
                            {t(
                              "admin.role.clientDetails." +
                                ClientDetailType[key]
                            )}
                          </option>
                        ))}
                      </Input>
                    </AdminRoleLimitationsRow>
                    {values.clientDetail !== ClientDetailType.None && (
                      <>
                        <AdminRoleRight name="clientGeneral" />
                        <AdminRoleRight name="clientContactPerson" />
                        <AdminRoleRight name="clientRisk" />
                        <AdminRoleRight name="clientNote" />
                        <AdminRoleRight name="clientHousingSituation" />
                        <AdminRoleRight name="clientFinancingData" />
                        <AdminRoleRight name="clientCare" />
                        <AdminRoleRight name="clientAction" />
                        <AdminRoleRight name="clientCalendar" />
                        <AdminRoleRight name="clientDemand" />
                        <AdminRoleRight name="clientDocument" />
                        <AdminRoleRight name="clientRights" />
                        <AdminRoleRight
                          name="clientChangeState"
                          mode={RoleRightMode.Write}
                        />
                        <AdminRoleRight
                          name="clientAnonymise"
                          mode={RoleRightMode.Write}
                        />
                        <AdminRoleRight
                          name="clientExport"
                          mode={RoleRightMode.Write}
                        />
                        <AdminRoleRight
                          name="clientGdprAgreement"
                          mode={RoleRightMode.Write}
                        />
                        <AdminRoleRight
                          name="clientGdprAgreementWithoutSecret"
                          mode={RoleRightMode.Write}
                        />
                      </>
                    )}
                  </AdminRoleCategoryContainer>
                )}

                <AdminRoleCategory name="categoryAssistance" />
                {values.categoryAssistance && (
                  <AdminRoleCategoryContainer>
                    <AdminRoleRight
                      name="assistanceCreate"
                      mode={RoleRightMode.Write}
                    />
                    <AdminRoleRight name="assistanceGeneral" />
                    {values.assistanceGeneral !== RoleRightType.None && (
                      <AdminRoleRight
                        name="assistanceChangeState"
                        mode={RoleRightMode.Write}
                      />
                    )}
                  </AdminRoleCategoryContainer>
                )}

                <AdminRoleCategory name="categoryModule" />
                {values.categoryModule && (
                  <AdminRoleCategoryContainer>
                    <AdminRoleRight name="moduleDocument" />
                    <AdminRoleRight name="moduleWorkflowTemplate" />
                    <AdminRoleRight name="moduleWorkflow" />
                    <AdminRoleRight name="moduleReporting" />
                  </AdminRoleCategoryContainer>
                )}

                <AdminRoleCategory name="categoryAdmin" />
                {values.categoryAdmin && (
                  <AdminRoleCategoryContainer>
                    <AdminRoleRightContainer>
                      <AdminRoleSubCategoryLabel>
                        {t("admin.role.categoryAdminUser")}
                      </AdminRoleSubCategoryLabel>
                      <AdminRoleRadioContainer>
                        <AdminRoleRadio
                          name="categoryAdminUser"
                          type="radio"
                          value={RoleRightType.None}
                        />
                      </AdminRoleRadioContainer>
                      <AdminRoleRadioContainer>
                        <AdminRoleRadio
                          name="categoryAdminUser"
                          type="radio"
                          value={RoleRightType.Read}
                        />
                      </AdminRoleRadioContainer>
                      <AdminRoleRadioContainer>
                        <AdminRoleRadio
                          name="categoryAdminUser"
                          type="radio"
                          value={RoleRightType.Write}
                        />
                      </AdminRoleRadioContainer>
                      {values.categoryAdminUser !== RoleRightType.None && (
                        <Input name="adminUserDetail" component="select">
                          {(
                            Object.keys(UserDetailType) as Array<
                              keyof typeof UserDetailType
                            >
                          ).map((key) => (
                            <option key={key} value={UserDetailType[key]}>
                              {t(
                                "admin.role.adminUserDetails." +
                                  UserDetailType[key]
                              )}
                            </option>
                          ))}
                        </Input>
                      )}
                    </AdminRoleRightContainer>
                    {values.categoryAdminUser !== RoleRightType.None && (
                      <AdminRoleCategoryContainer>
                        <AdminRoleRight
                          name="adminUserAdd"
                          mode={RoleRightMode.Write}
                          firstColumn="8rem"
                        />
                        <AdminRoleRight
                          name="adminUserChangeState"
                          mode={RoleRightMode.Write}
                          firstColumn="8rem"
                        />
                      </AdminRoleCategoryContainer>
                    )}
                    <AdminRoleRight name="adminRole" />
                    <AdminRoleRight name="adminGroup" />
                    <AdminRoleRight name="adminCareType" />
                    <AdminRoleRight name="adminListing" />
                    <AdminRoleRight name="adminNotification" />
                    <AdminRoleRight name="adminBasicSettings" />
                    <AdminRoleRight
                      name="adminPublic"
                      mode={RoleRightMode.Read}
                    />
                    <AdminRoleRight
                      name="adminMonitor"
                      mode={RoleRightMode.Read}
                    />
                    <AdminRoleRight name="adminLog" mode={RoleRightMode.Read} />
                  </AdminRoleCategoryContainer>
                )}
              </>
            )}
          </FormGroup>
          {error && <ApiError>{error}</ApiError>}
          <FormButton
            ver="secondary"
            disabled={isSubmitting}
            onClick={handleCancel}
          >
            {t("common.back")}
          </FormButton>
          {hasRight(identityRights, [RightType.WriteRole]) && (
            <>
              <SpaceBetweenButtons />
              <SubmitForm />
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    roleState: selectRoleState(state),
    role: selectRole(state),
    identityRights: selectIdentityRights(state),
  };
};

const mapDispachToProps = {
  getRole,
  getDefaultRole,
  roleListLoad,
};

export default connect(mapStateToProps, mapDispachToProps)(AdminRoleDetail);
