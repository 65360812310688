export enum WorkflowType {
  Standard = "standard",
  PublicPortalDayCentre = "publicPortalDayCentre",
  PublicPortalSeniorTransport = "publicPortalSeniorTransport",
  PublicPortalOtherAssistance = "publicPortalOtherAssistance",
  PublicPortalCancelAction = "publicPortalCancelAction",
}

export enum WorkflowPriorityType {
  Low = "low",
  Medium = "medium",
  High = "high",
}

export enum WorkflowDefinitionItemType {
  Template = "template",
  Question = "question",
  Auto = "auto",
  Workflow = "workflow",
  WorkflowOut = "workflowOut",
}

export enum WorkflowDefinitionItemAutoType {
  State = "state",
}

export enum WorkflowDefinitionItemTopMode {
  AtLeastOne = "atLeastOne",
  AllPaths = "allPaths",
}

export interface IWorkflowItem {
  id: number;
  isActive: boolean;
  name: string;
  type: WorkflowType;
}

export interface IWorkflow {
  id: number;
  isActive: boolean;
  name: string;
  type: WorkflowType;
  priority: WorkflowPriorityType;

  categoryId: number;
  categoryName: string;

  definition: IWorkflowDefinition[];
}

export interface IWorkflowDefinition {
  id: number;
  parentId: ParentIdType[];
  parallerOrder: number;
  type: WorkflowDefinitionItemType;
  text?: string | null;
  autoType?: WorkflowDefinitionItemAutoType | null;
  templateId?: number | null;
  templateName?: string | null;
  topPortMode: WorkflowDefinitionItemTopMode;
  nestedWorkflowId?: number | null;
  nestedWorkflowName?: string | null;
}

export interface IWorkflowQuestion {
  id: number;
  parentId: ParentIdType[];
  parallerOrder: number;
  text: string;
  type: WorkflowDefinitionItemType;
  topPortMode: WorkflowDefinitionItemTopMode;
}

export type ParentIdType = number | null;
export type WorkflowSelectedQuestions = Record<number, number>;
