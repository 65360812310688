import { AxiosRequestConfig, AxiosResponse } from "axios";
import { IItemIdName } from "../models/common";
import { IGroupEdit, IGroupItem } from "../models/group";
import api from "../utils/api";

const getList = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<IGroupItem[]>> => api.get(`/api/Group/List`, config);

const getCount = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<number>> => api.get(`/api/Group/Count`, config);

const getSuggestionList = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<IItemIdName[]>> =>
  api.get(`/api/Group/Suggested/List`, config);

const addGroup = async (data: IGroupEdit): Promise<AxiosResponse> =>
  api.post(`/api/Group`, data);

const updateGroup = async (
  id: number,
  data: IGroupEdit
): Promise<AxiosResponse> => api.put(`/api/Group/${id}`, data);

const deleteGroup = async (id: number): Promise<AxiosResponse> =>
  api.delete(`/api/Group/${id}`);

const groupApi = {
  getList,
  getCount,
  getSuggestionList,
  addGroup,
  updateGroup,
  deleteGroup,
};

export default groupApi;
