import { IWorkflowTemplateItem } from "../../models/workflowTemplate";
import { IFilterType } from "../filterType";
import { IListType } from "../listType";

export enum WorkflowTemplateListTypes {
  WORKFLOW_TEMPLATE_LIST_FETCH_REQUEST = "@@workflowTemplateList/FETCH_REQUEST",
  WORKFLOW_TEMPLATE_LIST_FETCH_SUCCESS = "@@workflowTemplateList/FETCH_SUCCESS",
  WORKFLOW_TEMPLATE_LIST_FETCH_ERROR = "@@workflowTemplateList/FETCH_ERROR",
  WORKFLOW_TEMPLATE_LIST_COUNT_REQUEST = "@@workflowTemplateList/COUNT_REQUEST",
  WORKFLOW_TEMPLATE_LIST_COUNT_SUCCESS = "@@workflowTemplateList/COUNT_SUCCESS",
  WORKFLOW_TEMPLATE_LIST_COUNT_ERROR = "@@workflowTemplateList/COUNT_ERROR",
  WORKFLOW_TEMPLATE_LIST_CHANGE_ORDER = "@@workflowTemplateList/CHANGE_ORDER",
  WORKFLOW_TEMPLATE_LIST_CHANGE_PAGE = "@@workflowTemplateList/CHANGE_PAGE",
  WORKFLOW_TEMPLATE_LIST_CHANGE_FILTER = "@@workflowTemplateList/CHANGE_FILTER",
  WORKFLOW_TEMPLATE_LIST_RESET = "@@workflowTemplateList/RESET",
  WORKFLOW_TEMPLATE_LIST_LOAD = "@@workflowTemplateList/LOAD",
}

export interface IWorkflowTemplateListState
  extends IListType<IWorkflowTemplateItem, IFilterType> {}
