import { useMemo, useState } from "react";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { IItemIdName } from "../../models/common";
import { IApplicationState } from "../../store";
import { StoreState } from "../../store/storeState";
import { COLORS } from "../../styles/colors";
import { VerticalSpace } from "../../styles/spaces";
import Loader from "../common/Loader";
import BreadCrumb from "../common/navigation/BreadCrumb";
import { TailSpin } from "react-loader-spinner";
import { SpanRed } from "../../styles/text";
import ModuleDefaultDocumentFolder from "./ModuleDefaultDocumentFolder";
import ModuleDefaultDocumentDocument from "./ModuleDefaultDocumentDocument";
import {
  getDefaulDocumentDefaultFolder,
  getDefaultDocumentFolder,
  selectDefaultDocumentFolder,
  selectDefaultDocumentFolderState,
} from "../../store/defaultDocumentFolder";
import {
  changeDefaultDocumentFolderListFilter,
  IDefaultDocumentFolderListFilter,
  IDefaultDocumentFolderListState,
  selectDefaultDocumentFolderList,
} from "../../store/defaultDocumentFolderList";
import {
  changeDefaultDocumentDocumentListFilter,
  IDefaultDocumentDocumentListFilter,
  IDefaultDocumentDocumentListState,
  selectDefaultDocumentDocumentList,
} from "../../store/defaultDocumentDocumentList";
import Page from "../layout/Page";

interface IProps {
  defaultDocumentFolderState: StoreState;
  defaultDocumentFolder: IItemIdName[];
  defaultDocumentFolderListState: IDefaultDocumentFolderListState;
  defaultDocumentDocumentListState: IDefaultDocumentDocumentListState;
  getDefaultDocumentFolder(folderId: string): void;
  getDefaulDocumentDefaultFolder(): void;
  changeDefaultDocumentFolderListFilter(
    filter: IDefaultDocumentFolderListFilter
  ): void;
  changeDefaultDocumentDocumentListFilter(
    filter: IDefaultDocumentDocumentListFilter
  ): void;
}

const ModuleDefaultDocument: FC<IProps> = ({
  defaultDocumentFolderState,
  defaultDocumentFolder,
  defaultDocumentFolderListState,
  defaultDocumentDocumentListState,
  getDefaultDocumentFolder,
  getDefaulDocumentDefaultFolder,
  changeDefaultDocumentFolderListFilter,
  changeDefaultDocumentDocumentListFilter,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const { folderId } = useParams();

  useEffect(() => {
    if (folderId) {
      getDefaultDocumentFolder(folderId);
    } else {
      getDefaulDocumentDefaultFolder();
    }

    changeDefaultDocumentFolderListFilter({
      ...defaultDocumentFolderListState.filter!,
      idFolder: folderId ? parseInt(folderId) : null,
    });
    changeDefaultDocumentDocumentListFilter({
      ...defaultDocumentDocumentListState.filter!,
      idFolder: folderId ? parseInt(folderId) : null,
    });

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderId]);

  const breadCrumbItems = useMemo(() => {
    const items = [];
    items.push({
      label: t("module.document.root"),
      to: "/module/default-document",
    });

    items.push(
      ...defaultDocumentFolder.map((x) => ({
        label: x.name,
        to: `/module/default-document/${x.id}`,
      }))
    );

    return items;
  }, [defaultDocumentFolder, t]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Page>
      <h1>{t("module.defaultDocument.pageTitle")}</h1>
      <h2>{t("module.defaultDocument.pageSubtitle")}</h2>
      <p>
        {defaultDocumentFolderState === StoreState.Loading && (
          <TailSpin color={COLORS.loaderColor} width={16} height={16} />
        )}
        {defaultDocumentFolderState === StoreState.Error && (
          <SpanRed>{t("errors.unknown")}</SpanRed>
        )}
        {defaultDocumentFolderState === StoreState.Loaded && (
          <BreadCrumb items={breadCrumbItems} />
        )}
      </p>
      <VerticalSpace />
      <ModuleDefaultDocumentFolder />
      <VerticalSpace />
      <ModuleDefaultDocumentDocument />
    </Page>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    defaultDocumentFolderState: selectDefaultDocumentFolderState(state),
    defaultDocumentFolder: selectDefaultDocumentFolder(state),
    defaultDocumentFolderListState: selectDefaultDocumentFolderList(state),
    defaultDocumentDocumentListState: selectDefaultDocumentDocumentList(state),
  };
};

const mapDispachToProps = {
  getDefaultDocumentFolder,
  getDefaulDocumentDefaultFolder,
  changeDefaultDocumentFolderListFilter,
  changeDefaultDocumentDocumentListFilter,
};

export default connect(
  mapStateToProps,
  mapDispachToProps
)(ModuleDefaultDocument);
