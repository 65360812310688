import { IItemIdName } from "./common";

export enum CalendarItemType {
  Available,
  Unavailable,
}

export interface ICalendarResponseItem {
  user: IItemIdName;
  action: IItemIdName;
  dateTime: Date;
}

export interface ICalendarDataItem {
  datetime: Date;
  userId?: number | null;
  userName?: string | null;
  actionId?: number | null;
  actionName?: string | null;
}

export interface ICalendarItem {
  datetime: Date;
  joinCount?: number | null;
  data: ICalendarDataItem[];
}

export interface ICalendarTimeValue {
  hours: number;
  mins: number;
}

export interface ICalendarTimesLabels {
  times: ICalendarTimeValue[];
  labels: string[];
}

export interface ICalendarCol {
  title?: string;
  date: Date;
  items: ICalendarItem[];
}

export interface ICalendarAvailableRequest {
  from: Date;
  to: Date;

  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
}

export interface ICalendarCols {
  labels: string[];
  cols: ICalendarCol[];
}

export interface IActionItem {
  id: number;
  description?: string | null;
  userCreator: IItemIdName;
  userAssignee?: IItemIdName | null;
  client?: IItemIdName | null;
  type: IItemIdName;
  from: Date;
  to: Date;
}

export interface IAction {
  id: number;
  description?: string | null;
  userCreator: IItemIdName;
  userAssignee?: IItemIdName | null;
  client?: IItemIdName | null;
  type: IItemIdName;
  from?: Date;
  to?: Date;
}

export interface IActionCreateRequest {
  idClient?: number | null;
  idUserAssignee?: number | null;
  idActionListingType: number;
  description?: string | null;

  from: Date;
  to: Date;

  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
}

export enum ActionConflictReason {
  IsAssignable = "isAssignable",
  Unavailable = "unavailable",
  NotWorking = "notWorking",
}

export interface IActionConflict {
  timeInterval: {
    from: Date;
    to: Date;
  };
  conflictReason: ActionConflictReason;
}
