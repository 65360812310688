import { faAngleDoubleUp, faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AxiosResponse } from "axios";
import { Form, Formik } from "formik";
import { FC } from "react";
import { ExportType } from "../../../models/common";
import { IFilterType } from "../../../store/filterType";
import { FormGroup } from "../../../styles/form";
import Search from "../form/Search";
import { GridIconCollapse, GridIconExpand } from "../grid/GridIcons";
import FilterExportButtons from "./FilterExportButtons";
import FilterPanelExtended from "./FilterPanelExtended";
import {
  FilterContainer,
  FilterExtendedContainer,
  FilterHeaderContainer,
  FilterHeaderIcon,
  FilterSearchContainer,
  FilterTitleContainer,
} from "./FilterPanelStyles";

interface IProps {
  viewFiltration?: boolean;
  title?: string;
  isExpanded?: boolean;
  setIsExpanded?(newState: boolean): void;
  name: string;
  filter: IFilterType;
  initialValues?: any;
  validationSchema?: any;
  changeFilter(filter: IFilterType): void;
  doExport?(type: ExportType): Promise<AxiosResponse<Blob>>;
}

const FilterPanel: FC<IProps> = ({
  viewFiltration = true,
  title,
  isExpanded,
  setIsExpanded,
  name,
  filter,
  initialValues,
  validationSchema,
  changeFilter,
  doExport,
  children,
}) => {
  if (isExpanded === false && setIsExpanded) {
    return (
      <FilterTitleContainer>
        <GridIconExpand onClick={() => setIsExpanded(true)} />
        <h1>{title}</h1>
      </FilterTitleContainer>
    );
  }

  return (
    <FilterContainer>
      <FilterHeaderContainer>
        <FilterTitleContainer>
          {isExpanded === true && setIsExpanded && (
            <GridIconCollapse onClick={() => setIsExpanded(false)} />
          )}
          <h1>{title}</h1>
        </FilterTitleContainer>
        {viewFiltration && (
          <FilterSearchContainer>
            <Search
              name={name + "Search"}
              defaultValue={filter.search}
              onChanged={(value) => {
                changeFilter({ ...filter, search: value });
              }}
            />
            {children && (
              <FilterHeaderIcon>
                <FontAwesomeIcon
                  icon={filter.isOpen ? faAngleDoubleUp : faFilter}
                  size="2x"
                  onClick={() => {
                    changeFilter({ ...filter, isOpen: !filter.isOpen });
                  }}
                />
              </FilterHeaderIcon>
            )}
          </FilterSearchContainer>
        )}
      </FilterHeaderContainer>
      {children && (
        <FilterExtendedContainer open={filter.isOpen}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnMount={true}
            onSubmit={() => undefined}
          >
            {({ values, isValid }) => (
              <Form>
                <FilterPanelExtended
                  values={values}
                  isValid={isValid}
                  onChange={() => {
                    changeFilter({
                      search: filter.search,
                      isOpen: filter.isOpen,
                      ...values,
                    });
                  }}
                />
                <FormGroup>{children}</FormGroup>
              </Form>
            )}
          </Formik>
          {doExport && <FilterExportButtons doExport={doExport} />}
        </FilterExtendedContainer>
      )}
    </FilterContainer>
  );
};

export default FilterPanel;
