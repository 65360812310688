import { IItemIdName } from "./common";

export interface IDocumentEditOnlineUrl {
  token: string;
  url: string;
}

export interface IFolderItem {
  id: number;
  name: string;
  users: IItemIdName[];
  groups: IItemIdName[];
  roles: IItemIdName[];
  assistances: IItemIdName[];
  isClientVisible: boolean;
}

export enum SIGN_DOWN_TYPE {
  biometric = "BIO",
  code = "COD",
  codeNotInPerson = "CODNP",
}
