import { AppThunkAction, AppThunkActionAsync } from "..";
import * as actions from "./actions";
import clientApi from "../../api/client";
import { selectClientRevenueList } from ".";
import { IFilterType } from "../filterType";
import { CancelToken } from "axios";
import {
  listEffectCountFilter,
  listEffectGetFilter,
  listEffectLoad,
  listEffectReload,
  listEffectsCountCheck,
  listEffectsGetCheck,
  listEffectsGetCount,
  listEffectsGetNew,
  listEffectsGetParams,
} from "../listEffect";

export function getClientRevenueList(
  clientId: number,
  cancelToken: CancelToken
): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectClientRevenueList(getState());
    if (listEffectsGetCheck(state.state)) {
      return;
    }

    let newState = listEffectsGetNew(state);
    dispatch(actions.clientRevenueListFetchRequest(newState.newCountState));
    try {
      const params = listEffectsGetParams(state, newState.take);
      listEffectGetFilter(params, state.filter);

      const revenues = await clientApi.getClientRevenueList(clientId, {
        params,
        cancelToken,
      });
      cancelToken.throwIfRequested();
      newState = listEffectsGetCount(newState, revenues);

      dispatch(
        actions.clientRevenueListFetchSuccess({
          data: revenues.data,
          newCountState: newState.newCountState,
          newCount: newState.newCount,
        })
      );
    } catch {
      dispatch(
        actions.clientRevenueListFetchError(cancelToken.reason !== undefined)
      );
    }
  };
}

export function getClientRevenueListCount(
  clientId: number,
  cancelToken: CancelToken
): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectClientRevenueList(getState());
    if (listEffectsCountCheck(state.countState)) {
      return;
    }

    dispatch(actions.clientRevenueListCountRequest());
    try {
      const params = listEffectCountFilter(state.filter);
      const count = await clientApi.getClientRevenueCount(clientId, {
        params,
        cancelToken,
      });
      cancelToken.throwIfRequested();

      dispatch(actions.clientRevenueListCountSuccess(count.data));
    } catch {
      dispatch(
        actions.clientRevenueListCountError(cancelToken.reason !== undefined)
      );
    }
  };
}

export function changeClientRevenueListOrder(
  orderBy: string,
  orderDesc: boolean
): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.clientRevenueListChangeOrder({ orderBy, orderDesc }));
  };
}

export function changeClientRevenueListPage(page: number): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.clientRevenueListChangePage(page));
  };
}

export function changeClientRevenueListFilter(
  filter: IFilterType
): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.clientRevenueListChangeFilter(filter));
  };
}

export function clientRevenueListLoad(reload: boolean): AppThunkAction {
  return async (dispatch, getState) => {
    const state = selectClientRevenueList(getState());
    reload = listEffectReload(reload, state);
    if (reload) {
      dispatch(actions.clientRevenueListReset());
    } else {
      const newState = listEffectLoad(state);
      dispatch(
        actions.clientRevenueListLoadAction({
          newState: newState.newState,
          newCountState: newState.newCountState,
        })
      );
    }
  };
}
