import styled from "styled-components";

export const DashboardWidgetContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 1rem;
  row-gap: 2rem;
  margin-top: 3rem;
`;

export const DashboardWidgetDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

//todo: color
export const DashboardWidgetTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  align-items: center;
  border: 1px solid black;
  border-radius: 5px;

  hr {
    margin: 0.5rem;
  }
`;

//todo: colors
export const DashboardWidgetTitle = styled.div`
  font-size: 1.3rem;
  font-weight: bold;
  text-align: center;
  color: black;
  border-radius: 5px;
`;

//todo: color
export const DashboardWidgetCounterContainer = styled.div`
  position: absolute;
  right: -0.5rem;
  top: -0.5rem;
  text-align: right;
  width: 3rem;
  height: 3rem;
  font-size: 0.75rem;

  border-style: solid;
  border-width: 0 3rem 3rem 0;
  border-color: transparent #f4f5f5 transparent transparent;
`;

export const DashboardWidgetCounterText = styled.div`
  width: 3rem;
  height: 3rem;
  padding-top: 0.2rem;
  padding-right: 0.2rem;
  cursor: pointer;
`;
