import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import {
  AnyAction,
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
} from "redux";
import thunk, { ThunkAction, ThunkDispatch } from "redux-thunk";
import * as ReselectModule from "reselect";
import { identityReducer, IIdentityState } from "./identity";
import { IListingListState, listingListReducer } from "./listingList";
import { IUserState, userReducer } from "./user";
import { IUserListState, userListReducer } from "./userList";
import { IGroupListState, groupListReducer } from "./groupList";
import { IRoleListState, roleListReducer } from "./roleList";
import { IRoleState, roleReducer } from "./role";
import { IAssistanceListState, assistanceListReducer } from "./assistanceList";
import { IAssistanceState, assistanceReducer } from "./assistance";
import {
  IAssistancePersonListState,
  assistancePersonListReducer,
} from "./assistancePersonList";
import {
  IAssistancePersonState,
  assistancePersonReducer,
} from "./assistancePerson";
import {
  IAssistanceAddressListState,
  assistanceAddressListReducer,
} from "./assistanceAddressList";
import {
  IAssistanceAddressState,
  assistanceAddressReducer,
} from "./assistanceAddress";
import { IClientListState, clientListReducer } from "./clientList";
import { IClientState, clientReducer } from "./client";
import { IDashboardLinkState, dashboardLinkReducer } from "./dashboardLink";
import { clientHouseReducer, IClientHouseState } from "./clientHouse";
import { clientRiskReducer, IClientRiskState } from "./clientRisk";
import {
  clientRevenueListReducer,
  IClientRevenueListState,
} from "./clientRevenueList";
import {
  clientBenefitListReducer,
  IClientBenefitListState,
} from "./clientBenefitList";
import { clientDebtListReducer, IClientDebtListState } from "./clientDebtList";
import { clientNoteListReducer, IClientNoteListState } from "./clientNoteList";
import { actionListReducer, IActionListState } from "./actionList";
import { clientCareListReducer, IClientCareListState } from "./clientCareList";
import { clientCareReducer, IClientCareState } from "./clientCare";
import {
  ITemplateFolderListState,
  templateFolderListReducer,
} from "./templateFolderList";
import {
  ITemplateDocumentListState,
  templateDocumentListReducer,
} from "./templateDocumentList";
import { basicSettingsReducer, IBasicSettingsState } from "./basicSettings";
import { ITemplateFolderState, templateFolderReducer } from "./templateFolder";
import {
  clientDocumentListReducer,
  IClientDocumentListState,
} from "./clientDocumentList";
import { ITemplateTreeState, templateTreeReducer } from "./templateTree";
import {
  INotificationListState,
  notificationListReducer,
} from "./notificationList";
import { INotificationState, notificationReducer } from "./notification";
import {
  assistanceDocumentListReducer,
  IAssistanceDocumentListState,
} from "./assistanceDocumentList";
import { IWorkflowListState, workflowListReducer } from "./workflowList";
import { IWorkflowState, workflowReducer } from "./workflow";
import {
  clientContactPersonListReducer,
  IClientContactPersonListState,
} from "./clientContactPersonList";
import {
  clientContactPersonReducer,
  IClientContactPersonState,
} from "./clientContactPerson";
import { demandListReducer, IDemandListState } from "./demandList";
import { IReportListState, reportListReducer } from "./reportList";
import { ILogListState, logListReducer } from "./logList";
import { demandReducer, IDemandState } from "./demand";
import { demandHistoryReducer, IDemandHistoryState } from "./demandHistory";
import {
  clientDemandListReducer,
  IClientDemandListState,
} from "./clientDemandList";
import {
  clientActionListReducer,
  IClientActionListState,
} from "./clientActionList";
import { actionReducer, IActionState } from "./action";
import {
  clientRightGroupListReducer,
  IClientRightGroupListState,
} from "./clientRightGroupList";
import {
  clientRightUserListReducer,
  IClientRightUserListState,
} from "./clientRightUserList";
import { ISearchState, searchReducer } from "./search";
import {
  healthInsuranceListReducer,
  IHealthInsuranceListState,
} from "./healthInsuranceList";
import {
  IPasswordStrengthState,
  passwordStrengthReducer,
} from "./passwordStrength";
import {
  clientFolderListReducer,
  IClientFolderListState,
} from "./clientFolderList";
import { clientFolderReducer, IClientFolderState } from "./clientFolder";
import {
  IWorkflowUnsetTypesState,
  workflowUnsetTypesReducer,
} from "./workflowUnsetTypes";
import {
  defaultDocumentFolderListReducer,
  IDefaultDocumentFolderListState,
} from "./defaultDocumentFolderList";
import {
  defaultDocumentFolderReducer,
  IDefaultDocumentFolderState,
} from "./defaultDocumentFolder";
import {
  defaultDocumentDocumentListReducer,
  IDefaultDocumentDocumentListState,
} from "./defaultDocumentDocumentList";
import { careTypeReducer, ICareTypeState } from "./careType";
import { careTypeListReducer, ICareTypeListState } from "./careTypeList";
import {
  IWorkflowTemplateListState,
  workflowTemplateListReducer,
} from "./workflowTemplateList";
import {
  IWorkflowTemplateState,
  workflowTemplateReducer,
} from "./workflowTemplate";
import {
  diagramSettingsReducer,
  IDiagramSettingsState,
} from "./diagramSettings";
import {
  changeDemandListReducer,
  IChangeDemandListState,
} from "./changeDemandList";
import { changeDemandReducer, IChangeDemandState } from "./changeDemand";

export interface IApplicationState {
  identity: IIdentityState;
  passwordStrength: IPasswordStrengthState;
  userList: IUserListState;
  user: IUserState;
  listingList: IListingListState;
  healthInsuranceList: IHealthInsuranceListState;
  groupList: IGroupListState;
  roleList: IRoleListState;
  role: IRoleState;
  assistanceList: IAssistanceListState;
  assistance: IAssistanceState;
  assistancePersonList: IAssistancePersonListState;
  assistancePerson: IAssistancePersonState;
  assistanceAddressList: IAssistanceAddressListState;
  assistanceAddress: IAssistanceAddressState;
  assistanceDocumentList: IAssistanceDocumentListState;
  clientList: IClientListState;
  client: IClientState;
  clientHouse: IClientHouseState;
  clientRisk: IClientRiskState;
  clientRevenueList: IClientRevenueListState;
  clientBenefitList: IClientBenefitListState;
  clientDebtList: IClientDebtListState;
  clientNoteList: IClientNoteListState;
  clientCareList: IClientCareListState;
  clientCare: IClientCareState;
  clientFolderList: IClientFolderListState;
  clientFolder: IClientFolderState;
  clientDocumentList: IClientDocumentListState;
  clientContactPersonList: IClientContactPersonListState;
  clientContactPerson: IClientContactPersonState;
  clientDemandList: IClientDemandListState;
  clientActionList: IClientActionListState;
  clientRightGroupList: IClientRightGroupListState;
  clientRightUserList: IClientRightUserListState;
  dashboardLink: IDashboardLinkState;
  actionList: IActionListState;
  action: IActionState;
  templateFolderList: ITemplateFolderListState;
  templateFolder: ITemplateFolderState;
  templateDocumentList: ITemplateDocumentListState;
  templateTree: ITemplateTreeState;
  defaultDocumentFolderList: IDefaultDocumentFolderListState;
  defaultDocumentFolder: IDefaultDocumentFolderState;
  defaultDocumentDocumentList: IDefaultDocumentDocumentListState;
  basicSettings: IBasicSettingsState;
  notificationList: INotificationListState;
  notification: INotificationState;
  workflowTemplateList: IWorkflowTemplateListState;
  workflowTemplate: IWorkflowTemplateState;
  workflowList: IWorkflowListState;
  workflow: IWorkflowState;
  workflowUnsetTypes: IWorkflowUnsetTypesState;
  demandList: IDemandListState;
  demand: IDemandState;
  demandHistory: IDemandHistoryState;
  changeDemandList: IChangeDemandListState;
  changeDemand: IChangeDemandState;
  reportList: IReportListState;
  logList: ILogListState;
  search: ISearchState;
  careType: ICareTypeState;
  careTypeList: ICareTypeListState;
  diagramSettings: IDiagramSettingsState;
}

export type AppThunkDispatch = ThunkDispatch<
  IApplicationState,
  undefined,
  AnyAction
>;
export type AppThunkAction<R = void> = ThunkAction<
  R,
  IApplicationState,
  undefined,
  AnyAction
>;
export type AppThunkActionAsync<R = void> = AppThunkAction<Promise<R>>;

export type AppSelector<R> = ReselectModule.Selector<IApplicationState, R>;
export type AppParametricSelector<P, R> = ReselectModule.ParametricSelector<
  IApplicationState,
  P,
  R
>;

export const useAppSelector: TypedUseSelectorHook<IApplicationState> =
  useSelector;
export const useAppDispatch = () => useDispatch<AppThunkDispatch>();

export const createRootReducer = combineReducers({
  identity: identityReducer,
  passwordStrength: passwordStrengthReducer,
  userList: userListReducer,
  user: userReducer,
  listingList: listingListReducer,
  healthInsuranceList: healthInsuranceListReducer,
  groupList: groupListReducer,
  roleList: roleListReducer,
  role: roleReducer,
  assistanceList: assistanceListReducer,
  assistance: assistanceReducer,
  assistancePersonList: assistancePersonListReducer,
  assistancePerson: assistancePersonReducer,
  assistanceAddressList: assistanceAddressListReducer,
  assistanceAddress: assistanceAddressReducer,
  assistanceDocumentList: assistanceDocumentListReducer,
  clientList: clientListReducer,
  client: clientReducer,
  clientHouse: clientHouseReducer,
  clientRisk: clientRiskReducer,
  clientRevenueList: clientRevenueListReducer,
  clientBenefitList: clientBenefitListReducer,
  clientDebtList: clientDebtListReducer,
  clientNoteList: clientNoteListReducer,
  clientCareList: clientCareListReducer,
  clientCare: clientCareReducer,
  clientFolderList: clientFolderListReducer,
  clientFolder: clientFolderReducer,
  clientDocumentList: clientDocumentListReducer,
  clientContactPersonList: clientContactPersonListReducer,
  clientContactPerson: clientContactPersonReducer,
  clientDemandList: clientDemandListReducer,
  clientActionList: clientActionListReducer,
  clientRightGroupList: clientRightGroupListReducer,
  clientRightUserList: clientRightUserListReducer,
  dashboardLink: dashboardLinkReducer,
  actionList: actionListReducer,
  action: actionReducer,
  templateFolderList: templateFolderListReducer,
  templateFolder: templateFolderReducer,
  templateDocumentList: templateDocumentListReducer,
  templateTree: templateTreeReducer,
  defaultDocumentFolderList: defaultDocumentFolderListReducer,
  defaultDocumentFolder: defaultDocumentFolderReducer,
  defaultDocumentDocumentList: defaultDocumentDocumentListReducer,
  basicSettings: basicSettingsReducer,
  notificationList: notificationListReducer,
  notification: notificationReducer,
  workflowTemplateList: workflowTemplateListReducer,
  workflowTemplate: workflowTemplateReducer,
  workflowList: workflowListReducer,
  workflow: workflowReducer,
  workflowUnsetTypes: workflowUnsetTypesReducer,
  demandList: demandListReducer,
  demand: demandReducer,
  demandHistory: demandHistoryReducer,
  reportList: reportListReducer,
  logList: logListReducer,
  search: searchReducer,
  careType: careTypeReducer,
  careTypeList: careTypeListReducer,
  diagramSettings: diagramSettingsReducer,
  changeDemandList: changeDemandListReducer,
  changeDemand: changeDemandReducer,
});

const myCreateStore = () => {
  if (process.env.REACT_APP_REDUX_DEVTOOLS === "true") {
    const composeEnhancers =
      (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    return createStore(
      createRootReducer,
      composeEnhancers(applyMiddleware(thunk))
    );
  } else {
    return createStore(createRootReducer, applyMiddleware(thunk));
  }
};

export const store = myCreateStore();
