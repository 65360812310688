import { IActionItem } from "../../models/calendar";
import { IFilterType } from "../filterType";
import { IListType } from "../listType";

export enum ClientActionListTypes {
  CLIENT_ACTION_LIST_FETCH_REQUEST = "@@clientActionList/FETCH_REQUEST",
  CLIENT_ACTION_LIST_FETCH_SUCCESS = "@@clientActionList/FETCH_SUCCESS",
  CLIENT_ACTION_LIST_FETCH_ERROR = "@@clientActionList/FETCH_ERROR",
  CLIENT_ACTION_LIST_COUNT_REQUEST = "@@clientActionList/COUNT_REQUEST",
  CLIENT_ACTION_LIST_COUNT_SUCCESS = "@@clientActionList/COUNT_SUCCESS",
  CLIENT_ACTION_LIST_COUNT_ERROR = "@@clientActionList/COUNT_ERROR",
  CLIENT_ACTION_LIST_CHANGE_ORDER = "@@clientActionList/CHANGE_ORDER",
  CLIENT_ACTION_LIST_CHANGE_PAGE = "@@clientActionList/CHANGE_PAGE",
  CLIENT_ACTION_LIST_CHANGE_FILTER = "@@clientActionList/CHANGE_FILTER",
  CLIENT_ACTION_LIST_RESET = "@@clientActionList/RESET",
  CLIENT_ACTION_LIST_LOAD = "@@clientActionList/LOAD",
}

export interface IClientActionListState
  extends IListType<IActionItem, IFilterType> {}
