import { FC, useMemo } from "react";
import {
  AbstractModelFactory,
  AbstractReactFactory,
  GenerateWidgetEvent,
} from "@projectstorm/react-canvas-core";
import {
  DiagramEngine,
  LinkModel,
  NodeModel,
  NodeModelGenerics,
  PortModelAlignment,
} from "@projectstorm/react-diagrams-core";
import {
  DefaultLinkModel,
  DefaultPortModel,
  RightAngleLinkModel,
} from "@projectstorm/react-diagrams";
import {
  WorkflowDefinitionItemAutoType,
  WorkflowDefinitionItemTopMode,
  WorkflowDefinitionItemType,
} from "../../../models/workflow";
import {
  DiagramMultipleWrapper,
  PortWidgetWrapperTop,
  PortWidgetWrapperBottomMultiple,
  StyledPortWidget,
  StyledPortWidgetMultiple,
  DiagramStateIcon,
  PortWidgetWrapperBottom,
  StyledPortWidgetMultipleContainer,
  DiagramTopPortMode,
  DiagramTitle,
  PortWidgetWrapperBottomMultiple2,
} from "./DiagramStyles";
import { useTranslation } from "react-i18next";
import { DemandStateType, IDemandHistoryItem } from "../../../models/demand";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { COLORS } from "../../../styles/colors";
import { DiagramPortFoundType } from "./DiagramTypes";
import {
  DIAGRAM_MULTIPLE_FAKE_PORT_CLASS,
  DIAGRAM_MULTIPLE_MAX_ITEMS,
} from "./DiagramConsts";
import { store } from "../../../store";
import { selectDiagramSettings } from "../../../store/diagramSettings";

const NAME = "multiple";

class Factory extends AbstractReactFactory<DiagramMultipleNode, DiagramEngine> {
  constructor() {
    super(NAME);
  }

  generateReactWidget(
    event: GenerateWidgetEvent<DiagramMultipleNode>
  ): JSX.Element {
    return <Widget engine={this.engine} node={event.model} />;
  }

  generateModel() {
    return new DiagramMultipleNode({
      type: WorkflowDefinitionItemType.Question,
      items: [],
      topPortMode: WorkflowDefinitionItemTopMode.AtLeastOne,
    });
  }
}

interface Item {
  id: string;
  name: string;
}

interface NodeProps {
  //Question and Auto only.
  type: WorkflowDefinitionItemType;
  name?: string | null;
  autoType?: WorkflowDefinitionItemAutoType | null;
  items: Item[];
  topPortMode: WorkflowDefinitionItemTopMode;
  disableClick?: boolean;
  notFound?: boolean;
  historyItem?: IDemandHistoryItem;
  highlight?: boolean;
  historyPort?: string;
  portFound?: DiagramPortFoundType;
  isLocked?: boolean;
}

export class DiagramMultipleNode extends NodeModel<NodeModelGenerics> {
  constructor(data: NodeProps) {
    super({
      type: NAME,
      extras: data,
    });

    this.addPort(new Port(PortModelAlignment.TOP, PortModelAlignment.TOP));

    for (const item of data.items) {
      this.addPort(new Port(PortModelAlignment.BOTTOM, item.id));
    }

    this.setLocked(data.isLocked);
  }

  myPortTop() {
    return this.getPort(PortModelAlignment.TOP) as Port;
  }

  myPortItem(id: string) {
    return this.getPort(id) as Port;
  }

  myData() {
    return this.options.extras as NodeProps;
  }
}

interface WidgetProps {
  node: DiagramMultipleNode;
  engine: DiagramEngine;
}

const Widget: FC<WidgetProps> = ({ node, engine }) => {
  const { t } = useTranslation();

  const portTop = node.myPortTop();
  const data = node.myData();

  const portTopLinksCount = Object.keys(portTop.getLinks()).length;
  const title =
    data.type === WorkflowDefinitionItemType.Auto
      ? t(`module.workflow.autoTypes.${data.autoType}`)
      : data.name;

  let icon = null;
  if (data.historyItem?.state === DemandStateType.Finished) {
    icon = (
      <DiagramStateIcon icon={faCircleCheck} color={COLORS.successIconColor} />
    );
  }

  const items = useMemo(
    () =>
      data.items.map((x, index) => {
        let notFound = true;
        if (data.portFound === DiagramPortFoundType.History) {
          notFound = data.historyPort !== x.id;
        }

        const title =
          data.type === WorkflowDefinitionItemType.Auto ? t(x.name) : x.name;
        const useIndex = data.items.length > DIAGRAM_MULTIPLE_MAX_ITEMS;

        return (
          <StyledPortWidgetMultipleContainer
            key={x.id}
            className={DIAGRAM_MULTIPLE_FAKE_PORT_CLASS}
            data-id={x.id}
          >
            <StyledPortWidgetMultiple
              notFound={notFound}
              disabled={!!data.notFound}
              title={title}
            >
              {useIndex ? index + 1 : title}
            </StyledPortWidgetMultiple>
            <PortWidgetWrapperBottom>
              <StyledPortWidget port={node.myPortItem(x.id)} engine={engine} />
            </PortWidgetWrapperBottom>
          </StyledPortWidgetMultipleContainer>
        );
      }),
    [
      data.items,
      data.type,
      data.portFound,
      data.notFound,
      data.historyPort,
      engine,
      node,
      t,
    ]
  );

  return (
    <DiagramMultipleWrapper
      highlight={!!data.highlight}
      canClick={!data.disableClick}
      notFound={!!data.notFound}
      maxWidth={data.items.length <= DIAGRAM_MULTIPLE_MAX_ITEMS}
    >
      <PortWidgetWrapperTop>
        <StyledPortWidget port={portTop} engine={engine} />
      </PortWidgetWrapperTop>
      {portTopLinksCount > 1 && <DiagramTopPortMode mode={data.topPortMode} />}
      <DiagramTitle title={title ?? undefined}>
        {icon}
        {title}
      </DiagramTitle>
      <PortWidgetWrapperBottomMultiple>
        <PortWidgetWrapperBottomMultiple2 count={data.items.length}>
          {items}
        </PortWidgetWrapperBottomMultiple2>
      </PortWidgetWrapperBottomMultiple>
    </DiagramMultipleWrapper>
  );
};

class Port extends DefaultPortModel {
  constructor(alignment: PortModelAlignment, name: string) {
    super({
      type: NAME,
      name: name,
      alignment: alignment,
      in: alignment === PortModelAlignment.TOP,
    });
  }

  createLinkModel(factory?: AbstractModelFactory<LinkModel>): LinkModel {
    if (factory) {
      return factory.generateModel({});
    }

    const diagramSettings = selectDiagramSettings(store.getState());
    if (diagramSettings.linkCurved) {
      return new DefaultLinkModel();
    }

    return new RightAngleLinkModel();
  }
}

export const DiagramMultipleRegister = (engine: DiagramEngine) => {
  engine.getNodeFactories().registerFactory(new Factory());
};
