export enum LoginResponseStatusType {
  None = "none",
  BadLoginOrPass = "badLoginOrPass",
  TooMuchAttempts = "tooMuchAttempts",
  Ok = "ok",
  Expired = "expired",
  Error = "error",
  TwoFactor = "twoFactor",
}

export enum RightType {
  CreateAssistance = "CreateAssistance",
  ReadAssistances = "ReadAssistances",
  ReadAllAssistances = "ReadAllAssistances",
  WriteAssistances = "WriteAssistances",
  WriteAllAssistances = "WriteAllAssistances",
  WriteAssistanceState = "WriteAssistanceState",
  ReadWorkflowTemplate = "ReadWorkflowTemplate",
  WriteWorkflowTemplate = "WriteWorkflowTemplate",
  ReadWorkflow = "ReadWorkflow",
  WriteWorkflow = "WriteWorkflow",

  ReadAllClients = "ReadAllClients",
  ReadMyClients = "ReadMyClients",
  ReadClientsInTheSameAssistance = "ReadClientsInTheSameAssistance",
  ReadClientsFinancingData = "ReadClientsFinancingData",
  ReadClientNotes = "ReadClientNotes",
  ReadClientContactPersons = "ReadClientContactPersons",
  ReadClientsRights = "ReadClientsRights",
  ReadClientRisk = "ReadClientRisk",
  ReadClientHousingSituation = "ReadClientHousingSituation",
  ReadClientAction = "ReadClientAction",
  ReadClientDemand = "ReadClientDemand",

  WriteClientContactPersons = "WriteClientContactPersons",
  CreateClient = "CreateClient",
  WriteAllClients = "WriteAllClients",
  WriteMyClients = "WriteMyClients",
  WriteClientsInTheSameAssistance = "WriteClientsInTheSameAssistance",
  WriteClientGeneral = "WriteClientGeneral",
  WriteClientNotes = "WriteClientNotes",
  WriteClientsFinancingData = "WriteClientsFinancingData",
  WriteClientsRights = "WriteClientsRights",
  WriteClientRisk = "WriteClientRisk",
  WriteClientHousingSituation = "WriteClientHousingSituation",
  WriteClientAction = "WriteClientAction",
  WriteClientDemand = "WriteClientDemand",
  ChangeClientState = "ChangeClientState",

  ReadAllUsers = "ReadAllUsers",
  ReadUsersInTheSameGroup = "ReadUsersInTheSameGroup",
  ReadUsersInTheSameAssistance = "ReadUsersInTheSameAssistance",
  ReadUsers = "ReadUsers",
  WriteUsers = "WriteUsers",
  AddUsers = "AddUsers",
  WriteAllUsers = "WriteAllUsers",
  WriteUsersInTheSameGroup = "WriteUsersInTheSameGroup",
  WriteUsersInTheSameAssistance = "WriteUsersInTheSameAssistance",
  ChangeUserIsActive = "ChangeUserIsActive",
  AddClientGdprAgreement = "AddClientGdprAgreement",
  AddClientGdprAgreementWithoutSecret = "AddClientGdprAgreementWithoutSecret",

  ReadActionListing = "ReadActionListing",
  WriteActionListing = "WriteActionListing",
  ReadAssistanceCategoryListing = "ReadAssistanceCategoryListing",
  WriteAssistanceCategoryListing = "WriteAssistanceCategoryListing",
  ReadClientContactPersonListing = "ReadClientContactPersonListing",
  WriteClientContactPersonListing = "WriteClientContactPersonListing",
  ReadClientRiskListing = "ReadClientRiskListing",
  WriteClientRiskListing = "WriteClientRiskListing",
  ReadSocialBenefitListing = "ReadSocialBenefitListing",
  WriteSocialBenefitListing = "WriteSocialBenefitListing",
  ReadTargetGroupListing = "ReadTargetGroupListing",
  WriteTargetGroupListing = "WriteTargetGroupListing",
  ReadContactPersonListing = "ReadContactPersonListing",
  WriteContactPersonListing = "WriteContactPersonListing",
  ReadWorkflowCategoryListing = "ReadWorkflowCategoryListing",
  WriteWorkflowCategoryListing = "WriteWorkflowCategoryListing",

  ReadHealthInsurance = "ReadHealthInsurance",
  WriteHealthInsurance = "WriteHealthInsurance",

  ReadBasicSettings = "ReadBasicSettings",
  WriteBasicSettings = "WriteBasicSettings",
  ReadPublic = "ReadPublic",
  ReadMonitor = "ReadMonitor",
  ReadLog = "ReadLog",
  ReadUserGroups = "ReadUserGroups",
  WriteUserGroups = "WriteUserGroups",
  ReadCareType = "ReadCareType",
  WriteCareType = "WriteCareType",
  ReadRole = "ReadRole",
  WriteRole = "WriteRole",
  ChangeRoleRights = "ChangeRoleRights",
  AnonymiseClient = "AnonymiseClient",
  ExportClient = "ExportClient",

  ReadDemand = "ReadDemand",
  WriteDemand = "WriteDemand",
  AssignDemand = "AssignDemand",
  ChangeAssignedUserDemand = "ChangeAssignedUserDemand",
  ChangeAssignedGroupDemand = "ChangeAssignedGroupDemand",

  ReadAllActions = "ReadAllActions",
  ReadAction = "ReadAction",
  WriteAllActions = "WriteAllActions",
  WriteAction = "WriteAction",
  CancelAction = "CancelAction",
  UpdateAssignedUserAction = "UpdateAssignedUserAction",
  DeleteAction = "DeleteAction",

  ReadAllCalendars = "ReadAllCalendars",
  ReadCalendar = "ReadCalendar",
  ReadClientCalendar = "ReadClientCalendar",
  WriteAllCalendars = "WriteAllCalendars",
  WriteCalendar = "WriteCalendar",
  WriteClientCalendar = "WriteClientCalendar",
  ReadCalendarList = "ReadCalendarList",
  WriteCalendarList = "WriteCalendarList",
  ReadCalendarMy = "ReadCalendarMy",
  WriteCalendarMy = "WriteCalendarMy",
  ReadCalendarTeamWeek = "ReadCalendarTeamWeek",
  WriteCalendarTeamWeek = "WriteCalendarTeamWeek",
  ReadCalendarTeamDay = "ReadCalendarTeamDay",
  WriteCalendarTeamDay = "WriteCalendarTeamDay",

  ReadAllTemplates = "ReadAllTemplates",
  ReadClientDocuments = "ReadClientDocuments",
  WriteClientDocuments = "WriteClientDocuments",
  ReadAssistanceDocuments = "ReadAssistanceDocuments",
  WriteAssistanceDocuments = "WriteAssistanceDocuments",
  WriteAllTemplates = "WriteAllTemplates",
  DeleteAnyDocument = "DeleteAnyDocument",
  ReadDeletedDocuments = "ReadDeletedDocuments",
  ReadFolders = "ReadFolders",
  WriteFolders = "WriteFolders",
  SoftDeleteFolders = "SoftDeleteFolders",

  ReadCare = "ReadCare",
  WriteCare = "WriteCare",
  ReadNotification = "ReadNotification",
  WriteNotification = "WriteNotification",
  ReadReporting = "ReadReporting",
  WriteReporting = "WriteReporting",
}

export interface ILoginData {
  login: string;
  pass: string;
}

export interface ILoginResponse {
  status: LoginResponseStatusType;
  token: string | null;
  tokenValidMinutes: number | null;
  userMaxIdleMinutes: number | null;
}

export interface ITwoFactorData {
  secret: string;
}

export interface IIdentity {
  id: number;
  name: string;
  rightsList: RightType[];
}

export interface IForgottenPasswordData {
  email: string;
}

export interface IResetPasswordData {
  secret?: string | null;
  pass: string;
  passRepeat: string;
}

export interface IChangePasswordData {
  oldPassword: string;
  newPassword: string;
  newPasswordRepeat: string;
}

export interface IChangePasswordResult {
  token: string;
  tokenValidMinutes: number;
  userMaxIdleMinutes: number;
}
