import { createAction } from "@reduxjs/toolkit";
import { IAssistanceDocumentItem } from "../../models/assistance";
import { StoreState, StoreStateDontLoad } from "../storeState";
import {
  AssistanceDocumentListTypes,
  IAssistanceDocumentListFilterType,
} from "./types";

export const assistanceDocumentListFetchRequest = createAction<
  StoreStateDontLoad,
  AssistanceDocumentListTypes.ASSISTANCE_DOCUMENT_LIST_FETCH_REQUEST
>(AssistanceDocumentListTypes.ASSISTANCE_DOCUMENT_LIST_FETCH_REQUEST);

export const assistanceDocumentListFetchSuccess = createAction<
  {
    data: IAssistanceDocumentItem[];
    newCountState: StoreStateDontLoad;
    newCount: number;
  },
  AssistanceDocumentListTypes.ASSISTANCE_DOCUMENT_LIST_FETCH_SUCCESS
>(AssistanceDocumentListTypes.ASSISTANCE_DOCUMENT_LIST_FETCH_SUCCESS);

export const assistanceDocumentListFetchError = createAction<
  boolean,
  AssistanceDocumentListTypes.ASSISTANCE_DOCUMENT_LIST_FETCH_ERROR
>(AssistanceDocumentListTypes.ASSISTANCE_DOCUMENT_LIST_FETCH_ERROR);

export const assistanceDocumentListCountRequest = createAction<
  void,
  AssistanceDocumentListTypes.ASSISTANCE_DOCUMENT_LIST_COUNT_REQUEST
>(AssistanceDocumentListTypes.ASSISTANCE_DOCUMENT_LIST_COUNT_REQUEST);

export const assistanceDocumentListCountSuccess = createAction<
  number,
  AssistanceDocumentListTypes.ASSISTANCE_DOCUMENT_LIST_COUNT_SUCCESS
>(AssistanceDocumentListTypes.ASSISTANCE_DOCUMENT_LIST_COUNT_SUCCESS);

export const assistanceDocumentListCountError = createAction<
  boolean,
  AssistanceDocumentListTypes.ASSISTANCE_DOCUMENT_LIST_COUNT_ERROR
>(AssistanceDocumentListTypes.ASSISTANCE_DOCUMENT_LIST_COUNT_ERROR);

export const assistanceDocumentListChangeOrder = createAction<
  { orderBy: string; orderDesc: boolean },
  AssistanceDocumentListTypes.ASSISTANCE_DOCUMENT_LIST_CHANGE_ORDER
>(AssistanceDocumentListTypes.ASSISTANCE_DOCUMENT_LIST_CHANGE_ORDER);

export const assistanceDocumentListChangePage = createAction<
  number,
  AssistanceDocumentListTypes.ASSISTANCE_DOCUMENT_LIST_CHANGE_PAGE
>(AssistanceDocumentListTypes.ASSISTANCE_DOCUMENT_LIST_CHANGE_PAGE);

export const assistanceDocumentListChangeFilter = createAction<
  IAssistanceDocumentListFilterType,
  AssistanceDocumentListTypes.ASSISTANCE_DOCUMENT_LIST_CHANGE_FILTER
>(AssistanceDocumentListTypes.ASSISTANCE_DOCUMENT_LIST_CHANGE_FILTER);

export const assistanceDocumentListReset = createAction<
  void,
  AssistanceDocumentListTypes.ASSISTANCE_DOCUMENT_LIST_RESET
>(AssistanceDocumentListTypes.ASSISTANCE_DOCUMENT_LIST_RESET);

export const assistanceDocumentListLoadAction = createAction<
  { newState: StoreState; newCountState: StoreStateDontLoad },
  AssistanceDocumentListTypes.ASSISTANCE_DOCUMENT_LIST_LOAD
>(AssistanceDocumentListTypes.ASSISTANCE_DOCUMENT_LIST_LOAD);
