import {
  faFileWord,
  faProjectDiagram,
  faTasks,
} from "@fortawesome/free-solid-svg-icons";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Routes, Route } from "react-router";
import { RightType } from "../../models/auth";
import { IApplicationState } from "../../store";
import { selectIdentityRights } from "../../store/identity";
import { filterMenu } from "../../utils/rights";
import AuthRoute from "../auth/AuthRoute";
import { SidebarPageContainer } from "../layout/LayoutStyles";
import Sidebar from "../layout/Sidebar";
import ModuleDefaultDocument from "./ModuleDefaultDocument";
import ModuleDocument from "./ModuleDocument";
import ModuleReport from "./ModuleReport";
import ModuleWorkflow from "./ModuleWorkflow";
import ModuleWorkflowDetail from "./ModuleWorkflowDetail";
import ModuleWorkflowTemplate from "./ModuleWorkflowTemplate";
import ModuleWorkflowTemplateDetail from "./ModuleWorkflowTemplateDetail";

const links = [
  { id: "document", icon: faFileWord, rights: [RightType.ReadAllTemplates] },
  {
    id: "default-document",
    icon: faFileWord,
    //todo: rights ?
    rights: [RightType.ReadAllTemplates],
  },
  {
    id: "workflow-template",
    icon: faProjectDiagram,
    rights: [RightType.ReadWorkflowTemplate],
  },
  { id: "workflow", icon: faProjectDiagram, rights: [RightType.ReadWorkflow] },
  { id: "report", icon: faTasks, rights: [RightType.ReadReporting] },
];

interface IProps {
  identityRights?: RightType[];
}

const Module: FC<IProps> = ({ identityRights }) => {
  const { t } = useTranslation();

  return (
    <SidebarPageContainer>
      <Sidebar
        title={t("module.title")}
        showTitle={true}
        links={filterMenu(identityRights, links).map((l) => ({
          key: l.id,
          to: "/module/" + l.id,
          text: t(`module.nav.${l.id}`),
          icon: l.icon,
        }))}
      />
      <Routes>
        <Route
          path="document"
          element={
            <AuthRoute rights={[RightType.ReadAllTemplates]}>
              <ModuleDocument />
            </AuthRoute>
          }
        />
        <Route
          path="document/:folderId"
          element={
            <AuthRoute rights={[RightType.ReadAllTemplates]}>
              <ModuleDocument />
            </AuthRoute>
          }
        />
        <Route
          path="default-document"
          element={
            <AuthRoute
              //todo: rights ?
              rights={[RightType.ReadAllTemplates]}
            >
              <ModuleDefaultDocument />
            </AuthRoute>
          }
        />
        <Route
          path="default-document/:folderId"
          element={
            <AuthRoute
              //todo: rights ?
              rights={[RightType.ReadAllTemplates]}
            >
              <ModuleDefaultDocument />
            </AuthRoute>
          }
        />
        <Route
          path="workflow-template"
          element={
            <AuthRoute rights={[RightType.ReadWorkflowTemplate]}>
              <ModuleWorkflowTemplate />
            </AuthRoute>
          }
        />
        <Route
          path="workflow-template/:workflowTemplateId"
          element={
            <AuthRoute rights={[RightType.ReadWorkflowTemplate]}>
              <ModuleWorkflowTemplateDetail />
            </AuthRoute>
          }
        />
        <Route
          path="workflow"
          element={
            <AuthRoute rights={[RightType.ReadWorkflow]}>
              <ModuleWorkflow />
            </AuthRoute>
          }
        />
        <Route
          path="workflow/:workflowId"
          element={
            <AuthRoute rights={[RightType.ReadWorkflow]}>
              <ModuleWorkflowDetail />
            </AuthRoute>
          }
        />
        <Route
          path="report"
          element={
            <AuthRoute rights={[RightType.ReadReporting]}>
              <ModuleReport />
            </AuthRoute>
          }
        />
      </Routes>
    </SidebarPageContainer>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    identityRights: selectIdentityRights(state),
  };
};

export default connect(mapStateToProps)(Module);
