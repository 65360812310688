import { Form, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../styles/button";
import { FormGroup, ApiError } from "../../styles/form";
import { errorToast, promiseToastSave } from "../../utils/toasts";
import CustomModal from "../common/modal/CustomModal";
import SuggestionFormik from "../common/suggestion/SuggestionFormik";
import { handleLoadUserSuggestions } from "../../utils/suggestions";
import calendarApi from "../../api/calendar";
import Input from "../common/form/Input";
import { VerticalSpace } from "../../styles/spaces";
import { TailSpin } from "react-loader-spinner";
import { format } from "date-fns";
import { DATE_TIME_FORMAT, PAGE_SIZE, TIME_FORMAT } from "../../utils/consts";
import { COLORS } from "../../styles/colors";
import validations from "../../utils/validations";
import { SubmitModalForm } from "../common/form/SubmitForm";
import { ActionConflictReason, IActionConflict } from "../../models/calendar";
import { errorSet } from "../../utils/error";
import axios from "axios";

export interface IFormData {
  userId: number;
  userName: string;
  unassigned: boolean;
}

interface IAssignUserConflictsProps {
  selectedIds: number[];
}

const AssignUserModalConflicts: FC<IAssignUserConflictsProps> = ({
  selectedIds,
}) => {
  const [conflicts, setConflicts] = useState<{
    data: IActionConflict[];
    loading: boolean;
    tooMuch: boolean;
  }>({ loading: false, data: [], tooMuch: false });

  const { values, setValues } = useFormikContext<IFormData>();

  const { t } = useTranslation();

  useEffect(() => {
    const token = axios.CancelToken.source();

    (async () => {
      if (values.userId) {
        setValues({ ...values, unassigned: false });
        setConflicts({ data: [], loading: true, tooMuch: false });
        try {
          const response = await calendarApi.checkAssignAvailabilityByActions(
            values.userId,
            selectedIds,
            PAGE_SIZE + 1,
            token.token
          );

          if (response.data.length > PAGE_SIZE) {
            setConflicts({
              data: response.data.slice(0, PAGE_SIZE),
              loading: false,
              tooMuch: true,
            });
          } else {
            setConflicts({
              data: response.data,
              loading: false,
              tooMuch: false,
            });
          }
        } catch {
          if (!token.token.reason) {
            errorToast(t("calendar.assignment.conflicts.errorLoading"));
            setConflicts({ data: [], loading: false, tooMuch: false });
          }
        }
      } else {
        setConflicts({ data: [], loading: false, tooMuch: false });
      }
    })();

    return () => {
      token.cancel();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.userId]);

  useEffect(() => {
    if (values.unassigned) {
      setValues({ ...values, userId: 0 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.unassigned]);

  if (!values.userId) return null;
  return (
    <>
      {conflicts.data.length > 0 && (
        <>
          <VerticalSpace />
          <p>{t("calendar.assignment.conflicts.title")}</p>
          <VerticalSpace />
        </>
      )}
      {conflicts.loading ? (
        <TailSpin color={COLORS.loaderColor} width={48} height={48} />
      ) : (
        <ul>
          {conflicts.data
            .filter(
              (x) => x.conflictReason !== ActionConflictReason.IsAssignable
            )
            .map((x, index) => (
              <li key={index}>
                {format(x.timeInterval.from, DATE_TIME_FORMAT)}
                {" - "}
                {format(x.timeInterval.to, TIME_FORMAT)}
                {" ("}
                {x.conflictReason === ActionConflictReason.Unavailable &&
                  t("calendar.assignment.conflicts.unavailable")}
                {x.conflictReason === ActionConflictReason.NotWorking &&
                  t("calendar.assignment.conflicts.notWorking")}
                {")"}
              </li>
            ))}
          {conflicts.tooMuch && <li>...</li>}
        </ul>
      )}
    </>
  );
};

export interface IProps {
  selectedActionIds: number[];
  isOpen: boolean;
  close(): void;
  confirm(): void;
}

const AssignUserModal: FC<IProps> = ({
  selectedActionIds,
  isOpen,
  close,
  confirm,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setError(null);
  }, [isOpen]);

  const handleSubmit = async (data: IFormData) => {
    setError(null);

    try {
      await promiseToastSave(async () => {
        await calendarApi.updateAssignedUsers(
          data.userId !== 0 ? data.userId : null,
          selectedActionIds
        );
        confirm();
      });
    } catch (err) {
      errorSet(setError, err, t);
    }
  };

  return (
    <Formik<IFormData>
      initialValues={{
        userId: 0,
        userName: "",
        unassigned: false,
      }}
      validationSchema={Yup.object({
        userId: Yup.number().when("unassigned", {
          is: (unassigned: boolean) => !unassigned,
          then: validations.idRequired(t).moreThan(0),
        }),
      })}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <CustomModal
          title={t("calendar.list.assignmentModal.title")}
          isOpen={isOpen}
          close={close}
          actions={
            <>
              <Button ver="secondary" disabled={isSubmitting} onClick={close}>
                {t("common.cancel")}
              </Button>
              <SubmitModalForm />
            </>
          }
        >
          <Form>
            <FormGroup>
              <Input
                key="unassigned"
                name="unassigned"
                type="checkbox"
                label={t("calendar.list.assignmentModal.unassigned")}
                inputWidth="1.5rem"
                inputHeight="1.5rem"
              />

              <SuggestionFormik
                nameId="userId"
                nameText="userName"
                label={t("calendar.list.assignmentModal.user")}
                loadSuggestions={handleLoadUserSuggestions}
              />

              {error && <ApiError>{error}</ApiError>}
            </FormGroup>
          </Form>
          <AssignUserModalConflicts selectedIds={selectedActionIds} />
        </CustomModal>
      )}
    </Formik>
  );
};

export default AssignUserModal;
