import { AxiosResponse } from "axios";
import { IBasicSettings, IPasswordStrength } from "../models/basic";
import api from "../utils/api";

const getBasicSettings = async (): Promise<AxiosResponse<IBasicSettings>> =>
  api.get(`/api/BasicSettings`);

const updateBasicSettings = async (
  data: IBasicSettings
): Promise<AxiosResponse<IBasicSettings>> =>
  api.put(`/api/BasicSettings`, data);

const getPasswordStrength = async (): Promise<
  AxiosResponse<IPasswordStrength>
> => api.get(`/api/BasicSettings/PasswordStrength`);

const basicApi = {
  getBasicSettings,
  updateBasicSettings,
  getPasswordStrength,
};

export default basicApi;
