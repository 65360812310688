import { createAction } from "@reduxjs/toolkit";
import { IItemIdName } from "../../models/common";
import { DefaultDocumentFolderTypes } from "./types";

export const defaultDocumentFolderFetchRequest = createAction<
  void,
  DefaultDocumentFolderTypes.DEFAULT_DOCUMENT_FOLDER_FETCH_REQUEST
>(DefaultDocumentFolderTypes.DEFAULT_DOCUMENT_FOLDER_FETCH_REQUEST);

export const defaultDocumentFolderFetchSuccess = createAction<
  IItemIdName[],
  DefaultDocumentFolderTypes.DEFAULT_DOCUMENT_FOLDER_FETCH_SUCCESS
>(DefaultDocumentFolderTypes.DEFAULT_DOCUMENT_FOLDER_FETCH_SUCCESS);

export const defaultDocumentFolderFetchError = createAction<
  void,
  DefaultDocumentFolderTypes.DEFAULT_DOCUMENT_FOLDER_FETCH_ERROR
>(DefaultDocumentFolderTypes.DEFAULT_DOCUMENT_FOLDER_FETCH_ERROR);
