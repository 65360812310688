import { CancelToken } from "axios";
import { FC, ReactElement, useEffect } from "react";
import { connect } from "react-redux";
import { IClientContactPersonItem } from "../../models/client";
import { IApplicationState } from "../../store";
import { IFilterType } from "../../store/filterType";
import {
  changeClientContactPersonListFilter,
  changeClientContactPersonListOrder,
  changeClientContactPersonListPage,
  getClientContactPersonList,
  getClientContactPersonListCount,
  IClientContactPersonListState,
  selectClientContactPersonList,
  clientContactPersonListLoad,
} from "../../store/clientContactPersonList";
import FilterPanel from "../common/filter/FilterPanel";
import Grid from "../common/grid/Grid";
import { GridIconAdd, GridIconEdit } from "../common/grid/GridIcons";
import { TableCol } from "../common/grid/TableCol";
import { RightType } from "../../models/auth";
import { hasClientWriteRight } from "../../utils/rights";
import { selectIdentityRights } from "../../store/identity";
import { selectClientId } from "../../store/client";
import { useNavigate, useParams } from "react-router";
import { NavLink } from "react-router-dom";

interface IProps {
  prov: IClientContactPersonListState;
  clientId?: number;
  identityRights?: RightType[];
  getClientContactPersonList(clientId: number, cancelToken: CancelToken): void;
  getClientContactPersonListCount(
    clientId: number,
    cancelToken: CancelToken
  ): void;
  changeClientContactPersonListOrder(orderBy: string, orderDesc: boolean): void;
  changeClientContactPersonListPage(page: number): void;
  changeClientContactPersonListFilter(filter: IFilterType): void;
  clientContactPersonListLoad(reload: boolean): void;
}

const ClientPerson: FC<IProps> = ({
  prov,
  clientId,
  identityRights,
  getClientContactPersonList,
  getClientContactPersonListCount,
  changeClientContactPersonListOrder,
  changeClientContactPersonListPage,
  changeClientContactPersonListFilter,
  clientContactPersonListLoad,
}) => {
  const { clientId: paramClientId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    clientContactPersonListLoad(false);
  }, [clientContactPersonListLoad]);

  const handleRenderData = (item: IClientContactPersonItem): ReactElement => {
    return (
      <>
        <TableCol>{item.type.name}</TableCol>
        <TableCol>{item.name}</TableCol>
        <TableCol>{item.mobilePhone}</TableCol>
        <TableCol>{item.phone}</TableCol>
        <TableCol>{item.email}</TableCol>
        <TableCol>
          <NavLink to={`/client/${paramClientId}/person/${item.id}`}>
            <GridIconEdit />
          </NavLink>
        </TableCol>
      </>
    );
  };

  const handleRowClick = (item: IClientContactPersonItem) => {
    navigate(`/client/${paramClientId}/person/${item.id}`);
  };

  const handleGetData = (cancelToken: CancelToken) =>
    getClientContactPersonList(clientId!, cancelToken);

  const handleGetCount = (cancelToken: CancelToken) =>
    getClientContactPersonListCount(clientId!, cancelToken);

  return (
    <>
      <FilterPanel
        name="clientContactPerson"
        filter={prov.filter!}
        changeFilter={changeClientContactPersonListFilter}
      />
      <Grid<IClientContactPersonItem>
        headers={[
          { captionStr: "client.person.type" },
          { captionStr: "client.person.name" },
          { captionStr: "client.person.mobilePhone" },
          { captionStr: "client.person.phone" },
          { captionStr: "client.person.email" },
          {
            captionEl: hasClientWriteRight(identityRights, [
              RightType.WriteClientContactPersons,
            ]) ? (
              <NavLink to={`/client/${paramClientId}/person/new`}>
                <GridIconAdd />
              </NavLink>
            ) : undefined,
          },
        ]}
        renderData={handleRenderData}
        getData={handleGetData}
        getCount={handleGetCount}
        changeOrder={changeClientContactPersonListOrder}
        changePage={changeClientContactPersonListPage}
        onRowClick={handleRowClick}
        prov={prov}
      />
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    prov: selectClientContactPersonList(state),
    clientId: selectClientId(state),
    identityRights: selectIdentityRights(state),
  };
};

const mapDispachToProps = {
  getClientContactPersonList,
  getClientContactPersonListCount,
  changeClientContactPersonListOrder,
  changeClientContactPersonListPage,
  changeClientContactPersonListFilter,
  clientContactPersonListLoad,
};

export default connect(mapStateToProps, mapDispachToProps)(ClientPerson);
