import { Form, Formik } from "formik";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router";
import { IUser } from "../../models/user";
import { IApplicationState } from "../../store";
import { StoreState } from "../../store/storeState";
import {
  getDefaultUser,
  getUser,
  selectUser,
  selectUserState,
} from "../../store/user";
import { FormButton } from "../../styles/button";
import { ApiError, FormGroup } from "../../styles/form";
import Input from "../common/form/Input";
import PhoneInput from "../common/form/PhoneInput";
import Loader from "../common/Loader";
import * as Yup from "yup";
import { userListLoad } from "../../store/userList";
import userApi from "../../api/user";
import { SpaceBetweenButtons } from "../../styles/spaces";
import validations from "../../utils/validations";
import SuggestionFormik from "../common/suggestion/SuggestionFormik";
import SuggestionListFormik from "../common/suggestion/SuggestionListFormik";
import { RightType } from "../../models/auth";
import { hasRight } from "../../utils/rights";
import { selectIdentityRights } from "../../store/identity";
import {
  handleLoadAssistanceSuggestions,
  handleLoadGroupSuggestions,
  handleLoadRoleSuggestions,
} from "../../utils/suggestions";
import { promiseToast, promiseToastSave } from "../../utils/toasts";
import SubmitForm from "../common/form/SubmitForm";
import authApi from "../../api/auth";
import { errorSet } from "../../utils/error";

interface IProps {
  userState: StoreState;
  user: IUser | null;
  identityRights?: RightType[];
  getUser(userId: number): void;
  getDefaultUser(): void;
  userListLoad(reload: boolean): void;
}

const fields: Array<{ name: keyof IUser; maxLen?: number }> = [
  { name: "login", maxLen: 255 },
  { name: "title", maxLen: 32 },
  { name: "firstName", maxLen: 100 },
  { name: "lastName", maxLen: 100 },
  { name: "degree", maxLen: 32 },
];

const AdminUserDetail: FC<IProps> = ({
  userState,
  user,
  identityRights,
  getUser,
  getDefaultUser,
  userListLoad,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { userId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [sendPasswordInProgress, setSendPasswordInProgress] = useState(false);
  const returnTo = new URLSearchParams(location.search).get("return");

  useEffect(() => {
    if (userId === "new") {
      getDefaultUser();
    } else {
      getUser(parseInt(userId!));
    }
    setLoading(false);
  }, [userId, getUser, getDefaultUser]);

  const handleSubmit = async (data: IUser) => {
    setError(null);
    try {
      await promiseToastSave(async () => {
        if (userId === "new") {
          await userApi.createUser(data);
        } else {
          await userApi.updateUser(data);
        }

        userListLoad(true);
        navigate(returnTo ?? "/admin/user");
      });
    } catch (err) {
      errorSet(setError, err, t);
    }
  };

  const handleCancel = () => {
    navigate(returnTo ?? "/admin/user");
  };

  const handleSendPassword = async () => {
    setError(null);
    setSendPasswordInProgress(true);
    try {
      await promiseToast(
        async () => {
          await authApi.sendPasswordResetLink({ email: user!.email });
        },
        t("sendPassword.pending"),
        t("sendPassword.success"),
        t("errors.unknown")
      );
    } catch (err) {
      errorSet(setError, err, t);
    }
    setSendPasswordInProgress(false);
  };

  if (loading || userState === StoreState.Loading) {
    return <Loader />;
  }

  if (userState === StoreState.Error) {
    return t("errors.unknown");
  }

  return (
    <Formik<IUser>
      initialValues={{
        ...user!,
        title: user!.title ?? "",
        degree: user!.degree ?? "",
        phone: user!.phone ?? "",
      }}
      validationSchema={Yup.object({
        login: validations.stringRequired(t),
        firstName: validations.stringRequired(t),
        lastName: validations.stringRequired(t),
        mobilePhone: validations.phoneRequired(t),
        phone: validations.phoneOptional(t),
        email: validations.emailRequired(t),
        roleId: validations.idRequired(t),
        assistanceId: validations.idRequired(t),
      })}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, isSubmitting, values, isValid }) => (
        <Form>
          <h1>{t("admin.user.detailTitle")}</h1>
          <FormGroup>
            {hasRight(identityRights, [RightType.ChangeUserIsActive]) && (
              <Input
                key="isActive"
                name="isActive"
                type="checkbox"
                label={t(`admin.user.isActive`)}
                inputWidth="1.5rem"
                inputHeight="1.5rem"
              />
            )}
            {fields.map((f) => (
              <Input
                key={f.name}
                name={f.name}
                label={t("admin.user." + f.name)}
                error={touched[f.name] && !!errors[f.name]}
                maxLength={f.maxLen}
              />
            ))}
            <PhoneInput
              name="mobilePhone"
              label={t("admin.user.mobilePhone")}
              error={touched.mobilePhone && !!errors.mobilePhone}
            />
            <PhoneInput
              name="phone"
              label={t("admin.user.phone")}
              error={touched.phone && !!errors.phone}
            />
            <Input
              name="email"
              label={t("admin.user.email")}
              error={touched.email && !!errors.email}
              maxLength={255}
            />
            <SuggestionFormik
              nameId="roleId"
              nameText="roleName"
              label={t("admin.user.role")}
              loadSuggestions={handleLoadRoleSuggestions}
            />
            <SuggestionFormik
              nameId="assistanceId"
              nameText="assistanceName"
              label={t("admin.user.assistance")}
              loadSuggestions={handleLoadAssistanceSuggestions}
            />
            <SuggestionListFormik
              nameList="groups"
              nameId="id"
              nameText="name"
              label={t("admin.user.group")}
              loadSuggestions={handleLoadGroupSuggestions}
            />
          </FormGroup>
          {error && <ApiError>{error}</ApiError>}
          <FormButton
            ver="secondary"
            disabled={isSubmitting || sendPasswordInProgress}
            onClick={handleCancel}
          >
            {t("common.back")}
          </FormButton>
          {hasRight(identityRights, [RightType.WriteUsers]) && (
            <>
              <SpaceBetweenButtons />
              <SubmitForm disabled={sendPasswordInProgress} />
              {userId !== "new" && (
                <>
                  <SpaceBetweenButtons />
                  <FormButton
                    ver="secondary"
                    type="button"
                    disabled={
                      isSubmitting ||
                      sendPasswordInProgress ||
                      !isValid ||
                      values.email !== user!.email ||
                      values.login !== user!.login
                    }
                    onClick={handleSendPassword}
                  >
                    {t("sendPassword.button")}
                  </FormButton>
                </>
              )}
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    userState: selectUserState(state),
    user: selectUser(state),
    identityRights: selectIdentityRights(state),
  };
};

const mapDispachToProps = {
  getUser,
  getDefaultUser,
  userListLoad,
};

export default connect(mapStateToProps, mapDispachToProps)(AdminUserDetail);
