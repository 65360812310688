import { createAction } from "@reduxjs/toolkit";
import { INotificationItem } from "../../models/notification";
import { IFilterType } from "../filterType";
import { StoreState, StoreStateDontLoad } from "../storeState";
import { NotificationListTypes } from "./types";

export const notificationListFetchRequest = createAction<
  StoreStateDontLoad,
  NotificationListTypes.NOTIFICATION_LIST_FETCH_REQUEST
>(NotificationListTypes.NOTIFICATION_LIST_FETCH_REQUEST);

export const notificationListFetchSuccess = createAction<
  {
    data: INotificationItem[];
    newCountState: StoreStateDontLoad;
    newCount: number;
  },
  NotificationListTypes.NOTIFICATION_LIST_FETCH_SUCCESS
>(NotificationListTypes.NOTIFICATION_LIST_FETCH_SUCCESS);

export const notificationListFetchError = createAction<
  boolean,
  NotificationListTypes.NOTIFICATION_LIST_FETCH_ERROR
>(NotificationListTypes.NOTIFICATION_LIST_FETCH_ERROR);

export const notificationListCountRequest = createAction<
  void,
  NotificationListTypes.NOTIFICATION_LIST_COUNT_REQUEST
>(NotificationListTypes.NOTIFICATION_LIST_COUNT_REQUEST);

export const notificationListCountSuccess = createAction<
  number,
  NotificationListTypes.NOTIFICATION_LIST_COUNT_SUCCESS
>(NotificationListTypes.NOTIFICATION_LIST_COUNT_SUCCESS);

export const notificationListCountError = createAction<
  boolean,
  NotificationListTypes.NOTIFICATION_LIST_COUNT_ERROR
>(NotificationListTypes.NOTIFICATION_LIST_COUNT_ERROR);

export const notificationListChangeOrder = createAction<
  { orderBy: string; orderDesc: boolean },
  NotificationListTypes.NOTIFICATION_LIST_CHANGE_ORDER
>(NotificationListTypes.NOTIFICATION_LIST_CHANGE_ORDER);

export const notificationListChangePage = createAction<
  number,
  NotificationListTypes.NOTIFICATION_LIST_CHANGE_PAGE
>(NotificationListTypes.NOTIFICATION_LIST_CHANGE_PAGE);

export const notificationListChangeFilter = createAction<
  IFilterType,
  NotificationListTypes.NOTIFICATION_LIST_CHANGE_FILTER
>(NotificationListTypes.NOTIFICATION_LIST_CHANGE_FILTER);

export const notificationListReset = createAction<
  void,
  NotificationListTypes.NOTIFICATION_LIST_RESET
>(NotificationListTypes.NOTIFICATION_LIST_RESET);

export const notificationListLoadAction = createAction<
  { newState: StoreState; newCountState: StoreStateDontLoad },
  NotificationListTypes.NOTIFICATION_LIST_LOAD
>(NotificationListTypes.NOTIFICATION_LIST_LOAD);
