import styled from "styled-components";

export const Tooltip = styled.span`
  cursor: help;
  position: relative;

  &:hover::after {
    content: "${(props: { tooltipText: string }) => props.tooltipText}";
    background-color: #000;
    color: #fff;
    border-radius: 4px;
    padding: 16px 24px;
    position: absolute;
    left: 80%;
    transform: translateX(-80%);
    bottom: 170%;
    font-size: 14px;
    opacity: 0.9;
    z-index: 1;
    font-size: 1rem;
  }

  &:hover::before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%) rotate(180deg);
    bottom: 0%;
    border-width: 15px;
    border-style: solid;
    border-color: transparent transparent #000 transparent;
    opacity: 0.9;
  }
`;
