import { FC, useState } from "react";
import { connect } from "react-redux";
import { IApplicationState } from "../../store";
import { RightType } from "../../models/auth";
import { selectIdentityRights } from "../../store/identity";
import { hasRight } from "../../utils/rights";
import ClientButtonState from "./ClientButtonState";
import ClientButtonGdpr from "./ClientButtonGdpr";
import ClientButtonExport from "./ClientButtonExport";
import { ClientStateType, IClient } from "../../models/client";
import { selectClient } from "../../store/client";
import { ClientButtonContainer } from "./ClientButtonStyles";

interface IProps {
  client: IClient | null;
  identityRights?: RightType[];
}

const ClientButton: FC<IProps> = ({ client, identityRights }) => {
  const [isWorking, setIsWorking] = useState(false);

  return (
    <ClientButtonContainer>
      {hasRight(identityRights, [RightType.ChangeClientState]) && (
        <ClientButtonState isWorking={isWorking} setIsWorking={setIsWorking} />
      )}
      {((client!.fullRegistration && client!.gdprAgreementAt) ||
        hasRight(identityRights, [
          RightType.AddClientGdprAgreement,
          RightType.AddClientGdprAgreementWithoutSecret,
        ]) ||
        (client!.state !== ClientStateType.Anonymised &&
          hasRight(identityRights, [RightType.AnonymiseClient]))) && (
        <ClientButtonGdpr isWorking={isWorking} setIsWorking={setIsWorking} />
      )}
      {hasRight(identityRights, [RightType.ExportClient]) && (
        <ClientButtonExport isWorking={isWorking} setIsWorking={setIsWorking} />
      )}
    </ClientButtonContainer>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    client: selectClient(state),
    identityRights: selectIdentityRights(state),
  };
};

export default connect(mapStateToProps)(ClientButton);
