import { createAction } from "@reduxjs/toolkit";
import { ISearchFoundItem } from "../../models/search";
import { ISearchFilter, SearchTypes } from "./types";

export const searchFetchRequest = createAction<
  ISearchFilter,
  SearchTypes.SEARCH_FETCH_REQUEST
>(SearchTypes.SEARCH_FETCH_REQUEST);

export const searchFetchPartialSuccess = createAction<
  ISearchFoundItem[],
  SearchTypes.SEARCH_FETCH_PARTIAL_SUCCESS
>(SearchTypes.SEARCH_FETCH_PARTIAL_SUCCESS);

export const searchFetchSuccess = createAction<
  void,
  SearchTypes.SEARCH_FETCH_SUCCESS
>(SearchTypes.SEARCH_FETCH_SUCCESS);

export const searchFetchError = createAction<
  boolean,
  SearchTypes.SEARCH_FETCH_ERROR
>(SearchTypes.SEARCH_FETCH_ERROR);
