export const DIAGRAM_MIN_HEIGHT = 700;
export const DIAGRAM_OFFSET_X = 10;
export const DIAGRAM_OFFSET_Y = 10;
export const DIAGRAM_GAP_X = 40;
export const DIAGRAM_GAP_Y = 40;
export const DIAGRAM_HEIGHT = 85;
export const DIAGRAM_WIDTH = 250;
export const DIAGRAM_MULTIPLE_FAKE_PORT_CLASS = "multiple-fake-port";
export const DIAGRAM_MULTIPLE_MAX_ITEMS = 5;

export const DIAGRAM_RECALCULATE_ALL = -1;

export const DIAGRAM_COLORS = [
  "#a96be7",
  "#49c85d",
  "#a23daa",
  "#70b939",
  "#5173ea",
  "#a8be3a",
  "#5655bf",
  "#3c9735",
  "#d748ae",
  "#7abf6e",
  "#7c48b2",
  "#c7b03b",
  "#5b8fe9",
  "#e49d39",
  "#4068b4",
  "#db5826",
  "#4cbee0",
  "#d73a4b",
  "#5dd2be",
  "#e84790",
  "#57bd8b",
  "#db7ddf",
  "#7b9527",
  "#a67cd3",
  "#4f8a3c",
  "#b13d82",
  "#357943",
  "#d24472",
  "#38a9a3",
  "#e86d56",
  "#4f94cb",
  "#be702d",
  "#a79fe3",
  "#5c6916",
  "#7e4f99",
  "#8d9142",
  "#6866a4",
  "#a78331",
  "#d886bc",
  "#2f8063",
  "#a83a4d",
  "#b6b872",
  "#975483",
  "#5f6e34",
  "#e5838d",
  "#84662e",
  "#9f5062",
  "#df9c6e",
  "#a74123",
  "#9e593c",
];
