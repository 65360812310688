import * as ReselectModule from "reselect";
import { IApplicationState } from "..";
import { INotification } from "../../models/notification";
import { StoreState } from "../storeState";

export const selectNotificationState: ReselectModule.Selector<
  IApplicationState,
  StoreState
> = (state) => state.notification.state;

export const selectNotification: ReselectModule.Selector<
  IApplicationState,
  INotification | null
> = (state) => state.notification.data;
