import { addDays, addHours, addMinutes, format, isSameMinute } from "date-fns";
import {
  IActionItem,
  ICalendarCols,
  ICalendarDataItem,
  ICalendarItem,
  ICalendarResponseItem,
  ICalendarTimesLabels,
  ICalendarTimeValue,
} from "../models/calendar";
import { IItemIdName } from "../models/common";
import {
  CALENDAR_NUMBER_DEFAULT_MAX,
  CALENDAR_NUMBER_DEFAULT_MAX_SLOT,
  CALENDAR_ACTION_NUMBER_DEFAULT_MAX_SLOT,
  CALENDAR_NUMBER_DEFAULT_MIN,
  CALENDAR_STEP,
} from "./consts";

export const addZero = (n: number) => ("0" + n).slice(-2);

export const getTimesLabels = (input: Date[]): ICalendarTimesLabels => {
  const timesNumber = input.map((x) => x.getHours() * 100 + x.getMinutes());

  const timesNumberMin = Math.min(
    CALENDAR_NUMBER_DEFAULT_MIN,
    Math.min(...timesNumber)
  );
  const timesNumberMax = Math.max(
    CALENDAR_NUMBER_DEFAULT_MAX,
    Math.max(...timesNumber)
  );

  const times = [];
  const labels = [];
  let timeNumber = timesNumberMin;
  while (true) {
    if (timeNumber > timesNumberMax) {
      break;
    }

    const hours = Math.floor(timeNumber / 100);
    const mins = timeNumber % 100;

    times.push({ hours, mins });
    labels.push(hours + ":" + addZero(mins));

    timeNumber += CALENDAR_STEP;

    if (timeNumber % 100 === 60) {
      timeNumber += 40;
    }
  }

  return {
    times,
    labels,
  };
};

export const getCalendarCol = (
  year: number,
  month: number,
  day: number,
  times: ICalendarTimeValue[],
  input: ICalendarItem[],
  canJoin: boolean
): ICalendarItem[] => {
  const allCells = [];
  for (const time of times) {
    const datetime = new Date(year, month, day, time.hours, time.mins);

    const inputItem = input.find((x) => isSameMinute(x.datetime, datetime));
    if (inputItem) {
      allCells.push({ datetime, data: inputItem.data });
    } else {
      allCells.push({ datetime, data: [] });
    }
  }

  if (!canJoin) {
    return allCells;
  }

  const res = [];
  let i = 0;
  while (i < allCells.length) {
    const allActions = allCells[i].data.filter((x) => x.actionId);
    if (allActions.length === 0) {
      res.push(allCells[i]);
      i++;
      continue;
    }

    let joinCount = 1;
    let nextI = i + 1;
    while (nextI < allCells.length) {
      const nextActions = allCells[nextI].data.filter((x) => x.actionId);
      if (
        nextActions.some((x) =>
          allActions.some((y) => y.actionId === x.actionId)
        )
      ) {
        joinCount++;
        allActions.push(
          ...nextActions.filter(
            (x) => !allActions.some((y) => y.actionId === x.actionId)
          )
        );
      } else {
        break;
      }

      nextI++;
    }

    res.push({ datetime: allCells[i].datetime, joinCount, data: allActions });
    i = nextI;
  }

  return res;
};

export const getCalendar = (
  fromDate: Date,
  days: number,
  input: ICalendarItem[],
  canJoin: boolean
): ICalendarCols => {
  const timeLabels = getTimesLabels(input.map((x) => x.datetime));

  const res = [];
  let date = fromDate;
  for (let i = 0; i < days; i++) {
    const col = getCalendarCol(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      timeLabels.times,
      input,
      canJoin
    );

    res.push({ date, items: col });
    date = addDays(date, 1);
  }

  return { labels: timeLabels.labels, cols: res };
};

export const getCalendarOneDay = (
  date: Date,
  users: IItemIdName[],
  input: ICalendarResponseItem[],
  canJoin: boolean
): ICalendarCols => {
  const timeLabels = getTimesLabels(input.map((x) => x.dateTime));
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  const res = [];
  for (const user of users) {
    const items = input.filter((x) => x.user.id === user.id);
    const col = getCalendarCol(
      year,
      month,
      day,
      timeLabels.times,
      items.map((x) => ({
        datetime: x.dateTime,
        data: [transformResponseToData(x)],
      })),
      canJoin
    );

    res.push({ title: user.name, date, items: col });
    date = addDays(date, 1);
  }

  return { labels: timeLabels.labels, cols: res };
};

export const transformResponseToData = (
  item: ICalendarResponseItem
): ICalendarDataItem => ({
  datetime: item.dateTime,
  userId: item.user.id,
  userName: item.user.name,
  actionId: item.action?.id,
  actionName: item.action?.name,
});

export const transformCalendarReponseSimple = (
  response: ICalendarResponseItem[]
): ICalendarItem[] =>
  response.map((x) => ({
    datetime: x.dateTime,
    data: [transformResponseToData(x)],
  }));

export const transformCalendarReponseMulti = (
  response: ICalendarResponseItem[]
): ICalendarItem[] => {
  const map = new Map<string, ICalendarItem>();
  response.forEach((item) => {
    const key = format(item.dateTime, "yyyyMMddHHmm");
    const collection = map.get(key);
    if (!collection) {
      map.set(key, {
        datetime: item.dateTime,
        data: [transformResponseToData(item)],
      });
    } else {
      collection.data.push(transformResponseToData(item));
    }
  });
  return Array.from(map.values());
};

export const transformActionReponseSimple = (
  response: IActionItem[]
): ICalendarItem[] => {
  const res = [] as ICalendarItem[];
  response.forEach((x) => {
    let actualDate = x.from;
    while (true) {
      if (actualDate >= x.to) {
        break;
      }

      // eslint-disable-next-line no-loop-func
      const existsItem = res.find((x) => isSameMinute(x.datetime, actualDate));
      if (existsItem) {
        existsItem.data.push({
          datetime: actualDate,
          userId: x.userAssignee?.id,
          userName: x.userAssignee?.name,
          actionId: x.id,
          actionName: x.type.name,
        });
      } else {
        res.push({
          datetime: actualDate,
          data: [
            {
              datetime: actualDate,
              userId: x.userAssignee?.id,
              userName: x.userAssignee?.name,
              actionId: x.id,
              actionName: x.type.name,
            },
          ],
        });
      }

      actualDate = addMinutes(actualDate, CALENDAR_STEP);
    }
  });

  return res;
};

const getTimeSlots = () => {
  const res = [];
  let date = new Date(0, 0, 0, 0, 0);
  while (true) {
    const time = `${("0" + date.getHours()).slice(-2)}:${(
      "0" + date.getMinutes()
    ).slice(-2)}`;
    res.push(time);
    date = addMinutes(date, CALENDAR_STEP);
    if (date.getHours() === 0 && date.getMinutes() === 0) {
      break;
    }
  }
  res.push("24:00");
  return res;
};

export const timeSlots = getTimeSlots();

const translateTime = (timeNumber: number): string => {
  const time = ("0000" + timeNumber).slice(-4);
  return time.slice(0, 2) + ":" + time.slice(2);
};

export const calendarStringDefaultMin = translateTime(
  CALENDAR_NUMBER_DEFAULT_MIN
);
export const calendarStringDefaultMax = translateTime(
  CALENDAR_NUMBER_DEFAULT_MAX_SLOT
);
export const calendarStringDefaultMaxAction = translateTime(
  CALENDAR_ACTION_NUMBER_DEFAULT_MAX_SLOT
);

export const getHoursFromSlot = (time: string) => parseInt(time.slice(0, 2));

export const getMinutesFromSlot = (time: string) => parseInt(time.slice(3));

export const addTimeSlotToDate = (date: Date, time: string) =>
  addMinutes(addHours(date, getHoursFromSlot(time)), getMinutesFromSlot(time));

export const translateTimeToTimeSlot = (date: Date): string =>
  translateTime(date.getHours() * 100 + date.getMinutes());

export const zeroTimeSlot = translateTime(0);
