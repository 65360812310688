import { useFormikContext } from "formik";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Input from "../common/form/Input";

export interface IFormData {
  file: string;
  name: string;
  visible: boolean;
}

interface IProps {
  file: File | null;
}

const ClientDocumentAddContent: FC<IProps> = ({ file }) => {
  const { t } = useTranslation();

  const { errors, touched, resetForm, setFieldValue } =
    useFormikContext<IFormData>();

  useEffect(() => {
    resetForm();
    setFieldValue("file", file?.name ?? "");
    setFieldValue("name", file?.name ?? "");
  }, [file, resetForm, setFieldValue]);

  return (
    <>
      <Input
        name="file"
        label={t("document.addDocument.file")}
        disabled={true}
      />
      <Input
        name="name"
        label={t("document.addDocument.name")}
        error={touched.name && !!errors.name}
        maxLength={255}
      />
      <Input
        name="visible"
        type="checkbox"
        label={t(`module.document.rights.isClientVisible`)}
        inputWidth="1.5rem"
        inputHeight="1.5rem"
      />
    </>
  );
};

export default ClientDocumentAddContent;
