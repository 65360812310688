import { AxiosRequestConfig, AxiosResponse } from "axios";
import {
  AssistanceStateType,
  IAssistance,
  IAssistanceAddress,
  IAssistanceAddressItem,
  IAssistanceDocumentItem,
  IAssistanceItem,
  IAssistancePerson,
  IAssistancePersonItem,
} from "../models/assistance";
import { IItemIdName } from "../models/common";
import api from "../utils/api";

const getList = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<IAssistanceItem[]>> =>
  api.get(`/api/Assistance`, config);

const getCount = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<number>> => api.get(`/api/Assistance/Count`, config);

const assistanceListExportCsv = (
  params: URLSearchParams
): Promise<AxiosResponse<Blob>> =>
  api.get(`/api/Assistance/Export/Csv`, { params, responseType: "blob" });

const assistanceListExportXlsx = (
  params: URLSearchParams
): Promise<AxiosResponse<Blob>> =>
  api.get(`/api/Assistance/Export/Xlsx`, { params, responseType: "blob" });

const getSuggestionList = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<IItemIdName[]>> =>
  api.get(`/api/Assistance/Suggested/List`, config);

const getAddressSuggestionList = async (
  assistanceId: number,
  config: AxiosRequestConfig
): Promise<AxiosResponse<IItemIdName[]>> =>
  api.get(
    `/api/Assistance/${assistanceId}/ContactAddress/Suggested/List`,
    config
  );

const getAssistance = async (
  assistanceId: number
): Promise<AxiosResponse<IAssistance>> =>
  api.get(`/api/Assistance/${assistanceId}`);

const updateAssistance = async (
  assistance: IAssistance
): Promise<AxiosResponse<IAssistance>> =>
  api.put(`/api/Assistance/${assistance.id}`, assistance);

const createAssistance = async (
  assistance: IAssistance
): Promise<AxiosResponse<IAssistance>> =>
  api.post(`/api/Assistance`, assistance);

const changeAssistanceState = (
  assistanceId: number,
  state: AssistanceStateType
): Promise<AxiosResponse<IAssistance>> =>
  api.put(`/api/Assistance/${assistanceId}/State/${state}`);

const getPersonList = async (
  assistanceId: number,
  config: AxiosRequestConfig
): Promise<AxiosResponse<IAssistancePersonItem[]>> =>
  api.get(`/api/Assistance/${assistanceId}/ContactPerson`, config);

const getPersonCount = async (
  assistanceId: number,
  config: AxiosRequestConfig
): Promise<AxiosResponse<number>> =>
  api.get(`/api/Assistance/${assistanceId}/ContactPerson/Count`, config);

const getPerson = async (
  personId: number
): Promise<AxiosResponse<IAssistancePerson>> =>
  api.get(`/api/Assistance/ContactPerson/${personId}`);

const updatePerson = async (
  person: IAssistancePerson
): Promise<AxiosResponse<IAssistancePerson>> =>
  api.put(`/api/Assistance/ContactPerson/${person.id}`, person);

const createPerson = async (
  assistanceId: number,
  person: IAssistancePerson
): Promise<AxiosResponse<IAssistancePerson>> =>
  api.post(`/api/Assistance/${assistanceId}/ContactPerson`, person);

const getAddressList = async (
  assistanceId: number,
  config: AxiosRequestConfig
): Promise<AxiosResponse<IAssistanceAddressItem[]>> =>
  api.get(`/api/Assistance/${assistanceId}/ContactAddress`, config);

const getAddressCount = async (
  assistanceId: number,
  config: AxiosRequestConfig
): Promise<AxiosResponse<number>> =>
  api.get(`/api/Assistance/${assistanceId}/ContactAddress/Count`, config);

const getAddress = async (
  addressId: number
): Promise<AxiosResponse<IAssistanceAddress>> =>
  api.get(`/api/Assistance/ContactAddress/${addressId}`);

const updateAddress = async (
  address: IAssistanceAddress
): Promise<AxiosResponse<IAssistanceAddress>> =>
  api.put(`/api/Assistance/ContactAddress/${address.id}`, address);

const createAddress = async (
  assistanceId: number,
  address: IAssistanceAddress
): Promise<AxiosResponse<IAssistanceAddress>> =>
  api.post(`/api/Assistance/${assistanceId}/ContactAddress`, address);

const getAssistanceDocumentList = async (
  assistanceId: number,
  config: AxiosRequestConfig
): Promise<AxiosResponse<IAssistanceDocumentItem[]>> =>
  api.get(`/api/Assistance/${assistanceId}/Documents`, config);

const getAssistanceDocumentCount = async (
  assistanceId: number,
  config: AxiosRequestConfig
): Promise<AxiosResponse<number>> =>
  api.get(`/api/Assistance/${assistanceId}/Documents/Count`, config);

const addAssistanceDocumentFromTemplate = async (
  assistanceId: number,
  templateId: number
): Promise<AxiosResponse<IAssistanceDocumentItem>> =>
  api.post(`/api/Assistance/${assistanceId}/Documents/Template/${templateId}`);

const getAssistanceDocumentUploadUrl = async (
  assistanceId: number,
  fileName: string | null,
  documentId: string | null
): Promise<AxiosResponse<string>> => {
  const params = new URLSearchParams();

  if (fileName) {
    params.append("fileName", fileName);
  }

  if (documentId) {
    params.append("documentId", documentId);
  }

  return api.get(`/api/Assistance/${assistanceId}/Document/UploadUrl`, {
    params,
  });
};

const addAssistanceDocument = async (
  assistanceId: number,
  fileName: string
): Promise<AxiosResponse<string>> => {
  const params = new URLSearchParams();
  params.append("fileName", fileName);

  return api.post(`/api/Assistance/${assistanceId}/Document`, undefined, {
    params,
  });
};

const getAssistanceDocumentDownloadUrl = async (
  assistanceId: number,
  documentId: number
): Promise<AxiosResponse<string>> =>
  api.get(`/api/Assistance/${assistanceId}/Document/${documentId}/Download`);

const updateAssistanceDocument = async (
  assistanceId: number,
  documentId: number
): Promise<AxiosResponse<string>> =>
  api.put(`/api/Assistance/${assistanceId}/Document/${documentId}`);

const assistanceApi = {
  getList,
  getCount,
  assistanceListExportCsv,
  assistanceListExportXlsx,
  getSuggestionList,
  getAddressSuggestionList,
  getAssistance,
  updateAssistance,
  createAssistance,
  changeAssistanceState,
  getPersonList,
  getPersonCount,
  getPerson,
  updatePerson,
  createPerson,
  getAddressList,
  getAddressCount,
  getAddress,
  updateAddress,
  createAddress,
  getAssistanceDocumentList,
  getAssistanceDocumentCount,
  addAssistanceDocumentFromTemplate,
  getAssistanceDocumentUploadUrl,
  addAssistanceDocument,
  getAssistanceDocumentDownloadUrl,
  updateAssistanceDocument,
};

export default assistanceApi;
