import { faCalendarAlt, faList } from "@fortawesome/free-solid-svg-icons";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Routes, Route, Navigate } from "react-router";
import { RightType } from "../../models/auth";
import { IApplicationState } from "../../store";
import { selectIdentityRights } from "../../store/identity";
import { filterMenu, hasRight } from "../../utils/rights";
import AuthRoute from "../auth/AuthRoute";
import { SidebarPageContainer } from "../layout/LayoutStyles";
import Page from "../layout/Page";
import Sidebar from "../layout/Sidebar";
import CalendarList from "./CalendarList";
import CalendarListDetail from "./CalendarListDetail";
import CalendarMy from "./CalendarMy";
import CalendarTeamDay from "./CalendarTeamDay";
import CalendarTeamWeek from "./CalendarTeamWeek";

const links = [
  { id: "list", icon: faList, rights: [RightType.ReadCalendarList] },
  { id: "my", icon: faCalendarAlt, rights: [RightType.ReadCalendarMy] },
  {
    id: "teamweek",
    icon: faCalendarAlt,
    rights: [RightType.ReadCalendarTeamWeek],
  },
  {
    id: "teamday",
    icon: faCalendarAlt,
    rights: [RightType.ReadCalendarTeamDay],
  },
];

interface IProps {
  identityRights?: RightType[];
}

const Calendar: FC<IProps> = ({ identityRights }) => {
  const { t } = useTranslation();

  const defaultRedirect = () => {
    if (hasRight(identityRights, [RightType.ReadCalendar])) {
      return "my";
    }

    if (hasRight(identityRights, [RightType.ReadCalendarList])) {
      return "list";
    }

    if (hasRight(identityRights, [RightType.ReadCalendarTeamWeek])) {
      return "teamweek";
    }

    if (hasRight(identityRights, [RightType.ReadCalendarTeamDay])) {
      return "teamday";
    }

    return "/";
  };

  return (
    <SidebarPageContainer>
      <Sidebar
        title={t("calendar.pageTitle")}
        showTitle={true}
        links={filterMenu(identityRights, links).map((l) => ({
          key: l.id,
          to: `/calendar/${l.id}`,
          text: t(`calendar.nav.${l.id}`),
          icon: l.icon,
        }))}
      />
      <Page>
        <Routes>
          <Route
            path="list"
            element={
              <AuthRoute rights={[RightType.ReadCalendarList]}>
                <CalendarList />
              </AuthRoute>
            }
          />
          <Route
            path="list/:actionId"
            element={
              <AuthRoute rights={[RightType.ReadCalendarList]}>
                <CalendarListDetail />
              </AuthRoute>
            }
          />
          <Route
            path="my"
            element={
              <AuthRoute rights={[RightType.ReadCalendarMy]}>
                <CalendarMy />
              </AuthRoute>
            }
          />
          <Route
            path="teamweek"
            element={
              <AuthRoute rights={[RightType.ReadCalendarTeamWeek]}>
                <CalendarTeamWeek />
              </AuthRoute>
            }
          />
          <Route
            path="teamday"
            element={
              <AuthRoute rights={[RightType.ReadCalendarTeamDay]}>
                <CalendarTeamDay />
              </AuthRoute>
            }
          />
          <Route
            path="*"
            element={
              <AuthRoute>
                <Navigate to={defaultRedirect()} />
              </AuthRoute>
            }
          />
        </Routes>
      </Page>
    </SidebarPageContainer>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    identityRights: selectIdentityRights(state),
  };
};

export default connect(mapStateToProps)(Calendar);
