import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { StoreState } from "../../../store/storeState";
import { COLORS } from "../../../styles/colors";
import { SpanRed } from "../../../styles/text";
import {
  CustomTreeFile,
  CustomTreeFolder,
  CustomTreeLi,
  CustomTreeRootUl,
  CustomTreeUl,
} from "./CustomTreeStyles";
import { TailSpin } from "react-loader-spinner";

export interface ITreeItem {
  id: number;
  name: string;
  isFolder: boolean;
}

export interface ITreeReponse {
  state: StoreState;
  items: ITreeItem[];
}

export interface IProps {
  selectedFile: number | null | undefined;
  openedIcon?: IconProp;
  closedIcon?: IconProp;
  getItems(id: number): ITreeReponse;
  select(item: ITreeItem): void;
}

const CustomTree: FC<IProps> = ({
  selectedFile,
  openedIcon,
  closedIcon,
  getItems,
  select,
}) => {
  const { t } = useTranslation();
  const [openFolders, setOpenFolders] = useState<number[]>([]);

  const handleToggle = (id: number) => {
    if (openFolders.includes(id)) {
      setOpenFolders(openFolders.filter((x) => x !== id));
    } else {
      setOpenFolders([...openFolders, id]);
    }
  };

  const handleSelect = (item: ITreeItem) => {
    select(item);
  };

  const renderItems = (id: number) => {
    const response = getItems(id);

    if (response.state === StoreState.Error) {
      return (
        <CustomTreeLi>
          <SpanRed>{t("errors.unknown")}</SpanRed>
        </CustomTreeLi>
      );
    }

    if (response.state !== StoreState.Loaded) {
      return (
        <CustomTreeLi>
          <TailSpin color={COLORS.loaderColor} width={16} height={16} />
        </CustomTreeLi>
      );
    }

    if (response.items.length === 0) {
      return <CustomTreeLi>{t("common.noData")}</CustomTreeLi>;
    }

    return response.items.map((x) => (
      <CustomTreeLi key={(x.isFolder ? "folder" : "file") + x.id}>
        {x.isFolder ? (
          <>
            <CustomTreeFolder onClick={() => handleToggle(x.id)}>
              {openedIcon && closedIcon && (
                <FontAwesomeIcon
                  icon={openFolders.includes(x.id) ? openedIcon : closedIcon}
                />
              )}
              {x.name}
            </CustomTreeFolder>
            {openFolders.includes(x.id) && (
              <CustomTreeUl>{renderItems(x.id)}</CustomTreeUl>
            )}
          </>
        ) : (
          <CustomTreeFile
            className={selectedFile === x.id ? "selected" : undefined}
            onClick={() => handleSelect(x)}
          >
            {x.name}
          </CustomTreeFile>
        )}
      </CustomTreeLi>
    ));
  };

  return <CustomTreeRootUl>{renderItems(0)}</CustomTreeRootUl>;
};

export default CustomTree;
