import { AxiosRequestConfig, AxiosResponse } from "axios";
import { ISearchFoundItem } from "../models/search";
import api from "../utils/api";

const getSearch = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<ISearchFoundItem[]>> => api.get(`/api/Search`, config);

const searchApi = {
  getSearch,
};

export default searchApi;
